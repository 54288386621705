import { SortOrder } from '@xbcb/shared-types';
import { shipperId, shipperName } from './party';
import { createPartyTable } from './createPartyTable';
import { EsDataType } from '../../enums';

export const globalConsignee = createPartyTable({
  columns: {
    shipperName,
    shipperId,
    identificationIssuingCountry: {
      name: 'Country',
      type: EsDataType.KEYWORD,
      default: true,
      hidden: false,
    },
  },
  tableOptions: {
    sortOrder: SortOrder.DESC,
  },
});
