import {
  CreateUsPostSummaryCorrectionInput,
  UsConsumptionEntry,
} from '@xbcb/api-gateway-client';
import {
  UsCbpEntryModeOfTransport,
  UsPostSummaryCorrectionAmendmentReason,
} from '@xbcb/work-order-types';
import { transformUsConsumptionEntryInvoicesToUsPostSummaryCorrectionInvoiceInputs } from './transformUsConsumptionEntryInvoicesToUsPostSummaryCorrectionInvoiceInputs';

export const transformUsConsumptionEntryToUsPostSummaryCorrectionInput = ({
  ior,
  consignee,
  arrival,
  masterBills,
  departure,
  conveyance,
  invoices,
  totalValue,
  cbpCharges,
  cbpLiquidatedCharges,
  poNumbers,
  summaryNotification,
  cbpTeam,
  acceleratedLiquidation,
  entryNumber,
  brokerNumber,
  entryType,
  singleTransactionBond,
  amendmentReason,
  merchandiseUse,
  broker,
  group,
  operator,
  tags,
}: UsConsumptionEntry): CreateUsPostSummaryCorrectionInput => {
  const { address, name, pointOfContact, usCustomsBroker } = broker;
  const { email, name: pocName, phone } = pointOfContact || {};
  const { id } = usCustomsBroker;
  const {
    containerized,
    conveyanceName,
    grossWeight,
    modeOfTransport,
    tripNumber,
  } = conveyance;
  // The MOT enums are technically two different ones and thus could diverge
  // over time. Thus, we must make sure the entry's conveyance MOT is a valid
  // UsCbpEntryModeOfTransport.
  if (
    !Object.values(UsCbpEntryModeOfTransport).includes(
      modeOfTransport as UsCbpEntryModeOfTransport,
    )
  ) {
    throw new Error(`Unsupported conveyance mode of transport`);
  }
  // Note: We shouldn't copy `summaryStatus` or `summaryRejectionReasons`
  // because this information is copied from CBP and should be handled strictly
  // by the responses we get from CBP. The users will need to file the PSC at
  // some point anyways and then this info will be properly saved
  const createUsPostSummaryCorrectionInput: CreateUsPostSummaryCorrectionInput =
    {
      broker: {
        address,
        name,
        pointOfContact: { email, name: pocName, phone },
        usCustomsBroker: { id },
      },
      group: { id: group.id },
      operator: { id: operator.id },
      // Technically the entry and PSC conveyance are different types, thus we
      // copy the exact fields that we need here. At the moment the fields we
      // directly copy are the same with the exception of MOT which we check and
      // throw an error above if it is invalid
      conveyance: {
        containerized,
        conveyanceName,
        grossWeight,
        modeOfTransport,
        tripNumber,
      },
      // The remaining fields copied here are fields that have the same type for
      // both entry and PSC. Additionally, they have the same type for both input
      // and output. Thus, we can directly copy them.
      totalValue,
      cbpCharges,
      cbpLiquidatedCharges,
      poNumbers,
      summaryNotification,
      cbpTeam,
      acceleratedLiquidation,
      entryNumber,
      brokerNumber,
      entryType,
      singleTransactionBond,
      merchandiseUse,
      tags,
    };

  // TODO explanation and headerReasons are PSC specific fields and I don't
  // think there is any information on the entry that can be used to fill these
  // fields out. I'm thinking since they're optional we could leave them blank?

  // All the remaining fields added below are technically "shared" fields but
  // could differ (i.e. take a type when being defined). So we only copy the
  // exact fields needed incase the entry type being extended grows while the
  // PSC type being extend doesn't. See this file for more info:
  // https://code.amazon.com/packages/XbcbWorkOrderAuthority/blobs/mainline/--/packages/lambda/src/api/types/sharedUsEntrySummary/usEntrySummary.ts
  if (ior) {
    const { address, iorNumber, name, usIor } = ior;
    const { id, version } = usIor;
    createUsPostSummaryCorrectionInput.ior = {
      address,
      iorNumber,
      name,
      usIor: { id, version },
    };
  }

  if (consignee) {
    const { address, iorNumber, name, sameAsIor, usConsignee } = consignee;
    createUsPostSummaryCorrectionInput.consignee = {
      address,
      iorNumber,
      name,
      sameAsIor,
    };
    if (usConsignee) {
      const { id, version } = usConsignee;
      createUsPostSummaryCorrectionInput.consignee.usConsignee = {
        id,
        version,
      };
    }
  }

  if (arrival) {
    const { importDate, inBond, portOfUnladingCode, usDestinationStateCode } =
      arrival;
    createUsPostSummaryCorrectionInput.arrival = {
      importDate,
      portOfUnladingCode,
      usDestinationStateCode,
    };
    if (inBond) {
      const { estimatedEntryDate, initiationDate, portOfEntryCode } = inBond;
      createUsPostSummaryCorrectionInput.arrival.inBond = {
        estimatedEntryDate,
        initiationDate,
        portOfEntryCode,
      };
    }
  }

  if (departure) {
    const { exportCountryCode, exportDate, portOfLadingCode } = departure;
    createUsPostSummaryCorrectionInput.departure = {
      exportCountryCode,
      exportDate,
      portOfLadingCode,
    };
  }

  createUsPostSummaryCorrectionInput.masterBills = masterBills?.map(
    ({ houseBills, number }) => ({
      number,
      houseBills: houseBills?.map(({ inBondNumber, number, quantity }) => ({
        inBondNumber,
        quantity,
        number,
      })),
    }),
  );

  createUsPostSummaryCorrectionInput.invoices =
    transformUsConsumptionEntryInvoicesToUsPostSummaryCorrectionInvoiceInputs(
      invoices,
    );

  // The enums are different so we must make sure the entry's reasonCode is a
  // valid UsPostSummaryCorrectionAmendmentReason.
  // TODO is it okay to just leave it undefined if it's not a shared amendmentReason?
  if (
    amendmentReason &&
    Object.values(UsPostSummaryCorrectionAmendmentReason).includes(
      amendmentReason as UsPostSummaryCorrectionAmendmentReason,
    )
  ) {
    createUsPostSummaryCorrectionInput.amendmentReason =
      amendmentReason as UsPostSummaryCorrectionAmendmentReason;
  }

  return createUsPostSummaryCorrectionInput;
};
