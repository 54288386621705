import {
  addressFields,
  phoneFields,
  recordFields,
  recordActionFields,
} from '@xbcb/shared-queries';

const usDrawbackClaimDocumentGenerationRequestFragment = `
fragment usDrawbackClaimDocumentGenerationRequestFields on UsDrawbackClaimDocumentGenerationRequest {
  bond {
    accountNumber
    amount
    bondType
    suretyCode
  }
  broker {
    name
    address {
      ...addressFields
    }
    phone {
      ...phoneFields
    }
    email
  }
  cbpRejectionReasons {
    code
    reason
  }
  cbpStatus
  responseReceiveDate
  document {
    id
  }
  drawbackFilingPortCode
  entryNumber
  operator {
    id
  }
  status
  ...recordFields
}
`;

export const usDrawbackClaimDocumentGenerationRequestFragments = `
${usDrawbackClaimDocumentGenerationRequestFragment}
${addressFields}
${phoneFields}
${recordFields}
${recordActionFields}
`;
