import { LogicalOperator, BooleanOperator } from '@xbcb/shared-types';

export const logicMap = {
  [LogicalOperator.GREATER_THAN]: (a: number, b: number) => a > b,
  [LogicalOperator.GREATER_THAN_EQUALS]: (a: number, b: number) => a >= b,
  [LogicalOperator.LESS_THAN]: (a: number, b: number) => a < b,
  [LogicalOperator.LESS_THAN_EQUALS]: (a: number, b: number) => a <= b,
  [LogicalOperator.EQUALS]: (a: number, b: number) => a === b,
  [BooleanOperator.OR]: (a: boolean, b: boolean) => a || b,
  [BooleanOperator.AND]: (a: boolean, b: boolean) => a && b,
};
