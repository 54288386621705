import { createAction } from '@reduxjs/toolkit';
import { ModalKey } from '@xbcb/ui-types';

export type ModalPayload = { key: ModalKey };

interface SetModalPayload extends ModalPayload {
  props?: {
    [key: string]: any;
  };
}
interface MergeModalPayload extends ModalPayload {
  width?: number;
}

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const SET_MODAL = 'SET_MODAL';
export const MERGE_MODAL = 'MERGE_MODAL';

// from now on our redux actions are camelCase
export const openModal = createAction<ModalPayload, typeof OPEN_MODAL>(
  OPEN_MODAL,
);

export const closeModal = createAction<ModalPayload, typeof CLOSE_MODAL>(
  CLOSE_MODAL,
);

export const toggleModal = createAction<ModalPayload, typeof TOGGLE_MODAL>(
  TOGGLE_MODAL,
);

export const setModal = createAction<SetModalPayload, typeof SET_MODAL>(
  SET_MODAL,
);

export const mergeModal = createAction<MergeModalPayload, typeof MERGE_MODAL>(
  MERGE_MODAL,
);
