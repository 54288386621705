import { documentGenerationRequestFragments } from './documentGenerationRequest';

const usCbp5106DocumentGenerationRequestFragment = `
fragment usCbp5106DocumentGenerationRequestFields on UsCbp5106DocumentGenerationRequest {
	document {
		id
	}
	cbpMailingAddressType {
		code
	}
	cbpPhysicalAddressType {
		code
	}
	individuals {
		name
		title
		email
		phone {
			number
			country
			extension
		}
		user {
			id
		}
		socialSecurityNumber
		passport {
			number
			expiration
			countryCode
			type
		}
	}
	relatedBusinesses {
		name
		iorNumber {
			value
			type
		}
		type
	}
	businessDescription
	programCodes
	businessStructure
	entriesPerYearCode
	bank {
		name
		city
		stateCode
		countryCode
		routingNumber
	}
	name
	addresses {
		physical {
			address
			address2
			city
			postalCode
			stateCode
			countryCode
		}
		mailing {
			address
			address2
			city
			postalCode
			stateCode
			countryCode
		}
	}
	phone {
		number
		country
		extension
	}
	email
	website
	duns
	fax
	naics
	doingBusinessAs
	iorNumber {
		value
		type
	}
	signerDate
	...documentGenerationRequestFields
	...recordFields
}
`;

export const usCbp5106DocumentGenerationRequestFragments = `
${usCbp5106DocumentGenerationRequestFragment}
${documentGenerationRequestFragments}
`;
