import { AccountType } from '@xbcb/shared-types';
import { operatorId, EsDataType } from '../../index';
import { createUserTeamTableConfig } from './userTeam';

export const businessSupportTeam = createUserTeamTableConfig({
  columns: {
    operatorId,
    operatorName: {
      name: 'Operator',
      type: EsDataType.TEXT,
      whitelist: [AccountType.OPERATOR],
    },
    memberCount: {
      name: 'Members',
      type: EsDataType.INTEGER,
      default: true,
    },
  },
});
