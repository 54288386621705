import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { AnyObject } from '@xbcb/shared-types';
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
  setModal as setModalAction,
  CLOSE_MODAL,
  ModalPayload,
} from '../actions';
import { ModalKey } from '@xbcb/ui-types';

export interface UseModal {
  openModal: () => void;
  closeModal: () => {
    payload: ModalPayload;
    type: typeof CLOSE_MODAL;
  };
  setModal: (props: AnyObject) => void;
  visible: boolean;
  modalProps: any;
}

export const useModal = (key: ModalKey): UseModal => {
  const dispatch = useDispatch();
  const { visible, ...modalProps } = useSelector((state) =>
    get(state, ['ui', 'modals', key], {}),
  );

  const openModal = () => dispatch(openModalAction({ key }));

  const closeModal = () => dispatch(closeModalAction({ key }));

  const setModal = (props: AnyObject) => {
    dispatch(setModalAction({ key, props }));
  };

  return {
    openModal,
    closeModal,
    setModal,
    visible,
    modalProps,
  };
};
