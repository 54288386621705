import { DocumentTag } from '@xbcb/document-types';
import { UsBusinessStructure } from '@xbcb/party-types';
import { formatDocumentTag } from '../format';

// Maps business structure to document required/suggested during IOR onboarding of a UsIor.
export const businessStructureToDocumentTagsMap: {
  [key in UsBusinessStructure]?: {
    suggested: DocumentTag[];
    required: DocumentTag[];
  };
} = {
  [UsBusinessStructure.CORPORATION]: {
    suggested: [DocumentTag.EIN_VERIFICATION, DocumentTag.OFFICER_VERIFICATION],
    required: [],
  },
  [UsBusinessStructure.PARTNERSHIP]: {
    suggested: [],
    required: [DocumentTag.PARTNERSHIP_VERIFICATION],
  },
  [UsBusinessStructure.INDIVIDUAL]: {
    suggested: [DocumentTag.GOVERNMENT_ISSUED_PHOTO_ID],
    required: [],
  },
  [UsBusinessStructure.SOLE_PROPRIETOR]: {
    suggested: [DocumentTag.GOVERNMENT_ISSUED_PHOTO_ID],
    required: [DocumentTag.BUSINESS_LICENSE_OR_PERMIT],
  },
  [UsBusinessStructure.LLC]: {
    suggested: [DocumentTag.EIN_VERIFICATION, DocumentTag.OFFICER_VERIFICATION],
    required: [],
  },
};

// The exact same as businessStructureToDocumentTagsMap except it returns the
// DocumentTags formatted so they can be rendered in the UI to the users
export const businessStructureToFormattedDocumentTagsMap: {
  [key in UsBusinessStructure]?: {
    suggested: string[];
    required: string[];
  };
} = {};

Object.entries(businessStructureToDocumentTagsMap).forEach(
  ([businessStructure, documentTagInfo]) => {
    const { required = [], suggested = [] } = documentTagInfo || {};
    businessStructureToFormattedDocumentTagsMap[
      // Object.entries types it as a string instead of the enum
      businessStructure as UsBusinessStructure
    ] = {
      required: required.map((documentTag) => formatDocumentTag(documentTag)),
      suggested: suggested.map((documentTag) => formatDocumentTag(documentTag)),
    };
  },
);

// Maps business structure to document required/suggested depending on whether the business is a US resident or not during IOR onboarding of a UsIor
// TODO: Will replace completely businessStructureToDocumentTagsMap with this once gamma tested is done
export const businessStructureAndUsResidencyToDocumentTagsMap: {
  [key in UsBusinessStructure]?: {
    usResident: {
      suggested: DocumentTag[];
      required: DocumentTag[];
    };
    nonUsResident: {
      suggested: DocumentTag[];
      required: DocumentTag[];
    };
  };
} = {
  [UsBusinessStructure.CORPORATION]: {
    usResident: {
      suggested: [
        DocumentTag.EIN_VERIFICATION,
        DocumentTag.OFFICER_VERIFICATION,
        DocumentTag.CREDIT_REPORT,
        DocumentTag.GOVERNMENT_ISSUED_PHOTO_ID,
      ],
      required: [],
    },
    nonUsResident: {
      suggested: [
        DocumentTag.EIN_VERIFICATION,
        DocumentTag.OFFICER_VERIFICATION,
        DocumentTag.GOVERNMENT_ISSUED_PHOTO_ID,
      ],
      required: [
        DocumentTag.BUSINESS_LICENSE_OR_PERMIT,
        DocumentTag.CORPORATE_CERTIFICATION,
      ],
    },
  },
  [UsBusinessStructure.PARTNERSHIP]: {
    usResident: {
      suggested: [
        DocumentTag.CREDIT_REPORT,
        DocumentTag.GOVERNMENT_ISSUED_PHOTO_ID,
      ],
      required: [DocumentTag.PARTNERSHIP_VERIFICATION],
    },
    nonUsResident: {
      suggested: [DocumentTag.GOVERNMENT_ISSUED_PHOTO_ID],
      required: [
        DocumentTag.PARTNERSHIP_VERIFICATION,
        DocumentTag.BUSINESS_LICENSE_OR_PERMIT,
        DocumentTag.CORPORATE_CERTIFICATION,
      ],
    },
  },
  [UsBusinessStructure.INDIVIDUAL]: {
    usResident: {
      suggested: [DocumentTag.GOVERNMENT_ISSUED_PHOTO_ID],
      required: [],
    },
    nonUsResident: {
      suggested: [DocumentTag.GOVERNMENT_ISSUED_PHOTO_ID],
      required: [],
    },
  },
  [UsBusinessStructure.SOLE_PROPRIETOR]: {
    usResident: {
      suggested: [
        DocumentTag.CREDIT_REPORT,
        DocumentTag.GOVERNMENT_ISSUED_PHOTO_ID,
      ],
      required: [],
    },
    nonUsResident: {
      suggested: [DocumentTag.GOVERNMENT_ISSUED_PHOTO_ID],
      required: [DocumentTag.BUSINESS_LICENSE_OR_PERMIT],
    },
  },
  [UsBusinessStructure.LLC]: {
    usResident: {
      suggested: [
        DocumentTag.EIN_VERIFICATION,
        DocumentTag.OFFICER_VERIFICATION,
        DocumentTag.CREDIT_REPORT,
        DocumentTag.GOVERNMENT_ISSUED_PHOTO_ID,
      ],
      required: [],
    },
    nonUsResident: {
      suggested: [
        DocumentTag.EIN_VERIFICATION,
        DocumentTag.OFFICER_VERIFICATION,
      ],
      required: [],
    },
  },
};

// The exact same as businessStructureAndUsResidencyToDocumentTagsMap except it returns the
// DocumentTags formatted so they can be rendered in the UI to the users
// TODO: Will replace completely businessStructureToFormattedDocumentTagsMap with this once gamma tested is done
export const businessStructureAndUsResidencyToFormattedDocumentTagsMap: {
  [key in UsBusinessStructure]?: {
    usResident: {
      suggested: string[];
      required: string[];
    };
    nonUsResident: {
      suggested: string[];
      required: string[];
    };
  };
} = {};

Object.entries(businessStructureAndUsResidencyToDocumentTagsMap).forEach(
  ([businessStructure, documentTagInfo]) => {
    const {
      required: requiredForUsResident = [],
      suggested: suggestedForUsResident = [],
    } = documentTagInfo?.usResident || {};
    const {
      required: requiredForNonUsResident = [],
      suggested: suggestedForNonUsResident = [],
    } = documentTagInfo?.nonUsResident || {};
    businessStructureAndUsResidencyToFormattedDocumentTagsMap[
      // Object.entries types it as a string instead of the enum
      businessStructure as UsBusinessStructure
    ] = {
      usResident: {
        required: requiredForUsResident.map((documentTag) =>
          formatDocumentTag(documentTag),
        ),
        suggested: suggestedForUsResident.map((documentTag) =>
          formatDocumentTag(documentTag),
        ),
      },
      nonUsResident: {
        required: requiredForNonUsResident.map((documentTag) =>
          formatDocumentTag(documentTag),
        ),
        suggested: suggestedForNonUsResident.map((documentTag) =>
          formatDocumentTag(documentTag),
        ),
      },
    };
  },
);
