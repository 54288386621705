import {
  userFragments,
  forwarderUserPermissionFields,
  userNotificationFields,
} from './user';

const forwarderUserFragment = `
fragment forwarderUserFields on ForwarderUser {
  forwarder {
    id
    billingDetails {
      subscriptionPlan
    }
  }
  cargowise {
    cargowiseId
  }
  ${forwarderUserPermissionFields}
  ${userNotificationFields}
  ...userFields
  ...recordFields
}
`;

export const forwarderUserFragments = `
  ${forwarderUserFragment}
  ${userFragments}
`;
