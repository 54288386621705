import {
  UsType86EntryIor,
  UsType86EntryTariff,
  UsType86EntryPgaRequirements,
} from '@xbcb/api-gateway-client';

// These are the FDA and NHT pgas
const mandatoryPocRequiredPgas = ['FD1', 'FD2', 'FD3', 'FD4', 'DT2'];
const pgaExits = (pgas?: UsType86EntryPgaRequirements) =>
  Boolean(
    pgas &&
      Boolean(
        mandatoryPocRequiredPgas.some(
          (value) => pgas[value as keyof UsType86EntryPgaRequirements],
        ),
      ),
  );

export const isPocInfoRequiredButMissing = (
  ior: UsType86EntryIor,
  tariff: UsType86EntryTariff,
): boolean => {
  const pgas = tariff.pga;
  return pgaExits(pgas) && !ior.pointOfContact;
};
