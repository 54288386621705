import { MessageBundle } from '@amzn/arb-tools';
import { message } from 'antd';

export const safeGetMessage = (
  bundle: MessageBundle,
  stringId: string,
  args?: Record<string, unknown>,
  defaultValue?: string,
): string => {
  if (!bundle || !stringId) {
    return '';
  }
  try {
    if (!args) {
      return bundle.getMessage(stringId);
    }
    return bundle.formatMessage(stringId, args);
  } catch (e) {
    if (defaultValue) {
      return defaultValue;
    }
    message.error(`Error retrieving text for ${stringId}: ${e.message}`);
    return '';
  }
};
