import {
  isEmpty,
  isArray,
  isObject,
  pickBy,
  mapValues,
  isUndefined,
} from 'lodash';
import { unMomentize } from './momentize';

export const cleanValue = (val?: any) => {
  return typeof val !== 'boolean' && typeof val !== 'number' && isEmpty(val)
    ? undefined
    : typeof val === 'string'
    ? val.trim() // don't want extraneous white space stored.
    : val; // be careful with isEmpty, it returns true for numbers
};

// removes all empty string, arrays, and undefined values from object, recursively
export const cleanObject = (uncleanValues?: { [key: string]: any }): any => {
  const cleaned = unMomentize(uncleanValues);
  if (isArray(cleaned)) {
    const newArr = cleaned
      .map((innerObj) => cleanObject(innerObj))
      .filter(Boolean);
    return newArr.length ? newArr : undefined;
  } else if (isObject(cleaned)) {
    const newObj = pickBy(
      mapValues(cleaned, (val) => cleanObject(val)),
      (value, key) => !isUndefined(value),
    );
    return isEmpty(newObj) ? undefined : newObj;
  } else {
    return cleanValue(cleaned);
  }
};
