import { recordFields, recordActionFields } from '@xbcb/shared-queries';

export const invoiceCreditNoteFields = `
fragment invoiceCreditNoteFields on InvoiceCreditNote {
  ...recordFields
  creditTime
  description
  lines {
    type
    amount {
      currency
      value
    }
  }
  status
  totalCredit {
    currency
    value
  }
}
${recordFields}
${recordActionFields}
`;
