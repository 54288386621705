import { userWorkDayFields, userWorkingHoursFields } from './user';
import { userTeamFragments } from './userTeam';

const assignmentTeamFragment = `
  fragment assignmentTeamFields on AssignmentTeam {
    operator {
      id
    }
    customsBroker {
      id
    }
    workOrderTypes
    workOrderTaskTypes
    verticals {
      modeOfTransport
      loadTypes
      productTypes
      forwarders {
        id
      }
      shippers {
        id
      }
      iors {
        id
      }
    }
    subjectMatterExpertTeam {
      id
    }
    backupTeam {
      id
    }
    operatorUsers {
      nodes {
        id
      }
      edges {
        node {
          id
          version
          away
          workingHours {
            ...userWorkingHoursFields
          }
        }
        relationship {
          roles
          groups {
            groupNumber
            roles
          }
        }
      }
    }
    ...userTeamFields
    ...recordFields
  }
`;

export const assignmentTeamFragments = `
  ${assignmentTeamFragment}
  ${userTeamFragments}
  ${userWorkingHoursFields}
  ${userWorkDayFields}
`;
