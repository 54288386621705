import styled from 'styled-components';

export const StyledOptionalFormSectionTitleDiv = styled.div<{
  $isVisible: boolean;
}>`
  display: flex;
  ${($isVisible) => ($isVisible ? 'margin-bottom:-16px' : null)}
`;

export const StyledHeading = styled.h4`
  margin-bottom: var(--space-4);
  margin-right: var(--space-4);
`;
