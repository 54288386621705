import React from 'react';
import { StyledAnchor } from './styles';

type AttributionsProps = {
  // TODO ideally call this something better
  /** String containing the file path to the attributions.txt file */
  attribution: string;
};

const Attributions = ({ attribution }: AttributionsProps) => (
  <StyledAnchor target="blank" rel="noopener noreferrer" href={attribution}>
    Attributions
  </StyledAnchor>
);

export default Attributions;
