import styled from 'styled-components';
import { Select } from 'antd';

// should be typed as AntdSelect, but Antd has a bug. fixed here https://github.com/ant-design/ant-design/pull/27482 but not released yet
export const StyledSelect = styled<any>(Select)`
  .ant-select-selection-item {
    background: white;
  }
  &&&&& .ant-select-selector {
    height: 35px;
  }
`;
