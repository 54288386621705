import { getFullFormKeys } from '../getFullFormKeys';

export const formErrorsToInvalidFields = (errors) => {
  if (!errors) return [];
  // there is a more deeply nested field called "field" that gives us the key of the invalid field, but just using getFullFormKeys at the .errors level is equivalent.
  //
  // sample errors object:
  // errors = {
  //   charges: [
  //     {
  //       amount: { errors: [{ message: ' ', field: 'charges[0].amount' }] },
  //       type: { errors: [{ message: ' ', field: 'charges[0].type' }] },
  //     },
  //   ],
  // };
  const fullErrorKeys = getFullFormKeys({
    values: errors,
    whitelist: ['errors'],
  });

  const errorFields = fullErrorKeys.reduce((acc, errorKey) => {
    if (errorKey.endsWith('.errors')) {
      // chop off ".errors" so we're left w/ just the invalid field.
      acc.push(errorKey.slice(0, -7));
    }
    return acc;
  }, []);

  return errorFields;
};
