import styled from 'styled-components';
import { Tooltip } from 'antd';
import { TooltipProps } from 'antd/lib/tooltip';

export const StyledTd = styled.td<{
  $bold?: boolean;
}>`
  padding-left: var(--space-4);
  font-weight: ${({ $bold }) => ($bold ? 'bold' : 'var(--font-weight-3)')};
`;

export const StyledLabel = styled.th<{
  $bold?: boolean;
}>`
  font-weight: ${({ $bold }) => ($bold ? 'bold' : 'var(--font-weight-2)')};
`;

export const StyledTooltip = styled<React.ComponentType<TooltipProps>>(Tooltip)`
  text-decoration: underline;
`;
