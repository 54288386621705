import { get } from 'lodash';
import {
  UsConsumptionEntry,
  UsIsf,
  UsConsumptionEntryCommercialInvoice,
  UsConsumptionEntryProduct,
  UsConsumptionEntryLine,
  UsConsumptionEntryTariff,
  UsConsumptionEntryPga,
} from '@xbcb/api-gateway-client';
import { stripTypeNames, cloneJson } from '@xbcb/js-utils';

export enum UsConsumptionEntryLevel {
  INVOICES = 'INVOICES',
  PRODUCTS = 'PRODUCTS',
  LINES = 'LINES',
  TARIFFS = 'TARIFFS',
  PGAS = 'PGAS',
}
type ExtraProps = {
  path?: string;
};

export type UsConsumptionEntryCommercialInvoiceGenerator =
  UsConsumptionEntryCommercialInvoice & ExtraProps;

export type UsConsumptionEntryProductGenerator = UsConsumptionEntryProduct &
  ExtraProps & {
    invoice?: UsConsumptionEntryCommercialInvoiceGenerator;
  };

export type UsConsumptionEntryLineGenerator = UsConsumptionEntryLine &
  ExtraProps & {
    product?: UsConsumptionEntryProductGenerator;
  };

export type UsConsumptionEntryTariffGenerator = UsConsumptionEntryTariff &
  ExtraProps & {
    line?: UsConsumptionEntryLineGenerator;
  };

export type UsConsumptionEntryPgaGenerator = UsConsumptionEntryPga &
  ExtraProps & {
    flag?: string;
    tariff?: UsConsumptionEntryTariffGenerator;
  };

export const usConsumptionEntryGenerator = function* makeShipmentIterator(
  {
    entry: originalEntry,
    level,
  }: {
    entry: UsConsumptionEntry;
    level: UsConsumptionEntryLevel;
  },
  { clone = true } = {},
): Generator<
  | UsConsumptionEntryCommercialInvoiceGenerator
  | UsConsumptionEntryProductGenerator
  | UsConsumptionEntryLineGenerator
  | UsConsumptionEntryTariffGenerator
  | UsConsumptionEntryPgaGenerator,
  void,
  any
> {
  let entry = clone ? cloneJson(originalEntry) : originalEntry;
  entry = stripTypeNames(entry);

  const invoices = get(entry, 'invoices') || [];
  for (const [invoiceIndex, invoice] of invoices.entries()) {
    if (invoice) {
      const newInvoice: UsConsumptionEntryCommercialInvoiceGenerator =
        cloneJson(invoice);

      newInvoice.path = `invoices[${invoiceIndex}]`;
      if (level === UsConsumptionEntryLevel.INVOICES) {
        yield newInvoice;
        continue;
      }
      const products = newInvoice?.products || [];
      for (const [productIndex, product] of products.entries()) {
        if (product) {
          const newProduct: UsConsumptionEntryProductGenerator =
            cloneJson(product);
          newProduct.invoice = newInvoice;
          newProduct.path = `invoices[${invoiceIndex}].products[${productIndex}]`;
          if (level === UsConsumptionEntryLevel.PRODUCTS) {
            yield newProduct;
            continue;
          }
          const lines = newProduct.lines || [];
          for (const [lineIndex, line] of lines.entries()) {
            if (line) {
              const newLine: UsConsumptionEntryLineGenerator = cloneJson(line);

              newLine.product = product;
              newLine.path = `invoices[${invoiceIndex}].products[${productIndex}].lines[${lineIndex}]`;
              if (level === UsConsumptionEntryLevel.LINES) {
                yield newLine;
                continue;
              }
              const tariffs = newLine.tariffs || [];
              for (const [tariffIndex, tariff] of tariffs.entries()) {
                if (tariff) {
                  const newTariff: UsConsumptionEntryTariffGenerator =
                    cloneJson(tariff);

                  newTariff.line = line;
                  newTariff.path = `invoices[${invoiceIndex}].products[${productIndex}].lines[${lineIndex}].tariffs[${tariffIndex}]`;
                  if (level === UsConsumptionEntryLevel.TARIFFS) {
                    yield newTariff;
                    continue;
                  }
                  // tariff.pga could be `null`, which `get` doesn't handle. We need to explicitly declare `|| {}`
                  const pgas = newTariff.pga || {};
                  for (const pgaFlag of Object.keys(pgas)) {
                    const pga: UsConsumptionEntryPgaGenerator | undefined =
                      pgas[pgaFlag as keyof typeof pgas];
                    if (pga) {
                      pga.flag = pgaFlag;
                      pga.tariff = tariff;
                      pga.path = `invoices[${invoiceIndex}].products[${productIndex}].lines[${lineIndex}].tariffs[${tariffIndex}].pga[${pgaFlag}]`;
                      if (level === UsConsumptionEntryLevel.PGAS) {
                        yield pga;
                        continue;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

export enum UsIsfLevel {
  MANUFACTURERS = 'MANUFACTURERS',
  PRODUCTS = 'PRODUCTS',
}

export const usIsfGenerator = function* makeIsfIterator(
  { isf: originalIsf, level }: { isf: UsIsf; level: UsIsfLevel },
  { clone = true } = {},
) {
  const isf = clone ? JSON.parse(JSON.stringify(originalIsf)) : originalIsf;

  const isfManufacturers = get(isf, 'manufacturers', []);
  for (const [manufacturerKey, manufacturer] of isfManufacturers.entries()) {
    manufacturer.path = `manufacturers[${manufacturerKey}]`;
    if (level === UsIsfLevel.MANUFACTURERS) {
      yield manufacturer;
      continue;
    }
    const isfProducts = get(manufacturer, 'products', []);
    for (const [productKey, product] of isfProducts.entries()) {
      product.path = `manufacturers[${manufacturerKey}].products[${productKey}]`;
      if (level === UsIsfLevel.PRODUCTS) {
        yield product;
        continue;
      }
    }
  }
};
