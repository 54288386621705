import { AccountType, SkillLevel } from '@xbcb/shared-types';
import { EsDataType } from '../../index';
import { createUserTableConfig } from './user';

export const operatorUser = createUserTableConfig({
  columns: {
    operatorName: {
      default: true,
      name: 'Operator',
      type: EsDataType.TEXT,
      whitelist: [AccountType.OPERATOR],
    },
    customsBrokerId: {
      name: 'Broker ID',
      type: EsDataType.KEYWORD,
      hidden: true,
      whitelist: [AccountType.OPERATOR],
    },
    customsBrokerName: {
      name: 'Broker Name',
      type: EsDataType.TEXT,
      default: true,
      whitelist: [AccountType.OPERATOR],
    },
    vertical: {
      default: true,
      name: 'Industry',
      type: EsDataType.KEYWORD,
      whitelist: [AccountType.OPERATOR],
    },
    workOrderTaskDefinitionId: {
      hidden: true,
      name: 'Work Order Task Definition Id',
      type: EsDataType.KEYWORD,
    },
    skillLevel: {
      name: 'Skill level',
      type: EsDataType.KEYWORD,
      optionValues: Object.values(SkillLevel),
    },
    assignmentTeamId: {
      hidden: true,
      name: 'Assignment Team ID',
      type: EsDataType.KEYWORD,
    },
    assignmentTeamName: {
      default: true,
      name: 'Assignment Team',
      type: EsDataType.TEXT,
    },
    subjectMatterExpertTeamId: {
      hidden: true,
      name: 'SME Team ID',
      type: EsDataType.KEYWORD,
    },
    subjectMatterExpertTeamName: {
      default: true,
      name: 'SME Team',
      type: EsDataType.TEXT,
    },
  },
});
