import { SortOrder } from '@xbcb/shared-types';
import { EsDataType, recordFields } from '../index';
import { shipperId, shipperName } from './party';
import { createTableConfig } from './createTableConfig';

// TODO actually implement classification, this is unused

const columns = {
  shipperName,
  shipperId,
  name: {
    default: true,
    name: 'Name',
    type: EsDataType.TEXT,
  },
  htsNumber: {
    default: true,
    name: 'HTS Number',
    type: EsDataType.TEXT,
  },
  rateString: {
    default: true,
    name: 'Duty',
    type: EsDataType.KEYWORD,
  },
  countryName: {
    default: true,
    name: 'Country',
    type: EsDataType.KEYWORD,
  },
  description: {
    default: true,
    name: 'Description',
    type: EsDataType.TEXT,
  },
  materials: {
    default: true,
    name: 'Materials',
    type: EsDataType.TEXT,
  },
  classified: {
    default: true,
    name: 'Classified',
    type: EsDataType.BOOLEAN,
  },
  ...recordFields({
    createdTime: { react: true, default: true, name: 'Requested At' },
  }),
};

export const classification = createTableConfig({
  columns,
  tableOptions: {
    sortOrder: SortOrder.DESC,
  },
});
