export enum DocumentStatus {
  // The file is yet to be uploaded, the url has been not issued to the client.
  PENDING_UPLOAD = 'PENDING_UPLOAD',

  // The created document has been uploaded by document generation workflow.
  UPLOADED = 'UPLOADED',

  // The uploaded document is Pending for process.
  PENDING_PROCESSING = 'PENDING_PROCESSING',

  // The document is successfully processed.
  PROCESSED = 'PROCESSED',
}
