import { RecordType, SubscriptionPaymentPeriod } from '@xbcb/shared-types';

// subscription fee will be increased starting 28th May, 2022
// https://app.asana.com/0/1200574629683823/1202003454792251
export const subscriptionFeePriceTable = {
  [RecordType.SHIPPER]: {
    [SubscriptionPaymentPeriod.MONTHLY]: {
      LITE: 28.8,
      BASIC: 148.8,
      PRO: 373.2,
      ENTERPRISE: 748.8,
    },
    [SubscriptionPaymentPeriod.ANNUALLY]: {
      LITE: 288,
      BASIC: 1488,
      PRO: 3732,
      ENTERPRISE: 7488,
    },
  },
  [RecordType.FORWARDER]: {
    [SubscriptionPaymentPeriod.MONTHLY]: {
      LITE: 28.8,
      BASIC: 148.8,
      PRO: 373.2,
      ENTERPRISE: 748.8,
    },
    [SubscriptionPaymentPeriod.ANNUALLY]: {
      LITE: 288,
      BASIC: 1488,
      PRO: 3732,
      ENTERPRISE: 7488,
    },
  },
};
