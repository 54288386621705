import React, { useState, useRef, useEffect, useCallback } from 'react';
import { remove, add } from '@xbcb/ui-utils';
import { debounce } from 'lodash';
import { EventArgs, StoreValue } from 'rc-field-form/lib/interface';

// TODO see if we can get rid of this and replace it w/ a "debounce" prop in FormItem

type DebouncedComponentProps = {
  value?: StoreValue;
  onChange?: Function;
  [key: string]: any;
};

export default ({
  triggerMs = 1000,
  getValueFromEvent,
}: {
  triggerMs?: number;
  getValueFromEvent?: (...args: EventArgs) => StoreValue;
} = {}) => {
  return (C: any) => {
    const DebouncedComponent = (props: DebouncedComponentProps) => {
      const [value, setValue] = useState<StoreValue>(props.value);
      // "controlled" means that this component is currently capturing debounced input. It turns on when a user is interacting w/ an input and turns off after enough time has elapsed w/ no user input for the actual debounced handler to be invoked.
      const controlled = useRef<boolean>(false);
      const uuid = useRef<number>();

      const onChange = useCallback(
        debounce((value) => {
          try {
            props.onChange && props.onChange(value);
          } catch (e) {
            // form is probably no longer in existence
          }
          controlled.current = false;
        }, triggerMs),
        [],
      );

      useEffect(() => {
        uuid.current = add(onChange); // when a debounced component mounts, we store a reference to it in a map of all existing debounced functions so we can flush them all if needed.
        // when the component unmounts, we flush any currently debounced input and remove it from the map of debounced functions.
        return () => {
          onChange.flush();
          remove(uuid.current);
        };
      }, [onChange]);

      // I blieve this effect handles the case of the field's value being changed from elsewhere in the form
      useEffect(() => {
        if (!controlled.current && value && props.value !== value) {
          setValue(props.value);
        }
      }, [props.value, value]);

      const handleOnChange = (valueOrEvent: any) => {
        let value;
        if (typeof valueOrEvent === 'number') {
          value = valueOrEvent;
        } else {
          const event = valueOrEvent;
          if (getValueFromEvent) {
            value = getValueFromEvent(event);
          } else {
            value = event?.target?.value;
          }
        }
        setValue(value);
        controlled.current = true;
        onChange(value);
      };

      return <C {...props} value={value} onChange={handleOnChange} />;
    };
    return DebouncedComponent;
  };
};
