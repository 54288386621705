import { uiStageToBackendStage } from '@xbcb/ui-types';
import {
  businessStructureToDocumentTagsMap,
  getEnv,
  businessStructureAndUsResidencyToDocumentTagsMap,
} from '@xbcb/ui-utils';
import {
  cbpReg2ChangesFeature,
  CBP_REG_2_CHANGES_FEATURE,
} from '@xbcb/feature-flags';
import {
  UsBusinessStructure,
  Document,
  TemplateType,
} from '@xbcb/api-gateway-client';

const { stage } = getEnv();

const isCbpReg2ChangesFeatureEnabled = CBP_REG_2_CHANGES_FEATURE.isEnabled(
  cbpReg2ChangesFeature,
  {
    stage: uiStageToBackendStage[stage],
  },
);

interface FilterRequiredDocumentsParams {
  businessStructure: UsBusinessStructure;
  isUsResident: boolean;
  documents: Document[];
  templateType: TemplateType;
}

export const filterRequiredDocuments = ({
  businessStructure,
  isUsResident,
  documents,
  templateType,
}: FilterRequiredDocumentsParams): Set<string> | undefined => {
  const usResidencyToDocumentTagsMap =
    businessStructureAndUsResidencyToDocumentTagsMap[businessStructure];
  const documentTagInfo = isCbpReg2ChangesFeatureEnabled
    ? isUsResident
      ? usResidencyToDocumentTagsMap?.usResident
      : usResidencyToDocumentTagsMap?.nonUsResident
    : businessStructureToDocumentTagsMap[businessStructure];

  if (!documentTagInfo) return;

  const requiredDocTags = new Set<string>(documentTagInfo.required);
  if (!requiredDocTags.size) return;

  requiredDocTags.delete(templateType);

  documents?.forEach(({ documentTags }) => {
    documentTags.forEach((documentTag) => {
      if (requiredDocTags.has(documentTag)) {
        requiredDocTags.delete(documentTag);
      }
    });
  });
  return requiredDocTags;
};
