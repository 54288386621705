import { AccountType } from '@xbcb/shared-types';
import { EsDataType } from '../index';
import { createTableConfig } from './createTableConfig';

export const globalSearch = createTableConfig({
  columns: {
    forwarderName: {
      default: true,
      name: 'Forwarder',
      blacklist: [AccountType.FORWARDER],
      type: EsDataType.TEXT,
    },
    clientId: {
      default: [AccountType.SHIPPER, AccountType.FORWARDER],
      name: 'Client #',
      type: EsDataType.KEYWORD,
    },
    bookingId: {
      name: 'Booking Id provided by client',
      type: EsDataType.KEYWORD,
    },
    loadType: {
      name: 'Load Type',
      type: EsDataType.KEYWORD,
    },
    friendlyId: {
      name: 'ID',
      type: EsDataType.KEYWORD,
    },
    containerNumber: {
      name: 'Container #',
      type: EsDataType.TEXT,
    },
    masterBill: {
      default: true,
      name: 'Master Bill #',
      type: EsDataType.TEXT,
    },
    houseBill: {
      default: true,
      name: 'House Bill #',
      type: EsDataType.TEXT,
    },
    conveyanceName: {
      name: 'Conveyance Name',
      type: EsDataType.TEXT,
    },
    firmsCode: {
      default: true,
      name: 'FIRMS',
      type: EsDataType.KEYWORD,
    },
    dailyStatementNumber: {
      name: 'Daily Statement #',
      type: EsDataType.KEYWORD,
    },
    periodicStatementNumber: {
      name: 'Periodic Statement #',
      type: EsDataType.KEYWORD,
    },
    poNumber: {
      name: 'Ref #',
      type: EsDataType.KEYWORD,
    },
    tripNumber: {
      name: 'Voyage #',
      type: EsDataType.KEYWORD,
    },
    entryNumber: {
      name: 'Entry #',
      type: EsDataType.TEXT,
      analyzer: 'whitespace',
    },
    inBondNumber: {
      // Not sure if this is correct
      name: 'IT In-Bond',
      type: EsDataType.KEYWORD,
    },
    invoiceNumber: {
      name: 'Invoice #',
      type: EsDataType.KEYWORD,
    },
    qboNumber: {
      name: 'Qbo Invoice Doc Number',
      type: EsDataType.KEYWORD,
    },
    isfNumber: {
      default: true,
      name: 'ISF #',
      type: EsDataType.KEYWORD,
    },
    iorName: {
      default: true,
      name: 'IOR name',
      type: EsDataType.TEXT,
    },
    htsNumber: {
      default: true,
      name: 'Hts',
      type: EsDataType.TEXT,
    },
    caseNumber: {
      default: true,
      name: 'Case Number',
      type: EsDataType.TEXT,
    },
    principalCaseNumber: {
      default: true,
      name: 'Principal Case Number',
      type: EsDataType.TEXT,
    },
    id: { name: 'ID', type: EsDataType.KEYWORD, hidden: true },
    version: { name: 'Version', type: EsDataType.INTEGER, hidden: true },
    recordType: { name: 'Type', type: EsDataType.KEYWORD, hidden: true },
    tags: { name: 'Tags', type: EsDataType.KEYWORD, hidden: true },
    deletedTime: {
      name: 'Deleted Time',
      type: EsDataType.TIME,
    },
    productName: {
      default: true,
      name: 'Name',
      type: EsDataType.TEXT,
    },
    cbpDescription: {
      default: true,
      name: 'CBP Description',
      type: EsDataType.TEXT,
    },
    duns: {
      name: 'DUNS #',
      type: EsDataType.KEYWORD,
    },
    name: {
      name: 'Name',
      type: EsDataType.TEXT,
    },
    scac: {
      name: 'SCAC',
      type: EsDataType.KEYWORD,
    },
    ein: {
      name: 'EIN',
      type: EsDataType.KEYWORD,
    },
    feiNumber: {
      name: 'FEI number',
      type: EsDataType.KEYWORD,
    },
    mid: {
      name: 'MID',
      type: EsDataType.KEYWORD,
    },
    iorNumber: {
      name: 'IOR #',
      type: EsDataType.TEXT,
    },
    eori: {
      name: 'EORI',
      type: EsDataType.KEYWORD,
    },
    vatNumber: {
      name: 'VAT',
      type: EsDataType.KEYWORD,
    },
    customsBrokerName: {
      name: 'Broker Name',
      type: EsDataType.TEXT,
    },
    customsBrokerId: {
      name: 'Broker Id',
      type: EsDataType.KEYWORD,
    },
  },
  tableOptions: {
    pageSize: 25,
  },
  skipRecordFields: true,
});
