import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { CloseCode, createClient } from 'graphql-ws';
import { v4 } from 'uuid';
import {
  createOneMutation,
  getRecordFromResponseV2,
} from '@xbcb/shared-queries';
import { RecordType } from '@xbcb/shared-types';
import { UiStage } from '@xbcb/ui-types';
import { getEnv } from '@xbcb/ui-utils';
import { client } from '../client';

const { stage } = getEnv();
const gatewayStage = process.env.REACT_APP_GATEWAY_STAGE;

// The WS server is exposed through a public ALB
const webSocketUrlByUiStage = {
  [UiStage.LOCAL]: `wss://${
    gatewayStage ? gatewayStage.toLowerCase() : 'alpha'
  }.subscriptions.cbms.global-mile.amazon.dev`,
  [UiStage.ALPHA]: 'wss://alpha.subscriptions.cbms.global-mile.amazon.dev',
  [UiStage.BETA]: 'wss://beta.subscriptions.cbms.global-mile.amazon.dev',
  [UiStage.GAMMA]: 'wss://gamma.subscriptions.cbms.global-mile.amazon.dev',
  [UiStage.PROD]: 'wss://cbms-subscriptions.inlt.com',
};

const createAuthenticationTokenMutation = createOneMutation({
  recordName: RecordType.AUTHENTICATION_TOKEN,
});

// the socket close timeout due to token expiry
let tokenExpiryTimeout: NodeJS.Timeout;

const wsClient = createClient({
  url: webSocketUrlByUiStage[stage],
  connectionParams: async () => {
    const response = await client.mutate({
      mutation: createAuthenticationTokenMutation,
      variables: {
        idempotencyKey: v4(),
        input: {},
      },
    });
    const authToken = getRecordFromResponseV2({
      recordName: RecordType.AUTHENTICATION_TOKEN,
      response,
      crudOrSearchType: 'create',
    });
    return { authToken: authToken.id };
  },
  on: {
    connected: (socket: any, payload) => {
      // clear timeout on every connect for debouncing the expiry
      clearTimeout(tokenExpiryTimeout);
      // set a token expiry timeout for closing the socket
      // with an `4403: Forbidden` close event indicating
      // that the token expired. timeout set to 20 minutes
      // (same as authentication token DDB ttl)
      // this value should be consistent with the Authentication Token ttl
      // defined in Client Authority
      tokenExpiryTimeout = setTimeout(() => {
        if (socket.readyState === WebSocket.OPEN)
          socket.close(CloseCode.Forbidden, 'Forbidden');
      }, 20 * 60 * 1000);
    },
  },
});

export const wsLink = new GraphQLWsLink(wsClient);
