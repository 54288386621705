import { recordActionFields, recordFields } from '@xbcb/shared-queries';

export const externalSystemFields = `
fragment externalSystemFields on ExternalSystem {
  name
  webhookUrl
  awsIntegration {
    status
    iamRole {
      accountId
      arn
      name
    }
    snsTopic {
      accountId
      arn
    }
  }
}
`;

export const externalSystemFragments = `
${externalSystemFields}
${recordFields}
${recordActionFields}
`;
