import { getEnv } from '@xbcb/ui-utils';
import {
  fromCognitoIdentityPool,
  CognitoIdentityCredentialProvider,
} from '@aws-sdk/credential-provider-cognito-identity';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';

export const getGuestUserCredentialsProvider = async (
  region: string,
): Promise<CognitoIdentityCredentialProvider> => {
  const { GUEST_COGNITO_IDENTITY_POOL_ID: guestIdentityPoolId } = getEnv<{
    GUEST_COGNITO_IDENTITY_POOL_ID: string;
  }>();
  const client = new CognitoIdentityClient({ region });

  return fromCognitoIdentityPool({
    client,
    identityPoolId: guestIdentityPoolId,
  });
};
