import styled from 'styled-components';
import { Form } from 'antd';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import { CssSpace } from '@xbcb/ui-types';

// TODO make this a FormItem and some of this styling will handle itself. This
// has a wide blast radius so handle post-launch
export const StyledFormItem = styled(Form.Item)<{
  $spaceTop?: boolean;
  $spaceRight?: boolean;
}>`
  ${({ $spaceTop }) => $spaceTop && `margin-top: ${CssSpace.SPACE_4};`}
  ${({ $spaceRight }) => $spaceRight && `margin-right: ${CssSpace.SPACE_4};`}
  width: auto;
`;

export const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  font-size: var(--font-size-16);
`;
