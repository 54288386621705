import moment from 'moment';
// Needs to be updating at least annually
// https://inside.amazon.com/en/Employment/PublicHolidays/Pages/Holidays-in-the-Americas.aspx#amz_section07

// used for deadline calculation
export const amazonUsCaliforniaHolidays = [
  '2020-01-01',
  '2020-01-20',
  '2020-05-25',
  '2020-07-03',
  '2020-09-07',
  '2020-11-26',
  '2020-11-27',
  '2020-12-24',
  '2020-12-25',
  '2021-01-01',
  '2021-01-18',
  '2021-05-31',
  '2021-07-05',
  '2021-09-06',
  '2021-11-25',
  '2021-11-26',
  '2021-12-23',
  '2021-12-24',
  '2021-12-31',
  '2022-01-17',
  '2022-05-30',
  '2022-07-04',
  '2022-09-05',
  '2022-11-24',
  '2022-11-25',
  '2022-12-23',
  '2022-12-26',
  '2023-01-02', // Monday, January 2       New Year’s Day
  '2023-01-16', // Monday, January 16      Birthday of Martin Luther King, Jr.
  '2023-05-29', // Monday, May 29          Memorial Day
  '2023-07-04', // Tuesday, July 4         Independence Day
  '2023-09-04', // Monday, September 4     Labor Day
  '2023-11-23', // Thursday, November 23   Thanksgiving Day
  '2023-11-24', // Friday, November 24     Day After Thanksgiving
  '2023-12-22', // Friday, December 22     Christmas Eve Observed
  '2023-12-25', // Monday, December 25     Christmas Day
  '2024-01-01',
  '2024-01-15',
  '2024-05-27',
  '2024-07-04',
  '2024-09-02',
  '2024-11-28',
  '2024-12-25',
];

export const amazonUsNonCaliforniaHolidays = [
  '2020-01-01',
  '2020-05-25',
  '2020-07-03',
  '2020-09-07',
  '2020-11-26',
  '2020-12-25',
  '2021-01-01',
  '2021-01-18',
  '2021-05-31',
  '2021-07-05',
  '2021-09-06',
  '2021-11-25',
  '2021-12-24',
  '2021-12-31',
  '2022-01-17',
  '2022-05-30',
  '2022-07-04',
  '2022-09-05',
  '2022-11-24',
  '2022-12-26',
  // https://app.asana.com/0/0/1203589269608051/f
  '2023-01-02', // Monday, January 2       New Year’s Day
  '2023-01-16', // Monday, January 16      Birthday of Martin Luther King, Jr.
  '2023-05-29', // Monday, May 29          Memorial Day
  '2023-07-04', // Tuesday, July 4         Independence Day
  '2023-09-04', // Monday, September 4     Labor Day
  '2023-11-23', // Thursday, November 23   Thanksgiving Day
  '2023-12-25', // Monday, December 25     Christmas Day
  '2024-01-01',
  '2024-01-15',
  '2024-05-27',
  '2024-07-04',
  '2024-09-02',
  '2024-11-28',
  '2024-12-25',
];

export const checkAmazonUsCaliforniaHolidays = (date: moment.Moment | Date) => {
  if (!date) throw new Error('Missing date in checkAmazonUsCaliforniaHolidays');
  const str = date.toISOString().substr(0, 10);
  return amazonUsCaliforniaHolidays.includes(str);
};

export const checkAmazonUsNonCaliforniaHolidays = (date: any) => {
  if (!date)
    throw new Error('Missing date in checkAmazonUsNonCaliforniaHolidays');
  const str = date.toISOString().substr(0, 10);
  return amazonUsNonCaliforniaHolidays.includes(str);
};
