import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

export const asinFeature = 'ASIN_FEATURE';

const ASIN_FEATURE = new FeatureFlagService([
  {
    feature: asinFeature,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA],
  },
]);

export { ASIN_FEATURE };
