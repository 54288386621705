import { get } from 'lodash';
import { einShort, ssn } from '@xbcb/regex';
import { isValidEIN } from '../isValidEIN';
import { isValidCBPNumber } from '../isValidCBPNumber';
import { isValidIORNumber } from '../isValidIORNumber';
import { cleanObject } from '../clean';
import { unMomentize } from '../momentize';

/**
 * validates importer fields on importsign before submisssion
 * @param {Object} values
 * @param {string} cbpPortCodes optional, used for extra validation of CBP Assigned Number DDPP portion
 * @return {undefined}
 */
export const validateImporterFields = (values, cbpPortCodes) => {
  values = cleanObject(unMomentize(values));
  const errors = [];
  // some old clients might not have importerNumberType, so we have backup logic.
  // Also if the importerNumberType is undefined if the type is deterministic from country and structure (e.g. US corporation has to be ein).
  const iorIsEIN =
    values.importerNumberType === 'ein' ||
    (values.structure &&
      !['Individual', 'Sole Proprietorship'].includes(values.structure) &&
      values.countryCode === 'US');

  // EIN validation
  if (iorIsEIN && values.iorNumber) {
    if (!isValidEIN(values.iorNumber)) {
      errors.push(
        `Hmm... ${values.iorNumber} isn't the correct format for a Federal Tax ID (EIN). Please fix before submitting.`,
      );
    } else if (einShort.test(values.iorNumber)) {
      values.iorNumber += '00';
    }
  }
  // SSN validation
  if (
    (values.importerNumberType === 'ssn' ||
      (values.structure &&
        values.structure === 'Individual' &&
        values.countryCode === 'US')) &&
    values.iorNumber &&
    !ssn.test(values.iorNumber)
  ) {
    errors.push(
      `Hmm... ${values.iorNumber} isn't the correct format for a Social Security Number (SSN). Please fix before submitting.`,
    );
  }
  // CBP Number validation
  if (
    values.importerNumberType === 'cbp' &&
    values.iorNumber &&
    !isValidCBPNumber(values.iorNumber)
  ) {
    errors.push(
      `Hmm... ${values.iorNumber} isn't the correct format for a CBP Assigned Number (YYDDPP-NNNNN). Please fix before submitting.`,
    );
  }
  get(values, 'relatedBusinesses', []).forEach((relatedBusiness) => {
    if (
      relatedBusiness.name &&
      values.name &&
      get(values, 'name', '')
        .toLowerCase()
        .includes(get(relatedBusiness, 'name', '').toLowerCase())
    ) {
      errors.push(
        `A related business cannot have the same name as the importer. Please remove the related business if it is the same.`,
      );
    }
    if (relatedBusiness.iorNumber === values.iorNumber) {
      errors.push(
        `A related business cannot have the same IOR Number as the importer. Please remove the related business if it is the same.`,
      );
    }

    if (
      relatedBusiness.iorNumber &&
      !isValidIORNumber(relatedBusiness.iorNumber, cbpPortCodes)
    ) {
      errors.push(
        `The related business IOR Number ${relatedBusiness.iorNumber} is not a valid format.`,
      );
    }
    if (relatedBusiness.iorNumber && einShort.test(relatedBusiness.iorNumber)) {
      relatedBusiness.iorNumber += '00';
    }
  });
  if (values.website) values.website = values.website.toString().toLowerCase();
  // TODO enforce mandatory fields in each individual, we keep getting 5106 rejections later if these are partially filled out
  // https://www.cbp.gov/sites/default/files/assets/documents/2019-Mar/ACE%20CATAIR%205106%20Create_Update%20Version%2012%20%28NEW2%29.pdf
  delete values.samePhysicalAddress;
  delete values.sameIncorporationState;
  return { errors, values };
};
