import {
  EscalationReasonCategoryCode,
  EscalationReasonSubCategoryCode,
  WorkOrderTaskStatus,
} from '../enums';
import {
  SubjectMatterCategory,
  SubjectMatterSubCategory,
} from '@xbcb/shared-types';

export type ScheduleDetails = {
  // Unique id of the schedule (eg ARN of DACIA schedule)
  scheduleId: string;
  // Index of the scheduling out of the configured sequence
  schedulingSequenceIndex: number;
  // Time at which execution is scheduled for i.e., time for trigger
  scheduledAtTime: Date;
  // Schedule creation time
  createdTime: Date;
};

export enum TaskExecutionStatus {
  // Statuses for Manual Execution
  UNASSIGNED = 'UNASSIGNED',
  ASSIGNED_TO_OPERATOR_USER = 'ASSIGNED_TO_OPERATOR_USER',
  // Statuses for Auto Execution
  AUTO_EXECUTED = 'AUTO_EXECUTED',
  AUTO_EXECUTION_FAILED = 'AUTO_EXECUTION_FAILED',
  SCHEDULED = 'SCHEDULED',
}

export const TERMINAL_WORK_ORDER_TASK_STATUS = [
  WorkOrderTaskStatus.COMPLETED,
  WorkOrderTaskStatus.CANCELED,
  WorkOrderTaskStatus.CLOSED,
];

export type TaskExecutionResult = {
  // Status of the  execution
  status: TaskExecutionStatus;
  // (Optional) Id of the operator user assigned to task
  operatorUserId?: string;
  // (Optional) Current scheduled details if the execution has been scheduled for later
  currentScheduleDetails?: ScheduleDetails;
  // Time of completion of execution
  completedTime?: Date;
  // Last updated time of execution
  lastUpdatedTime?: Date;
  // (Optional) Errors from execution
  errors?: { [key: string]: string[] };
};

export type EscalationReasonCategoryType =
  | SubjectMatterCategory
  | EscalationReasonCategoryCode;

export type EscalationReasonSubCategoryType =
  | SubjectMatterSubCategory
  | EscalationReasonSubCategoryCode;
