import { LoadType, ModeOfTransport } from '@xbcb/shared-types';
import { EsDataType } from '../../index';
import { WorkOrderBadge } from '@xbcb/work-order-types';

export const sharedCustomsEntryColumns = {
  transportationServiceName: {
    name: 'Transportation Service Name',
    type: EsDataType.TEXT,
  },
  modeOfTransport: {
    default: true,
    name: 'Mode of Transportation',
    type: EsDataType.KEYWORD,
    react: true,
    optionValues: Object.values(ModeOfTransport),
  },
  conveyanceName: {
    name: 'Conveyance Name',
    type: EsDataType.TEXT,
  },
  exportDate: {
    default: true,
    name: 'Export Date',
    type: EsDataType.TIME,
  },
  exportCountryCode: {
    name: 'Export Country Code',
    type: EsDataType.KEYWORD,
  },
  exportCountryName: {
    name: 'Export Country',
    type: EsDataType.TEXT,
  },
  importDate: {
    name: 'Import Date',
    type: EsDataType.TIME,
  },
  portOfUnlading: {
    name: 'Port of Unlading',
    type: EsDataType.KEYWORD,
  },
  portOfLading: {
    default: true,
    name: 'Origin',
    type: EsDataType.KEYWORD,
  },
  tripNumber: {
    name: 'Voyage #',
    type: EsDataType.KEYWORD,
  },
  tripNumbers: {
    name: 'Trip #',
    type: EsDataType.KEYWORD,
  },
  invoiceNumber: {
    name: 'Invoice #',
    type: EsDataType.KEYWORD,
  },
  masterBill: {
    default: true,
    name: 'Master Bill #',
    type: EsDataType.TEXT,
  },
  houseBill: {
    default: true,
    name: 'House Bill #',
    type: EsDataType.TEXT,
  },
  containerNumber: {
    name: 'Container #',
    type: EsDataType.TEXT,
  },
  bookingId: {
    default: true,
    name: 'Booking ID',
    type: EsDataType.KEYWORD,
  },
  loadType: {
    name: 'Load Type',
    type: EsDataType.KEYWORD,
    optionValues: Object.values(LoadType),
  },
  iorName: { name: 'IOR Name', type: EsDataType.TEXT },
  badge: {
    name: 'Badge',
    type: EsDataType.KEYWORD,
    optionValues: Object.keys(WorkOrderBadge),
  },
  brokerName: {
    name: 'Broker Name',
    type: EsDataType.TEXT,
  },
};
