import { Shipper, Forwarder } from '@xbcb/api-gateway-client';
import { getPayer } from '@xbcb/finance-utils';
import { WorkOrderType } from '@xbcb/work-order-types';

type Payer = Shipper | Forwarder;

export const getWorkOrderConfiguration = ({
  shipper,
  forwarders,
  workOrderType,
}: {
  shipper?: any;
  forwarders?: any[];
  workOrderType: WorkOrderType;
}) => {
  const {
    workOrderConfirmationConfigurations:
      shipperWorkOrderConfirmationConfigurations,
    workOrderConfig: shipperWorkOrderConfig,
  } = shipper || {};

  // TODO consolidate confirmation + general work order configuration into one field.
  let workOrderConfirmationConfiguration;
  let workOrderConfiguration;

  const shipperWorkOrderConfirmationConfiguration =
    shipperWorkOrderConfirmationConfigurations?.find(
      (config: any) => config.workOrderType === workOrderType,
    );

  // getPayer will return either a shipper, or a forwarder, or error/undefined if none can be found
  const payer = getPayer({ shipper, forwarders, workOrderType }) as Payer;
  if (payer === shipper) {
    // TODO at the time of writing, shippers WO config is not yet implemented in the schema.
    workOrderConfiguration = shipperWorkOrderConfig;
    workOrderConfirmationConfiguration =
      shipperWorkOrderConfirmationConfiguration;
  } else {
    workOrderConfiguration = payer?.workOrderConfig;

    // shipper can override their forwarder's confirmation config even if they are not the payer
    if (shipperWorkOrderConfirmationConfiguration?.overrideForwarderConfig) {
      workOrderConfirmationConfiguration =
        shipperWorkOrderConfirmationConfiguration;
    } else {
      workOrderConfirmationConfiguration =
        payer?.workOrderConfirmationConfigurations?.find(
          (config: any) => config.workOrderType === workOrderType,
        );
    }
  }

  return {
    workOrderConfiguration,
    workOrderConfirmationConfiguration,
  };
};

// TODO consolidate w/ identical logic in finance-utils/src/getPayer (what package is appropriate? Need to avoid circular dependencies).
export const getWorkOrderConfigurationFromWorkOrderGroup = ({
  workOrderGroup,
  workOrderType,
}: {
  workOrderGroup: any;
  workOrderType: WorkOrderType;
}) => {
  const { shipper, forwarders: nestedForwarders } = workOrderGroup;

  // forwarders are doubly nested in WorkOrderGroup.
  const forwarders = nestedForwarders?.map(
    (forwarder: any) => forwarder.forwarder,
  );

  return getWorkOrderConfiguration({ shipper, forwarders, workOrderType });
};
