import { Columns, RecordColumns, TableOptions } from '../../tables';
import { createTableConfig } from '../createTableConfig';
import { partyFields } from './party';

export const createPartyTable = <TColumns extends Columns>({
  tableOptions = {},
  columns,
  skipOperatorId,
}: {
  tableOptions?: TableOptions;
  columns?: TColumns;
  skipOperatorId?: boolean;
}): RecordColumns =>
  createTableConfig({
    columns: {
      ...partyFields,
      ...columns,
    },
    tableOptions,
    skipOperatorId,
  });
