import { getCodes } from '@xbcb/ui-utils';
import { connect } from 'react-redux';
import { get } from 'lodash';
import LocationForm from './component';

const mapStateToProps = (state) => {
  const codes = getCodes();
  return {
    countryCodes: codes.ISO.country.alpha2,
    zipCodes: codes.USPS.ZIP,
    typeCodes: get(codes, 'CBP.CATAIR.importerAddress'),
  };
};

export default connect(mapStateToProps)(LocationForm);
