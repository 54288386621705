import { CrudPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const OperatorUserInvoicePermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserPayableInvoicePermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserInvoiceLinesRequestPermissionName = {
  ...CrudPermissionName,
};

export default {
  [ObjectType.INVOICE]: OperatorUserInvoicePermissionName,
  [ObjectType.PAYABLE_INVOICE]: OperatorUserPayableInvoicePermissionName,
  [ObjectType.INVOICE_LINES_REQUEST]:
    OperatorUserInvoiceLinesRequestPermissionName,
};
