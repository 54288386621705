export enum DocumentGenerationRequestStatus {
  // The generation request is under processing.
  PROCESSING = 'PROCESSING',

  // The document has been generated.
  GENERATED = 'GENERATED',

  // The generation request has been failed.
  FAILED = 'FAILED',
}
