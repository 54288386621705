import { UsIorNumberType } from '@xbcb/party-types';
import {
  ssn as ssnRegex,
  ein as einRegex,
  cbp as cbpAssignedRegex,
} from '@xbcb/regex';

export const determineIorNumberType = (iorNumber: string) => {
  if (ssnRegex.test(iorNumber)) {
    return UsIorNumberType.SSN;
  } else if (einRegex.test(iorNumber)) {
    return UsIorNumberType.EIN;
  } else if (cbpAssignedRegex.test(iorNumber)) {
    return UsIorNumberType.CBP;
  } else {
    throw new Error(`This IOR number ${iorNumber} is invalid`);
  }
};
