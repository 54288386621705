import { FormInstance } from 'antd/lib/form';
import { get, groupBy, partition, set } from 'lodash';
import type { AnyObject } from '@xbcb/shared-types';
import { NamePath, AdditionalFormError } from '@xbcb/ui-types';
import { formatFormErrors } from '../formatFormErrors';
import { getMissingRequiredFieldErrors } from '../getMissingRequiredFields';
import { showValidationErrors } from '../showValidationErrors';
import { isLargeEntryUXEnabled } from '../isLargeEntryUXEnabled';

export interface CustomFieldError extends AdditionalFormError {
  path: NamePath;
}

const baseError = {
  title: 'Commercial invoices tab has errors',
  messages: [],
};

const groupAdditionalErrors = (errors: AdditionalFormError[] = []) => {
  return groupBy(errors, (error) => {
    if (error.path) return 'customFieldErrors';
    else return 'generalErrors';
  });
};

/**
 * Removes all actual values from the form state while preserving the shape
 * (e.g. number of invoices, products, lines, tariffs for an entry)
 */

export const cleanFormValues = (input: any): AnyObject | undefined => {
  let result;
  if (typeof input === 'object' && input !== null) {
    result = {} as Record<string, unknown>;
    if (Array.isArray(input)) {
      return input.map(cleanFormValues).filter(Boolean);
    }
    for (const key of Object.keys(input)) {
      if (input[key] && typeof input[key] === 'object') {
        result[key] = cleanFormValues(input[key]);
      }
    }
  }
  return result;
};

/**
 * Convert the errors into a tree structure so
 * components can query errors by name path
 * from Redux state
 */

const handleCustomFieldErrors = (
  payload: Record<string, unknown>,
  customErrors: CustomFieldError[] = [],
) => {
  customErrors.forEach((customError) => {
    const { path } = customError;
    const existingErrors = get(payload, [...path, 'errors']) ?? [];
    set(payload, [...path, 'errors'], [...existingErrors, customError]);
  });
  return payload;
};

export const validateForm = async ({
  form,
  additionalErrors,
  validateFields,
  skipValidateFields,
  setErrorState,
  currentUserId,
}: {
  form: FormInstance;
  additionalErrors?: AdditionalFormError[];
  validateFields?: NamePath[];
  skipValidateFields?: boolean;
  setErrorState?: (args: any) => void;
  currentUserId?: string;
}): Promise<boolean> => {
  const largeEntryUXEnabled = isLargeEntryUXEnabled(currentUserId);
  setErrorState && setErrorState({});
  const fullValidateFields = validateFields ? [...validateFields] : [];

  if (largeEntryUXEnabled && validateFields?.length) {
    /**
     * This is a bit of a hack. We want to display missing fields
     * inline where applicable (currently only the commercial invoices tab)
     * rather than in the sidebar, but validateFields
     * is just an array of namepaths.
     *
     * Also, since in the large entry layout not all form fields are visible/registered,
     * using Ant's native form validation won't work, since that only validates registered fields.
     */
    const [sideBarFields, inlineFields] = partition(
      validateFields,
      (namePath) => !namePath.includes('invoices'),
    );
    validateFields = sideBarFields;
    // eslint-disable-next-line
    // @ts-ignore
    const input = form.getFieldValue();
    const missingFieldErrors = getMissingRequiredFieldErrors(
      input,
      inlineFields,
    );
    if (missingFieldErrors?.length) {
      if (additionalErrors?.length) {
        additionalErrors.push(...missingFieldErrors);
      } else {
        additionalErrors = [...missingFieldErrors];
      }
    }
  }

  // eslint-disable-next-line
  // @ts-ignore
  const input = form.getFieldValue();
  const payload = cleanFormValues(input) ?? {};

  try {
    if (!skipValidateFields) {
      const fieldsToValidate = validateFields?.length
        ? validateFields
        : undefined; // undefined means validate all fields
      await form.validateFields(fieldsToValidate);
    }

    if (additionalErrors?.length) {
      if (largeEntryUXEnabled) {
        // With the new UI layout, we need to store invoice errors in state rather than show in the sidebar
        // Any error included by a validator with a "path" property is considered a custom field error;
        // without a path, it's a normal "general" error and will appear in the sidebar.
        const { customFieldErrors = [], generalErrors = [] } =
          groupAdditionalErrors(additionalErrors);

        const customErrorResult = handleCustomFieldErrors(
          payload,
          customFieldErrors as CustomFieldError[],
        );
        setErrorState &&
          setErrorState({
            errorMap: customErrorResult,
            validateFields: fullValidateFields ?? [],
          });
        if (customFieldErrors.length) {
          generalErrors.unshift(baseError);
        }
        // there were no form errors, but there were some additionalErrors in the custom validateUpdate
        generalErrors?.length && showValidationErrors(generalErrors);
      } else {
        showValidationErrors(additionalErrors);
      }

      return false;
    } else {
      setErrorState &&
        setErrorState({
          errorMap: payload,
          validateFields: fullValidateFields ?? [],
        });
      return true;
    }
  } catch (errorInfo) {
    const formattedErrors = formatFormErrors({ errorInfo });
    const validationErrors = [formattedErrors];
    if (largeEntryUXEnabled) {
      const { customFieldErrors = [], generalErrors = [] } =
        groupAdditionalErrors(additionalErrors as CustomFieldError[]);
      const customErrorResult = handleCustomFieldErrors(
        payload,
        customFieldErrors as CustomFieldError[],
      );
      setErrorState &&
        setErrorState({
          errorMap: customErrorResult,
          validateFields: fullValidateFields ?? [],
        });
      if (customFieldErrors.length) {
        validationErrors.unshift(baseError);
      }
      validationErrors.push(...generalErrors);
    } else if (additionalErrors) {
      validationErrors.push(...additionalErrors);
    }
    showValidationErrors(validationErrors);
    return false;
  }
};

export type ValidateFormSnapshotFieldsProps = {
  input: any;
  idPath: NamePath;
  displayName: string;
  embeddedFields: NamePath[];
};

export const validateFormSnapshotFields = (
  props: ValidateFormSnapshotFieldsProps,
) => {
  if (!get(props.input, props.idPath)) {
    return []; // only add additionalErrors if reference is selected
  }
  const path = props.idPath.slice(0, -2);
  const messages = props.embeddedFields.reduce((acc: string[], field) => {
    const fullNamePath = [...path, ...field];
    if (!get(props.input, fullNamePath)) {
      acc.push(`Missing ${fullNamePath.join('.')}`); // TODO improve formatting
    }
    return acc;
  }, []);
  const versionFullNamePath = [...props.idPath.slice(0, -1), 'version'];
  if (!get(props.input, versionFullNamePath)) {
    messages.push(`Missing ${versionFullNamePath.join('.')}`); // TODO improve formatting
  }
  if (!messages.length) return [];
  else
    return [
      {
        title: `Fields missing on the ${props.displayName} record.`,
        // TODO why is this necessary, reduce should be returning string[]
        messages: messages as string[],
      },
    ];
};

export const manifestFieldsToValidate = (
  masterBills: {
    number?: string;
    houseBills: { number?: string }[];
  }[],
) => {
  const fieldsToValidate: NamePath[] = [];
  masterBills?.forEach((masterBill, masterBillIndex) => {
    const masterBillNamePath = ['masterBills', masterBillIndex];
    fieldsToValidate.push([...masterBillNamePath, 'number']);
    masterBill.houseBills?.forEach((houseBill: any, houseBillIndex: number) => {
      const houseBillNamePath = [
        ...masterBillNamePath,
        'houseBills',
        houseBillIndex,
      ];
      fieldsToValidate.push([...houseBillNamePath, 'number']);
      // these next fields are only on the Entry, not the ISF
      fieldsToValidate.push([...houseBillNamePath, 'quantity']);
      if (houseBill?.inBondNumber)
        fieldsToValidate.push(['arrival', 'inBond', 'initiationDate']);
    });
  });
  return fieldsToValidate;
};

export type CreatePartyValidatorProps = {
  validateFields: NamePath[];
  additionalErrors: AdditionalFormError[];
  input: any;
};

export const defaultCreatePartyValidatorEmbeddedFields = [
  ['name'],
  ['address', 'address'],
  ['address', 'city'],
  ['address', 'stateCode'],
  ['address', 'countryCode'],
];

// N.B. This is a mutating function. Not ideal but it makes for easy invocation where we need it.
export const createPartyValidator =
  ({ validateFields, additionalErrors, input }: CreatePartyValidatorProps) =>
  (
    idPath: NamePath,
    displayName: string,
    embeddedFields?: NamePath[], // default is name and address
  ) => {
    validateFields.push(idPath);
    additionalErrors.push(
      ...validateFormSnapshotFields({
        input,
        idPath,
        displayName,
        embeddedFields:
          embeddedFields || defaultCreatePartyValidatorEmbeddedFields,
      }),
    );
  };
