import { AccountType } from '@xbcb/shared-types';
import { forwarderUserPermissions } from './forwarderUser';
import { shipperUserPermissions } from './shipperUser';
import { operatorUserPermissions } from './operatorUser';

export * from './forwarderUser';
export * from './shipperUser';
export * from './operatorUser';
export * from './base';

export const PermissionsByAccountType = {
  [AccountType.FORWARDER]: forwarderUserPermissions,
  [AccountType.SHIPPER]: shipperUserPermissions,
  [AccountType.OPERATOR]: operatorUserPermissions,
};
