import { Decimal } from 'decimal.js';
import { AnyObject } from '@xbcb/tools/src/types';

// Define interface for DynamoDB item
interface DynamoDBItem {
  [key: string]: DynamoDBAttributeValue;
}

// Define type for DynamoDB attribute value
type DynamoDBAttributeValue =
  | string
  | number
  | boolean
  | null
  | Buffer
  | DynamoDBItem
  | DynamoDBItem[];

/**
 * Calculate size of a DynamoDB item in bytes
 * @param {DynamoDBItem} item of DDB
 * @return {number} DDB item size in bytes
 */
// TODO : throw error if its not json type and add unit tests
export const calculateDynamoDBItemSize = (
  item: DynamoDBItem | AnyObject,
): number => {
  let size = 0;
  for (const [key, value] of Object.entries(item)) {
    size += Buffer.byteLength(key, 'utf8');
    size += calculateDynamoDBAttributeSize(value);
  }
  return size;
};

/**
 * Calculate size of a DynamoDB attribute in bytes
 * @param {DynamoDBAttributeValue} attribute of DDB item
 * @return {number} DDB attribute size in bytes
 */
const calculateDynamoDBAttributeSize = (
  attribute: DynamoDBAttributeValue,
): number => {
  if (typeof attribute === 'string') {
    return Buffer.byteLength(attribute, 'utf8');
  } else if (typeof attribute === 'number') {
    return calculateNumberSizeInBytes(attribute);
  } else if (
    typeof attribute === 'boolean' ||
    attribute === null ||
    attribute === undefined
  ) {
    return 1; // Boolean or Null
  } else if (Array.isArray(attribute)) {
    return calculateArraySize(attribute);
  } else if (Buffer.isBuffer(attribute)) {
    return attribute.length;
  } else {
    return calculateMapSize(attribute);
  }
};

/**
 * Calculate size of a number attribute in bytes
 * @param {number} number attribute of DDB item
 * @return {number} number element size in bytes
 */
const calculateNumberSizeInBytes = (number: number): number => {
  const decimal = new Decimal(number);
  const fixedString = decimal.toFixed();
  return Buffer.byteLength(fixedString, 'utf8');
};

/**
 * Calculate size of an array attribute in bytes
 * @param {DynamoDBItem[]} array of DDB Item
 * @return {number} DDB Array size in bytes
 */
const calculateArraySize = (array: DynamoDBItem[]): number => {
  let size = 3; // size of empty list
  for (const element of array) {
    size += calculateDynamoDBAttributeSize(element);
    size++; // Extra 1 byte for each element
  }
  return size;
};

/**
 * Calculate size of an object (map) attribute in bytes
 * @param {DynamoDBItem} map DynamoDBItem
 * @return {number} DDB Map size in bytes
 */
const calculateMapSize = (map: DynamoDBItem): number => {
  let size = 3; // size of empty map
  for (const [key, value] of Object.entries(map)) {
    size += Buffer.byteLength(key, 'utf8');
    size += calculateDynamoDBAttributeSize(value);
    size++; // Extra 1 byte for each key-value pair
  }
  return size;
};
