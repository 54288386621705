import { CrudPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const OperatorUserDocumentPermissionName = {
  ...CrudPermissionName,
};
export const OperatorUserDocumentTemplatePermissionName = {
  ...CrudPermissionName,
};

export default {
  [ObjectType.DOCUMENT]: OperatorUserDocumentPermissionName,
  [ObjectType.DOCUMENT_TEMPLATE]: OperatorUserDocumentTemplatePermissionName,
};
