export enum AdCvdDisclaimerCode {
  /* Dimensions out of Scope */
  A = 'A',
  /* Material out of Scope */
  B = 'B',
  /* Product Function out of Scope */
  C = 'C',
  /* Specifically Excluded from Scope */
  D = 'D',
}

export const AdCvdDisclaimerCodeMap: Record<AdCvdDisclaimerCode, string> = {
  [AdCvdDisclaimerCode.A]: 'Dimensions out of Scope',
  [AdCvdDisclaimerCode.B]: 'Material out of Scope',
  [AdCvdDisclaimerCode.C]: 'Product Function out of Scope',
  [AdCvdDisclaimerCode.D]: 'Specifically Excluded from Scope',
};
