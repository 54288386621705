import {
  CustomerUserNotificationType,
  ForwarderUserNotificationType as ForwarderUserNotifications,
  UserNotificationType,
} from '@xbcb/notification-types';
import { UserNotificationMethod } from '../base';

export const ForwarderUserNotificationMethod = {
  ...UserNotificationMethod,
};
export const ForwarderUserNotificationType = {
  ...CustomerUserNotificationType,
  ...UserNotificationType,
  ...ForwarderUserNotifications,
};
