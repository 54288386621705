import { cloneJson } from './misc';

/**
 * Transforms the data, by applying the given transform function for the input path.
 *
 * @param {any} data the data to be transformed.
 * @param {string} path the path at which the transformation is to be applied. For objects use '.' delimiter, and '[]' when traversing a list. Sample path to modify value of c in this {a:1, b:[{c:2}, {c:3}]} would be 'a.b.[].c'
 * @param {any} fx the transform function to applied at the given path.
 * @param {boolean} strict Allow null or undefined (missing) values. The data would be returned unchanged. Defaults to false.
 * @return {any} the modified data.
 */
export const transform = (
  data: any,
  path: string,
  fx: (obj: any) => any,
  strict = false,
) => {
  if (!data) {
    throw new Error('Data to be transformed is missing');
  }

  // Clone before modifying the data.
  const clonedData = cloneJson(data);

  // If the transformation is to be applied at top level.
  if (['', '.'].includes(path)) {
    return fx(clonedData);
  }

  const pathArray = path.split('.');
  return recursiveTransform(clonedData, pathArray, 0, fx, strict);
};

const recursiveTransform = (
  data: any,
  pathArray: string[],
  index: number,
  fx: (obj: any) => any,
  strict: boolean,
): any => {
  // Path fully traversed. Apply the provided transform function.
  if (index === pathArray.length) {
    return fx(data);
  }

  // Path/element to be traversed next. (Doing this to optimize traversal).
  const element = pathArray[index];

  // Traverse array
  if (element === '[]' && data instanceof Array) {
    return data.map((p) =>
      recursiveTransform(p, pathArray, index + 1, fx, strict),
    );
  } else if (element !== '[]' && data[element]) {
    // If a key is next in path and it exists in data.
    return {
      ...data,
      // Only update the provided key. Spread the rest.
      [element]: recursiveTransform(
        data[element],
        pathArray,
        index + 1,
        fx,
        strict,
      ),
    };
  } else if (element !== '[]' && !data[element] && !strict) {
    // If undefined allowed (strict off), return unchanged data.
    return data;
  } else {
    // Strict ON, and next element not found. Throw error.
    throw new Error(`Key [${element}] not found. Path or the data is invalid`);
  }
};

export const asyncRecursiveTransform = async (
  data: any,
  pathArray: string[],
  index: number,
  fx: (obj: any) => any,
  strict: boolean,
): Promise<any> => {
  // Path fully traversed. Apply the provided transform function.
  if (index === pathArray.length) {
    return await fx(data);
  }

  // Path/element to be traversed next. (Doing this to optimize traversal).
  const element = pathArray[index];

  // Traverse array
  if (element === '[]' && data instanceof Array) {
    const newData = [];
    for (const dataElement of data) {
      const result = await asyncRecursiveTransform(
        dataElement,
        pathArray,
        index + 1,
        fx,
        strict,
      );
      newData.push(result);
    }
    return newData;
  } else if (element !== '[]' && data[element]) {
    // If a key is next in path and it exists in data.
    const result = await asyncRecursiveTransform(
      data[element],
      pathArray,
      index + 1,
      fx,
      strict,
    );
    return {
      ...data,
      // Only update the provided key. Spread the rest.
      [element]: result,
    };
  } else if (element !== '[]' && !data[element] && !strict) {
    // If undefined allowed (strict off), return unchanged data.
    return data;
  } else {
    // Strict ON, and next element not found. Throw error.
    throw new Error(`Key [${element}] not found. Path or the data is invalid`);
  }
};
