import {
  InvoiceRequestLine,
  UsConsumptionEntry,
} from '@xbcb/api-gateway-client';
import { ChargeCode } from '@xbcb/finance-types';
import log from '@xbcb/log';
import { getCurrency } from './getCurrency';

export const addPayableDutyFeeLine = (workOrder: UsConsumptionEntry) => {
  const paymentType = workOrder.paymentType;
  const taxes = workOrder.cbpCharges?.taxes || 0;
  const fees = workOrder.cbpCharges?.fees || 0;
  const duties = workOrder.cbpCharges?.duties || 0;
  const adDuties = workOrder.cbpCharges?.adDuties || 0;
  const cvDuties = workOrder.cbpCharges?.cvDuties || 0;
  const totalDutyFeeAmount = taxes + fees + duties + adDuties + cvDuties;

  const lineItems: InvoiceRequestLine[] = [];

  if (!paymentType) {
    log.info(`Payment type not found in work order id: ${workOrder.id}`, {
      workOrderId: workOrder.id,
    });
    return lineItems;
  }

  const payingDirect = ['1', '3', '7'].includes(paymentType);
  const pms = ['6', '7'].includes(paymentType);

  if (payingDirect) {
    log.info('Payer pays duty direct to cbp in work order', {
      workOrderId: workOrder.id,
    });
    return lineItems;
  }

  if (totalDutyFeeAmount) {
    const item = {
      type: (pms ? ChargeCode.DUTY_PMS : ChargeCode.DUTY_DAILY) as any,
      amount: {
        currency: getCurrency(),
        value: totalDutyFeeAmount,
      },
      workOrder: {
        id: workOrder.id,
      },
    };
    lineItems.push(item);
  }

  log.info(`addPayableDutyFee for work order id: ${workOrder.id}`, {
    key: `addPayableDutyFee`,
    workOrderId: workOrder.id,
    payload: { dutyLineItem: lineItems },
  });

  return lineItems;
};
