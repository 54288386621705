import { SortOrder } from '@xbcb/shared-types';
import { EsDataType } from './enums';

export const operatorId = {
  name: 'Operator ID',
  type: EsDataType.KEYWORD,
  hidden: true,
};

export const expirationTime = {
  name: 'Expiration Time',
  type: EsDataType.TIME,
  hidden: true,
};

export const recordFields = (changes: { [key: string]: any } = {}) => {
  return {
    id: { name: 'ID', type: EsDataType.KEYWORD, hidden: true },
    version: { name: 'Version', type: EsDataType.INTEGER, hidden: true },
    recordType: { name: 'Type', type: EsDataType.KEYWORD, hidden: true }, // TODO will be unhidden for interface level searches
    tags: { name: 'Tags', type: EsDataType.KEYWORD, hidden: true },
    updatedTime: {
      name: 'Updated Time',
      type: EsDataType.TIME,
      ...(changes.updatedTime || {}),
    },
    deletedByName: {
      name: 'Deleted By Name',
      type: EsDataType.TEXT,
      ...(changes.deletedByName || {}),
    },
    deletedTime: {
      name: 'Deleted Time',
      type: EsDataType.TIME,
      ...(changes.deletedTime || {}),
    },
    deletedById: {
      name: 'Deleted By ID',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    createdTime: {
      name: 'Created Time',
      type: EsDataType.TIME,
      ...(changes.createdTime || {}),
    },
    createdById: {
      name: 'Created By ID',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    createdByName: {
      name: 'Created By Name',
      type: EsDataType.TEXT,
      ...(changes.createdByName || {}),
    },
  };
};

export const defaultTableSlice = {
  pageSize: 25,
  current: 1,
  sortOrder: SortOrder.DESC,
  sortField: 'createdTime',
  records: [],
  filters: {},
};
