import React, { Component } from 'react';
// import GA from 'react-ga';
import { recordUIEvent, reportError } from '@xbcb/ui-utils';
import { StyledH2 } from './styles';
import { UiStage } from '@xbcb/ui-types';

class ErrorBoundary extends Component {
  state = { hasError: false };

  componentDidCatch(error, info) {
    if (process.env.REACT_APP_STAGE === 'prod') {
      // GA.exception({
      //   description: error.toString(),
      //   fatal: true,
      // });
    }
    this.setState({ hasError: true, message: error.toString() });
    recordUIEvent('main_error_boundary_hit', {
      info,
      message: error.toString(),
      fullUrl: window.location.toString(),
    });
    reportError(error);
  }

  render() {
    const { hideOnError, children } = this.props;
    if (this.state.hasError) {
      if (hideOnError) return null;
      return (
        <>
          <h1>Sorry, something went wrong.</h1>
          <StyledH2>Our team has been notified.</StyledH2>
          {process.env.REACT_APP_STAGE !== UiStage.PROD &&
            this.state.message && <p>{this.state.message}</p>}
        </>
      );
    } else {
      return children;
    }
  }
}

export default ErrorBoundary;
