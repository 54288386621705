import { CrudPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const OperatorUserProductPermissionName = {
  ...CrudPermissionName,
};

export default {
  [ObjectType.PRODUCT]: OperatorUserProductPermissionName,
};
