import { AccountType } from '@xbcb/shared-types';
import { EsDataType } from '../../index';
import { createExternalSystemTableConfig } from './externalSystem';

export const forwarderSystem = createExternalSystemTableConfig({
  columns: {
    forwarderId: {
      name: 'Forwarder ID',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    forwarderName: {
      default: true,
      name: 'Forwarder',
      type: EsDataType.TEXT,
      whitelist: [AccountType.OPERATOR],
    },
  },
});
