// TODO remove once we have auto generated types
export const documentInputFields = [
  'tags',
  'contentType',
  'size',
  'sha1',
  'antivirusScanStatus',
  'passwordStatus',
  'documentTags',
];
