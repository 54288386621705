import { message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { v4 as uuidv4 } from 'uuid';
import { documentFragments } from '@xbcb/document-queries';
import { RecordType, Tag } from '@xbcb/shared-types';
import { mutateRecord } from '@xbcb/apollo-client';
import { SearchQuery, createSearchQueryVariables } from '@xbcb/shared-queries';
import { MessageBundle } from '@xbcb/ui-types';
import { reportError, fileMetadata, safeGetMessage } from '@xbcb/ui-utils';
import { DocumentTag } from '@xbcb/document-types';

export const createDocuments = async ({
  createDocument,
  files,
  operatorId,
  searchDeletedDocuments,
  tagsForNewDocuments,
  documentTags,
  searchQueryTags,
  documentsPageBundle,
}: {
  createDocument: any;
  files: RcFile[];
  operatorId: string;
  searchDeletedDocuments?: boolean;
  documentTags?: DocumentTag[];
  tagsForNewDocuments: Tag[];
  searchQueryTags: Tag[];
  documentsPageBundle: MessageBundle;
}) => {
  const fileCount = files.length;
  const documents = [];
  if (fileCount > 10) {
    message.error(
      safeGetMessage(documentsPageBundle, 'exceed_upload_max'),
      5.0,
    );
    return;
  }
  for (let f = 0; f < fileCount; f++) {
    const file = files[f];
    if (file.size > 20000000) {
      message.error(
        safeGetMessage(documentsPageBundle, 'exceed_upload_size', {
          fileName: file.name,
        }),
        5.0,
      );
      return;
    }
    documents.push(fileMetadata(file));
  }
  const mutationResponsePromises = [];
  const optimisticTags = tagsForNewDocuments.map((tag) => ({
    ...tag,
    __typename: 'Tag',
  }));
  const searchQuery = SearchQuery({
    recordName: RecordType.DOCUMENT,
    fields: '...documentFields',
    fragments: documentFragments,
  });
  const searchQueryVariables = createSearchQueryVariables({
    deletedTimeExists: searchDeletedDocuments,
    operatorId,
    tags: searchQueryTags,
    sortOptions: [
      {
        field: 'createdTime',
      },
    ],
  });
  try {
    for (let d = 0; d < files.length; d++) {
      const document = documents[d];
      mutationResponsePromises.push(
        mutateRecord({
          mutationType: 'create',
          mutation: createDocument,
          mutationVariables: {
            idempotencyKey: uuidv4(),
            input: {
              ...document,
              documentTags,
              tags: tagsForNewDocuments,
              operator: {
                id: operatorId,
              },
            },
          },
          overwriteOptimisticRecordFields: { tags: optimisticTags },
          optimisticUpdateParams: {
            gqlQueryString: searchQuery,
            updatedQueryType: 'search',
            variables: searchQueryVariables,
          },
          recordType: RecordType.DOCUMENT,
        }),
      );
    }
    const responses = await Promise.all(mutationResponsePromises);
    return responses;
  } catch (e) {
    reportError(e);
    message.error(
      safeGetMessage(documentsPageBundle, 'create_document_error'),
      5.0,
    );
    return;
  }
};
