import { AnyObject } from '@xbcb/shared-types';
import { S3Uploader } from '../s3Uploader';
import { RecordSaver, SavedRecordFieldsReference } from './recordSaver';

/**
 * A RecordSaver that uploads records to s3 using a given S3Uploader
 * @template {AnyObject} SelectedFields a type representing the fields to save off of FullRecord
 * @template {SelectedFields} FullRecord the type of the object for which this S3RecordSaver will save fields
 */
export class S3RecordSaver<
  SelectedFields extends AnyObject,
  FullRecord extends SelectedFields,
> extends RecordSaver<SelectedFields, FullRecord> {
  /** The S3Uploader used to save fields during saveFields */
  private s3Uploader: S3Uploader;
  /** An optional compression function to call before uploading the fields during saveFields */
  private compressionFunction: (fields: SelectedFields) => unknown;

  constructor(
    fieldsToSave: (keyof SelectedFields)[],
    s3Uploader: S3Uploader,
    compressionFunction: (fields: SelectedFields) => unknown = (fields) =>
      fields,
  ) {
    super(fieldsToSave);
    this.s3Uploader = s3Uploader;
    this.compressionFunction = compressionFunction;
  }

  /**
   * Saves the fields to s3 using the given S3Uploader
   * @param {SelectedFields} fields the fields to save to s3
   * @param {function(SelectedFields):unknown} compressionFunction an optional function to use to compress the fields before uploading them
   * @return {Promise<SavedRecordFieldsReference>} a reference to where the fields have been stored in s3
   */
  protected async saveFields(
    fields: SelectedFields,
  ): Promise<SavedRecordFieldsReference> {
    return this.s3Uploader.upload(this.compressionFunction(fields));
  }
}
