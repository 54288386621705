export const usEntrySharedFacilityFields = `{
  name
  address {
    ...addressFields
  }
  facility {
    id
    version
  }
} `;

export const usConsumptionEntryFacilityFields = `fragment usEntryFacilityFields on UsConsumptionEntryFacility ${usEntrySharedFacilityFields}`;

export const usType86EntryFacilityFields = `fragment usType86EntryFacilityFields on UsType86EntryFacility ${usEntrySharedFacilityFields}`;

export const lpcoFields = `
  number
  exemptionCode
  quantity
  date
  countryCode
  issuer
  issuerLocation
  regionDescription
  uomCode
`;

export const AphFields = `
  itemIdentity {
    qualifierCode
    value
  }
  politicalSubunitName
  aphProductCode
  productQualifierCode
  seedAnalysisCertificate {
    ${lpcoFields}
  }
  statementsPermit {
    ${lpcoFields}
  }
  phytosanitaryCertificate {
    ${lpcoFields}
  }
  healthCertificate {
    ${lpcoFields}
  }
  meatCertificate {
    ${lpcoFields}
  }
  treatmentCertificate {
    ${lpcoFields}
  }
  ircRegistryNumber {
    ${lpcoFields}
  }
  importationOfVeterinaryBiologicalProducts {
    ${lpcoFields}
  }
  foreignSiteCertificateOfInspection {
    ${lpcoFields}
  }
  soilPermit {
    ${lpcoFields}
  }
  postEntryQuarantinePermit {
    ${lpcoFields}
  }
  plantPestsPermit {
    ${lpcoFields}
  }
  timberPermit {
    ${lpcoFields}
  }
  transitPlantsPermit {
    ${lpcoFields}
  }
  cottonPermit {
    ${lpcoFields}
  }
  sugarcanePermit {
    ${lpcoFields}
  }
  nurseryPlantsPermit {
    ${lpcoFields}
  }
  cornPermit {
    ${lpcoFields}
  }
  ricePermit {
    ${lpcoFields}
  }
  fruitsAndVegetablesPermit {
    ${lpcoFields}
  }
  khapraBeetlePermit {
    ${lpcoFields}
  }
  plantsOfCanadianOriginPermit {
    ${lpcoFields}
  }
  prohibitedPlantForResearchPermit {
    ${lpcoFields}
  }
  protectedPlantPermit {
    ${lpcoFields}
  }
  veterinaryPermitToImportControlledMaterials {
    ${lpcoFields}
  }
  declarationOfImportation {
    ${lpcoFields}
  }
  liveAnimalsPermit {
    ${lpcoFields}
  }
  applicationForInspectionAndDipping {
    ${lpcoFields}
  }
  rabiesVaccinationCertifcate {
    ${lpcoFields}
  }
  dogsPermit {
    ${lpcoFields}
  }
  certificateOfOrigin {
    ${lpcoFields}
  }
  gmoPermit {
    ${lpcoFields}
  }
  brsNotification {
    ${lpcoFields}
  }
  noticeOfArrivalPermit {
    ${lpcoFields}
  }
  brsAcknowledgementLetter {
    ${lpcoFields}
  }
`;

export const usEntryPgaFields = `{
  agencyCode
  programCode
  processingCode
  disclaimerCode
  constituentElements {
    harvestCountryCode
    name
    isActiveIngredient
    quantity {
      value
      unit
    }
    scientificName
    percentageRecycled
    fwsDescriptionCode
  }
  isImageSubmitted
  isConfidentialInformation
  source {
    sourceCountryType
    countryCode
    geographicLocation
    processingStartDate
    processingEndDate
    processingTypeCode
    processingDescription
  }
  characteristics {
    qualifier
    code
    description
  }
  intendedUse
  intendedUseDescription
  tradeName
  categoryCode
  originCountryCode
  transshipmentCountryCode
  transitCountryCode
  inspection {
    status
    date
    cbpPortCode
    locationCode
  }
  fdaProductCode
  priorNotice {
    confirmationNumber
    conditionsNotApplicable
  }
  radiationDeclaration {
    declarationCode
    reasonCode
    complianceCode {
      type
      value
    }
    eprcAccessionNumber
    eprcAnnualReportAccessionNumber
  }
  refusalCountryCode
  shipmentCountryCode
  laboratoryTypeCode
  foodContainerDimensions {
    length {
      value
      unit
    }
    height {
      value
      unit
    }
    width {
      value
      unit
    }
    diameter {
      value
      unit
    }
  }
  drugForm
  drugRegistrationNumber
  drugListingNumber
  drugApplicationNumber
  investigationNewDrugApplicationNumber
  foodFacilityRegistration {
    number
    exemption
  }
  railCarNumbers
  foodCanningEstablishmentNumber
  scheduleIdentifierNumber
  foodVolume
  deviceForm
  devicePremarketNumber
  deviceDomesticManufacturer
  deviceForeignManufacturer
  deviceForeignExporter
  investigationalDeviceExemptionNumber
  deviceListingNumber
  electrode
  impactResistanceLensCertification
  lotNumber
  isTscaCompliant
  epaRegistrationNumber {
    ${lpcoFields}
  }
  epaProducerEstablishmentNumber {
    ${lpcoFields}
  }
  casNumber
  chemicalAbstractServiceNumbers
  pcNumber
  noticeOfArrival
  vehicle {
    model
    modelYear
    manufactureMonth
    serialNumbers
    vehicleIdentificationNumbers
  }
  engines {
    model
    modelYear
    manufactureMonth
    serialNumbers
  }
  vneImportCode
  vneIndustryCode
  vneBondExemptionNumber
  enginePowerRating {
    value
    unit
  }
  vneBondNumber
  vneExemptionNumber
  vneOtherExemptionRegulation
  packaging {
    value
    unit
  }
  performanceStandard
  epaTestGroup
  manufactureDateLocation
  globalProductClassificationBrickCode
  taxonomicSerialNumber
  unpscCommodityCode
  liveAnimalTags
  liveAnimalTagRanges {
    startTag
    endTag
  }
  band
  animalBrand
  microchip
  nmfsTagNumber
  rfid
  slaughterNumber
  officialAnimalNumber
  bouquetGroupingCode
  categoryTypeCode
  conformanceDeclaration
  iorIsVehicleManufacturer
  driveSide
  dotBond {
    amount
    serialNumber
    suretyCode
    qualifier
  }
  embassyCountryCode
  tireManufacturerCode
  glazingManufacturerCode
  permissionLetter {
    ${lpcoFields}
  }
  vehicleEligibilityNumber {
    ${lpcoFields}
  }
  registeredImporterNumber {
    ${lpcoFields}
  }
  passportNumber
  passportCountryCode
  foreignCertificate {
    ${lpcoFields}
  }
  certificateOfLabelApproval {
    numbers
    exemptionCode
  }
  ttbImporterPermitNumber {
    ${lpcoFields}
  }
  ttbFacilityPermitNumber {
    ${lpcoFields}
  }
  paperOrTubeQuantity
  weaponMeasurements {
    caliberGaugeSize
    barrelLength {
      value
      unit
    }
    overallLength {
      value
      unit
    }
  }
  atfCategory
  federalFirearmsLicense {
    ${lpcoFields}
  }
  federalExplosiveLicense {
    ${lpcoFields}
  }
  atfImportPermit {
    ${lpcoFields}
  }
  atfImporterRegistration {
    ${lpcoFields}
  }
  laboratory {
    ...usEntryFacilityFields
  }
  storageLocation {
    ...usEntryFacilityFields
  }
  lpcoAuthorizedParty {
    ...usEntryFacilityFields
  }
  permittedDestination {
    ...usEntryFacilityFields
  }
  cropGrower {
    name
    address {
      ...addressFields
    }
    mid
    supplier {
      id
      version
    }
  }
  foreignSupplierVerificationProgram {
    name
    address {
      ...addressFields
    }
    duns
    unknownDuns
    sameAsIor
    customsAgent {
      id
      version
    }
    pointOfContact {
      ...pointOfContactFields
    }
  }
  deviceInitialImporter {
    name
    address {
      ...addressFields
    }
    sameAsIor
    customsAgent {
      id
      version
    }
  }
  ${AphFields}
}`;

export const usType86EntryPgaFields = `{
  agencyCode
  programCode
  processingCode
  disclaimerCode
  constituentElements {
    harvestCountryCode
    name
    isActiveIngredient
    quantity {
      value
      unit
    }
    scientificName
    percentageRecycled
    fwsDescriptionCode
  }
  isImageSubmitted
  isConfidentialInformation
  source {
    sourceCountryType
    countryCode
    geographicLocation
    processingStartDate
    processingEndDate
    processingTypeCode
    processingDescription
  }
  characteristics {
    qualifier
    code
    description
  }
  intendedUse
  intendedUseDescription
  tradeName
  categoryCode
  originCountryCode
  transshipmentCountryCode
  transitCountryCode
  inspection {
    status
    date
    cbpPortCode
    locationCode
  }
  fdaProductCode
  priorNotice {
    confirmationNumber
    conditionsNotApplicable
  }
  radiationDeclaration {
    declarationCode
    reasonCode
    complianceCode {
      type
      value
    }
    eprcAccessionNumber
    eprcAnnualReportAccessionNumber
  }
  refusalCountryCode
  shipmentCountryCode
  laboratoryTypeCode
  foodContainerDimensions {
    length {
      value
      unit
    }
    height {
      value
      unit
    }
    width {
      value
      unit
    }
    diameter {
      value
      unit
    }
  }
  drugForm
  drugRegistrationNumber
  drugListingNumber
  drugApplicationNumber
  investigationNewDrugApplicationNumber
  foodFacilityRegistration {
    number
    exemption
  }
  railCarNumbers
  foodCanningEstablishmentNumber
  scheduleIdentifierNumber
  foodVolume
  deviceForm
  devicePremarketNumber
  deviceDomesticManufacturer
  deviceForeignManufacturer
  deviceForeignExporter
  investigationalDeviceExemptionNumber
  deviceListingNumber
  electrode
  impactResistanceLensCertification
  lotNumber
  isTscaCompliant
  epaRegistrationNumber {
    ${lpcoFields}
  }
  epaProducerEstablishmentNumber {
    ${lpcoFields}
  }
  casNumber
  chemicalAbstractServiceNumbers
  pcNumber
  noticeOfArrival
  vehicle {
    model
    modelYear
    manufactureMonth
    serialNumbers
    vehicleIdentificationNumbers
  }
  engines {
    model
    modelYear
    manufactureMonth
    serialNumbers
  }
  vneImportCode
  vneIndustryCode
  vneBondExemptionNumber
  enginePowerRating {
    value
    unit
  }
  vneBondNumber
  vneExemptionNumber
  vneOtherExemptionRegulation
  packaging {
    value
    unit
  }
  performanceStandard
  epaTestGroup
  manufactureDateLocation
  globalProductClassificationBrickCode
  taxonomicSerialNumber
  unpscCommodityCode
  liveAnimalTags
  liveAnimalTagRanges {
    startTag
    endTag
  }
  band
  animalBrand
  microchip
  nmfsTagNumber
  rfid
  slaughterNumber
  officialAnimalNumber
  bouquetGroupingCode
  categoryTypeCode
  conformanceDeclaration
  iorIsVehicleManufacturer
  driveSide
  dotBond {
    amount
    serialNumber
    suretyCode
    qualifier
  }
  embassyCountryCode
  tireManufacturerCode
  glazingManufacturerCode
  permissionLetter {
    ${lpcoFields}
  }
  vehicleEligibilityNumber {
    ${lpcoFields}
  }
  registeredImporterNumber {
    ${lpcoFields}
  }
  passportNumber
  passportCountryCode
  foreignCertificate {
    ${lpcoFields}
  }
  certificateOfLabelApproval {
    numbers
    exemptionCode
  }
  ttbImporterPermitNumber {
    ${lpcoFields}
  }
  ttbFacilityPermitNumber {
    ${lpcoFields}
  }
  paperOrTubeQuantity
  weaponMeasurements {
    caliberGaugeSize
    barrelLength {
      value
      unit
    }
    overallLength {
      value
      unit
    }
  }
  atfCategory
  federalFirearmsLicense {
    ${lpcoFields}
  }
  federalExplosiveLicense {
    ${lpcoFields}
  }
  atfImportPermit {
    ${lpcoFields}
  }
  atfImporterRegistration {
    ${lpcoFields}
  }
  laboratory {
    ...usType86EntryFacilityFields
  }
  storageLocation {
    ...usType86EntryFacilityFields
  }
  lpcoAuthorizedParty {
    ...usType86EntryFacilityFields
  }
  permittedDestination {
    ...usType86EntryFacilityFields
  }
  cropGrower {
    name
    address {
      ...addressFields
    }
    mid
    supplier {
      id
      version
    }
  }
  foreignSupplierVerificationProgram {
    name
    address {
      ...addressFields
    }
    duns
    unknownDuns
    sameAsIor
    customsAgent {
      id
      version
    }
    pointOfContact {
      ...pointOfContactFields
    }
  }
  deviceInitialImporter {
    name
    address {
      ...addressFields
    }
    sameAsIor
    customsAgent {
      id
      version
    }
  }
  ${AphFields}
}`;

export const usConsumptionEntryPgaFragment = `fragment usConsumptionEntryPgaFields on UsConsumptionEntryPga ${usEntryPgaFields}`;

export const usType86EntryPgaFragment = `fragment usType86EntryPgaFields on UsType86EntryPga ${usType86EntryPgaFields}`;

const pgaFlags = [
  'TB1',
  'TB2',
  'TB3',
  'AQ1',
  'AQ2',
  'AL1',
  'AL2',
  'ATF',
  'FD1',
  'FD2',
  'FD3',
  'FD4',
  'EP1',
  'EP2',
  'EP3',
  'EP4',
  'EP5',
  'EP6',
  'EP7',
  'EP8',
  'DT1',
  'DT2',
  'FW1',
  'FW2',
  'FW3',
  'DE1',
  'FS3',
  'FS4',
  'NM1',
  'NM2',
  'NM3',
  'NM4',
  'NM5',
  'NM6',
  'NM8',
  'AM1',
  'AM2',
  'AM3',
  'AM4',
  'AM6',
  'AM7',
  'AM8',
  'OM1',
  'OM2',
];

const VehiclesAndEnginesFields = `{
  agencyCode
  programCode
  disclaimerCode
  isImageSubmitted
  regulatoryCategory
  vehicle {
    model
    modelYear
    manufactureMonth
    serialNumbers
    vehicleIdentificationNumbers
  }
  engines {
    manufactureMonth
    model
    modelYear
    serialNumbers
  }
  vneImportCode
  vneIndustryCode
  vneOtherExemptionRegulation
  vneBondExemptionNumber
  vneBondNumber
  epaTestGroup
  enginePowerRating {
    unit
    value
  }
}`;

const PgaFields: Map<string, string> = new Map([
  ['EP3', VehiclesAndEnginesFields],
  ['EP4', VehiclesAndEnginesFields],
]);

type GeneratePgaFieldsProps = {
  defaultFields: string;
};

export const generatePgaFields = ({
  defaultFields,
}: GeneratePgaFieldsProps): string =>
  pgaFlags
    .map((flag) => {
      const fields = PgaFields.get(flag) ?? defaultFields;
      return flag + ' ' + fields;
    })
    .join('\n');

export const pgaRequirements = generatePgaFields({
  defaultFields: '{ ...usConsumptionEntryPgaFields }',
});

export const type86PgaRequirements = generatePgaFields({
  defaultFields: '{...usType86EntryPgaFields }',
});

export const pgaRequirementFragments = `fragment pgaRequirementFields on UsConsumptionEntryPgaRequirements {
  ${pgaRequirements}
}
${usConsumptionEntryFacilityFields}
${usConsumptionEntryPgaFragment}
`;

export const type86PgaRequirementFragments = `fragment type86PgaRequirementFields on UsType86EntryPgaRequirements {
  ${type86PgaRequirements}
}
${usType86EntryFacilityFields}
${usType86EntryPgaFragment}
`;
