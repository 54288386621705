import moment from 'moment';

const formatDate = (date: moment.Moment): string => date.format('YYYY-MM-DD');
const ROANOKE_BOND_RENEWAL_SCHEDULE = [1, 'year'];

export const getNextBondRenewalDateFromEffectiveDate = (
  effectiveDateStr: string,
): string => {
  const now = moment().startOf('day');
  const effectiveDate = moment(effectiveDateStr).startOf('day');

  if (effectiveDate.isAfter(now))
    return formatDate(effectiveDate.add(...ROANOKE_BOND_RENEWAL_SCHEDULE));

  const currentYear = now.year();
  const thisCalendarYearRenewal = effectiveDate.set('year', currentYear);
  return thisCalendarYearRenewal.isAfter(now)
    ? formatDate(thisCalendarYearRenewal)
    : formatDate(thisCalendarYearRenewal.add(...ROANOKE_BOND_RENEWAL_SCHEDULE));
};
