export const sharedEuCustomsEntryLineFields = `
  origin {
    countryCode
    stateCode
  }
  manufacturer {
    name
    address {
      ...addressFields
    }
    supplier {
      id
      version
    }
  }
  tariffs {
    unitReportingQuantities {
      value
      unit
    }
    unitValue {
      value
      currency
    }
    unitAssist {
      value
      currency
    }
    htsNumber
    hts {
      id
    }
  }
  description
  value {
    value
    currency
  }
  assist {
    value
    currency
  }
  externalReference {
    ...externalComplianceReferenceFields
  }
  grossWeight {
    value
    unit
  }
`;
