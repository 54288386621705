import {
  embeddedRecordFragment,
  embeddedRecordFragments,
  recordFields,
  recordActionFields,
} from '@xbcb/shared-queries';

export const workOrderTaskStepFragment = `fragment workOrderTaskStepFields on WorkOrderTaskStep {
  ...embeddedRecordFields
  name
  description
  completed {
    ...recordActionFields
  }
}
`;

export const workOrderTaskEscalationFragment = `fragment workOrderTaskEscalationsFields on WorkOrderTaskEscalation {
  escalationDetails {
    details
    escalatedTo {
      id
      name
    }
    escalatedBy {
      id
      name
    }
    escalationType
    escalationReasons {
      reasonCategory
      reasonSubCategory
    }
    escalatedTime
  }
  resolutionDetails {
    details
    resolutionReasons
    resolutionTime
    resolvedBy {
      id
      name
    }
  }
  replyDetails {
    details
    replyTime
    repliedBy {
      name
      id
    }
  }
}`;

export const assignmentTeamFragment = `fragment assignmentTeamFields on AssignmentTeam {
  id
  subjectMatterExpertTeam {
    id
    operatorUsers {
      edges {
        relationship {
          expertiseCategories {
            category
            subCategories
          }
        }
        node {
          id
          name
          away
        }
      }
    }
  }
  operatorUsers {
    edges
    {
      node
      {
        id
        name
        away
      } 
      relationship {
        roles
        groups {
          groupNumber
          roles
        }
      }
    }
  }
}`;

export const workOrderTaskStepFragments = `
  ${workOrderTaskStepFragment}
  ${embeddedRecordFragments}
`;

export const workOrderTaskFragment = `fragment workOrderTaskFields on WorkOrderTask {
  ...recordFields
  name
  workOrderType
  status
  subStatus
  statusReason
  assignee {
    id
  }
  definition {
    isException
  }
  assignmentTeam {
    ...assignmentTeamFields
  }
  escalations {
    ...workOrderTaskEscalationsFields
  }
  deadline
  steps {
    ...workOrderTaskStepFields
  }
  completed {
    time
  }
}
`;

const commonWorkOrderTaskFragments = `
${workOrderTaskStepFragment}
${embeddedRecordFragment}
${recordFields}
${recordActionFields}
`;

export const workOrderTaskFragments = `
${commonWorkOrderTaskFragments}
${workOrderTaskFragment}
${workOrderTaskEscalationFragment}
${assignmentTeamFragment}
`;

export const subscriptionWorkOrderTaskFragment = `fragment workOrderTaskFields on WorkOrderTask {
  ...recordFields
  name
  workOrderType
  status
  statusReason
  assignee {
    id
  }
  definition {
    isException
  }
  escalations {
    escalationDetails {
      details
      escalationType
      escalatedTo {
        id
      }
      escalatedBy {
        id
      }
      escalationReasons {
        reasonCategory
        reasonSubCategory
      }
      escalatedTime
    }
    resolutionDetails {
      details
      resolutionReasons
      resolutionTime
      resolvedBy {
        id
      }
    }
  }
  deadline
  steps {
    ...workOrderTaskStepFields
  }
}
`;

export const subscriptionWorkOrderTaskFragments = `
${commonWorkOrderTaskFragments}
${subscriptionWorkOrderTaskFragment}
`;
