export const UsDrawbackExportDestroyIndicatorDescription =
  'Indicates imports subsequantly destroyed or exported.';
export enum UsDrawbackExportDestroyIndicator {
  DESTROYED = 'DESTROYED',
  EXPORTED = 'EXPORTED',
}

export const UsDrawbackActionIndicatorDescription =
  'Indicates action covered by drawback claim';
export enum UsDrawbackActionIndicator {
  DESTROYED = 'DESTROYED',
  LADEN_AS_SUPPLIES = 'LADEN AS SUPPLIES',
  MAIL_SHIPMENT = 'MAIL SHIPMENT',
  GOVERNMENT_MAIL = 'GOVERNMENT MAIL',
  VESSEL_OR_AIRCRAFTS = 'VESSEL OR AIRCRAFTS',
  EXPORTED = 'EXPORTED',
  MANUFACTURED = 'MANUFACTURED',
  TRANSPORT = 'MANUFACTURE AND TRANSPORT OR TRANSPORT ONLY',
  FTZ = 'EXPORTED TO FTZ',
}

export const usCbpDrawbackClaimStatusDescription =
  'Status of the Drawback Claim (DE) filing with CBP.';
export enum UsCbpDrawbackClaimStatus {
  SUBMITTED = 'SUBMITTED',
  FILED = 'FILED',
  REJECTED = 'REJECTED',
}
