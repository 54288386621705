import { AccountType } from '@xbcb/shared-types';
import { EsDataType } from '../../index';
import { createUserTableConfig } from './user';

export const shipperUser = createUserTableConfig({
  columns: {
    // ...userColumns,
    shipperId: {
      name: 'Shipper ID',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    shipperName: {
      default: true,
      name: 'Shipper',
      type: EsDataType.TEXT,
      whitelist: [AccountType.OPERATOR],
    },
  },
});
