import {
  userFragments,
  shipperUserPermissionFields,
  userNotificationFields,
} from './user';

const shipperUserFragment = `
fragment shipperUserFields on ShipperUser {
  shipper {
    id
    billingDetails {
      subscriptionPlan
    }
  }
  ${shipperUserPermissionFields}
  ${userNotificationFields}
  ...userFields
  ...recordFields
}
`;

export const shipperUserFragments = `
  ${shipperUserFragment}
  ${userFragments}
`;
