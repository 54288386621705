import { ModeOfTransport } from '@xbcb/shared-types';
import { EsDataType } from '../../index';

export const sharedUsEntrySummaryColumns = {
  modeOfTransport: {
    default: true,
    name: 'Mode of Transportation',
    type: EsDataType.KEYWORD,
    react: true,
    optionValues: Object.values(ModeOfTransport),
  },
  conveyanceName: {
    name: 'Conveyance Name',
    type: EsDataType.TEXT,
  },
  exportDate: {
    name: 'Export Date',
    type: EsDataType.TIME,
  },
  brokerNumber: {
    name: 'Broker Number',
    type: EsDataType.KEYWORD,
  },
  exportCountryCode: {
    name: 'Export Country Code',
    type: EsDataType.KEYWORD,

    // TODO add format
  },
  exportCountryName: {
    name: 'Export Country',
    type: EsDataType.TEXT,
  },
  importDate: {
    name: 'Import Date',
    type: EsDataType.TIME,
  },
  portOfUnladingName: {
    name: 'Port of Unlading',
    type: EsDataType.KEYWORD,
  },
  portOfLadingName: {
    name: 'Origin',
    type: EsDataType.KEYWORD,
  },
  tripNumber: {
    name: 'Voyage #',
    type: EsDataType.KEYWORD,
  },
  summaryStatus: {
    name: 'Summary Status',
    type: EsDataType.KEYWORD,
  },
  entryNumber: {
    name: 'Entry #',
    type: EsDataType.TEXT,
    analyzer: 'whitespace',
  },
  entryType: {
    name: 'Entry Type',
    type: EsDataType.KEYWORD,
  },
  inBondNumber: {
    // Not sure if this is correct
    name: 'IT In-Bond',
    type: EsDataType.KEYWORD,
  },
  invoiceNumber: {
    name: 'Commercial Invoice #',
    type: EsDataType.KEYWORD,
  },
  masterBill: {
    default: true,
    name: 'Master Bill #',
    type: EsDataType.TEXT,
  },
  houseBill: {
    default: true,
    name: 'House Bill #',
    type: EsDataType.TEXT,
  },
  iorName: { name: 'IOR Name', type: EsDataType.TEXT },
};
