import { partyFragments } from './party';

const nlCustomsBrokerFragment = `
fragment nlCustomsBrokerFields on NlCustomsBroker {
  ...recordFields
  ...partyFields
  entityName
  name
  eori
}
`;

export const nlCustomsBrokerFragments = `
${nlCustomsBrokerFragment}
${partyFragments}
`;
