// Converts string to a number, boolean, or string.
// eg:
//   '1'    -> 1
//   'True' -> true
//   'test' -> 'test'
export const stringToNativeType = (
  value: string,
): number | boolean | string => {
  if (!isNaN(Number(value))) {
    return Number(value);
  }
  const lowerCaseVal = value.toLowerCase();
  if (lowerCaseVal === 'true') {
    return true;
  }
  if (lowerCaseVal === 'false') {
    return false;
  }
  return value;
};
