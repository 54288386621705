import React from 'react';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

type InfoTooltipProps = {
  className?: string;
  title: string | React.ReactNode;
  placement?: TooltipPlacement;
  overlayClassName?: string;
};

const InfoTooltip = ({
  className = '',
  title,
  placement = 'right',
  overlayClassName,
}: InfoTooltipProps) => (
  <Tooltip
    className={`info-tooltip ${className}`}
    placement={placement}
    title={title}
    overlayClassName={overlayClassName}
  >
    <InfoCircleOutlined />
  </Tooltip>
);

export default InfoTooltip;
