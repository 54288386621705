import { PortCode, PortCodeInput } from '@xbcb/api-gateway-client';
import {
  usCbpPortCodeMapping,
  usCbpForeignPortCodeMapping,
} from '@xbcb/codes/dist/portCodeMapping';
import log from '@xbcb/log';
import { PortCodeType } from '@xbcb/shipment-types';

export const getPortOfUnladingFromPortCode = (
  portCode?: PortCode,
  arrivalCountry?: string,
) => {
  if (!portCode) return;
  const { type, value } = portCode;
  let portOfUnlading;
  if (arrivalCountry && arrivalCountry !== 'US') {
    portOfUnlading = { type, value } as PortCodeInput;
  } else if (type === PortCodeType.CBP) {
    portOfUnlading = value;
  } else if (type === PortCodeType.IATA || type === PortCodeType.UNLOCODE) {
    portOfUnlading = usCbpPortCodeMapping[type][value];
  } else {
    log.error(
      `Unsupported PortCodeType found: ${type} when trying to get portOfUnlading from portCode`,
      { key: 'unsupportedPortCodeType', params: { type, value } },
    );
  }
  return portOfUnlading;
};

export const getPortOfLadingFromPortCode = (
  portCode?: PortCode,
  arrivalCountry?: string,
) => {
  if (!portCode) return;
  const { type, value } = portCode;
  let portOfLading;
  if (arrivalCountry && arrivalCountry !== 'US') {
    portOfLading = { type, value } as PortCodeInput;
  } else if (type === PortCodeType.CBP) {
    portOfLading = value;
  } else if (type === PortCodeType.IATA || type === PortCodeType.UNLOCODE) {
    portOfLading = usCbpForeignPortCodeMapping[type][value];
  } else {
    log.error(
      `Unsupported PortCodeType found: ${type} when trying to get portOfLading from portCode`,
      { key: 'unsupportedPortCodeType', params: { type, value } },
    );
  }
  return portOfLading;
};
