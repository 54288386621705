export enum LogicalOperator {
  GREATER_THAN = 'GREATER_THAN',
  GREATER_THAN_EQUALS = 'GREATER_THAN_EQUALS',
  LESS_THAN = 'LESS_THAN',
  LESS_THAN_EQUALS = 'LESS_THAN_EQUALS',
  EQUALS = 'EQUALS',
}

export enum BooleanOperator {
  AND = 'AND',
  OR = 'OR',
}
