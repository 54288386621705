import { createShipmentWorkOrderRecord } from './utils';
import { EsDataType } from '../../enums';
import { WorkOrderMilestoneName } from '@xbcb/work-order-types';

export const deliveryOrder = createShipmentWorkOrderRecord({
  columns: {
    truckerId: {
      hidden: true,
      default: true,
      name: 'Trucker ID',
      type: EsDataType.KEYWORD,
    },
    truckerName: {
      default: true,
      name: 'Trucker Name',
      type: EsDataType.TEXT,
    },
    dispatchEmail: {
      default: true,
      name: 'Dispatch Email',
      type: EsDataType.TEXT,
    },
    dispatchPhone: {
      name: 'Dispatch Phone',
      type: EsDataType.TEXT,
    },
    pickupLocation: {
      name: 'Pickup Location address',
      type: EsDataType.KEYWORD,
    },
    deliveryLocation: {
      name: 'Delivery Location',
      type: EsDataType.KEYWORD,
    },
    pickupNumber: {
      default: true,
      name: 'Pickup #',
      type: EsDataType.TEXT,
    },
    latestMilestone: {
      name: 'Latest Milestone',
      type: EsDataType.KEYWORD,
      default: true,
      optionValues: Object.keys(WorkOrderMilestoneName),
    },
    iorNumber: {
      default: true,
      name: 'IOR #',
      type: EsDataType.TEXT,
    },
    masterBill: {
      default: true,
      name: 'Master Bill #',
      type: EsDataType.TEXT,
    },
    houseBill: {
      default: true,
      name: 'House Bill #',
      type: EsDataType.TEXT,
    },
    eta: {
      name: 'ETA',
      type: EsDataType.TIME,
    },
    examSite: {
      name: 'Exam Site',
      type: EsDataType.KEYWORD,

      // TODO add format
    },
    preferredExamSite: {
      name: 'Preferred Exam Site',
      type: EsDataType.KEYWORD,
    },
    importDate: {
      name: 'Import Date',
      type: EsDataType.TIME,
    },
    entryNumber: {
      name: 'Entry #',
      type: EsDataType.TEXT,
      default: true,
      analyzer: 'keyword',
    },
    entryStatus: {
      name: 'Entry Status',
      type: EsDataType.KEYWORD,
      default: true,
    },
    iorName: { name: 'IOR Name', type: EsDataType.TEXT },
  },
});
