import { get } from 'lodash';
import { createReducer } from '@reduxjs/toolkit';
import { AnyObject } from '@xbcb/shared-types';
import { putRecord } from '../actions';

const defaultRecord: AnyObject = {};

export const recordsReducer = createReducer(defaultRecord, (builder) =>
  builder.addCase(putRecord, (state, { payload }) => {
    const { id, ...rest } = payload;
    const existingState = get(state, [id], {});
    return {
      ...state,
      [id]: {
        ...existingState,
        ...rest,
      },
    };
  }),
);
