import { businessTime, stripTypeNames } from '@xbcb/js-utils';
import moment from 'moment';
import {
  WorkOrderType,
  UsConsumptionEntryBadge,
  UsInBondBadge,
  WorkOrderBadge,
} from '@xbcb/work-order-types';
import { ModeOfTransport } from '@xbcb/shipment-types';
import { getWorkOrderConfiguration } from '../getWorkOrderConfiguration';
import { camelCase } from 'change-case';
import log from '@xbcb/log';

// If the work order config is not present on the customer then use these
const defaultWorkOrderSLA = {
  [WorkOrderType.UsConsumptionEntry]: [
    {
      calendarDaysBeforeArrival: 5,
      matchingCriteria: {
        modesOfTransport: [ModeOfTransport.OCEAN],
      },
    },
    {
      businessHoursAfterReady: 36,
      matchingCriteria: {
        modesOfTransport: [ModeOfTransport.OCEAN, ModeOfTransport.RAIL],
      },
    },
    {
      businessHoursAfterReady: 5,
      matchingCriteria: {
        modesOfTransport: [ModeOfTransport.AIR, ModeOfTransport.TRUCK],
      },
    },
  ],
  [WorkOrderType.UsIsf]: [
    {
      businessHoursAfterReady: 5,
    },
  ],
  [WorkOrderType.UsIorActivation]: [
    {
      businessHoursAfterReady: 5,
    },
  ],
  [WorkOrderType.GbIorActivation]: [
    {
      businessHoursAfterReady: 72,
    },
  ],
  [WorkOrderType.NlIorActivation]: [
    {
      businessHoursAfterReady: 72,
    },
  ],
  [WorkOrderType.FrIorActivation]: [
    {
      businessHoursAfterReady: 72,
    },
  ],
  [WorkOrderType.DeIorActivation]: [
    {
      businessHoursAfterReady: 72,
    },
  ],
  [WorkOrderType.UsIorContinuousBondRequest]: [
    {
      businessHoursAfterReady: 12,
    },
  ],
  [WorkOrderType.UsInBond]: [
    {
      businessHoursAfterReady: 12,
    },
  ],
  [WorkOrderType.UsType86Entry]: [
    {
      businessHoursAfterReady: 5,
      matchingCriteria: {
        modesOfTransport: [ModeOfTransport.AIR],
      },
    },
  ],
};

export type IsfServiceLevelAgreement = {
  businessHoursAfterReady: number;
  matchingCriteria?: any;
};

export type UsConsumptionEntryServiceLevelAgreementMatchingCriteria = {
  modesOfTransport?: ModeOfTransport[];
  badges?: UsConsumptionEntryBadge[];
};

export type EuCustomsEntryServiceLevelAgreementMatchingCriteria = {
  modesOfTransport?: ModeOfTransport[];
  badges?: WorkOrderBadge[];
};

export type UsType86EntryServiceLevelAgreementMatchingCriteria = {
  modesOfTransport?: ModeOfTransport[];
};

export type UsConsumptionEntryServiceLevelAgreement = {
  businessHoursAfterReady?: number;
  calendarDaysBeforeArrival?: number;
  matchingCriteria?: UsConsumptionEntryServiceLevelAgreementMatchingCriteria;
};

export type EuCustomsEntryServiceLevelAgreement = {
  businessHoursAfterReady?: number;
  calendarDaysBeforeArrival?: number;
  matchingCriteria?: EuCustomsEntryServiceLevelAgreementMatchingCriteria;
};

export type UsType86EntryServiceLevelAgreement = {
  businessHoursAfterReady?: number;
  calendarDaysBeforeArrival?: number;
  matchingCriteria?: UsType86EntryServiceLevelAgreementMatchingCriteria;
};

export type UsInBondServiceLevelAgreementMatchingCriteria = {
  modesOfTransport?: ModeOfTransport[];
  badges?: UsInBondBadge[];
};

export type UsInBondServiceLevelAgreement = {
  businessHoursAfterReady?: number;
  calendarDaysBeforeArrival?: number;
  matchingCriteria?: UsInBondServiceLevelAgreementMatchingCriteria;
};

export type UsIsfConfig = {
  autoSetReadyStatus?: boolean;
  serviceLevelAgreements?: IsfServiceLevelAgreement[];
};

export type UsConsumptionEntryConfig = {
  autoSetReadyStatus?: boolean;
  serviceLevelAgreements?: UsConsumptionEntryServiceLevelAgreement[];
};

export type EuCustomsEntryConfig = {
  autoSetReadyStatus?: boolean;
  serviceLevelAgreements?: EuCustomsEntryServiceLevelAgreement[];
};

export type UsInBondConfig = {
  autoSetReadyStatus?: boolean;
  serviceLevelAgreements?: UsInBondServiceLevelAgreement[];
};

export type UsType86EntryConfig = {
  autoSetReadyStatus?: boolean;
  serviceLevelAgreements?: UsType86EntryServiceLevelAgreement[];
};

export type WorkOrderConfig = {
  [WorkOrderType.UsIsf]?: UsIsfConfig;
  [WorkOrderType.UsConsumptionEntry]?: UsConsumptionEntryConfig;
  [WorkOrderType.UsInBond]?: UsInBondConfig;
  [WorkOrderType.DeCustomsEntry]?: EuCustomsEntryConfig;
  [WorkOrderType.GbCustomsEntry]?: EuCustomsEntryConfig;
  [WorkOrderType.NlCustomsEntry]?: EuCustomsEntryConfig;
  [WorkOrderType.FrCustomsEntry]?: EuCustomsEntryConfig;
  [WorkOrderType.UsType86Entry]?: UsType86EntryConfig;
};

export type ServiceLevelAgreementTypes =
  | IsfServiceLevelAgreement
  | UsConsumptionEntryServiceLevelAgreement
  | UsInBondServiceLevelAgreement
  | EuCustomsEntryServiceLevelAgreement
  | UsType86EntryServiceLevelAgreement;

export type ServiceLevelAgreementTypesWithBadges =
  | UsConsumptionEntryServiceLevelAgreement
  | UsInBondServiceLevelAgreement;

export type WorkOrderBadgeTypes = WorkOrderBadge | UsConsumptionEntryBadge;

export type GetWorkOrderDeadlineProps = {
  modeOfTransport?: ModeOfTransport;
  arrivalDate?: string;
  // Hot work order request time is prioritized over the work order ready time
  deadlineCalculationTime?: string;
  shipper?: any;
  forwarders?: any[];
  workOrderType: WorkOrderType;
  badges?: WorkOrderBadgeTypes[];
};

export const addHighPrioritySlaToSlaList = (
  slaToBeAdded: ServiceLevelAgreementTypesWithBadges,
  slaList?: ServiceLevelAgreementTypes[],
): void => {
  /* If the existing configuration returned by party authority already has hot SLA config, do not add the default. Otherwise add it */
  const isMatchingConfigPresent = slaList?.find((sla) =>
    slaToBeAdded.matchingCriteria?.badges?.every((badge) =>
      (
        sla.matchingCriteria as typeof slaToBeAdded.matchingCriteria
      )?.badges?.includes(badge),
    ),
  );

  /* Hot SLA not found in the config so adding it as the first element because the current implementation extracts the businessHoursAfterReady from the first matching SLA */
  if (!isMatchingConfigPresent) {
    slaList?.unshift(slaToBeAdded);
  } else {
    log.debug(
      `Ignore adding high priority SLA to SLA list. slaToBeAdded: ${JSON.stringify(
        slaToBeAdded,
      )} is already present in slaList : ${JSON.stringify(slaList)}`,
    );
  }
};

const isMatchingModesOfTransport = (
  matchingCriterionValue: any,
  modeOfTransport?: ModeOfTransport,
) => matchingCriterionValue.includes(modeOfTransport);

const isMatchingBadges = (
  matchingCriterionValue: any,
  badges?: WorkOrderBadgeTypes[],
) =>
  matchingCriterionValue.every((badge: WorkOrderBadgeTypes) =>
    badges?.includes(badge),
  );

export const getWorkOrderDeadline = ({
  modeOfTransport,
  arrivalDate,
  deadlineCalculationTime,
  forwarders,
  shipper,
  workOrderType,
  badges,
}: GetWorkOrderDeadlineProps): string => {
  const defaultDeadline = businessTime
    .add(moment.utc(), 12)
    .startOf('hour')
    .toISOString();
  const defaultWoDeadlineLogMsg = `Using the default SLA of 1 day (12 business hours) SLA`;
  const logParams = {
    params: {
      modeOfTransport,
      arrivalDate,
      deadlineCalculationTime,
      shipperId: shipper?.id,
      forwarderIds: forwarders?.map((forwarder) => forwarder.id).join(),
      workOrderType,
      badges,
    },
  };

  // IOR activation work order SLA is constant as specified in the map above regardless of other conditions ,
  // so skipping other calculations.
  if (
    [
      WorkOrderType.UsIorActivation,
      WorkOrderType.GbIorActivation,
      WorkOrderType.NlIorActivation,
      WorkOrderType.FrIorActivation,
      WorkOrderType.DeIorActivation,
    ].includes(workOrderType)
  ) {
    const hoursAfterReady = defaultWorkOrderSLA[
      workOrderType as keyof typeof defaultWorkOrderSLA
    ][0].businessHoursAfterReady as number;
    log.info(
      `Using default SLA of ${hoursAfterReady} hours for ${WorkOrderType.UsIorActivation} `,
      logParams,
    );
    return businessTime
      .add(moment.utc(), hoursAfterReady)
      .startOf('hour')
      .toISOString();
  }
  const { workOrderConfiguration } = getWorkOrderConfiguration({
    shipper,
    forwarders,
    workOrderType,
  });
  const serviceLevelAgreements: ServiceLevelAgreementTypes[] = stripTypeNames(
    workOrderConfiguration?.[camelCase(workOrderType)]
      ?.serviceLevelAgreements || [],
  );

  // Note: Even though the badges used here evaluate to the same value, we keep both of them.
  const defaultPremiumSla: ServiceLevelAgreementTypesWithBadges = {
    // Value defined as per https://app.asana.com/0/0/1200145085966311/1200217780958108/f
    businessHoursAfterReady: 8,
    matchingCriteria: {
      badges: [WorkOrderBadge.PREMIUM],
    },
  };

  const defaultHotSla: ServiceLevelAgreementTypesWithBadges = {
    businessHoursAfterReady: 4,
    matchingCriteria: {
      badges: [WorkOrderBadge.HOT],
    },
  };

  // Note: The priority of an sla is based on the order in the array.
  // So the highest priority sla should be added to the list last using this function
  const defaults = [defaultPremiumSla, defaultHotSla];
  defaults.forEach((sla) =>
    // IMP: don't call this function again below to add an SLA that is lower priority than these
    // If a new lower priority sla needs to be added, either do this before this block ,
    // or append to the list. (see defaultWorkOrderSLA for reference)
    addHighPrioritySlaToSlaList(sla, serviceLevelAgreements),
  );

  // As these are default SLA for this work order, these should always be the last elements in the array
  // Meaning: if SLA doesn't exist on the customer record this will be the only element in the SLA array and will be selected
  if (Object.keys(defaultWorkOrderSLA).includes(workOrderType)) {
    serviceLevelAgreements.push(
      ...defaultWorkOrderSLA[workOrderType as keyof typeof defaultWorkOrderSLA],
    );
  }

  const matchingServiceLevelAgreements = serviceLevelAgreements.filter(
    (serviceLevelAgreement) =>
      Object.entries(serviceLevelAgreement.matchingCriteria || {}).every(
        ([matchingCriterion, matchingCriterionValue]) => {
          if (!matchingCriterionValue) return true;
          if (matchingCriterion === 'modesOfTransport') {
            return isMatchingModesOfTransport(
              matchingCriterionValue,
              modeOfTransport,
            );
          } else if (matchingCriterion === 'badges') {
            return isMatchingBadges(matchingCriterionValue, badges);
          } else {
            log.error(
              `Unknown service level agreement matching criteria`,
              logParams,
            );
          }
        },
      ),
  );

  if (matchingServiceLevelAgreements.length) {
    /**
     *  Incase of multiple matching service level agreements, we use the first one where the businessHoursSla or calendarDaysSla is found. This is a limitation of this approach. Be mindful while changing the configuration.
     */
    const businessHoursSla = matchingServiceLevelAgreements.find(
      (serviceLevelAgreement) => serviceLevelAgreement.businessHoursAfterReady,
    );
    const calendarDaysSla = matchingServiceLevelAgreements.find(
      (serviceLevelAgreement) =>
        (serviceLevelAgreement as UsConsumptionEntryServiceLevelAgreement)
          .calendarDaysBeforeArrival,
    );
    const businessHoursAfterReady = businessHoursSla?.businessHoursAfterReady;
    const calendarDaysBeforeArrival = (
      calendarDaysSla as UsConsumptionEntryServiceLevelAgreement
    )?.calendarDaysBeforeArrival;
    // the calendar day SLA should always take precedence over the business hours SLA
    if (calendarDaysBeforeArrival && arrivalDate) {
      return moment
        .utc(arrivalDate)
        .startOf('hour')
        .subtract(calendarDaysBeforeArrival, 'days')
        .toISOString();
    }
    // business hours is the default fallback if a calendar day based deadline cannot be determined
    if (businessHoursAfterReady && deadlineCalculationTime) {
      return businessTime
        .add(moment.utc(deadlineCalculationTime), businessHoursAfterReady)
        .startOf('hour')
        .toISOString();
    }
    log.error(
      `No work order deadline could be determined based on resolved service level agreement`,
      logParams,
    );
  } else {
    log.error(
      `Could not resolve a service level agreement for deadline calculation`,
      logParams,
    );
  }

  log.info(defaultWoDeadlineLogMsg, logParams);
  return defaultDeadline;
};
