import styled from 'styled-components';
import FormItem, { StyledFormItemType } from '../FormItem';
import { spaceRight } from '@xbcb/ui-styles';

export const StyledFormItem: StyledFormItemType = styled(FormItem)`
  // TODO try to get LabelAbove to work instead of p
  &&& p {
    display: inline-block;
    ${spaceRight}
  }
`;
