export const prefix =
  process.env.REACT_APP_REACT && process.env.REACT_APP_REACT.toString() === '1'
    ? 'REACT_APP_'
    : '';

export const apiKey = (path) =>
  process.env[prefix + 'API_KEY_APP_' + path.split('/')[1].toUpperCase()];

export const redisUrl = process.env.REDIS_URL
  ? process.env.REDIS_URL
  : 'redis://localhost:6379/0';
