import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { selectFilter, show } from '@xbcb/ui-utils';
import { NamePath } from 'rc-field-form/lib/interface';
import { CssSize } from '@xbcb/ui-types';
import { Option, Select } from '../ClickSelect';
import FormItem from '../FormItem';

type TagSelectProps = {
  form: FormInstance;
  tags: string[];
  field?: NamePath;
  disabled?: boolean;
  readOnly?: boolean;
  hideLabel?: boolean;
  label?: string;
  size?: CssSize;
  required?: boolean;
  maxTagCount?: number;
  // Optional function to format the tag that is rendered to the user (aka when
  // we create the Options below). This is helpful for times when enums are
  // provided and we want to preserve the enum value (constantCase version) but
  // want to display it more appropriately to the user. Intentionally making
  // this parameter `any` so that enums are valid and not just string
  formatTag?: (value: any) => string;
};

const TagSelect: React.FC<TagSelectProps> = ({
  form,
  tags,
  field = 'tags',
  disabled,
  readOnly,
  hideLabel,
  label,
  size,
  required = false,
  maxTagCount,
  formatTag,
}) => {
  const tagOptions = tags.map((t) => (
    <Option key={t} value={t}>
      {formatTag ? formatTag(t) : t}
    </Option>
  ));
  const showForm = show({ readOnly, form, field });
  return showForm ? (
    <FormItem
      $itemSize={size ? size : CssSize.SHORT}
      label={hideLabel ? undefined : label ?? 'Tags'}
      name={field}
      rules={[{ required, message: ' ' }]}
      $readOnly={readOnly}
    >
      <Select
        disabled={disabled}
        showSearch
        allowClear
        mode="multiple"
        filterOption={selectFilter}
        maxTagCount={maxTagCount}
      >
        {tagOptions}
      </Select>
    </FormItem>
  ) : null;
};

export default TagSelect;
