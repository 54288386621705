import gql from 'graphql-tag';

export const generateUsConsumptionEntryNumber = gql`
  mutation GenerateUsConsumptionEntryNumber($usConsumptionEntryId: ID!) {
    generateUsConsumptionEntryNumber(
      usConsumptionEntryId: $usConsumptionEntryId
    ) {
      usConsumptionEntry {
        id
        version
        brokerNumber
        entryNumber
      }
      newNumberGenerated
    }
  }
`;

export const queryAmsUsConsumptionEntry = gql`
  mutation QueryAmsUsConsumptionEntry(
    $id: ID!
    $idempotencyKey: String!
    $input: QueryAmsUsConsumptionEntryInput!
  ) {
    queryAmsUsConsumptionEntry(
      usConsumptionEntryId: $id
      idempotencyKey: $idempotencyKey
      input: $input
    ) {
      transaction {
        id
        version
        usConsumptionEntry {
          id
        }
      }
    }
  }
`;
