import { monsRumDomains, importSignDomains } from '@xbcb/core';

/**
 * This should not be a constant but since we're still in testing stages
 * these are subject to change (or just test URLs)
 */
export const AGL_DOMAINS = [
  'agl-beta-eu.integ.amazon.com',
  'agl-beta-jp.integ.amazon.com',
  'agl-beta-na.integ.amazon.com',
  'agl-gamma-na.aka.amazon.com',
  'agl-gamma-jp.aka.amazon.com',
  'agl-gamma.aka.amazon.com',
  'agl.amazon.com',
  'www.agl.amazon.com',
];
export const MONS_DOMAINS = [
  // CBMS-owned sites
  ...Object.values(monsRumDomains),
  ...Object.values(importSignDomains),
  ...AGL_DOMAINS,
];

export const isMonsEnv = (): boolean => {
  const domain = window.location.hostname;
  return MONS_DOMAINS.includes(domain);
};

export const isAglPortal = (): boolean => {
  const domain = window.location.hostname;
  return AGL_DOMAINS.includes(domain);
};
