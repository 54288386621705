import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

export const GatewayV2Features = {
  BLUE_GREEN_STRATEGY_FEATURE: 'BLUE_GREEN_STRATEGY_FEATURE',
  CONSTRUCT_XBCB_GATEWAY_V2_INFRA: 'CONSTRUCT_XBCB_GATEWAY_V2_INFRA',
  MIGRATE_API_GATEWAY_ENDPOINT: 'MIGRATE_API_GATEWAY_ENDPOINT',
};

export const GATEWAY_V2_FEATURE = new FeatureFlagService([
  {
    feature: GatewayV2Features.BLUE_GREEN_STRATEGY_FEATURE,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA, Stage.PROD],
  },
  {
    feature: GatewayV2Features.CONSTRUCT_XBCB_GATEWAY_V2_INFRA,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA, Stage.PROD],
  },
  {
    feature: GatewayV2Features.MIGRATE_API_GATEWAY_ENDPOINT,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA, Stage.PROD],
  },
]);
