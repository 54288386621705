import gql from 'graphql-tag';

export const queryAmsUsType86Entry = gql`
  mutation QueryAmsUsType86Entry(
    $id: ID!
    $idempotencyKey: String!
    $input: QueryAmsUsType86EntryInput!
  ) {
    queryAmsUsType86Entry(
      usType86EntryId: $id
      idempotencyKey: $idempotencyKey
      input: $input
    ) {
      transaction {
        id
        version
        usType86Entry {
          id
        }
      }
    }
  }
`;
