import gql from 'graphql-tag';
import { getRecordName, dedupRecordQuery, RecordProps } from './shared';
import { pluralize } from '@xbcb/js-utils';

interface GetManyProps extends RecordProps {}

export const getManyQuery = ({
  recordName,
  fields = '',
  fragments = '',
}: GetManyProps) => {
  const { fieldsString, fragmentsString } = dedupRecordQuery(fields, fragments);
  return gql`
  query getMany($identifiers: [IdOptionalVersionInput!]!) {
    ${pluralize(getRecordName(recordName, 'camel'))}(
      identifiers: $identifiers
    ) {
      ${fieldsString}
    }
  }
  ${fragmentsString}
`;
};
