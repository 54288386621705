import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

const singleSignOnFeatureName = 'SINGLE_SIGN_ON_FEATURE';

const SINGLE_SIGN_ON_FEATURE = new FeatureFlagService([
  {
    feature: singleSignOnFeatureName,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA],
  },
]);

export { SINGLE_SIGN_ON_FEATURE, singleSignOnFeatureName };
