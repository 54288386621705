import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

const resetPasswordForExtCustomersFeatureName =
  'RESET_PASSWORD_FOR_EXTERNAL_CUSTOMERS_FEATURE';

const opsOperatorUsers = {
  prod: [
    'operatorUser_16a86614-5bf4-46d8-8e4b-27c3f231bd4c',
    'operatorUser_4ac45ab0-706b-44b0-8efc-cb02f71d3032',
    'operatorUser_df1c489a-cda4-47b8-b3c8-80179d0e170a',
    'operatorUser_1a30021b-d9b1-4034-97d6-b406af3f1f82',
    'operatorUser_ae11661d-21ab-4280-8fda-1614c2ff5963',
  ],
};

const RESET_PASSWORD_FOR_EXT_CUSTOMERS_FEATURE = new FeatureFlagService([
  {
    feature: resetPasswordForExtCustomersFeatureName,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA, Stage.PROD],
    operators: {
      [Stage.PROD]: [...opsOperatorUsers.prod],
    },
  },
]);

export {
  RESET_PASSWORD_FOR_EXT_CUSTOMERS_FEATURE,
  resetPasswordForExtCustomersFeatureName,
};
