import { guestUserAllowedQueryList } from '@xbcb/core';
import { AnyObject } from '@xbcb/shared-types';

// TODO improve this logic
// We need a way to validate that a request contains only publicly accessible queries (not behind the user login)
// right now we are looping over a hardcoded list
export const isLoginProtectedQuery = (body: string): boolean => {
  const parsedBody = JSON.parse(body);
  return Array.isArray(parsedBody)
    ? !parsedBody.every((query: AnyObject) =>
        guestUserAllowedQueryList.some((queryName: string) =>
          query.query.includes(queryName),
        ),
      )
    : !guestUserAllowedQueryList.some((queryName: string) =>
        parsedBody.query.includes(queryName),
      );
};
