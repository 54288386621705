import gql from 'graphql-tag';
export const getPendingSubPoas = gql`
  query getPendingSubPoas($forwarderId: ID!) {
    getPendingSubPoas(forwarderId: $forwarderId) {
      additionalFields
      masterPoaLink
      subPoaId
      subPoaLink
    }
  }
`;
