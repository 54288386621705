import { documentGenerationRequestFragments } from './documentGenerationRequest';

const usCbp7512DocumentGenerationRequestFragment = `
fragment usCbp7512DocumentGenerationRequestFields on UsCbp7512DocumentGenerationRequest {
  document {
    id
  }
  cbpInBondNumber
  previousCbpInBond {
    date
    number
    port
  }
  cbpInBondEntryType
  goodsNowAt
  valueOfGoods
  usPortOfDestination
  foreignPortOfDestination
  consignee {
    name
    address {
      address
      city
      postalCode
      stateCode
      countryCode
    }
  }
  importer {
    name
    iorNumber
  }
  carrier {
    name
    ein
  }
  bills {
    quantity
    number
  }
  shipment {
    arrival {
      time
      portOfUnlading
    }
    conveyance {
      conveyanceName
      grossWeight
    }
    departure {
      country
      time
      portOfLading
    }
  }
  ...documentGenerationRequestFields
  ...recordFields
}
`;

export const usCbp7512DocumentGenerationRequestFragments = `
${usCbp7512DocumentGenerationRequestFragment}
${documentGenerationRequestFragments}
`;
