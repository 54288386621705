import gql from 'graphql-tag';
import { dedupRecordQuery, getRecordName, RecordProps } from './shared';

interface GetOneProps extends RecordProps {}

export const getOneQuery = ({
  recordName,
  fields = '',
  fragments = '',
}: GetOneProps) => {
  const { fieldsString, fragmentsString } = dedupRecordQuery(fields, fragments);
  return getOneQueryLite({
    recordName,
    fields: fieldsString,
    fragments: fragmentsString,
    queryName: 'getOne',
  });
};

export const getOneQueryLite = ({
  recordName,
  fields = '',
  fragments = '',
  queryName,
}: GetOneProps & { queryName: string }) => {
  return gql`
  query ${queryName}($id: ID!, $version: Int) {
    ${getRecordName(recordName, 'camel')}(
      id: $id
      version: $version
    ) {
      id
      ${fields}
    }
  }
  ${fragments}
`;
};
