import { capitalCase } from 'change-case';
import { UsBusinessStructure } from '@xbcb/party-types';

export const mapStructureCode = (typeCode: UsBusinessStructure) => {
  if (typeCode === UsBusinessStructure.LLC) {
    return 'LLC';
  } else if (typeCode === UsBusinessStructure.US_GOVERNMENT) {
    return 'US Government';
  } else {
    return capitalCase(typeCode);
  }
};
