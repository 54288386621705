// N.B. Taking an entry off of a CBP statement is not a valid amendment reason for PSCs, so we need this separate enum.
export enum UsPostSummaryCorrectionAmendmentReason {
  INCORRECT_DOCUMENTATION = 'INCORRECT_DOCUMENTATION',
  MISSING_COMMERCIAL_INVOICE = 'MISSING_COMMERCIAL_INVOICE',
  HTS_NOT_PROVIDED_AND_CLIENT_DISAGREED_WITH_BROKER_CLASSIFICATION = 'HTS_NOT_PROVIDED_AND_CLIENT_DISAGREED_WITH_BROKER_CLASSIFICATION',
  HTS_PROVIDED_AND_CLIENT_DISAGREED_AFTER_REVIEWING_ENTRY_SUMMARY = 'HTS_PROVIDED_AND_CLIENT_DISAGREED_AFTER_REVIEWING_ENTRY_SUMMARY',
  INCORRECT_PIECE_COUNT_ENTERED = 'INCORRECT_PIECE_COUNT_ENTERED',
  OTHER_CLERICAL_ERROR = 'OTHER_CLERICAL_ERROR',
}

export const UsPostSummaryCorrectionAmendmentReasonDescription =
  'The reason for amending the post summary correction.';
