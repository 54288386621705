import { UsBusinessStructure, UsIorNumberType } from '@xbcb/party-types';

const { EIN } = UsIorNumberType;
const { INDIVIDUAL, SOLE_PROPRIETOR } = UsBusinessStructure;

// some old clients might not have iorNumberType, so we have backup logic.
// Also if the iorNumberType is undefined if the type is deterministic from country and structure (e.g. US corporation has to be ein).
// if physical or mailing address is non-US, we allow it to not be Ein
export const isEin = (record: any) => {
  const { businessStructure, iorNumber, addresses } = record || {};
  const iorNumberType = iorNumber?.type;
  const mailingCountryCode = addresses?.mailing?.countryCode;
  const physicalCountryCode = addresses?.physical?.countryCode;
  // As long as one of them are defined, and the combination of the two are either === ‘US’ or weren’t provided (we assume “same as” was checked) then it’s ‘US'
  const usCountryCode = 'US';
  const isUs =
    (mailingCountryCode || physicalCountryCode) &&
    (!mailingCountryCode || mailingCountryCode === usCountryCode) &&
    (!physicalCountryCode || physicalCountryCode === usCountryCode);
  return (
    iorNumberType === EIN ||
    (businessStructure &&
      ![INDIVIDUAL, SOLE_PROPRIETOR].includes(businessStructure) &&
      isUs)
  );
};
