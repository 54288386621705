import { Columns, EsDataType, recordFields } from '../index';
import { createTableConfig } from './createTableConfig';

const columns: Columns = {
  errorName: {
    default: true,
    name: 'Error Definition',
    type: EsDataType.TEXT,
  },
  ...recordFields({ id: { name: 'Error ID' }, createdTime: { default: true } }),
};

export const errorDefinition = createTableConfig<typeof columns>({
  columns,
});
