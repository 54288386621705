import { snakeCase, noCase } from 'change-case';
import { formatAbbreviation } from './formatAbbreviation';
import { WorkOrderType } from '@xbcb/work-order-types';
import { titleCase } from 'title-case';

const euCustomsEntryPrefix = 'EU_CUSTOMS_ENTRY_';

export const codeToText = (code: string): string => {
  const workOrderPrefixes = Object.keys(WorkOrderType).map(
    (workOrder) => `${snakeCase(workOrder).toUpperCase()}_`,
  );
  workOrderPrefixes.push(euCustomsEntryPrefix);
  workOrderPrefixes.forEach((prefix) => {
    if (code.startsWith(prefix)) {
      code = code.replace(prefix, '');
    }
  });

  return formatAbbreviation(titleCase(noCase(code)));
};
