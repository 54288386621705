import { RecordType, PartyRecordType } from '../enums';

export const shipperLinkedPartyRecordTypes: RecordType[] = [
  PartyRecordType.FORWARDER,
  PartyRecordType.US_CONSIGNEE,
  PartyRecordType.US_IOR,
  PartyRecordType.TRUCKER,
  PartyRecordType.FACILITY,
  PartyRecordType.SUPPLIER,
  PartyRecordType.CUSTOMS_AGENT,
];

export const shipperLinkedRecordTypes: RecordType[] = [
  ...shipperLinkedPartyRecordTypes,
  RecordType.PRODUCT,
];
