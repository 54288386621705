import gql from 'graphql-tag';
import { getRecordName, dedupRecordQuery, FieldsAndFragments } from './shared';
import { ObjectType } from '@xbcb/shared-types';

interface UncancelOneProps extends FieldsAndFragments {
  recordName: ObjectType;
}

export const uncancelOneMutation = ({
  recordName,
  fields = '',
  fragments = '',
}: UncancelOneProps) => {
  const { fieldsString, fragmentsString } = dedupRecordQuery(fields, fragments);
  const RecordName = getRecordName(recordName);
  return gql`
    mutation UncancelOne($id: ID!, $version: Int!) {
      uncancel${RecordName}(
        id: $id
        version: $version
      ) {
        record {
          status
          ${fieldsString}
        }
      }
    }
    ${fragmentsString}
  `;
};
