// Any outbound email communication cannot happen from amazon.com email addresses
// Do not use any email addresses with the @amazon.com domain to send any emails to external customers because it requires L8 approval from the AGLT org and the Outbound Amazon team. Until this happens, we cannot use this email for that purpose. Receiving email is fine.
export enum CbmsEmailList {
  SYSTEM_ERROR_EMAIL = 'cbms-system-errors@amazon.com',
  AMAZON_SUPPORT = 'inlt-support@amazon.com',
  BILLING_EMAIL = 'billing@inlt.com',
  LEGACY_INLT_SUPPORT = 'support@inlt.com',
  ACTIVATIONS = 'inlt-activations@amazon.com',
  INLT_BONDS = 'inlt-bonds@amazon.com',
}
