/**
 * File extension of the document.
 */
export enum DocumentFileExtension {
  BMP = 'BMP',
  CSV = 'CSV',
  DOC = 'DOC',
  DOCX = 'DOCX',
  HTML = 'HTML',
  JPEG = 'JPEG',
  JPG = 'JPG',
  PNG = 'PNG',
  PDF = 'PDF',
  RTF = 'RTF',
  TIF = 'TIF',
  TIFF = 'TIFF',
  GIF = 'GIF',
  TXT = 'TXT',
  XLS = 'XLS',
  XLSM = 'XLSM',
  XLSX = 'XLSX',
  JSON = 'JSON',
}
