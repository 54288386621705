// Note: as this file in imported in UI don't instantiate bindings
import { get } from 'lodash';
import moment from 'moment';
import {
  usCbpEntryNumber,
  ssn as ssnRegex,
  ein as einRegex,
  cbp as cbpAssignedRegex,
} from '@xbcb/regex';
import { UsIorNumberType } from '@xbcb/party-types';
import {
  AdCvdDisclaimerCode,
  CaseRate,
  UsConsumptionEntry,
  UsConsumptionEntryCommercialInvoice,
  UsPostSummaryCorrection,
} from '@xbcb/api-gateway-client';
import { roundDate } from '@xbcb/js-utils';

export const roundNumber = (value: number, decimals: number) =>
  value
    ? Number(Math.round(Number(`${value}e${decimals}`)) + 'e-' + decimals)
    : value;

export const roundDollars = (val: number) => roundNumber(val, 2);

export const stripNonEnglishChars = (value?: string) =>
  value?.replace(/[^\x00-\x7F]/g, '');

export const formatDate = (date: any) =>
  date && moment(date).format('MM/DD/YYYY');

export const getTariffQuantity = (tariffQuantity: any) =>
  `${+get(tariffQuantity, `value`, 0).toFixed(2)}${get(
    tariffQuantity,
    `unit`,
    'X',
  )}`;

export const getEffectiveCaseRate = (
  timeString: string | undefined,
  rates: CaseRate[],
) => {
  const time = timeString ? moment(timeString) : moment.utc();
  rates = [...rates];
  // Sort in descending order of effective date (reverse chronological order)
  // If effective date is equal, then sort in descending order of added date (reverse chronological order)
  const sortedRates = rates.sort((a: CaseRate, b: CaseRate) =>
    a.effectiveDate < b.effectiveDate ||
    (a.effectiveDate === b.effectiveDate && a.addedDate < b.addedDate)
      ? 1
      : -1,
  );
  for (const rate of sortedRates) {
    if (
      moment(rate.effectiveDate).isBefore(time) &&
      (!rate.inactivatedDate || moment(rate.inactivatedDate).isAfter(time))
    )
      return rate;
  }
  return undefined;
};

export const formatCaseRate = (rate: any) => {
  if (!rate) return rate;
  const { adValorem, specific, uom, uomDescription } = rate;
  const rates = [];
  if (adValorem) rates.push(`${adValorem}%`);
  if (specific)
    rates.push(
      `${specific}/${uom}${uomDescription ? ` ${uomDescription}` : ''}`,
    );
  return rates.join(' ');
};

export const error = (msg: string, prefix?: string) => {
  if (prefix) {
    throw new Error(prefix.concat(' ', msg));
  }
  throw new Error(msg);
};

export const splitEntryNumber = (fullEntryNumber: string) => {
  if (!usCbpEntryNumber.test(fullEntryNumber)) {
    throw new Error(
      `${fullEntryNumber} is not a valid entry number, must match /^[A-Z0-9]{3}-\d{8}$/`, // eslint-disable-line no-useless-escape
    );
  }
  const [filerCode, entryNumber] = fullEntryNumber.split('-');
  return { filerCode, entryNumber };
};

export const determineIorNumberType = (iorNumber: string) => {
  if (ssnRegex.test(iorNumber)) {
    return UsIorNumberType.SSN;
  } else if (einRegex.test(iorNumber)) {
    return UsIorNumberType.EIN;
  } else if (cbpAssignedRegex.test(iorNumber)) {
    return UsIorNumberType.CBP;
  } else {
    throw new Error(`This IOR number ${iorNumber} is invalid`);
  }
};

export const removeDates = (obj: {
  entryDataWithUpdates: UsConsumptionEntry;
  cbpEntryJson: any;
  totalValue: any;
  dutiesFees: any;
  hasBond: boolean;
  suretyCode: any;
}) => {
  const regx = RegExp(
    '[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])T(2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9].[0-9]{1,}Z',
    'g',
  );
  const cleaned = JSON.stringify(obj).replace(regx, '');
  return JSON.parse(cleaned);
};

export const getHtsNumbersFromInvoices = (
  invoices: UsConsumptionEntryCommercialInvoice[],
) => {
  const htsNumbers: string[] = [];
  invoices?.forEach((invoice) => {
    invoice.products?.forEach((product) => {
      product.lines?.forEach((line) => {
        line.tariffs?.forEach((tariff) => {
          tariff.htsNumber && htsNumbers.push(tariff.htsNumber);
        });
      });
    });
  });
  return htsNumbers;
};

export const getAdCvdCaseIdsFromInvoices = (
  invoices: UsConsumptionEntryCommercialInvoice[],
): string[] => {
  const caseIds: string[] = [];
  invoices?.forEach((invoice) => {
    invoice.products?.forEach((product) => {
      product.lines?.forEach((line) => {
        if (line.adCase) {
          caseIds.push(line.adCase.id);
        }
        if (line.cvCase) {
          caseIds.push(line.cvCase.id);
        }
      });
    });
  });
  return caseIds;
};

export const getHtsNumbersCbpInvoices = (invoices: any[]) => {
  const htsNumbers: string[] = [];
  invoices?.forEach((invoice: any) => {
    invoice.lineItems?.forEach((lineItem: any) => {
      lineItem.tariffs?.forEach((tariff: any) => {
        tariff.htsNumber && htsNumbers.push(tariff.htsNumber);
      });
    });
  });
  return htsNumbers;
};

export const isAdCvdDisclaimed = (adCvDisclaimerCode?: AdCvdDisclaimerCode) => {
  return adCvDisclaimerCode !== undefined && adCvDisclaimerCode !== null;
};

export const isAdCvdDisclaimerCodeEnabled = () => {
  return true;
};

type UsEntrySummary = UsConsumptionEntry | UsPostSummaryCorrection;

export const getEntryEffectiveDate = (entry: UsEntrySummary) => {
  const { arrival } = entry;
  const { inBond, importDate } = arrival || {};
  const { initiationDate, estimatedEntryDate } = inBond || {};
  return (
    (initiationDate && roundDate(initiationDate)) ||
    (estimatedEntryDate && roundDate(estimatedEntryDate)) ||
    (importDate && roundDate(importDate))
  );
};

export const isLargeUsConsumptionEntry = (input: UsEntrySummary): boolean => {
  return 'invoicesReference' in input;
};
