import {
  UsIorContinuousBondRequest,
  UsContinuousCustomsBond,
} from '@xbcb/api-gateway-client';
import log from '@xbcb/log';

export const getEffectiveDateFromUsIorContinuousBondRequest = (
  usIorContinuousBondRequest: UsIorContinuousBondRequest,
): string | undefined => {
  const {
    bondEffectiveDate,
    bondNumber: bondNumberFromWorkOrder,
    usIor: { continuousBonds },
    id,
  } = usIorContinuousBondRequest;

  if (bondEffectiveDate) return bondEffectiveDate;

  const { present, future, past = [] } = continuousBonds || {};

  const allBondsOnUsIor = [present, future, ...past].filter(
    (bond): bond is UsContinuousCustomsBond => bond !== undefined,
  );

  log.warn(
    'Bond effective date not found on UsIorContinuousBond record. Falling back to UsIor profile.',
    {
      key: 'noBondEffectiveDateFoundOnWorkOrder',
      id,
      params: {
        allBondsOnUsIor: allBondsOnUsIor.map(({ number, effectiveDate }) => ({
          number,
          effectiveDate,
        })),
      },
    },
  );

  return allBondsOnUsIor.find(
    (continuousBondOnUsIor) =>
      continuousBondOnUsIor?.number === bondNumberFromWorkOrder,
  )?.effectiveDate;
};
