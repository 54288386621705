export enum UsConsigneeCbpTransactionRecordType {
  CREATE_US_CONSIGNEE_CBP_5106_TRANSACTION = 'CREATE_US_CONSIGNEE_CBP_5106_TRANSACTION',
  UPDATE_US_CONSIGNEE_CBP_5106_TRANSACTION = 'UPDATE_US_CONSIGNEE_CBP_5106_TRANSACTION',
  QUERY_US_CONSIGNEE_CBP_TRANSACTION = 'QUERY_US_CONSIGNEE_CBP_TRANSACTION',
}

export enum UsIorCbpTransactionRecordType {
  CREATE_US_IOR_CBP_5106_TRANSACTION = 'CREATE_US_IOR_CBP_5106_TRANSACTION',
  UPDATE_US_IOR_CBP_5106_TRANSACTION = 'UPDATE_US_IOR_CBP_5106_TRANSACTION',
  QUERY_US_IOR_CBP_TRANSACTION = 'QUERY_US_IOR_CBP_TRANSACTION',
  REQUEST_US_IOR_CBP_ASSIGNED_NUMBER_TRANSACTION = 'REQUEST_US_IOR_CBP_ASSIGNED_NUMBER_TRANSACTION',
  QUERY_CBP_BY_IOR_NUMBER_TRANSACTION = 'QUERY_CBP_BY_IOR_NUMBER_TRANSACTION',
}

export enum SupplierCbpTransactionRecordType {
  UPSERT_SUPPLIER_CBP_MID_TRANSACTION = 'UPSERT_SUPPLIER_CBP_MID_TRANSACTION',
}

export enum UsDrawbackClaimCbpTransactionRecordType {
  CREATE_US_DRAWBACK_CLAIM_CBP_TRANSACTION = 'CREATE_US_DRAWBACK_CLAIM_CBP_TRANSACTION',
  UPDATE_US_DRAWBACK_CLAIM_CBP_TRANSACTION = 'UPDATE_US_DRAWBACK_CLAIM_CBP_TRANSACTION',
}

export enum UsIsfCbpTransactionRecordType {
  CREATE_US_ISF_CBP_TRANSACTION = 'CREATE_US_ISF_CBP_TRANSACTION',
  UPDATE_US_ISF_CBP_TRANSACTION = 'UPDATE_US_ISF_CBP_TRANSACTION',
  DELETE_US_ISF_CBP_TRANSACTION = 'DELETE_US_ISF_CBP_TRANSACTION',
}

export enum UsConsumptionEntryCbpTransactionRecordType {
  UPSERT_US_CONSUMPTION_ENTRY_SUMMARY_CBP_TRANSACTION = 'UPSERT_US_CONSUMPTION_ENTRY_SUMMARY_CBP_TRANSACTION',
  DELETE_US_CONSUMPTION_ENTRY_SUMMARY_CBP_TRANSACTION = 'DELETE_US_CONSUMPTION_ENTRY_SUMMARY_CBP_TRANSACTION',
  QUERY_US_CONSUMPTION_ENTRY_SUMMARY_CBP_TRANSACTION = 'QUERY_US_CONSUMPTION_ENTRY_SUMMARY_CBP_TRANSACTION',

  UPDATE_US_CONSUMPTION_ENTRY_RELEASE_CBP_TRANSACTION = 'UPDATE_US_CONSUMPTION_ENTRY_RELEASE_CBP_TRANSACTION',
  REPLACE_US_CONSUMPTION_ENTRY_RELEASE_CBP_TRANSACTION = 'REPLACE_US_CONSUMPTION_ENTRY_RELEASE_CBP_TRANSACTION',
  DELETE_US_CONSUMPTION_ENTRY_RELEASE_CBP_TRANSACTION = 'DELETE_US_CONSUMPTION_ENTRY_RELEASE_CBP_TRANSACTION',

  UPDATE_US_CONSUMPTION_ENTRY_PGA_CBP_TRANSACTION = 'UPDATE_US_CONSUMPTION_ENTRY_PGA_CBP_TRANSACTION',

  UPDATE_US_CONSUMPTION_ENTRY_STATEMENT_CBP_TRANSACTION = 'UPDATE_US_CONSUMPTION_ENTRY_STATEMENT_CBP_TRANSACTION',

  AUTHORIZE_US_CBP_ENTRY_DAILY_STATEMENT_DEBIT_TRANSACTION = 'AUTHORIZE_US_CBP_ENTRY_DAILY_STATEMENT_DEBIT_TRANSACTION',
}

export enum UsType86EntryCbpTransactionRecordType {
  CREATE_US_TYPE86_ENTRY_RELEASE_CBP_TRANSACTION = 'CREATE_US_TYPE86_ENTRY_RELEASE_CBP_TRANSACTION',
  UPDATE_US_TYPE86_ENTRY_RELEASE_CBP_TRANSACTION = 'UPDATE_US_TYPE86_ENTRY_RELEASE_CBP_TRANSACTION',
  REPLACE_US_TYPE86_ENTRY_RELEASE_CBP_TRANSACTION = 'REPLACE_US_TYPE86_ENTRY_RELEASE_CBP_TRANSACTION',
  DELETE_US_TYPE86_ENTRY_RELEASE_CBP_TRANSACTION = 'DELETE_US_TYPE86_ENTRY_RELEASE_CBP_TRANSACTION',
  QUERY_US_TYPE86_ENTRY_RELEASE_CBP_TRANSACTION = 'QUERY_US_TYPE86_ENTRY_RELEASE_CBP_TRANSACTION',
  UPDATE_US_TYPE86_ENTRY_PGA_CBP_TRANSACTION = 'UPDATE_US_TYPE86_ENTRY_PGA_CBP_TRANSACTION',
}

export enum UsPostSummaryCorrectionCbpTransactionRecordType {
  UPSERT_US_POST_SUMMARY_CORRECTION_CBP_TRANSACTION = 'UPSERT_US_POST_SUMMARY_CORRECTION_CBP_TRANSACTION',
  QUERY_US_POST_SUMMARY_CORRECTION_CBP_TRANSACTION = 'QUERY_US_POST_SUMMARY_CORRECTION_CBP_TRANSACTION',
}

export enum UsInBondCbpTransactionRecordType {
  SUBMIT_IN_BOND_CBP_TRANSACTION = 'SUBMIT_IN_BOND_CBP_TRANSACTION',
  ACTION_IN_BOND_CBP_TRANSACTION = 'ACTION_IN_BOND_CBP_TRANSACTION',
  DELETE_IN_BOND_CBP_TRANSACTION = 'DELETE_IN_BOND_CBP_TRANSACTION',
  DELETE_IN_BOND_BILL_CBP_TRANSACTION = 'DELETE_IN_BOND_BILL_CBP_TRANSACTION',
}

export enum AbiQueriesCbpTransactionRecordType {
  QUERY_FIRMS_CODE_CBP_TRANSACTION = 'QUERY_FIRMS_CODE_CBP_TRANSACTION',
  QUERY_CARRIER_CODE_CBP_TRANSACTION = 'QUERY_CARRIER_CODE_CBP_TRANSACTION',
  QUERY_AD_CVD_CASE_CBP_TRANSACTION = 'QUERY_AD_CVD_CASE_CBP_TRANSACTION',
}

// these are 1:1 with 3P system transactions (e.g. CBP ABI CATIAR App Code)
export enum TransactionUnionType {
  // party
  QUERY_CBP_IOR_TRANSACTION = 'QUERY_CBP_IOR_TRANSACTION', // KI/KR
  UPDATE_CBP_5106_TRANSACTION = 'UPDATE_CBP_5106_TRANSACTION', // TP/TT
  UPSERT_CBP_MID_TRANSACTION = 'UPSERT_CBP_MID_TRANSACTION', // $I/$R
  // work order
  MUTATE_CBP_ISF_TRANSACTION = 'MUTATE_CBP_ISF_TRANSACTION', // SF/SN
  MUTATE_CBP_ENTRY_SUMMARY_TRANSACTION = 'MUTATE_CBP_ENTRY_SUMMARY_TRANSACTION', // AE/AX
  MUTATE_CBP_DRAWBACK_CLAIM_TRANSACTION = 'MUTATE_CBP_DRAWBACK_CLAIM_TRANSACTION', // DE/DX
  QUERY_CBP_ENTRY_SUMMARY_TRANSACTION = 'QUERY_CBP_ENTRY_SUMMARY_TRANSACTION', // JC/JD
  MUTATE_CBP_ENTRY_RELEASE_TRANSACTION = 'MUTATE_CBP_ENTRY_RELEASE_TRANSACTION', // SE/SX
  MUTATE_US_TYPE86_ENTRY_CBP_ENTRY_RELEASE_TRANSACTION = 'MUTATE_US_TYPE86_ENTRY_CBP_ENTRY_RELEASE_TRANSACTION', // SE/SX
  MUTATE_CBP_ENTRY_PGA_TRANSACTION = 'MUTATE_CBP_ENTRY_PGA_TRANSACTION', // CA/CC
  MUTATE_US_TYPE86_ENTRY_CBP_ENTRY_PGA_TRANSACTION = 'MUTATE_US_TYPE86_ENTRY_CBP_ENTRY_PGA_TRANSACTION', // CA/CC
  UPDATE_CBP_ENTRY_STATEMENT_TRANSACTION = 'UPDATE_CBP_ENTRY_STATEMENT_TRANSACTION', // SU/SQ
  AUTHORIZE_CBP_ENTRY_STATEMENT_DEBIT_TRANSACTION = 'AUTHORIZE_CBP_ENTRY_STATEMENT_DEBIT_TRANSACTION', // PN/PZ
}

// FYI this seems to be a SCREAMING_SNAKE_CASE version of the pascal cased Transaction class interfaces.
export enum TransactionInterfaceType {
  // party
  US_IOR_CBP_TRANSACTION = 'US_IOR_CBP_TRANSACTION',
  US_CONSIGNEE_CBP_TRANSACTION = 'US_CONSIGNEE_CBP_TRANSACTION',
  SUPPLIER_CBP_TRANSACTION = 'SUPPLIER_CBP_TRANSACTION',
  // work order
  US_ISF_CBP_TRANSACTION = 'US_ISF_CBP_TRANSACTION',
  US_CONSUMPTION_ENTRY_CBP_TRANSACTION = 'US_CONSUMPTION_ENTRY_CBP_TRANSACTION',
  US_TYPE86_ENTRY_CBP_TRANSACTION = 'US_TYPE86_ENTRY_CBP_TRANSACTION',
  US_POST_SUMMARY_CORRECTION_CBP_TRANSACTION = 'US_POST_SUMMARY_CORRECTION_CBP_TRANSACTION',
  US_DRAWBACK_CLAIM_CBP_TRANSACTION = 'US_DRAWBACK_CLAIM_CBP_TRANSACTION',
}

export const TransactionRecordType = {
  ...UsConsigneeCbpTransactionRecordType,
  ...UsIorCbpTransactionRecordType,
  ...SupplierCbpTransactionRecordType,
  ...UsIsfCbpTransactionRecordType,
  ...UsConsumptionEntryCbpTransactionRecordType,
  ...UsPostSummaryCorrectionCbpTransactionRecordType,
  ...UsInBondCbpTransactionRecordType,
  ...UsDrawbackClaimCbpTransactionRecordType,
  ...AbiQueriesCbpTransactionRecordType,
  ...UsType86EntryCbpTransactionRecordType,
};
export type TransactionRecordType =
  | UsConsigneeCbpTransactionRecordType
  | UsIorCbpTransactionRecordType
  | SupplierCbpTransactionRecordType
  | UsIsfCbpTransactionRecordType
  | UsConsumptionEntryCbpTransactionRecordType
  | UsPostSummaryCorrectionCbpTransactionRecordType
  | UsInBondCbpTransactionRecordType
  | UsDrawbackClaimCbpTransactionRecordType
  | AbiQueriesCbpTransactionRecordType
  | UsType86EntryCbpTransactionRecordType;
