import { get } from 'lodash';
import { PartyRecordType } from '@xbcb/shared-types';
import { BillingParty } from '@xbcb/api-gateway-client';
import { getRecordType } from '@xbcb/core';
import log from '@xbcb/log';
import { PriceTable } from './types';

const nonMemberRate = 249;

const getPriceFromPriceTable = (
  priceTable: PriceTable,
  payer: BillingParty,
  key: string,
  priorityPlan?: string,
): number => {
  const plan = priorityPlan || payer.billingDetails?.subscriptionPlan;
  if (!plan) {
    log.error(
      `Invoice payer ${payer.id} has no subscription plan, Non-member rate is $249`,
      {
        key: 'NoSubscriptionPlan',
        payload: { payerId: payer.id, payerVersion: payer.version },
      },
    );
    return nonMemberRate;
  }

  const payerType = getRecordType(payer.id);
  if (
    payerType !== PartyRecordType.FORWARDER &&
    payerType !== PartyRecordType.SHIPPER
  )
    throw new Error(`Wrong payer type`);
  return cleanAmount(get(priceTable, [payerType, key, plan], undefined)) || 0;
};

const cleanAmount = (maybeNumber: string | undefined) =>
  maybeNumber &&
  parseFloat(
    maybeNumber
      .replace(/[,\/#!$%\^&\*;:{}=\-_`~()]/g, '') // eslint-disable-line no-useless-escape
      .replace(/[A-Za-z]/g, ''),
  );

export default getPriceFromPriceTable;
