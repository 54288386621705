import { BillingParty, InvoiceFeeDetails } from '@xbcb/api-gateway-client';
import { ChargeCode } from '@xbcb/finance-types';

const getCustomFeeBase = (
  chargeCode: ChargeCode,
  fees?: InvoiceFeeDetails[],
) => {
  // Find the first invoiceFeeDetails that has a type matching the provided chargeCode
  const invoiceFeeDetails = fees?.filter(({ type }) => type === chargeCode)[0];
  // If there aren't any fees or there wasn't any invoiceFeeDetails found
  // matching the provided chargeCode default to 0. Otherwise, return the cost
  return invoiceFeeDetails?.value?.value || 0;
};

const getCustomFeeFromPayer = (
  payer: BillingParty,
  chargeCode: ChargeCode,
): number => getCustomFeeBase(chargeCode, payer.billingDetails?.customFees);

export default getCustomFeeFromPayer;
