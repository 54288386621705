import { WorkOrderInquiryReasonCode } from './inquiry';

export const workOrderBlockedReasonCodeDescription =
  'The blocked reason code for the work order';

export enum WorkOrderPendingReasonCode {
  PENDING_ENTRY_SUMMARY_CONFIRMATION = 'PENDING_ENTRY_SUMMARY_CONFIRMATION',
  SUSPENDED_PAYEE_ACCOUNT = 'SUSPENDED_PAYEE_ACCOUNT',
}

export const WorkOrderBlockedReasonCode = {
  ...WorkOrderInquiryReasonCode,
  ...WorkOrderPendingReasonCode,
};

export type WorkOrderBlockedReasonCode =
  | WorkOrderInquiryReasonCode
  | WorkOrderPendingReasonCode;
