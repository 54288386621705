import styled from 'styled-components';
import { Input } from 'antd';

export const StyledLocationFormDiv = styled.div`
  max-width: var(--medium);
  text-align: left;
  .company-info-full-city-width {
    padding-right: 0;
  }
`;

export const StyledInputGroup = styled(Input.Group)<{
  $removeSpaceBottom?: boolean;
}>`
  ${({ $removeSpaceBottom }) => $removeSpaceBottom && 'margin-bottom: 0;'}
`;
