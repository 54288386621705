import styled from 'styled-components';
import { InlineTooltip } from '@xbcb/feedback-components';
import { inlineBlockFormItem } from '@xbcb/ui-styles';

export const StyledDiv = styled.div<{
  $inline?: boolean;
}>`
  ${({ $inline }) => $inline && 'display: inline-block;'}
  .bond-form-import-details-title {
    margin-bottom: 5px;
  }
`;

export const StyledTooltip = styled(InlineTooltip)`
  ${inlineBlockFormItem}
`;
