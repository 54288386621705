import { camelCase } from 'change-case';
import { WorkOrderType } from '@xbcb/api-gateway-client';

export const shouldInvoiceShipper = (
  forwarder: any,
  workOrderType: WorkOrderType,
) => {
  return Boolean(
    forwarder?.workOrderConfig?.[camelCase(workOrderType)]?.invoiceShipper,
  );
};
