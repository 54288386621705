import {
  Invoice,
  ChargeCode as apigChargeCode,
} from '@xbcb/api-gateway-client';

import { ChargeCode, InvoiceStatus } from '@xbcb/finance-types';
export const getDutyInvoices = (invoices?: Invoice[]) => {
  const invoicesWithDuty = invoices?.filter((invoice) =>
    Boolean(
      invoice.lines &&
        invoice.lines.some((line) =>
          allowAmendChargeCodeLists.includes(line.type),
        ) &&
        ![InvoiceStatus.PENDING_VOID, InvoiceStatus.VOID].includes(
          invoice.status as InvoiceStatus,
        ) &&
        !invoice.deleted,
    ),
  );
  return invoicesWithDuty || [];
};

export const dutyChargeCodes: apigChargeCode[] = [
  ChargeCode.DUTY,
  ChargeCode.DUTY_PMS,
  ChargeCode.DUTY_DAILY,
  ChargeCode.AD_DUTY,
  ChargeCode.CV_DUTY,
  ChargeCode.IMPORT_VALUE_ADDED_TAX,
];

export const allowAmendChargeCodeLists: apigChargeCode[] = [
  ChargeCode.ENTRY_FEE,
  ChargeCode.DISBURSEMENT_FEE,
  ...dutyChargeCodes,
];
