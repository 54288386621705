import gql from 'graphql-tag';
import { dedupRecordQuery, getRecordName, RecordProps } from './shared';

interface SubscribeOnCreateProps extends RecordProps {}

export const subscribeOnCreateQuery = ({
  recordName,
  fields = '',
  fragments = '',
}: SubscribeOnCreateProps) => {
  const { fieldsString, fragmentsString } = dedupRecordQuery(fields, fragments);
  return gql`
  subscription subscribeOnCreate($topic: String!) {
    subscribe${getRecordName(recordName)}OnCreate(topic: $topic) {
      record {
        ${fieldsString}
      }
    }
  }
  ${fragmentsString}
  `;
};
