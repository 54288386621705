import moment from 'moment';

export const isS3PresignedUrlExpired = (presignedUrl?: string): boolean => {
  if (!presignedUrl) return true;
  // These regexes use a capture group to grab the values for the creation time/expiration time.
  // Using creation time for example, the URL with a creation time would have this in the URL:
  // '...X-Amz-Date=51065432&X-Amz-Expires...'. If it exists, linkCreatedTime would be
  // ['X-Amz-Date=51065432&X-Amz-Expires', 51065432] with index 1 having the value.
  const linkCreatedTime = presignedUrl.match(
    /X-Amz-Date=(.*)&X-Amz-Expires/,
  )?.[1];
  const linkExpirationTime = presignedUrl.match(
    /X-Amz-Expires=(.*)&X-Amz-Security/,
  )?.[1];
  if (!linkExpirationTime) {
    return false;
  }
  if (linkCreatedTime) {
    const linkExpirationTimeMoment = moment(linkCreatedTime);
    linkExpirationTimeMoment.add(linkExpirationTime, 'seconds');
    const now = moment();
    return !(
      linkExpirationTimeMoment.isValid() &&
      now.isBefore(linkExpirationTimeMoment)
    );
  }
  return true;
};
