import { SubscriptionPlan } from '@xbcb/shared-types';

interface AntiDumpingCountervailingDutySteppedRates {
  fiftyToOneHundredThousand: number;
  twoHundredToFiveHundredThousand: number;
  sixHundredToOneMillion: number;
  overOneMillion: number;
}

const antiDumpingCountervailingDutyContinuousBondFee: {
  [key in SubscriptionPlan]?: AntiDumpingCountervailingDutySteppedRates;
} = {
  [SubscriptionPlan.BASIC]: {
    fiftyToOneHundredThousand: 0.0114,
    twoHundredToFiveHundredThousand: 0.00892,
    sixHundredToOneMillion: 0.00436,
    overOneMillion: 0.0017,
  },
  [SubscriptionPlan.PRO]: {
    fiftyToOneHundredThousand: 0.0102,
    twoHundredToFiveHundredThousand: 0.00796,
    sixHundredToOneMillion: 0.00388,
    overOneMillion: 0.00151,
  },
  [SubscriptionPlan.ENTERPRISE]: {
    fiftyToOneHundredThousand: 0.0091,
    twoHundredToFiveHundredThousand: 0.00708,
    sixHundredToOneMillion: 0.00344,
    overOneMillion: 0.00134,
  },
};

export default antiDumpingCountervailingDutyContinuousBondFee;
