import moment, { Moment } from 'moment';

/**
 * Function for getting a random time within a time interval
 * @param {Moment} minHour The minimum hour (EST) of the scheduleding time range interval.
 * @param {Moment} maxHour The maximum hour (EST) of the scheduleding time range interval.
 * @returns { number, number } An object with randomized hour and minute fields
 */

export const getRandomDateTimeFromDateInterval = (
  minDate: Moment,
  maxDate: Moment,
): { hour: number; minute: number } => {
  if (minDate.isAfter(maxDate)) {
    throw new Error(`Error: minDate must precede maxDate`);
  }
  const startTime = minDate.toDate().getTime();
  const endTime = maxDate.toDate().getTime();
  const randDate = moment(
    new Date(Math.floor(Math.random() * (endTime - startTime + 1) + startTime)),
  );
  return {
    hour: randDate.hours(),
    minute: randDate.minutes(),
  };
};
