import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

export const new232SectionFeature = 'NEW_232_SECTION_FEATURE';

const NEW_232_SECTION_FEATURE = new FeatureFlagService([
  {
    feature: new232SectionFeature,
    enabled: true,
    // add Stage.BETA, Stage.GAMMA and Stage.PROD later
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA, Stage.PROD],
  },
]);

export { NEW_232_SECTION_FEATURE };
