import { HttpRequest } from '@aws-sdk/protocol-http';
import { SignatureV4 } from '@aws-sdk/signature-v4';
import { Sha256 } from '@aws-crypto/sha256-js';
import {
  CognitoIdentityCredentials,
  CognitoIdentityCredentialProvider,
} from '@aws-sdk/credential-provider-cognito-identity';

export interface SignHttpRequestOptions {
  credentials: CognitoIdentityCredentials | CognitoIdentityCredentialProvider;
  uri: string;
  body?: string;
  region: string;
  headers?: Record<string, string>;
  method?: string;
}

export interface SignedHttpRequest {
  path: string;
  body?: string;
  headers: Record<string, string>;
  method: string;
  protocol: string;
}

const getRequestParams = ({
  uri,
  method,
  headers,
  body,
}: Omit<SignHttpRequestOptions, 'credentials' | 'region'>) => {
  const parsedUrl = new URL(uri);
  const parsedEndpoint = parsedUrl.hostname.toString();
  const parsedPath = parsedUrl.pathname.toString();

  const baseHeaders = {
    'Content-Type': 'application/json',
    host: parsedEndpoint,
  };
  return {
    method,
    path: parsedPath,
    hostname: parsedEndpoint,
    headers: { ...baseHeaders, ...headers },
    body,
  };
};

/**
 * Signs an HTTP request with SigV4
 * @param {object} SignRequestOptions
 * @return SignedHttpRequest
 */

export const signHttpRequest = async ({
  credentials,
  uri,
  body,
  method = 'POST',
  region,
  headers,
}: SignHttpRequestOptions): Promise<SignedHttpRequest> => {
  const requestParams = getRequestParams({ uri, body, method, headers });
  const request = new HttpRequest(requestParams);
  const signer = new SignatureV4({
    region,
    service: 'execute-api',
    credentials,
    sha256: Sha256,
  });
  return await signer.sign(request, {
    signingDate: new Date(),
  });
};
