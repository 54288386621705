import { SortOrder } from '@xbcb/shared-types';
import { createPartyTable } from './createPartyTable';
import { EsDataType } from '../../enums';

export const operator = createPartyTable({
  columns: {
    city: {
      default: true,
      name: 'City',
      type: EsDataType.TEXT,
    },
    stateName: {
      default: true,
      name: 'State',
      type: EsDataType.TEXT,
    },
    countryName: {
      name: 'Country',
      type: EsDataType.TEXT,
      default: true,
    },
    duns: {
      default: true,
      name: 'DUNS',
      type: EsDataType.KEYWORD,
    },
  },
  tableOptions: {
    sortOrder: SortOrder.DESC,
  },
  skipOperatorId: true,
});
