import { CrudPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

const { READ } = CrudPermissionName;

export const ShipperUserForwarderPermissionName = {
  ...CrudPermissionName,
};

export const ShipperUserUsConsigneePermissionName = {
  ...CrudPermissionName,
};

export const ShipperUserUsIorPermissionName = {
  ...CrudPermissionName,
};

export const ShipperUserTruckerPermissionName = {
  ...CrudPermissionName,
};

export const ShipperUserFacilityPermissionName = {
  ...CrudPermissionName,
};

export const ShipperUserSupplierPermissionName = {
  ...CrudPermissionName,
};

export const ShipperUserCustomsAgentPermissionName = {
  ...CrudPermissionName,
};

export const ShipperUserUsCustomsBrokerPermissionName = {
  READ,
};

export const ShipperUserCustomsBrokerPermissionName = {
  READ,
};

export default {
  [ObjectType.FORWARDER]: ShipperUserForwarderPermissionName,
  [ObjectType.US_CONSIGNEE]: ShipperUserUsConsigneePermissionName,
  [ObjectType.US_IOR]: ShipperUserUsIorPermissionName,
  [ObjectType.TRUCKER]: ShipperUserTruckerPermissionName,
  [ObjectType.FACILITY]: ShipperUserFacilityPermissionName,
  [ObjectType.SUPPLIER]: ShipperUserSupplierPermissionName,
  [ObjectType.CUSTOMS_AGENT]: ShipperUserCustomsAgentPermissionName,
  [ObjectType.US_CUSTOMS_BROKER]: ShipperUserUsCustomsBrokerPermissionName,
  [ObjectType.CUSTOMS_BROKER]: ShipperUserCustomsBrokerPermissionName,
};
