import React, { useState, useRef, useEffect, useCallback } from 'react';
import { LabelAbove } from '@xbcb/static-text-components';
import { Form } from 'antd';
import styled, { StyledComponent } from 'styled-components';
import { FormItemProps as AntdFormItemProps } from 'antd/lib/form';
import { CssSize } from '@xbcb/ui-types';
import { readOnly as readOnlyStyle } from '@xbcb/ui-styles';
import { remove, add } from '@xbcb/ui-utils';
import { debounce } from 'lodash';
import { StoreValue } from 'rc-field-form/lib/interface';

export interface FormItemProps extends AntdFormItemProps {
  label?: React.ReactNode;
  debounce?: boolean;
  debounceTimeout?: number;
  // intentionally using this casing to follow the existing data-cy pattern
  // https://docs.cypress.io/guides/references/best-practices
  'data-cy'?: string;
}

type DebouncedComponentProps = {
  value?: StoreValue;
  onChange?: Function;
  debounceTimeout?: number;
  [key: string]: any;
};

const DebouncedComponent: React.FC<DebouncedComponentProps> = (props) => {
  const {
    value: freshValue, // this is the value being passed down from the Antd Form Item control
    debounceTimeout = 1000,
    children,
    ...threadedProps // this includes other stuff, like the form item id
  } = props;

  const [value, setValue] = useState<StoreValue>(freshValue);
  // "controlled" means that this component is currently capturing debounced input. It turns on when a user is interacting w/ an input and turns off after enough time has elapsed w/ no user input for the actual debounced handler to be invoked.
  const controlled = useRef<boolean>(false);
  const uuid = useRef<number>();

  // We always want to use the most up-to-date references to any callbacks, but we don't want our wrapper callbacks to re-generate when a new reference is passed.
  // https://reactjs.org/docs/hooks-faq.html#what-can-i-do-if-my-effect-dependencies-change-too-often
  const latestProps = useRef(props);
  useEffect(() => {
    latestProps.current = props;
  });
  const onChange = useCallback(
    debounce((value) => {
      controlled.current = false;
      if (latestProps.current?.onChange) {
        latestProps.current.onChange(value);
      }
    }, debounceTimeout),
    [],
  );

  useEffect(() => {
    uuid.current = add(onChange); // when a debounced component mounts, we store a reference to it in a map of all existing debounced functions so we can flush them all if needed.
    // when the component unmounts, we flush any currently debounced input and remove it from the map of debounced functions.
    return () => {
      onChange.flush();
      remove(uuid.current);
    };
  }, [onChange]);

  // This effect handles the case of the field's value being changed from elsewhere in the form
  useEffect(() => {
    if (!controlled.current && freshValue !== value) {
      setValue(freshValue);
    }
  }, [freshValue, value]);

  const handleOnChange = useCallback(
    (valueOrEvent: any) => {
      let value;
      if (typeof valueOrEvent === 'number') {
        value = valueOrEvent;
      } else {
        if (latestProps.current?.getValueFromEvent) {
          value = latestProps.current?.getValueFromEvent(valueOrEvent);
        } else {
          value = valueOrEvent?.target?.value;
        }
      }
      setValue(value);
      controlled.current = true;
      onChange(value);
    },
    [onChange],
  );

  return (
    <>
      {React.Children.map(children, (child) => {
        if (React.isValidElement<any>(child)) {
          return React.cloneElement(child, {
            ...threadedProps,
            value,
            onChange: handleOnChange,
          });
        } else {
          return child;
        }
      })}
    </>
  );
};

const FormItem: React.FC<FormItemProps> = ({
  label,
  children,
  className,
  style,
  debounce,
  debounceTimeout,
  getValueFromEvent,
  'data-cy': dataCy,
  ...threadedProps
}) => {
  let onChange;
  if (debounce) {
    // Ant design internally wraps the "onChange" prop of the direct child of Form.Item
    // If we are debouncing, we inject the debounce component between Form.Item and its child
    // In order to keep everything working, we need to lift up the child's onChange prop and pass it to the Debounced component
    React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        onChange = child.props.onChange;
      }
    });
  }
  return (
    <Form.Item className={className} style={style} data-cy={dataCy}>
      {label && <LabelAbove text={label} />}
      <Form.Item
        help={null}
        {...threadedProps}
        noStyle
        // To normalize the uni-code characters that come as a result of copy-pasting from word/pdf files. ref: https://t.corp.amazon.com/V1169013476
        normalize={(value) =>
          typeof value === 'string' ? value.normalize('NFKC') : value
        }
        getValueFromEvent={debounce ? undefined : getValueFromEvent} // we'll invoke getValueFromEvent ourselves if we are debouncing
      >
        {debounce ? (
          <DebouncedComponent
            debounceTimeout={debounceTimeout}
            getValueFromEvent={getValueFromEvent}
            onChange={onChange}
          >
            {children}
          </DebouncedComponent>
        ) : (
          children
        )}
      </Form.Item>
    </Form.Item>
  );
};

type StyledFormItemProps = {
  $itemSize?: CssSize | string;
  $inline?: boolean;
  $spaceTop?: boolean;
  $spaceLeft?: boolean;
  $removeSpaceBottom?: boolean;
  $removeSpaceLeft?: boolean;
  $readOnly?: boolean;
};

// We expect our inputs to have a height of 35px. You'll see below that
// .ant-input-number-input uses 33px, that's because it does not count the 1px
// top and bottom border. But, we can't style the parent element (that has the
// border) to 35px or the styling of the actual input is incorrect.
const StyledFormItem = styled(FormItem)<StyledFormItemProps>`
  &.ant-form-item {
    margin: 0 var(--space-4) var(--space-4) 0;
    ${({ $itemSize }) =>
      $itemSize && `width: calc(${$itemSize} - var(--space-4));`}

    ${({ $inline }) => $inline && 'display: inline-block;'}

    ${({ $spaceTop }) => $spaceTop && `margin-top: var(--space-4);`}

    ${({ $spaceLeft }) => $spaceLeft && `margin-left: var(--space-4);`}

    ${({ $removeSpaceBottom }) => $removeSpaceBottom && `margin-bottom: 0;`}

    ${({ $removeSpaceLeft }) => $removeSpaceLeft && `margin-left: 0;`}

    // we don't use ant design's built in error message helper
    .ant-form-item-explain {
      display: none;
    }

    .ant-row {
      display: flex;
    }

    p, input, .ant-select-selector, .ant-select-selection-item, textarea, .ant-input-affix-wrapper {
      color: black;
      background-color: rgb(242, 242, 242);
      font-size: 16px;
    }

    .ant-select-multiple .ant-select-selection-item {
      background: white;
    }

    .ant-select-clear {
      background: none;
    }

    .ant-input-number {
      width: 100%;
    }

    .ant-input-number-input {
      height: 33px;
    }

    .ant-select-auto-complete > .ant-select-selector, .ant-input-search-button {
      height: 35px;
    }

    ${({ $readOnly }) =>
      $readOnly &&
      `&&& {
        p, input, textarea, .ant-select-selection, .ant-select-selection-item, .ant-select-selector, .ant-input-number, .ant-input-affix-wrapper, .ant-picker.ant-picker-disabled {
          ${readOnlyStyle}
        }
        .ant-select-arrow {
          display: none;
        }
      }`}

      textarea {
        resize: none;
      }
`;

// TODO deprecate this type, TS should be able to infer it, but we were getting the below error without it
// The inferred type of 'StyledFormItem' cannot be named without a reference to '@xbcb/ui-utils/node_modules/@xbcb/ui-types'. This is likely not portable. A type annotation is necessary.
export type StyledFormItemType = StyledComponent<
  React.FC<FormItemProps>,
  any,
  StyledFormItemProps,
  never
>;

export default StyledFormItem;
