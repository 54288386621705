import {
  CreateUsConsumptionEntryInput,
  UpdateUsConsumptionEntryInput,
} from '@xbcb/api-gateway-client';

export type UsConsumptionEntryMutationInput =
  | CreateUsConsumptionEntryInput
  | UpdateUsConsumptionEntryInput;

export const US_CONSUMPTION_ENTRY_INVOICES = 'invoices';

/** `as const` ensures it's treated as a tuple of string literals */
export const LARGE_US_CONSUMPTION_ENTRY_FIELDS = [
  US_CONSUMPTION_ENTRY_INVOICES,
] as const;

/** Extract the union of keys */
export type LargeUsConsumptionEntryFieldKeys =
  (typeof LARGE_US_CONSUMPTION_ENTRY_FIELDS)[number];

export type LargeEntrySavedFields = Pick<
  UsConsumptionEntryMutationInput,
  LargeUsConsumptionEntryFieldKeys
>;
