import { get } from 'lodash';
import { sanitize } from '../sanitize';

const countryMergeTag = 'country';
const stateMergeTag = 'state';

const mergeTags = [
  'name',
  'alternateName',
  'address',
  'city',
  'postalCode',
  countryMergeTag,
  stateMergeTag,
  'phone',
  'iorNumber',
  'forwarder',
  'structure',
  // secondary officer fields are set for CC and SubPOA
  'secondaryOfficerName',
  'secondaryOfficerTitle',
  'signerName', // deprecated for officerName
  'signerTitle', // deprecated for officeTitle
  // officer fields are set for MasterPOA / DirectPOA
  'officerName', // should be the signer of the POA on behalf of the grantor for the CC form
  'officerTitle', // should be the signer of the POA on behalf of the grantor for the CC form
];

export const renderTemplate = ({ countryCodes, html, data = {} }) => {
  if (!html) return html;
  let htmlString = html;
  mergeTags.forEach((field) => {
    let fieldName = field;
    let value = data[field] || '__________';
    if (field === countryMergeTag) {
      value = get(countryCodes, [data[field], 'name'], value);
    } else if (field === stateMergeTag) {
      value = get(
        countryCodes,
        [data[countryMergeTag], 'subdivision', data[field], 'name'],
        value,
      );
    } else if (field === 'address1') {
      fieldName = 'address';
    }
    const mergeTag = `{{${fieldName}}}`;
    value = sanitize(value);
    htmlString = htmlString.replace(new RegExp(mergeTag, 'g'), value);
  });
  return htmlString;
};
