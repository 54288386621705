import {
  AccountType,
  LoadType,
  SortOrder,
  ModeOfTransport,
} from '@xbcb/shared-types';
import {
  WorkOrderStatus,
  UsCbpEntryReleaseStatus,
  UsCbpEntrySummaryStatus,
  WorkOrderMilestoneName,
} from '@xbcb/work-order-types';
import { EsDataType, recordFields, Columns } from '../index';
import { columns as externalIntegrationReferenceColumns } from './externalIntegrationReference';
import { createTableConfig } from './createTableConfig';
import { sharedCancellationColumns } from './sharedCancellationColums';
import { ShipmentBadge, ShipmentStatus } from '@xbcb/shipment-types';

const columns: Columns = {
  shipperName: {
    default: false,
    name: (accountType: AccountType) =>
      accountType === AccountType.FORWARDER ? 'Customer' : 'Shipper',
    type: EsDataType.TEXT,
  },
  shipperId: {
    name: 'Shipper ID',
    type: EsDataType.KEYWORD,
    hidden: true,
  },
  customsBrokerId: {
    name: 'Broker ID',
    type: EsDataType.KEYWORD,
    hidden: true,
  },
  customsBrokerName: {
    default: true,
    name: 'Broker Name',
    type: EsDataType.TEXT,
  },
  forwarderName: {
    default: false,
    name: (accountType: AccountType) =>
      accountType === AccountType.FORWARDER ? 'Agent' : 'Forwarder',
    type: EsDataType.TEXT,
  },
  forwarderId: {
    name: 'Forwarder ID',
    type: EsDataType.KEYWORD,
    hidden: true,
  },
  clientIdentifier: {
    default: [AccountType.SHIPPER, AccountType.FORWARDER],
    name: 'Client #',
    type: EsDataType.KEYWORD,
  },
  friendlyId: {
    name: 'ID',
    type: EsDataType.TEXT,
  },
  modeOfTransport: {
    default: true,
    name: 'Mode of Transportation',
    type: EsDataType.KEYWORD,
    react: true,
    optionValues: Object.values(ModeOfTransport),
  },
  containerNumber: {
    name: 'Container #',
    type: EsDataType.TEXT,
  },
  masterBill: {
    default: true,
    name: 'Master Bill #',
    type: EsDataType.TEXT,
  },
  houseBill: {
    default: true,
    name: 'House Bill #',
    type: EsDataType.TEXT,
  },
  bookingId: {
    name: 'Booking ID',
    type: EsDataType.KEYWORD,
  },
  loadType: {
    name: 'Load Type',
    type: EsDataType.KEYWORD,
    optionValues: Object.keys(LoadType),
  },
  badge: {
    name: 'Badge',
    type: EsDataType.KEYWORD,
    optionValues: Object.keys(ShipmentBadge),
  },
  poNumber: {
    name: 'Ref #',
    type: EsDataType.KEYWORD,
  },
  iorName: {
    name: 'IOR name',
    type: EsDataType.TEXT,
  },
  entryStatus: {
    name: 'Entry Status',
    type: EsDataType.KEYWORD,
    default: true,
    optionValues: Object.values(WorkOrderStatus),
  },
  entryDeadline: {
    name: 'Entry Deadline',
    type: EsDataType.TIME,
    default: true,
  },
  isfStatus: {
    name: 'ISF Status',
    type: EsDataType.KEYWORD,
    optionValues: Object.values(WorkOrderStatus),
  },
  isfDeadline: {
    name: 'ISF Deadline',
    type: EsDataType.TIME,
  },
  inBondStatus: {
    name: 'In-Bond Status',
    type: EsDataType.KEYWORD,
    optionValues: Object.values(WorkOrderStatus),
  },
  inBondDeadline: {
    name: 'In-Bond Deadline',
    type: EsDataType.TIME,
  },
  deliveryOrderStatus: {
    name: 'DO Status',
    type: EsDataType.KEYWORD,
    optionValues: Object.values(WorkOrderStatus),
  },
  deliveryOrderDeadline: {
    name: 'DO Deadline',
    type: EsDataType.TIME,
  },
  latestMilestone: {
    name: 'Latest Milestone',
    type: EsDataType.KEYWORD,
    optionValues: Object.values(WorkOrderMilestoneName),
  },
  conveyanceName: {
    name: 'Conveyance Name',
    type: EsDataType.TEXT,
  },
  exportDate: {
    name: 'Export Date',
    type: EsDataType.TIME,
  },
  exportCountryCode: {
    name: 'Export Country Code',
    type: EsDataType.KEYWORD,
  },
  exportCountryName: {
    name: 'Export Country',
    type: EsDataType.TEXT,
  },
  importDate: {
    name: 'Import Date',
    type: EsDataType.TIME,
  },
  portOfUnladingName: {
    name: 'Port of Unlading',
    type: EsDataType.KEYWORD,
  },
  portOfLadingName: {
    name: 'Port of Lading',
    type: EsDataType.KEYWORD,
  },
  tripNumber: {
    name: 'Voyage #',
    type: EsDataType.TEXT,
  },
  summaryStatus: {
    name: 'Summary Status',
    type: EsDataType.KEYWORD,
    optionValues: Object.keys(UsCbpEntrySummaryStatus),
  },
  entryNumber: {
    name: 'Entry #',
    type: EsDataType.TEXT,
  },
  entryType: {
    name: 'Entry Type',
    type: EsDataType.KEYWORD,
  },
  inBondNumber: {
    name: 'IT In-Bond',
    type: EsDataType.KEYWORD,
  },
  invoiceNumber: {
    name: 'Invoice #',
    type: EsDataType.KEYWORD,
  },
  firmsCode: {
    name: 'FIRMS',
    type: EsDataType.KEYWORD,
  },
  examSite: {
    name: 'Exam Site',
    type: EsDataType.KEYWORD,
  },
  preferredExamSite: {
    name: 'Preferred Exam Site',
    type: EsDataType.KEYWORD,
  },
  releaseDate: {
    name: 'Release Date',
    type: EsDataType.TIME,
  },
  releaseStatus: {
    name: 'Release Status',
    type: EsDataType.KEYWORD,
    // TODO: Filtering on this column causes a backend error
    optionValues: Object.keys(UsCbpEntryReleaseStatus),
  },
  eta: {
    name: 'ETA',
    type: EsDataType.TIME,
  },
  pgaDisposition: {
    name: 'PGA Disposition',
    type: EsDataType.KEYWORD,
  },
  releaseDisposition: {
    name: 'Release Disposition',
    type: EsDataType.TEXT,
  },
  status: {
    default: true,
    name: 'Status',
    type: EsDataType.KEYWORD,
    optionValues: Object.keys(ShipmentStatus),
  },
  ...externalIntegrationReferenceColumns,
  ...sharedCancellationColumns,
  // TODO decide which fields should be added back, discuss with business and ops
  // even if the field is now moved to the work order level (like entry number) we can still index it at the shipment level
  // portOfLadingName: {
  //   default: true,
  //   name: 'Origin',
  //   type: EsDataType.KEYWORD,
  // },
  // portOfEntryName: {
  //   default: true,
  //   name: 'Port of Entry',
  //   type: EsDataType.KEYWORD,
  // },
  // invoiceNumber: {
  //   name: 'Invoice #',
  //   type: EsDataType.KEYWORD,
  // },
  // entryRejectCode: {
  //   name: 'Entry Exception',
  //   type: EsDataType.KEYWORD,
  //   format: (
  //     v: string[],
  //     { entryRejectedReasonCodes = {} }: { [key: string]: any } = {},
  //   ) =>
  //     v &&
  //     v.length &&
  //     v.map((code) => get(entryRejectedReasonCodes, [code, 'name'], code)),
  // },
  // collectionStatus: {
  //   name: 'Collection Status',
  //   type: EsDataType.KEYWORD,
  //   format: (
  //     code: string,
  //     { collectionStatusCodes = {} }: { [key: string]: any } = {},
  //   ) => collectionStatusCodes[code] || code,
  // },
  // pgaStatusCode: {
  //   name: 'PGA Status',
  //   type: EsDataType.KEYWORD,
  // },
  // fileTime: {
  //   name: 'Time to File',
  //   type: EsDataType.TIME,
  //   react: true,
  // },
  // htsNumber: {
  //   name: 'HTS #',
  //   type: EsDataType.KEYWORD,
  // },
  // sellerName: {
  //   name: 'Seller',
  //   type: EsDataType.TEXT,
  //   // TODO blacklist: ['supplier'],
  // },
  // manufacturerName: {
  //   name: 'Manufacturer',
  //   type: EsDataType.TEXT,
  //   // TODO blacklist: ['supplier'],
  // },
  // lfd: {
  //   name: 'Last Free Day',
  //   type: EsDataType.TIME,
  // },
  // isfNumber: {
  //   name: 'ISF #',
  //   type: EsDataType.TEXT,
  // },
  // inBondNumber: {
  //   name: 'In-bond #',
  //   type: EsDataType.TEXT,
  // },
  // isfSubmittedTime: {
  //   name: 'ISF Filed Time',
  //   type: EsDataType.TIME,
  // },
  // isfFiledTime: {
  //   name: 'ISF Accepted Time',
  //   type: EsDataType.TIME,
  // },
  // docsSubmittedTime: {
  //   name: 'Ready For Entry Time',
  //   type: EsDataType.TIME,
  // },
  // entrySubmittedTime: {
  //   name: 'Entry Filed Time',
  //   type: EsDataType.TIME,
  // },
  // entryFiledTime: {
  //   name: 'Entry Accepted Time',
  //   type: EsDataType.TIME,
  // },
  // customsReleaseTime: {
  //   name: 'Customs Release Time',
  //   type: EsDataType.TIME,
  // },
  ...recordFields({ id: { name: 'Shipment #' } }),
};

export const shipment = createTableConfig<typeof columns>({
  columns,
  tableOptions: {
    sortOrder: SortOrder.DESC,
    sortField: 'clientIdentifier',
  },
});
