import { client } from '../../client';
import {
  getOneQuery,
  getRecordFromResponse,
  MutationType,
} from '@xbcb/shared-queries';
import { IdVersion, RecordType } from '@xbcb/shared-types';
import { reportError } from '@xbcb/ui-utils';
import { OptimisticUpdateParams } from '../interfaces';
import { mutateRecord } from './mutateRecord';

export interface RetryMutationArgs<
  MutationVariables,
  Record extends IdVersion,
> {
  fields: string;
  fragments?: string;
  id: string;
  mutation: any;
  mutationType: MutationType;
  createMutationVariables?: (record: any) => { [key: string]: any };
  mutationVariables?: MutationVariables;
  recordType: RecordType;
  optimisticUpdateParams?: OptimisticUpdateParams<Record>;
}

// Fetch the latest version of a record from DynamoDB and re-trigger a mutation
export const retryMutation = async <
  MutationVariables,
  Record extends IdVersion,
>({
  fields,
  fragments,
  id,
  mutation,
  mutationType,
  createMutationVariables,
  mutationVariables,
  recordType,
  optimisticUpdateParams,
}: RetryMutationArgs<MutationVariables, Record>) => {
  try {
    const response = await client.query({
      query: getOneQuery({
        recordName: recordType,
        fields,
        fragments,
      }),
      fetchPolicy: 'network-only',
      variables: {
        id,
      },
    });
    const record = getRecordFromResponse(response, 'get', recordType);
    const { version } = record;
    const variables = mutationVariables
      ? { ...mutationVariables, version }
      : createMutationVariables
      ? createMutationVariables(record)
      : {};
    const result = await mutateRecord({
      mutation,
      mutationType,
      mutationVariables: variables,
      overwriteOptimisticRecordFields: record,
      recordType,
      optimisticUpdateParams,
    });
    return result;
  } catch (e) {
    reportError(e);
    throw e;
  }
};
