import styled from 'styled-components';
import { Input } from 'antd';
import { Select } from '../ClickSelect';
import { spaceBottom } from '@xbcb/ui-styles';

export const StyledDiv = styled.div<{
  $inline?: boolean;
  $spaceBottom?: boolean;
}>`
  margin: 0 var(--space-4) 0 0;
  ${({ $spaceBottom }) => $spaceBottom && spaceBottom}
  width: calc(var(--short) - var(--space-4));
  ${({ $inline }) => $inline && 'display: inline-block;'}
`;

export const StyledInputGroup = styled(Input.Group)`
  &&& {
    display: flex;
    & > .ant-row.ant-form-item {
      margin: 0;
    }
  }
`;

export const StyledSelect = styled(Select)`
  width: 67px !important;
  .ant-select-selection {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-select-selection-selected-value {
    padding-right: 0;
  }
`;
