import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';
import { SupportedCountryAbbreviation } from '@xbcb/shared-types';

export const arIntegrationFeature = 'AR_INTEGRATION_FEATURE';

const AR_INTEGRATION_FEATURE = new FeatureFlagService([
  {
    countriesOfOperation: {
      [Stage.ALPHA]: [
        SupportedCountryAbbreviation.GB,
        SupportedCountryAbbreviation.US,
      ],
      [Stage.BETA]: [
        SupportedCountryAbbreviation.GB,
        SupportedCountryAbbreviation.US,
      ],
      [Stage.GAMMA]: [
        SupportedCountryAbbreviation.GB,
        SupportedCountryAbbreviation.US,
      ],
      [Stage.PROD]: [
        SupportedCountryAbbreviation.GB,
        SupportedCountryAbbreviation.US,
      ],
    },
    enabled: true,
    feature: arIntegrationFeature,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA, Stage.PROD],
  },
]);

export { AR_INTEGRATION_FEATURE };
