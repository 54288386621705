import { partyFragments } from './party';

const facilityFragment = `
fragment facilityFields on Facility {
  ...recordFields
  ...partyFields
  complianceDetails {
    us {
      firmsCode
      feiNumber
      ein
    }
  }
  receivingPointOfContact {
    name
    title
    email
    phone {
      ...phoneFields
    }
    user {
      id
    }
  }
}
`;

export const facilityFragments = `
${facilityFragment}
${partyFragments}
`;

// TODO deprecated name
export const facilityFields = facilityFragments;
