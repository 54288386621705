import gql from 'graphql-tag';
import { getRecordName, dedupRecordQuery, FieldsAndFragments } from './shared';
import { ObjectType } from '@xbcb/shared-types';

interface CancelOneProps extends FieldsAndFragments {
  recordName: ObjectType;
}

export const cancelOneMutation = ({
  recordName,
  fields = '',
  fragments = '',
}: CancelOneProps) => {
  const { fieldsString, fragmentsString } = dedupRecordQuery(fields, fragments);
  const RecordName = getRecordName(recordName);
  return gql`
    mutation CancelOne($id: ID!, $version: Int!, $cancellationReason:CancellationReason! ) {
      cancel${RecordName}(
        id: $id
        version: $version
        cancellationReason: $cancellationReason
      ) {
        record {
          ${fieldsString}
          status
          canceled {
            reason {
              text
            }
          }
        }
      }
    }
    ${fragmentsString}
  `;
};
