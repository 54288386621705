import React, { ReactNode } from 'react';
import { StyledCard, StyledTitle, StyledContent } from './styles';

export interface SingleFormCardProps {
  title?: ReactNode;
  titlePosition?: string;
  className?: string;
  extraContent?: ReactNode;
  topLine?: boolean;
  onClick?: any;
  onClickTitle?: any;
  otherGridProps?: any[];
  $noMarginBottom?: boolean;
  titleWidth?: string;
}

const SingleFormCard: React.FC<SingleFormCardProps> = ({
  title,
  children,
  titlePosition = 'left',
  className,
  extraContent,
  topLine,
  onClick,
  onClickTitle,
  $noMarginBottom,
  titleWidth,
  ...otherGridProps
}) => {
  let cardClassName = className ? className : '';
  if (topLine) cardClassName += ' topLine';
  cardClassName += ` title-position-${titlePosition}`;

  return (
    <StyledCard
      onClick={onClick}
      className={cardClassName}
      {...otherGridProps}
      $inline={titlePosition === 'left'}
      $noMarginBottom={$noMarginBottom}
    >
      {title && (
        <StyledTitle
          className="single-form-card-col-title"
          $clickable={!!onClickTitle}
          onClick={onClickTitle}
          $width={titleWidth}
        >
          <h3 className="single-card-title">
            {title}
            {titlePosition === 'top' && extraContent}
          </h3>
          {titlePosition === 'left' && extraContent}
        </StyledTitle>
      )}
      <StyledContent className="single-form-card-col-content remove-padding">
        {children}
      </StyledContent>
    </StyledCard>
  );
};

export default SingleFormCard;
