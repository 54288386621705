import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

export const apIntegrationFeature = 'AP_INTEGRATION_FEATURE';

const AP_INTEGRATION_FEATURE = new FeatureFlagService([
  {
    feature: apIntegrationFeature,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA, Stage.PROD],
  },
]);

export { AP_INTEGRATION_FEATURE };
