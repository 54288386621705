import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { get } from 'lodash';
import { getCodes } from '../codes';
import { capitalCase } from 'change-case';
import pluralize from 'pluralize';

export const formatFormErrors = ({
  errorInfo,
}: {
  errorInfo: ValidateErrorEntity;
}) => {
  const { errorFields, values } = errorInfo;
  const codes = getCodes();
  const pgaFlags = get(codes, 'CBP.CATAIR.PGA.flag', {});
  const nameMap: { [key: string]: { title?: string; identifier?: string } } = {
    containers: {
      identifier: 'number',
    },
    invoices: {
      identifier: 'invoiceNumber',
    },
    tariffs: {
      identifier: 'htsNumber',
    },
    masterBills: {
      identifier: 'number',
    },
    houseBills: {
      identifier: 'number',
    },
    isf: {
      title: 'ISF',
    },
    pga: {
      title: 'PGA',
    },
    us: {
      title: 'U.S.',
    },
    htsNumber: {
      title: 'HTS Number',
    },
    ...Object.entries(pgaFlags).reduce(
      (
        acc: { [key: string]: { title: string } },
        [flag, data]: [string, any],
      ) => {
        acc[flag] = {
          title: data.agencyCode,
        };
        return acc;
      },
      {},
    ),
  };

  // we will traverse down the field name and map elements as needed
  const errorMessages = errorFields.map(({ name }) => {
    const partialName: (string | number)[] = []; // this is the name up to the point of the current element;
    const mappedNameElements = name.map((nameElement) => {
      partialName.push(nameElement);
      let identifierValue; // can be an index, like line 2, or something custom, like HTS 1234.56.7890;
      const mappingInfo = nameMap[nameElement as keyof typeof nameMap];

      if (typeof nameElement === 'number') {
        nameElement += 1; // make array indexes 1-indexed instead of 0-indexed.
      }
      let titleValue = pluralize.singular(capitalCase(nameElement.toString()));
      if (mappingInfo) {
        const { identifier, title } = mappingInfo;
        // custom mapping
        if (identifier) {
          // in this case, we don't want to use the default (the index of the item), instead we want to use some other identifier.
          identifierValue = get(values, [...partialName, identifier]);
        }
        if (title) titleValue = title;
      }
      // we don't have a custom identifier set, let's use the item's index if we have one.
      if (identifierValue) {
        titleValue += ` ${identifierValue}`;
      }
      return titleValue;
    });

    const formattedErrorMessage = mappedNameElements.join(', ');

    return formattedErrorMessage;
  });

  return {
    title: 'Invalid fields',
    messages: errorMessages,
  };
};
