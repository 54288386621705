import { shipperId, shipperName } from './party';
import { EsDataType } from '../../enums';
import { createPartyTable } from './createPartyTable';

export const customsAgent = createPartyTable({
  columns: {
    shipperId,
    shipperName,
    duns: {
      default: true,
      name: 'DUNS',
      type: EsDataType.KEYWORD,
    },
    city: {
      default: true,
      name: 'City',
      type: EsDataType.TEXT,
    },
    stateName: {
      default: true,
      name: 'State',
      type: EsDataType.TEXT,
    },
  },
});
