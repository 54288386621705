import { OperatorUser } from '@xbcb/api-gateway-client';

export const isOperatorUser = (obj: unknown): obj is OperatorUser => {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    'name' in obj &&
    'assignmentTeams' in obj
  );
};
