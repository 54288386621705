import styled from 'styled-components';
import FormItem, { StyledFormItemType } from '../FormItem';

export const StyledFormItem: StyledFormItemType = styled(FormItem)`
  .ant-picker-clear,
  .ant-picker {
    // TODO consolidate this into a variable, it's also used in FormItem
    background: rgb(242, 242, 242);
  }

  .ant-picker {
    width: 100%;
  }
`;
