import {
  ChargeCode,
  InvoiceSource,
  InvoiceStatus,
  InvoiceType,
} from '@xbcb/finance-types';
import { AccountType, AnyObject, LoadType } from '@xbcb/shared-types';
import { EsDataType, expirationTime } from '../..';
import { shipperId, shipperName } from '../party';
import { createTableConfig } from '../createTableConfig';

const columns: AnyObject = {
  expirationTime,
  shipperName,
  shipperId,
  iorName: {
    name: 'IOR name',
    type: EsDataType.TEXT,
  },
  number: {
    default: true,
    name: 'Invoice Doc #',
    type: EsDataType.KEYWORD,
  },
  type: {
    default: true,
    name: 'Invoice Type',
    type: EsDataType.KEYWORD,
    optionValues: Object.values(InvoiceType),
  },
  forwarderId: {
    default: true,
    name: 'Forwarder Id',
    type: EsDataType.KEYWORD,
  },
  forwarderName: {
    default: false,
    name: (accountType: AccountType) =>
      accountType === AccountType.FORWARDER ? 'Agent Name' : 'Forwarder Name',
    type: EsDataType.TEXT,
  },
  billToPartyId: {
    default: true,
    name: 'Bill to party Id',
    type: EsDataType.KEYWORD,
    hidden: true,
  },
  billToPartyName: {
    default: true,
    name: 'Bill to party Name',
    type: EsDataType.TEXT,
  },
  shipmentFriendlyId: {
    name: 'Shipment ID',
    type: EsDataType.KEYWORD,
    default: true,
  },
  paymentMethod: {
    name: 'Payment Method',
    type: EsDataType.KEYWORD,
    default: true,
    optionValues: Object.values(InvoiceSource),
  },
  total: {
    default: true,
    name: 'Total',
    type: EsDataType.FLOAT,
  },
  invoicedTime: {
    default: true,
    name: 'Invoice Date',
    type: EsDataType.TIME,
  },
  dueTime: {
    default: true,
    name: 'Due Date',
    type: EsDataType.TIME,
  },
  status: {
    default: true,
    name: 'Invoice Status',
    type: EsDataType.KEYWORD,
    optionValues: Object.values(InvoiceStatus),
  },
  workOrderGroupId: {
    default: true,
    name: 'Work Order Group Id',
    type: EsDataType.KEYWORD,
  },
  qboId: {
    default: true,
    name: 'Qbo Invoice Id',
    type: EsDataType.KEYWORD,
  },
  qboNumber: {
    default: true,
    name: 'Qbo Invoice Doc Number',
    type: EsDataType.KEYWORD,
  },
  claimId: {
    default: true,
    name: 'Claim Id',
    type: EsDataType.KEYWORD,
  },
  bookingId: {
    name: 'Booking ID',
    type: EsDataType.KEYWORD,
  },
  loadType: {
    name: 'Load Type',
    type: EsDataType.KEYWORD,
    optionValues: Object.values(LoadType),
  },
  masterBill: {
    default: true,
    name: 'Master Bill #',
    type: EsDataType.TEXT,
  },
  houseBill: {
    default: true,
    name: 'House Bill #',
    type: EsDataType.TEXT,
  },
  poNumber: {
    name: 'Ref #',
    type: EsDataType.KEYWORD,
  },
  arInvoiceId: {
    default: true,
    name: 'AR Invoice ID',
    type: EsDataType.KEYWORD,
  },
  chargeCode: {
    default: true,
    name: 'Charge Code',
    type: EsDataType.KEYWORD,
    optionValues: Object.values(ChargeCode),
  },
};

export const invoice = createTableConfig({
  columns,
  tableOptions: {
    sortField: 'billToPartyName',
  },
});
