import { createTableConfig } from '../createTableConfig';
import { EsDataType } from '../../index';

export const onboardingRequest = createTableConfig({
  columns: {
    // ...userColumns,
    name: { default: true, name: 'Name', type: EsDataType.TEXT },
    email: {
      default: true,
      name: 'Email',
      type: EsDataType.TEXT,
    },
    title: {
      default: true,
      name: 'Title',
      type: EsDataType.TEXT,
    },
    accountType: {
      default: true,
      name: 'Account Type',
      type: EsDataType.KEYWORD,
    },
    onboardingStatus: {
      default: true,
      name: 'Onboarding Status',
      type: EsDataType.KEYWORD,
    },
  },
});
