import styled, { css } from 'styled-components';

const fullWidth = css`
  width: 100%;
  margin-right: 0;
`;

export const StyledDiv = styled.div<{
  $fullWidth?: boolean;
}>`
  width: 25%;
  height: 158px;
  margin-bottom: ${({ theme }) => theme.spacing.space_4};

  ${({ $fullWidth }) => ($fullWidth ? fullWidth : 'min-width: 312px;')}
  .ant-upload {
    padding: 0;
    height: 158px;
    .anticon {
      font-size: ${({ theme }) => theme.spacing.space_6};
    }
  }
  .ant-upload-list {
    display: none;
  }
  & {
    :hover {
      .anticon {
        color: ${({ theme }) => theme.palette.primaryColor};
      }
    }
    :active {
      .anticon {
        color: ${({ theme }) => theme.palette.primaryColor};
      }
    }
  }
`;
