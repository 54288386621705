import { WorkOrderTask } from '@xbcb/api-gateway-client';
import { WorkOrderTaskStatus } from '@xbcb/work-order-types';

export const taskIsInProgress = (task: WorkOrderTask) => {
  const inProgressTaskStatus = [
    WorkOrderTaskStatus.IN_PROGRESS,
    WorkOrderTaskStatus.ASSIGNED,
    WorkOrderTaskStatus.NOT_ASSIGNED,
  ];
  // If the task status is consider in progress, return that task
  return inProgressTaskStatus.includes(task.status as WorkOrderTaskStatus);
};

export const findMostRecentInProgressTask = (tasks: WorkOrderTask[]) => {
  if (tasks.length === 0) return;
  // In the future we may support multiple tasks being IN_PROGRESS and we want to find the most recent one.
  for (let i = tasks.length - 1; i >= 0; i--) {
    const task = tasks[i];
    if (taskIsInProgress(task)) return task;
  }
  return;
};
