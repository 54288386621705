import { AccountType } from '@xbcb/shared-types';
import { EsDataType } from '../../index';
import { createTableConfig } from '../createTableConfig';
import { sharedCancellationColumns } from '../sharedCancellationColums';

export const workOrderGroup = createTableConfig({
  tableOptions: { whitelist: [AccountType.OPERATOR] }, // TODO feature to be added at top level
  columns: {
    workOrderId: {
      // parent interface id
      name: 'Work Order Id',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    shipperId: {
      name: 'Shipper Id',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    forwarderId: {
      name: 'Forwarder Id',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    brokerId: {
      name: 'Broker Id',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    status: {
      name: 'Work Order Group Status',
      type: EsDataType.KEYWORD,
      default: true,
    },
    ...sharedCancellationColumns,
  },
});
