import { CrudPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const OperatorUserBulkMilestoneUploadRequestPermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserBulkChargesUploadRequestPermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserBulkComplianceUploadRequestPermissionName = {
  ...CrudPermissionName,
};

export default {
  [ObjectType.BULK_MILESTONE_UPLOAD_REQUEST]:
    OperatorUserBulkMilestoneUploadRequestPermissionName,
  [ObjectType.BULK_CHARGES_UPLOAD_REQUEST]:
    OperatorUserBulkChargesUploadRequestPermissionName,
  [ObjectType.BULK_COMPLIANCE_UPLOAD_REQUEST]:
    OperatorUserBulkComplianceUploadRequestPermissionName,
};
