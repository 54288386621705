import { message } from 'antd';
// import GA from 'react-ga';
import { UiStage } from '@xbcb/ui-types';
import { get } from 'lodash';
import { getEnv } from '@xbcb/ui-env';

export const GENERAL_ERROR =
  'Sorry, an error occurred, please try again later.';

export const networkError = (
  e: any,
  override?: boolean,
): boolean | undefined => {
  const { stage } = getEnv();
  let network = e.message.indexOf('NetworkError') !== -1;
  network = network || e.message.indexOf('Network Error') !== -1;
  const notFound = e.message.indexOf('NotFound') !== -1;
  let description = 'No description';
  if (network || notFound || override) {
    message.error(
      'Oops! There seems to be a network issue, please check your connection and try again',
      6.0,
    );
    description = e.message;
  } else {
    const errorCode =
      get(e, ['graphQLErrors', 0, 'extensions', 'code']) ||
      get(e, ['extensions', 'code']); // this is the level that the onError function receives data at. Apollo client can batch requests, so e could be all the requests errors. If using in a single useMutation/useQuery onError prop, it would just be that single request.
    const errorQuery =
      get(e, ['graphQLErrors', 0, 'path', 0]) ||
      get(e, ['path', 0]) ||
      get(e, ['extensions', 'path', 0]) ||
      get(e, ['extensions', 'exception', 'path', 0]);

    let messageText = e.message || get(e, 'response.data.message');

    if (messageText) {
      // these errors should be silent
      if (messageText.includes('does not match the latest version')) {
        return;
      }
      if (messageText.startsWith('GraphQL error: ')) {
        messageText = messageText.slice(15);
      }
      // TODO consider showing actual errors on Gamma once more stable
      // can add 'stage === UiStage.LOCAL' here when we want to see gamma/prod behavior
      if (stage === UiStage.PROD || stage === UiStage.GAMMA) {
        if (
          allowlistedErrorCodes.includes(errorCode) &&
          allowlistedErrorQueries.includes(errorQuery)
        ) {
          message.error(messageText, 7);
        } else {
          message.error(GENERAL_ERROR, 5);
        }
      } else {
        message.error(messageText, 5);
      }
      description = messageText;
    } else {
      description = e.toString();
    }
  }
  if (stage === UiStage.PROD) {
    // GA.exception({
    //   description,
    //   fatal: true,
    // });
  }
  return network;
};

// Allow to expose prod UI
const allowlistedErrorCodes = ['BAD_USER_INPUT'];
const allowlistedErrorQueries = [
  'validateUsConsumptionEntry',
  'createUsIorContinuousBondRequest',
  'updateUsIorActivationStatus',
];
