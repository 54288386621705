import gql from 'graphql-tag';
import { getRecordName, RecordProps } from '../shared';

interface SearchTableProps extends RecordProps {}

export const SearchTableQuery = ({
  recordName,
  fields = '',
  fragments = '',
}: SearchTableProps) => {
  const RecordName = getRecordName(recordName);
  return gql`
    query search${RecordName}Table($input: Search${RecordName}TableInput!) {
      search${RecordName}Table(input: $input) {
        results {
          ${fields}
        }
        paginationInfo {
          pageNumber
          pageSize
          totalPages
        }
        totalResults
      }
    }
    ${fragments}
  `;
};
