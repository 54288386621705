import { pick } from 'lodash';
import { AnyObject } from '@xbcb/shared-types';

/** A reference key that points to where the record was saved */
export interface SavedRecordFieldsReference {
  referenceKey: string;
}

/**
 * An abstract class to be extended to create an object that saves fields off of an object in some way
 * @template {AnyObject} SubObjectType a type representing the fields to save off of FullObjectType.  These fields will be the only fields available to select in "fieldsToSave"
 * @template {SubObjectType} FullObjectType the type of the object for which this RecordSaver will save fields
 */
export abstract class RecordSaver<
  SubObjectType extends AnyObject,
  FullObjectType extends SubObjectType,
> {
  /** A list of keys used at run time to save fields of off the FullObjectType object */
  public readonly fieldsToSave: (keyof SubObjectType)[];

  constructor(fieldsToSave: (keyof SubObjectType)[]) {
    this.fieldsToSave = fieldsToSave;
  }

  /**
   * Creates a SubObjectType object from the input FullObjectType object using
   *  the "fieldsToSave" values.  This implementation should work for all extensions of RecordSaver
   * @param {FullObjectType} record the object to get the fields from
   * @return {SubObjectType} an object containing the fields from fieldsToSave
   */
  private getFields(record: FullObjectType): SubObjectType {
    const savedFields: SubObjectType = pick(record, this.fieldsToSave);
    return savedFields;
  }

  /**
   * The function that determines how the data from getFields is saved.  To be decided by implementer
   * @param {SubObjectType} fields the fields returned from "getFields"
   */
  protected abstract saveFields(
    fields: SubObjectType,
  ): Promise<SavedRecordFieldsReference>;

  /**
   * Saves the SavedFields from the given object using the "getFields" and "saveFields" methods
   * @param {FullObjectType} record the object from which to save "fieldsToSave"
   * @return {SavedRecordFieldsReference} a reference to the where the fields were saved
   */
  public save(record: FullObjectType): Promise<SavedRecordFieldsReference> {
    const fields = this.getFields(record);
    return this.saveFields(fields);
  }
}
