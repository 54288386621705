import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

const glitchWatchFeatureName = 'GLITCHWATCH_FEATURE';

export const GlitchWatchFeatures = {
  GLITCHWATCH_FEATURE: glitchWatchFeatureName,
  CAPTURE_ERRORS: 'CAPTURE_ERRORS',
  UI_ALERT_BUTTON: 'UI_ALERT_BUTTON',
  UI_ADMIN_VIEW: 'UI_ADMIN_VIEW',
};

const GLITCHWATCH_FEATURE = new FeatureFlagService([
  {
    feature: glitchWatchFeatureName,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA],
  },
  {
    feature: GlitchWatchFeatures.CAPTURE_ERRORS,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA, Stage.PROD],
  },
  {
    feature: GlitchWatchFeatures.UI_ALERT_BUTTON,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA],
  },
  {
    feature: GlitchWatchFeatures.UI_ADMIN_VIEW,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA],
  },
]);

export { GLITCHWATCH_FEATURE, glitchWatchFeatureName };
