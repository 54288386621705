import gql from 'graphql-tag';
import { dedupRecordQuery, FieldsAndFragments } from './shared';

export const updateTemplateDocumentSignRequestsWithSignaturesMutation = ({
  fields = '',
  fragments = '',
}: FieldsAndFragments) => {
  const { fieldsString, fragmentsString } = dedupRecordQuery(fields, fragments);
  return gql`
    mutation updateTemplateDocumentSignRequestsWithSignatures(
      $input: UpdateTemplateDocumentSignRequestsWithSignaturesInput!
    ) {
      updateTemplateDocumentSignRequestsWithSignatures(input: $input) {
        records {
          ${fieldsString}
        }
      }
    }
    ${fragmentsString}
  `;
};
