import React from 'react';
import { Checkbox } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import FormItem from '../FormItem';
import { show } from '@xbcb/ui-utils';
import { NamePath } from '@xbcb/ui-types';
import { StyledInfoTooltip } from './styles';

type CheckboxProps = {
  readOnly?: boolean;
  disabled?: boolean;
  fullNamePath: NamePath;
  localNamePath?: NamePath;
  text?: string;
  tooltip?: string;
  required?: boolean;
  form: FormInstance;
  onChange?: (e: CheckboxChangeEvent) => void;
  $spaceLeft?: boolean;
  $inline?: boolean;
  $removeSpaceBottom?: boolean;
  $spaceTop?: boolean;
};

const INLTCheckbox = ({
  readOnly,
  disabled,
  fullNamePath,
  localNamePath,
  text,
  tooltip,
  required,
  form,
  onChange,
  $spaceLeft,
  $inline,
  $removeSpaceBottom,
  $spaceTop,
}: CheckboxProps) => {
  localNamePath = localNamePath ? localNamePath : fullNamePath;
  return show({ readOnly, form, field: fullNamePath }) ? (
    <>
      <FormItem
        name={localNamePath}
        rules={[{ required, message: ' ' }]}
        valuePropName="checked"
        $spaceLeft={$spaceLeft}
        $inline={$inline}
        $readOnly={readOnly}
        $removeSpaceBottom={$removeSpaceBottom}
        $spaceTop={$spaceTop}
      >
        <Checkbox onChange={onChange} disabled={disabled}>
          {text}
        </Checkbox>
      </FormItem>
      {tooltip && <StyledInfoTooltip title={tooltip} overlayClassName="" />}
    </>
  ) : null;
};

INLTCheckbox.Group = Checkbox.Group;

export default INLTCheckbox;
