import gql from 'graphql-tag';
import { dedupRecordQuery } from '../shared';

export type ModifyWorkOrderTaskStepsCompletionProps = {
  fields?: string;
  fragments?: string;
};

export const modifyWorkOrderTaskStepsCompletionMutation = ({
  fields = '',
  fragments = '',
}: ModifyWorkOrderTaskStepsCompletionProps) => {
  const { fieldsString, fragmentsString } = dedupRecordQuery(fields, fragments);
  return gql`
    mutation modifyWorkOrderTaskStepsCompletion(
      $steps: [ModifiedWorkOrderTaskStepInput!]!
      $taskId: ID!
      $version: Int!
    ) {
      modifyWorkOrderTaskStepsCompletion(
        steps: $steps
        taskId: $taskId
        version: $version
      ) {
        workOrderTask {
          ${fieldsString}
        }
      }
    }
    ${fragmentsString}
  `;
};
