import DisSupportedDocuments from './disSupportedDocuments.json';
import { DocumentTag } from '../enums';

enum AgencyCode {
  EPA = 'EPA',
  CBP = 'CBP',
}

type LabelCode = string;

export type DisDocument = {
  readonly agencyCode: AgencyCode;
  readonly name: string;
  readonly labelCode: LabelCode;
};

export class DisDocuments {
  static readonly SUPPORTED_DOCUMENTS: DisDocument[] =
    DisSupportedDocuments as DisDocument[];

  private static readonly ALTERNATE_DOCUMENT_TAG_MAPPING = new Map<
    DocumentTag,
    LabelCode
  >([
    [DocumentTag.EPA_3520_1, 'EPA_3520_1_IMPORTER_OF_MOTOR_V_E_ON_ROAD'],
    [DocumentTag.EPA_3520_21, 'EPA_3520_21_IMPORT_OF_MOTOR_V_E_OFF_ROAD'],
    [DocumentTag.EPA_3540_01, 'EPA_3540_1_NOA'],
  ]);

  static getDisDocumentByDocumentTag(
    documentTag: DocumentTag,
  ): DisDocument | undefined {
    const labelCode =
      DisDocuments.ALTERNATE_DOCUMENT_TAG_MAPPING.get(documentTag) ??
      documentTag;

    return DisDocuments.SUPPORTED_DOCUMENTS.find(
      (document) => document.labelCode === labelCode,
    );
  }
}
