import { omit } from 'lodash';
export const CrudPermissionName = {
  ADMIN: 'ADMIN',
  CREATE: 'CREATE',
  READ: 'READ',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
};

export const NoAccessPermissionName = {
  NO_ACCESS: 'NO_ACCESS',
};

export const UsIorPermissionName = {
  ...CrudPermissionName,
  SUB_POA_AUTHORITY: 'SUB_POA_AUTHORITY',
};

export const UsIsfPermissionName = {
  ...CrudPermissionName,
  CREATE_CBP_TRANSACTION: 'CREATE_CBP_TRANSACTION',
  UPDATE_CBP_TRANSACTION: 'UPDATE_CBP_TRANSACTION',
  DELETE_CBP_TRANSACTION: 'DELETE_CBP_TRANSACTION',
};

export const UsInBondPermissionName = {
  ...CrudPermissionName,
  IN_BOND_SUBMIT_CBP_TRANSACTION: 'IN_BOND_SUBMIT_CBP_TRANSACTION',
  IN_BOND_DELETE_CBP_TRANSACTION: 'IN_BOND_DELETE_CBP_TRANSACTION',
  IN_BOND_SUBMIT_ACTION_CBP_TRANSACTION:
    'IN_BOND_SUBMIT_ACTION_CBP_TRANSACTION',
  IN_BOND_DELETE_BILL_CBP_TRANSACTION: 'IN_BOND_DELETE_BILL_CBP_TRANSACTION',
};

export const CompanyPermissionName = omit(CrudPermissionName, 'DELETE');
