export enum DocumentSignRequestStatus {
  // The request is pending signature from the client.
  PENDING_SIGNATURE = 'PENDING_SIGNATURE',

  // The document has been signed.
  SIGNED = 'SIGNED',

  // The generation request has been voided.
  VOID = 'VOID',
}
