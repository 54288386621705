import {
  addressFields,
  recordActionFields,
  recordFields,
  phoneFields,
} from '@xbcb/shared-queries';
// TODO Use graphql-tag
/**
 * This file defines the Party record fields returned in query result.
 *
 * Rules:
 * 1. As this is a shared package, changes to these strings should be backward compatible and in sync
 * with the latest Party schema.
 * 2. Add all required fragment substrings to only top level party record query strings.
 * Query string can not have duplicate fragment definitions. (they can have duplicate fields but we should avoid this as a best practice)
 * Ex: phoneFields fragment is used in multiple fragments. To avoid duplicate phoneField fragment definitions,
 * suffix ${phoneFields} to top level usIorFields/usConsigneeFields fragment only.
 *
 * @author goyad
 */

// TODO deprecated, remove once consumes update to import form @xbcb/shared-queries
export { phoneFields };

export const iorNumberFields = `
fragment iorNumberFields on UsIorNumber {
  type
  value
}`;

export const pointOfContactFields = `
fragment pointOfContactFields on PointOfContact {
  name
  title
  email
  phone {
    ...phoneFields
  }
  user {
    id
  }
}`;

export const passportFields = `
fragment passportFields on Passport {
  number
  expiration
  countryCode
  type
}`;

export const addressesFields = `
fragment addressesFields on Party {
  addresses {
    physical {
      ...addressFields
    }
    mailing {
      ...addressFields
    }
  }
}
${addressFields}
`;

export const partyFields = `
fragment partyFields on Party {
  name
  alternateName
  description
  ...addressesFields
  phone {
    ...phoneFields
  }
  email
  duns
  public
  pointOfContact {
    name
    title
    email
    phone {
      ...phoneFields
    }
    user { 
      id
    }
  }
}`;

export const partyFragments = `
${addressesFields}
${phoneFields}
${recordActionFields}
${recordFields}
${partyFields}
`;
