import { PartyRecordType } from '@xbcb/shared-types';
import { createPartyTable } from './createPartyTable';

// N.B. these must be exported after the declaration of createPartyTable above
export * from './customsAgent';
export * from './facility';
export * from './forwarder';
export * from './operator';
export * from './customsBroker';
export * from './usCustomsBroker';
export * from './ukCustomsBroker';
export * from './deCustomsBroker';
export * from './nlCustomsBroker';
export * from './frCustomsBroker';
export * from './shipper';
export * from './supplier';
export * from './trucker';
export * from './usConsignee';
export * from './gbConsignee';
export * from './deConsignee';
export * from './nlConsignee';
export * from './frConsignee';
export * from './usIor';
export * from './deIor';
export * from './gbIor';
export * from './nlIor';
export * from './frIor';
export * from './party';
export * from './team';
export * from './importer';
export * from './importerEntity';
export * from './broker';
export * from './globalConsignee';

export const party = createPartyTable({
  tableOptions: { recordTypes: Object.values(PartyRecordType) },
});
