export const UsCbpEntryExamDescription =
  'Exam details associated with the entry.';

export const ExamSiteDescription = 'Firms code associated with the exam site.';

export const UsCbpEntryExamTypeDescription =
  'The type of the exam conducted on the entry.';

export const UsCbpEntryExamSubTypeDescription =
  'The specific type of exam conducted on the entry.';

export const UsCbpEntryExamStatusDescription =
  'The status of the exam being conducted on the entry.';

export const UsCbpEntryExamContainerDescription =
  'Containers associated with the exam.';

export const UsCbpEntryLspNotifiedDate = 'Last date the LSP was notified on.';

export enum UsCbpEntryExamType {
  INTENSIVE = 'INTENSIVE',
  NON_INTENSIVE = 'NON_INTENSIVE',
  PGA = 'PGA',
}

export const UsCbpEntryExamTypeOptionDescription: {
  [key in UsCbpEntryExamType]: string;
} = {
  INTENSIVE: 'Intensive',
  NON_INTENSIVE: 'Non-Intensive',
  PGA: 'PGA',
};

export enum UsCbpEntryIntensiveExamType {
  CET = 'CET',
  MET = 'MET',
  AQI = 'AQI',
  FUMIGATION = 'FUMIGATION',
  TRADE = 'TRADE',
  UFLPA = 'UFLPA',
}

export const UsCbpEntryIntensiveExamTypeOptionDescription: {
  [key in UsCbpEntryIntensiveExamType]: string;
} = {
  CET: 'CET',
  MET: 'MET',
  AQI: 'AQI',
  FUMIGATION: 'Fumigation',
  TRADE: 'Trade',
  UFLPA: 'UFLPA',
};

export enum UsCbpEntryNonIntensiveExamType {
  BILL_HOLD = 'BILL_HOLD',
  DOCUMENT_REQUEST = 'DOCUMENT_REQUEST',
  NII = 'NII',
  TAILGATE = 'TAILGATE',
  EPA_REVIEW = 'EPA_REVIEW',
  ISF_HOLD = 'ISF_HOLD',
}

export const UsCbpEntryNonIntensiveExamTypeOptionDescription: {
  [key in UsCbpEntryNonIntensiveExamType]: string;
} = {
  BILL_HOLD: 'Bill Hold',
  DOCUMENT_REQUEST: 'Document Request',
  NII: 'NII',
  TAILGATE: 'Tailgate',
  EPA_REVIEW: 'EPA Review',
  ISF_HOLD: 'ISF Hold',
};

export enum UsCbpEntryPgaExamType {
  FDA_HOLD = 'FDA_HOLD',
  FDA_EXAM = 'FDA_EXAM',
  CPSC_EXAM = 'CPSC_EXAM',
  OTHER_PGA_EXAM = 'OTHER_PGA_EXAM',
  EPA_EXAM = 'EPA_EXAM',
}

export const constUsCbpEntryPgaExamTypeOptionDescription: {
  [key in UsCbpEntryPgaExamType]: string;
} = {
  FDA_HOLD: 'FDA Hold',
  FDA_EXAM: 'FDA Exam',
  CPSC_EXAM: 'CPSC Exam',
  OTHER_PGA_EXAM: 'Other PGA Exam',
  EPA_EXAM: 'EPA Exam',
};

export const UsCbpEntryExamSubType = {
  ...UsCbpEntryIntensiveExamType,
  ...UsCbpEntryNonIntensiveExamType,
  ...UsCbpEntryPgaExamType,
};

export type UsCbpEntryExamSubType =
  | UsCbpEntryIntensiveExamType
  | UsCbpEntryNonIntensiveExamType
  | UsCbpEntryPgaExamType;

export enum UsCbpEntryExamStatus {
  ON_VESSEL = 'ON_VESSEL',
  PENDING_PICKUP_AT_TERMINAL = 'PENDING_PICKUP_AT_TERMINAL',
  AT_CFS_ON_HOLD = 'AT_CFS_ON_HOLD',
  TERMINAL_OUT_GATE = 'TERMINAL_OUT_GATE',
  AT_CFS_PENDING_EXAM_TRANSFER = 'AT_CFS_PENDING_EXAM_TRANSFER',
  AT_EXAM_SITE_PENDING_EXAM = 'AT_EXAM_SITE_PENDING_EXAM',
  CES_DEVANNING = 'CES_DEVANNING',
  EXAM_IN_PROGRESS = 'EXAM_IN_PROGRESS',
  AT_FUMIGATION_SITE = 'AT_FUMIGATION_SITE',
  CBP_HOLD_AT_CES = 'CBP_HOLD_AT_CES',
  EXAM_COMPLETED = 'EXAM_COMPLETED',
  CLEARED_FOR_PICKUP_1USG = 'CLEARED_FOR_PICKUP_1USG',
  EXAM_OUT_GATE = 'EXAM_OUT_GATE',
  SPECIAL_EXCEPTION_DETAINED = 'SPECIAL_EXCEPTION_DETAINED',
  SPECIAL_EXCEPTION_RECONDITIONING = 'SPECIAL_EXCEPTION_RECONDITIONING',
  SPECIAL_EXCEPTION_PARTIAL_RELEASE = 'SPECIAL_EXCEPTION_PARTIAL_RELEASE',
  SPECIAL_EXCEPTION_DESTRUCTION = 'SPECIAL_EXCEPTION_DESTRUCTION',
  SPECIAL_EXCEPTION_EXPORT = 'SPECIAL_EXCEPTION_EXPORT',
  SPECIAL_EXCEPTION_COMPLETED = 'SPECIAL_EXCEPTION_COMPLETED',
  SPECIAL_EXCEPTION_RECONDITIONING_COMPLETED = 'SPECIAL_EXCEPTION_RECONDITIONING_COMPLETED',
  SPECIAL_EXCEPTION_DESTRUCTION_COMPLETED = 'SPECIAL_EXCEPTION_DESTRUCTION_COMPLETED',
  SPECIAL_EXCEPTION_EXPORT_COMPLETED = 'SPECIAL_EXCEPTION_EXPORT_COMPLETED',
  SPECIAL_EXCEPTION_SEIZED = 'SPECIAL_EXCEPTION_SEIZED',
}

export const UsCbpEntryExamStatusOptionDescription: {
  [key in UsCbpEntryExamStatus]: string;
} = {
  ON_VESSEL: 'On Vessel',
  PENDING_PICKUP_AT_TERMINAL: 'Pending Pickup at Terminal',
  AT_CFS_ON_HOLD: 'At CFS on Hold',
  TERMINAL_OUT_GATE:
    'Container has either moved to the CFS or LCL or is on the move to exam site for FCL',
  AT_CFS_PENDING_EXAM_TRANSFER: 'At CFS pending exam transfer',
  AT_EXAM_SITE_PENDING_EXAM: 'At Exam Site pending exam (Exam In-Gate)',
  CES_DEVANNING: 'CES Devanning',
  EXAM_IN_PROGRESS: 'Exam in Progress',
  AT_FUMIGATION_SITE: 'At Fumigation Site',
  CBP_HOLD_AT_CES: 'CBP hold at CES',
  EXAM_COMPLETED: 'Exam Completed',
  CLEARED_FOR_PICKUP_1USG: 'Cleared for pickup / 1USG',
  EXAM_OUT_GATE: 'Exam Out-Gate',
  SPECIAL_EXCEPTION_DETAINED: 'Special exception - detained',
  SPECIAL_EXCEPTION_RECONDITIONING: 'Special exception - Reconditioning',
  SPECIAL_EXCEPTION_PARTIAL_RELEASE: 'Special exception - Partial Release',
  SPECIAL_EXCEPTION_DESTRUCTION: 'Special exception - Destruction',
  SPECIAL_EXCEPTION_EXPORT: 'Special exception - Export',
  SPECIAL_EXCEPTION_COMPLETED: 'Special exception - Completed',
  SPECIAL_EXCEPTION_RECONDITIONING_COMPLETED:
    'Special exception - Reconditioning Completed',
  SPECIAL_EXCEPTION_DESTRUCTION_COMPLETED:
    'Special exception - Destruction Completed',
  SPECIAL_EXCEPTION_EXPORT_COMPLETED: 'Special exception - Export Completed',
  SPECIAL_EXCEPTION_SEIZED: 'Special Exception - Seized',
};
