import { DocumentNode, FieldNode } from 'graphql';
import { getOperationDefinitionFromDocument } from './getOperationDefinitionFromDocument';

export const getOperationNameFromDocument = (
  document: DocumentNode,
): string | undefined => {
  const operationDefinition = getOperationDefinitionFromDocument(document);
  const selection = operationDefinition?.selectionSet?.selections[0] as
    | FieldNode
    | undefined;
  return selection?.name?.value;
};
