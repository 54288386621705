import { get } from 'lodash';

export const formatInvalidFieldErrors = ({
  invalidFields,
  pgaFlags,
  shipment,
}) => {
  const nameMap = {
    manufacturers: {
      title: 'Manufacturer',
    },
    products: {
      title: 'Product',
    },
    sellers: {
      title: 'Seller',
    },
    lines: {
      title: 'Line',
    },
    containers: {
      title: 'Container',
      identifier: 'number',
    },
    invoices: {
      title: 'Invoice',
      identifier: 'invoiceNumber',
    },
    tariffs: {
      title: 'Tariff',
      identifier: 'htsNumber',
    },
    masterBills: {
      title: 'Master Bill',
      identifier: 'number',
    },
    houseBills: {
      title: 'House Bill',
      identifier: 'number',
    },
    isf: {
      title: 'ISF',
    },
    pga: {
      title: 'PGA',
    },
    ...Object.entries(pgaFlags).reduce((acc, [flag, data]) => {
      acc[flag] = {
        title: data.agencyCode,
      };
      return acc;
    }, {}),
  };

  const errorMessages = invalidFields.map((fullErrorKey) => {
    // remove `.errors` from fullErrorKey and split it constituent parts of the key.
    const keyParts = fullErrorKey.split(/\./);

    let fullKey = '';
    // key parts are the parts that make up a key so topLevel[1].midLevel[3].leaf[2] would become topLevel[1], midLevel[3], and leaf[2]
    const mappedKeyParts = keyParts.map((keyPart) => {
      let identifierValue; // can be an index, like line 2, or something custom, like HTS 1234.56.7890;
      fullKey += keyPart + '.';
      let arrayIndex;
      const regexMatch = keyPart.match(/^(.*)\[(\d+)\]$/); // matches if this keyPart is for an array i.e. 'someKey[someIndex]'
      if (regexMatch) {
        keyPart = regexMatch[1];
        arrayIndex = regexMatch[2];
      }
      const mappingInfo = nameMap[keyPart];
      let titleValue = keyPart;
      if (mappingInfo) {
        const { identifier, title } = mappingInfo;
        // custom mapping
        if (identifier) {
          // in this case, we don't want to use the default (the index of the item), instead we want to use some other identifier.
          identifierValue = get(shipment, `props.${fullKey + identifier}`);
        }
        if (title) titleValue = mappingInfo.title;
      }
      // we don't have a custom identifier set, let's use the item's index if we have one.
      if (!identifierValue && arrayIndex && !isNaN(arrayIndex)) {
        identifierValue = parseInt(arrayIndex) + 1;
      }
      if (identifierValue) {
        titleValue += ` ${identifierValue}`;
      }
      return titleValue;
    });

    const formattedErrorMessage = mappedKeyParts.join(', ');

    return formattedErrorMessage;
  });

  return errorMessages;
};
