import { recordFields, recordActionFields } from '@xbcb/shared-queries';

export const workOrderStateMachineFragment = `fragment workOrderStateMachineFields on WorkOrderStateMachine {
  ...recordFields
  name
  description
  workOrderType
  start
  states {
    name
    description
    type
    end
    next
  }
}
`;
// TODO need initial records implemented in testAuthority so it can use this fragment
// taskDefinition {
//   ...workOrderTaskDefinitionFields
// }

export const workOrderStateMachineFragments = `
${workOrderStateMachineFragment}
${recordFields}
${recordActionFields}
`;
// ${workOrderTaskDefinitionFragment}
