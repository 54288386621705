import { SortOrder } from '@xbcb/shared-types';
import { createPartyTable } from './createPartyTable';
import { EsDataType } from '../../enums';

export const ukCustomsBroker = createPartyTable({
  columns: {
    eori: {
      hidden: true,
      name: 'EORI',
      type: EsDataType.KEYWORD,
    },
  },
  tableOptions: {
    sortOrder: SortOrder.DESC,
  },
});
