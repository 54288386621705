import { Stage } from '@xbcb/shared-types';

export enum UiStage {
  LOCAL = 'LOCAL',
  ALPHA = 'ALPHA',
  BETA = 'BETA',
  GAMMA = 'GAMMA',
  PROD = 'PROD',
}

const gatewayStage = process.env.REACT_APP_GATEWAY_STAGE ?? 'BETA';

// consolidate UiStage + Stage
export const uiStageToBackendStage = {
  // Local is not a backend stage, mapping to match the Gateway env variable, falling back to Beta
  [UiStage.LOCAL]: Stage[gatewayStage as keyof typeof Stage] || Stage.BETA,
  [UiStage.ALPHA]: Stage.BETA, // As per packages/apollo-client/src/client.ts mapping standard
  [UiStage.BETA]: Stage.BETA,
  [UiStage.GAMMA]: Stage.GAMMA,
  [UiStage.PROD]: Stage.PROD,
};
