export const teamFragments = `
fragment teamFields on Team {
    name
    account {
      id
    }
    operator {
      id
    }
  }`;
