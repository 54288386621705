import { ChargeCode } from '@xbcb/finance-types';
import { AccountType, RecordType } from '@xbcb/shared-types';
import { mapToAllUserTypes } from './mapToAllUserTypes';
import { StringMap } from '../biMap';
import { pluralize as pluralizeLib } from '../pluralize';
import { capitalCase } from 'capital-case';

export const locationNameMap = {
  countryCode: 'Country', // very important that this comes before stateCode, so that the mapping tool will be able to know countryCode when looking up stateName
  stateCode: 'State',
  postalCode: 'Postal Code',
  city: 'City',
  address: 'Street Address',
};

export const companyNameMap = {
  name: 'Company Name',
  dba: 'DBA',
  structure: 'Corporate Structure',
  iorNumber: 'IOR #',
  ...locationNameMap,
  authorized: 'Power of Attorney',
  officerEmail: 'Officer Email',
  officerName: 'Officer Name',
  officerTitle: 'Officer Title',
  secondaryOfficerEmail: '2nd Officer Email',
  secondaryOfficerName: '2nd Officer Name',
  secondaryOfficerTitle: '2nd Officer Title',
};

// TODO: some charges are not applicable to FBA/External customers
// hence, segregate logic to show only applicable charges in UI
export const unplannedCharges: StringMap = {
  [ChargeCode.DUTY]: 'Duties',
  [ChargeCode.PIERPASS_TMF]: 'PierPass TMF',
  [ChargeCode.PIERPASS_ADMIN]: 'PierPass administration fee',
  [ChargeCode.IMPORTER_SURCHARGE]: 'Importer surchage',
  [ChargeCode.EXAM_FEE]: 'Exam fee',
  [ChargeCode.DEMURRAGE]: 'Demurrage',
  [ChargeCode.TERMINAL_FEES]: 'CFS & terminal fees',
  [ChargeCode.TRUCKING]: 'Trucking',
  [ChargeCode.COURIER_FEE]: 'Courier fee',
  [ChargeCode.MANAGEMENT_FEE]: 'Management fee',
  [ChargeCode.PROTEST_FEE]: 'Protest Fee',
  [ChargeCode.COMPLIANCE_CONSULTING]: 'Compliance Consulting',
  [ChargeCode.POST_SUMMARY_CORRECTION_FEE]: 'PSC Fee',
  [ChargeCode.CONTINUOUS_BOND]: 'Continuous Bond',
};

export const EuPlannedCharges: StringMap = {
  [ChargeCode.AGENCY_FEE]: 'AGF_Agency Fee',
  [ChargeCode.ENTRY_FEE]: 'CUS_Customs Brokerage Fee',
  [ChargeCode.DESTINATION_PORT_INFRASTRUCTURE_FEE]:
    'DIA_Destination Port Infrastructure Fee',
  [ChargeCode.DESTINATION_PORT_SECURITY_FEE]:
    'DPS_Destination Port Security Fee',
  [ChargeCode.PARTNER_GOVERNMENT_AGENCY_HANDLING_FEE]:
    'PGH_Partner Government Agency Handling',
};

/**
 * Any charge added here will be available on the UI and in the Bulk Charges Uploader,
 *  however it will have to be manually added to the Bulk Charges Upload template
 *  for operators to be able to bulk upload it
 *  SOP: https://quip-amazon.com/VLPWAnubum2B/How-to-Update-Bulk-Upload-Templates
 *
 * NOTE: ENSURE THAT ANY CHARGE ADDED HERE IS ALSO REPRESENTED IN XbcbShared/packages/finance-sao/src/constants.ts:paymentManifestToCbmsChargeCode,
 *  else Bulk Upload will fail for that charge
 **/
export const euUnplannedCharges: StringMap = {
  [ChargeCode.ADDITIONAL_CUSTOMS_HANDLING_FEE]:
    'ACU_Additional Customs Handling',
  [ChargeCode.AGENCY_FEE]: 'AGF_Agency Fee',
  [ChargeCode.AMENDMENT_FEE]: 'CEA_Customs Entry Amendment',
  [ChargeCode.CUSTOMS_FINES_PASSTHROUGH]: 'CFN_Customs Fines Passthrough', // requires doc upload at entry level
  // [ChargeCode.ENTRY_FEE]: 'CUS_Customs Brokerage Fee', temporarily disabled as this charge is not supported by our backend yet
  [ChargeCode.ADDITIONAL_DESTINATION_PORT_INFRASTRUCTURE_FEE]:
    'DIA_Destination Port Infrastructure Fee',
  [ChargeCode.DISBURSEMENT_FEE]: 'DIS_Disbursement Fee',
  [ChargeCode.DESTINATION_PORT_SECURITY_FEE]:
    'DPS_Destination Port Security Fee',
  [ChargeCode.DUTY]: 'DUT_Duty Fees',
  [ChargeCode.EXAM_FEE]: 'EXM_Exam Charges', // requires doc upload at entry level
  [ChargeCode.EXAM_HANDLING]: 'EXH_Exam Handling',
  [ChargeCode.HTS_CLASSIFICATION_ADDITIONAL_TARRIF_HEADINGS]:
    'HTS_Classification - Additional Tariff Headings',
  [ChargeCode.ADDITIONAL_CUSTOMS_LINE]: 'LIN_Line Charge',
  [ChargeCode.PARTNER_GOVERNMENT_AGENCY_HANDLING_FEE]:
    'PGH_Partner Government Agency Handling',
  [ChargeCode.POA_SETUP_FEE]: 'POA_POA Set-up Fee',
  [ChargeCode.SELLER_SUPPORT_FEE]: 'SEF_Seller Support Fee',
  [ChargeCode.VALUE_ADDED_TAX]: 'VAT_VAT Fees',
  [ChargeCode.IMPORT_VALUE_ADDED_TAX]: 'IVT_Import VAT',
};

export const jpUnplannedCharges: StringMap = {
  [ChargeCode.AMENDMENT_FEE]: 'CEA_Customs Entry Amendment',
  [ChargeCode.COURIER_FEE]: 'COU_Courier Fee',
  [ChargeCode.ACP_FEE_PER_CLEARANCE]: 'ACP_ACP Fee Per Clearance',
  [ChargeCode.ADDITIONAL_DESTINATION_PORT_INFRASTRUCTURE_FEE]:
    'DIA_Destination Port Infrastructure Fee',
  [ChargeCode.DESTINATION_PORT_INFRASTRUCTURE_FEE]:
    'DPI_Destination Port Infrastructure Fee',
  [ChargeCode.DESTINATION_PORT_SECURITY_FEE]:
    'DPS_Destination Port Security fee',
  [ChargeCode.EXAM_HANDLING]: 'EXH_Exam Handling',
  [ChargeCode.EXAM_FEE]: 'EXM_Exam Charges',
  [ChargeCode.ADDITIONAL_CUSTOMS_LINE]: 'LIN_Additional Customs Lines',
  [ChargeCode.PARTNER_GOVERNMENT_AGENCY_HANDLING_FEE]:
    'PGH_Partner Government Agency Charges',
  [ChargeCode.POA_SETUP_FEE]: 'POA_POA Set-up Fee',
};

// Should not be exported because to properly format parties we need to know
// the accountType of the user. Thus to get the party display name use
// `formatRecordName` below
const activationWoDisplayName = 'IOR Activation';
const workOrderDisplayNameMap: { [key in RecordType]?: string } = {
  [RecordType.US_ISF]: 'ISF',
  [RecordType.US_TYPE86_ENTRY]: 'Type 86 Entry',
  [RecordType.US_CONSUMPTION_ENTRY]: 'Entry',
  [RecordType.GB_CUSTOMS_ENTRY]: 'Entry',
  [RecordType.NL_CUSTOMS_ENTRY]: 'Entry',
  [RecordType.FR_CUSTOMS_ENTRY]: 'Entry',
  [RecordType.DE_CUSTOMS_ENTRY]: 'Entry',
  [RecordType.US_IN_BOND]: 'In-Bond',
  [RecordType.US_DRAWBACK_CLAIM]: 'Drawback claim',
  [RecordType.DELIVERY_ORDER]: 'Delivery Order',
  [RecordType.US_IOR_CONTINUOUS_BOND_REQUEST]: 'Continuous Bond',
  [RecordType.US_IOR_ACTIVATION]: activationWoDisplayName,
  [RecordType.GB_IOR_ACTIVATION]: activationWoDisplayName,
  [RecordType.NL_IOR_ACTIVATION]: activationWoDisplayName,
  [RecordType.FR_IOR_ACTIVATION]: activationWoDisplayName,
  [RecordType.DE_IOR_ACTIVATION]: activationWoDisplayName,
  [RecordType.US_POST_SUMMARY_CORRECTION]: 'Post Summary Correction',
};

// Should not be exported because to properly format parties we need to know
// the accountType of the user. Thus to get the party display name use
// `formatRecordName` below
const iorDisplayName = 'Importer of Record';
// ref: https://t.corp.amazon.com/P63067406
const pluralizedIorDisplayName = 'Importers of Record';

const partyDisplayMap: { [key in RecordType]?: string } = {
  [RecordType.US_IOR]: iorDisplayName,
  [RecordType.DE_IOR]: iorDisplayName,
  [RecordType.GB_IOR]: iorDisplayName,
  [RecordType.NL_IOR]: iorDisplayName,
  [RecordType.FR_IOR]: iorDisplayName,
  [RecordType.US_CONSIGNEE]: 'Consignee',
  [RecordType.US_CUSTOMS_BROKER]: 'Us Customs Broker',
};

// Should not be exported because to properly format parties we need to know
// the accountType of the user. Thus to get the party display name use
// `formatRecordName` below
const userDisplayMap: { [key in RecordType]?: string } = {
  // Users do not understand the concept of multiple types of users. Thus, when
  // displaying we should user User/Users regardless of the user type
  [RecordType.FORWARDER_USER]: 'User',
  [RecordType.OPERATOR_USER]: 'User',
  [RecordType.SHIPPER_USER]: 'User',
};

// Not meant to be exported, users should instead call
// `formatRecordName` with `pluralize: true`
const pluralizeRecordName = ({
  recordType,
  displayName,
}: {
  recordType: RecordType;
  displayName: string;
}) => {
  // Check special cases first
  // `pluralizeLib` converts ISF => ISFS.
  if (recordType === RecordType.US_ISF) return 'ISFs';
  // Report Reconciliation should remain singular
  if (recordType === RecordType.REPORT_RECONCILIATION_REQUEST) {
    return displayName;
  }

  // can't user pluralize.addPluralRule as it doesn't respect the case of the replacement string
  if (displayName === iorDisplayName) {
    return pluralizedIorDisplayName;
  }
  // Otherwise, rely on pluralizeLib
  return pluralizeLib(displayName);
};

export const formatRecordName = ({
  recordType,
  accountType,
  pluralize,
}: {
  recordType: RecordType;
  accountType: AccountType;
  pluralize?: boolean;
}): string => {
  let displayName;
  // Check special cases first
  if (
    // Forwarders should see other forwarders as "agents"
    accountType === AccountType.FORWARDER &&
    recordType === RecordType.FORWARDER
  ) {
    displayName = 'Agent';
  }
  if (
    // Forwarders should see other shippers as "customers"
    accountType === AccountType.FORWARDER &&
    recordType === RecordType.SHIPPER
  ) {
    displayName = 'Customer';
  }
  if (recordType === RecordType.REPORT_RECONCILIATION_REQUEST) {
    displayName = 'Report Reconciliation';
  }
  const recordTypeDisplayMap = {
    ...workOrderDisplayNameMap,
    ...partyDisplayMap,
    ...userDisplayMap,
  };
  // If no special cases were found (i.e. `displayName` hasn't
  // been set yet), use the recordTypeDisplayMap
  if (!displayName) {
    // Fallback to capitalCase if not in the recordTypeDisplayMap
    displayName = recordTypeDisplayMap[recordType] || capitalCase(recordType);
  }
  return pluralize
    ? pluralizeRecordName({ recordType, displayName })
    : displayName;
};

export const nameMap = {
  invoiceBillTo: {
    name: 'Name',
    address: 'Address',
    region: 'Region',
  },
  invoiceDetails: {
    type: 'Type',
    total: 'Total',
    status: 'Status',
    paymentMethod: 'Payment Method',
    dueDate: 'Due Date',
  },
  invoiceReferences: {
    importer: 'Importer',
    vesselName: 'Vessel/Voyage',
    masterBill: 'MBL/MAWB Number',
    houseBill: 'HBL/HAWB Number',
    reference: 'Reference Number',
    shipmentNumber: 'Shipment Number',
    bookingId: 'Booking Id',
    entryNumber: 'EntryNumber',
    containers: 'Container Numbers',
    poNumbers: 'PO Numbers',
    bondNumber: 'Bond Number',
    activationTime: 'Activation Time',
  },
  reportReconciliationRequestSummary: {
    fileName: 'File Name',
    fileType: 'File Type',
    reportType: 'Report Type',
    status: 'Status',
    uploader: 'Uploader',
    createTime: 'Create Time',
    totalRows: 'Total Rows',
    cbmsRows: 'CBMS Rows',
  },
  bulkUploadRequestSummary: {
    uploader: 'Uploader',
    status: 'Status',
    createTime: 'Create Time',
    totalRows: 'Total Rows',
  },
  invoiceAMSQuery: {
    input: 'Input',
    createdTime: 'Date Submitted',
    amount: 'Amount',
  },
  invoiceSubscription: {
    plan: 'Plan type',
    period: 'Billing period',
  },
  invoiceBond: {
    shipper: 'Shipper',
    activatedTime: 'Activated Date',
    accountNumber: 'Bond No.',
    coverage: 'Amount',
    amount: 'Total',
  },
  invoiceClassification: {
    shipper: 'Shipper',
    name: 'Short Description',
    requestDate: 'Request Date',
    htsNumber: 'Classification',
    amount: 'Total',
  },
  invoiceShipment: {
    shipper: 'Shipper',
    shipmentId: 'Shipment #',
    supplierId: 'Manufacturer',
    poNumber: 'PO #',
    billOfLading: 'Bill of Lading',
    container: 'Container',
    dutyAmount: 'Total duties and fees',
    singleEntryBond: 'Single entry bond',
    otherAmount: 'Other charges',
    entryFee: 'Entry fee',
    adDuty: 'Anti-dumping duty',
    cvDuty: 'Countervailing duty',
    canadianEntryFee: 'Canadian entry fee',
    consolidatedEntryFee: 'Consolidated entry fee',
    manualCreationFee: 'Manual creation fee',
    hotFee: 'Hot entry fee',
    amendmentFee: 'Amendment fee',
    weekendFee: 'Weekend fee',
    cancelationFee: 'Cancelation fee',
    inBondFee: 'In-bond fee',
    total: 'Total',
    shipmentNumber: 'Shipment #',
  },
  summaryCharges: {
    // TODO: Remove dutiesFees,adDuty and cvDuty in favor of each charge code
    ...euUnplannedCharges,
    ...EuPlannedCharges,
    dutiesFees: 'Taxes and fees',
    adDuty: 'Anti-dumping duty',
    cvDuty: 'Countervailing duty',
    stbAmount: 'Single entry bond',
    [ChargeCode.DUTY]: 'Duties',
    [ChargeCode.DUTY_PMS]: 'Duties, taxes and fees',
    [ChargeCode.DUTY_DAILY]: 'Duties, taxes and fees',
    [ChargeCode.AD_DUTY]: 'Anti-dumping duty',
    [ChargeCode.CV_DUTY]: 'Countervailing duty',
    [ChargeCode.SINGLE_ENTRY_BOND]: 'Single entry bond',
    [ChargeCode.ENTRY_FEE]: 'Entry fee',
    [ChargeCode.CANADIAN_ENTRY_FEE]: 'Canadian entry fee',
    [ChargeCode.CONSOLIDATED_ENTRY_FEE]: 'Consolidated entry fee',
    [ChargeCode.MANUAL_CREATION_FEE]: 'Manual creation fee',
    [ChargeCode.SELF_FILED_ENTRY_FEE]: 'Self Filed Entry fee',
    // TODO this doesn't exist anymore, add other hot fees
    // [ChargeCode.HOT_FEE]: 'Hot entry fee',
    [ChargeCode.AMENDMENT_FEE]: 'Amendment fee',
    [ChargeCode.WEEKEND_FEE]: 'Weekend fee',
    [ChargeCode.CANCELATION_FEE]: 'Cancelation fee',
    [ChargeCode.ISF_BOND]: 'ISF bond',
    [ChargeCode.ISF_FEE]: 'ISF fee',
    ...unplannedCharges,
    disbursementFee: 'Disbursement fee',
    [ChargeCode.IN_BOND_FEE]: 'In-bond fee',
    [ChargeCode.MERCHANT_FEE]: 'Merchant processing fee',
    [ChargeCode.IMPORT_VALUE_ADDED_TAX]: 'Import value added tax',
    [ChargeCode.VALUE_ADDED_TAX]: 'Value added tax',
    CUSTOMS_DUTIES: 'Customs Duties Charges',
    CUSTOMS_CLEARANCE: 'Customs Clearance Charges',
    totalCustomsCharges: 'Total Customs Charges',
    totalBrokerageCharges: 'Total Brokerage Charges',
    total: 'Total',
  },
  summaryStatistics: {
    entryNumber: 'Entry number',
    entryType: 'Entry type',
    paymentType: 'Payment type',
    pms: 'PMS',
    pmsMonth: 'PMS month',
    pmsNumber: 'PMS number',
    goNumber: 'GO number',
    payingDirect: 'Pay direct to CBP',
    statementNumber: 'Daily statement number',
    statementStatus: 'Statement status',
    stbAmount: 'Single entry bond',
    totalValue: 'Total value',
    cbpTeam: 'CBP team number',
    cargoControlNumber: 'Cargo control number',
    numberOfLines: 'Line count',
    exchangeRate: 'Exchange rate',
    collectionStatus: 'Collection status',
    liquidationReasonCodes: 'Liquidation Reasons',
    extensionSuspensionReasonCodes: 'Extension/Suspension Reasons',
    preferredDutyCollectPartyType: 'Duty Collect Party',
  },
  summaryTimeline: {
    exportDate: 'Export date',
    importDate: 'Import date',
    entryDate: 'Entry date',
    releaseDate: 'Release date',
    prelimStatementDate: 'Prelim. statement date',
    statementDate: 'Daily statement date',
    pmsDate: 'PMS date',
  },
  // order matters here, this is the order they are displayed (EntrySubmit/container)
  summaryCustoms: {
    duties: 'Duties',
    taxes: 'Taxes',
    fees: 'Fees',
    adDuties: 'Anti-dumping duty',
    cvDuties: 'Countervailing duty',
    b3Duty: 'Duty',
    b3SalesTax: 'Sales tax',
    b3ExciseTax: 'Excise tax',
    total: 'Total',
  },
  shipmentReferences: {
    clientNumber: 'Client Number',
    shipperId: 'Shipper',
    shipperName: 'Shipper',
    createdBy: 'Created By',
    poNumber: 'PO Number',
    container: 'Container',
    houseBill: 'House Bill',
    masterBill: 'Master Bill',
    entryNumber: 'Entry number',
    isfNumber: 'ISF number',
    inBondNumber: 'In-bond number',
  },
  [RecordType.US_CONSUMPTION_ENTRY]: {
    shipperId: 'Shipper',
    shipperName: 'Shipper',
    createdByName: 'Created By',
    shipmentNumber: 'Shipment #',
    clientNumber: 'Client #',
    usConsumptionEntryId: `${
      workOrderDisplayNameMap[RecordType.US_CONSUMPTION_ENTRY]
    } Id`,
    firmsCode: 'FIRMS',
    createdTime: 'Created',
    exportDate: 'Export Date',
    id: 'Shipment #',
    forwarderIds: 'Forwarder',
    forwarderName: 'Forwarder',
    assignedToName: 'Assigned To',
    mot: 'Type',
    statusIcons: 'Releases',
    supplierId: 'Supplier',
    poNumber: 'Ref #',
    portOfLadingCode: 'Origin',
    portOfUnladingCode: 'Destination',
    portOfLadingName: 'Origin',
    portOfEntryName: 'Port of Entry',
    etd: 'ETD',
    eta: 'ETA',
    lfd: 'LFD',
    bill: 'Bill #',
    masterBill: 'Master Bill #',
    houseBill: 'House Bill #',
    container: 'Container #',
    status: 'Status',
    isfSubmittedTime: 'ISF Submitted',
    isfSubmitter: 'ISF Submitter',
    isfFiledTime: 'ISF Accepted',
  },
  [RecordType.INVOICE]: {
    accountId: 'Customer',
    invoiceNumber: 'Invoice #',
    id: 'Invoice #',
    name: 'Customer',
    shipperId: 'Shipper',
    shipperName: 'Shipper',
    qboNumber: 'Invoice #',
    shipmentId: 'Shipment #',
    type: 'Type',
    source: 'Payment Method',
    amount: 'Total',
    invoicedTime: 'Invoice Date',
    dueTime: 'Due Date',
    status: 'Status',
    range: 'Date Range',
    payNumbers: 'Check/Wire Numbers',
  },
  [RecordType.FORWARDER]: {
    shipperId: 'Shipper',
    shipperName: 'Shipper',
    name: 'Name',
    duns: 'DUNS',
    ein: 'EIN',
    scac: 'SCAC',
    ctpat: 'CTPAT',
    licenseNumber: 'License',
    tags: 'Forwarder Tags',
    fromEmail: 'Mail From',
    dispatchEmail: 'Dispatch Email',
    dispatchPhone: 'Dispatch Phone',
    email: 'Dispatch Email',
    phone: 'Dispatch Phone',
    ...locationNameMap,
    stateName: 'State',
  },
  [RecordType.TRUCKER]: {
    shipperId: 'Shipper',
    shipperName: 'Shipper',
    name: 'Name',
    ein: 'EIN',
    scac: 'SCAC',
    dispatchEmail: 'Dispatch Email',
    dispatchPhone: 'Dispatch Phone',
    email: 'Dispatch Email',
    phone: 'Dispatch Phone',
    tags: 'Trucker Tags',
    duns: 'DUNS',
    ...locationNameMap,
    stateName: 'State',
  },
  [RecordType.US_CONSIGNEE]: {
    shipperId: 'Shipper',
    shipperName: 'Shipper',
    ...companyNameMap,
    email: 'Email',
    phone: 'Phone',
    duns: 'DUNS',
    stateName: 'State',
    createdByName: 'Created By',
    countryName: 'Country',
    name: 'Name',
    officerName: 'Officer Name',
    officerTitle: 'Officer Title',
    // iorNumberType: 'IOR Number Type',
    officerPhone: 'Officer Phone',
  },
  [RecordType.FACILITY]: {
    shipperId: 'Shipper',
    shipperName: 'Shipper',
    name: 'Name',
    duns: 'DUNS',
    ein: 'EIN',
    fei: 'FEI',
    receivingEmail: 'Receiving Email',
    receivingPhone: 'Receiving Phone',
    email: 'Receiving Email',
    phone: 'Receiving Phone',
    tags: 'Facility Tags',
    firmsCode: 'FIRMS',
    ...locationNameMap,
    stateName: 'State',
  },
  [RecordType.CUSTOM_DOMAIN]: {
    domain: 'Custom Domain',
    tabTitle: 'Browser Tab Title',
    certificate: 'Domain Verified',
  },
  // TODO figure out how to handle users
  ...mapToAllUserTypes({
    name: 'Name',
    role: 'Role',
    title: 'Title',
    email: 'Email',
    phone: 'Phone',
    loggedIn: 'Logged In',
    createdTime: 'Invited',
    vertical: 'Industry',
  }),
  [RecordType.SUPPLIER]: {
    shipperId: 'Shipper',
    shipperName: 'Shipper',
    name: 'Name',
    ...locationNameMap,
    stateName: 'State',
    countryName: 'Country',
    contactName: 'Contact Name',
    contactEmail: 'Contact Email',
    mid: 'MID',
    fei: 'FEI',
    duns: 'DUNS',
  },
  [RecordType.CLASSIFICATION]: {
    createdByName: 'Created By',
    shipperId: 'Shipper',
    shipperName: 'Shipper',
    name: 'Name',
    description: 'Description',
    materials: 'Materials',
    htsNumber: 'HTS Number',
    originCountryCode: 'Country of Origin',
    comment: 'Comment',
    updatedTime: 'Last Updated At',
    duty: 'Duty',
    rateString: 'Duty',
    classified: 'Classified',
    createdTime: 'Requested At',
    countryName: 'Country',
  },
  [RecordType.PRODUCT]: {
    createdByName: 'Created By',
    shipperId: 'Shipper',
    shipperName: 'Shipper',
    clientIdentifier: 'Item Number',
    name: 'Display Name',
    description: 'CBP Description',
    cbpDescription: 'CBP Description',
    htsNumber: 'HTS Number',
    originCountryCode: 'Country of Origin',
    supplier: 'Supplier',
    supplierName: 'Supplier',
    notes: 'Notes',
    unitValue: 'Unit Value',
    unitAssist: 'Unit Assist',
    spi: 'Special Program Indicator',
    rateString: 'Custom Duty Rate',
    countryName: 'Country',
  },
  profile: {
    email: 'Email',
    name: 'Name',
    title: 'Title',
    officePhone: 'Office Phone',
    mobilePhone: 'Mobile Phone',
  },
  onboardingProfileDetails: {
    email: 'Email',
    name: 'Name',
    title: 'Title',
    officePhone: 'Office Phone',
    mobilePhone: 'Mobile Phone',
  },
  onboardingCompanyDetails: {
    name: 'Company Name',
    email: 'Accounting Email',
    phone: 'Accounting Phone',
    ...locationNameMap,
    ein: 'EIN',
    duns: 'DUNS',
  },
  subscription: {
    period: 'Billing Period',
    plan: 'INLT Subscription Plan',
    paymentMethod: 'Payment Method',
  },
  onboardingBillingDetails: {
    period: 'Billing Period',
    plan: 'INLT Subscription Plan',
    paymentMethod: 'Payment Method',
  },
  [RecordType.DOCUMENT_TEMPLATE]: {
    entityName: 'Owner',
    name: 'Name',
    type: 'Template Type',
    createdByName: 'Created By',
    approvedTime: 'Approved',
  },
  [RecordType.SHIPPER]: {
    createdByName: 'Created By',
    forwarderId: 'Forwarder',
    forwarderName: 'Forwarder',
    ...companyNameMap,
    countryName: 'Country',
    stateName: 'State',
    voided: 'Voided',
    name: 'Name',
    activated: 'Activated',
    status: 'Status',
    pmsRequestTime: 'PMS Requested',
    duns: 'DUNS',
    naics: 'NAICS',
    pms: 'PMS',
    bond: 'Bond',
    payingDirect: 'Pay Direct',
    bondAmount: 'Amount',
    bondTypeCode: 'Type',
    bondSuretyCode: 'Surety',
    bondNumber: 'Number',
    bondRenewalTime: 'Bond Expires',
    bondEffectiveDate: 'Effective',
    bondExpires: 'Expires',
    bondTerminationDate: 'Terminates',
    businessUnit: 'Purchased',
    payerUnitNumber: 'Payer Unit Number',
    confidentialityRequestTime: 'Confidentiality Requested',
    confidentialityConfirmedTime: 'Confidentiality Expires',
    officerName: 'Officer Name',
    officerTitle: 'Officer Title',
    officerPhone: 'Officer Phone',
    email: 'Email',
    phone: 'Phone',
    programCodes: 'Programs',
    vertical: 'Industry',
    website: 'Website',
  },
  company: {
    ...companyNameMap,
    accountingName: 'Accounting Name',
    accountingEmail: 'Accounting Email',
  },
  duties: {
    paying: 'CBP Duty Payment',
    payerUnitNumber: 'ACH Payer Unit Number',
    paymentMethod: 'Payment Method',
  },
  bond: {
    need: 'Bond Needed',
    hasReason: 'Reason',
    duties: 'Est. Annual Duty',
    coverage: 'Coverage',
    quote: 'Quote',
    complex: 'Considerations',
  },
  amsQuery: {
    queryType: 'Type',
    createdTime: 'Created Time',
    response: 'Response',
    input: 'Input',
  },
  forwarderMailFrom: {
    fromEmail: 'Email Address',
    fromTitle: 'Email Title',
    fromSignature: 'Email Signature',
    emailVerificationStatus: 'Email Verified',
    dkimStatus: 'DKIM Verified',
    domainStatus: 'SPF Verified',
  },
  cuDeSummaryInfo: {
    identification: 'Identification',
  },
};
