import React from 'react';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { ApolloQueryResult } from '@apollo/client';
import { TemplateDocumentSignRequest, Tag } from '@xbcb/api-gateway-client';
import {
  TemplateBasedSignUploadsCard,
  TemplateBasedSignUploadsCardDocs,
} from '@xbcb/document-components';
import { DocumentTemplateType } from '@xbcb/document-types';
import { AnyObject, PoaType, RecordType } from '@xbcb/shared-types';
import { shouldUpdate, safeGetMessage } from '@xbcb/ui-utils';
import OptionalCompanyInfo from '../OptionalCompanyInfo';
import PartyFormBase from '../PartyFormBase';
import TemplateBasedSignSubmissionContent from '../TemplateBasedSignSubmissionContent';
import UsIorOfficerInfo from '../UsIorOfficerInfo';
import { StyledSpan } from './styles';
import { iscbpReg2ChangesFeatureEnabled } from '../featureFlags';
import { useBundle } from '@amzn/react-arb-tools';

const templateTypeToPoaTypeMap: { [key in DocumentTemplateType]?: PoaType } = {
  [DocumentTemplateType.DIRECT_POWER_OF_ATTORNEY]: PoaType.MASTER_POA,
  [DocumentTemplateType.MASTER_POWER_OF_ATTORNEY]: PoaType.MASTER_POA,
  [DocumentTemplateType.SUB_POWER_OF_ATTORNEY]: PoaType.SUB_POA,
  [DocumentTemplateType.SUB_POWER_OF_ATTORNEY_AUTHORITY]:
    PoaType.SUB_POA_AUTHORITY,
  [DocumentTemplateType.CORPORATE_CERTIFICATION]: PoaType.CC,
};

interface TemplateBasedSignFieldsProps {
  form: FormInstance;
  type: DocumentTemplateType;
  operatorId: string;
  usIorId?: string;
  additionalTags: Tag[];
  templateDocumentSignRequest: TemplateDocumentSignRequest;
  updateTemplateDocumentSignRequest: (props: {
    variables: AnyObject;
  }) => Promise<any>;
  mutationLoading: boolean;
  attribution: string;
  Docs: TemplateBasedSignUploadsCardDocs;
  refetchTemplateDocumentSignRequest: () => Promise<ApolloQueryResult<any>>;
}

const TemplateBasedSignFields: React.FC<TemplateBasedSignFieldsProps> = ({
  form,
  type,
  operatorId,
  usIorId,
  additionalTags,
  templateDocumentSignRequest,
  updateTemplateDocumentSignRequest,
  mutationLoading,
  attribution,
  Docs,
  refetchTemplateDocumentSignRequest,
}) => {
  const [bundle] = useBundle(
    'components.formComponents.TemplateBasedSignFields',
  );
  const isSubPowerOfAttorney =
    type === DocumentTemplateType.SUB_POWER_OF_ATTORNEY;
  const isCorporateCertification =
    type === DocumentTemplateType.CORPORATE_CERTIFICATION;
  const shouldHideHelpMeGetOne =
    isCorporateCertification || isSubPowerOfAttorney;
  const isDirectOrMasterPowerOfAttorney = [
    DocumentTemplateType.DIRECT_POWER_OF_ATTORNEY,
    DocumentTemplateType.MASTER_POWER_OF_ATTORNEY,
  ].includes(type);

  const readOnlyDisabled = {
    disabled: isSubPowerOfAttorney,
    readOnly: isSubPowerOfAttorney,
  };

  const signerNameNamePath = ['signerName'];
  const signerTitleNamePath = ['signerTitle'];
  const signerBirthDateNamePath = ['signerBirthDate'];
  const officerPhoneNamePath = ['officerPhone'];
  const businessStructureNamePath = ['businessStructure'];
  const countryCodeField = ['addresses', 'mailing', 'countryCode'];
  const physicalAddressCountryCodeField = [
    'addresses',
    'physical',
    'countryCode',
  ];
  const nrccOfficerEmailNamePath = ['nrccOfficerEmail'];
  const poaType = PoaType[templateTypeToPoaTypeMap[type] as PoaType];
  const OfficerInfoCard = (
    <Form.Item
      shouldUpdate={shouldUpdate([physicalAddressCountryCodeField])}
      noStyle
    >
      {() => {
        return (
          <UsIorOfficerInfo
            form={form}
            nameFullNamePath={signerNameNamePath}
            nameLocalNamePath={signerNameNamePath}
            titleFullNamePath={signerTitleNamePath}
            titleLocalNamePath={signerTitleNamePath}
            birthDateFullNamePath={signerBirthDateNamePath}
            birthDateLocalNamePath={signerBirthDateNamePath}
            type={poaType}
            phoneFullNamePath={officerPhoneNamePath}
            phoneLocalNamePath={officerPhoneNamePath}
            nrccEmailLocalNamePath={nrccOfficerEmailNamePath}
            countryCodeFullNamePath={physicalAddressCountryCodeField}
          />
        );
      }}
    </Form.Item>
  );
  const SubmissionContent = (
    <TemplateBasedSignSubmissionContent
      attribution={attribution}
      form={form}
      usIorId={usIorId}
      templateDocumentSignRequest={templateDocumentSignRequest}
      updateTemplateDocumentSignRequest={updateTemplateDocumentSignRequest}
      mutationLoading={mutationLoading}
      refetchTemplateDocumentSignRequest={refetchTemplateDocumentSignRequest}
      additionalTags={additionalTags}
      physicalAddressCountryCodePath={physicalAddressCountryCodeField}
    />
  );

  return (
    <>
      {/* If it is direct / master POA, show the entire form otherwise only show the OfficerInfoCard */}
      {isDirectOrMasterPowerOfAttorney ? (
        <PartyFormBase
          $maxHeight="calc(100vh - 70px)"
          // Rather than having PartyFormBase handle Docs (aka render it
          // to the right), we handle it below in a separate card (TemplateBasedSignUploadsCard)
          hideDocs
          form={form}
          {...readOnlyDisabled}
          // TODO consider hiding billingDetails
          companyInfoExtraContent={
            <>
              <p>
                {safeGetMessage(bundle, 'all_fields_in_red_required', {
                  span: () => (
                    <StyledSpan>{safeGetMessage(bundle, 'red')}</StyledSpan>
                  ),
                })}
              </p>
              <br />
              <p>{safeGetMessage(bundle, 'dont_have_ein')}</p>
            </>
          }
          nameLabel={safeGetMessage(bundle, 'company_full_legal_name')}
          // allRequired
          showUsCbp5106AddressType
          showUnknownDuns
          contactInfoRequired
          enforceNamePattern
          addressRequired={true}
          hidePointOfContact
          recordType={RecordType.US_IOR}
          hideHelpMeGetOne={shouldHideHelpMeGetOne}
          businessStructureRequired
          // This should not be changed by the shipper/forwarder (aka the
          // account types that will be using the sign page)
          hidePreferredCbpEntryPaymentMethod
          verticalRequired
          iorNumberRequired
          hideVertical
          // Sign page should not have a concept of a currentUser, thus
          // since billingDetails is specific to operator users we should
          // hide it here
          showBillingDetails={false}
          showBusinessWebsite={iscbpReg2ChangesFeatureEnabled}
        >
          <OptionalCompanyInfo form={form} {...readOnlyDisabled} />
          {OfficerInfoCard}
          <TemplateBasedSignUploadsCard
            businessStructureNamePath={businessStructureNamePath}
            form={form}
            operatorId={operatorId}
            additionalTags={additionalTags}
            Docs={Docs}
            physicalAddressCountryCodePath={physicalAddressCountryCodeField}
          />
          {SubmissionContent}
        </PartyFormBase>
      ) : (
        <>
          {OfficerInfoCard}
          {SubmissionContent}
        </>
      )}
    </>
  );
};

export default TemplateBasedSignFields;
