// These are the list of only headers that CBMS gateway will allow form the graphQL requests
export const cbmsGatewayAllowableHeaders = [
  'Content-Type',
  'X-Amz-Date',
  'Authorization',
  'X-Api-Key',
  'X-Amz-Security-Token',
  'X-Amz-Content-Sha256',
  'X-Amz-User-Agent',
  'apollo-server',
  'active-broker-id',
];
