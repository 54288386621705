import { isArray, isObject } from 'lodash';
import xss from 'xss';

const sanitizer = new xss.FilterXSS({
  whiteList: {},
  escapeHtml: (html) =>
    html
      .replace(/&(?![0-9a-zA-Z]+;)/g, '&amp;') // dont replace HTML entities, must be first so it doesnt replace below uses
      .replace(/\$/g, '&#36;')
      .replace(/`/g, '&#96;')
      .replace(/'/g, '&#39;')
      .replace(/"/g, '&#34;')
      .replace(/\\/g, '&#92;')
      .replace(/\//g, '&#47;')
      .replace(/=/g, '&#61;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;'),
});

export const sanitize = (values, omitKeys = []) => {
  if (isArray(values)) {
    return values.map((val) => sanitize(val, omitKeys));
  } else if (isObject(values)) {
    return Object.keys(values).reduce(
      (acc, key) => ({
        ...acc,
        [key]: omitKeys.includes(key)
          ? values[key]
          : sanitize(values[key], omitKeys),
      }),
      {},
    );
  } else {
    // We only sanitize strings. If you pass any other type to the xss library, it will just convert it to a string anyway and mess things up.
    if (typeof values === 'string') {
      return sanitizer.process(values);
    } else {
      return values;
    }
  }
};
