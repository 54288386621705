export enum WorkOrderTab {
  AuditLogs = 'Audit Logs',
  Bond = 'Bond',
  CbpInBonds = 'CbpInBonds',
  Charges = 'Charges',
  CommercialInvoices = 'Commercial Invoices',
  CustomFields = 'Custom Fields',
  DeliveryOrder = 'Delivery Order',
  Documents = 'Documents',
  ExamOrHold = 'Exam/Hold',
  Inbox = 'Inbox',
  ISF = 'ISF',
  Milestones = 'Milestones',
  PSC = 'PSC',
  Summary = 'Summary',
  Tasks = 'Tasks',
  Transportation = 'Transportation',
}
