import styled from 'styled-components';
import { Button } from 'antd';
export type ButtonTypeProps = any;

export const StyledButton = styled<ButtonTypeProps>(Button)<{
  $firstRow?: boolean;
  $lineItem?: boolean;
  $marginLeft?: boolean;
}>`
  border: 0 !important;
  display: inline-block;
  ${({ $marginLeft }) => $marginLeft && 'margin-left: var(--space-3);'}
  margin-top: var(--space-1);
  box-shadow: none;
  ${({ $firstRow }) => $firstRow && 'margin-top: var(--space-4);'}
  ${({ $lineItem }) => $lineItem && 'margin-top: 0;'}
`;
