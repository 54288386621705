import { EsDataType } from '../index';

export const sharedCancellationColumns = {
  canceledTime: {
    name: 'Canceled Time',
    type: EsDataType.TIME,
    hidden: true,
  },
  canceledById: {
    name: 'Canceled By ID',
    type: EsDataType.KEYWORD,
    hidden: true,
  },
  canceledByName: {
    name: 'Canceled By Name',
    type: EsDataType.TEXT,
    hidden: true,
  },
};
