import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

const auditLogFeatureName = 'AUDIT_LOG_UX_FEATURE';

export const AuditLogUXFeatures = {
  AUDIT_LOG_UX_FEATURE: auditLogFeatureName,
};

const AUDIT_LOG_UX_FEATURE = new FeatureFlagService([
  {
    feature: auditLogFeatureName,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA],
  },
]);

export { AUDIT_LOG_UX_FEATURE, auditLogFeatureName };
