import { recordFields, recordActionFields } from '@xbcb/shared-queries';

const documentTemplateFragment = `
fragment documentTemplateFields on DocumentTemplate {
  templateType
  name
  html
  jsonHtml
  status
  account {
    id
  }
  approved {
    ...recordActionFields
  }
  activated {
    ...recordActionFields
  }
  rejected {
    ...recordActionFields
  }
  ...recordFields
}
`;

export const documentTemplateFragments = `
${documentTemplateFragment}
${recordFields}
${recordActionFields}
`;
