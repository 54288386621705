import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Modal } from 'antd';
import { timeout, cleanString } from '@xbcb/js-utils';
import { SingleFormCard } from '@xbcb/display-components';
import { debounceComponent } from '@xbcb/higher-order-components';
import { PhoneNumber, OfficerTitle, Email } from '@xbcb/form-item-components';
import { LabelAbove } from '@xbcb/static-text-components';
import CompanyInfo from '../CompanyInfo';
import OptionalCompanyInfo from '../OptionalCompanyInfo';
import { StyledSignFieldsDiv } from './styles';

const DebouncedInput = debounceComponent()(Input);

const officerNameFieldMap = {
  'direct-poa': 'officerName',
  'master-poa': 'officerName',
  'sub-poa': 'forwarderOfficerName',
  subPOAAuthority: 'forwarderOfficerName',
  cc: 'secondaryOfficerName',
};
const officerTitleFieldMap = {
  'direct-poa': 'officerTitle',
  'master-poa': 'officerTitle',
  'sub-poa': 'forwarderOfficerTitle',
  subPOAAuthority: 'forwarderOfficerTitle',
  cc: 'secondaryOfficerTitle',
};

const SignFields = ({
  form,
  isFetching,
  type,
  initialValues,
  linkForwarderId,
}) => {
  if (!form) return null;
  const { getFieldDecorator: field, getFieldValue } = form;
  const readOnlyDisabled = {
    disabled: isFetching || type === 'sub-poa',
    readOnly: type === 'sub-poa',
  };
  const officerReadOnlyDisabled = { disabled: isFetching, readOnly: false };
  const officerNameField = officerNameFieldMap[type];
  const officerTitleField = officerTitleFieldMap[type];
  const countryCode = getFieldValue('countryCode');
  const showCC =
    countryCode &&
    countryCode !== 'US' &&
    ['direct-poa', 'master-poa'].includes(type);

  const invalidName = (value) => !value || value.trim().split(' ').length <= 1;

  const onBlur = async () => {
    await timeout(1000);
    const raw = getFieldValue(officerNameField);
    const value = cleanString(raw);
    if (raw !== value) {
      form.setFieldsValue({ [officerNameField]: value });
    }
    if (value && invalidName(value)) {
      Modal.warning({
        title: 'Invalid officer name',
        content: `Please enter the FULL name of an officer of the company.`,
      });
    }
  };

  const validateName = (rule, value, callback) => {
    if (invalidName(value)) {
      return callback('Invalid officer name');
    } else {
      return callback();
    }
  };

  return (
    <StyledSignFieldsDiv>
      {!linkForwarderId && ['direct-poa', 'master-poa'].includes(type) && (
        <>
          <SingleFormCard
            title="Company Info"
            extraContent={
              <div className="space-right">
                <p>
                  All fields in <span className="bad">red</span> are required.
                </p>
                <br />
                <p>
                  If you don't have a Federal Tax ID (EIN) or wish to use a SSN
                  or Customs Assigned Number, change the structure to reflect
                  you preference.
                </p>
              </div>
            }
          >
            <CompanyInfo
              form={form}
              readOnlyDisabled={readOnlyDisabled}
              titleField="name"
              allRequired={true}
              ior={true}
              hideHelpMeGetOne={type === 'cc' || type === 'sub-poa'}
              initialValues={initialValues}
            />
          </SingleFormCard>
          <OptionalCompanyInfo
            form={form}
            readOnlyDisabled={readOnlyDisabled}
            initialValues={initialValues}
          />
        </>
      )}
      <SingleFormCard
        title="Your Info"
        extraContent={
          <div className="space-right">
            You must be an officer or authorized to sign on behalf of an officer
            to sign this form. If confirmation you are an officer is not
            available based on public data, additional verification will be
            requested.
          </div>
        }
      >
        <Form.Item className="short" help={null}>
          <LabelAbove
            text="Officer Full Name"
            field={officerNameField}
            form={form}
          />
          {field(officerNameField, {
            rules: [
              {
                validator: validateName,
                required: true,
                message: ' ',
              },
            ],
            initialValue: initialValues[officerNameField],
          })(
            <DebouncedInput
              className={officerReadOnlyDisabled.readOnly ? 'read-only' : ''}
              disabled={officerReadOnlyDisabled.disabled}
              onBlur={onBlur}
            />,
          )}
        </Form.Item>
        <OfficerTitle
          localNamePath={officerTitleField}
          fullNamePath={officerTitleField}
          required={true}
          initialValue={initialValues[officerTitleField]}
          {...officerReadOnlyDisabled}
          type={type}
          form={form}
        />
        {['direct-poa', 'master-poa'].includes(type) && (
          <PhoneNumber
            {...officerReadOnlyDisabled}
            form={form}
            // TODO fix these name paths
            localNamePath={['officerPhone']}
            fullNamePath={['officerPhone']}
            required={false}
            initialValues={{
              phone: initialValues.officerPhone,
              phoneCountry: initialValues.officerPhoneCountry,
            }}
          />
        )}
        {showCC && (
          <div>
            <p className="space-bottom">
              CBP requires an separate officer to sign a Corporation
              Certfication for non-US companies
            </p>
            <Email
              form={form}
              localNamePath={['secondaryOfficerEmail']}
              {...readOnlyDisabled}
              label="Secondary Officer Email"
              required={true}
              initialValue={initialValues.secondaryOfficerEmail}
            />
          </div>
        )}
      </SingleFormCard>
    </StyledSignFieldsDiv>
  );
};

SignFields.propTypes = {
  form: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  type: PropTypes.string.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default SignFields;
