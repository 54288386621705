const BadPrefixes = ['N', 'PT', 'JSC', 'OAO', 'OOO', 'ZAO'];

export const mid = (inputAddress, name, code) => {
  let result = '';
  // First part
  if (!inputAddress.countryCode) {
    throw new Error('Missing Country Code');
  }
  if (
    Object.keys(code.ISO.country.alpha2).indexOf(inputAddress.countryCode) ===
    -1
  ) {
    throw new Error('Wrong Country Code');
  }

  if (inputAddress.countryCode === 'CA') {
    if (!inputAddress.stateCode) {
      throw new Error('Country is CA and Missing State Code');
    }
    const cbpProviceCode =
      code.CBP.CATAIR.canadianProvince[inputAddress.stateCode];
    if (!cbpProviceCode) {
      throw new Error(
        `Country is CA and ${inputAddress.stateCode} is not a valid state code`,
      );
    }
    result += cbpProviceCode;
  } else {
    result += inputAddress.countryCode;
  }

  // Second part
  if (!name) {
    throw new Error('Missing name, cannot generate MID.');
  }

  const outputName = name
    .toUpperCase()
    .replace(/[.,\/#!$%\^&\*;:{}=\-_'`~()]/g, '')
    .replace(/\bA\b/g, '')
    .replace(/\bAN\b/g, '')
    .replace(/\bAND\b/g, '')
    .replace(/\bOF\b/g, '')
    .replace(/\bTHE\b/g, '')
    .replace(/FABRICA DE ARTIGOS DE VESTUARIO/g, '')
    .replace(/COMPANHIA TEXTIL/g, '');

  let words = outputName.split(' ');
  words = words.filter((word) => word !== '' && Number.isNaN(Number(word)));
  if (words.length === 0) {
    throw new Error(
      'Name only contains number which is invalid, thus cannot generate MID.',
    );
  }
  while (BadPrefixes.indexOf(words[0]) !== -1) {
    words.shift();
  }
  result += words[0].substring(0, 3);
  if (words.length > 1) {
    result += words[1].substring(0, 3);
  }

  // Third part
  let address;
  if (!inputAddress.address) {
    const splitAddress = (a) =>
      a.split(' ').map((word) => ({ information: word }));
    if (inputAddress.address1) {
      address = splitAddress(inputAddress.address1);
    }
    if (inputAddress.address2) {
      if (address) {
        address = address.concat(splitAddress(inputAddress.address2));
      } else {
        address = splitAddress(inputAddress.address2);
      }
    }
  } else {
    if (typeof inputAddress.address === 'string') {
      address = [{ information: inputAddress.address }];
    } else {
      address = inputAddress.address;
    }
  }

  let largestNumber = -1;
  address.forEach((addressPart) => {
    const split = addressPart.information.split(' ');
    split.forEach((part) => {
      const a = part
        .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
        .replace(/[A-Za-z]/g, ' ');
      const letterSplit = a.split(' ');
      letterSplit.forEach((p) => {
        if (!isNaN(p)) {
          if (largestNumber < parseInt(p, 10)) {
            largestNumber = parseInt(p, 10);
          }
        }
      });
    });
  });

  if (largestNumber !== -1) {
    result += largestNumber.toString().substring(0, 4);
  }

  // Fourth part
  if (!inputAddress.city) {
    throw new Error('Missing City');
  }

  const city = inputAddress.city
    .toUpperCase()
    .replace(/[0-9]/g, '')
    .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
    .replace(/\bA\b/g, '')
    .replace(/\bAN\b/g, '')
    .replace(/\bAND\b/g, '')
    .replace(/\bOF\b/g, '')
    .replace(/\bTHE\b/g, '');

  result += city.replace(' ', '').substring(0, 3);
  return result;
};
