import { EsDataType } from '../../enums';
import { createDocumentSignRequestRecord } from './createDocumentSignRequestRecord';

export const templateDocumentSignRequest = createDocumentSignRequestRecord({
  columns: {
    signedDocumentId: {
      name: 'Signed Document ID',
      type: EsDataType.KEYWORD,
    },
    templateId: {
      name: 'Template ID',
      type: EsDataType.KEYWORD,
    },
    templateVersion: {
      name: 'Template Version',
      type: EsDataType.INTEGER,
    },
  },
});
