import gql from 'graphql-tag';
import { getRecordName, dedupRecordQuery, RecordProps } from './shared';
import { pluralize } from '@xbcb/js-utils';

interface GetAllProps extends RecordProps {}

export const getAllQuery = ({
  recordName,
  fields = '',
  fragments = '',
}: GetAllProps) => {
  const { fieldsString, fragmentsString } = dedupRecordQuery(fields, fragments);
  const RecordName = getRecordName(recordName);
  return gql`
    query GetAll($input: CursorPaginationInput) {
      all${pluralize(RecordName)}(
        input: $input
      ) {
        pageInfo {
          ...pageInfo
        }
        edges {
          cursor
          node {
            ${fieldsString}
          }
        }
        nodes {
          ${fieldsString}
        }
      }
    }
    fragment pageInfo on CursorPagination {
      startCursor
      endCursor
      hasNextPage
    }
    ${fragmentsString}
  `;
};
