import { EsDataType } from '../../index';
import { createTableConfig } from '../createTableConfig';

export const invoicePayment = createTableConfig({
  columns: {
    invoiceId: {
      default: true,
      name: 'Invoice Id',
      type: EsDataType.KEYWORD,
    },
    qboPaymentId: {
      default: true,
      name: 'Qbo Payment Id',
      type: EsDataType.KEYWORD,
    },
  },
  tableOptions: {
    sortField: 'invoiceId',
  },
});
