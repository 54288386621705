import { cbp5106Fields, cbp5106Fragments } from './cbp5106';

export const usContinuousCustomsBondFragment = `
fragment usContinuousCustomsBondFields on UsContinuousCustomsBond {
  number
  amount
  suretyCode
  typeCode
  effectiveDate
  terminationDate
  renewalDate
}`;

export const continuousBondsFields = `
  continuousBonds {
    past {
      ...usContinuousCustomsBondFields
    },
    present {
      ...usContinuousCustomsBondFields
    }
    future {
      ...usContinuousCustomsBondFields
    }
  }
`;

export const usIorFragment = `
fragment usIorFields on UsIor {
  ...recordFields
  ...partyFields
  iorNumber {
    ...iorNumberFields
  }
  ${cbp5106Fields}
  payerUnitNumber
  firstTimeStatus
  pmsStatus
  preferredCbpEntryPaymentMethod
  industry
  ${continuousBondsFields}
  unknownDuns
  activationStatus
  activationTime
  poaMethod
  nrccOfficerEmail
  businessStructure
  paymentTerms
  paymentTermsUpdated {
    time
  }
  status
  activations {
    status
    customsBroker {
      ... on UsCustomsBroker {
         id
      }
    }
    activated {
      time
    }
  }
}
`;

export const usIorFragments = `
${usIorFragment}
${cbp5106Fragments}
${usContinuousCustomsBondFragment}
`;

// TODO deprecated name
export const usIorFields = usIorFragments;
