import { cleanString } from '@xbcb/js-utils';
import { FormInstance } from 'antd/lib/form';
import { NamePath } from '@xbcb/ui-types';

export const formatString = (
  form: FormInstance,
  fullNamePath: NamePath,
  fun = cleanString,
) => {
  const value = form.getFieldValue(fullNamePath);
  const cleanedValue = fun(value);
  if (value !== cleanedValue) {
    form.setFields([{ name: fullNamePath, value: cleanedValue }]);
  }
};
