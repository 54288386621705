import { RcFile } from 'antd/lib/upload';
/** Importing from dist since we want to avoid importing Bindings (which uses 'fs' and is prohibited from the UI due to security concerns) */
import { extensionContentTypeMap } from '@xbcb/doc-utils/dist/extensionContentTypeMap';

export const fileMetadata = (file: RcFile) => {
  const escapedFileName = file.name.replace(/\//g, '_');
  const splitPeriod = escapedFileName.split('.');
  const extension = splitPeriod[splitPeriod.length - 1]
    .toString()
    .replace(/_/g, '')
    .trim()
    .toUpperCase();
  const fileName = splitPeriod.slice(0, -1).join('.').trim();
  const contentType =
    extensionContentTypeMap[extension as keyof typeof extensionContentTypeMap];
  return { fileName, contentType, extension, size: file.size };
};
