import gql from 'graphql-tag';
import { RecordType } from '@xbcb/shared-types';
import * as changeCase from 'change-case';
import { pluralize } from '@xbcb/js-utils';
import { record } from './shared';
import { connectionPageInfoFields } from './fields';

interface RelationshipQueriesProps {
  left: {
    name: RecordType;
    fields?: string;
    fragments?: string;
  };
  right: {
    name: RecordType;
    fields?: string;
    fragments?: string;
  };
  relationship: {
    input?: boolean;
    fields?: string;
    fragments?: string;
  };
}

export const relationshipQueries = ({
  left: {
    name: LEFT_RECORD_NAME,
    fields: leftRecordFields = '',
    fragments: leftRecordFragments = '',
  },
  right: {
    name: RIGHT_RECORD_NAME,
    fields: rightRecordFields = '',
    fragments: rightRecordFragments = '',
  },
  relationship: {
    input = false,
    fields: relationshipFields = '',
    fragments: relationshipFragments = '',
  },
}: RelationshipQueriesProps) => {
  const leftRecordName = changeCase.camelCase(LEFT_RECORD_NAME.toString());
  const rightRecordName = changeCase.camelCase(RIGHT_RECORD_NAME.toString());
  const LeftRecordName = changeCase.pascalCase(leftRecordName);
  const RightRecordName = changeCase.pascalCase(rightRecordName);
  const JoinName = `${LeftRecordName}${RightRecordName}`;
  const leftRecordIdName = `${leftRecordName}Id`;
  const rightRecordIdName = `${rightRecordName}Id`;
  const InputName = `${JoinName}RelationshipInput`;
  const inputNamedParam = input ? `, $input: ${InputName}!` : '';
  const inputParam = input ? `, input: $input` : '';
  const relationshipFieldsString = `
    ...recordFields
    ${leftRecordName} {
      ...recordFields
      ${leftRecordFields}
    }
    ${rightRecordName} {
      ...recordFields
      ${rightRecordFields}
    }
    ${relationshipFields}
  `;
  const relationshipFragmentsString = `
    ${record}
    ${leftRecordFragments}
    ${rightRecordFragments}
    ${relationshipFragments}
  `;
  const createRelationship = gql`
    mutation Create${JoinName}Relationship($idempotencyKey: String!, $${leftRecordIdName}: ID!, $${rightRecordIdName}: ID!${inputNamedParam}) {
      create${JoinName}Relationship(idempotencyKey: $idempotencyKey, ${leftRecordIdName}: $${leftRecordIdName}, ${rightRecordIdName}: $${rightRecordIdName}${inputParam}) {
        relationship {
          ${relationshipFieldsString}
        }
      }
    }
    ${relationshipFragmentsString}
  `;

  const getRelationship = gql`
    query ${JoinName}Relationship($${leftRecordIdName}: ID, $${rightRecordIdName}: ID, $id: ID, $version: Int) {
      ${leftRecordName}${RightRecordName}Relationship(${leftRecordIdName}: $${leftRecordIdName}, ${rightRecordIdName}: $${rightRecordIdName}, id: $id, version: $version) {
        ${relationshipFieldsString}
      }
    }
    ${relationshipFragmentsString}
  `;

  const getRelationshipField = gql`
    query ${JoinName}RelationshipField($${leftRecordIdName}: ID!, $${rightRecordIdName}: ID!) {
      ${leftRecordName}(id: $${leftRecordIdName}) {
        ...recordFields
        ${rightRecordName}(${rightRecordIdName}: $${rightRecordIdName}) {
          ${relationshipFieldsString}
        }
      }
    }
    ${relationshipFragmentsString}
  `;

  const getRelationshipsField = gql`
    query ${JoinName}RelationshipsField($${leftRecordIdName}: ID!) {
      ${leftRecordName}(id: $${leftRecordIdName}) {
        ...recordFields
        ${pluralize(rightRecordName)} {
          ...connectionPageInfoFields
          edges {
            node {
              ...recordFields
            }
            relationship {
              ${relationshipFieldsString}
            }
          }
        }
      }
    }
    ${relationshipFragmentsString}
    ${connectionPageInfoFields}
  `;

  const getReverseRelationshipField = gql`
    query Reverse${JoinName}RelationshipField($${leftRecordIdName}: ID!, $${rightRecordIdName}: ID!) {
      ${rightRecordName}(id: $${rightRecordIdName}) {
        ...recordFields
        ${leftRecordName}(${leftRecordIdName}: $${leftRecordIdName}) {
          ${relationshipFieldsString}
        }
      }
    }
    ${relationshipFragmentsString}
  `;

  const getReverseRelationshipsField = gql`
    query ${JoinName}RelationshipsField($${rightRecordIdName}: ID!) {
      ${rightRecordName}(id: $${rightRecordIdName}) {
        ...recordFields
        ${pluralize(leftRecordName)} {
          ...connectionPageInfoFields
          edges {
            node {
              ...recordFields
            }
            relationship {
              ${relationshipFieldsString}
            }
          }
        }
      }
    }
    ${relationshipFragmentsString}
    ${connectionPageInfoFields}
  `;

  return {
    createRelationship,
    getRelationship,
    getRelationshipField,
    getRelationshipsField,
    getReverseRelationshipField,
    getReverseRelationshipsField,
  };
};
