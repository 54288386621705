import { DataCyPrefix } from './prefix';
import { DataCySuffix } from './suffix';

export * from './prefix';
export * from './suffix';

// data-cy values should follow the pattern: dataCyPrefix-dataCySuffix (where
// the dataCySuffix is optional). This is a creator function meant to take a
// prefix and optional suffix and construct a dataCy value
export const createDataCyValue = (
  dataCyPrefix: DataCyPrefix,
  // Ideally should be DataCySuffix if provided, but there are some cases where
  // we dynamically create the DataCySuffix, thus also allow string. When
  // possible add the value to DataCySuffix and avoid hard coding strings
  // Remove extra spaces
  dataCySuffix?: DataCySuffix | string,
): string =>
  // We have inputs like "Test-able value" for this function.
  // markWorkOrdersReadyButton line 66, key: name is used. This change is fine for future safety, but it would be
  // nice to clean this up at the source as well with something like key: camelCase(name)
  // TODO: https://app.asana.com/0/1202349248932865/1203873610441926
  `${dataCyPrefix}${dataCySuffix ? `-${dataCySuffix}` : ''}`.replace(/ /g, '-');
