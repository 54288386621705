import { getOneQuery, getRecordFromResponse } from '@xbcb/shared-queries';
import { documentFragments } from '@xbcb/document-queries';
import { RecordType, AnyObject } from '@xbcb/shared-types';
import { isS3PresignedUrlExpired } from '@xbcb/js-utils';
import { openDocumentInNewTab } from '../';
import { message } from 'antd';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

export const handleDownload = async (
  document: AnyObject,
  client: ApolloClient<NormalizedCacheObject>,
) => {
  const { id, extension, fileName, content } = document;

  const formattedFileName = `${fileName}.${extension.toLowerCase()}`;
  message.info(`Retrieving ${formattedFileName}...`, 3.0);
  const existingDownloadLink = content?.downloadLink;
  const isLinkExpired = isS3PresignedUrlExpired(existingDownloadLink);

  if (existingDownloadLink && !isLinkExpired) {
    openDocumentInNewTab(existingDownloadLink);
  } else {
    const response = await client.query({
      query: getOneQuery({
        recordName: RecordType.DOCUMENT,
        fields: '...documentFields',
        fragments: documentFragments,
      }),
      variables: {
        id,
      },
      fetchPolicy: 'network-only',
    });
    const document = getRecordFromResponse(
      response,
      'get',
      RecordType.DOCUMENT,
    );
    const downloadLink = document.content?.downloadLink;
    if (downloadLink) {
      openDocumentInNewTab(downloadLink);
    } else {
      message.error(
        `Sorry, there was a problem retrieving ${formattedFileName}. Please try again later.`,
        5.0,
      );
    }
  }
};
