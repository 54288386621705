import { createWorkOrderRecord } from './utils';
import { EsDataType } from '../../index';

export const importerEntityActivationColumns = {
  columns: {
    importerId: { name: 'Importer ID', type: EsDataType.KEYWORD, hidden: true },
    importerName: {
      default: true,
      name: 'Importer Name',
      type: EsDataType.TEXT,
    },
    importerEntityId: {
      name: 'Importer Entity ID',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    countryCode: {
      name: 'Country Code',
      type: EsDataType.KEYWORD,
      default: true,
    },
    activationStatus: {
      name: 'Importer Entity Activation Status',
      type: EsDataType.KEYWORD,
      default: true,
    },
    activationTime: {
      name: 'Importer Entity Activation Time',
      type: EsDataType.TIME,
      default: true,
    },
  },
};

export const importerEntityActivation = createWorkOrderRecord(
  importerEntityActivationColumns,
);
