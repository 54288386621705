import gql from 'graphql-tag';
import { dedupRecordQuery, getRecordName, RecordProps } from './shared';

interface SubscribeOnUpdateProps extends RecordProps {}

export const subscribeOnUpdateQuery = ({
  recordName,
  fields = '',
  fragments = '',
}: SubscribeOnUpdateProps) => {
  const { fieldsString, fragmentsString } = dedupRecordQuery(fields, fragments);
  return gql`
  subscription subscribeOnUpdate($id: ID!, $topic: String!) {
    subscribe${getRecordName(recordName)}OnUpdate(id: $id, topic: $topic) {
      record {
        ${fieldsString}
      }
    }
  }
  ${fragmentsString}
  `;
};
