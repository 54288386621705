import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

export const assistFeature = 'assistFeature';

// TODO: Remove assist feature post-launch
// https://app.asana.com/0/1205995408079733/1206071688831721/f
const ASSIST_FEATURE = new FeatureFlagService([
  {
    feature: assistFeature,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA, Stage.PROD],
  },
]);

export { ASSIST_FEATURE };
