import { CrudPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const ShipperUserInvoicePermissionName = {
  READ: CrudPermissionName.READ,
};

export default {
  [ObjectType.INVOICE]: ShipperUserInvoicePermissionName,
};
