export enum OnboardingAccountType {
  SHIPPER = 'SHIPPER',
  FORWARDER = 'FORWARDER',
}

export enum OnboardingStatus {
  CREATED = 'CREATED',
  SUBMITTED = 'SUBMITTED',
  COMPLETED = 'COMPLETED',
}
