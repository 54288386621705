import moment from 'moment';
import { checkAmazonUsCaliforniaHolidays } from './checkAmazonHolidays';
import { AnyObject } from '@xbcb/shared-types';
import { isDst } from './isDst';

export const calculateDifferenceInHours = (
  startTime: moment.Moment,
  endTime: moment.Moment,
) => {
  return endTime.diff(startTime, 'hours', true);
};

// Reset input moment and set hours
export const getMomentWithHour = (momentObj: moment.Moment, hrs: number) => {
  return momentObj.clone().startOf('day').hours(hrs);
};

export const isWorkingDay = (date: moment.Moment, weekend: AnyObject) => {
  if (!date || !moment(date).isValid())
    throw new Error('Missing date in isValidDay');
  // TODO support holidays per team in different states (CA has more holidays than others)
  if (!checkAmazonUsCaliforniaHolidays(date)) {
    if (!weekend.includes(date.day())) {
      return true;
    }
  }
  return false;
};

export const convertUtcToEst = (momentObj: moment.Moment) => {
  if (isDst(momentObj.toDate())) return momentObj.clone().subtract(4, 'hours');
  return momentObj.clone().subtract(5, 'hours');
};

export const convertEstToUtc = (momentObj: moment.Moment) => {
  if (isDst(momentObj.toDate())) return momentObj.clone().add(4, 'hours');
  return momentObj.clone().add(5, 'hours');
};

export const shiftDetailsinEST = {
  weekend: [0, 6],
  shiftStartHour: 9,
  shiftEndHour: 21,
};

export const businessTimeDiff = (
  startTime: moment.Moment,
  endTime: moment.Moment,
) => {
  if (!startTime || !endTime)
    throw new Error('Missing date in businessTimeDiff');
  if (!moment.isMoment(startTime))
    throw new Error('startTime is not a moment object');
  if (!moment.isMoment(endTime))
    throw new Error('endTime is not a moment object');
  if (startTime.isAfter(endTime))
    throw new Error(
      'Start time should chronologically occur before ending time',
    );
  const convertedStartTime = convertUtcToEst(startTime);
  const convertedEndTime = convertUtcToEst(endTime);
  const { weekend, shiftStartHour, shiftEndHour } = shiftDetailsinEST;
  const shiftStartMoment = getMomentWithHour(
    convertedStartTime,
    shiftStartHour,
  );
  const shiftEndMoment = getMomentWithHour(convertedStartTime, shiftEndHour);
  const shiftDuration = calculateDifferenceInHours(
    shiftStartMoment,
    shiftEndMoment,
  );
  let hours = 0;
  while (!convertedStartTime.isSame(convertedEndTime, 'day')) {
    if (isWorkingDay(convertedStartTime, weekend)) {
      if (convertedStartTime.hours() < shiftStartHour) {
        hours += shiftDuration;
      } else if (convertedStartTime.hours() < shiftEndHour) {
        hours += calculateDifferenceInHours(
          convertedStartTime,
          getMomentWithHour(convertedStartTime, shiftEndHour),
        );
      }
    }
    convertedStartTime.add(1, 'days').startOf('day');
  }
  if (isWorkingDay(convertedEndTime, weekend)) {
    const shiftStartMomentToday = getMomentWithHour(
      convertedStartTime,
      shiftStartHour,
    );
    const shiftEndMomentToday = getMomentWithHour(
      convertedEndTime,
      shiftEndHour,
    );
    if (
      convertedStartTime.isSameOrBefore(shiftStartMomentToday) &&
      convertedEndTime.isSameOrAfter(shiftEndMomentToday)
    ) {
      hours += shiftDuration;
    } else if (convertedEndTime.isAfter(shiftStartMomentToday)) {
      hours += calculateDifferenceInHours(
        moment.max(convertedStartTime, shiftStartMomentToday),
        moment.min(convertedEndTime, shiftEndMomentToday),
      );
    }
  }
  return hours;
};
