import { isEqual, omit } from 'lodash';
import {
  Spi,
  HtsFee,
  HtsRate,
  HtsSpiRate,
  UsHts,
} from '@xbcb/api-gateway-client';
import log from '@xbcb/log';
import { htsNumber as htsNumberRegex } from '@xbcb/regex';
import section232SteelHTSQuotas from './section232SteelHTSQuotas';
import { UsHtsDataRetriever } from './sao/hsSao';

import { isUnitValueApplicableToHts } from './isUnitValueApplicableToHts';

/**
 * Determines if a given HTS number should have a unit of measure
 * @param {string} htsNumber - HTS number
 * @return {boolean}
 */
export const isHtsWithoutUom = (htsNumber: string) => {
  const htsWithoutDots = htsNumber.replace(/\./g, '');
  const exclusions = ['99', '98', '821510', '821520'];
  const specificNumbers = ['9801001045'];
  if (specificNumbers.includes(htsWithoutDots)) return false;
  return exclusions.some((number) => htsWithoutDots.startsWith(number));
};

export const isRealHts = (htsNumber: string): boolean => {
  const htsWithoutDots = htsNumber?.replace(/\./g, '');
  return (
    htsNumberRegex.test(htsWithoutDots) &&
    isUnitValueApplicableToHts(htsWithoutDots)
  );
};

const quotaHtsNumbers = [
  ...section232SteelHTSQuotas.map((x) => x.number),
  // Iron or Steel Products of Austria, Belgium, Bulgaria, Croatia, Cyprus, Czechia (Czech Republic), Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, Netherlands, Poland, Portugal, Romania, Slovakia, Slovenia, Spain, Sweden
  '99038065',
  '99038066',
  '99038067',
  '99038068',
  '99038069',
  '99038070',
  '99038071',
  '99038072',
  '99038073',
  '99038074',
  '99038075',
  '99038076',
  '99038077',
  '99038078',
  '99038079',
  '99038080',
  '99038081',
  '99038082',
  '99038083',
  '99038084',
  '99038085',
  '99038086',
  '99038087',
  '99038088',
  '99038089',
  '99038090',
  '99038091',
  '99038092',
  '99038093',
  '99038094',
  '99038095',
  '99038096',
  '99038097',
  '99038098',
  '99038099',
  '99038101',
  '99038102',
  '99038103',
  '99038104',
  '99038105',
  '99038106',
  '99038107',
  '99038108',
  '99038109',
  '99038110',
  '99038111',
  '99038112',
  '99038113',
  '99038114',
  '99038115',
  '99038116',
  '99038117',
  '99038118',
  '99038119',
  // section232 aluminum quota
  '99038505',
  '99038525',
  '99038506',
  '99038544',
  // section 201 solar cells/modules
  '99034521',
  // Large residential washers and washing machine parts
  '99034501',
  '99034505',
  // AGOA stuff
  '9819',
  // greek yogurt
  '2202992400',
  // certain tobacco
  '2401208510',
  // Section 301 List 4A
  '2103907400',
  // Peanut Butter 'Unsalted' 13oz
  '2008110500',
  '7228308041', // Bars and rods of alloy steel
  '7228308045',
];

export const isQuotaHts = (htsNumber: string) =>
  quotaHtsNumbers.some((p) => htsNumber.startsWith(p));

export const someQuotaHts = async (
  htsNumbers: string[],
  usHtsDataRetriever: UsHtsDataRetriever,
): Promise<boolean> => {
  const usHtsMap = await usHtsDataRetriever.fetchEffectiveHtss(htsNumbers);
  return Object.values(usHtsMap).some((usHts) => usHts.quota);
};

export const htsWithoutDelimiters = (hts: string) => hts.replace(/\./g, '');

/**
 * To format htsNumber by adding dot delimiters. Eg. 8000800000 will be converted to 8000.80.0000
 * @param {htsNumber} htsNumber
 * @return {dotSeparatedHtsNumber} formattedHtsNumber
 */
export const formatHtsNumber = (htsNumber: string) => {
  if (!htsNumber) return htsNumber;
  const periodsRemoved = htsNumber.replace(/\./g, '');
  return (
    periodsRemoved.substr(0, 4) +
    '.' +
    periodsRemoved.substr(4, 2) +
    '.' +
    periodsRemoved.substr(6)
  );
};

type SpiCode = string;
type HtsJsonSpiRate = {
  adValorem?: number;
  specific?: number;
  other?: number;
};
type HtsJsonSpiRates = {
  [key: SpiCode]: HtsJsonSpiRate;
};

type HtsJsonRecord = {
  spi?: string[];
  duty?: string;
  fees?: {
    feeAdValorem?: number;
    feeSpecific?: number;
    class?: string;
    duty?: string;
  }[];
  pga?: string[];
  uom?: string[];
  rate?: {
    specific?: number;
    adValorem?: number;
    other?: number;
  };
  startDate?: string;
  endDate?: string;
  text?: string;
  gspExcluded?: string[];
  trumpList?: string;
  spiRates?: HtsJsonSpiRates;
  [key: string]: any;
};

/*
 * When given a JSON object returns an Hts object similar to the graphql api.
 * Used so we can fallback to JSON file with minimal other changes in constructCbpSharedEntryData.
 */
export const convertJsonToApiObject = (htsJson: HtsJsonRecord) => {
  const spi = htsJson.spi?.map((code) => {
    return {
      code,
    } as Spi;
  });
  const fees = htsJson.fees?.map((fee) => {
    return {
      class: fee.class,
      rateCode: fee.duty,
      rate: {
        adValorem: fee.feeAdValorem,
        specific: fee.feeSpecific,
      },
    } as HtsFee;
  });
  let spiRates: HtsSpiRate[] | undefined = undefined;
  if (htsJson.spiRates) {
    spiRates = Object.keys(htsJson.spiRates).map((spiCode) => {
      /* this if statement is required because the semantic analyzer 
         doesn't seem smart enough to recognize that htsJson.spiRates 
         was checked for undefined/null a few lines before */
      if (htsJson.spiRates) {
        return {
          code: spiCode,
          adValorem: htsJson.spiRates[spiCode].adValorem,
          specific: htsJson.spiRates[spiCode].specific,
          other: htsJson.spiRates[spiCode].other,
        } as HtsSpiRate;
      }
      return {} as HtsSpiRate; // this should never be hit
    });
  }
  const htsResult = {
    uom: htsJson.uom,
    rateCode: htsJson.duty,
    fees,
    spi,
    rate: htsJson.rate as HtsRate,
    startDate: htsJson.startDate,
    endDate: htsJson.endDate,
    gspExcluded: htsJson.gspExcluded,
    spiRates,
    text: htsJson.text,
  } as UsHts;
  return htsResult;
};

// only compares fields defined in HtsJsonRecord
// sorts all arrays by required value (with exception of fees)
export const compareApiHtsToJsonHts = (
  htsFromApi: UsHts,
  htsFromJson: UsHts,
) => {
  const htsApiStandarized = omit(JSON.parse(JSON.stringify(htsFromApi)), [
    'number',
    'truncatedDescription',
    'description',
  ]) as UsHts;
  const htsJsonStandarized = omit(JSON.parse(JSON.stringify(htsFromJson)), [
    'text',
  ]) as UsHts;

  htsApiStandarized.fees?.sort((a, b) =>
    (a.class || '') < (b.class || '') ? -1 : 1,
  );
  htsJsonStandarized.fees?.sort((a, b) =>
    (a.class || '') < (b.class || '') ? -1 : 1,
  );

  htsApiStandarized.spi?.sort((a, b) => (a.code < b.code ? -1 : 1));
  htsJsonStandarized.spi?.sort((a, b) => (a.code < b.code ? -1 : 1));

  htsApiStandarized.gspExcluded?.sort();
  htsJsonStandarized.gspExcluded?.sort();

  htsApiStandarized.spiRates?.sort((a, b) =>
    (a.code || '') < (b.code || '') ? -1 : 1,
  );
  htsJsonStandarized.spiRates?.sort((a, b) =>
    (a.code || '') < (b.code || '') ? -1 : 1,
  );

  if (!isEqual(htsApiStandarized, htsJsonStandarized)) {
    log.warn('Hts from TDA Api contained incorrect results', {
      key: 'IncorrectResultWithSearchHtsInfo',
      params: {
        htsWithTdaData: htsApiStandarized,
        htsWithJsonData: htsJsonStandarized,
        htsNumber: htsFromApi.number,
      },
    });
  } else {
    log.info('Hts from TDA and Json simplified to match each other', {
      key: 'MatchedResultWithSearchHtsInfo',
      params: {
        htsNumber: htsFromApi.number,
      },
    });
  }
};
