import { UserInputError } from 'apollo-server-errors';
import log from '@xbcb/log';
import {
  deVatNumber,
  esVatNumber,
  euEori,
  frVatNumber,
  gbVatNumber,
  itVatNumber,
  nlVatNumber,
  plVatNumber,
  atVatNumber,
  czVatNumber,
  ukEori,
} from '@xbcb/regex';

import { PartyReferenceNumberType } from '@xbcb/shared-types';

const countryToVatNumberRegexMap: { [key: string]: RegExp } = {
  GB: gbVatNumber,
  NL: nlVatNumber,
  FR: frVatNumber,
  DE: deVatNumber,
  ES: esVatNumber,
  IT: itVatNumber,
  PL: plVatNumber,
  AT: atVatNumber,
  CZ: czVatNumber,
};

const countryToEoriRegexMap: { [key: string]: RegExp } = {
  GB: ukEori,
  NL: euEori,
  FR: euEori,
  DE: euEori,
  ES: euEori,
  IT: euEori,
  PL: euEori,
  AT: euEori,
  CZ: euEori,
};

// Throws an error if the given vatNumber/eori does not match the country RegEx
export const validateInputByCountryRegex = (
  type: PartyReferenceNumberType,
  inputString?: string | null,
  country?: string | null,
): void => {
  if (!inputString || !country) {
    log.info(`inputString or ${country} is null or undefined`, {
      key: 'validateShipmentPartyInputStringUndefined',
    });
    return;
  }

  const regexPattern =
    type === PartyReferenceNumberType.VAT_NUMBER
      ? countryToVatNumberRegexMap[country]
      : countryToEoriRegexMap[country];

  if (!regexPattern) {
    const errorString = `${country} is not supported for ${type} validation.`;
    log.error(errorString, {
      key: 'validateShipmentPartyCountryNotSupported',
    });
    throw new UserInputError(errorString);
  } else if (!regexPattern.test(inputString)) {
    log.error(`Invalid ${type}`, {
      key: 'validateShipmentPartyInvalidType',
    });
    throw new UserInputError(`Invalid ${type}`);
  }
};
