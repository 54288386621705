import React from 'react';
import { capitalCase } from 'change-case';
import {
  formatString,
  selectFilter,
  maxLength,
  getNumber,
  getCodes,
  shouldUpdate,
  isRequired,
  cleanObject,
  safeGetMessage,
} from '@xbcb/ui-utils';
import { MinusButton, OptionalFormSection } from '@xbcb/button-components';
import {
  INLTSwitch as Switch,
  CountrySelect,
  IORNumber,
  PickDate,
  Select,
  PhoneNumber,
  Email,
  ANNumber,
  YearSelect,
  SimpleSelect,
  FormItem,
  IorNumberAndType,
} from '@xbcb/form-item-components';
import { Divider, Form, Input, Button } from 'antd';
import { FormInstance } from 'antd/lib/form';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import moment from 'moment';
import { get, omit } from 'lodash';
import { SingleFormCard } from '@xbcb/display-components';
import { CssSize } from '@xbcb/ui-types';
import {
  UsIorNumberType,
  UsCbp5106EntriesPerYearCode,
  UsCbp5106RelatedBusinessType,
} from '@xbcb/party-types';
import { PassportType, AnyObject, AccountType } from '@xbcb/shared-types';
import { passportNumberRegex } from '@xbcb/regex';
import { StyledDiv, StyledParagraph } from './styles';
import { useBundle } from '@amzn/react-arb-tools';

const {
  ONE_TO_FOUR,
  FIVE_TO_TWENTY_FIVE,
  TWENTY_FIVE_OR_MORE,
  INFREQUENT_PERSONAL_SHIPMENTS,
  DO_NOT_INTEND_TO_IMPORT,
} = UsCbp5106EntriesPerYearCode;
const entriesPerYearCodes = {
  [ONE_TO_FOUR]: '1-4 per year',
  [FIVE_TO_TWENTY_FIVE]: '5-24 per year',
  [TWENTY_FIVE_OR_MORE]: '25 or more per year',
  [INFREQUENT_PERSONAL_SHIPMENTS]: 'Infrequent personal shipments',
  [DO_NOT_INTEND_TO_IMPORT]: 'Do not intend to import',
};

interface OptionalCompanyInfoProps {
  form: FormInstance;
  disabled?: boolean;
  readOnly?: boolean;
  accountType?: AccountType;
}

const endYear = moment().year();

const OptionalCompanyInfo = (props: OptionalCompanyInfoProps) => {
  const [bundle] = useBundle('components.formComponents.OptionalCompanyInfo');
  const [sharedBundle] = useBundle('shared');
  const { form, readOnly, disabled, accountType } = props;
  const codes = getCodes();
  const programCodes = get(codes, 'CBP.CATAIR.importerProgram');
  const countryCodes = get(codes, 'ISO.country.alpha2');
  // TODO store all fields in variables
  const importActivitySectionFields = [
    ['entriesPerYearCode'],
    ['programCodes'],
  ];
  const businessInformationSectionFields = [
    ['businessDescription'],
    ['establishment', 'year'],
    ['establishment', 'stateCode'],
    ['establishment', 'countryCode'],
  ];
  const naicsSectionFields = [['naics']];
  const relatedBusinessesSectionFields = [['relatedBusinesses']];
  const bankSectionFields = [
    ['bank', 'name'],
    ['bank', 'routingNumber'],
    ['bank', 'city'],
    ['bank', 'stateCode'],
    ['bank', 'countryCode'],
  ];
  const individualsSectionFields = [['individuals']];

  // If it's readOnly mode and none of the fields are filled out, we should
  // not show anything
  if (
    readOnly &&
    ![
      ...importActivitySectionFields,
      ...businessInformationSectionFields,
      ...naicsSectionFields,
      ...relatedBusinessesSectionFields,
      ...bankSectionFields,
      ...individualsSectionFields,
    ].some((field) => cleanObject(form.getFieldValue(field)))
  ) {
    return null;
  }

  return (
    <SingleFormCard
      title={safeGetMessage(bundle, 'advanced_info')}
      extraContent={
        <StyledDiv>
          <p>{safeGetMessage(bundle, 'in_most_cases')}</p>
          <br />
          <p>{safeGetMessage(bundle, 'absence_of_information')}</p>
        </StyledDiv>
      }
    >
      <div>
        <Form.Item
          shouldUpdate={shouldUpdate(importActivitySectionFields)}
          noStyle
        >
          {() => {
            return (
              <OptionalFormSection
                title={safeGetMessage(bundle, 'import_activity')}
                fields={importActivitySectionFields}
                form={form}
                readOnly={readOnly}
              >
                <SimpleSelect
                  form={form}
                  readOnly={readOnly}
                  disabled={disabled}
                  localNamePath={['entriesPerYearCode']}
                  fullNamePath={['entriesPerYearCode']}
                  required={false}
                  $itemSize={CssSize.SHORT_MEDIUM}
                  label={safeGetMessage(bundle, 'how_many_entries')}
                  map={omit(entriesPerYearCodes, [
                    UsCbp5106EntriesPerYearCode.DO_NOT_INTEND_TO_IMPORT,
                  ])}
                  dropdownMatchSelectWidth={false}
                  allowClear
                />
                <SimpleSelect
                  form={form}
                  readOnly={readOnly}
                  disabled={disabled}
                  localNamePath={['programCodes']}
                  fullNamePath={['programCodes']}
                  required={false}
                  $itemSize={CssSize.MEDIUM}
                  label={safeGetMessage(bundle, 'do_you_participate')}
                  multiple
                  map={programCodes}
                  dropdownMatchSelectWidth={false}
                />
              </OptionalFormSection>
            );
          }}
        </Form.Item>
        <Divider />
        <Form.Item
          shouldUpdate={shouldUpdate(businessInformationSectionFields)}
          noStyle
        >
          {() => {
            return (
              <OptionalFormSection
                title={safeGetMessage(bundle, 'business_information')}
                fields={businessInformationSectionFields}
                form={form}
                readOnly={readOnly}
              >
                <FormItem
                  label={safeGetMessage(bundle, 'provide_business_information')}
                  name="businessDescription"
                  $itemSize={CssSize.MEDIUM}
                  $readOnly={readOnly}
                  getValueFromEvent={maxLength(40)}
                  debounce
                >
                  <Input disabled={disabled} />
                </FormItem>
                <div>
                  <YearSelect
                    form={form}
                    localNamePath={['establishment', 'year']}
                    readOnly={readOnly}
                    disabled={disabled}
                    label={safeGetMessage(bundle, 'year_established')}
                    startYear={1700}
                    endYear={endYear}
                    $inline
                  />
                  <Switch
                    readOnly={readOnly}
                    disabled={disabled}
                    onWord={safeGetMessage(sharedBundle, 'yes')}
                    offWord={safeGetMessage(sharedBundle, 'no')}
                    label={safeGetMessage(bundle, 'same_as_mailing_address')}
                    field="sameEstablishmentState"
                    onChange={(newStatus?: boolean) => {
                      // If we toggle the switch to "Yes", reset the physical address data
                      if (newStatus) {
                        form.setFields([
                          {
                            name: ['establishment', 'stateCode'],
                            value: undefined,
                          },
                          {
                            name: ['establishment', 'countryCode'],
                            value: undefined,
                          },
                        ]);
                      }
                    }}
                  />
                </div>
                <Form.Item
                  shouldUpdate={shouldUpdate([['sameEstablishmentState']])}
                  noStyle
                >
                  {() => {
                    const sameEstablishmentState = form.getFieldValue([
                      'sameEstablishmentState',
                    ]);
                    return (
                      !sameEstablishmentState && (
                        <div>
                          <Form.Item
                            shouldUpdate={shouldUpdate([
                              ['establishment', 'countryCode'],
                            ])}
                            noStyle
                          >
                            {() => {
                              const establishmentCountryCode =
                                form.getFieldValue([
                                  'establishment',
                                  'countryCode',
                                ]) || 'US';
                              const establishmentStateOptions = Object.entries(
                                get(
                                  countryCodes,
                                  [establishmentCountryCode, 'subdivision'],
                                  {},
                                ),
                              ).map(([code, data]: any) => (
                                <Select.Option key={code} value={code}>
                                  {data.name}
                                </Select.Option>
                              ));
                              return (
                                <>
                                  <CountrySelect
                                    $inline
                                    readOnly={readOnly}
                                    disabled={disabled}
                                    localNamePath={[
                                      'establishment',
                                      'countryCode',
                                    ]}
                                    $itemSize={CssSize.TINY_SHORT}
                                    label={safeGetMessage(
                                      bundle,
                                      'incorporation_country',
                                    )}
                                    onChange={() => {
                                      form.setFields([
                                        {
                                          name: ['establishment', 'stateCode'],
                                          value: undefined,
                                        },
                                      ]);
                                    }}
                                  />
                                  <FormItem
                                    label={safeGetMessage(
                                      bundle,
                                      'incorporation_state_province',
                                    )}
                                    name={['establishment', 'stateCode']}
                                    $itemSize={CssSize.SHORT}
                                    $readOnly={readOnly}
                                    $inline
                                  >
                                    <Select
                                      disabled={disabled}
                                      showSearch
                                      filterOption={selectFilter}
                                      notFoundContent={safeGetMessage(
                                        sharedBundle,
                                        'none_found',
                                      )}
                                      dropdownMatchSelectWidth={false}
                                      allowClear
                                    >
                                      {establishmentStateOptions}
                                    </Select>
                                  </FormItem>
                                </>
                              );
                            }}
                          </Form.Item>
                        </div>
                      )
                    );
                  }}
                </Form.Item>
              </OptionalFormSection>
            );
          }}
        </Form.Item>
        <Divider />
        <OptionalFormSection
          title={safeGetMessage(bundle, 'business_numbers')}
          fields={naicsSectionFields}
          form={form}
          readOnly={readOnly}
        >
          <ANNumber
            form={form}
            debounce
            readOnly={readOnly}
            disabled={disabled}
            fullNamePath={['naics']}
            localNamePath={['naics']}
            mask={getNumber}
            $itemSize={CssSize.SHORT_MEDIUM}
            max={6}
            label={safeGetMessage(bundle, 'naics')}
          />
        </OptionalFormSection>
        <Divider />
        <OptionalFormSection
          title={safeGetMessage(bundle, 'related_businesses')}
          fields={relatedBusinessesSectionFields}
          form={form}
          readOnly={readOnly}
        >
          <StyledParagraph>
            {safeGetMessage(bundle, 'list_names_and_internal_revenue_service')}
          </StyledParagraph>
          <Form.List name={['relatedBusinesses']} minimum={1}>
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((field, index) => {
                    const fieldsToCheck = [
                      ['relatedBusinesses', field.name, 'name'],
                      ['relatedBusinesses', field.name, 'iorNumber', 'value'],
                      ['relatedBusinesses', field.name, 'type'],
                    ];
                    return (
                      <Form.Item
                        shouldUpdate={shouldUpdate(fieldsToCheck)}
                        noStyle
                        key={field.key}
                      >
                        {() => {
                          const required = isRequired(form, fieldsToCheck);
                          if (readOnly && !required) return null;
                          return (
                            <div>
                              <div>
                                <FormItem
                                  label={safeGetMessage(
                                    bundle,
                                    'name_of_business',
                                  )}
                                  name={[field.name, 'name']}
                                  $itemSize={CssSize.SHORT}
                                  $readOnly={readOnly}
                                  $inline
                                  rules={[{ required }]}
                                  debounce
                                  getValueFromEvent={maxLength(30)}
                                >
                                  <Input
                                    disabled={disabled}
                                    onBlur={() =>
                                      formatString(form, [
                                        'relatedBusinesses',
                                        field.name,
                                        'name',
                                      ])
                                    }
                                  />
                                </FormItem>
                                {!readOnly && (
                                  <MinusButton
                                    label={safeGetMessage(
                                      bundle,
                                      'related_business',
                                    )}
                                    firstRow
                                    onRemove={() => remove(field.name)}
                                    disabled={disabled}
                                  />
                                )}
                              </div>
                              <IorNumberAndType
                                form={form}
                                readOnly={readOnly}
                                disabled={disabled}
                                accountType={accountType}
                                localNamePath={[field.name, 'iorNumber']}
                                fullNamePath={[
                                  'relatedBusinesses',
                                  field.name,
                                  'iorNumber',
                                ]}
                                required={required}
                              />
                              <SimpleSelect
                                form={form}
                                disabled={disabled}
                                readOnly={readOnly}
                                $itemSize={CssSize.TINY_SHORT}
                                label={safeGetMessage(
                                  bundle,
                                  'current_or_previous',
                                )}
                                required={required}
                                localNamePath={[field.name, 'type']}
                                fullNamePath={[
                                  'relatedBusinesses',
                                  field.name,
                                  'type',
                                ]}
                                selectOptions={Object.values(
                                  UsCbp5106RelatedBusinessType,
                                )}
                                map={(typeCode: UsCbp5106RelatedBusinessType) =>
                                  capitalCase(typeCode)
                                }
                                inline
                                allowClear
                              />
                            </div>
                          );
                        }}
                      </Form.Item>
                    );
                  })}
                  {!readOnly && (
                    <Button
                      type="dashed"
                      onClick={add}
                      disabled={disabled || fields.length >= 3}
                    >
                      <PlusOutlined />
                      {safeGetMessage(bundle, 'related_business')}
                    </Button>
                  )}
                </>
              );
            }}
          </Form.List>
        </OptionalFormSection>
        <Divider />
        <Form.Item shouldUpdate={shouldUpdate(bankSectionFields)} noStyle>
          {() => {
            return (
              <OptionalFormSection
                title={safeGetMessage(bundle, 'primary_banking_information')}
                fields={bankSectionFields}
                form={form}
                readOnly={readOnly}
              >
                <div>
                  <FormItem
                    label={safeGetMessage(bundle, 'name')}
                    name={['bank', 'name']}
                    $itemSize={CssSize.SHORT}
                    $readOnly={readOnly}
                    $inline
                    debounce
                  >
                    <Input
                      disabled={disabled}
                      onBlur={() => formatString(form, ['bank', 'name'])}
                    />
                  </FormItem>
                  <ANNumber
                    form={form}
                    debounce
                    localNamePath={['bank', 'routingNumber']}
                    fullNamePath={['bank', 'routingNumber']}
                    mask={getNumber}
                    readOnly={readOnly}
                    disabled={disabled}
                    max={11}
                    label={safeGetMessage(bundle, 'routing_number')}
                  />
                </div>
                <div>
                  <FormItem
                    label={safeGetMessage(bundle, 'city')}
                    name={['bank', 'city']}
                    $itemSize={CssSize.TINY_SHORT}
                    $readOnly={readOnly}
                    $inline
                    debounce
                  >
                    <Input
                      disabled={disabled}
                      onBlur={() => formatString(form, ['bank', 'city'])}
                    />
                  </FormItem>
                  <CountrySelect
                    $inline
                    readOnly={readOnly}
                    disabled={disabled}
                    localNamePath={['bank', 'countryCode']}
                    $itemSize={CssSize.TINY_SHORT}
                    label={safeGetMessage(bundle, 'country')}
                    onChange={() => {
                      form.setFields([
                        {
                          name: ['bank', 'stateCode'],
                          value: undefined,
                        },
                      ]);
                    }}
                  />
                  <Form.Item
                    shouldUpdate={shouldUpdate([['bank', 'countryCode']])}
                    noStyle
                  >
                    {() => {
                      const bankCountryCode =
                        form.getFieldValue(['bank', 'countryCode']) || 'US';
                      const bankStateOptions = Object.entries(
                        get(countryCodes, [bankCountryCode, 'subdivision'], {}),
                      ).map(([code, data]: any) => (
                        <Select.Option key={code} value={code}>
                          {data.name}
                        </Select.Option>
                      ));
                      return (
                        <FormItem
                          label={safeGetMessage(bundle, 'state_province')}
                          name={['bank', 'stateCode']}
                          $itemSize={CssSize.TINY_SHORT}
                          $readOnly={readOnly}
                          $inline
                        >
                          <Select
                            disabled={disabled}
                            showSearch
                            filterOption={selectFilter}
                            notFoundContent={safeGetMessage(
                              sharedBundle,
                              'none_found',
                            )}
                            dropdownMatchSelectWidth={false}
                            allowClear
                          >
                            {bankStateOptions}
                          </Select>
                        </FormItem>
                      );
                    }}
                  </Form.Item>
                </div>
              </OptionalFormSection>
            );
          }}
        </Form.Item>
        <Divider />
        <OptionalFormSection
          title={safeGetMessage(bundle, 'company_officers')}
          fields={individualsSectionFields}
          form={form}
          readOnly={readOnly}
        >
          <Form.List name={['individuals']} minimum={1}>
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map((field, index) => {
                    const passportFields = [
                      ['individuals', field.name, 'passport', 'number'],
                      ['individuals', field.name, 'passport', 'countryCode'],
                      ['individuals', field.name, 'passport', 'expiration'],
                      ['individuals', field.name, 'passport', 'type'],
                    ];
                    const fieldsToCheck = [
                      ['individuals', field.name, 'name'],
                      ['individuals', field.name, 'title'],
                      ['individuals', field.name, 'phone', 'country'],
                      ['individuals', field.name, 'phone', 'number'],
                      ['individuals', field.name, 'phone', 'extension'],
                      ['individuals', field.name, 'email'],
                      ['individuals', field.name, 'socialSecurityNumber'],
                      ...passportFields,
                    ];
                    return (
                      <Form.Item
                        shouldUpdate={shouldUpdate(fieldsToCheck)}
                        noStyle
                        key={field.key}
                      >
                        {() => {
                          const someSubFieldsFilledOut = isRequired(
                            form,
                            fieldsToCheck,
                          );
                          const somePassportFieldsFilledOut = isRequired(
                            form,
                            passportFields,
                          );
                          if (readOnly && !someSubFieldsFilledOut) return null;
                          return (
                            <div>
                              <div>
                                <FormItem
                                  label={safeGetMessage(
                                    bundle,
                                    'full_name_of_officer',
                                  )}
                                  name={[field.name, 'name']}
                                  $itemSize={CssSize.SHORT}
                                  $readOnly={readOnly}
                                  $inline
                                  rules={[
                                    { required: someSubFieldsFilledOut },
                                    { pattern: /^\w+\s+\w+(\s+\w+)?$/ },
                                  ]}
                                  debounce
                                  getValueFromEvent={maxLength(30)}
                                >
                                  <Input
                                    disabled={disabled}
                                    onBlur={() =>
                                      formatString(form, [
                                        'individuals',
                                        field.name,
                                        'name',
                                      ])
                                    }
                                  />
                                </FormItem>
                                <FormItem
                                  label={safeGetMessage(
                                    bundle,
                                    'company_title',
                                  )}
                                  name={[field.name, 'title']}
                                  $itemSize={CssSize.SHORT}
                                  $readOnly={readOnly}
                                  $inline
                                  rules={[{ required: someSubFieldsFilledOut }]}
                                  debounce
                                  getValueFromEvent={maxLength(22)}
                                >
                                  <Input
                                    disabled={disabled}
                                    onBlur={() =>
                                      formatString(form, [
                                        'individuals',
                                        field.name,
                                        'title',
                                      ])
                                    }
                                  />
                                </FormItem>
                                {!readOnly && (
                                  <MinusButton
                                    label={safeGetMessage(bundle, 'officer')}
                                    firstRow
                                    onRemove={() => remove(field.name)}
                                    disabled={disabled}
                                  />
                                )}
                              </div>
                              <div>
                                <PhoneNumber
                                  disabled={disabled}
                                  readOnly={readOnly}
                                  label={safeGetMessage(bundle, 'phone')}
                                  form={form}
                                  localNamePath={[field.name, 'phone']}
                                  fullNamePath={[
                                    'individuals',
                                    field.name,
                                    'phone',
                                  ]}
                                  $inline
                                  $spaceBottom
                                  required={someSubFieldsFilledOut}
                                />
                                <ANNumber
                                  form={form}
                                  readOnly={readOnly}
                                  disabled={disabled}
                                  debounce
                                  localNamePath={[
                                    field.name,
                                    'phone',
                                    'extension',
                                  ]}
                                  fullNamePath={[
                                    'individuals',
                                    field.name,
                                    'phone',
                                    'extension',
                                  ]}
                                  mask={getNumber}
                                  max={9}
                                  label={safeGetMessage(bundle, 'extension')}
                                />
                              </div>
                              <div>
                                <Email
                                  localNamePath={[field.name, 'email']}
                                  label={safeGetMessage(bundle, 'direct_email')}
                                  readOnly={readOnly}
                                  disabled={disabled}
                                  $inline
                                  required={someSubFieldsFilledOut}
                                />
                                <IORNumber
                                  form={form}
                                  localNamePath={[
                                    field.name,
                                    'socialSecurityNumber',
                                  ]}
                                  fullNamePath={[
                                    'individuals',
                                    field.name,
                                    'socialSecurityNumber',
                                  ]}
                                  type={UsIorNumberType.SSN}
                                  accountType={accountType}
                                  hideTooltip
                                  readOnly={readOnly}
                                  disabled={disabled}
                                  $itemSize={CssSize.TINY_SHORT}
                                  $inline
                                />
                              </div>
                              <div>
                                <ANNumber
                                  form={form}
                                  readOnly={readOnly}
                                  disabled={disabled}
                                  localNamePath={[
                                    field.name,
                                    'passport',
                                    'number',
                                  ]}
                                  fullNamePath={[
                                    'individuals',
                                    field.name,
                                    'passport',
                                    'number',
                                  ]}
                                  $itemSize={CssSize.TINY_SHORT}
                                  max={13}
                                  pattern={passportNumberRegex}
                                  label={safeGetMessage(
                                    bundle,
                                    'passport_number',
                                  )}
                                  required={somePassportFieldsFilledOut}
                                  onChange={(e: AnyObject) => {
                                    const val = e.target.value;
                                    // The other passport fields show according to weather this
                                    // field is filled out or not. If the passport number is set
                                    // to `""`, it should reset the other passport fields. We need
                                    // to explicitly use `resetFields` rather than `setFields` to
                                    // set them each to undefined otherwise the form will try to
                                    // validate the `passportNumber` as required, which should not
                                    //  be the case since it is optional and now none of the other
                                    // fields are filled out. `resetFields` will remount the component
                                    // to ensure the validation is correct (aka `required` is updated)
                                    if (!val) form.resetFields(passportFields);
                                  }}
                                />
                                <Form.Item
                                  shouldUpdate={shouldUpdate([
                                    [
                                      'individuals',
                                      field.name,
                                      'passport',
                                      'number',
                                    ],
                                  ])}
                                  noStyle
                                >
                                  {() =>
                                    form.getFieldValue([
                                      'individuals',
                                      field.name,
                                      'passport',
                                      'number',
                                    ]) && (
                                      <>
                                        <CountrySelect
                                          readOnly={readOnly}
                                          disabled={disabled}
                                          localNamePath={[
                                            field.name,
                                            'passport',
                                            'countryCode',
                                          ]}
                                          $itemSize={CssSize.TINY_SHORT}
                                          label={safeGetMessage(
                                            bundle,
                                            'country_of_issuance',
                                          )}
                                          $inline
                                          required={somePassportFieldsFilledOut}
                                        />
                                        <PickDate
                                          form={form}
                                          localNamePath={[
                                            field.name,
                                            'passport',
                                            'expiration',
                                          ]}
                                          fullNamePath={[
                                            'individuals',
                                            field.name,
                                            'passport',
                                            'expiration',
                                          ]}
                                          label={safeGetMessage(
                                            bundle,
                                            'expiration',
                                          )}
                                          disabledDate={(current) =>
                                            current &&
                                            current <
                                              moment().subtract(1, 'day')
                                          }
                                          readOnly={readOnly}
                                          disabled={disabled}
                                          required={somePassportFieldsFilledOut}
                                        />
                                        <SimpleSelect
                                          form={form}
                                          $itemSize={CssSize.TINY_SHORT}
                                          label={safeGetMessage(bundle, 'type')}
                                          readOnly={readOnly}
                                          required={somePassportFieldsFilledOut}
                                          localNamePath={[
                                            field.name,
                                            'passport',
                                            'type',
                                          ]}
                                          fullNamePath={[
                                            'individuals',
                                            field.name,
                                            'passport',
                                            'type',
                                          ]}
                                          selectOptions={Object.values(
                                            PassportType,
                                          )}
                                          map={(typeCode: PassportType) =>
                                            capitalCase(typeCode)
                                          }
                                          inline
                                          allowClear
                                        />
                                      </>
                                    )
                                  }
                                </Form.Item>
                              </div>
                              {field.name < fields.length - 1 && <Divider />}
                            </div>
                          );
                        }}
                      </Form.Item>
                    );
                  })}
                  {!readOnly && (
                    <Button
                      type="dashed"
                      onClick={add}
                      disabled={disabled || fields.length >= 4}
                    >
                      <PlusOutlined />
                      {safeGetMessage(bundle, 'company_officer')}
                    </Button>
                  )}
                </>
              );
            }}
          </Form.List>
        </OptionalFormSection>
      </div>
    </SingleFormCard>
  );
};

export default OptionalCompanyInfo;
