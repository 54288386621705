import React from 'react';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { FieldData } from 'rc-field-form/lib/interface';
import IORNumber from '../IORNumber';
import IORNumberType, { ShowTypesInterface } from '../IORNumberType';
import { CssSize, NamePath } from '@xbcb/ui-types';
import { UsIorNumberType } from '@xbcb/party-types';
import { shouldUpdate } from '@xbcb/ui-utils';
import { AccountType } from '@xbcb/shared-types';

type PropsForIorNumberAndType = {
  form: FormInstance;
  readOnly?: boolean;
  disabled?: boolean;
  accountType?: AccountType;
  localNamePath: NamePath;
  fullNamePath: NamePath;
  required?: boolean;
  showTypes?: ShowTypesInterface;
  // Can be set when only the number should be readOnly
  numberReadOnly?: boolean;
  // Can be set when only the number should be disabled
  numberDisabled?: boolean;
  // additional fields to set when the ior number type changes
  additionalFieldsToSet?: FieldData[];
};

const IorNumberAndType = ({
  form,
  readOnly,
  disabled,
  accountType,
  localNamePath,
  fullNamePath,
  required,
  showTypes = {
    ein: true,
    ssn: true,
    cbp: true,
  },
  numberReadOnly,
  numberDisabled,
  additionalFieldsToSet = [],
}: PropsForIorNumberAndType) => {
  const { getFieldValue, setFields } = form;
  const formValue = getFieldValue([...fullNamePath, 'type']);
  const initialType = UsIorNumberType.EIN;
  return (
    <>
      <IORNumberType
        $inline
        localNamePath={[...localNamePath, 'type']}
        showTypes={showTypes}
        disabled={disabled}
        readOnly={readOnly}
        initialValue={formValue ? undefined : initialType}
        onChange={() => {
          setFields([
            ...additionalFieldsToSet,
            {
              name: [...fullNamePath, 'value'],
              value: undefined,
            },
          ]);
        }}
        required={required}
      />
      <Form.Item
        shouldUpdate={shouldUpdate([[...fullNamePath, 'type']])}
        noStyle
      >
        {() => {
          const type = getFieldValue([...fullNamePath, 'type']) || initialType;
          return (
            <IORNumber
              form={form}
              localNamePath={[...localNamePath, 'value']}
              fullNamePath={[...fullNamePath, 'value']}
              type={type}
              accountType={accountType}
              hideTooltip
              readOnly={readOnly || numberReadOnly}
              disabled={disabled || numberDisabled}
              $itemSize={CssSize.TINY_SHORT}
              $inline
              required={required}
            />
          );
        }}
      </Form.Item>
    </>
  );
};

export default IorNumberAndType;
