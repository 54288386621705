import React from 'react';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { Rule } from 'antd/lib/form';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { createDataCyValue, DataCyPrefix, NamePath } from '@xbcb/ui-types';
import FormItem from '../FormItem';
import { StyledRadio } from './styles';

type RadioProps = {
  disabled?: boolean;
  localNamePath: NamePath;
  label?: string;
  onChange?: (e: RadioChangeEvent) => void;
  required?: boolean;
  size?: SizeType;
  options?: { value: string; label: string; className?: string }[];
  readOnly?: boolean;
  value?: any;
  rules?: Rule[];
  $inline?: boolean;
  initialValue?: any;
};

const AppRadioGroup = ({
  disabled,
  localNamePath,
  label,
  onChange,
  required,
  size,
  options = [],
  readOnly,
  value,
  rules = [],
  $inline,
  ...restProps
}: RadioProps) => {
  const buttons = options.map(({ value, label, className }) => (
    // TODO this may need more readOnly styling
    <Radio.Button
      key={value}
      value={value}
      className={className}
      data-cy={createDataCyValue(DataCyPrefix.RADIO_BUTTON, label)}
    >
      {label}
    </Radio.Button>
  ));

  return (
    <FormItem
      label={label}
      name={localNamePath}
      required={required}
      rules={rules}
      $inline={$inline}
      $readOnly={readOnly}
      {...restProps}
    >
      <StyledRadio
        onChange={onChange}
        size={size}
        disabled={disabled}
        value={value}
        name={localNamePath.toString()}
      >
        {buttons}
      </StyledRadio>
    </FormItem>
  );
};

export default AppRadioGroup;
