/* eslint-disable camelcase */
// import GA from 'react-ga';
import Butter from 'buttercms';
import { UiStage } from '@xbcb/ui-types';
import { getEnv } from '@xbcb/ui-env';

// stage version are available outside a closure
const { stage } = getEnv();

export const initButter = () => {
  const { BUTTER_API_KEY } = getEnv();
  window.butter = Butter(BUTTER_API_KEY, false, 6000);
};

export const initGA = () => {
  const { GA_ID: gaId } = getEnv();
  if (stage === UiStage.PROD) {
    // GA.initialize(gaId);
    // GA.set({appVersion: version});
  } else {
    // GA.initialize('foo', { testMode: true });
    // GA.set({ appVersion: version });
    // window.GA = GA;
  }
};

export const initLinkedIn = () => {
  if (stage !== 'prod') return null;
  const { LINKED_IN_ID: linkedInId } = getEnv();
  window._linkedin_data_partner_id = linkedInId.toString();
  (function () {
    const s = document.getElementsByTagName('script')[0];
    const b = document.createElement('script');
    b.type = 'text/javascript';
    b.async = true;
    b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
    s.parentNode.insertBefore(b, s);
  })();
};

export const initIntercom = () => {
  if (stage !== UiStage.PROD) return;
  // Must be called at runtime because it might not be loaded
  const { INTERCOM_ID } = getEnv();
  (function () {
    const w = window;
    const ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      const d = document;
      // eslint-disable-next-line
      var i = function () {
        // eslint-disable-next-line
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      // eslint-disable-next-line
      function l() {
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/' + INTERCOM_ID;
        const x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      }
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
};

export const startIntercom = (attributes = {}) => {
  if (stage !== UiStage.PROD) return;
  // Must be called at runtime because it might not be loaded
  const { INTERCOM_ID } = getEnv();
  window.Intercom('boot', {
    app_id: INTERCOM_ID,
    horizontal_padding: 37,
    vertical_padding: 20,
    ...attributes,
  });
};

export const hideIntercom = () => {
  window.Intercom('update', { hide_default_launcher: true });
};
