import { CrudPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const ForwarderUserProductPermissionName = {
  ...CrudPermissionName,
};

export default {
  [ObjectType.PRODUCT]: ForwarderUserProductPermissionName,
};
