import { CrudPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const ShipperUserDocumentPermissionName = {
  ...CrudPermissionName,
};
export const ShipperUserDocumentTemplatePermissionName = {
  ...CrudPermissionName,
};

export default {
  [ObjectType.DOCUMENT]: ShipperUserDocumentPermissionName,
  [ObjectType.DOCUMENT_TEMPLATE]: ShipperUserDocumentTemplatePermissionName,
};
