import fs from 'fs';

/**
 * @param {string} fileName
 * @param {Object} json
 * @return {undefined}
 */
export const writeJson = (fileName: string, json: any) => {
  if (!fileName) throw new Error('No filename to write');
  if (!json) throw new Error('No JSON to write');
  fs.writeFileSync(fileName, JSON.stringify(json, null, 2));
};
