import { useQuery } from '@apollo/client';
import { Document } from '@xbcb/api-gateway-client';
import { documentFragments } from '@xbcb/document-queries';
import { ObjectType, Tag } from '@xbcb/shared-types';
import {
  getRecordFromResponseV2,
  SearchQuery,
  createSearchQueryVariables,
} from '@xbcb/shared-queries';

interface UseSearchDocumentsProps {
  operatorId: string;
  tags: Tag[];
  searchDeletedDocuments?: boolean;
  skip?: boolean;
}

interface UseSearchDocuments {
  documents: Document[];
  loading: boolean;
}

// Searches for documents by operatorId and the provided tags, optionally
// searches deleted documents
export const useSearchDocuments = ({
  operatorId,
  searchDeletedDocuments,
  skip,
  tags,
}: UseSearchDocumentsProps): UseSearchDocuments => {
  const recordName = ObjectType.DOCUMENT;
  const searchQuery = SearchQuery({
    recordName,
    fields: '...documentFields',
    fragments: documentFragments,
  });
  const searchQueryVariables = createSearchQueryVariables({
    deletedTimeExists: searchDeletedDocuments,
    operatorId,
    sortOptions: [
      {
        field: 'createdTime',
      },
    ],
    // Without sorting the tags we risk the variables technically being
    // different across the different use cases of the hook
    tags: tags.sort((tag1, tag2) => tag1.key.localeCompare(tag2.key)),
  });
  const { loading, data } = useQuery(searchQuery, {
    skip: !operatorId || skip,
    variables: searchQueryVariables,
  });

  const payload = getRecordFromResponseV2({
    response: { data },
    crudOrSearchType: 'search',
    recordName,
  });
  const documents: Document[] = payload?.results || [];
  return { documents, loading };
};
