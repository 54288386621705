import log from '@xbcb/log';
import {
  InvoiceStatus,
  ChargeCode,
  CalculateAmendmentResultStatus,
} from '@xbcb/finance-types';
import {
  UsConsumptionEntry,
  UsCbpEntryCharges,
} from '@xbcb/api-gateway-client';
import { Amendment } from '@xbcb/finance-schema';

type CalculateUsConsumptionEntryAmendmentProps = {
  oldUsConsumptionEntry: UsConsumptionEntry;
  newUsConsumptionEntry: UsConsumptionEntry;
  operatorId: string;
};

const calculateDutiesFees = (cbpCharges?: UsCbpEntryCharges) => {
  const duties = cbpCharges?.duties || 0;
  const taxes = cbpCharges?.taxes || 0;
  const fees = cbpCharges?.fees || 0;
  return duties + taxes + fees;
};

/**
 * Determines if any invoices need to be amended when duty fees change on a UsConsumptionEntry
 * @module
 * @param {Object} oldUsConsumptionEntry - UsConsumptionEntry object
 * @param {Object} newUsConsumptionEntry - UsConsumptionEntry object
 * @param {string} operatorId - ID of the user in the format: `${recordType}_${UUID}`
 * @return {Object}
 */
export default async ({
  oldUsConsumptionEntry,
  newUsConsumptionEntry,
  operatorId,
}: CalculateUsConsumptionEntryAmendmentProps): Promise<Amendment> => {
  const newUsConsumptionEntryId = newUsConsumptionEntry.id;
  const shipperId = oldUsConsumptionEntry?.group?.shipper?.id;
  const iorId = oldUsConsumptionEntry?.ior?.usIor?.id;
  const oldDutiesFees = calculateDutiesFees(oldUsConsumptionEntry?.cbpCharges);
  let newDutiesFees = calculateDutiesFees(newUsConsumptionEntry?.cbpCharges);
  if (oldDutiesFees > 0) {
    const newPaymentType = newUsConsumptionEntry.paymentType;
    const brokerPaysDuty =
      newPaymentType && ['6', '2'].includes(newPaymentType);
    const oldPaymentType = oldUsConsumptionEntry.paymentType;
    const brokerDidPay = oldPaymentType && ['6', '2'].includes(oldPaymentType);
    if (
      (brokerPaysDuty && oldDutiesFees !== newDutiesFees) ||
      (brokerDidPay && !brokerPaysDuty)
    ) {
      if (brokerDidPay && !brokerPaysDuty) newDutiesFees = 0;
      const invoices = newUsConsumptionEntry.group.invoices || [];
      let existingDutyInvoice;
      let existingDutyLine;
      for (const invoice of invoices) {
        if (
          [InvoiceStatus.PENDING_VOID, InvoiceStatus.VOID].includes(
            invoice.status as InvoiceStatus,
          )
        )
          continue;
        const { lines = [] } = invoice;
        const dutyLine = lines.filter((line) =>
          [ChargeCode.DUTY_PMS, ChargeCode.DUTY_DAILY].includes(
            line.type as ChargeCode,
          ),
        )[0];
        if (dutyLine) {
          existingDutyInvoice = invoice;
          existingDutyLine = dutyLine;
          break;
        }
      }
      if (existingDutyInvoice && existingDutyLine) {
        let status;
        const change = newDutiesFees - oldDutiesFees;
        const invoiceId = existingDutyInvoice.id;
        const invoiceStatus = existingDutyInvoice.status;
        const logFields = {
          value: change,
          invoiceId,
          invoiceLineId: existingDutyLine.id,
          shipperId,
          iorId,
          workOrderId: newUsConsumptionEntryId,
          workOrderGroupId: newUsConsumptionEntry.group.id,
          operatorId,
          string: invoiceStatus,
          params: { invoice: existingDutyInvoice, line: existingDutyLine },
        };
        log.info(
          `Processing duty amendment of ${change} on consumption entry ${newUsConsumptionEntryId} with invoice ${invoiceId} in ${invoiceStatus} status`,
          { ...logFields, key: 'amendmentProcessing' },
        );
        let amendmentMessage;
        if (invoiceStatus === InvoiceStatus.UPCOMING) {
          // not invoiced (sent to customer), just update lines but don't issue new invoice
          // rare that duty would ever be in this state, because we invoice right after we add the line
          // only if there was some processing error with the invoicing
          amendmentMessage = 'We will update the line on the above invoice.';
          log.info(
            `Will update existing upcoming invoice ${invoiceId} for consumption entry ${newUsConsumptionEntryId} with changed duty amount ${oldDutiesFees} => ${newDutiesFees}`,
            { ...logFields, key: 'amendmentWillUpdate' },
          );
          status = CalculateAmendmentResultStatus.WILL_UPDATE_UPCOMING;
        } else if (invoiceStatus === InvoiceStatus.UNPAID) {
          // void existing invoice and issue new invoice
          amendmentMessage =
            'We will void the above invoice and issue a replacement.';
          log.info(
            `Will void invoiceId ${invoiceId} in status ${invoiceStatus}`,
            { ...logFields, key: 'amendmentWillVoid' },
          );
          status = CalculateAmendmentResultStatus.WILL_VOID_AND_REPLACE;
        } else if (invoiceStatus === InvoiceStatus.PAID) {
          // already paid, need to issue credit memo for difference
          if (change > 0) {
            amendmentMessage =
              'We will issue a new invoice for the difference.';
            status = CalculateAmendmentResultStatus.WILL_ISSUE_INVOICE_FOR_DIFF;
          } else {
            amendmentMessage =
              'We will issue a new credit memo for the difference.';
            status = CalculateAmendmentResultStatus.WILL_ISSUE_CREDIT_FOR_DIFF;
          }
          log.info(
            `Amending paid invoice ${invoiceId}. Will issue a new credit or invoice for the difference.`,
            { ...logFields, key: 'amendmentWillIssueNew' },
          );
        } else {
          log.fatal(
            `Unknown invoice status ${existingDutyInvoice.status} when processing duty amendment for consumption entry ${newUsConsumptionEntryId} invoice ${invoiceId}`,
            { ...logFields, key: 'amendmentUnknownStatus' },
          );
          return {
            status: CalculateAmendmentResultStatus.UNKNOWN_INVOICE_STATUS,
          };
        }

        return {
          title: `Duty will be amended on ${existingDutyInvoice.status} invoice #${existingDutyInvoice.number}`,
          message: amendmentMessage,
          newDutiesFees,
          oldDutiesFees,
          status,
        };
      } else {
        // duty line not yet created, no amendment needed
        log.info(
          `duty line not yet created. Not amending any invoices for consumption entry ${oldUsConsumptionEntry.id}`,
          {
            params: {
              oldDutiesFees,
              newDutiesFees,
            },
            shipperId: oldUsConsumptionEntry.group.shipper?.id,
            workOrderGroupId: oldUsConsumptionEntry.group.id,
            workOrderId: newUsConsumptionEntryId,
            operatorId,
            key: 'amendmentLineNotCreated',
          },
        );
        return { status: CalculateAmendmentResultStatus.LINE_NOT_CREATED };
      }
    } else {
      log.info(
        `${
          brokerPaysDuty ? 'Duty unchanged' : `Broker doesn't pay duty`
        }. Not amending any invoices for consumption entry ${
          oldUsConsumptionEntry.id
        }`,
        {
          params: {
            oldDutiesFees,
            newDutiesFees,
          },
          shipperId: oldUsConsumptionEntry.group.shipper?.id,
          workOrderGroupId: oldUsConsumptionEntry.group.id,
          workOrderId: newUsConsumptionEntryId,
          operatorId,
          key: brokerPaysDuty
            ? 'amendmentNoDutyChange'
            : 'amendmentBrokerDoesntPayDuty',
        },
      );
      return {
        status: brokerPaysDuty
          ? CalculateAmendmentResultStatus.NO_DUTY_CHANGE
          : CalculateAmendmentResultStatus.BROKER_DOES_NOT_PAY_DUTY,
      };
    }
  } else {
    log.info(
      `No invoices need amending to change duties from ${oldDutiesFees} to ${newDutiesFees} for consumption entry ${oldUsConsumptionEntry.id}`,
      {
        params: {
          oldDutiesFees,
          newDutiesFees,
        },
        shipperId: oldUsConsumptionEntry.group.shipper?.id,
        workOrderGroupId: oldUsConsumptionEntry.group.id,
        workOrderId: newUsConsumptionEntryId,
        operatorId,
        key: 'amendmentNotNecessary',
      },
    );
    return { status: CalculateAmendmentResultStatus.NOT_NECESSARY };
  }
};
