import React from 'react';
import { DatePicker } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Moment } from 'moment';
import { CssSize, NamePath } from '@xbcb/ui-types';
import { show } from '@xbcb/ui-utils';
import { StyledFormItem } from './styles';

const { MonthPicker } = DatePicker;

export interface PickDateProps {
  disabled?: boolean;
  disabledDate?: (date: any) => boolean;
  fullNamePath: NamePath;
  localNamePath: NamePath;
  form: FormInstance;
  hideLabel?: boolean;
  label?: string;
  monthOnly?: boolean;
  onChange?: Function;
  readOnly?: boolean;
  required?: boolean;
  $itemSize?: CssSize;
  removeSpaceBottom?: boolean;
  format?: string;
  dataCy?: string;
  isTimeSensitive?: boolean;
}

const PickDate: React.FC<PickDateProps> = ({
  dataCy,
  disabled,
  disabledDate,
  fullNamePath,
  localNamePath,
  form,
  hideLabel,
  label,
  monthOnly,
  onChange,
  readOnly,
  required,
  $itemSize = CssSize.TINY_SHORT,
  removeSpaceBottom,
  format,
  isTimeSensitive = false,
}) => {
  const Component = monthOnly ? MonthPicker : DatePicker;

  if (!show({ readOnly, form, field: fullNamePath })) return null;

  const handleChange = (date: Moment | null) => {
    if (!isTimeSensitive) {
      // Converts date to UTC start of day without changing date value
      date?.utcOffset(0, true).startOf('day');
    }
    if (onChange) onChange(date);
  };

  return (
    <StyledFormItem
      $itemSize={$itemSize}
      $removeSpaceBottom={removeSpaceBottom}
      $inline
      label={hideLabel ? '' : label}
      name={localNamePath}
      rules={[{ required, message: ' ' }]}
      $readOnly={readOnly}
    >
      <Component
        disabled={disabled}
        disabledDate={disabledDate}
        onChange={handleChange}
        format={format}
        data-cy={dataCy}
      />
    </StyledFormItem>
  );
};

export default PickDate;
