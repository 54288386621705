import { UserRecordType } from '@xbcb/shared-types';

export const mapToAllUserTypes = <T>(data: T) =>
  Object.keys(UserRecordType).reduce(
    (acc: { [key in UserRecordType]?: T }, userKey: any) => {
      acc[userKey as UserRecordType] = data;
      return acc;
    },
    {},
  );
