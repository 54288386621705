import { createWorkOrderRecord } from './utils';
import { EsDataType } from '../../index';

export const euIorActivationColumns = {
  columns: {
    iorName: {
      default: true,
      name: 'IOR Name',
      type: EsDataType.TEXT,
    },
    iorId: { name: 'The IOR ID', type: EsDataType.KEYWORD, hidden: true },
    industry: { name: 'Industry', type: EsDataType.KEYWORD },
    activationStatus: {
      name: 'Activation Status',
      type: EsDataType.KEYWORD,
      default: true,
    },
    activationTime: {
      name: 'IOR Activation Time',
      type: EsDataType.TIME,
    },
  },
};

export const gbIorActivation = createWorkOrderRecord(euIorActivationColumns);
export const deIorActivation = createWorkOrderRecord(euIorActivationColumns);
export const nlIorActivation = createWorkOrderRecord(euIorActivationColumns);
export const frIorActivation = createWorkOrderRecord(euIorActivationColumns);
