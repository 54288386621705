import { camelCase, pascalCase } from 'change-case';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import { RecordType } from '@xbcb/shared-types';

export const spreadsheetPreviewQuery = (
  recordType: RecordType,
): DocumentNode => {
  const camelCaseRecordType = camelCase(recordType);
  return gql`
    query ${camelCaseRecordType}SpreadsheetPreview($input: ${pascalCase(
    recordType,
  )}SpreadsheetPreviewInput!) {
      ${camelCaseRecordType}SpreadsheetPreview(input: $input) {
        numberOfCreates
        numberOfMerges
        numberOfOverwrites
        numberOfSkips
        numberOfUploadedRecords
        spreadsheetRows
        spreadsheetRowsWithDuplicates
        warnings
      }
    }
  `;
};
