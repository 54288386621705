import { ActionFunctionAny } from 'redux-actions';
import { Dispatch } from 'redux';
import { gaEvent } from '../gaEvent';
import { reportError } from '../reportError';
import axios from 'axios';
import localForage from 'localforage';
import { get } from 'lodash';
import { tradeDataCodes } from '@xbcb/trade-data-queries';

let codes: any = null;

export const loadCodes = async (): Promise<void> => {
  const storedCodes = await localForage.getItem<string>('codes');
  if (storedCodes) {
    try {
      codes = JSON.parse(storedCodes);
    } catch (e) {
      reportError(e);
    }
  }
};

let releaseCodeNames: any;
export const getReleaseCodeNames = () => {
  if (!releaseCodeNames) {
    const { disposition = {}, billDisposition } = get(
      codes,
      'CBP.CATAIR.release',
      {},
    );
    releaseCodeNames = {
      ...disposition,
      ...billDisposition,
    };
  }

  return releaseCodeNames;
};

export const getCountryCodes = () => get(codes, 'ISO.country.alpha2', {});
export const getCATAIR = () => get(codes, 'CBP.CATAIR', {});

export const cbpCurrencyCodes = [
  'USD',
  'AUD',
  'BRL',
  'CAD',
  'CHF',
  'CNY',
  'DKK',
  'GBP',
  'EUR',
  'HKD',
  'ILS',
  'INR',
  'IRR',
  'JPY',
  'KRW',
  'LKR',
  'MXN',
  'MYR',
  'NOK',
  'NZD',
  'PHP',
  'SEK',
  'SGD',
  'THB',
  'TWD',
  'VEF',
  'ZAR',
];
// let currencyCodes;
export const getCurrencyCodes = () => {
  return get(codes, ['ISO', 'currency', 'alpha']);
  // if (currencyCodes && codes) return currencyCodes;
  // if (!codes) return {};
  // currencyCodes = {};
  // cbpCurrencyCodes.forEach((currencyCode) => {
  //  currencyCodes[currencyCode] = get(codes, [
  //    'ISO',
  //    'currency',
  //    'alpha',
  //    currencyCode,
  //  ]);
  // });
  // return currencyCodes;
};

export const getCodes = () => codes;

interface RefreshCodesProps {
  type: 'app' | 'public';
  client: any; // TODO
  currentVersion?: string;
  dispatch: Dispatch;
  CODE_VERSION_CHANGE: ActionFunctionAny<any>;
}

// NOTE: Any changes made to refreshCodes should be made to refreshCodesNoRedux
// until the TODO below is addressed (consolidating the logic together)
export const refreshCodes = async ({
  type,
  client,
  currentVersion,
  dispatch,
  CODE_VERSION_CHANGE,
}: RefreshCodesProps): Promise<boolean> => {
  // refresh trade data codes
  try {
    const result = await client.query({ query: tradeDataCodes({ type }) });
    const { versionId: newVersion, link } = get(result, [
      'data',
      `${type}TradeDataCodes`,
    ]);
    if (!codes || newVersion !== currentVersion) {
      try {
        const response = await axios({
          method: 'GET',
          url: link,
        });
        codes = response.data;
        await localForage.setItem('codes', JSON.stringify(codes));
        gaEvent('Public Codes', 'Updated');
        await dispatch(CODE_VERSION_CHANGE(newVersion));
        return true;
      } catch (e) {
        console.log(JSON.stringify(e), e.response, e);
        reportError(e);
        return false;
      }
    }
    return true;
  } catch (e) {
    if (e.name !== 'QuotaExceededError') {
      reportError(e);
    }
    return false;
  }
};

// Currently being used by Import Sign since it does not use redux. It is very
// similar to `refreshCodes` except instead of returning true / false
// (signifying whether the action was dispatched or not) it returns the
// newVersion or undefined if there's not a new version. Then Import Sign can
// proceed to handle the new version
interface RefreshCodesNoReduxProps {
  type: 'app' | 'public';
  client: any;
  currentVersion?: string;
}

// TODO consolidate with refreshCodes
export const refreshCodesNoRedux = async ({
  type,
  client,
  currentVersion,
}: RefreshCodesNoReduxProps): Promise<string | undefined> => {
  // refresh trade data codes
  try {
    const result = await client.query({ query: tradeDataCodes({ type }) });
    const { versionId: newVersion, link } = get(result, [
      'data',
      `${type}TradeDataCodes`,
    ]);
    if (!codes || newVersion !== currentVersion) {
      try {
        const response = await axios({
          method: 'GET',
          url: link,
        });
        codes = response.data;
        await localForage.setItem('codes', JSON.stringify(codes));
        gaEvent('Public Codes', 'Updated');
        return newVersion;
      } catch (e) {
        console.log(JSON.stringify(e), e.response, e);
        reportError(e);
        return;
      }
    }
    return;
  } catch (e) {
    if (e.name !== 'QuotaExceededError') {
      reportError(e);
    }
    return;
  }
};
