import { SortOrder } from '@xbcb/shared-types';
import { shipperId, shipperName } from './party';
import { createPartyTable } from './createPartyTable';
import { iorNumber } from '../iorNumber';
import { EsDataType } from '../../enums';

export const usConsignee = createPartyTable({
  columns: {
    shipperName,
    shipperId,
    iorNumber,
    iorName: {
      name: 'IOR name',
      type: EsDataType.TEXT,
    },
    iorId: { name: 'The IOR ID', type: EsDataType.KEYWORD, hidden: true },
  },
  tableOptions: {
    sortOrder: SortOrder.DESC,
  },
});
