/**
 * Represents the status of the exchange message request.
 */
export enum ExchangeMessageStatus {
  // The input document is yet to be uploaded, the url has been issued to the client.
  PENDING_INPUT_UPLOAD = 'PENDING_INPUT_UPLOAD',

  // The request is under processing.
  PROCESSING = 'PROCESSING',

  // The output document is yet to be uploaded, the url has been issued to the client.
  PENDING_OUTPUT_UPLOAD = 'PENDING_OUTPUT_UPLOAD',

  // DEPRECATED : DO NOT USE. KEEPING UNTILL ALL CLIENTS CHANGE TO MOVE TO TRANSFORMED STATUS.
  COMPLETED = 'COMPLETED',

  // The message transformation is complete and will queued for transmission to the desired partner soon.
  TRANSFORMED = 'TRANSFORMED',

  // The message has been transmitted to the desired partner.
  TRANSMITTED = 'TRANSMITTED',

  // The message transformation failed.
  TRANSFORM_FAILED = 'TRANSFORM_FAILED',

  // The message transmission failed.
  TRANSMISSION_FAILED = 'TRANSMISSION_FAILED',
}
