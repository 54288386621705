import React from 'react';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { Tag } from '@xbcb/api-gateway-client';
import { SingleFormCard } from '@xbcb/display-components';
import { UsBusinessStructure } from '@xbcb/party-types';
import { TagKey } from '@xbcb/shared-types';
import { NamePath, uiStageToBackendStage } from '@xbcb/ui-types';
import {
  shouldUpdate,
  businessStructureToDocumentTagsMap,
  businessStructureToFormattedDocumentTagsMap,
  businessStructureAndUsResidencyToDocumentTagsMap,
  businessStructureAndUsResidencyToFormattedDocumentTagsMap,
  getEnv,
} from '@xbcb/ui-utils';
import { DocsCoreCommonProps } from '../DocsCore';
import {
  cbpReg2ChangesFeature,
  CBP_REG_2_CHANGES_FEATURE,
} from '@xbcb/feature-flags';

const { stage } = getEnv();
const isCbpReg2ChangesFeatureEnabled = CBP_REG_2_CHANGES_FEATURE.isEnabled(
  cbpReg2ChangesFeature,
  {
    stage: uiStageToBackendStage[stage],
  },
);

// The Docs component should take the DocsCoreCommonProps as well as the field
// that is relevant for the Sign functionality (`additionalTags`).
// Additionally, since ImportSignDocs must have the `operatorId` provided
// TemplateBasedSignUploadsCardDocsProps should be able to take an `operatorId`.
type TemplateBasedSignUploadsCardDocsProps = DocsCoreCommonProps & {
  additionalTags: Tag[];
  operatorId: string;
};

export type TemplateBasedSignUploadsCardDocs =
  React.ElementType<TemplateBasedSignUploadsCardDocsProps>;

type TemplateBasedSignUploadsCardProps = {
  businessStructureNamePath: NamePath;
  form: FormInstance;
  operatorId: string;
  additionalTags: Tag[];
  Docs: TemplateBasedSignUploadsCardDocs;
  physicalAddressCountryCodePath: NamePath;
};

const TemplateBasedSignUploadsCard: React.FC<
  TemplateBasedSignUploadsCardProps
> = ({
  businessStructureNamePath,
  form,
  operatorId,
  additionalTags,
  Docs,
  physicalAddressCountryCodePath,
}) => {
  const physicalAddressCountryCode = Form.useWatch(
    physicalAddressCountryCodePath,
    form,
  );
  const isUsResident = React.useMemo(
    () => physicalAddressCountryCode === 'US',
    [physicalAddressCountryCode],
  );
  const getDocsList = (businessStructure: UsBusinessStructure) => {
    const usResidencyToFormattedDocumentTagsMap =
      businessStructureAndUsResidencyToFormattedDocumentTagsMap[
        businessStructure
      ];
    const { suggested, required } =
      (isCbpReg2ChangesFeatureEnabled
        ? isUsResident
          ? usResidencyToFormattedDocumentTagsMap?.usResident
          : usResidencyToFormattedDocumentTagsMap?.nonUsResident
        : businessStructureToFormattedDocumentTagsMap[businessStructure]) || {};
    return (
      <ul>
        {required?.map((item, index) => (
          <li key={`${item}${index}`}>{item} (required)</li>
        ))}
        {suggested?.map((item, index) => (
          <li key={`${item}${index}`}>{item}</li>
        ))}
      </ul>
    );
  };
  return (
    <SingleFormCard
      title="Uploads"
      extraContent={
        <Form.Item
          shouldUpdate={shouldUpdate([
            businessStructureNamePath,
            physicalAddressCountryCodePath,
          ])}
          noStyle
        >
          {() => {
            const businessStructure: UsBusinessStructure = form.getFieldValue(
              businessStructureNamePath,
            );

            const usResidencyToDocumentTagsMap =
              businessStructureAndUsResidencyToDocumentTagsMap[
                businessStructure
              ];
            const { suggested, required } =
              (isCbpReg2ChangesFeatureEnabled
                ? isUsResident
                  ? usResidencyToDocumentTagsMap?.usResident
                  : usResidencyToDocumentTagsMap?.nonUsResident
                : businessStructureToDocumentTagsMap[businessStructure]) || {};
            return (
              <div>
                {businessStructure ? (
                  suggested?.length || required?.length ? (
                    <>
                      <p>
                        Please upload the following documents to help validate
                        the name and address of the {businessStructure} and the
                        authority of the signer:
                      </p>
                      {getDocsList(businessStructure)}
                    </>
                  ) : (
                    <p>
                      There are no required or suggested items to help validate
                      the name and address of the {businessStructure} and the
                      authority of the signer. You may skip uploading items.
                    </p>
                  )
                ) : (
                  <p>
                    Select a business structure to view the items that are
                    required and/or suggested to be uploaded.
                  </p>
                )}
              </div>
            );
          }}
        </Form.Item>
      }
    >
      <Docs
        operatorId={operatorId}
        additionalTags={additionalTags}
        // We want to search by the usIorActivationId and show all documents
        // that are tagged with it or the template DSR ID
        searchQueryTags={additionalTags.filter(
          ({ key }) =>
            key === TagKey.US_IOR_ACTIVATION_ID ||
            key === TagKey.TEMPLATE_DOCUMENT_SIGN_REQUEST_ID,
        )}
      />
    </SingleFormCard>
  );
};

export default TemplateBasedSignUploadsCard;
