import { Columns, TableOptions } from '../../tables';
import { createTableConfig } from '../createTableConfig';
import { EsDataType } from '../../enums';

export const createDocumentSignRequestRecord = ({
  tableOptions = {},
  columns = {},
}: {
  tableOptions?: TableOptions;
  columns?: Columns;
}) =>
  createTableConfig({
    columns: {
      documentTags: {
        name: 'Document tag.',
        type: EsDataType.KEYWORD,
        default: true,
      },
      method: {
        name: 'Document signing method.',
        type: EsDataType.KEYWORD,
      },
      status: {
        name: 'Document sign request status.',
        type: EsDataType.KEYWORD,
        default: true,
      },
      signerName: {
        name: 'Signing officer name.',
        type: EsDataType.TEXT,
        default: true,
      },
      signedTime: {
        name: 'The time the document sign request was signed by the officer.',
        type: EsDataType.TIME,
        default: true,
      },
      ...columns,
    },
    tableOptions,
  });
