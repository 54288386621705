import {
  GbIor,
  DeIor,
  NlIor,
  FrIor,
  ImporterEntity,
} from '@xbcb/api-gateway-client';
import { ActivationStatus } from '@xbcb/party-types';

export type IorEntity = GbIor | DeIor | NlIor | FrIor | ImporterEntity;

export const isImporterEntityStatusNonMutable = ({
  iorEntity,
  customsBrokerId,
}: {
  iorEntity?: IorEntity;
  customsBrokerId: string;
}): boolean =>
  Boolean(
    iorEntity?.activations?.some(
      ({ status, customsBroker }) =>
        (status === ActivationStatus.ACTIVATED ||
          status === ActivationStatus.REJECTED) &&
        customsBroker?.id === customsBrokerId,
    ),
  );
