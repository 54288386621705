import React from 'react';
import { Modal, Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { timeout, cleanString } from '@xbcb/js-utils';
import { PoaType, FormattedPoaType } from '@xbcb/shared-types';
import { CssSize, NamePath } from '@xbcb/ui-types';
import FormItem from '../FormItem';

const nonOfficerTitles = [
  'manager',
  'specialist',
  'customer',
  'service',
  'supervisor',
  'accountant',
];

export const officerTitleWarning = (title?: string, poaType?: string) => {
  if (!title || !poaType) return;
  const lowerCasedTitle = title.toLowerCase();
  let warn = false;
  nonOfficerTitles.forEach((nonOfficerTitle) => {
    if (lowerCasedTitle.includes(nonOfficerTitle)) warn = true;
  });
  if (warn) {
    return (
      <div>
        <p>
          The Power of Attorney is a legal document authorizing the broker to
          conduct business on your behalf. Broker cannot make a declaration to
          customs authorities without a signed POA.
        </p>
        <br />
        <p>
          <strong>The POA must be signed by an officer of the company.</strong>{' '}
          Who has the authority to sign the POA may depend on the type of
          company (corporation, LLC, etc.), so confirm with a company officer if
          you are unsure. If your title is not President, Vice President,
          Secretary, Treasurer, or the like, please upload documentation to
          substantiate your authority to sign POA on behalf of your
          organization.
        </p>
      </div>
    );
  } else {
    return null;
  }
};

type OfficeTitleProps = {
  form: FormInstance;
  localNamePath: NamePath;
  fullNamePath: NamePath;
  disabled?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  documentType: PoaType;
};

const OfficerTitle: React.FC<OfficeTitleProps> = ({
  localNamePath,
  fullNamePath,
  required,
  disabled,
  readOnly,
  documentType,
  form,
}) => {
  const onBlur = async () => {
    await timeout(1000);
    const inputtedTitle = form.getFieldValue(fullNamePath);
    const cleaned = cleanString(inputtedTitle);
    if (inputtedTitle !== cleaned) {
      form.setFields([{ name: fullNamePath, value: cleaned }]);
    }
    const warningText = officerTitleWarning(
      cleaned,
      FormattedPoaType[documentType],
    );
    if (warningText) {
      Modal.warning({
        title: 'Possible invalid title',
        content: warningText,
      });
    }
  };

  return (
    <FormItem
      label="Officer Company Title"
      name={localNamePath}
      rules={[{ required, message: '' }]}
      $readOnly={readOnly}
      debounce
      $itemSize={CssSize.SHORT}
    >
      <Input disabled={disabled} onBlur={onBlur} />
    </FormItem>
  );
};

export default OfficerTitle;
