import { conformToMask } from 'text-mask-core';

/**
 * text mask class for phone numbers
 */
export class PhoneMask {
  /**
   * initializes previousValue to empty string
   * @return {undefined}
   */
  constructor() {
    this.previousValue = '';
  }

  /**
   * formats raw event value as phone number
   * @param {Event} e
   * @param {string} countryCode
   * @return {string}
   */
  handlePhoneChange(e, countryCode) {
    if (!e) {
      return e || undefined;
    }
    if (!e.target) {
      return e.target || undefined;
    }

    let mask;
    if (countryCode === '1') {
      mask = [
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    } else {
      // Setting phone number max to be 15 digits based on https://tiny.amazon.com/mt92njn3/stacques7235.
      mask = [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    }
    const result = conformToMask(e.target.value, mask, {
      guide: false,
      previousConformedValue: this.previousValue,
    }).conformedValue;
    this.previousValue = result;
    return result || undefined;
  }
}
