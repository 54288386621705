import { EsDataType, recordFields } from '../../index';
import { createTableConfig } from '../createTableConfig';

export const bulkUpload = createTableConfig({
  columns: {
    status: {
      default: true,
      name: 'Status',
      type: EsDataType.KEYWORD,
    },
    totalRows: {
      default: true,
      name: 'Total Rows',
      type: EsDataType.INTEGER,
    },
    totalFailures: {
      default: true,
      name: 'Total Failures',
      type: EsDataType.INTEGER,
    },
    operatorId: {
      default: false,
      hidden: true,
      name: 'Operator ID',
      type: EsDataType.TEXT,
    },
    documentId: {
      default: false,
      hidden: true,
      name: 'Document ID',
      type: EsDataType.TEXT,
    },
    brokerId: {
      default: false,
      hidden: true,
      name: 'Broker ID',
      type: EsDataType.KEYWORD,
    },
    ...recordFields({
      createdTime: { react: true, default: true, name: 'Time' },
    }),
  },
  tableOptions: {
    sortField: 'createdTime',
  },
});
