import {
  operatorId,
  recordFields,
  defaultTableSlice,
  Columns,
  RecordColumns,
  TableOptions,
} from '../index';

export const createTableConfig = <TColumns extends Columns>({
  tableOptions = {},
  columns: customColumns = {} as TColumns,
  skipRecordFields,
  skipOperatorId,
}: {
  tableOptions?: TableOptions;
  columns?: TColumns;
  skipRecordFields?: boolean;
  skipOperatorId?: boolean;
}): RecordColumns => {
  let columns = {
    operatorId: skipOperatorId ? undefined : operatorId,
    ...customColumns,
  };
  if (!skipRecordFields) {
    columns = {
      ...recordFields(),
      ...columns,
    };
  }
  return {
    columns,
    ...defaultTableSlice,
    ...tableOptions,
  };
};
