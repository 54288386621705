import { css } from 'styled-components';
import { CssSize } from '@xbcb/ui-types';

export const isfCard = css`
  height: ${CssSize.SHORT};
  margin-bottom: var(--space-4);
  .ant-card-body {
    padding: 0;
    h3 {
      border-bottom: 1px solid #b7bcbe;
      padding: 8px 16px;
      font-size: 20px;
      color: var(--red-violet-1);
      width: 100%;
    }
  }
`;
