import { UserTeamRecordType } from '@xbcb/shared-types';
import { EsDataType, recordFields, Columns, TableOptions } from '../../index';
import { createTableConfig } from '../createTableConfig';

export const sharedUserTeamColumns = {
  name: { name: 'Team Name', type: EsDataType.TEXT, default: true },
  ...recordFields(),
};

export const createUserTeamTableConfig = <TColumns extends Columns>({
  tableOptions = {},
  columns,
}: {
  tableOptions?: TableOptions;
  columns?: TColumns;
}) =>
  createTableConfig({
    columns: {
      ...sharedUserTeamColumns,
      ...columns,
    },
    tableOptions,
  });

export const userTeam = createUserTeamTableConfig({
  tableOptions: { recordTypes: Object.values(UserTeamRecordType) },
});
