const internalUserSupportedDomains = ['amazon', 'ring', 'zappos'];

export const isInternalUser = (email: string): boolean => {
  const domain = email.split('@')[1].split('.')[0];
  return internalUserSupportedDomains.includes(domain);
};

export const getLoginFromEmail = (email: string): string => {
  return email.split('@')[0].split('+')[0].split('-')[0].split('_')[0];
};
