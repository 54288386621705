import { WorkOrder } from '@xbcb/api-gateway-client';
import { isInternalForwarder } from '@xbcb/party-utils';

/**
 * Check if the work order is for internal forwarder
 * @param {WorkOrder} workOrder to check
 * @return {boolean} true if the work order is for internal forwarder
 */
export const isInternalWorkOrder = (workOrder: WorkOrder): boolean =>
  Boolean(
    workOrder?.group?.forwarders?.some(
      ({ forwarder }) => forwarder && isInternalForwarder(forwarder),
    ),
  );
