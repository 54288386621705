import { recordActionFields, recordFields } from '@xbcb/shared-queries';

export const htsFields = `
  fragment htsFields on Hts {
    ...recordFields
    cbpStartDate
    cbpEndDate
    number
    text
    description
    additionalHTS
    additionalHtsNumber
    quota
    ad
    cv
    startDate
    endDate
    licenseCode
    licenseName
    textile
    uom
    rate {
      adValorem
      specific
      other
    }
    rateCode
    rateString
    gspExcluded
    ...usHtsFields
    restriction {
      countryCode
      quantityCode
      quantityLowBounds
      quantityHighBounds
      valueCode
      valueLowBounds
      valueHighBounds
    }
    spi {
      code
      name
      country
      group
    }
    fees {
      required
      class
      type
      name
      rate {
        adValorem
        specific
        other
      }
      rateCode
      rateString
    }
    pga {
      ...pgaRequirementFields
    }
    additional {
      ...additionalHtsFields
    }
  }`;

export const additionalHtsFields = `
fragment additionalHtsFields on AdditionalHts {
  type
  number
  overQuotaNumber
  additionalDutiesNumber
  include
  exclude
  additionalDutiesHts {
    number 
    text 
    description 
  }
  overQuotaHts {
    number 
    text 
    description
  }
  hts {
    number 
    text 
    description
  }
}`;

export const pgaRequirementFields = `
fragment pgaRequirementFields on PgaRequirement {
  flag
  agencyCode
  programCodes
  programs {
    code
    name
    processingCodes {
      code
      name
    }
  }
  description
  required
  disclaimerCodes
  likelyDisclaimerCode
  likelyProgramCode
  likelyProcessingCode
  fields {
    ...supplementalFieldFields
  }
}
`;

// double nesting SupplementalField.fields because I cant spread a fragment into itself
export const supplementalFieldFields = `
  fragment supplementalFieldFields on SupplementalField {
    required
    name
    code
    type
    list
    description
    fields {
      required
      name
      code
      type
      list
      description
      fields {
        required
        name
        code
        type
        list
        description
        fields {
          required
          name
          code
          type
          list
          description
        }
      }
    }
  }`;

export const usHtsFragment = `fragment usHtsFields on UsHts {
    trumpList
  }`;

export const htsFragments = `
  ${additionalHtsFields}
  ${supplementalFieldFields}
  ${pgaRequirementFields}
  ${recordFields}
  ${htsFields}
  ${recordActionFields}
  ${usHtsFragment}
`;
