import { capitalCase } from 'change-case';

export * from './milestone';
export * from './task';
export * from './confirmation';
export * from './dis';
export * from './inquiry';
export * from './productLevelReasonCode';
export * from './badge';
export * from './blockedReasonCode';
export * from './warning';
export * from './warningConstants';
export * from './scheduleTypes';
export * from './reference';

export enum EuCustomsEntryWorkOrderType {
  GbCustomsEntry = 'GbCustomsEntry',
  DeCustomsEntry = 'DeCustomsEntry',
  NlCustomsEntry = 'NlCustomsEntry',
  FrCustomsEntry = 'FrCustomsEntry',
}

export enum EuIorActivationWorkOrderType {
  GbIorActivation = 'GbIorActivation',
  DeIorActivation = 'DeIorActivation',
  NlIorActivation = 'NlIorActivation',
  FrIorActivation = 'FrIorActivation',
}

export enum WorkOrderType {
  UsIsf = 'UsIsf',
  UsConsumptionEntry = 'UsConsumptionEntry',
  UsPostSummaryCorrection = 'UsPostSummaryCorrection',
  UsInBond = 'UsInBond',
  UsDrawbackClaim = 'UsDrawbackClaim',
  UsIorContinuousBondRequest = 'UsIorContinuousBondRequest',
  UsIorActivation = 'UsIorActivation',
  DeliveryOrder = 'DeliveryOrder',
  GbCustomsEntry = 'GbCustomsEntry',
  DeCustomsEntry = 'DeCustomsEntry',
  NlCustomsEntry = 'NlCustomsEntry',
  FrCustomsEntry = 'FrCustomsEntry',
  UsType86Entry = 'UsType86Entry',
  GbIorActivation = 'GbIorActivation',
  DeIorActivation = 'DeIorActivation',
  NlIorActivation = 'NlIorActivation',
  FrIorActivation = 'FrIorActivation',
  CustomsDeclaration = 'CustomsDeclaration',
  ImporterEntityActivation = 'ImporterEntityActivation',
}

const noCountryWorkOrders = [
  WorkOrderType.DeliveryOrder,
  WorkOrderType.CustomsDeclaration,
  WorkOrderType.ImporterEntityActivation,
];
// We expected the countryAbbreviation to be length 2
const expectedCountryAbbreviationLength = 2;

export const getCountryAbbreviationFromWorkOrderType = (
  workOrderType: WorkOrderType,
) => {
  if (noCountryWorkOrders.includes(workOrderType)) {
    return '';
  }
  const countryAbbreviation = workOrderType.slice(
    0,
    expectedCountryAbbreviationLength,
  );
  return countryAbbreviation.toUpperCase();
};

// NOTE: This lib includes the country prefix. If displaying to the user
// consider using `formatRecordName` from `@xbcb/js-utils` instead
export const formatWorkOrderType = (workOrderType: WorkOrderType) => {
  // Transform into a space separated string with each word capitalized.
  let formattedWorkOrderType = capitalCase(workOrderType);
  // Now properly capitalize the country abbreviation if it's the proper length
  const countryAbbreviation =
    getCountryAbbreviationFromWorkOrderType(workOrderType);
  const remainingFormattedWorkOrderType = formattedWorkOrderType.slice(
    noCountryWorkOrders.includes(workOrderType)
      ? 0
      : expectedCountryAbbreviationLength,
  );
  // If it doesn't start with " " then it wasn't the proper abbreviation length
  // thus, leave as is. Otherwise, capitalize the country abbreviation
  if (remainingFormattedWorkOrderType.startsWith(' ')) {
    formattedWorkOrderType =
      countryAbbreviation + remainingFormattedWorkOrderType;
  }
  // Now support unique cases (remember the searchValue will already be capitalCased)
  const uniqueCases = [{ searchValue: 'In Bond', replaceValue: 'In-Bond' }];
  uniqueCases.forEach(({ searchValue, replaceValue }) => {
    formattedWorkOrderType = formattedWorkOrderType.replace(
      searchValue,
      replaceValue,
    );
  });
  return formattedWorkOrderType;
};

export const workOrderTaskTypeDescription =
  'The type of work order task to be completed.';

export enum AmsTaskType {
  AMS_QUERY = 'AMS_QUERY',
  AMS_EXCEPTION = 'AMS_EXCEPTION',
}
// N.B. FOLLOW THIS CONVENTION WHEN NAMING TASK TYPES: ${WORK_ORDER_TYPE}_${TASK_NOUN}
// e.g. US_POST_SUMMARY_CORRECTION_FILING
// avoid something like FILE_POST_SUMMARY_CORRECTION, which is not namespaced under the work order type.

export enum UsConsumptionEntryTaskType {
  US_CONSUMPTION_ENTRY_BUILD_INVOICE = 'US_CONSUMPTION_ENTRY_BUILD_INVOICE',
  // Note: US_CONSUMPTION_ENTRY_BUILD_TRANSPORTATION_AND_INVOICE is currently specific to the entry external long SLA
  // SM. If we use this for other WOs in the future we will need to think of the side effects as right now we
  // specifically check for this task type when determining whether to auto close the task when the WO is marked ready
  // inside of: https://code.amazon.com/packages/XbcbWorkOrderWorkflow/blobs/a6cf4763e5d51f10caec7b59f001669200f46886/--/packages/lambda/src/handleWorkOrderMilestoneEvents/executor/hotWorkOrderRequestedExecutor.ts
  US_CONSUMPTION_ENTRY_BUILD_TRANSPORTATION_AND_INVOICE = 'US_CONSUMPTION_ENTRY_BUILD_TRANSPORTATION_AND_INVOICE',
  US_CONSUMPTION_ENTRY_CARGO_RELEASE_EXCEPTION = 'US_CONSUMPTION_ENTRY_CARGO_RELEASE_EXCEPTION',
  US_CONSUMPTION_ENTRY_CBP_EXAM = 'US_CONSUMPTION_ENTRY_CBP_EXAM',
  US_CONSUMPTION_ENTRY_CBP_HOLD = 'US_CONSUMPTION_ENTRY_CBP_HOLD',
  US_CONSUMPTION_ENTRY_COMPLETE_INVOICE = 'US_CONSUMPTION_ENTRY_COMPLETE_INVOICE',
  US_CONSUMPTION_ENTRY_DOCUMENT_TAGGING = 'US_CONSUMPTION_ENTRY_DOCUMENT_TAGGING',
  US_CONSUMPTION_ENTRY_EXCEPTION = 'US_CONSUMPTION_ENTRY_EXCEPTION',
  US_CONSUMPTION_ENTRY_FILING = 'US_CONSUMPTION_ENTRY_FILING',
  US_CONSUMPTION_ENTRY_SHIPMENT_INFO = 'US_CONSUMPTION_ENTRY_SHIPMENT_INFO',
  US_CONSUMPTION_ENTRY_PGA_REJECTION = 'US_CONSUMPTION_ENTRY_PGA_REJECTION',
  US_CONSUMPTION_ENTRY_PGA_HOLD = 'US_CONSUMPTION_ENTRY_PGA_HOLD',
  US_CONSUMPTION_ENTRY_POST_SUMMARY_CORRECTION = 'US_CONSUMPTION_ENTRY_POST_SUMMARY_CORRECTION',
  US_CONSUMPTION_ENTRY_PROTEST = 'US_CONSUMPTION_ENTRY_PROTEST',
  US_CONSUMPTION_ENTRY_REVISION = 'US_CONSUMPTION_ENTRY_REVISION',
  US_CONSUMPTION_ENTRY_COMPLETION_SUBMISSION = 'US_CONSUMPTION_ENTRY_COMPLETION_SUBMISSION',
  US_CONSUMPTION_ENTRY_STATEMENT_PROCESSING_EXCEPTION = 'US_CONSUMPTION_ENTRY_STATEMENT_PROCESSING_EXCEPTION',
  US_CONSUMPTION_ENTRY_PGA_EXCEPTION = 'US_CONSUMPTION_ENTRY_PGA_EXCEPTION',
  US_CONSUMPTION_ENTRY_PAYMENT_PROCESSING_EXCEPTION = 'US_CONSUMPTION_ENTRY_PAYMENT_PROCESSING_EXCEPTION',
  US_CONSUMPTION_ENTRY_STATEMENT_DATE_CALCULATION_EXCEPTION = 'US_CONSUMPTION_ENTRY_STATEMENT_DATE_CALCULATION_EXCEPTION',
  US_CONSUMPTION_ENTRY_CENSUS_WARNING = 'US_CONSUMPTION_ENTRY_CENSUS_WARNING',
  US_CONSUMPTION_ENTRY_CBP_CANCELLATION = 'US_CONSUMPTION_ENTRY_CBP_CANCELLATION',
  US_CONSUMPTION_ENTRY_AUTO_FLIP_EXCEPTION = 'US_CONSUMPTION_ENTRY_AUTO_FLIP_EXCEPTION',
  US_CONSUMPTION_ENTRY_REQUEST_FOR_INFORMATION = 'US_CONSUMPTION_ENTRY_REQUEST_FOR_INFORMATION',
  US_CONSUMPTION_ENTRY_NOTICE_OF_PROPOSED_ACTION = 'US_CONSUMPTION_ENTRY_NOTICE_OF_PROPOSED_ACTION',
  US_CONSUMPTION_ENTRY_NOTICE_OF_ACTION_TAKEN = 'US_CONSUMPTION_ENTRY_NOTICE_OF_ACTION_TAKEN',
  US_CONSUMPTION_ENTRY_LIQUIDATED_DAMAGES = 'US_CONSUMPTION_ENTRY_LIQUIDATED_DAMAGES',
}

export enum UsType86EntryTaskType {
  US_TYPE86_ENTRY_COMPLETION_AND_SUBMISSION = 'US_TYPE86_ENTRY_COMPLETION_AND_SUBMISSION',
  US_TYPE86_ENTRY_EXCEPTION = 'US_TYPE86_ENTRY_EXCEPTION',
  US_TYPE86_ENTRY_CBP_HOLD = 'US_TYPE86_ENTRY_CBP_HOLD',
  US_TYPE86_ENTRY_CBP_EXAM = 'US_TYPE86_ENTRY_CBP_EXAM',
  US_TYPE86_ENTRY_PGA_HOLD = 'US_TYPE86_ENTRY_PGA_HOLD',
  US_TYPE86_ENTRY_PGA_EXCEPTION = 'US_TYPE86_ENTRY_PGA_EXCEPTION',
  US_TYPE86_ENTRY_AUTO_FLIP_EXCEPTION = 'US_TYPE86_ENTRY_AUTO_FLIP_EXCEPTION',
}

export enum UsIsfTaskType {
  US_ISF_FILING = 'US_ISF_FILING',
  US_ISF_EXCEPTION = 'US_ISF_EXCEPTION',
}

export enum UsDrawbackClaimTaskType {
  US_DRAWBACK_CLAIM_FILING = 'US_DRAWBACK_CLAIM_FILING',
}

export enum UsIorContinuousBondRequestTaskType {
  ACQUIRE_US_IOR_CONTINUOUS_BOND = 'ACQUIRE_US_IOR_CONTINUOUS_BOND',
  US_IOR_CONTINUOUS_BOND_CHARGE_BOND_RENEWAL_EXCEPTION = 'US_IOR_CONTINUOUS_BOND_CHARGE_BOND_RENEWAL_EXCEPTION',
  US_IOR_CONTINUOUS_BOND_VERIFY_RENEWAL_EXCEPTION = 'US_IOR_CONTINUOUS_BOND_VERIFY_RENEWAL_EXCEPTION',
}

export enum UsIorActivationTaskType {
  US_IOR_ACTIVATION = 'US_IOR_ACTIVATION',
}

export enum UsPostSummaryCorrectionTaskType {
  US_POST_SUMMARY_CORRECTION_FILING = 'US_POST_SUMMARY_CORRECTION_FILING',
}

export enum UsInBondTaskType {
  US_IN_BOND_FILING = 'US_IN_BOND_FILING',
}

export enum DeliveryOrderTaskType {
  DELIVERY_ORDER_CREATION = 'DELIVERY_ORDER_CREATION',
}

export enum EuCustomsEntryExceptionTaskType {
  EU_CUSTOMS_ENTRY_CUSTOMS_REQUESTED_AMENDMENTS = 'EU_CUSTOMS_ENTRY_CUSTOMS_REQUESTED_AMENDMENTS',
  EU_CUSTOMS_ENTRY_SELLER_REQUESTED_AMENDMENTS = 'EU_CUSTOMS_ENTRY_SELLER_REQUESTED_AMENDMENTS',
  EU_CUSTOMS_ENTRY_BROKER_ERROR_EXCEPTION = 'EU_CUSTOMS_ENTRY_BROKER_ERROR_EXCEPTION',
  EU_CUSTOMS_ENTRY_CUSTOMS_SYSTEM_ERROR_EXCEPTION = 'EU_CUSTOMS_ENTRY_CUSTOMS_SYSTEM_ERROR_EXCEPTION',
}

export enum GbCustomsEntryTaskType {
  GB_CUSTOMS_ENTRY_UPLOAD_DRAFT_ENTRY_DOCUMENT = 'GB_CUSTOMS_ENTRY_UPLOAD_DRAFT_ENTRY_DOCUMENT',
  GB_CUSTOMS_ENTRY_UPLOAD_DUTIES = 'GB_CUSTOMS_ENTRY_UPLOAD_DUTIES',
  GB_CUSTOMS_ENTRY_UPLOAD_FINAL_ENTRY_DOCUMENT = 'GB_CUSTOMS_ENTRY_UPLOAD_FINAL_ENTRY_DOCUMENT',
  GB_CUSTOMS_ENTRY_UPLOAD_FINAL_CHARGES = 'GB_CUSTOMS_ENTRY_UPLOAD_FINAL_CHARGES',
  GB_CUSTOMS_ENTRY_REVIEW_DOCUMENTS = 'GB_CUSTOMS_ENTRY_REVIEW_DOCUMENTS', // For Brexit
}

export enum DeCustomsEntryTaskType {
  DE_CUSTOMS_ENTRY_UPLOAD_DRAFT_ENTRY_DOCUMENT = 'DE_CUSTOMS_ENTRY_UPLOAD_DRAFT_ENTRY_DOCUMENT',
  DE_CUSTOMS_ENTRY_UPLOAD_DUTIES = 'DE_CUSTOMS_ENTRY_UPLOAD_DUTIES',
  DE_CUSTOMS_ENTRY_UPLOAD_FINAL_ENTRY_DOCUMENT = 'DE_CUSTOMS_ENTRY_UPLOAD_FINAL_ENTRY_DOCUMENT',
  DE_CUSTOMS_ENTRY_UPLOAD_FINAL_CHARGES = 'DE_CUSTOMS_ENTRY_UPLOAD_FINAL_CHARGES',
  DE_CUSTOMS_ENTRY_REVIEW_DOCUMENTS = 'DE_CUSTOMS_ENTRY_REVIEW_DOCUMENTS', // For Brexit
}

export enum NlCustomsEntryTaskType {
  NL_CUSTOMS_ENTRY_UPLOAD_DRAFT_ENTRY_DOCUMENT = 'NL_CUSTOMS_ENTRY_UPLOAD_DRAFT_ENTRY_DOCUMENT',
  NL_CUSTOMS_ENTRY_UPLOAD_DUTIES = 'NL_CUSTOMS_ENTRY_UPLOAD_DUTIES',
  NL_CUSTOMS_ENTRY_UPLOAD_FINAL_ENTRY_DOCUMENT = 'NL_CUSTOMS_ENTRY_UPLOAD_FINAL_ENTRY_DOCUMENT',
  NL_CUSTOMS_ENTRY_UPLOAD_FINAL_CHARGES = 'NL_CUSTOMS_ENTRY_UPLOAD_FINAL_CHARGES',
  NL_CUSTOMS_ENTRY_REVIEW_DOCUMENTS = 'NL_CUSTOMS_ENTRY_REVIEW_DOCUMENTS', // For Brexit
}

export enum FrCustomsEntryTaskType {
  FR_CUSTOMS_ENTRY_UPLOAD_DRAFT_ENTRY_DOCUMENT = 'FR_CUSTOMS_ENTRY_UPLOAD_DRAFT_ENTRY_DOCUMENT',
  FR_CUSTOMS_ENTRY_UPLOAD_DUTIES = 'FR_CUSTOMS_ENTRY_UPLOAD_DUTIES',
  FR_CUSTOMS_ENTRY_UPLOAD_FINAL_ENTRY_DOCUMENT = 'FR_CUSTOMS_ENTRY_UPLOAD_FINAL_ENTRY_DOCUMENT',
  FR_CUSTOMS_ENTRY_UPLOAD_FINAL_CHARGES = 'FR_CUSTOMS_ENTRY_UPLOAD_FINAL_CHARGES',
  FR_CUSTOMS_ENTRY_REVIEW_DOCUMENTS = 'FR_CUSTOMS_ENTRY_REVIEW_DOCUMENTS', // For Brexit
}

export enum CustomsDeclarationTaskType {
  CUSTOMS_DECLARATION_DOCUMENT_REVIEW = 'CUSTOMS_DECLARATION_DOCUMENT_REVIEW',
  CUSTOMS_DECLARATION_DRAFT_ENTRY_CONFIRMATION = 'CUSTOMS_DECLARATION_DRAFT_ENTRY_CONFIRMATION',
  CUSTOMS_DECLARATION_UPLOAD_DRAFT_ENTRY_DOCUMENT = 'CUSTOMS_DECLARATION_UPLOAD_DRAFT_ENTRY_DOCUMENT',
  CUSTOMS_DECLARATION_UPLOAD_DUTIES = 'CUSTOMS_DECLARATION_UPLOAD_DUTIES',
  CUSTOMS_DECLARATION_UPLOAD_FINAL_ENTRY_DOCUMENT = 'CUSTOMS_DECLARATION_UPLOAD_FINAL_ENTRY_DOCUMENT',
  CUSTOMS_DECLARATION_UPLOAD_ECHALLAN = 'CUSTOMS_DECLARATION_UPLOAD_ECHALLAN',
  CUSTOMS_DECLARATION_UPLOAD_FINAL_CHARGES = 'CUSTOMS_DECLARATION_UPLOAD_FINAL_CHARGES',
  CUSTOMS_DECLARATION_DOWNLOAD_POST_RELEASE_DOCUMENT = 'CUSTOMS_DECLARATION_DOWNLOAD_POST_RELEASE_DOCUMENT',
}

export enum GbIorActivationTaskType {
  GB_IOR_ACTIVATION = 'GB_IOR_ACTIVATION',
}

export enum DeIorActivationTaskType {
  DE_IOR_ACTIVATION = 'DE_IOR_ACTIVATION',
}

export enum NlIorActivationTaskType {
  NL_IOR_ACTIVATION = 'NL_IOR_ACTIVATION',
}

export enum FrIorActivationTaskType {
  FR_IOR_ACTIVATION = 'FR_IOR_ACTIVATION',
}

export enum ImporterEntityActivationTaskType {
  IMPORTER_ENTITY_ACTIVATION = 'IMPORTER_ENTITY_ACTIVATION',
}

export enum AceSystemErrorExceptionTaskType {
  ACE_SYSTEM_ERROR_EXCEPTION = 'ACE_SYSTEM_ERROR_EXCEPTION',
}

export const EuWorkOrderTaskType = {
  ...GbCustomsEntryTaskType,
  ...DeCustomsEntryTaskType,
  ...NlCustomsEntryTaskType,
  ...FrCustomsEntryTaskType,
  ...GbIorActivationTaskType,
  ...DeIorActivationTaskType,
  ...NlIorActivationTaskType,
  ...FrIorActivationTaskType,
  ...EuCustomsEntryExceptionTaskType,
};

export const WorkOrderTaskType = {
  ...AmsTaskType,
  ...UsConsumptionEntryTaskType,
  ...UsIsfTaskType,
  ...UsDrawbackClaimTaskType,
  ...UsIorContinuousBondRequestTaskType,
  ...UsIorActivationTaskType,
  ...UsPostSummaryCorrectionTaskType,
  ...UsInBondTaskType,
  ...DeliveryOrderTaskType,
  ...GbCustomsEntryTaskType,
  ...DeCustomsEntryTaskType,
  ...NlCustomsEntryTaskType,
  ...FrCustomsEntryTaskType,
  ...UsType86EntryTaskType,
  ...GbIorActivationTaskType,
  ...DeIorActivationTaskType,
  ...NlIorActivationTaskType,
  ...FrIorActivationTaskType,
  ...AceSystemErrorExceptionTaskType,
  ...EuCustomsEntryExceptionTaskType,
  ...CustomsDeclarationTaskType,
  ...ImporterEntityActivationTaskType,
};

export type WorkOrderTaskType =
  | AmsTaskType
  | UsConsumptionEntryTaskType
  | UsIsfTaskType
  | UsDrawbackClaimTaskType
  | UsIorContinuousBondRequestTaskType
  | UsIorActivationTaskType
  | UsPostSummaryCorrectionTaskType
  | UsInBondTaskType
  | DeliveryOrderTaskType
  | GbCustomsEntryTaskType
  | DeCustomsEntryTaskType
  | NlCustomsEntryTaskType
  | FrCustomsEntryTaskType
  | UsType86EntryTaskType
  | GbIorActivationTaskType
  | DeIorActivationTaskType
  | NlIorActivationTaskType
  | FrIorActivationTaskType
  | AceSystemErrorExceptionTaskType
  | EuCustomsEntryExceptionTaskType
  | CustomsDeclarationTaskType
  | ImporterEntityActivationTaskType;

// inspired by https://docs.aws.amazon.com/step-functions/latest/dg/concepts-states.html
export enum WorkOrderStateType {
  // for now we only support TASK
  TASK = 'TASK',
  // CHOICE = 'CHOICE',
  // PARALLEL = 'PARALLEL',
}

export enum WorkOrderGroupStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  BLOCKED = 'BLOCKED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export enum WorkOrderStatus {
  NOT_STARTED = 'NOT_STARTED',
  READY = 'READY',
  IN_PROGRESS = 'IN_PROGRESS',
  BLOCKED = 'BLOCKED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  REPLACED = 'REPLACED',
}

export enum WorkOrderTaskStatus {
  NOT_ASSIGNED = 'NOT_ASSIGNED',
  ASSIGNED = 'ASSIGNED',
  IN_PROGRESS = 'IN_PROGRESS',
  ESCALATED = 'ESCALATED',
  BLOCKED = 'BLOCKED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  CLOSED = 'CLOSED',
}

export enum WorkOrderTaskSubStatus {
  ACTIVELY_IN_PROGRESS = 'ACTIVELY_IN_PROGRESS',
  PASSIVELY_IN_PROGRESS = 'PASSIVELY_IN_PROGRESS',
  PENDING_INFORMATION = 'PENDING_INFORMATION',
}

export enum EuCountryCodes {
  'GB' = 'GB',
  'DE' = 'DE',
  'NL' = 'NL',
  'FR' = 'FR',
}

export enum CustomsEntryReviewStatus {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
}

export enum EuCustomsEntryReviewDocumentsTaskType {
  GB_CUSTOMS_ENTRY_REVIEW_DOCUMENTS = 'GB_CUSTOMS_ENTRY_REVIEW_DOCUMENTS',
  DE_CUSTOMS_ENTRY_REVIEW_DOCUMENTS = 'DE_CUSTOMS_ENTRY_REVIEW_DOCUMENTS',
  NL_CUSTOMS_ENTRY_REVIEW_DOCUMENTS = 'NL_CUSTOMS_ENTRY_REVIEW_DOCUMENTS',
  FR_CUSTOMS_ENTRY_REVIEW_DOCUMENTS = 'FR_CUSTOMS_ENTRY_REVIEW_DOCUMENTS',
}
