import { ApolloLink } from '@apollo/client';
import { stripTypeNames } from '@xbcb/js-utils';

export const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = stripTypeNames(operation.variables);
  }
  return forward(operation).map((data) => {
    return data;
  });
});
