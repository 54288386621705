import gql from 'graphql-tag';
import { pascalCase } from 'change-case';

interface TradeDataCodesProps {
  type: 'app' | 'public';
}

export const tradeDataCodes = ({ type }: TradeDataCodesProps) => {
  return gql`
    query ${pascalCase(type)}TradeDataCodes {
      ${type}TradeDataCodes {
      versionId
      link
    }
    }
  `;
};
