import { SupportedCountryAbbreviation } from '@xbcb/shared-types';
import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

export const devicesFeature = 'DEVICES_FEATURE';

const DEVICES_FEATURE = new FeatureFlagService([
  {
    feature: devicesFeature,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA],
    countriesOfOperation: {
      [Stage.ALPHA]: [SupportedCountryAbbreviation.US],
      [Stage.BETA]: [SupportedCountryAbbreviation.US],
      [Stage.GAMMA]: [SupportedCountryAbbreviation.US],
    },
  },
]);

export { DEVICES_FEATURE };
