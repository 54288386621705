import { createWorkOrderRecord } from './utils';
import { iorNumber } from '../iorNumber';
import { EsDataType } from '../../index';

export const usIorContinuousBondRequest = createWorkOrderRecord({
  columns: {
    productDescription: {
      name: 'Product Description',
      type: EsDataType.TEXT,
    },
    productOriginCountryCode: {
      name: 'Product Origin Country Code',
      type: EsDataType.KEYWORD,
    },
    bondNumber: {
      default: true,
      name: 'Bond #',
      type: EsDataType.KEYWORD,
    },
    bondSuretyCode: {
      name: 'Bond Surety Code',
      type: EsDataType.KEYWORD,
    },
    bondAmount: {
      name: 'Bond Amount',
      type: EsDataType.TEXT,
      default: true,
    },
    iorNumber,
    usIorId: {
      name: 'US Ior ID',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    usIorName: {
      name: 'US Ior Name',
      type: EsDataType.TEXT,
    },
    renewalDate: {
      name: 'Bond Renewal Date',
      type: EsDataType.TIME,
    },
    terminationDate: {
      name: 'Bond Termination Date',
      type: EsDataType.TIME,
    },
    renewalScheduled: {
      name: 'Bond Renewal Scheduled',
      type: EsDataType.TEXT,
    },
    renewalCanceledDate: {
      name: 'Bond Renewal Canceled Date',
      type: EsDataType.TIME,
    },
    suretyReferenceNumber: {
      name: 'Surety Reference Number',
      type: EsDataType.KEYWORD,
    },
  },
});
