import React, { useState, useEffect } from 'react';
import { Form, Result } from 'antd';
import { find, omit } from 'lodash';
import { useQuery, useMutation } from '@apollo/client';
import { client } from '@xbcb/apollo-client';
import { DocumentSignRequestStatus } from '@xbcb/document-types';
import { TemplateBasedSignUploadsCardDocs } from '@xbcb/document-components';
import { Loading } from '@xbcb/feedback-components';
import { HiddenField } from '@xbcb/form-item-components';
import { RecordType } from '@xbcb/shared-types';
import {
  StyledContentDiv,
  StyledContentMainSignDiv,
} from '../StampBasedSign/styles';
import { cloneJson } from '@xbcb/js-utils';
import {
  loadCodes,
  getCodes,
  refreshCodesNoRedux,
  transformParty,
  prepareInitialValues,
} from '@xbcb/ui-utils';
import {
  GET_TEMPLATE_DOCUMENT_SIGN_REQUEST,
  GET_US_IOR,
  UPDATE_TEMPLATE_DOCUMENT_SIGN_REQUEST_WITH_SIGNATURE,
} from './queries';
import TemplateBasedSignFields from '../TemplateBasedSignFields';

interface TemplateBasedSignProps {
  id: string;
  attribution: string;
  Docs: TemplateBasedSignUploadsCardDocs;
}

const TemplateBasedSign = ({
  id,
  attribution,
  Docs,
}: TemplateBasedSignProps) => {
  const [currentVersion, setCurrentVersion] = useState('');
  const [form] = Form.useForm();

  useEffect(() => {
    const update = async () => {
      await loadCodes();
      if (!getCodes()) {
        const newVersion = await refreshCodesNoRedux({
          type: 'public',
          client,
          currentVersion,
        });
        if (newVersion) setCurrentVersion(newVersion);
      }
      form.validateFields();
    };
    update();
  });

  const {
    loading: templateDsrQueryLoading,
    error: templateDsrQueryError,
    data: templateDsrQueryData,
    refetch: refetchTemplateDocumentSignRequest,
  } = useQuery(GET_TEMPLATE_DOCUMENT_SIGN_REQUEST, {
    variables: { id },
  });
  const templateDocumentSignRequest =
    templateDsrQueryData?.templateDocumentSignRequest || {};
  const {
    id: templateDocumentSignRequestId,
    status,
    deleted,
    expireTime,
    template,
    tags,
    operator = {},
    signature,
    additionalFields,
  } = templateDocumentSignRequest;
  const { id: operatorId } = operator;

  // used to look for and add to documents related to usIors (e.g. MPOA document)
  const usIorIdTagKey = 'usIorId';
  const usIorTag = find(tags, { key: usIorIdTagKey });
  const usIorId = usIorTag?.value;

  // Query for the record info on page load
  const getQueryVariables = { id: usIorId };
  const usIorQueryResponse = useQuery(GET_US_IOR, {
    variables: getQueryVariables,
    skip: !usIorId,
  });
  const {
    loading: usIorQueryLoading,
    error: usIorQueryError,
    data: usIorQueryData,
  } = usIorQueryResponse;
  const usIor = usIorQueryData?.usIor || {};

  const queryError = templateDsrQueryError || usIorQueryError;
  const queryLoading = templateDsrQueryLoading || usIorQueryLoading;

  const [
    updateTemplateDocumentSignRequest,
    { loading: mutationLoading, error: mutationError, data: mutationData },
  ] = useMutation(UPDATE_TEMPLATE_DOCUMENT_SIGN_REQUEST_WITH_SIGNATURE);

  // Validate immediately to highlight required fields in red
  form.validateFields();

  // Used for `HiddenField` below
  const forwarderNamePath = ['forwarder'];

  const renderContent = () => {
    if (
      queryError ||
      mutationError ||
      expireTime < new Date().toISOString() ||
      status === DocumentSignRequestStatus.VOID ||
      deleted
    )
      return (
        <Result
          status="error"
          title="Error"
          subTitle="There was an error with this document"
        />
      );
    if (mutationData)
      return (
        <Result
          status="success"
          title="Success!"
          subTitle="The document has been signed"
        />
      );
    if (status === DocumentSignRequestStatus.SIGNED || signature)
      return (
        <Result
          status="success"
          title="Success!"
          subTitle="The document has already been signed"
        />
      );

    const { templateType } = template;
    return (
      <StyledContentMainSignDiv>
        {/* Using a `HiddenField` here to add the forwarder to the form so it
        is submitted as `additionalFields` and so we can use it in
        `renderTemplate` to fill the template */}
        <HiddenField
          localNamePath={forwarderNamePath}
          fullNamePath={forwarderNamePath}
          value={additionalFields?.forwarder}
          form={form}
        />
        <TemplateBasedSignFields
          attribution={attribution}
          form={form}
          type={templateType}
          operatorId={operatorId}
          usIorId={usIorId}
          // Include all of the TemplateDSR tags, they should be copied over to
          // any docs that are uploaded as well as a template DSR ID tag. The
          // template DSR ID tag is added by default in the Docs component since
          // it is the "recordIdTag" so we don't need to add it here
          additionalTags={tags}
          templateDocumentSignRequest={templateDocumentSignRequest}
          updateTemplateDocumentSignRequest={updateTemplateDocumentSignRequest}
          mutationLoading={mutationLoading}
          Docs={Docs}
          refetchTemplateDocumentSignRequest={
            refetchTemplateDocumentSignRequest
          }
        />
      </StyledContentMainSignDiv>
    );
  };

  // Use the record we got back from the query to create the initial values for the form
  const recordInput = omit(
    transformParty.toForm({
      existingRecord: cloneJson(usIor),
    }), // we clone so we can modify apollo-client's read only returned data
    ['__typename', 'id', 'version', 'tags', 'created', 'updated', 'deleted'],
  );
  const initialValues = prepareInitialValues({
    record: recordInput,
    recordType: RecordType.US_IOR,
  });

  // TODO how to handle `validateCbp5106Party` aka `validateUsIor` (since all it does is call `validateCbp5106Party`)

  if (queryLoading || !getCodes()) return <Loading isLoading />;

  return (
    <Form form={form} initialValues={initialValues}>
      <StyledContentDiv>{renderContent()}</StyledContentDiv>
    </Form>
  );
};

export default TemplateBasedSign;
