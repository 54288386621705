import { addressFields, phoneFields } from '@xbcb/shared-queries';
import {
  documentGenerationRequestFragments,
  usQuantityFragment,
} from './documentGenerationRequest';

const usCargoReleaseDocumentGenerationRequestFragment = `
fragment usCargoReleaseDocumentGenerationRequestFields on UsCargoReleaseDocumentGenerationRequest {
  shipment {
    id
  }
  document {
    id
  }
  importer {
    id
  }
  broker {
    name
    address {
      ...addressFields
    }
    phone {
      ...phoneFields
    }
  }
  inltShipmentId
  carrierCode
  vesselName
  tripNumber
  arrivalTime
  entry {
    number
    code
  }
  firmsCode
  releaseDispositions {
    ...usCargoReleaseDispositionInfoFields
  }
  billDispositions {
    ...usCargoReleaseBillDispositionInfoFields
  }
  ...documentGenerationRequestFields
  ...recordFields
}
`;

const usCargoReleaseDispositionInfoFragment = `
fragment usCargoReleaseDispositionInfoFields on UsCargoReleaseDispositionInfo {
  action {
    time
    code
  }
  releaseInfo {
    time
    originCode
  }
  message
}
`;

const usCargoReleaseBillDispositionInfoFragment = `
fragment usCargoReleaseBillDispositionInfoFields on UsCargoReleaseBillDispositionInfo {
  fullBillNumber
  time
  code
  message
  customEnteredQuantity {
    ...usQuantityFields
  }
  manifestedQuantity {
    ...usQuantityFields
  }
  inBondInfo {
    itNumber
    quantity {
      ...usQuantityFields
    }
  }
}
`;

export const usCargoReleaseDocumentGenerationRequestFragments = `
${usCargoReleaseDocumentGenerationRequestFragment}
${addressFields}
${phoneFields}
${usCargoReleaseDispositionInfoFragment}
${usCargoReleaseBillDispositionInfoFragment}
${usQuantityFragment}
${documentGenerationRequestFragments}
`;
