import styled from 'styled-components';
import { Button, Card } from 'antd';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import LockOutlined from '@ant-design/icons/LockOutlined';

// TODO we used this pattern a lot, address it
export type ButtonTypeProps = any;

export const StyledButton = styled<ButtonTypeProps>(Button)<{
  $color?: string;
}>`
  ${({ $color }) => ($color ? `color: ${$color};` : '')}
  border: none;
  top: 2px;
`;

export const StyledCard = styled(Card)<{ $fullWidth?: boolean }>`
  width: 25%;
  height: 158px;
  padding: 0;
  margin-bottom: var(--space-4);
  margin-right: var(--space-4);
  ${({ $fullWidth }) =>
    $fullWidth ? 'width: 100%; margin-right: 0;' : 'min-width: 312px;'}
  .ant-card-body {
    height: 100%;
    display: flex;
    flex-grow: 1;
    padding: var(--space-4);
  }
  .ant-form-item {
    margin-top: var(--space-4);
  }
  .ant-select-selection {
    width: var(--space-8);
  }
  .ant-spin {
    width: 100%;
    align-self: center;
  }
`;

export const StyledDocumentActionsDiv = styled.div`
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .ant-btn {
    box-shadow: none;
    background: none;
  }
  button:not(:hover):not(.ant-popover-open):not(:active) {
    &.doc-download,
    &.doc-edit,
    &.doc-info {
      color: var(--text-grey);
    }
    &.doc-delete {
      color: var(--bad);
    }
  }
  button:not(:last-child) {
    margin-right: var(--space-3);
  }
`;

export const StyledDocumentDetailsDiv = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  .ant-btn {
    box-shadow: none;
  }
`;

export const StyledDocumentTagsDiv = styled.div`
  height: 60%;
  overflow-y: auto;
  .ant-tag {
    width: fit-content;
    max-width: 125px;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const StyledDocumentTitleDiv = styled.div`
  font-size: 16px;
  font-weight: 400;
  height: 20%;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledDocumentPreviewDiv = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  margin-right: var(--space-4);
  align-items: center;
  justify-content: center;
  & {
    :hover {
      img {
        opacity: var(--disabled-opacity);
      }
    }
  }
`;

export const StyledMissingPreviewDiv = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: var(--space-6);
  .anticon {
    color: var(--dark-grey-4);
  }
`;

export const StyledLockOutlined = styled(LockOutlined)`
  font-size: 16px;
  color: var(--orange-1);
  margin-right: var(--space-4);
  margin-top: var(--space-3);
`;

export const StyledDeleteOutlined = styled(DeleteOutlined)`
  font-size: 20px;
  margin-right: var(--space-2);
  color: red;
`;
