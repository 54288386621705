export enum CssSize {
  MICRO = 'var(--micro)',
  MICRO_TINY = 'var(--micro-tiny)',
  TINY = 'var(--tiny)',
  TINY_SHORT = 'var(--tiny-short)',
  SHORT = 'var(--short)',
  SHORT_MEDIUM = 'var(--short-medium)',
  MEDIUM = 'var(--medium)',
  MEDIUM_LARGE = 'var(--medium-large)',
  LARGE = 'var(--large)',
  EXTRA_LARGE = 'var(--extra-large)',
}

export enum CssSpace {
  SPACE_1 = 'var(--space-1)',
  SPACE_2 = 'var(--space-2)',
  SPACE_3 = 'var(--space-3)',
  SPACE_4 = 'var(--space-4)',
  SPACE_5 = 'var(--space-5)',
  SPACE_6 = 'var(--space-6)',
  SPACE_7 = 'var(--space-7)',
  SPACE_8 = 'var(--space-8)',
  SPACE_9 = 'var(--space-9)',
  SPACE_10 = 'var(--space-10)',
}
