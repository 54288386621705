import { AnyObject } from '@xbcb/shared-types';

// Sorts an object by its keys.
export const sortObjectKeys = (unordered: AnyObject) => {
  if (!unordered) return unordered;
  const sortedObject: AnyObject = {};
  const sortedKeys = Object.keys(unordered).sort();
  sortedKeys.forEach((key) => {
    const value = unordered[key];
    // Arrays shouldn't be recursively sorted
    if (typeof value === 'object' && !Array.isArray(value)) {
      sortedObject[key] = sortObjectKeys(value);
    } else {
      sortedObject[key] = value;
    }
  });
  return sortedObject;
};
