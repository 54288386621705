import { SendEmailPayload } from './sendEmailPayload';
import { SnsEventPayload } from './snsEventPayload';
import { IotEventPayload, IotEventType } from './iotEventPayload';
import { ButterCmsEventKey } from '../enums';

// https://basarat.gitbook.io/typescript/type-system/typeguard#user-defined-type-guards

/**
 * Validates the payload against the SendEmailPayload interface.
 * @param { any } payload payload received
 * @return { boolean } true if payload is valid; false otherwise
 */
export const isSendEmailPayload = (
  payload: any,
): payload is SendEmailPayload => {
  const hasFromToAndSubject =
    typeof payload?.from === 'string' &&
    (typeof payload?.to === 'string' || validateListOfStrings(payload?.to)) &&
    typeof payload?.subject === 'string';
  const hasButterCmsEventKey = payload.butterCmsEventKey in ButterCmsEventKey;
  // if there are any dependencies provided, then make sure that each of them are valid
  if (
    payload.dependencies?.documentIds &&
    !validateListOfStrings(payload.dependencies.documentIds)
  ) {
    return false;
  }
  if (
    payload.dependencies?.pendingDocumentGenerationIds &&
    !validateListOfStrings(payload.dependencies.pendingDocumentGenerationIds)
  ) {
    return false;
  }
  return hasFromToAndSubject && hasButterCmsEventKey;
};

/**
 * Validates the payload against the SnsEventPayload interface.
 * @param { any } payload payload received
 * @return { boolean } true if payload is valid; false otherwise
 */
export const isSnsEventPayload = (payload: any): payload is SnsEventPayload => {
  const hasExternalSystemRecipientsGroups =
    Array.isArray(payload.externalSystemRecipientsGroups) &&
    payload.externalSystemRecipientsGroups.length;
  if (hasExternalSystemRecipientsGroups) {
    for (const externalSystemRecipientsGroup of payload.externalSystemRecipientsGroups) {
      const hasExternalSystemIds = validateListOfStrings(
        externalSystemRecipientsGroup.ids,
      );
      if (
        !hasExternalSystemIds ||
        (typeof externalSystemRecipientsGroup.payload !== 'object' &&
          !Array.isArray(externalSystemRecipientsGroup.payload))
      ) {
        return false;
      }
    }
    return true;
  }
  return false;
};

/**
 * Validates the payload against the IotEventPayload interface.
 * @param { any } payload payload received
 * @return { boolean } true if payload is valid; false otherwise
 */
export const isIotEventPayload = (payload: any): payload is IotEventPayload => {
  const hasCbmsUserRecipientsGroups =
    Array.isArray(payload.cbmsUserRecipientsGroups) &&
    payload.cbmsUserRecipientsGroups.length;
  if (hasCbmsUserRecipientsGroups) {
    for (const cbmsUserRecipientsGroup of payload.cbmsUserRecipientsGroups) {
      const hasUserIds = validateListOfStrings(cbmsUserRecipientsGroup.userIds);
      if (
        !hasUserIds ||
        !(cbmsUserRecipientsGroup.type in IotEventType) ||
        (typeof cbmsUserRecipientsGroup.payload !== 'object' &&
          !Array.isArray(cbmsUserRecipientsGroup.payload))
      ) {
        return false;
      }
    }
    return true;
  }
  return false;
};

/**
 * Validates a key of a payload that is expected to be
 * a list of strings.
 * @param { any } list
 * @return { boolean } true if it is a list of strings; false otherwise
 */
const validateListOfStrings = (list: any) => {
  return (
    Array.isArray(list) &&
    Boolean(list.length) &&
    (list as any[]).every((id) => typeof id === 'string')
  );
};
