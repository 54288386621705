import { CrudPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const OperatorUserExchangeMessagePermissionName = {
  ...CrudPermissionName,
};

export default {
  [ObjectType.EXCHANGE_MESSAGE]: OperatorUserExchangeMessagePermissionName,
};
