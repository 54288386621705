import { euIorActivationsFields } from './euIorActivations';
import { partyFragments } from './party';

export const gbIorFragment = `
fragment gbIorFields on GbIor {
  ...recordFields
  ...partyFields
  vatNumber
  eori
  industry
  businessStructure
  ${euIorActivationsFields}
}
`;

export const gbIorFragments = `
${gbIorFragment}
${partyFragments}
`;
