import {
  CreateDeliveryOrderInput,
  UsConsumptionEntry,
} from '@xbcb/api-gateway-client';

type transformShipmentToDeliveryOrderInputProps = {
  wogId: string;
  entry?: UsConsumptionEntry; // This is optional because we can create a Delivery Order without an entry.
};

export const transformShipmentToDeliveryOrderInput = ({
  wogId,
  entry,
}: transformShipmentToDeliveryOrderInputProps): CreateDeliveryOrderInput => {
  const { masterBills, containers, arrival } = entry || {};
  const masterBill = masterBills?.[0];
  const masterBillNumber = masterBill?.number;
  const houseBillNumber = masterBill?.houseBills?.[0].number;
  const pickup = arrival?.firmsCode
    ? {
        firmsCode: arrival?.firmsCode,
      }
    : undefined;
  const input = {
    group: {
      id: wogId,
    },
    containers: entry ? containers : [],
    pickup,
    email: {
      subject: `Delivery Order for Containers: ${
        containers ? containers.map((c) => c.number).join(', ') : ''
      }, Master Bill: ${
        masterBillNumber ? masterBillNumber : ''
      }, House Bill: ${houseBillNumber ? houseBillNumber : ''}`,
    },
  } as CreateDeliveryOrderInput;

  return input;
};
