export enum ReferenceType {
  IMPORT_ENTRY_NUMBER = 'IMPORT_ENTRY_NUMBER',
}

export enum ReferenceProcessingStatus {
  SUCCESS = 'SUCCESS',
  SKIPPED = 'SKIPPED',
  FAILED = 'FAILED',
}

export enum ImportEntryNumberErrorCode {
  INVALID_IEN_FORMAT_EXCEPTION = 'InvalidIenFormatException',
  INVALID_MERCHANT_ID_EXCEPTION = 'InvalidMerchantIdException',
  VALIDATION_EXCEPTION = 'ValidationException',
  INVALID_REQUEST_EXCEPTION = 'InvalidRequestException',
  INTERNAL_SERVICE_EXCEPTION = 'InternalServiceException',
  DEPENDENCY_FAILURE_EXCEPTION = 'DependencyFailureException',
  OPTIMISTIC_LOCK_EXCEPTION = 'OptimisticLockException',
  IEN_NOT_REQUIRED_EXCEPTION = 'IenNotRequiredException',
  UNKNOWN_IEN_EXCEPTION = 'UnknownIenException',
}

export const ReferenceErrorCode = {
  ...ImportEntryNumberErrorCode,
};

export type ReferenceErrorCode = ImportEntryNumberErrorCode;

export const IenErrorCodeDefinitionMap: {
  [key: string]: string;
} = {
  [ReferenceErrorCode.INVALID_IEN_FORMAT_EXCEPTION]:
    'IEN does not match MRN or S10 Format',
  [ReferenceErrorCode.INVALID_MERCHANT_ID_EXCEPTION]:
    'Provided encyptedMerchantCustomerId does not match the owner of the shipmentId',
  [ReferenceErrorCode.VALIDATION_EXCEPTION]:
    'Provided input does not conform to coral model definition',
  [ReferenceErrorCode.INVALID_REQUEST_EXCEPTION]:
    'Invalid request. See InvalidRequestException for possible reasons.',
  [ReferenceErrorCode.INTERNAL_SERVICE_EXCEPTION]:
    'Internal system error. Please retry request',
  [ReferenceErrorCode.DEPENDENCY_FAILURE_EXCEPTION]:
    'Internal system error. Please retry request',
  [ReferenceErrorCode.OPTIMISTIC_LOCK_EXCEPTION]:
    'Internal system error. Please retry request',
  [ReferenceErrorCode.IEN_NOT_REQUIRED_EXCEPTION]: 'IEN not required',
  [ReferenceErrorCode.UNKNOWN_IEN_EXCEPTION]: 'Unknown IEN exception error',
};
