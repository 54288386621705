import pluralize from 'pluralize';

// TODO add custom pluralize rules.
// // Example of new plural rule:
// pluralize.addPluralRule(/gex$/i, 'gexii')
// pluralize.plural('regex') //=> "regexii"

// // Example of new singular rule:
// pluralize.addSingularRule(/singles$/i, 'singular')
// pluralize.singular('singles') //=> "singular"

// // Example of new irregular rule, e.g. "I" -> "we":
// pluralize.addIrregularRule('irregular', 'regular')
// pluralize.plural('irregular') //=> "regular"

pluralize.addSingularRule('hts', 'singular');
pluralize.addIrregularRule('hts', 'htss');
pluralize.addOverride('usHts', 'usHtss');
pluralize.addOverride('UsHts', 'UsHtss');
pluralize.addSingularRule('usHts', 'singular');

export { pluralize };
