import { css } from 'styled-components';
import { CssSpace } from '@xbcb/ui-types';

const spacing = CssSpace.SPACE_4;

export const spaceBottom = css`
  margin-bottom: ${spacing};
`;

export const spaceTop = css`
  margin-top: ${spacing};
`;

export const spaceRight = css`
  margin-right: ${spacing};
`;

export const spaceLeft = css`
  margin-left: ${spacing};
`;
