import React, { useEffect } from 'react';
import { Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { NamePath } from '@xbcb/ui-types';
import { HiddenFormItem } from './styles';

interface HiddenFieldProps {
  localNamePath: NamePath;
  value?: any;
  form: FormInstance;
  fullNamePath?: NamePath;
}

// This is a very touchy component.
// There are two primary ways to use it.
// Don't provide the "value" prop and only use this component to send back read-only data that was set by the back-end
// Manually control the value of the hidden field via the value prop.

// If modifying this file, make sure that complicated cases like the following still work:
// 1. Value is declaratively set via the value prop, but it is imperatively cleared from elsewhere. HiddenField should revert to the declaratively set value. (e.g. deterministic UOM from hts code. Tariff data is all cleared when changing an HTS, but the UOM should still set itself to whatever is passed down via the `value` prop)

const HiddenField = ({
  localNamePath,
  value,
  form,
  fullNamePath,
}: HiddenFieldProps) => {
  const name = fullNamePath || localNamePath;
  const formValue = form.getFieldValue(name);
  const resolvedValue = value || formValue;
  useEffect(() => {
    if (resolvedValue !== formValue) {
      form.setFields([{ name, value: resolvedValue }]);
    }
  }, [form, formValue, resolvedValue, name]);
  return (
    <HiddenFormItem name={localNamePath} initialValue={resolvedValue}>
      <Input className="hidden" />
    </HiddenFormItem>
  );
};

export default HiddenField;
