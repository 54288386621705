import { CrudType, QueryType, SearchType } from '@xbcb/shared-queries';
import { IdVersion } from '@xbcb/shared-types';

export interface ConstructOptimisticResultsArgs<Record> {
  crudType: CrudType;
  record: Record;
  response: any;
  updatedQueryType: QueryType | SearchType;
}

export type ConstructOptimisticResults<Record extends IdVersion> = (
  props: ConstructOptimisticResultsArgs<Record>,
) => any;

// This function updates the results of a query in the cache with an
// existing record based on the desired crudType. For example, if you would
// like to update the searchDocuments query in the cache after a user uploads
// a document, then:
//   crudType = 'create' (since a document is being created),
//   updatedQueryType = 'search' (since the searchDocuments query is being updated)
export const constructOptimisticResults = <Record extends IdVersion>({
  crudType,
  record,
  response,
  updatedQueryType,
}: ConstructOptimisticResultsArgs<Record>) => {
  const updatedQueryTypeToResultsMap = {
    get: record,
    gets: response,
    getAll: response?.nodes,
    search: response?.results,
    table: response?.results,
  };
  const results = updatedQueryTypeToResultsMap[updatedQueryType];
  if (updatedQueryType === 'get') return results;
  let newResults;
  if (crudType === 'create') {
    newResults = [...results, record];
  } else if (crudType === 'update' || crudType === 'get') {
    newResults = results.map((result: IdVersion) => {
      if (result.id === record.id) return record;
      return result;
    });
  } else {
    newResults = results.filter((result: IdVersion) => result.id !== record.id);
  }
  if (updatedQueryType === 'gets') return newResults;
  const optimisticResult = {
    ...response,
  };
  if (updatedQueryType === 'getAll') {
    optimisticResult.nodes = newResults;
  } else {
    optimisticResult.results = newResults;
  }
  return optimisticResult;
};
