import { CrudPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const ShipperUserShipmentPermissionName = {
  ...CrudPermissionName,
};

export default {
  [ObjectType.SHIPMENT]: ShipperUserShipmentPermissionName,
};
