// TODO rename all of these variables (but keep an alias to avoid a breaking change).
//  Replace "Fields" with "Fragment" in the variable name, except keep the GQL fragment name ending in "Fields"
export const connectionPageInfoFields = `
fragment connectionPageInfoFields on Connection {
  pageInfo {
    startCursor
    endCursor
    hasNextPage
  }
}`;

export const phoneFields = `
fragment phoneFields on Phone {
  country
  number
  extension
}`;

export const costFields = `
fragment costFields on Cost {
  currency
  value
}`;

export const addressFields = `
fragment addressFields on Address {
  address
  address2
  city
  postalCode
  stateCode
  countryCode
}`;

export const recordActionFields = `
fragment recordActionFields on RecordAction {
  source {
    __typename
    ... on InternalSystem {
      internalSystemName: name
    }
    ... on InternalWebsite {
      internalWebsiteName: name
    }
    ... on ClientSystem {
      clientSystemName: name
    }
  }
  time
  reason {
    code
    text
  }
  client {
    id
  }
  request {
    ipAddress
    userAgent
    requestId
  }
}`;
// TODO add origin to request above

export const recordFields = `
fragment recordFields on Record {
  created {
    ...recordActionFields
  }
  updated {
    ...recordActionFields
  }
  deleted {
    ...recordActionFields
  }
  id
  version
  tags {
    key
    value
  }
}`;

export const recordFragments = `
 ${recordFields}
 ${recordActionFields}
`;

export const embeddedRecordFragment = `
fragment embeddedRecordFields on EmbeddedRecord {
  id
  created {
    ...recordActionFields
  }
  updated {
    ...recordActionFields
  }
}
`;

export const embeddedRecordFragments = `
${embeddedRecordFragment}
${recordActionFields}
`;
