import { flatMap, map } from 'lodash';
import { Shipment, ShipmentComplianceDetail } from '@xbcb/api-gateway-client';

/**
 * This function is used to generate a list of compliance details references for a shipment.
 * complianceDetailsReference + Manufacturer + COO for each leg, invoice and complianceDetails
 *
 * @param {Shipment} shipment The shipment for which the list of compliance details references will be generated.
 * @return {Object[]} The list of compliance details references for the shipment.
 */
export const generateProductReferencesForShipment = (shipment: Shipment) =>
  flatMap(shipment.legs, (leg) =>
    flatMap(leg.commercialInvoices, (invoice) =>
      map(invoice.complianceDetails, (complianceDetail) =>
        createProductReference(complianceDetail),
      ),
    ),
  );

/**
 * This function is used to create a reference for a single compliance detail.
 * It combines the complianceDetailsReference, manufacturer and origin of the compliance detail.
 *
 * @param {ShipmentComplianceDetail} complianceDetail compliance detail for which the reference will be created.
 * @return {Object}
 */
export const createProductReference = (
  complianceDetail: ShipmentComplianceDetail,
) => ({
  externalReference: complianceDetail.complianceDetailsReference,
  localProductDetails: {
    origin: complianceDetail.origin,
    supplier: complianceDetail.manufacturer,
  },
});
