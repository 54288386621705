import { Stage } from '@xbcb/core';
import { FeatureFlagService } from '../featureFlagService';

export const e2openIntegrationFeature = 'E2OPEN_INTEGRATION_FEATURE';

const E2OPEN_INTEGRATION_FEATURE = new FeatureFlagService([
  {
    feature: e2openIntegrationFeature,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA],
  },
]);

export { E2OPEN_INTEGRATION_FEATURE };
