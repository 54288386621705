import React from 'react';
import { Form } from 'antd';
import { UsIorFirstTimeStatus } from '@xbcb/party-types';
import { shouldUpdate } from '@xbcb/ui-utils';
import AppRadioGroup from '../AppRadioGroup';
import { StyledSpan } from './styles';

// DEPRECATED
const FirstTimeImporter = ({
  form,
  readOnly,
  disabled,
  localNamePath,
  fullNamePath,
}: any) => (
  <Form.Item shouldUpdate={shouldUpdate([fullNamePath])} noStyle>
    {() => {
      const firstTimeStatus = form.getFieldValue(fullNamePath);
      return (
        <>
          <AppRadioGroup
            label="First time importer?"
            size="small"
            disabled={disabled}
            readOnly={readOnly}
            options={[
              { value: UsIorFirstTimeStatus.YES, label: 'Yes' },
              { value: UsIorFirstTimeStatus.NO, label: 'No' },
              {
                value: UsIorFirstTimeStatus.UNKNOWN,
                label: "Don't Know",
              },
            ]}
            localNamePath={localNamePath}
            initialValue={
              firstTimeStatus ? undefined : UsIorFirstTimeStatus.YES
            }
            $inline
          />
          <StyledSpan>
            Phone and email are{' '}
            {firstTimeStatus === UsIorFirstTimeStatus.YES
              ? 'required'
              : 'optional'}
          </StyledSpan>
        </>
      );
    }}
  </Form.Item>
);

export default FirstTimeImporter;
