import { recordActionFields, recordFields } from '@xbcb/shared-queries';

export const userTeamFragment = `
  fragment userTeamFields on UserTeam {
    name
  }
`;

export const userTeamFields = userTeamFragment;

export const userTeamFragments = `
  ${userTeamFields}
  ${recordFields}
  ${recordActionFields}
`;
