import { NamePath } from 'rc-field-form/lib/interface';
import { FormInstance } from 'antd/lib/form';

type showProps = {
  readOnly?: boolean;
  form: FormInstance;
  field: NamePath;
};

export const show = ({ readOnly, form, field }: showProps) => {
  return Boolean(!readOnly || form.getFieldValue(field));
};
