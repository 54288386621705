import { RecordType } from '@xbcb/shared-types';

export enum ForwarderDocumentTag {
  SUB_POWER_OF_ATTORNEY_AUTHORITY = 'SUB_POWER_OF_ATTORNEY_AUTHORITY',
}

export enum ClassificationDocumentTag {
  BROCHURE = 'BROCHURE',
  MSDS = 'MSDS',
  OTHER = 'OTHER',
  PHOTOGRAPH = 'PHOTOGRAPH',
  SPEC_SHEET = 'SPEC_SHEET',
  TECH_PACK = 'TECH_PACK',
}

export enum ProductDocumentTag {
  BROCHURE = 'BROCHURE',
  MSDS = 'MSDS',
  OTHER = 'OTHER',
  PHOTOGRAPH = 'PHOTOGRAPH',
  SPEC_SHEET = 'SPEC_SHEET',
  TECH_PACK = 'TECH_PACK',
  // Generic document for proof of compliance. Used to tag documents from AFT.
  PROOF_OF_PRODUCT_COMPLIANCE = 'PROOF_OF_PRODUCT_COMPLIANCE',
}
export enum PowerOfAttorneyDocumentTag {
  MASTER_POWER_OF_ATTORNEY = 'MASTER_POWER_OF_ATTORNEY',
  SUB_POWER_OF_ATTORNEY = 'SUB_POWER_OF_ATTORNEY',
  DIRECT_REP_POWER_OF_ATTORNEY = 'DIRECT_REP_POWER_OF_ATTORNEY',
}
export enum ImporterOfRecordDocumentBaseTag {
  CONTINUOUS_BOND = 'CONTINUOUS_BOND',
  CORPORATE_CERTIFICATION = 'CORPORATE_CERTIFICATION',
  DIRECT_POWER_OF_ATTORNEY = 'DIRECT_POWER_OF_ATTORNEY',
  MONTHLY_DUTY_REPORT = 'MONTHLY_DUTY_REPORT',
  OFFICER_VERIFICATION = 'OFFICER_VERIFICATION',
  PERIODIC_MONTHLY_STATEMENT_APPLICATION = 'PERIODIC_MONTHLY_STATEMENT_APPLICATION',
  GOVERNMENT_ISSUED_PHOTO_ID = 'GOVERNMENT_ISSUED_PHOTO_ID',
  BUSINESS_LICENSE_OR_PERMIT = 'BUSINESS_LICENSE_OR_PERMIT',
  PARTNERSHIP_VERIFICATION = 'PARTNERSHIP_VERIFICATION',
  EIN_VERIFICATION = 'EIN_VERIFICATION',
  BOND_APPLICATION = 'BOND_APPLICATION',
  PVA = 'PVA',
  CREDIT_REPORT = 'CREDIT_REPORT',
  OTHER = 'OTHER',
}
export const ImporterOfRecordDocumentTag = {
  ...PowerOfAttorneyDocumentTag,
  ...ImporterOfRecordDocumentBaseTag,
};
export const ShipperDocumentTag = {
  ...ImporterOfRecordDocumentTag,
};

export enum UsDisDocumentCodeTag {
  PASSPORT_DL_GOVT_ISSUED_ID = 'PASSPORT_DL_GOVT_ISSUED_ID',
  NON_COMMERCIAL_INVOICE = 'NON_COMMERCIAL_INVOICE',
  STEEL_IMPORT_LICENSE = 'STEEL_IMPORT_LICENSE',
  BEEF_EXPORT = 'BEEF_EXPORT',
  DUTY_FREE_ENTRY = 'DUTY_FREE_ENTRY',
  ISF_BOND_TYPE_16 = 'ISF_BOND_TYPE_16',
  FREE_ENTRY_OF_RETURNED_AMERICAN_PROD = 'FREE_ENTRY_OF_RETURNED_AMERICAN_PROD',
  CBPF_4647 = 'CBPF_4647',
  CBPF_3229 = 'CBPF_3229',
  CBMA_PRODUCER_CERTIFICATE = 'CBMA_PRODUCER_CERTIFICATE',
  CBMA_IMPORTER_SPREADSHEET = 'CBMA_IMPORTER_SPREADSHEET',
  CBMA_CONTROLLED_GROUP = 'CBMA_CONTROLLED_GROUP',
  ENTRY_SUMMARY_PACKAGE = 'ENTRY_SUMMARY_PACKAGE',
  EPA_3520_1 = 'EPA_3520_1',
  EPA_3520_21 = 'EPA_3520_21',
  EPA_3540_01 = 'EPA_3540_01',
  EPA_PESTICIDE = 'EPA_PESTICIDE',
  EPA_V_E_EXEMPTION = 'EPA_V_E_EXEMPTION',
  EPA_NON_OBJECTION_NOTICE = 'EPA_NON_OBJECTION_NOTICE',
  EPA_IMPORT_OF_ODS = 'EPA_IMPORT_OF_ODS',
  EPA_TSCA = 'EPA_TSCA',
  EPA_CERTIFICATE_OF_ANALYSIS = 'EPA_CERTIFICATE_OF_ANALYSIS',
}

export enum DisDocumentCodeTag {
  PACKING_LIST = 'PACKING_LIST',
  COMMERCIAL_INVOICE = 'COMMERCIAL_INVOICE',
  OTHER = 'OTHER',
  PRODUCT_INFORMATION = 'PRODUCT_INFORMATION',
  BILL_OF_LADING = 'BILL_OF_LADING',
  INVOICE_WORKSHEET = 'INVOICE_WORKSHEET',
  GENERIC_DOCUMENT = 'GENERIC_DOCUMENT',
  COST_SUBMISSION = 'COST_SUBMISSION',
  DECLARATION_BY_IMPORTER = 'DECLARATION_BY_IMPORTER',
}

export enum EuShipmentDocumentTag {
  CONTRACT_ORDER = 'CONTRACT_ORDER',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  PVA_DOCUMENT = 'PVA_DOCUMENT',
  APPROVED_DOCUMENT = 'APPROVED_DOCUMENT',
  FREIGHT_SNAPSHOT = 'FREIGHT_SNAPSHOT',
  ASIN_LIST = 'ASIN_LIST',
}

export enum UsShipmentDocumentTag {
  CARGO_RELEASE = 'CARGO_RELEASE',
  CONFIDENTIAL = 'CONFIDENTIAL',
  MANUFACTURER_STATAEMENT_OF_USE = 'MANUFACTURER_STATAEMENT_OF_USE',
  HS_7 = 'HS_7',
  FDA_2877 = 'FDA_2877',
  FDA_CERTIFICATION_OF_REGISTRATION = 'FDA_CERTIFICATION_OF_REGISTRATION',
  FDA_DEVICE_INITIAL_IMPORTER_INFORMATION = 'FDA_DEVICE_INITIAL_IMPORTER_INFORMATION',
  TSCA_DECLARATION = 'TSCA_DECLARATION',
  FUMIGATION_CERTIFICATE = 'FUMIGATION_CERTIFICATE',
  SAFETY_DATA_SHEET = 'SAFETY_DATA_SHEET',
  VERIFIED_GROSS_MASS_CERTIFICATE = 'VERIFIED_GROSS_MASS_CERTIFICATE',
  MANIFEST_CONFIDENTIALITY_APPLICATION = 'MANIFEST_CONFIDENTIALITY_APPLICATION',
  GENERAL_CERTIFICATE_OF_CONFIRMITY = 'GENERAL_CERTIFICATE_OF_CONFIRMITY',
  INTERIM_FOOTWEAR_INVOICE = 'INTERIM_FOOTWEAR_INVOICE',
  FISH_AND_WILDLIFE_IMPORT_LICENSE = 'FISH_AND_WILDLIFE_IMPORT_LICENSE',
  PPQ_587 = 'PPQ_587',
  USDA_VS_16_3 = 'USDA_VS_16_3',
  PHYTOSANITARY_CERTIFICATES = 'PHYTOSANITARY_CERTIFICATES',
  PROCESSING_DECLARATION = 'PROCESSING_DECLARATION',
  ASSEMBLERS_DECLARATION = 'ASSEMBLERS_DECLARATION',
  PERSONAL_EFFECTS = 'PERSONAL_EFFECTS',
  FDA_NOTICE = 'FDA_NOTICE',
  LACEY_ACT = 'LACEY_ACT',
  IN_BOND_NOTES = 'IN_BOND_NOTES',
  IN_BOND_7512 = 'IN_BOND_7512',
  ENTRY_SUMMARY_7501 = 'ENTRY_SUMMARY_7501',
  ENTRY_SUMMARY_7501_PSC = 'ENTRY_SUMMARY_7501_PSC',
  DRAFT_ENTRY_SUMMARY_7501 = 'DRAFT_ENTRY_SUMMARY_7501',
  DRAFT_ENTRY_SUMMARY_7501_PSC = 'DRAFT_ENTRY_SUMMARY_7501_PSC',
  DAILY_STATEMENT = 'DAILY_STATEMENT',
  CF_28 = 'CF_28',
  CF_29_PROPOSED_ACTION = 'CF_29_PROPOSED_ACTION',
  CF_29_ACTION_TAKEN = 'CF_29_ACTION_TAKEN',
  LIQUIDATED_DAMAGES = 'LIQUIDATED_DAMAGES',
}

export enum ShipmentDocumentTag {
  MASTER_BILL_OF_LADING = 'MASTER_BILL_OF_LADING',
  HOUSE_BILL_OF_LADING = 'HOUSE_BILL_OF_LADING',
  ARRIVAL_NOTICE = 'ARRIVAL_NOTICE',
  BILL_OF_LADING = 'BILL_OF_LADING',
  CERTIFICATE_OF_ORIGIN = 'CERTIFICATE_OF_ORIGIN',
  COMMERCIAL_INVOICE = 'COMMERCIAL_INVOICE',
  EXAM_CHARGES = 'EXAM_CHARGES',
  INSPECTION_REPORT = 'INSPECTION_REPORT',
  LIQUIDATED_DAMAGES = 'LIQUIDATED_DAMAGES',
  PERIODIC_MONTHLY_STATEMENT_APPLICATION = 'PERIODIC_MONTHLY_STATEMENT_APPLICATION',
  TESTING_CERTIFICATE = 'TESTING_CERTIFICATE',
  PACKING_LIST = 'PACKING_LIST',
  EXPORT_LICENSE = 'EXPORT_LICENSE',
  DANGEROUS_GOODS_DECLARATION = 'DANGEROUS_GOODS_DECLARATION',
  CARRIER_CONFIRMATION = 'CARRIER_CONFIRMATION',
  SHIPPING_INSTRUCTIONS = 'SHIPPING_INSTRUCTIONS',
  FORWARDERS_CARGO_RECEIPT = 'FORWARDERS_CARGO_RECEIPT',
  CHILDREN_PRODUCT_CERTIFICATE = 'CHILDREN_PRODUCT_CERTIFICATE',
  LABEL_APPROVAL = 'LABEL_APPROVAL',
  OTHER_PGA_DOCUMENT = 'OTHER_PGA_DOCUMENT',
  ISF_WORKSHEET = 'ISF_WORKSHEET',
}

export enum DeliveryOrderDocumentTag {
  DELIVERY_ORDER_FORM = 'DELIVERY_ORDER_FORM',
}

export enum NRBSDocumentTag {
  NON_REIMBURSEMENT_STATEMENT_FORM = 'NON_REIMBURSEMENT_STATEMENT_FORM',
  NON_REIMBURSEMENT_STATEMENT_CERTIFICATE = 'NON_REIMBURSEMENT_STATEMENT_CERTIFICATE',
}

export enum EuCustomsEntryConfirmationDocumentTag {
  CUSTOMS_ENTRY = 'CUSTOMS_ENTRY',
  CUSTOMS_ENTRY_DRAFT = 'CUSTOMS_ENTRY_DRAFT',
  SELLER_PAYMENT_BANK_SLIP = 'SELLER_PAYMENT_BANK_SLIP',
  COMMUNICATION_HISTORY = 'COMMUNICATION_HISTORY',
  VALUATION_BREAKDOWN = 'VALUATION_BREAKDOWN',
}

export enum InCustomsDeclarationDocumentBaseTag {
  // Amazon -> DHL IN (Pre-entry document exchange)
  IMPORT_FILE = 'IMPORT_FILE',
  COMMERCIAL_INVOICE = 'COMMERCIAL_INVOICE',
  PACKING_LIST = 'PACKING_LIST',
  PROFORMA_INVOICE = 'PROFORMA_INVOICE',
  ARRIVAL_NOTICE = 'ARRIVAL_NOTICE',
  DSN_LIST = 'DSN_LIST', // (Devices Serial Number)
  BILL_OF_LADING = 'BILL_OF_LADING', // HAWB
  BIS_PRODUCT_REGISTRATION = 'BIS_PRODUCT_REGISTRATION', // (Bureau of Indian Standards)
  EQUIPMENT_TYPE_APPROVAL = 'EQUIPMENT_TYPE_APPROVAL', // (ETA)
  IMPORT_PERMIT = 'IMPORT_PERMIT',
  DUTY_PAYMENT_UNDER_PROTEST_INTIMATION_LETTER = 'DUTY_PAYMENT_UNDER_PROTEST_INTIMATION_LETTER',

  // Amazon -> DHL IN (Post-entry document exchange)
  E_CHALLAN = 'E_CHALLAN',

  // DHL IN -> Amazon
  DRAFT_CUSTOMS_DECLARATION = 'DRAFT_CUSTOMS_DECLARATION',
  CUSTOMS_DECLARATION = 'CUSTOMS_DECLARATION',
  BILL_OF_ENTRY = 'BILL_OF_ENTRY',
  OUT_OF_CHARGE_BILL_OF_ENTRY = 'OUT_OF_CHARGE_BILL_OF_ENTRY',
  GATEPASS = 'GATEPASS',
  DUTY_REQUEST_FORM = 'DUTY_REQUEST_FORM',
  BULK_MILESTONE = 'BULK_MILESTONE',
}

export const InCustomsDeclarationDocumentTag = {
  ...InCustomsDeclarationDocumentBaseTag,
};

export enum JpCustomsDeclarationDocumentTag {
  DRAFT_CUSTOMS_DECLARATION = 'DRAFT_CUSTOMS_DECLARATION',
  CUSTOMS_DECLARATION = 'CUSTOMS_DECLARATION',
  BILL_OF_LADING = 'BILL_OF_LADING',
  COMMERCIAL_INVOICE = 'COMMERCIAL_INVOICE',
  PACKING_LIST = 'PACKING_LIST',
  ARRIVAL_NOTICE = 'ARRIVAL_NOTICE',
  PRODUCT_LIST = 'PRODUCT_LIST',
  IMPORTER_AGENT = 'IMPORTER_AGENT',
  DIRECT_POWER_OF_ATTORNEY = 'DIRECT_POWER_OF_ATTORNEY',
  BUSINESS_LICENSE_OR_PERMIT = 'BUSINESS_LICENSE_OR_PERMIT',
  BILL_OF_ENTRY = 'BILL_OF_ENTRY',
  PRODUCT_INFORMATION = 'PRODUCT_INFORMATION',
  INSPECTION_REPORT = 'INSPECTION_REPORT',
  TESTING_CERTIFICATE = 'TESTING_CERTIFICATE',
  OTHER_PGA_DOCUMENT = 'OTHER_PGA_DOCUMENT',
  ENTRY_SUMMARY_PACKAGE = 'ENTRY_SUMMARY_PACKAGE',
  EXAM_CHARGES = 'EXAM_CHARGES',
  LIQUIDATED_DAMAGES = 'LIQUIDATED_DAMAGES',
  INVOICE = 'INVOICE',
  OTHER = 'OTHER',
  SHIPPING_INSTRUCTIONS = 'SHIPPING_INSTRUCTIONS',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  COST_SUBMISSION = 'COST_SUBMISSION',
  GENERIC_DOCUMENT = 'GENERIC_DOCUMENT',
  CERTIFICATE_OF_ORIGIN = 'CERTIFICATE_OF_ORIGIN',
  DANGEROUS_GOODS_DECLARATION = 'DANGEROUS_GOODS_DECLARATION',
  EXPORT_LICENSE = 'EXPORT_LICENSE',
  VALUATION_BREAKDOWN = 'VALUATION_BREAKDOWN',
  FORWARDERS_CARGO_RECEIPT = 'FORWARDERS_CARGO_RECEIPT',
  CARRIER_CONFIRMATION = 'CARRIER_CONFIRMATION',
  CHILDREN_PRODUCT_CERTIFICATE = 'CHILDREN_PRODUCT_CERTIFICATE',
  COMMUNICATION_HISTORY = 'COMMUNICATION_HISTORY',
  CONTRACT_ORDER = 'CONTRACT_ORDER',
  DECLARATION_BY_IMPORTER = 'DECLARATION_BY_IMPORTER',
  LABEL_APPROVAL = 'LABEL_APPROVAL',
  SELLER_PAYMENT_BANK_SLIP = 'SELLER_PAYMENT_BANK_SLIP',
}

export const EuCustomsEntryDocumentTag = {
  ...PowerOfAttorneyDocumentTag,
  ...ShipmentDocumentTag,
  ...EuShipmentDocumentTag,
  ...DisDocumentCodeTag,
  ...EuCustomsEntryConfirmationDocumentTag,
};

export const UsConsumptionEntryDocumentTag = {
  ...UsShipmentDocumentTag,
  ...ShipmentDocumentTag,
  ...UsDisDocumentCodeTag,
  ...DisDocumentCodeTag,
  ...NRBSDocumentTag,
};

export const UsType86EntryDocumentTag = {
  ...UsShipmentDocumentTag,
  ...ShipmentDocumentTag,
  ...UsDisDocumentCodeTag,
  ...DisDocumentCodeTag,
};

export const UsPostSummaryCorrectionDocumentTag = {
  ...UsShipmentDocumentTag,
  ...ShipmentDocumentTag,
  ...UsDisDocumentCodeTag,
  ...DisDocumentCodeTag,
};

export const CbpDocumentLabel = {
  ...UsDisDocumentCodeTag,
  ...DisDocumentCodeTag,
};

export enum InvoiceDocumentTag {
  DUTY_DAILY_STATEMENT = 'DUTY_DAILY_STATEMENT',
  DUTY_MONTHLY_STATEMENT = 'DUTY_MONTHLY_STATEMENT',
  INLT_SUBSCRIPTION = 'INLT_SUBSCRIPTION',
  SHIPMENT_INVOICE = 'SHIPMENT_INVOICE',
  SERVICES_STATEMENT = 'SERVICES_STATEMENT',
  WORK_ORDER_GROUP_INVOICE = 'WORK_ORDER_GROUP_INVOICE',
  IOR_INVOICE = 'IOR_INVOICE',
  INVOICE = 'INVOICE',
  CREDIT_NOTE_INVOICE = 'CREDIT_NOTE_INVOICE',
  UNPLANNED_CHARGE_PROOF = 'UNPLANNED_CHARGE_PROOF',
  REQUEST_FOR_PAYMENT = 'REQUEST_FOR_PAYMENT',
}

export enum DrawbackDocumentBaseTag {
  DRAWBACK_CLAIM_RESPONSE = 'DRAWBACK_CLAIM_RESPONSE',
}
export const DrawbackDocumentTag = {
  ...DrawbackDocumentBaseTag,
  ...UsDisDocumentCodeTag,
  ...DisDocumentCodeTag,
};

export enum UsIorContinuousBondRequestDocumentTag {
  CONTINUOUS_BOND = 'CONTINUOUS_BOND',
  FINANCIAL_STATEMENTS = 'FINANCIAL_STATEMENTS',
  BOND_APPLICATION = 'BOND_APPLICATION',
  BROKER_OF_RECORD_CHANGE_LETTER = 'BROKER_OF_RECORD_CHANGE_LETTER',
  LETTER_OF_CREDIT = 'LETTER_OF_CREDIT',
  GENERAL_INDEMNITY_AGREEMENT = 'GENERAL_INDEMNITY_AGREEMENT',
  OTHER = 'OTHER',
}

// added for backward compatibility
export enum DeprecatedDocumentTag {
  SELLER_PAYMENT_BACK_SLIP = 'SELLER_PAYMENT_BACK_SLIP',
}

export const DocumentTag = {
  ...ClassificationDocumentTag,
  ...ForwarderDocumentTag,
  ...InvoiceDocumentTag,
  ...ProductDocumentTag,
  ...UsShipmentDocumentTag,
  ...ShipmentDocumentTag,
  ...UsDisDocumentCodeTag,
  ...DisDocumentCodeTag,
  ...ImporterOfRecordDocumentTag,
  ...DrawbackDocumentBaseTag,
  ...DeliveryOrderDocumentTag,
  ...UsIorContinuousBondRequestDocumentTag,
  ...EuShipmentDocumentTag,
  ...EuCustomsEntryConfirmationDocumentTag,
  ...NRBSDocumentTag,
  ...DeprecatedDocumentTag,
  ...InCustomsDeclarationDocumentTag,
  ...JpCustomsDeclarationDocumentTag,
};

export type DocumentTag =
  | ClassificationDocumentTag
  | ForwarderDocumentTag
  | InvoiceDocumentTag
  | ProductDocumentTag
  | ShipmentDocumentTag
  | UsShipmentDocumentTag
  | UsDisDocumentCodeTag
  | DisDocumentCodeTag
  | PowerOfAttorneyDocumentTag
  | ImporterOfRecordDocumentBaseTag
  | DrawbackDocumentBaseTag
  | DeliveryOrderDocumentTag
  | UsIorContinuousBondRequestDocumentTag
  | EuShipmentDocumentTag
  | EuCustomsEntryConfirmationDocumentTag
  | NRBSDocumentTag
  | DeprecatedDocumentTag
  | InCustomsDeclarationDocumentBaseTag
  | JpCustomsDeclarationDocumentTag;

export const DocumentTagByRecordType = {
  [RecordType.CLASSIFICATION]: Object.keys(ClassificationDocumentTag),
  [RecordType.FORWARDER]: Object.keys(ForwarderDocumentTag),
  [RecordType.INVOICE]: Object.keys(InvoiceDocumentTag),
  [RecordType.PRODUCT]: Object.keys(ProductDocumentTag),
  [RecordType.SHIPMENT]: [
    ...Object.keys(ShipmentDocumentTag),
    ...Object.keys(UsShipmentDocumentTag),
  ],
  [RecordType.US_CONSUMPTION_ENTRY]: Object.keys(UsConsumptionEntryDocumentTag),
  [RecordType.US_POST_SUMMARY_CORRECTION]: Object.keys(
    UsPostSummaryCorrectionDocumentTag,
  ),
  [RecordType.US_CONSIGNEE]: Object.keys(ImporterOfRecordDocumentTag),
  [RecordType.US_IOR]: Object.keys(ImporterOfRecordDocumentTag),
  [RecordType.DE_IOR]: Object.keys(ImporterOfRecordDocumentTag),
  [RecordType.GB_IOR]: Object.keys(ImporterOfRecordDocumentTag),
  [RecordType.NL_IOR]: Object.keys(ImporterOfRecordDocumentTag),
  [RecordType.FR_IOR]: Object.keys(ImporterOfRecordDocumentTag),
  [RecordType.US_IOR_ACTIVATION]: Object.keys(ImporterOfRecordDocumentTag),
  [RecordType.SHIPPER]: Object.keys(ShipperDocumentTag),
  [RecordType.TEMPLATE_DOCUMENT_SIGN_REQUEST]: Object.keys(
    ImporterOfRecordDocumentTag,
  ),
  [RecordType.US_ISF]: [
    ...Object.keys(ShipmentDocumentTag),
    ...Object.keys(UsShipmentDocumentTag),
  ],
  [RecordType.US_IN_BOND]: [
    ...Object.keys(ShipmentDocumentTag),
    ...Object.keys(UsShipmentDocumentTag),
  ],
  [RecordType.US_DRAWBACK_CLAIM]: Object.keys(DrawbackDocumentTag),
  [RecordType.DELIVERY_ORDER]: Object.keys(DeliveryOrderDocumentTag),
  [RecordType.US_IOR_CONTINUOUS_BOND_REQUEST]: Object.keys(
    UsIorContinuousBondRequestDocumentTag,
  ),
  [RecordType.NL_CUSTOMS_ENTRY]: Object.keys(EuCustomsEntryDocumentTag),
  [RecordType.FR_CUSTOMS_ENTRY]: Object.keys(EuCustomsEntryDocumentTag),
  [RecordType.GB_CUSTOMS_ENTRY]: Object.keys(EuCustomsEntryDocumentTag),
  [RecordType.DE_CUSTOMS_ENTRY]: Object.keys(EuCustomsEntryDocumentTag),
  [RecordType.US_TYPE86_ENTRY]: Object.keys(UsType86EntryDocumentTag),
  [RecordType.GB_IOR_ACTIVATION]: Object.keys(ImporterOfRecordDocumentTag),
  [RecordType.DE_IOR_ACTIVATION]: Object.keys(ImporterOfRecordDocumentTag),
  [RecordType.NL_IOR_ACTIVATION]: Object.keys(ImporterOfRecordDocumentTag),
  [RecordType.FR_IOR_ACTIVATION]: Object.keys(ImporterOfRecordDocumentTag),
};

export type DocumentTagByRecordType = typeof DocumentTagByRecordType;

export type CountryLevelRecordDocumentTagConfiguration = Record<
  string, // RecordType
  Record<string, string[]> // Country code, DocumentTag[]
>;

// Used to map RecordType to a set of country-level DocumentTags
export const CountryLevelRecordDocumentTags: CountryLevelRecordDocumentTagConfiguration =
  {
    [RecordType.CUSTOMS_DECLARATION]: {
      IN: Object.keys(InCustomsDeclarationDocumentTag),
      JP: Object.keys(JpCustomsDeclarationDocumentTag),
    },
  };
