import { CrudPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const ShipperUserShipperUserPermissionName = {
  ...CrudPermissionName,
};

export default {
  [ObjectType.SHIPPER_USER]: ShipperUserShipperUserPermissionName,
};
