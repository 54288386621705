import { Invoice } from '@xbcb/api-gateway-client';
import { AccountingSystemReferenceType } from '@xbcb/finance-types';

export const getInvoiceId = (invoice: Invoice): string | undefined => {
  const accountingSystemReferences =
    invoice.accountingSystemDetails?.references;
  return accountingSystemReferences?.find(
    (ref) => ref.type === AccountingSystemReferenceType.AR_INVOICE_ID,
  )?.value;
};
