import { decrypt } from '@xbcb/js-utils';
import { QueryResult } from '@apollo/client';
import { get, find } from 'lodash';

export type EnvironmentVariables = { [key: string]: string };
export const environmentVariables: EnvironmentVariables = {};

export const processCustomDomainRuntimeInfo = (
  queryResult: QueryResult,
): void => {
  const encryptedEnvironmentVariables = get(queryResult, [
    'data',
    'customDomainRuntimeInfo',
    'environmentVariables',
  ]);
  const encryptionKeyName = 'CUSTOM_DOMAIN_ENVIRONMENT_VARIABLES_KEY';
  const encryptionKeyValue = get(
    find(encryptedEnvironmentVariables, {
      key: encryptionKeyName,
    }),
    'value',
  );
  encryptedEnvironmentVariables.forEach(
    ({ key, value }: { key: string; value: string }) => {
      if (key !== encryptionKeyName) {
        environmentVariables[key] = decrypt(
          atob(value),
          atob(encryptionKeyValue),
        );
      }
    },
  );
};
