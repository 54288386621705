import React from 'react';
// import GA from 'react-ga';
import { StyledDiv } from './styles';

class NotFound extends React.Component {
  componentDidMount = () => {
    // GA.exception({
    //   description: 'Page not found',
    // });
  };

  render = () => (
    <div className="content">
      <div className="content-header">
        <h1>Sorry, page not found!</h1>
      </div>
      <StyledDiv className="content-main" />
    </div>
  );
}

export default NotFound;
