import { SortOrder, AnyObject } from '@xbcb/shared-types';
import { EsDataType } from '../../index';
import { shipperName, shipperId } from './party';
import { createTableConfig } from '../createTableConfig';
import { ActivationStatus } from '@xbcb/party-types';

export const importerEntityColumns: AnyObject = {
  shipperName,
  shipperId,
  identifier: {
    name: 'Identifier',
    type: EsDataType.KEYWORD,
  },
  activationStatus: {
    name: 'Activation Status',
    type: EsDataType.KEYWORD,
    default: true,
    optionValues: Object.values(ActivationStatus),
  },
  activationTime: {
    name: 'Activation Time',
    type: EsDataType.TIME,
  },
  countryCode: {
    name: 'Country Code',
    type: EsDataType.KEYWORD,
    default: true,
  },
};

export const importerEntity = createTableConfig({
  columns: importerEntityColumns,
  tableOptions: {
    sortOrder: SortOrder.DESC,
  },
});
