export enum DocumentPreviewStatus {
  PENDING = 'PENDING',
  PENDING_UPLOAD = 'PENDING_UPLOAD',
  UPLOADED = 'UPLOADED',
  FAILED = 'FAILED',
  SKIPPED = 'SKIPPED',
}
export enum MutableDocumentPreviewStatus {
  FAILED = 'FAILED',
  SKIPPED = 'SKIPPED',
}
