import React from 'react';
import { StyledTd, StyledLabel, StyledTooltip } from './styles';

type DatumProps = {
  label?: React.ReactNode;
  value?: React.ReactNode;
  $bold?: boolean;
  tooltip?: React.ReactNode;
  forceShow?: boolean;
  link?: string;
};

// could use a refactor
const Datum = ({
  label,
  value,
  $bold,
  tooltip,
  forceShow,
  link,
}: DatumProps) => {
  if (!value && !forceShow) return null;
  const finalValue = link ? (
    <a href={link} target="_blank" rel="noopener noreferrer">
      {value}
    </a>
  ) : (
    value
  );
  const valTD = <StyledTd $bold={$bold}>{finalValue}</StyledTd>;
  const val = tooltip ? (
    <StyledTooltip placement="right" title={tooltip}>
      {valTD}
    </StyledTooltip>
  ) : (
    valTD
  );
  return (
    <tr>
      <StyledLabel $bold={$bold}>{label ? `${label}:` : ''}</StyledLabel>
      {val}
    </tr>
  );
};

export default Datum;
