import {
  UsConsumptionEntryCommercialInvoice,
  UsPostSummaryCorrectionCommercialInvoiceInput,
} from '@xbcb/api-gateway-client';
import { transformUsConsumptionEntryProductsToUsPostSummaryCorrectionProductInputs } from './transformUsConsumptionEntryProductsToUsPostSummaryCorrectionProductInputs';

export const transformUsConsumptionEntryInvoicesToUsPostSummaryCorrectionInvoiceInputs =
  (
    invoices: UsConsumptionEntryCommercialInvoice[] | undefined,
  ): UsPostSummaryCorrectionCommercialInvoiceInput[] | undefined =>
    invoices?.map(
      ({
        currencyRate,
        invoiceNumber,
        value,
        seller,
        adjustments,
        products,
      }) => {
        const invoice: UsPostSummaryCorrectionCommercialInvoiceInput = {
          currencyRate,
          invoiceNumber,
          value,
          // Despite being an object type, `adjustments` is a shared type
          // (`UsConsumptionEntryCommercialInvoiceAdjustmentInput[]`) so we
          // will just do a direct copy, no need to map it
          adjustments,
        };
        if (seller) {
          const { address, mid, name, supplier } = seller;
          invoice.seller = {
            address,
            mid,
            name,
          };
          if (supplier) {
            const { id, version } = supplier;
            invoice.seller.supplier = { id, version };
          }
        }
        invoice.products =
          transformUsConsumptionEntryProductsToUsPostSummaryCorrectionProductInputs(
            products,
          );
        return invoice;
      },
    );
