import {
  userFragments,
  userWorkDayFields,
  userWorkingHoursFields,
  operatorUserPermissionFields,
  userNotificationFields,
} from './user';

const operatorUserFragment = `
fragment operatorUserFields on OperatorUser {
  operator {
    id
  }
  dailyGoal
  skillLevel
  workingHours {
    ...userWorkingHoursFields
  }
  assignmentConfig {
    taskConfigs {
      experienceScore
      timePercentage
      workOrderTaskDefinition {
        id
        workOrderTaskType
      }
      usCbpIndustryVerticals
      modesOfTransport
    }
  }
  assignmentTeams {
    edges {
      node {
        id
        backupTeam {
          id
        }
      }
      relationship {
        roles
      }
    }
  }
  subjectMatterExpertTeams {
    nodes {
      id
    }
  }
  ${operatorUserPermissionFields}
  ${userNotificationFields}
  ...userFields
  ...recordFields
}
`;

export const operatorUserFragments = `
  ${operatorUserFragment}
  ${userWorkingHoursFields}
  ${userWorkDayFields}
  ${userFragments}
`;
