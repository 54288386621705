import {
  calculateDifferenceInHours,
  businessTimeDiff,
  getMomentWithHour,
  isWorkingDay,
  convertUtcToEst,
  convertEstToUtc,
  shiftDetailsinEST,
} from './businessTimeDiff';
import moment from 'moment';
import { AnyObject } from '@xbcb/shared-types';

const setMomentToSpecificHour = (momentObj: moment.Moment, hour: number) =>
  momentObj.startOf('day').hours(hour);

const evaluateShiftStartForNextBizDay = (
  momentObj: moment.Moment,
  weekend: AnyObject,
  shiftStartHour: number,
) => {
  momentObj.add(1, 'days');
  while (!isWorkingDay(momentObj, weekend)) {
    momentObj.add(1, 'days');
  }
  setMomentToSpecificHour(momentObj, shiftStartHour);
};

/** *
 * Adds business hours to a UTC moment
 * @param {Object} time - Starting time in UTC (moment object)
 * @param {number} businessHours - The amount of business hours to add to the starting time
 * @param {boolean} endOfShift - (Optional: Default = true) If the remaining time for the business hour calculation will cause the time to rollover to the first second at the start of the next business day, this flag indicates that the final time should instead be set to the last second of the current working shift
 * @return {Object} convertedTime - Converted time in UTC
 */
export const businessTimeAdd = (
  time: moment.Moment,
  businessHours: number,
  endOfShift = true,
) => {
  if (businessHours === 0) return time;
  if (!time) throw new Error('Missing time in businessTimeAdd');
  if (!moment.isMoment(time)) new Error('time is not a moment object');
  if (!businessHours) throw new Error('Missing hours in businessTimeAdd');
  const convertedTime = convertUtcToEst(time);
  const { weekend, shiftStartHour, shiftEndHour } = shiftDetailsinEST;
  const shiftStartMoment = getMomentWithHour(convertedTime, shiftStartHour);
  const shiftEndMoment = getMomentWithHour(convertedTime, shiftEndHour);
  const shiftDuration = calculateDifferenceInHours(
    shiftStartMoment,
    shiftEndMoment,
  );
  let shiftEndTime = getMomentWithHour(convertedTime, shiftEndHour);
  let remainingBusinessHours = Math.abs(businessHours);
  let timeRemainingInWorkShift;
  let hoursToAddToLastDay;
  while (remainingBusinessHours > 0) {
    if (isWorkingDay(convertedTime, weekend)) {
      if (convertedTime.hours() >= shiftEndHour) {
        timeRemainingInWorkShift = 0;
      } else if (convertedTime.hours() < shiftStartHour) {
        timeRemainingInWorkShift = shiftDuration;
        // After converting to the initial input to EST, it's possible for the convertedTime
        // to still be at some point in time on a business day before the actual start of the
        // shift (e.g. work order marked ready by external customer at 10 PM PST (1 AM EST)).
        // Therefore, we need to set the converted (EST) time to the start of the shift. Otherwise,
        // it will result in an incorrect time because we will mistakenly add business hours to a
        // moment that occurs during off-business hours.
        setMomentToSpecificHour(convertedTime, shiftStartHour);
      } else {
        const utcConvertedTime = convertEstToUtc(convertedTime);
        const utcShiftEndTime = convertEstToUtc(shiftEndTime);
        timeRemainingInWorkShift = businessTimeDiff(
          utcConvertedTime,
          utcShiftEndTime,
        );
      }
      if (remainingBusinessHours < timeRemainingInWorkShift) {
        hoursToAddToLastDay = remainingBusinessHours;
      } else if (
        endOfShift &&
        timeRemainingInWorkShift === remainingBusinessHours &&
        getMomentWithHour(convertedTime, shiftEndHour).isAfter(convertedTime)
      ) {
        convertedTime.startOf('day').hours(shiftEndHour);
        break; // we have set the time to the end of the current shift, no more to do
      } else {
        evaluateShiftStartForNextBizDay(convertedTime, weekend, shiftStartHour);
      }
      remainingBusinessHours -= timeRemainingInWorkShift;
    } else {
      evaluateShiftStartForNextBizDay(convertedTime, weekend, shiftStartHour);
    }
    shiftEndTime = getMomentWithHour(convertedTime, shiftEndHour);
  }
  convertedTime.add(hoursToAddToLastDay, 'hours');
  return convertEstToUtc(convertedTime);
};
