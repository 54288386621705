export const poaSubject = 'Your signature is needed';
export const ccSubject = 'Your signature is needed';
export const importerAccessSubject = "You've been invited to our new portal";
export const poaBody =
  'Hello,\n\nWe are excited to begin using new technology that will give us better insight into your shipment’s U.S. Customs release status and a handful of other operational benefits. Please electronically sign the Power of Attorney at the link below to allow us to make this change.';
export const ccBody =
  'Hello,\n\nWe are excited to begin using new technology that will give us better insight into your shipment’s U.S. Customs release status and a handful of other operational benefits. Please electronically sign the Power of Attorney at the link below to allow us to make this change.';
export const importerAccessBody = 'Click the link below to log in.';

export const getMailTo = (
  // TODO improve this type and share it with the UI
  type: string,
  url: string,
  userName?: string,
) => {
  let emailSubject;
  let emailBody;
  if (type.includes('poa')) {
    emailSubject = encodeURIComponent(poaSubject);
    emailBody = poaBody;
  } else if (type === 'cc') {
    emailSubject = encodeURIComponent(ccSubject);
    emailBody = ccBody;
  } else if (type === 'importerAccess') {
    emailSubject = encodeURIComponent(importerAccessSubject);
    emailBody = importerAccessBody;
  }

  if (url) emailBody += `\n\n${url}`;
  if (userName) emailBody += `\n\n${userName}`;
  emailBody = encodeURIComponent(emailBody || '');
  return `mailto:?subject=${emailSubject}&body=${emailBody}`;
};
