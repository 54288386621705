export const memoRecordActionFields = `
fragment memoRecordActionFields on RecordAction {
  source {
    __typename
    ... on InternalSystem {
      internalSystemName: name
    }
    ... on InternalWebsite {
      internalWebsiteName: name
    }
    ... on ClientSystem {
      clientSystemName: name
    }
  }
  time
  reason {
    code
    text
  }
  client {
    id
    name
  }
  request {
    ipAddress
    userAgent
    requestId
  }
}`;

const memoFragment = `
fragment memoFields on Memo {
  text
  isPublic
  operator {
    id
  }
  created {
    ...memoRecordActionFields
  }
  updated {
    ...memoRecordActionFields
  }
  deleted {
    ...memoRecordActionFields
  }
  id
  version
  tags {
    key
    value
  }
}
`;

export const memoFragments = `${memoFragment}${memoRecordActionFields}`;
