import { LoadType, ModeOfTransport } from '@xbcb/shared-types';
import {
  CbpInBondEntryType,
  CbpInBondStatus,
  UsCbpEntryModeOfTransport,
  WorkOrderBadge,
} from '@xbcb/work-order-types';
import { EsDataType } from '../../enums';
import { createShipmentWorkOrderRecord } from './utils';

export const usInBond = createShipmentWorkOrderRecord({
  columns: {
    entryDate: {
      name: 'Entry Type',
      type: EsDataType.TIME,
      // These field is in the process of being deprecated, we shouldn't show
      // it in the UI
      hidden: true,
    },
    conveyanceName: {
      name: 'Conveyance Name',
      type: EsDataType.TEXT,
    },
    tripNumber: { name: 'Voyage #', type: EsDataType.KEYWORD, default: false },
    portOfLadingName: {
      name: 'Port of Lading',
      type: EsDataType.TEXT,
    },
    portOfUnladingName: {
      name: 'Port of Unlading',
      type: EsDataType.TEXT,
    },
    departureCountry: {
      name: 'Departure Country Code',
      type: EsDataType.KEYWORD,
    },
    containerNumber: {
      name: 'Container #',
      type: EsDataType.TEXT,
    },
    masterBill: { name: 'Master Bill #', type: EsDataType.TEXT, default: true },
    modeOfTransport: {
      name: 'Mode of Transport',
      type: EsDataType.KEYWORD,
      optionValues: Object.keys(ModeOfTransport),
    },
    iorName: { name: 'IOR Name', type: EsDataType.TEXT, default: true },
    consigneeId: {
      name: 'Consignee ID',
      type: EsDataType.KEYWORD,
    },
    consigneeName: {
      name: 'Consignee Name',
      type: EsDataType.TEXT,
    },
    iorNumber: { name: 'IOR Number', type: EsDataType.KEYWORD, default: false },
    entryType: {
      name: 'Entry Type',
      type: EsDataType.KEYWORD,
      optionValues: Object.keys(CbpInBondEntryType),
    },
    cbpInBondModeOfTransport: {
      name: 'CBP In-Bond Mode of Transport',
      type: EsDataType.KEYWORD,
      optionValues: Object.keys(UsCbpEntryModeOfTransport),
    },
    foreignPortOfDestination: {
      name: 'Foreign Port of Destination',
      type: EsDataType.KEYWORD,
    },
    goodsNowAt: {
      name: 'Goods now at',
      type: EsDataType.KEYWORD,
    },
    importingConveyanceArrivalPort: {
      name: 'Importing Conveyance Arrival Port',
      type: EsDataType.KEYWORD,
    },
    carrierCode: {
      name: 'Carrier Code',
      type: EsDataType.KEYWORD,
    },
    carrierEin: {
      name: 'Carrier EIN',
      type: EsDataType.KEYWORD,
    },
    number: {
      name: 'In-bond Number',
      type: EsDataType.TEXT,
    },
    cbpInBondStatus: {
      default: true,
      name: 'CBP In-Bond Status',
      type: EsDataType.KEYWORD,
      optionValues: Object.keys(CbpInBondStatus),
    },
    usPortOfDestination: {
      default: true,
      name: 'US Port of Destination',
      type: EsDataType.KEYWORD,
    },
    cbpInBondConsigneeId: {
      name: 'CBP In-Bond Consignee ID',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    cbpInBondConsigneeName: {
      name: 'CBP In-Bond Consignee Name',
      type: EsDataType.TEXT,
    },
    carrierTruckerId: {
      name: 'Carrier Trucker ID',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    carrierTruckerName: {
      name: 'Carrier Trucker Name',
      type: EsDataType.TEXT,
    },
    loadType: {
      name: 'Load Type',
      type: EsDataType.KEYWORD,
      optionValues: Object.keys(LoadType),
    },
    eta: {
      name: 'ETA',
      type: EsDataType.TIME,
    },
    badge: {
      name: 'Badge',
      type: EsDataType.KEYWORD,
      optionValues: Object.keys(WorkOrderBadge),
    },
  },
});
