import React, { createRef, useEffect } from 'react';
import { AnyObject } from '@xbcb/shared-types';
import { getCountryCodes, renderTemplate } from '@xbcb/ui-utils';
import { StyledRenderedHtmlIframe } from './styles';

const RenderedTemplate = ({
  html,
  data,
}: {
  html: string;
  data: AnyObject;
}) => {
  const ref = createRef<HTMLIFrameElement>();
  const countryCodes = getCountryCodes();

  useEffect(() => {
    // Return early specifically if `!ref.current` otherwise TS will error
    // since we try to set this field below
    if (!ref.current) return;
    const document = ref.current.contentWindow?.document;
    if (!document) return;
    const template = renderTemplate({
      countryCodes,
      html,
      data,
    });
    document.open();
    document.write(template);
    document.close();
    const height = document.body.clientHeight;
    document.body.style.overflow = 'hidden';
    ref.current.height = height.toString();
  }, [data, countryCodes, html, ref]);

  return <StyledRenderedHtmlIframe ref={ref} title="Document" />;
};

export default RenderedTemplate;
