import { pick } from 'lodash';

/**
 * promisified setTimeout
 * @module
 * @param {Number} ms
 * @return {Promise}
 */
export const timeout = (ms: number): Promise<void> =>
  new Promise<void>((resolve) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      resolve();
    }, ms);
    return id;
  });

/**
 * returns promise, or throws if it takes longer than the timeout in ms
 * @module
 * @param {Promise} promise
 * @param {number} ms
 * @return {Promise}
 */
export const promiseTimeout = <T>(
  promise: Promise<T>,
  ms: number,
): Promise<T> => {
  const maxTimeout = new Promise((_, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      // eslint-disable-next-line prefer-promise-reject-errors
      reject('Timed out in ' + ms + 'ms.');
    }, ms);
  });

  return Promise.race([promise, maxTimeout]) as Promise<T>;
};

/**
 * returns a cloned JSON object or array (equivalent to lodash.cloneDeep)
 * @module
 * @param {any} obj
 * @return {any}
 */
export const cloneJson = <T>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj));
};

export const base64 = (input: any) => {
  return Buffer.from(input, 'utf8').toString('base64');
};

export const unbase64 = (input: any) => {
  return Buffer.from(input, 'base64').toString('utf8');
};

export type IdVersion = { id: string; version: number };

// very useful for eventually consistent integration tests
export const pollWithDelay = async <T = any>(
  executor: Function,
  interval: number,
  maxAttempts: number,
): Promise<T> => {
  let attempts = 0;
  let errorMessage = 'Failed to poll result within allocated time.';
  const poller = async (resolve: any, reject: any) => {
    let result;
    try {
      result = await executor();
    } catch (error) {
      if (error?.message) {
        errorMessage = error.message;
      }
      result = null;
    }
    attempts++;
    if (result) {
      return resolve(result);
    } else if (maxAttempts && attempts === maxAttempts) {
      return reject(new Error(errorMessage));
    } else {
      setTimeout(poller, interval, resolve, reject);
    }
  };
  return new Promise(poller);
};

// used to extract an object of ONLY {id, version} from a full record
export const pickIdVersion = (node: IdVersion & { [key: string]: any }) =>
  pick(node, ['id', 'version']);

export const doesKeyExistInArray = (array: any, key: string) => {
  return array.some((innerObject: any) => innerObject && !!innerObject[key]);
};

export const doesKeyExistInObject = (object: any, pathToField: string) => {
  const keys = pathToField.split('.');
  let currentSubtree = Array.isArray(object) ? [...object] : { ...object };
  for (const key of keys) {
    const isCurrentSubtreeAnArray = Array.isArray(currentSubtree);
    const result = isCurrentSubtreeAnArray
      ? doesKeyExistInArray(currentSubtree, key)
      : !!currentSubtree[key];

    if (!result) {
      return false;
    }

    currentSubtree = isCurrentSubtreeAnArray
      ? currentSubtree
          .filter(Boolean)
          .map((item: any) => item[key])
          .flat()
      : currentSubtree[key];
  }
  return true;
};
