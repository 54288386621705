import { Columns, TableOptions, RecordColumns, EsDataType } from '../../index';
import { PartyRecordType } from '@xbcb/shared-types';
import { createPartyTable } from './createPartyTable';

const customsBrokerRecordType = [
  PartyRecordType.DE_CUSTOMS_BROKER,
  PartyRecordType.FR_CUSTOMS_BROKER,
  PartyRecordType.NL_CUSTOMS_BROKER,
  PartyRecordType.US_CUSTOMS_BROKER,
  PartyRecordType.UK_CUSTOMS_BROKER,
  PartyRecordType.BROKER,
];

const sharedCustomsBrokerColumns = {
  assignmentTeams: {
    hidden: true,
    name: 'Assignment Teams',
    type: EsDataType.KEYWORD,
  },
  userTeams: {
    hidden: true,
    name: 'User Teams',
    type: EsDataType.KEYWORD,
  },
};

export const createCustomsBrokerTableConfig = <TColumns extends Columns>({
  tableOptions = {},
  columns,
}: {
  tableOptions?: TableOptions;
  columns?: TColumns;
}): RecordColumns =>
  createPartyTable({
    columns: {
      ...columns,
      ...sharedCustomsBrokerColumns,
    },
    tableOptions,
  });

export const customsBroker = createCustomsBrokerTableConfig({
  tableOptions: { recordTypes: customsBrokerRecordType },
});
