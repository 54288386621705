import { partyFragments } from './party';

export const truckerFragment = `
fragment truckerFields on Trucker {
  ...recordFields
  ...partyFields
  scac
  ein
  cain
  dispatchPointOfContact {
    name
    title
    email
    phone {
      ...phoneFields
    }
    user {
      id
    }
  }
}
`;

export const truckerFragments = `
${truckerFragment}
${partyFragments}
`;
