import { EsDataType } from '../../.';
import { createTableConfig } from '../createTableConfig';

export const invoiceCreditNote = createTableConfig({
  columns: {
    creditTime: {
      default: true,
      name: 'Credit Time',
      type: EsDataType.TIME,
    },
    invoiceId: {
      default: true,
      name: 'Invoice Id',
      type: EsDataType.KEYWORD,
    },
    number: {
      default: true,
      name: 'Invoice Credit Note Doc #',
      type: EsDataType.KEYWORD,
    },
    status: {
      default: true,
      name: 'Credit Note Status',
      type: EsDataType.KEYWORD,
    },
  },
  tableOptions: {
    sortField: 'invoiceId',
  },
});
