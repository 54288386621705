import { ShipmentComplianceDetail } from '@xbcb/api-gateway-client';
import { ExternalComplianceReferenceSource } from '@xbcb/compliance-types';
import { createProductReference } from './constructProductReference';
import { sortObjectKeys } from '@xbcb/js-utils';

export const getComplianceRefKey = (
  complianceDetail: ShipmentComplianceDetail,
) => {
  const { complianceDetailsReference } = complianceDetail;
  // Todo: after devices testing/launch, else logic can be used for all cases after proper gamma testing (similar conditioning will need to remove in SW during map creation).
  // FBA use-case, source is AFT and will be null for others
  if (
    complianceDetailsReference.source === ExternalComplianceReferenceSource.AFT
  ) {
    return JSON.stringify(sortObjectKeys(complianceDetailsReference));
  } else {
    const productReference = createProductReference(complianceDetail);
    return JSON.stringify(sortObjectKeys(productReference));
  }
};

export const roundNumber = (num: number, precision: number): number => {
  return Number(num.toFixed(precision));
};
