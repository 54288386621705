import React from 'react';
import {
  IORNumberType,
  IORNumber,
  INLTSwitch as Switch,
  CBPNumberReasonSelect,
  ANNumber,
  PhoneNumber,
  Email,
  Structure,
  PickDate,
  CountrySelect,
  DUNSNumber,
  AppRadioGroup,
  FormItem,
} from '@xbcb/form-item-components';
import { getNumber, formatString } from '@xbcb/ui-utils';
import { RecordType, AccountType } from '@xbcb/shared-types';
import { passportNumberRegex } from '@xbcb/regex';
import { timeout } from '@xbcb/js-utils';
import { Form, Checkbox, Divider, Input, message } from 'antd';
import { FormInstance } from 'antd/lib/form';
import LocationForm from '../LocationForm';
import moment from 'moment';
import {
  StyledCompanyInfoDiv,
  StyledFirstTimeRequirementsSpan,
} from './styles';
import { CssSize } from '@xbcb/ui-types';

export interface CompanyInfoProps {
  form: FormInstance;
  allRequired?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  titleField?: string;
  ior?: boolean;
  hideHelpMeGetOne?: boolean;
  nameRequired?: boolean;
  countryRequired?: boolean;
  initialValues: any;
  importerCreate?: boolean;
  onCountrySelect?: any;
  editMode?: any;
  recordType?: any;
  accountType?: any;
}

const CompanyInfo = (props: CompanyInfoProps) => {
  const {
    form,
    readOnly = false,
    disabled = false,
    titleField: inTitleField,
    allRequired,
    nameRequired,
    ior,
    hideHelpMeGetOne,
    countryRequired,
    initialValues,
    importerCreate,
    onCountrySelect,
    editMode,
    recordType,
    accountType,
  } = props;
  const { getFieldValue, setFieldsValue, validateFields } = form;

  const handleStructureChange = () => {
    validateFields(['iorNumber']);
  };
  const handleIorTypeChange = () => {
    setFieldsValue({ iorNumber: undefined });
    validateFields(['iorNumber']);
  };
  const handleFirstTimeIORChange = async () => {
    await timeout(0);
    validateFields(['phone', 'email']);
  };
  const handleDBA = async () => {
    await timeout(1000);
    const invalidDBA = ['n/a', 'none', 'not applicable'];
    formatString(form, ['dba']);
    const value =
      form.getFieldValue('dba') && form.getFieldValue('dba').toLowerCase();
    if (invalidDBA.includes(value) || value === 'na') {
      form.setFieldsValue({ dba: '' });
      message.warning(
        'If there is no DBA, please leave this field blank. We removed this text on your behalf.',
      );
    }
  };

  const titleField = inTitleField || 'name';
  const countryCode = getFieldValue('countryCode');
  const structure = getFieldValue('structure');
  const isUS = countryCode === 'US';
  const countryUsesSsn = ['US', 'PR', 'VI'].includes(countryCode);
  const isIndividual = structure === 'Individual';
  const isSoleProp = structure === 'Sole Proprietorship';
  const showTypes: any = {
    cbp: !isUS,
    ssn: countryUsesSsn && (isIndividual || isSoleProp),
    ein: !isIndividual,
  };
  const shownKeys = Object.keys(showTypes).filter((x) => Boolean(showTypes[x]));
  const iorNumberType =
    getFieldValue('iorNumberType') || initialValues.iorNumberType;
  const showHelpMeGetOne = !hideHelpMeGetOne && ior && iorNumberType === 'cbp';
  if (shownKeys.length === 1) {
    if (iorNumberType !== shownKeys[0]) {
      // set to only available option
      setFieldsValue({ iorNumberType: shownKeys[0] });
    }
  } else {
    if (iorNumberType && !shownKeys.includes(iorNumberType)) {
      // set to a default
      setFieldsValue({ iorNumberType: shownKeys[0] });
    }
  }
  if (getFieldValue('cbpNumberHelp') && iorNumberType !== 'cbp') {
    setFieldsValue({ cbpNumberHelp: undefined });
  }
  const personalEffects = isIndividual && (
    <>
      <Form.Item name="personalEffects" valuePropName="checked">
        <Checkbox
          className="personal-effects"
          disabled={disabled}
          onChange={async () => {
            await timeout(0);
            validateFields();
          }}
        >
          The consignee imports personal effects
        </Checkbox>
      </Form.Item>
      {getFieldValue('personalEffects') && (
        <div>
          {!isUS && (
            <div className="form-inline remove-space-bottom">
              <Divider />
              <h3>Passport</h3>
              <ANNumber
                form={form}
                debounce={false}
                readOnly={readOnly}
                disabled={disabled}
                // TODO these may need updated once implemented
                localNamePath={['passportNumber']}
                fullNamePath={['passportNumber']}
                mask={getNumber}
                $itemSize={CssSize.TINY_SHORT}
                max={13}
                pattern={passportNumberRegex}
                label="Number"
                required={allRequired}
              />
              <CountrySelect
                readOnly={readOnly}
                disabled={disabled}
                localNamePath={['passportCountryCode']}
                $itemSize={CssSize.TINY}
                label="Country of Issuance"
                required={allRequired}
                foreignOnly
              />
            </div>
          )}
          <Divider />
          <h4 className="space-bottom">
            {isUS ? 'Last foreign address' : 'New US address'}
          </h4>
          <LocationForm
            // TODO this fullNamePath needs updated
            fullNamePath={isUS ? ['lastForeign'] : ['newUS']}
            foreignOnly={isUS}
            readOnly={readOnly}
            disabled={disabled}
            form={form}
            allRequired={allRequired}
            spaceBottom={false}
          />
        </div>
      )}
    </>
  );
  return (
    <StyledCompanyInfoDiv>
      <div className="form-inline">
        <FormItem
          $readOnly={readOnly}
          label="Company Full Legal Name"
          name={titleField}
          rules={[{ required: allRequired || nameRequired, message: ' ' }]}
          debounce
        >
          <Input
            disabled={disabled}
            onBlur={() => formatString(form, [titleField])}
          />
        </FormItem>
        {!isIndividual &&
          (!readOnly || getFieldValue('dba') || initialValues.dba) && (
            <FormItem
              label="Alternate Name (DBA)"
              name="dba"
              $readOnly={readOnly}
              debounce
            >
              <Input disabled={disabled} onBlur={handleDBA} />
            </FormItem>
          )}
      </div>
      {!editMode && (
        <>
          <AppRadioGroup
            label="First time importer?"
            localNamePath={['firstTimeIOR']}
            onChange={handleFirstTimeIORChange}
            size="small"
            disabled={disabled}
            options={[
              { value: 'yes', label: 'Yes' },
              { value: 'no', label: 'No' },
              { value: 'unknown', label: "Don't Know" },
            ]}
          />
          <StyledFirstTimeRequirementsSpan className="space-left">
            Phone and email are{' '}
            {getFieldValue('firstTimeIOR') === 'no' ? 'optional' : 'required'}
          </StyledFirstTimeRequirementsSpan>
        </>
      )}
      <Divider />
      {importerCreate && nameRequired && (
        <p className="space-bottom instructions">
          The fields below can be completed now by you, or left blank and
          completed later by the importer.
        </p>
      )}
      <h4 className="space-bottom">Mailing Address</h4>
      <LocationForm
        ior={ior}
        readOnly={readOnly}
        disabled={disabled}
        form={form}
        allRequired={allRequired}
        countryRequired={countryRequired}
        onCountrySelect={onCountrySelect}
        showType={true}
        spaceBottom={false}
        // TODO this name path likely needs update
        fullNamePath={[]}
      />
      <Divider />
      <h4>Physical Address</h4>
      <Switch
        onWord="Yes"
        offWord="No"
        label="Same as mailing address?"
        field="samePhysicalAddress"
        initialValue={
          typeof initialValues.samePhysicalAddress === 'undefined'
            ? Object.keys(initialValues.physical || {}).length
              ? false
              : true
            : initialValues.samePhysicalAddress
        }
      />
      {!getFieldValue('samePhysicalAddress') && (
        <LocationForm
          fullNamePath={['addresses', 'physical']}
          readOnly={readOnly}
          disabled={disabled}
          form={form}
          allRequired={allRequired}
          showType={true}
        />
      )}
      <Divider />
      <div
        className={`form-inline${
          !showHelpMeGetOne && !isIndividual ? ' remove-space-bottom' : '' // need padding when the checkboxes are visible.
        }`}
      >
        <Structure
          form={form}
          required={allRequired}
          onChange={handleStructureChange}
          readOnly={readOnly}
          disabled={disabled}
          localNamePath={['structure']}
        />
        {ior && (
          <>
            <IORNumber
              form={form}
              localNamePath={['iorNumber', 'value']}
              fullNamePath={['iorNumber', 'value']}
              hideTooltip
              type={
                getFieldValue('iorNumberType') || initialValues.iorNumberType
              }
              isUS={isUS}
              isIndividual={isIndividual}
              readOnly={readOnly}
              disabled={disabled || (!isUS && getFieldValue('cbpNumberHelp'))}
              required={
                allRequired && !(!isUS && getFieldValue('cbpNumberHelp'))
              }
            />
            <IORNumberType
              localNamePath={['iorNumber', 'type']}
              required={allRequired}
              showTypes={showTypes}
              disabled={disabled}
              onChange={handleIorTypeChange}
              // TODO this was previously using the className below, but
              // className is no longer supported as a prop
              // className={
              //   shownKeys.length === 1 ||
              //   (!countryCode && !structure) ||
              //   readOnly
              //     ? 'ior-number-type hidden'
              //     : 'ior-number-type'
              // }
            />
          </>
        )}
        <ANNumber
          form={form}
          debounce={true}
          // TODO these may need updated once implemented
          localNamePath={['payerUnitNumber']}
          fullNamePath={['payerUnitNumber']}
          disabled={disabled}
          mask={getNumber}
          $itemSize={CssSize.TINY}
          max={6}
          label="Payer Unit Number"
          onChange={async (num: any) => {
            await timeout(0);
            if (num === '036968') {
              form.setFieldsValue({ payerUnitNumber: undefined });
              message.error('Invalid payer unit number');
            }
          }}
        />
        {isIndividual && getFieldValue('personalEffects') && (
          <PickDate
            form={form}
            fullNamePath={['birthDate']}
            localNamePath={['birthDate']}
            label="Birth date"
            readOnly={readOnly}
            disabled={disabled}
            disabledDate={(current) => current && current > moment()}
            required={allRequired}
          />
        )}
      </div>
      {showHelpMeGetOne && (
        <Form.Item name="cbpNumberHelp" valuePropName="checked">
          <Checkbox
            className="cbp-number-help"
            disabled={disabled}
            onChange={(val) => setFieldsValue({ iorNumber: undefined })}
          >
            Help me get a CBP-assigned Number
          </Checkbox>
        </Form.Item>
      )}
      {showHelpMeGetOne && getFieldValue('cbpNumberHelp') && (
        <CBPNumberReasonSelect
          form={form}
          readOnly={readOnly}
          disabled={disabled}
        />
      )}
      {personalEffects}
      <Divider />
      <div className="form-inline">
        <PhoneNumber
          form={form}
          disabled={disabled}
          label="Phone"
          required={
            allRequired &&
            getFieldValue('firstTimeIOR') !== 'no' &&
            !(editMode && !initialValues.phone)
          }
          localNamePath={['phone']}
          fullNamePath={['phone']}
        />
        <ANNumber
          form={form}
          debounce={true}
          // TODO these may need updated once implemented
          localNamePath={['phoneExtension']}
          fullNamePath={['phoneExtension']}
          disabled={disabled}
          mask={getNumber}
          $itemSize={CssSize.TINY}
          max={9}
          label="Extension"
        />
        <ANNumber
          form={form}
          debounce={true}
          // TODO these may need updated once implemented
          localNamePath={['fax']}
          fullNamePath={['fax']}
          disabled={disabled}
          mask={getNumber}
          max={15}
          label="Fax number"
        />
        {editMode && (
          <>
            <DUNSNumber
              disabled={
                initialValues.unknownDUNS ||
                getFieldValue('unknownDUNS') ||
                disabled
              }
              readOnly={readOnly}
              form={form}
            />
            {recordType === RecordType.SHIPPER &&
              accountType === AccountType.OPERATOR && (
                <Form.Item name="unknownDUNS" valuePropName="checked">
                  <Checkbox
                    className="unknown-duns-cbo"
                    disabled={disabled}
                    onChange={() => setFieldsValue({ duns: undefined })}
                  >
                    Unknown DUNS
                  </Checkbox>
                </Form.Item>
              )}
          </>
        )}
      </div>
      <div className="form-inline remove-space-bottom">
        <Email
          placeholder="email"
          localNamePath={['email']}
          disabled={disabled}
          required={
            allRequired &&
            getFieldValue('firstTimeIOR') !== 'no' &&
            !(editMode && !initialValues.email)
          }
        />
        {!editMode && (
          <FormItem
            name="website"
            label="Website"
            $readOnly={readOnly}
            debounce
          >
            <Input
              disabled={disabled}
              onBlur={() =>
                formatString(form, ['website'], (str) =>
                  str && typeof str === 'string'
                    ? str.trim().toLowerCase()
                    : undefined,
                )
              }
            />
          </FormItem>
        )}
      </div>
    </StyledCompanyInfoDiv>
  );
};

export default CompanyInfo;
