import { addressFields, phoneFields } from '@xbcb/shared-queries';
import {
  documentGenerationRequestFragments,
  costFragment,
  usQuantityFragment,
} from './documentGenerationRequest';

const usCbp7501DocumentGenerationRequestFragment = `
fragment usCbp7501DocumentGenerationRequestFields on UsCbp7501DocumentGenerationRequest {
  entry {
    number
    type
    time
    isPsc
    isPms
    portCode
    motCode
  }
  document {
    id
  }
  summaryTime
  import {
    carrier
    time
    usPortOfUnlading
    destinationStateCode
  }
  export {
    countryCode
    time
  }
  inBond {
    time
  }
  suretyNumber
  bondType
  foreignPortOfLading
  goNumber
  firmsCode
  consignee {
    name
    address {
      ...addressFields
    }
    iorNumber
    isSameAsIor
  }
  importer {
    name
    address {
      ...addressFields
    }
    iorNumber
    fileNumber
  }
  broker {
    name
    address {
      ...addressFields
    }
    phone {
      ...phoneFields
    }
    fileNumber
  }
  invoices {
    number
    lineItems {
      ...usCbp7501LineItemInfoFields
    }
    totalEnteredAssist {
      ...costFields
    }
    adjustmentInfo {
      adjustedAmount {
        ...costFields
      }
      unadjustedAmount {
        ...costFields
      }
      adjustments {
        type
        value {
          ...costFields
        }
        description
      }
    }
    foreignCurrency {
      conversionFactor
      originalAmount {
        ...costFields
      }
    }
  }
  manifests {
    ...usCbp7501ManifestInfoFields
  }
  totalCosts {
    duty {
      ...costFields
    }
    tax {
      ...costFields
    }
    otherFee {
      ...costFields
    }
    total {
      ...costFields
    }
    totalEnteredValue {
      ...costFields
    }
    totalEnteredAssist {
      ...costFields
    }
    feeSummary {
      classCode
      amount {
        ...costFields
      }
    }
  }
  sign {
    declarant {
      name
      title
      signee
    }
    time
  }
  ...documentGenerationRequestFields
  ...recordFields
}
`;

const usCbp7501LineItemInfoFragment = `
fragment usCbp7501LineItemInfoFields on UsCbp7501LineItemInfo {
  description
  pnNumber
  origin
  claimCode
  enteredValue {
    ...costFields
  }
  enteredAssist {
    ...costFields
  }
  grossWeight {
    value
    unit
  }
  manifestedQuantity {
    ...usQuantityFields
  }
  charge {
    ...costFields
  }
  licenseNumber
  isRelatedParty
  additionalDeclaration {
    information
    typeCode
  }
  mid
  adDuty {
    ...usCbp7501ProtectionDutyInfoFields
  }
  cvdDuty {
    ...usCbp7501ProtectionDutyInfoFields
  }
  taxes {
    classCode
    classType
    rate
    amount {
      ...costFields
    }
    isCbma
  }
  duty {
    ...usCbp7501DutyFields
  }
  fees {
    classCode
    classType
    rate
    amount {
      ...costFields
    }
  }
  tariffs {
    htsNumber
    htsDuty {
      ...usCbp7501DutyFields
    }
    description
    quantities {
      ...usQuantityFields
    }
  }
  categoryNumber
}
`;

const usCbp7501DutyFragment = `
fragment usCbp7501DutyFields on UsCbp7501Duty {
  rate
  amount {
    ...costFields
  }
}
`;

const usCbp7501ProtectionDutyInfoFragment = `
fragment usCbp7501ProtectionDutyInfoFields on UsCbp7501ProtectionDutyInfo {
  caseNumber
  suretyNumber
  rate
  amount {
    ...costFields
  }
}
`;

const usCbp7501ManifestInfoFragment = `
fragment usCbp7501ManifestInfoFields on UsCbp7501ManifestInfo {
  masterBill {
    ...usCbp7501BillInfoFields
  }
  houseBill {
    ...usCbp7501BillInfoFields
  }
  inBond {
    number
  }
  quantity {
    ...usQuantityFields
  }
}
`;

const usCbp7501BillInfoFragment = `
fragment usCbp7501BillInfoFields on UsCbp7501BillInfo {
  number
}
`;

export const usCbp7501DocumentGenerationRequestFragments = `
${usCbp7501DocumentGenerationRequestFragment}
${addressFields}
${phoneFields}
${usQuantityFragment}
${costFragment}
${usCbp7501ManifestInfoFragment}
${usCbp7501BillInfoFragment}
${usCbp7501LineItemInfoFragment}
${usCbp7501DutyFragment}
${usCbp7501ProtectionDutyInfoFragment}
${documentGenerationRequestFragments}
`;
