import { externalSystemFragments } from './externalSystem';

const shipperSystemFragment = `
fragment shipperSystemFields on ShipperSystem {
  operator {
    id
  }
  shipper {
    id
  }
  ...externalSystemFields
  ...recordFields
}
`;

export const shipperSystemFragments = `
${shipperSystemFragment}
${externalSystemFragments}
`;
