import { CrudPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const OperatorUserReportReconciliationRequestPermissionName = {
  ...CrudPermissionName,
};

export default {
  [ObjectType.REPORT_RECONCILIATION_REQUEST]:
    OperatorUserReportReconciliationRequestPermissionName,
};
