import { createWorkOrderRecord } from './utils';
import { EsDataType } from '../../enums';

export const usDrawbackClaim = createWorkOrderRecord({
  columns: {
    drawbackFilingPortCode: {
      default: true,
      name: 'Drawback Filing Port Code',
      type: EsDataType.KEYWORD,
    },
    brokerNumber: {
      name: 'Broker Number',
      type: EsDataType.KEYWORD,
    },
    brokerId: {
      name: 'Broker ID',
      type: EsDataType.KEYWORD,
    },
    drawbackProvision: {
      name: 'Drawback Provision Code',
      type: EsDataType.KEYWORD,
    },
    claimant: {
      default: true,
      name: 'Claimant ID',
      type: EsDataType.TEXT,
    },
    cbpStatus: {
      default: true,
      name: 'CBP Status',
      type: EsDataType.KEYWORD,
    },
    entryNumber: {
      name: 'Entry Number',
      type: EsDataType.TEXT,
    },
    importEntryNumber: {
      name: 'Import Entry Number',
      type: EsDataType.TEXT,
    },
    importHTSNumber: {
      name: 'Import Classification HTS Number',
      type: EsDataType.KEYWORD,
    },
  },
});
