import {
  CreateUsCbp5106DocumentGenerationRequestInput,
  UsIor,
} from '@xbcb/api-gateway-client';
import { TagKey } from '@xbcb/shared-types';

export const generateUsCbp5106DgrInput = (
  usIor: UsIor,
): CreateUsCbp5106DocumentGenerationRequestInput => {
  const {
    tags,
    bank,
    businessDescription,
    name,
    programCodes,
    individuals,
    addresses,
    businessStructure,
    cbpMailingAddressType,
    cbpPhysicalAddressType,
    relatedBusinesses,
    entriesPerYearCode,
    phone,
    email,
    operator,
  } = usIor;

  if (!addresses?.mailing) {
    throw new Error('Mailing address not found');
  }

  if (!cbpMailingAddressType) {
    throw new Error('Mailing address type not found');
  }

  return {
    operator,
    tags: [...tags, { key: TagKey.US_IOR_ID, value: usIor.id }],
    bank,
    businessDescription,
    businessStructure,
    name,
    signerDate: new Date().toLocaleDateString(),
    programCodes,
    individuals,
    addresses,
    cbpMailingAddressType,
    cbpPhysicalAddressType,
    relatedBusinesses,
    entriesPerYearCode,
    phone,
    email,
  };
};
