// import GA from 'react-ga';
import { UiStage } from '@xbcb/ui-types';

const cache = {};

export const butter = async (path, subpath, args) => {
  try {
    let response;
    if (path === 'content') {
      const cacheKey = args.join();
      if (!cache[cacheKey]) {
        cache[cacheKey] = window.butter[path][subpath](args);
      }
      response = await cache[cacheKey];
    } else {
      response = window.butter[path][subpath](args);
    }
    return response;
  } catch (e) {
    if (process.env.REACT_APP_STAGE === UiStage.PROD) {
      // GA.exception({
      //   description: e.message,
      //   fatal: true,
      // });
    }
    // reportError(e);
    // message.error(
    //   `Sorry, we weren't able to fetch your content, please try again.`,
    // );
    return null;
  }
};
