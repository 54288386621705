import { partyFragments } from './party';

const brokerFragment = `
fragment brokerFields on Broker {
  ...recordFields
  ...partyFields
  identifiers {
    identification
    identificationType
  }
  identificationIssuingCountry
  qualityAssurance
  function
}
`;

export const brokerFragments = `
${brokerFragment}
${partyFragments}
`;
