import { isEqual, isEmpty, set, pick } from 'lodash';
import moment from 'moment';
import { einShort } from '@xbcb/regex';
import {
  AccountType,
  AnyObject,
  BillingPartyAccountStatus,
  RecordType,
} from '@xbcb/shared-types';
import { isEin } from '../isEin';
import { DocumentTag } from '@xbcb/document-types';
import {
  WorkOrderType,
  UsCbpEntryModeOfTransport,
} from '@xbcb/work-order-types';
import {
  AccountingPointOfContact,
  CustomerDefaultWorkOrdersByModeOfTransportConfig,
} from '@xbcb/api-gateway-client';
import { cleanObject } from '@xbcb/js-utils';
import { AppRecordTransformCreateInputProps } from '@xbcb/ui-types';

const isEinShort = (input: any) => {
  const iorNumberValue = input?.iorNumber?.value;
  return isEin(input) && iorNumberValue && einShort.test(iorNumberValue);
};

export const transformParty = {
  toForm: ({ existingRecord: newRecord }: { existingRecord: any }) => {
    if (newRecord.addresses) {
      const { physical, mailing } = newRecord.addresses;
      if (isEqual(physical, mailing)) {
        delete newRecord.addresses.mailing;
        delete newRecord.cbpMailingAddressType;
        newRecord.sameMailingAddress = true;
      }

      if (
        mailing &&
        isEqual(mailing.stateCode, newRecord.estasblishment?.stateCode)
      ) {
        delete newRecord.establishment.countryCode;
        delete newRecord.establishment.stateCode;
        newRecord.sameEstablishmentState = true;
      }
    }
    if (newRecord.billingDetails) {
      const { status, customFeeConfig } = newRecord.billingDetails;
      if (status === BillingPartyAccountStatus.ACTIVE) {
        newRecord.accountStatus = true;
      }
      const singleEntryBondFeeConfig = customFeeConfig?.singleEntryBond;
      // rate and minimum are stored in the schema as a value but the form
      // expects a currency (value and currency combination)
      if (singleEntryBondFeeConfig?.rate) {
        singleEntryBondFeeConfig.rate = {
          value: singleEntryBondFeeConfig.rate,
          currency: 'USD',
        };
      }
      if (singleEntryBondFeeConfig?.minimum) {
        singleEntryBondFeeConfig.minimum = {
          value: singleEntryBondFeeConfig.minimum,
          currency: 'USD',
        };
      }
    }
    if (newRecord.personalEffects) {
      const { passport, birthDate } = newRecord.personalEffects;
      if (passport?.expiration) {
        newRecord.personalEffects.passport.expiration = moment(
          passport.expiration,
          'YYYY-MM-DD',
        );
      }
      if (birthDate) {
        newRecord.personalEffects.birthDate = moment(birthDate, 'YYYY-MM-DD');
      }
    }
    const transformTaggedDocs = (docs: DocumentTag[]) => {
      const documentTagsMap: AnyObject = {};
      docs.forEach((doc: string) => {
        documentTagsMap[doc] = true;
      });
      return documentTagsMap;
    };
    const includedDocuments =
      newRecord.workOrderConfirmationConfigurations?.[0]?.includedDocuments;
    if (includedDocuments) {
      newRecord.workOrderConfirmationConfigurations[0].includedDocuments =
        transformTaggedDocs(includedDocuments);
    }
    const accountingDocs = newRecord.billingDetails?.accountingDocs;
    if (accountingDocs)
      newRecord.billingDetails.accountingDocs =
        transformTaggedDocs(accountingDocs);
    // skipUsIsf
    const skipUsIsf = newRecord.defaultWorkOrdersConfig?.us?.some(
      (workOrderConfig: {
        modesOfTransport?: UsCbpEntryModeOfTransport[];
        workOrderTypes?: WorkOrderType[];
      }) => {
        const { modesOfTransport, workOrderTypes } = workOrderConfig || {};
        return (
          modesOfTransport?.includes(UsCbpEntryModeOfTransport.OCEAN) &&
          !workOrderTypes?.includes(WorkOrderType.UsIsf)
        );
      },
    );
    if (skipUsIsf) {
      newRecord.defaultWorkOrdersConfig.skipUsIsf = true;
    }
    return newRecord;
  },
  toSchema: ({
    input,
    isCreate,
    recordType,
    user = {},
  }: AppRecordTransformCreateInputProps) => {
    const { account, accountType } = user;

    if (input.sameMailingAddress && input.addresses) {
      input.addresses.mailing = input.addresses.physical;
      if (input.cbpPhysicalAddressType) {
        input.cbpMailingAddressType = input.cbpPhysicalAddressType;
      }
    }

    if (input.shipper) {
      // There is an issue when the record has multiple === true
      // We expect the shipper to be an array of objects with key as 'id' and value as the id.
      // Instead we get id as an array of strings.
      const shipperId = input.shipper.id;
      if (Array.isArray(shipperId)) {
        input.shippers = shipperId.map((id: string) => {
          return { id };
        });
      } else {
        input.shippers = [{ id: shipperId }];
      }
      delete input.shipper;
    }
    // `shippers` are accepted on the create inputs for all parties other than `shipper` but never
    // on the update inputs, thus we should remove them on update if `input.shippers` is defined
    if (!isCreate && input.shippers) {
      delete input.shippers;
    }

    delete input.sameMailingAddress; // want to delete even if this is false so we don't include it in the GraphQL input object.
    delete input.accountStatus;
    if (input.sameEstablishmentState) {
      if (input.establishment) {
        input.establishment.countryCode = input.addresses?.mailing?.countryCode;
        input.establishment.stateCode = input.addresses?.mailing?.stateCode;
      }
    }
    delete input.sameEstablishmentState; // want to delete even if this is false so we don't include it in the GraphQL input object.
    // There will always be an `iorNumber.type` as it's a radio select with a default value.
    // If there's no `iorNumber.value` we should delete `iorNumber` entirely as if it is
    // provided the `iorNumber.value` is required
    if (input.iorNumber !== null && !input.iorNumber?.value) {
      delete input.iorNumber;
    }
    if (isEinShort(input)) {
      input.iorNumber.value += '00';
    }
    // TODO this should be handled better and is just temporary.
    // It should likely be handled how validateImporterFields did in legacy
    // with all of the OptionalCompanyInfo (5106 data) validated as well.
    // I've created a follow up task to do this properly when not rushed to deploy
    if (input.relatedBusinesses) {
      input.relatedBusinesses = input.relatedBusinesses.map((business: any) => {
        const businessIorNumberValue = business.iorNumber?.value;
        if (!businessIorNumberValue) delete business.iorNumber;
        if (businessIorNumberValue && einShort.test(businessIorNumberValue)) {
          business.iorNumber.value += '00';
        }
        return pick(business, ['name', 'iorNumber', 'type']);
      });
    }
    delete input.memos;
    delete input.forwarders;
    // Phone number is mandatory and only country code in input will cause error.
    if (!input.phone?.number) {
      delete input.phone;
    }
    if (!input.pointOfContact?.phone?.number) {
      delete input.pointOfContact?.phone;
    }
    if (isEmpty(input.pointOfContact)) {
      delete input.pointOfContact;
    }
    const existingAccountingPointOfContacts = input?.billingDetails
      ?.accountingPointofContacts as AccountingPointOfContact[] | undefined;
    if (existingAccountingPointOfContacts) {
      // Reassign the existingAccountingPointOfContacts to only accounting POCs
      // that are non-empty (after we check if we need to remove `phone`)
      input.billingDetails.accountingPointofContacts =
        existingAccountingPointOfContacts.reduce(
          (
            newAccountingPointOfContacts: AccountingPointOfContact[],
            accountingPointOfContact,
          ) => {
            // If the POC didn't have a number, remove the entire phone object
            if (
              !accountingPointOfContact.pointOfContact?.phone?.number &&
              accountingPointOfContact.pointOfContact?.phone
            ) {
              delete accountingPointOfContact.pointOfContact.phone;
            }
            // Only include the ones that are non-empty as phone may have been
            // the only field inside poc and poc may have been the only field in
            // the accountingPointOfContact
            if (!isEmpty(cleanObject(accountingPointOfContact))) {
              newAccountingPointOfContacts.push(accountingPointOfContact);
            }
            return newAccountingPointOfContacts;
          },
          [],
        );
    }

    // Applicable only for tuckers
    if (!input.dispatchPointOfContact?.phone?.number) {
      delete input.dispatchPointOfContact?.phone;
    }
    if (isEmpty(input.dispatchPointOfContact)) {
      delete input.dispatchPointOfContact;
    }

    // Applicable only for facilities
    if (!input.receivingPointOfContact?.phone?.number) {
      delete input.receivingPointOfContact?.phone;
    }
    if (isEmpty(input.receivingPointOfContact)) {
      delete input.receivingPointOfContact;
    }
    // activations field is set by its own api, it should not be present in create/update input.
    if (input.activations) {
      delete input.activations;
    }
    // If `cbpNumberHelp` was selected, we should not be submitting an iorNumber
    if (input.cbpNumberHelp) delete input.iorNumber;
    if (input.enabled !== undefined) {
      if (
        input.enabled === false &&
        input.workOrderConfirmationConfigurations
      ) {
        input.workOrderConfirmationConfigurations = null;
      }
      delete input.enabled;
    }
    if (input.confirmIf !== undefined) {
      if (
        input.confirmIf === false &&
        input.workOrderConfirmationConfigurations?.[0]?.ruleGroup
      ) {
        input.workOrderConfirmationConfigurations[0].ruleGroup = null;
      }
      delete input.confirmIf;
    }
    if (input.overrideForwarderConfig) {
      set(
        input,
        ['workOrderConfirmationConfigurations', 0, 'overrideForwarderConfig'],
        input.overrideForwarderConfig,
      );
      if (!input.workOrderConfirmationConfigurations?.[0]?.workOrderType) {
        set(
          input,
          ['workOrderConfirmationConfigurations', 0, 'workOrderType'],
          WorkOrderType.UsConsumptionEntry,
        );
      }
    }
    delete input.overrideForwarderConfig;
    const transformTaggedDocs = (docs: AnyObject) => {
      const taggedDocs: DocumentTag[] = [];
      Object.keys(docs).forEach((key) => {
        if (docs[key] === true) {
          taggedDocs.push(key as DocumentTag);
        }
      });
      return taggedDocs;
    };
    const includedDocuments =
      input.workOrderConfirmationConfigurations?.[0]?.includedDocuments;
    if (includedDocuments) {
      input.workOrderConfirmationConfigurations[0].includedDocuments =
        transformTaggedDocs(includedDocuments);
    }
    const accountingDocs = input.billingDetails?.accountingDocs;
    if (accountingDocs)
      input.billingDetails.accountingDocs = transformTaggedDocs(accountingDocs);
    // defaultWorkOrdersConfig
    const skipUsIsf = input.defaultWorkOrdersConfig?.skipUsIsf;
    if (skipUsIsf !== undefined) {
      const workOrderConfigs: CustomerDefaultWorkOrdersByModeOfTransportConfig[] =
        [];
      if (skipUsIsf) {
        workOrderConfigs.push({
          modesOfTransport: [UsCbpEntryModeOfTransport.OCEAN],
          workOrderTypes: [WorkOrderType.UsConsumptionEntry],
        });
      } else {
        workOrderConfigs.push({
          modesOfTransport: [UsCbpEntryModeOfTransport.OCEAN],
          workOrderTypes: [
            WorkOrderType.UsConsumptionEntry,
            WorkOrderType.UsIsf,
          ],
        });
      }

      input.defaultWorkOrdersConfig?.us?.forEach(
        (woConfig: CustomerDefaultWorkOrdersByModeOfTransportConfig) => {
          if (
            !woConfig.modesOfTransport?.includes(
              UsCbpEntryModeOfTransport.OCEAN,
            )
          ) {
            workOrderConfigs.push(woConfig);
          }
        },
      );
      input.defaultWorkOrdersConfig = {
        us: workOrderConfigs,
      };
      delete input.defaultWorkOrdersConfig.skipUsIsf;
    }

    const singleEntryBondFeeConfig =
      input?.billingDetails?.customFeeConfig?.singleEntryBond;
    // rate and minimum is stored in the form as a currency (value and
    // currency), schema only expects value
    if (singleEntryBondFeeConfig?.rate) {
      singleEntryBondFeeConfig.rate = singleEntryBondFeeConfig.rate.value;
    }
    if (singleEntryBondFeeConfig?.minimum) {
      singleEntryBondFeeConfig.minimum = singleEntryBondFeeConfig.minimum.value;
    }
    // clean the `singleEntryBondFeeConfig` incase both values were undefined
    if (singleEntryBondFeeConfig) {
      input.billingDetails.customFeeConfig.singleEntryBond = cleanObject(
        singleEntryBondFeeConfig,
      );
    }

    // Shipper should not include `usIors` on the input even though we fetch for them
    if (input.usIors) delete input.usIors;

    // If a Forwarder is being created by another Forwarder, add the invitingPartyId
    // to the record
    if (
      accountType === AccountType.FORWARDER &&
      recordType === RecordType.FORWARDER &&
      account?.id
    ) {
      input.invitingParty = {
        id: account.id,
      };
    }

    // operator should never be part of the schema input
    delete input.operator;

    return input;
  },
};
