import { CrudPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const ForwarderUserInvoicePermissionName = {
  READ: CrudPermissionName.READ,
};

export default {
  [ObjectType.INVOICE]: ForwarderUserInvoicePermissionName,
};
