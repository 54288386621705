import { Stage } from '@xbcb/core';

// Email Addresses
export const Email = {
  FDA: {
    [Stage.ALPHA]: 'fda-notice-alpha@inlt.io',
    [Stage.BRAVO]: 'fda-notice-bravo@inlt.io',
    [Stage.BETA]: 'fda-notice-beta@inlt.io',
    [Stage.GAMMA]: 'fda-notice-gamma@inlt.io',
    [Stage.PROD]: 'fda-notice@inlt.io',
  },
  PMS: {
    [Stage.ALPHA]: 'pms-test-alpha@inlt.io',
    [Stage.BETA]: 'pms-test-beta@inlt.io',
    [Stage.BRAVO]: 'pms-test-bravo@inlt.io',
    [Stage.GAMMA]: 'pms-test-gamma@inlt.io',
    [Stage.PROD]: 'pms@inlt.io',
  },
  // TODO: expand email receiving accounts if future ???_INBOX are added to Inbox feature, or create a generic INBOX account to receive all new emails
  OPS_INBOX: {
    [Stage.ALPHA]: 'ops-inbox-alpha@inlt.io', // Security checks not configured. Do NOT forward any real operator inboxes here.
    [Stage.BETA]: 'ops-inbox-beta@inlt.io',
    [Stage.BRAVO]: 'ops-inbox-bravo@inlt.io',
    [Stage.GAMMA]: 'ops-inbox-gamma@inlt.io', // Intended to receive at or near prod-level email traffic from real operator inboxes
    [Stage.PROD]: 'ops-inbox@inlt.io',
  },
};

export const pmsRequestEmail = 'periodicstatement@dhs.gov';
export const pmsSupportEmail = 'inlt-activations@amazon.com';

export const pmsApplicationSignerName = 'CHRISTOPHER REYNOLDS, ATTY-IN-FACT';
export const pmsApplicationSignerPhone = '215-330-6295';

export const confidentialitySupportEmail = 'inlt-support@amazon.com';

// TODO: Create logic later to allow ops/fwd/brokers to configure their own email accounts to receive on UI page, rather than hardcoding here
export const actOpsAllEmail = 'act-ops-all@amazon.com';
export const aglFbaCustomsBrokerDocsDestEmail =
  'agl-fba-customsbroker-docs-dest@amazon.com';
export const customsOpsEmail = 'customsops@amazon.com';
