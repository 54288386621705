import styled from 'styled-components';
import FormItem, { StyledFormItemType } from '../FormItem';

export const StyledFormItem: StyledFormItemType = styled(FormItem)`
  .ant-form-item-control {
    display: flex;
  }
  .ant-input-affix-wrapper {
    width: auto;
    margin-right: var(--space-4);
  }
`;

export const StyledDiv = styled.div<{ $inline?: boolean }>`
  vertical-align: top;
  ${({ $inline }) => $inline && 'display: inline-block'}
`;
