import React from 'react';
import { Row } from 'antd';
import {
  StyledContent,
  StyledCol,
  StyledMainSectionDiv,
  StyledMainContentDiv,
} from './styles';

export const PageContent: React.FC<{
  rightContent: React.ReactNode;
  $maxHeight?: string;
}> = ({ children, rightContent, $maxHeight }) => (
  <StyledContent>
    <Row gutter={16}>
      <StyledCol $maxHeight={$maxHeight} span={rightContent ? 19 : 24}>
        <StyledMainSectionDiv>
          <StyledMainContentDiv>{children}</StyledMainContentDiv>
        </StyledMainSectionDiv>
      </StyledCol>
      {rightContent && (
        <StyledCol $maxHeight={$maxHeight} span={5}>
          {rightContent}
        </StyledCol>
      )}
    </Row>
  </StyledContent>
);

export default PageContent;
