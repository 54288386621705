// should be used directly from shared-types. Keeping here for backwards compatibilty.
export { ModeOfTransport } from '@xbcb/shared-types';

export enum PortCodeType {
  IATA = 'IATA',
  UNLOCODE = 'UNLOCODE',
  CBP = 'CBP',
}

export enum PgaContainerType {
  REFRIGERATED = 'REFRIGERATED',
  NOT_REFRIGERATED = 'NOT_REFRIGERATED',
}

export enum ShipmentBadge {
  HOT = 'HOT',
  PREMIUM = 'PREMIUM',
}

export enum ShipmentStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
}
