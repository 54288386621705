import { EsDataType } from '../../enums';
import { createTableConfig } from '../createTableConfig';

export const team = createTableConfig({
  columns: {
    accountId: {
      name: 'Account ID',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    name: {
      default: true,
      name: 'Name',
      type: EsDataType.TEXT,
    },
  },
});
