export enum PartyRelationshipRecordType {
  SHIPPER_FORWARDER_RELATIONSHIP = 'SHIPPER_FORWARDER_RELATIONSHIP',
  SHIPPER_SUPPLIER_RELATIONSHIP = 'SHIPPER_SUPPLIER_RELATIONSHIP',
  SHIPPER_FACILITY_RELATIONSHIP = 'SHIPPER_FACILITY_RELATIONSHIP',
  SHIPPER_TRUCKER_RELATIONSHIP = 'SHIPPER_TRUCKER_RELATIONSHIP',
  SHIPPER_CUSTOMS_AGENT_RELATIONSHIP = 'SHIPPER_CUSTOMS_AGENT_RELATIONSHIP',
  SHIPPER_US_IOR_RELATIONSHIP = 'SHIPPER_US_IOR_RELATIONSHIP',
  SHIPPER_GB_IOR_RELATIONSHIP = 'SHIPPER_GB_IOR_RELATIONSHIP',
  SHIPPER_DE_IOR_RELATIONSHIP = 'SHIPPER_DE_IOR_RELATIONSHIP',
  SHIPPER_NL_IOR_RELATIONSHIP = 'SHIPPER_NL_IOR_RELATIONSHIP',
  SHIPPER_FR_IOR_RELATIONSHIP = 'SHIPPER_FR_IOR_RELATIONSHIP',
  SHIPPER_US_CONSIGNEE_RELATIONSHIP = 'SHIPPER_US_CONSIGNEE_RELATIONSHIP',
  SHIPPER_US_CUSTOMS_BROKER_RELATIONSHIP = 'SHIPPER_US_CUSTOMS_BROKER_RELATIONSHIP',
  SHIPPER_UK_CUSTOMS_BROKER_RELATIONSHIP = 'SHIPPER_UK_CUSTOMS_BROKER_RELATIONSHIP',
  SHIPPER_DE_CUSTOMS_BROKER_RELATIONSHIP = 'SHIPPER_DE_CUSTOMS_BROKER_RELATIONSHIP',
  SHIPPER_NL_CUSTOMS_BROKER_RELATIONSHIP = 'SHIPPER_NL_CUSTOMS_BROKER_RELATIONSHIP',
  SHIPPER_FR_CUSTOMS_BROKER_RELATIONSHIP = 'SHIPPER_FR_CUSTOMS_BROKER_RELATIONSHIP',
  SHIPPER_BROKER_RELATIONSHIP = 'SHIPPER_BROKER_RELATIONSHIP',
  SHIPPER_IMPORTER_RELATIONSHIP = 'SHIPPER_IMPORTER_RELATIONSHIP',
  SHIPPER_GLOBAL_CONSIGNEE_RELATIONSHIP = 'SHIPPER_GLOBAL_CONSIGNEE_RELATIONSHIP',
}

export enum NonShipperPartyRelationship {
  IMPORTER_IMPORTER_ENTITY_RELATIONSHIP = 'IMPORTER_IMPORTER_ENTITY_RELATIONSHIP',
}

export const RelationshipRecordType = {
  ...NonShipperPartyRelationship,
  ...PartyRelationshipRecordType,
};
export type RelationshipRecordType = PartyRelationshipRecordType;
