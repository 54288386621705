import React from 'react';
import { omit } from 'lodash';
import {
  createDataCyValue,
  CssSize,
  DataCyPrefix,
  DataCySuffix,
  NamePath,
} from '@xbcb/ui-types';
import {
  badCountryCodes,
  getCountryCodes,
  selectFilter,
  safeGetMessage,
} from '@xbcb/ui-utils';
import FormItem from '../FormItem';
import { Option, Select } from '../ClickSelect';
import { useBundle } from '@amzn/react-arb-tools';

// CBP doesn't recognize certain ISO codes like British Indian Ocean Territory and Palestinian Territory
const filterNonCbpCountries = (input: string, option: any) => {
  let result = selectFilter(input, option);
  if (result && ['IO', 'PS'].includes(option.props.value)) {
    result = false;
  }
  return result;
};

export type CountrySelectProps = {
  localNamePath: NamePath;
  placeholder?: string;
  required?: boolean;
  label?: string;
  onChange?: (...args: any[]) => any;
  disabled?: boolean;
  foreignOnly?: boolean;
  onSelect?: (...args: any[]) => any;
  displayCode?: boolean;
  readOnly?: boolean;
  $itemSize?: string;
  $inline?: boolean;
  usOnly?: boolean;
  initialValue?: string; // TODO need a better type
  dataCySuffix?: string;
  notAllowedCountryCodes?: string[];
  additionalOptions?: CountryCodeOption[];
};

export type CountryCodeOption = {
  key: string;
  value: string;
  display: string;
};

const CountrySelect: React.FC<CountrySelectProps> = ({
  localNamePath,
  placeholder,
  required,
  label,
  onChange,
  readOnly,
  disabled,
  foreignOnly,
  onSelect,
  displayCode,
  $itemSize = CssSize.SHORT,
  $inline,
  usOnly,
  initialValue,
  dataCySuffix,
  notAllowedCountryCodes,
  additionalOptions = [],
}) => {
  const [sharedBundle] = useBundle('shared');
  const isPhysical = localNamePath.some((segment) => segment === 'physical');
  const countryCodeDataCy = createDataCyValue(
    isPhysical
      ? DataCyPrefix.PHYSICAL_COUNTRY_CODE
      : DataCyPrefix.MAILING_COUNTRY_CODE,
    dataCySuffix,
  );
  const usCountryCode = 'US';
  const countryCodes = getCountryCodes();
  const codes = omit(countryCodes, [
    usCountryCode,
    ...badCountryCodes,
    ...(notAllowedCountryCodes || []),
  ]);
  const createDataCyOptionValue = (code: string) =>
    createDataCyValue(
      isPhysical
        ? DataCyPrefix.PHYSICAL_COUNTRY_CODE_OPTION
        : DataCyPrefix.MAILING_COUNTRY_CODE_OPTION,
      code as DataCySuffix,
    );
  const countryOptions = Object.entries(codes)
    .sort(([code0, data0]: [string, any], [code1, data1]: [string, any]) =>
      data0.name.localeCompare(data1.name),
    )
    .map(([code, data]: [string, any]) => ({
      key: code,
      value: code,
      display: data.name,
    }));

  const toOption = (countryCodeOption: CountryCodeOption) => (
    <Option
      key={countryCodeOption.key}
      value={countryCodeOption.value}
      data-cy={createDataCyOptionValue(countryCodeOption.key)}
    >
      {countryCodeOption.display}
    </Option>
  );

  const usCountryCodeOption: CountryCodeOption = {
    key: usCountryCode,
    value: usCountryCode,
    display: countryCodes[usCountryCode].name,
  };

  const options = [
    ...additionalOptions,
    ...(!foreignOnly ? [usCountryCodeOption] : []),
    ...countryOptions,
  ].map(toOption);

  return (
    <FormItem
      label={label}
      name={localNamePath}
      rules={[{ required, message: ' ' }]}
      $itemSize={$itemSize}
      $inline={$inline}
      $readOnly={readOnly || usOnly}
      initialValue={usOnly ? usCountryCode : initialValue}
      data-cy={countryCodeDataCy}
    >
      <Select
        disabled={disabled || usOnly}
        showSearch
        allowClear
        placeholder={placeholder}
        optionLabelProp={displayCode ? 'value' : 'children'}
        onChange={onChange}
        onSelect={onSelect}
        filterOption={filterNonCbpCountries}
        notFoundContent={safeGetMessage(sharedBundle, 'none_found')}
        dropdownMatchSelectWidth={false}
        aria-label={'country-select'}
      >
        {options}
      </Select>
    </FormItem>
  );
};

export default CountrySelect;
