import { AccountType } from '@xbcb/shared-types';
import {
  ForwarderUserNotificationMethod,
  ForwarderUserNotificationType,
} from './forwarderUser';
import {
  ShipperUserNotificationMethod,
  ShipperUserNotificationType,
} from './shipperUser';
import {
  OperatorUserNotificationMethod,
  OperatorUserNotificationType,
} from './operatorUser';

export * from './forwarderUser';
export * from './operatorUser';
export * from './shipperUser';
export * from './externalSystem';
export * from './base';

export const NotificationMethodByAccountType = {
  [AccountType.FORWARDER]: ForwarderUserNotificationMethod,
  [AccountType.SHIPPER]: ShipperUserNotificationMethod,
  [AccountType.OPERATOR]: OperatorUserNotificationMethod,
};

export const NotificationTypeByAccountType = {
  [AccountType.FORWARDER]: ForwarderUserNotificationType,
  [AccountType.SHIPPER]: ShipperUserNotificationType,
  [AccountType.OPERATOR]: OperatorUserNotificationType,
};
