import { codeToText } from '@xbcb/js-utils';
import { AccountType } from '@xbcb/shared-types';
import {
  WorkOrderMilestoneName,
  WorkOrderStatus,
} from '@xbcb/work-order-types';
import { createTableConfig } from '../createTableConfig';
import { sharedCancellationColumns } from '../sharedCancellationColums';
import {
  Columns,
  EsDataType,
  RecordColumns,
  TableOptions,
  expirationTime,
} from '../..';

const sharedColumns = {
  expirationTime,
  workOrderGroupId: {
    name: 'Work Order Group Id',
    type: EsDataType.KEYWORD,
    hidden: true,
  },
  status: {
    name: 'Work Order Status',
    type: EsDataType.KEYWORD,
    default: true,
    optionValues: Object.values(WorkOrderStatus),
  },
  deadline: {
    name: 'Work Order Deadline',
    type: EsDataType.TIME,
    default: true,
  },
  latestMilestone: {
    name: 'Latest Milestone',
    type: EsDataType.KEYWORD,
    optionValues: Object.keys(WorkOrderMilestoneName),
  },
  shipperId: {
    name: 'Shipper Id',
    type: EsDataType.KEYWORD,
    hidden: true,
  },
  shipperName: {
    name: (accountType: AccountType) =>
      accountType === AccountType.FORWARDER ? 'Customer' : 'Shipper',
    type: EsDataType.KEYWORD,
    default: false,
  },
  forwarderId: {
    name: 'Forwarder Id',
    type: EsDataType.KEYWORD,
    hidden: true,
  },
  forwarderName: {
    name: (accountType: AccountType) =>
      accountType === AccountType.FORWARDER ? 'Agent' : 'Forwarder',
    type: EsDataType.KEYWORD,
  },
  brokerId: { name: 'Broker ID', type: EsDataType.KEYWORD, hidden: true },
  payableInvoiceNumber: {
    name: 'Payable Invoice #',
    type: EsDataType.TEXT,
  },
  isAutoSetReady: {
    name: 'Auto Set Ready',
    type: EsDataType.BOOLEAN,
  },
  blockedReason: {
    name: 'Blocked Reason Code',
    type: EsDataType.KEYWORD,
    default: false,
    format: (values: string | string[]) => {
      return Array.isArray(values)
        ? values.map((value) => codeToText(value ?? ''))
        : codeToText(values ?? '');
    },
  },
};

export const createWorkOrderRecord = <TColumns extends Columns>({
  tableOptions = {},
  columns,
}: {
  tableOptions?: TableOptions;
  columns?: TColumns;
}): RecordColumns =>
  createTableConfig({
    columns: {
      ...sharedColumns,
      ...columns,
    },
    tableOptions,
  });

const shipmentWorkOrderSharedColumns = {
  poNumber: {
    name: 'Ref #',
    type: EsDataType.KEYWORD,
  },
  clientIdentifier: {
    default: [AccountType.SHIPPER, AccountType.FORWARDER],
    name: 'Client #',
    type: EsDataType.KEYWORD,
  },
  friendlyId: {
    name: 'ID',
    type: EsDataType.TEXT,
  },
  iorId: {
    name: 'IOR ID',
    type: EsDataType.KEYWORD,
    default: false,
    hidden: true,
  },
  isAutoSetReady: {
    name: 'Auto Set Ready',
    type: EsDataType.BOOLEAN,
  },
};

// This create function is meant strictly for ShipmentWorkOrderTypes. See
// isShipmentWorkOrderType in @xbcb/core for more information.
export const createShipmentWorkOrderRecord = <TColumns extends Columns>({
  tableOptions = {},
  columns,
}: {
  tableOptions?: TableOptions;
  columns?: TColumns;
}): RecordColumns =>
  // Add poNumber in addition to the provided `columns` as well as tableOptions
  // then simply call createWorkOrderRecord for the default WO config
  createWorkOrderRecord({
    columns: {
      ...shipmentWorkOrderSharedColumns,
      ...sharedCancellationColumns,
      ...columns,
    },
    tableOptions,
  });
