// Range of Daylight Saving Time each year in UTC for Eastern location.
// https://www.timetemperature.com/tzus/daylight_saving_time.shtml
export const daylightSavingTimeRanges = [
  ['2017-03-12T07:00:00.000Z', '2017-11-05T07:00:00.000Z'],
  ['2018-03-11T07:00:00.000Z', '2018-11-04T07:00:00.000Z'],
  ['2019-03-10T07:00:00.000Z', '2019-11-03T07:00:00.000Z'],
  ['2020-03-08T07:00:00.000Z', '2020-11-01T07:00:00.000Z'],
  ['2021-03-14T07:00:00.000Z', '2021-11-07T07:00:00.000Z'],
  ['2022-03-13T07:00:00.000Z', '2022-11-06T07:00:00.000Z'],
  ['2023-03-12T07:00:00.000Z', '2023-11-05T07:00:00.000Z'],
];

// Check if it is Daylight Saving Time when converting to Eastern time. Input date is UTC.
export const isDst = (date = new Date()) => {
  for (const dst of daylightSavingTimeRanges) {
    if (date >= new Date(dst[0]) && date <= new Date(dst[1])) {
      return true;
    }
  }
  return false;
};
