import { CrudPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const ForwarderUserForwarderUserPermissionName = {
  ...CrudPermissionName,
};

export default {
  [ObjectType.FORWARDER_USER]: ForwarderUserForwarderUserPermissionName,
};
