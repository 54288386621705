import { AnyObject } from '@xbcb/shared-types';
import moment from 'moment';

const addPossibleBond = (possibleBonds: AnyObject[], bond?: AnyObject) => {
  if (bond && (bond.effectiveDate || bond.renewalDate) && bond.suretyCode)
    possibleBonds.push(bond);
};

export const getBondInformation = (
  bondDeterminationDate: string,
  continuousBonds?: AnyObject,
) => {
  let hasBond = false;
  let suretyCode;

  if (!continuousBonds) {
    return { hasBond };
  }

  // only consider past bonds if the entry date is the same or before the current day
  const possibleBonds = moment(bondDeterminationDate).isAfter(moment(), 'day')
    ? []
    : continuousBonds.past?.slice() || [];

  const currentBond = continuousBonds.present;
  addPossibleBond(possibleBonds, currentBond);
  const nextBond = continuousBonds.future;
  addPossibleBond(possibleBonds, nextBond);

  // needs to be decrementing (last to first)
  for (let j = possibleBonds.length - 1; j >= 0; j--) {
    const possibleBond = possibleBonds[j];
    const effectiveDate = possibleBond.effectiveDate
      ? moment(possibleBond.effectiveDate)
      : moment(possibleBond.renewalDate).subtract(1, 'years');
    if (
      effectiveDate.isSameOrBefore(bondDeterminationDate, 'day') &&
      (!possibleBond.terminationDate ||
        moment(possibleBond.terminationDate).isSameOrAfter(
          bondDeterminationDate,
          'day',
        ))
    ) {
      suretyCode = possibleBond.suretyCode;
      hasBond = true;
      break;
    }
  }
  return { hasBond, suretyCode };
};
