export enum UsCbpEntryLineRulingType {
  PRE_CLASSIFICATION_NUMBER = 'PRE_CLASSIFICATION_NUMBER',
  PRE_APPROVAL_NUMBER = 'PRE_APPROVAL_NUMBER',
  BINDING_RULING = 'BINDING_RULING',
}
export enum UsCbpEntryTaxType {
  DISTILLED_SPIRIT_TAX = 'DISTILLED_SPIRIT_TAX',
  WINE_TAX = 'WINE_TAX',
  TOBACCO_TAX = 'TOBACCO_TAX',
  OTHER_TAX = 'OTHER_TAX',
}

export enum UsCbpEntryHeaderFeeType {
  INFORMAL_MERCHANDISE_PROCESSING_FEE = 'INFORMAL_MERCHANDISE_PROCESSING_FEE',
  DUTIABLE_MAIL_FEE = 'DUTIABLE_MAIL_FEE',
  MANUAL_ENTRY_SURCHARGE = 'MANUAL_ENTRY_SURCHARGE',
}

export enum UsCbpEntryLineFeeType {
  BEEF_FEE = 'BEEF_FEE',
  PORK_FEE = 'PORK_FEE',
  HONEY_FEE = 'HONEY_FEE',
  COTTON_FEE = 'COTTON_FEE',
  RASPBERRY_FEE = 'RASPBERRY_FEE',
  SUGAR_FEE = 'SUGAR_FEE',
  POTATO_FEE = 'POTATO_FEE',
  LIME_FEE = 'LIME_FEE',
  MUSHROOM_FEE = 'MUSHROOM_FEE',
  WATERMELON_FEE = 'WATERMELON_FEE',
  SHEEP_FEE = 'SHEEP_FEE',
  BLUEBERRY_FEE = 'BLUEBERRY_FEE',
  AVOCADO_FEE = 'AVOCADO_FEE',
  MANGO_FEE = 'MANGO_FEE',
  SORGHUM_FEE = 'SORGHUM_FEE',
  DAIRY_PRODUCT_FEE = 'DAIRY_PRODUCT_FEE',
  FORMAL_MERCHANDISE_PROCESSING_FEE = 'FORMAL_MERCHANDISE_PROCESSING_FEE',
  HARBOR_MAINTENANCE_FEE = 'HARBOR_MAINTENANCE_FEE',
  COFFEE_IMPORTS_TO_PUERTO_RICO = 'COFFEE_IMPORTS_TO_PUERTO_RICO',
}

export type UsCbpEntryFeeType = UsCbpEntryHeaderFeeType | UsCbpEntryLineFeeType;

export const UsCbpEntryFeeType = {
  ...UsCbpEntryHeaderFeeType,
  ...UsCbpEntryLineFeeType,
};
