import { Columns, EsDataType, recordFields } from '../index';
import { createTableConfig } from './createTableConfig';

const columns: Columns = {
  name: {
    default: true,
    name: 'Processing Error',
    type: EsDataType.KEYWORD,
  },
  status: {
    default: true,
    name: 'Processing Error Status',
    type: EsDataType.KEYWORD,
  },
  message: {
    default: true,
    type: EsDataType.TEXT,
    name: 'Message',
  },
  source: {
    type: EsDataType.KEYWORD,
    name: 'Service that Errored',
    default: true,
  },
  affectedRecordIds: {
    type: EsDataType.TEXT,
    name: 'Affected Records',
    default: true,
  },
  ...recordFields({ id: { name: 'Error ID' }, createdTime: { default: true } }),
};

export const processingError = createTableConfig<typeof columns>({
  columns,
});
