import type { UsConsumptionEntry } from '@xbcb/api-gateway-client';
import { isPathPresentInFragment } from '@xbcb/api-gateway-client-utils/dist/parseFragments';
import log from '@xbcb/log';
import type { AdCvdQueryFunction, QueryProductsFunction } from '../../../types';
import { processProducts, processAdCvdCases } from '.';

type GetFieldsMapper = (
  queryProducts: QueryProductsFunction,
  queryAdCvdCases: AdCvdQueryFunction,
) => Record<
  string,
  {
    paths: string[];
    processor: (data: UsConsumptionEntry) => Promise<void>;
  }
>;
const getFieldsMapper: GetFieldsMapper = (queryProducts, queryAdCvdCases) => ({
  products: {
    paths: ['invoices.products.product'],
    processor: processProducts(queryProducts),
  },
  adCvdCases: {
    paths: ['invoices.products.lines.adCase', 'invoices.products.lines.cvCase'],
    processor: processAdCvdCases(queryAdCvdCases),
  },
});

export const processEntry = async (
  finalEntry: UsConsumptionEntry,
  info: string,
  queryAdCvdCases: AdCvdQueryFunction,
  queryProducts: QueryProductsFunction,
): Promise<void> => {
  const federatedFieldsMapper = getFieldsMapper(queryProducts, queryAdCvdCases);
  for (const [key, { paths, processor }] of Object.entries(
    federatedFieldsMapper,
  )) {
    log.info(`Starting to process ${key} cases at ${performance.now()}`);

    if (paths.every((path) => isPathPresentInFragment(info, path))) {
      await processor(finalEntry);
    }
    log.info(`Completed processing ${key} cases at ${performance.now()}`);
  }
};
