import { getEnv } from '@xbcb/ui-utils';
import { uiStageToBackendStage } from '@xbcb/ui-types';
import {
  cbpReg2ChangesFeature,
  CBP_REG_2_CHANGES_FEATURE,
} from '@xbcb/feature-flags';

const { stage } = getEnv();

export const iscbpReg2ChangesFeatureEnabled =
  CBP_REG_2_CHANGES_FEATURE.isEnabled(cbpReg2ChangesFeature, {
    stage: uiStageToBackendStage[stage],
  });
