import { camelCase } from 'change-case';
import { codeToText } from '@xbcb/js-utils';
import {
  AccountType,
  AnyObject,
  ClassificationSubjectMatterSubCategory,
  PgaSubjectMatterSubCategory,
  SubjectMatterCategory,
  SubjectMatterSubCategory,
} from '@xbcb/shared-types';
import { operatorId, EsDataType } from '../../index';
import { createUserTeamTableConfig } from './userTeam';

// categories to subcategories map
const subjectMatterCategoryMap: AnyObject = {
  [SubjectMatterCategory.CLASSIFICATION]: Object.values(
    ClassificationSubjectMatterSubCategory,
  ),
  [SubjectMatterCategory.PGA]: Object.values(PgaSubjectMatterSubCategory),
};

const getCategoryColumn = (category: string) => {
  return {
    [camelCase(category)]: {
      name: codeToText(category),
      type: EsDataType.TEXT,
      default: true,
    },
  };
};

const subjectMatterCategoryColumns = Object.values(
  SubjectMatterCategory,
).reduce(
  (acc, category) => ({
    ...acc,
    ...getCategoryColumn(category),
    // attach subcategory columns
    ...(subjectMatterCategoryMap[category]?.reduce(
      (acc2: AnyObject, subCategory: SubjectMatterSubCategory) => ({
        ...acc2,
        ...getCategoryColumn(subCategory),
      }),
      {},
    ) || []),
  }),
  {},
);

export const subjectMatterExpertTeam = createUserTeamTableConfig({
  columns: {
    operatorId,
    operatorName: {
      name: 'Operator',
      type: EsDataType.TEXT,
      whitelist: [AccountType.OPERATOR],
    },
    customsBrokerId: {
      name: 'Broker ID',
      type: EsDataType.KEYWORD,
      hidden: true,
      whitelist: [AccountType.OPERATOR],
    },
    customsBrokerName: {
      name: 'Broker Name',
      type: EsDataType.TEXT,
      whitelist: [AccountType.OPERATOR],
    },
    memberCount: {
      name: 'Members',
      type: EsDataType.INTEGER,
      default: true,
    },
    ...subjectMatterCategoryColumns,
  },
});
