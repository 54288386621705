import {
  parse,
  print as gqlPrint,
  OperationDefinitionNode,
  FragmentDefinitionNode,
  FieldNode,
} from 'graphql';

// todo: there is a limitation of this strategy that we are assuming that fragments
// as applied to top level fields only.
// If a sub-field has fragments then we would need to make a map of spread-ed field fragment definition in corresponds to.
// i.e un-wrap the fragments.
export const isPathPresentInFragment = (
  fragment: string,
  path: string,
): boolean => {
  const parsedFragment = parse(fragment);
  const [toSearch, ...remaining] = path.split('.');

  const definitions = parsedFragment.definitions.filter(
    (def) =>
      def.kind === 'OperationDefinition' || def.kind === 'FragmentDefinition',
  ) as (OperationDefinitionNode | FragmentDefinitionNode)[];

  if (!definitions?.length) return false;
  const selectionSet = definitions.find((definition) =>
    definition.selectionSet.selections.find(
      (selection) =>
        selection.kind === 'Field' && selection.name.value === toSearch,
    ),
  );
  // matching selection set not found
  if (!selectionSet?.selectionSet) return false;

  // We reached the end of the path and there is a selectionSet that contains it
  if (path.split('.').length === 1) return true;

  // Retrieve the query string for the next recursive call
  const selections = (
    selectionSet.selectionSet.selections.find(
      (sel) => sel.kind === 'Field' && sel.name.value === toSearch,
    ) as FieldNode
  ).selectionSet?.selections;

  // this exception should never be thrown. It is to make TS happy for `gqlPrint(selections[0])`
  if (!selections) throw new Error('No Selections found');

  return isPathPresentInFragment(
    `{ ${gqlPrint(selections[0])} }`,
    remaining.join('.'),
  );
};
