import zlib from 'zlib';

export const gunzipJson = (compressed: string | Buffer) => {
  const base64 = Buffer.isBuffer(compressed)
    ? compressed.toString('base64')
    : compressed;
  return JSON.parse(
    zlib.gunzipSync(Buffer.from(base64, 'base64')).toString('utf-8'),
  );
};

export const gunzipString = (compressed: string | Buffer) => {
  const buffer = Buffer.isBuffer(compressed)
    ? compressed
    : Buffer.from(compressed, 'base64');
  return zlib.gunzipSync(buffer).toString('utf-8');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const gzipJson = (json: any) => {
  return zlib.gzipSync(Buffer.from(JSON.stringify(json), 'utf-8'));
};

export const gzipString = (str: string) => {
  return zlib.gzipSync(Buffer.from(str, 'utf-8'));
};
