import styled from 'styled-components';
import { Button, Form } from 'antd';

// TODO we used this pattern a lot, address it
export type AntdTypeProps = any;

export const StyledButton = styled<AntdTypeProps>(Button)`
  width: var(--space-8);
  margin: 0 auto;
`;

export const StyledForm = styled<AntdTypeProps>(Form)`
  margin-top: var(--space-4);
  .ant-form-item {
    :last-child {
      margin-bottom: 0;
    }
    width: auto;
  }
  .ant-alert:not(:first-child) {
    margin-top: var(--space-3);
  }
`;

export const StyledDiv = styled.div`
  text-align: center;
  margin-bottom: var(--space-4);
`;
