import {
  LARGE_ENTRY_UX_FEATURE,
  largeEntryUXFeatureName,
} from '@xbcb/feature-flags';
import { uiStageToBackendStage } from '@xbcb/ui-types';
import { getEnv } from '@xbcb/ui-env';

export const isLargeEntryUXEnabled = (operatorUserId?: string): boolean => {
  if (!operatorUserId) return false;
  const flagEnabled = LARGE_ENTRY_UX_FEATURE.isEnabled(
    largeEntryUXFeatureName,
    {
      stage: uiStageToBackendStage[getEnv().stage],
      operator: operatorUserId,
    },
  );
  const override = localStorage.getItem('LARGE_ENTRY_UX');

  if (override === 'false') {
    return false; // Allow opting out of the feature via localstorage.
  }
  return flagEnabled;
};
