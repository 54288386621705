import { partyFragments } from './party';

const deCustomsBrokerFragment = `
fragment deCustomsBrokerFields on DeCustomsBroker {
  ...recordFields
  ...partyFields
  entityName
  name
  eori
}
`;

export const deCustomsBrokerFragments = `
${deCustomsBrokerFragment}
${partyFragments}
`;
