export type S3Reference = { referenceKey: string };

export abstract class S3Uploader {
  /**
   * Uploads the fields to s3 using the given s3LinkGenerationFunction
   * @param {unknown} fields the fields to save to s3
   * @return {Promise<S3Reference>} a reference to where the fields have been stored in s3
   */
  upload: (input: unknown) => Promise<S3Reference>;
}
