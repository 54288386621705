import { createReducer } from '@reduxjs/toolkit';
import { AnyObject } from '@xbcb/shared-types';
import { ModalKey } from '@xbcb/ui-types';
import {
  openModal,
  closeModal,
  toggleModal,
  setModal,
  mergeModal,
} from '../actions';

// Will be an empty object initially, thus we need `?` in `[key in ModalKey]?:`
type ModalState = {
  [key in ModalKey]?: {
    visible?: boolean;
  } & AnyObject;
};

const initialState: ModalState = {};

export const modalsReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(openModal, (state, { payload }) => {
      const { key } = payload;
      const currentModalProps = state?.[key] || {};
      return {
        ...state,
        [key]: {
          ...currentModalProps,
          visible: true,
        },
      };
    })
    .addCase(closeModal, (state, { payload }) => {
      const { key } = payload;
      const currentModalProps = state?.[key] || {};
      return {
        ...state,
        [key]: {
          ...currentModalProps,
          visible: false,
        },
      };
    })
    // TODO remove later in favor of OPEN/CLOSE modal
    .addCase(toggleModal, (state, { payload }) => {
      const { key } = payload;
      const currentModalProps = state[key] || {};
      return {
        ...state,
        [key]: {
          ...currentModalProps,
          visible: !currentModalProps?.visible,
        },
      };
    })
    .addCase(setModal, (state, { payload }) => {
      const { key, props } = payload;
      const currentModalProps = state?.[key] || {};
      return {
        ...state,
        [key]: {
          ...currentModalProps,
          ...props,
        },
      };
    })
    .addCase(mergeModal, (state, { payload }) => {
      const { key, width } = payload;
      const currentModalProps = state[key] || {};
      return {
        ...state,
        [key]: {
          ...currentModalProps,
          width,
        },
      };
    }),
);
