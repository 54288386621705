import React from 'react';
import { Input, Modal } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { SingleFormCard } from '@xbcb/display-components';
import { OfficerTitle, FormItem } from '@xbcb/form-item-components';
import { timeout, cleanString } from '@xbcb/js-utils';
import { PoaType } from '@xbcb/shared-types';
import { StyledSignFieldsDiv } from './styles';
import { invalidName } from '../lib/invalidName';

// const officerNameFieldMap = {
//   'direct-poa': 'officerName',
//   'master-poa': 'officerName',
//   'sub-poa': 'forwarderOfficerName',
//   subPOAAuthority: 'forwarderOfficerName',
//   cc: 'secondaryOfficerName',
// };
// const officerTitleFieldMap = {
//   'direct-poa': 'officerTitle',
//   'master-poa': 'officerTitle',
//   'sub-poa': 'forwarderOfficerTitle',
//   subPOAAuthority: 'forwarderOfficerTitle',
//   cc: 'secondaryOfficerTitle',
// };

// SignFields.propTypes = {
//   form: PropTypes.object.isRequired,
//   isFetching: PropTypes.bool,
//   type: PropTypes.string.isRequired,
//   initialValues: PropTypes.object.isRequired,
// };

interface StampBasedSignFieldsProps {
  form: FormInstance;
  disabled: boolean;
}

const StampBasedSignFields = (props: StampBasedSignFieldsProps) => {
  const { form, disabled } = props;
  if (!form) return null;
  const { getFieldValue } = form;

  const onBlur = async () => {
    await timeout(1000);
    const raw = getFieldValue('signerName');
    const value = cleanString(raw);
    if (raw !== value) {
      form.setFieldsValue({ signerName: value });
    }
    if (value && invalidName(value)) {
      Modal.warning({
        title: 'Invalid officer name',
        content: `Please enter the FULL name of an officer of the company.`,
      });
    }
  };

  const validateName = async (rule: any, value: string) => {
    if (invalidName(value)) {
      throw new Error('Invalid officer full name');
    }
  };

  return (
    <StyledSignFieldsDiv>
      <SingleFormCard
        title="Your Info"
        extraContent={
          <div className="space-right">
            You must be an officer or authorized to sign on behalf of an officer
            to sign this form. If confirmation you are an officer is not
            available based on public data, then additional verification will be
            requested.
          </div>
        }
      >
        <FormItem
          name="signerName"
          label="Officer Full Name"
          rules={[
            {
              validator: validateName,
              required: true,
              message: '',
            },
          ]}
        >
          <Input disabled={disabled} onBlur={onBlur} />
        </FormItem>
        <OfficerTitle
          localNamePath={['signerTitle']}
          fullNamePath={['signerTitle']}
          required={true}
          disabled={disabled}
          documentType={PoaType.MASTER_POA}
          form={form}
        />
      </SingleFormCard>
    </StyledSignFieldsDiv>
  );
};

export default StampBasedSignFields;
