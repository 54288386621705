import { get } from 'lodash';
import {
  type AdditionalFormError,
  type NamePath,
  ValidationErrorType,
} from '@xbcb/ui-types';

export const getMissingRequiredFieldErrors = (
  input: any,
  requiredFields: NamePath[],
): AdditionalFormError[] => {
  const errors: any[] = [];
  requiredFields.forEach((field) => {
    const val = get(input, field);
    if (typeof val === 'undefined' || val === '') {
      errors.push({
        title: 'Missing required field',
        type: ValidationErrorType.MISSING_FIELD,
        path: field,
        messages: [`${field.toString()} is required`],
      });
    }
  });

  return errors;
};
