import { recordFields, recordActionFields } from '@xbcb/shared-queries';
import {
  billingDetailsFields,
  shipperWorkOrderConfirmationConfigurationsFields,
  forwarderWorkOrderConfirmationConfigurationsFields,
} from '@xbcb/party-queries';

export const workOrderGroupFragment = `fragment workOrderGroupFields on WorkOrderGroup {
  ...recordFields
  workOrders {
    id
    ... on UsConsumptionEntry {
      releaseDate
    }
  }
}
`;

export const workOrderGroupFragments = `
${workOrderGroupFragment}
${recordFields}
${recordActionFields}
`;

export const workOrderGroupWithForwarderShipperFragment = `fragment workOrderGroupFields on WorkOrderGroup {
  ...recordFields
  shipper {
    id
    billingDetails {
      ...billingDetailsFields
    }
    workOrderConfirmationConfigurations {
      ...shipperWorkOrderConfirmationConfigurationsFields
    }
  }
  workOrders {
    id
    ... on UsConsumptionEntry {
      releaseDate
    }
  }
  forwarders {
    forwarder {
      id
      billingDetails {
        ...billingDetailsFields
      }
      workOrderConfirmationConfigurations {
        ...forwarderWorkOrderConfirmationConfigurationsFields
      }
      tags {
        key
        value
      }
    }
  }
}
`;

export const workOrderGroupWithForwarderShipperFragments = `
${workOrderGroupWithForwarderShipperFragment}
${recordFields}
${recordActionFields}
${billingDetailsFields}
${shipperWorkOrderConfirmationConfigurationsFields}
${forwarderWorkOrderConfirmationConfigurationsFields}
`;
