import { WorkOrderBadge } from '../workOrder/badge';

export const UsConsumptionEntryBadge = {
  ...WorkOrderBadge,
};

export const usConsumptionEntryBadgeDescription =
  'The Us Consumption Entry badge. Example: Hot';

export type UsConsumptionEntryBadge = keyof typeof UsConsumptionEntryBadge;
