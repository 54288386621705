import { get } from 'lodash';

export const selectFilter = (input, option) => {
  // if using JSX => fill the top div's prop search with the searchable text
  // i.e. <div search="find me"/>
  const searchProp = get(option, 'props.children.props.search');
  if (searchProp)
    return (
      searchProp.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  return (
    option.props.children
      .toString()
      .toLowerCase()
      .indexOf(input.toLowerCase()) >= 0 ||
    option.props.value.toString().toLowerCase().indexOf(input.toLowerCase()) >=
      0
  );
};
