import {
  SubjectMatterCategory,
  SubjectMatterSubCategory,
  SubjectMatterCategoryToSubCatoriesMap,
} from '@xbcb/shared-types';
import { EscalationReasonSubCategoryType } from '../../types';

export enum WorkOrderConfirmationTaskReason {
  PENDING_CONFIRMATION = 'PENDING_CONFIRMATION',
  APPROVED_CONFIRMATION = 'APPROVED_CONFIRMATION',
  DISPUTED_CONFIRMATION = 'DISPUTED_CONFIRMATION',
}

export enum WorkOrderTaskPaymentStatusReason {
  PENDING_PAYMENT = 'PENDING_PAYMENT',
}

export enum WorkOrderTaskPayerStatusReason {
  PAYER_ACTIVE = 'PAYER_ACTIVE',
  PAYER_SUSPENDED = 'PAYER_SUSPENDED',
}

export const WorkOrderTaskStatusReason = {
  ...WorkOrderConfirmationTaskReason,
  ...WorkOrderTaskPayerStatusReason,
  ...WorkOrderTaskPaymentStatusReason,
};

export type WorkOrderTaskStatusReason =
  | WorkOrderConfirmationTaskReason
  | WorkOrderTaskPayerStatusReason
  | WorkOrderTaskPaymentStatusReason;

export enum TaskEscalationType {
  ESCALATED_TO_TEAM_LEAD = 'ESCALATED_TO_TEAM_LEAD',
  ESCALATED_TO_SUBJECT_MATTER_EXPERT = 'ESCALATED_TO_SUBJECT_MATTER_EXPERT',
}

export enum ResolutionReasonCode {
  SOP_RESOURCE_PROVIDED = 'SOP_RESOURCE_PROVIDED',
  SOP_UPDATE_REQUESTED = 'SOP_UPDATE_REQUESTED',
  TEMPORARY_SYSTEM_ISSUE = 'TEMPORARY_SYSTEM_ISSUE',
  ESCALATE_TO_PROPER_POC = 'ESCALATE_TO_PROPER_POC',
  OTHER = 'OTHER',
}

// Add the category and sub category reason codes related to task esclation to below enum below
export enum EscalationReasonCategoryCode {
  GENERAL_ISSUE = 'GENERAL_ISSUE',
}

export enum EscalationReasonSubCategoryCode {
  DATA_INPUT_ISSUE = 'DATA_INPUT_ISSUE',
  ENTRY_SUBMISSION_ISSUE = 'ENTRY_SUBMISSION_ISSUE',
}

export const EscalationReasonCategory = {
  ...SubjectMatterCategory,
  ...EscalationReasonCategoryCode,
};

export const EscalationReasonSubCategory = {
  ...SubjectMatterSubCategory,
  ...EscalationReasonSubCategoryCode,
};
interface CategoryToSubcategoriesMap {
  [category: string]: EscalationReasonSubCategoryType[];
}

export const EscalationReasonCategoryToSubCatoriesMap: CategoryToSubcategoriesMap =
  {
    ...SubjectMatterCategoryToSubCatoriesMap,
    [EscalationReasonCategory.GENERAL_ISSUE]: [
      EscalationReasonSubCategory.DATA_INPUT_ISSUE,
      EscalationReasonSubCategory.ENTRY_SUBMISSION_ISSUE,
    ],
  };
