import { EsDataType } from '../../enums';
import { createDocumentSignRequestRecord } from './createDocumentSignRequestRecord';

export const stampDocumentSignRequest = createDocumentSignRequestRecord({
  columns: {
    sourceDocumentId: {
      name: 'Source Document ID',
      type: EsDataType.KEYWORD,
    },
    signedDocumentId: {
      name: 'Signed Document ID',
      type: EsDataType.KEYWORD,
    },
  },
});
