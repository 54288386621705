import React from 'react';
import { Input } from 'antd';
import { show, safeGetMessage } from '@xbcb/ui-utils';
import { FormInstance } from 'antd/lib/form';
import { StyledDiv, StyledTooltip } from './styles';
import {
  createDataCyValue,
  CssSize,
  DataCyPrefix,
  DataCySuffix,
} from '@xbcb/ui-types';
import FormItem from '../FormItem';
import { Rule } from 'rc-field-form/lib/interface';
import { useBundle } from '@amzn/react-arb-tools';

interface DUNSNumberProps {
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  form: FormInstance;
  $inline?: boolean;
  dataCySuffix?: DataCySuffix;
}

const validateDunsNumber = async (rule: Rule, value: string | number) => {
  const dunsRegex = /^\d{9}$/;
  if (
    value &&
    'UNK' !== value.toString() &&
    !dunsRegex.test(value.toString())
  ) {
    throw new Error('Invalid DUNS Number');
  }
};

const DUNSNumber: React.FC<DUNSNumberProps> = ({
  readOnly = false,
  disabled,
  form,
  $inline,
  className,
  dataCySuffix,
}: DUNSNumberProps) => {
  const [dunsBundle] = useBundle('components.formItemComponents.DUNSNumber');
  const field = 'duns';
  const showComponent = show({ readOnly, form, field });
  return showComponent ? (
    <StyledDiv $inline={$inline} className={className}>
      <FormItem
        $itemSize={CssSize.TINY_SHORT}
        $inline
        name={field}
        rules={[
          {
            validator: validateDunsNumber,
            required: false,
            message: ' ',
          },
        ]}
        // TODO For better UX, allow only valid characters to be input in duns number field i.e. UNK or 9 digit number
        // For this, need to fix getDunsNumber and remove validateDunsNumber()
        // getValueFromEvent={getDunsNumber}
        label={safeGetMessage(dunsBundle, 'duns_number')}
        $readOnly={readOnly}
        data-cy={createDataCyValue(DataCyPrefix.DUNS_NUMBER, dataCySuffix)}
      >
        <Input placeholder="NNNNNNNNN" disabled={disabled} />
      </FormItem>
      <StyledTooltip
        spaceTop
        title={safeGetMessage(dunsBundle, 'duns_number_tool_tip')}
      />
    </StyledDiv>
  ) : null;
};

export default DUNSNumber;
