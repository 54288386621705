/**
 * Represents the type of message received by XbcbMessageExchangeListener.
 */
export enum ExchangeMessageListenerType {
  INBOUND_CATAIR = 'INBOUND_CATAIR',
  INBOUND_CAMIR = 'INBOUND_CAMIR',
  INBOUND_DIS = 'INBOUND_DIS',
  INBOUND_EMAIL = 'INBOUND_EMAIL',
  INBOUND_X12 = 'INBOUND_X12',
  INBOUND_CDS = 'INBOUND_CDS',
  INBOUND_IRIS_S3 = 'INBOUND_IRIS_S3',
}
