import { LoadType, ModeOfTransport } from '@xbcb/shared-types';
import { createShipmentWorkOrderRecord } from './utils';
import { EsDataType } from '../../enums';
import { WorkOrderBadge } from '@xbcb/work-order-types';

export const customsDeclaration = createShipmentWorkOrderRecord({
  columns: {
    importCountryCode: {
      name: 'Import Country Code',
      type: EsDataType.KEYWORD,
    },
    arrivalLocationIdentification: {
      name: 'Arrival port',
      type: EsDataType.KEYWORD,
    },
    exportCountryCode: {
      name: 'Export Country Code',
      type: EsDataType.KEYWORD,
    },
    departureLocationIdentification: {
      default: true,
      name: 'Departure port',
      type: EsDataType.KEYWORD,
    },
    borderTransportationMeanName: {
      name: 'Transport Mean Name',
      type: EsDataType.TEXT,
    },
    borderTransportationMeanIdentificationType: {
      default: true,
      name: 'Transport Mean Type',
      type: EsDataType.KEYWORD,
      react: true,
      optionValues: Object.values(ModeOfTransport),
    },
    transportEquipmentIdentification: {
      name: 'Equipment Identification',
      type: EsDataType.TEXT,
    },
    exportDate: {
      default: true,
      name: 'Export Date',
      type: EsDataType.TIME,
    },
    importDate: {
      name: 'Import Date',
      type: EsDataType.TIME,
    },
    borderTransportationMeanJourney: {
      name: 'Journey #',
      type: EsDataType.KEYWORD,
    },
    invoiceNumber: {
      name: 'Invoice #',
      type: EsDataType.KEYWORD,
    },
    masterBill: {
      default: true,
      name: 'Master Bill #',
      type: EsDataType.TEXT,
    },
    houseBill: {
      default: true,
      name: 'House Bill #',
      type: EsDataType.TEXT,
    },
    bookingId: {
      default: true,
      name: 'Booking ID',
      type: EsDataType.KEYWORD,
    },
    transportEquipmentFullness: {
      name: 'Equipment Fullness',
      type: EsDataType.KEYWORD,
      optionValues: Object.values(LoadType),
    },
    importerId: { name: 'Importer Id', type: EsDataType.KEYWORD },
    importerName: { name: 'Importer Name', type: EsDataType.TEXT },
    badge: {
      name: 'Badge',
      type: EsDataType.KEYWORD,
      optionValues: Object.keys(WorkOrderBadge),
    },
    brokerName: {
      name: 'Agent Name',
      type: EsDataType.TEXT,
    },
  },
});
