import styled from 'styled-components';
import { Button } from 'antd';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import LockOutlined from '@ant-design/icons/LockOutlined';

// TODO we used this pattern a lot, address it
export type ButtonTypeProps = any;

export const StyledButton = styled<ButtonTypeProps>(Button)<{
  $color?: string;
}>`
  ${({ $color }) => ($color ? `color: ${$color};` : '')}
  border: none;
  top: 2px;
`;

export const StyledDocumentActionsDiv = styled.div`
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .ant-btn {
    box-shadow: none;
    background: none;
  }
  button:not(:hover):not(.ant-popover-open):not(:active) {
    &.doc-download,
    &.doc-edit,
    &.doc-info {
      color: var(--text-grey);
    }
    &.doc-delete {
      color: var(--bad);
    }
  }
  button:not(:last-child) {
    margin-right: var(--space-3);
  }
`;

export const StyledDeleteOutlined = styled(DeleteOutlined)`
  font-size: 20px;
  margin-right: var(--space-2);
  color: red;
`;

export const StyledLockOutlined = styled(LockOutlined)`
  font-size: 16px;
  color: var(--orange-1);
  margin-right: var(--space-4);
  margin-top: var(--space-3);
`;
