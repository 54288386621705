// FBA doesn't have subscription price tiering since the AGL forwarder is always billed
export const fbaContinuousBondRates: { [key: number]: number } = {
  50000: 350,
  60000: 360,
  70000: 370,
  80000: 380,
  90000: 390,
  100000: 400,
  200000: 562.5,
  300000: 781.25,
  400000: 1000,
  500000: 1218.75,
  600000: 1437.5,
  700000: 1656.25,
  800000: 1875,
  900000: 2093.75,
  1000000: 2312.5,
};
