import { UsIorNumberType } from '@xbcb/party-types';
import { AccountType } from '@xbcb/shared-types';

export type IorTypes =
  | UsIorNumberType.CBP
  | UsIorNumberType.SSN
  | UsIorNumberType.EIN;

export const labels = {
  EIN: 'Federal Tax ID (EIN)',
  SSN: 'Social Security No.',
  CBP: 'CBP Assigned No.',
};
export const placeholders = {
  EIN: 'NN-NNNNNNNXX',
  SSN: 'NNN-NN-NNNN',
  CBP: 'NNNNNN-NNNNN',
};
// Note: These masks are meant to strictly follow the pattern defined in the
// scalar used by the backend, UsIorNumberValue.
export const masks = {
  EIN: [
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /[a-zA-Z0-9]/,
    /[a-zA-Z0-9]/,
  ],
  SSN: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  CBP: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/],
};
export const tooltips = {
  EIN: 'Your Federal Tax ID is optional, but we will protect it with our lives. You do not need to enter it here, but we will need it to complete your application.',
  SSN: 'Your Social Security Number given by the Federal Government',
  CBP: "If you don't already have a CBP assigned number, leave this field blank and we will get you one at no additional cost.",
};

const confidentialIorNumberType = [UsIorNumberType.SSN, UsIorNumberType.EIN];
export const secureIorNumber = ({
  iorNumber,
  accountType,
  iorNumberType,
}: {
  iorNumber: string | number;
  iorNumberType: IorTypes;
  accountType?: AccountType;
}) => {
  if (typeof iorNumber !== 'string') return iorNumber;
  // iorNumber includes the hyphen
  if (
    accountType === AccountType.FORWARDER &&
    confidentialIorNumberType.includes(iorNumberType)
  ) {
    const fullMask = iorNumber.replace(/\d/g, 'X');
    return fullMask.slice(0, -4) + iorNumber.slice(-4);
  } else {
    return iorNumber;
  }
};
