export const guestUserAllowedQueryList = [
  'customDomainRuntimeInfo',
  'migrateUser',
  'decryptLoginLink',
  'linkAction',
  'stampDocumentSignRequest',
  'updateStampDocumentSignRequestWithSignature',
  'templateDocumentSignRequest',
  'updateTemplateDocumentSignRequestWithSignature',
  'publicTradeDataCodes',
  'createDocument',
  'updateDocument',
  'deleteDocument',
  'document',
  'searchDocuments',
  'usIor',
];
