// These are the parties integration partners. This should be as specific as possible and preferably client/end users name
export enum ExternalIntegrationPartner {
  CHAIN_IO = 'CHAIN_IO',
  CENTURY_DISTRIBUTION = 'CENTURY_DISTRIBUTION',
}

export enum ExternalIntegrationType {
  TRANSPORTATION_MANAGEMENT_SYSTEM = 'TRANSPORTATION_MANAGEMENT_SYSTEM',
  SNS = 'SNS',
}
