import { createWorkOrderRecord } from './utils';
import { iorNumber } from '../iorNumber';
import { EsDataType } from '../../index';

export const usIorActivation = createWorkOrderRecord({
  columns: {
    iorName: {
      default: true,
      name: 'IOR Name',
      type: EsDataType.TEXT,
    },
    iorNumber,
    iorId: { name: 'The IOR ID', type: EsDataType.KEYWORD, hidden: true },
    businessStructure: {
      name: 'Entity Structure',
      type: EsDataType.TEXT,
    },
    pmsStatus: { default: true, name: 'PMS Status', type: EsDataType.KEYWORD },
    cbpStatus: { name: 'CBP Status', type: EsDataType.KEYWORD },
    industry: { name: 'Industry', type: EsDataType.KEYWORD },
    activationStatus: {
      name: 'Activation Status',
      type: EsDataType.KEYWORD,
      default: true,
    },
    activationTime: {
      name: 'US IOR Activation Time',
      type: EsDataType.TIME,
    },
    poaMethod: {
      name: 'POA Signing Method',
      type: EsDataType.KEYWORD,
    },
    paymentMethod: {
      name: 'Payment Method',
      type: EsDataType.KEYWORD,
    },
    bondAnniversary: {
      name: 'Bond Anniversary',
      type: EsDataType.KEYWORD,
      default: true,
    },
    hasActiveBond: {
      name: 'Bond (Yes/No)',
      type: EsDataType.KEYWORD,
    },
  },
});
