import { recordFields, recordActionFields } from '@xbcb/shared-queries';

export const workOrderMilestoneFragment = `fragment workOrderMilestoneFields on WorkOrderMilestone {
  ...recordFields
  name
  source
  occurenceTime
  workOrder {
    id
  }
}
`;

export const workOrderMilestoneFragments = `
${workOrderMilestoneFragment}
${recordFields}
${recordActionFields}
`;
