import { ModeOfTransport } from './modeOfTransport';

export enum LoadType {
  LCL = 'LCL', // Less than container load
  FCL = 'FCL', // Full container load
  LTL = 'LTL', // Less than truck load
  FTL = 'FTL', // Full truck load
  AIR_FREIGHT = 'AIR_FREIGHT',
  SMALL_PARCEL = 'SMALL_PARCEL',
  PALLET = 'PALLET',
}

export const ModeOfTransportLoadTypeMap = {
  [ModeOfTransport.OCEAN]: [LoadType.FCL, LoadType.LCL],
  [ModeOfTransport.AIR]: [LoadType.AIR_FREIGHT, LoadType.SMALL_PARCEL],
  [ModeOfTransport.RAIL]: [],
  [ModeOfTransport.TRUCK]: [LoadType.FTL, LoadType.LTL],
};
