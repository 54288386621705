import { EsDataType } from '../../index';
import { createTableConfig } from '../createTableConfig';

export const adCvdCase = createTableConfig({
  columns: {
    caseNumber: {
      default: true,
      name: 'Case Number',
      type: EsDataType.TEXT,
    },
    htsNumbers: {
      name: 'HTS Numbers',
      type: EsDataType.KEYWORD,
    },
  },
  skipOperatorId: true,
});
