import document from './document';
import party from './party';
import product from './product';
import workOrder from './workOrder';
import invoice from './invoice';
import shipment from './shipment';
import client from './client';
import company from './company';
import bulkUpload from './bulkUpload';

export * from './document';
export * from './party';
export * from './product';
export * from './workOrder';
export * from './invoice';
export * from './shipment';
export * from './client';
export * from './company';
export * from './bulkUpload';

// TODO Tried to not type thids as any but got: Exported variable 'operatorUserPermissionMap' has or is using name 'ObjectType' from external module "XbcbShared/packages/client-types/src/permission/operatorUser/client" but cannot be named.
// Also tried explicitly typing the value as { [key: ObjectType]: any } but got: An index signature parameter type cannot be a union type. Consider using a mapped object type instead.
export const forwarderUserPermissions: any = {
  ...document,
  ...party,
  ...product,
  ...workOrder,
  ...invoice,
  ...shipment,
  ...client,
  ...company,
  ...bulkUpload,
};
