export enum CdkAppName {
  'xbcb-client-authority' = 'xbcb-client-authority',
  'xbcb-client-workflow' = 'xbcb-client-workflow',
  'xbcb-custom-domain-authority' = 'xbcb-custom-domain-authority',
  'xbcb-custom-domain-workflow' = 'xbcb-custom-domain-workflow',
  'xbcb-document-authority' = 'xbcb-document-authority',
  'xbcb-document-generation-workflow' = 'xbcb-document-generation-workflow',
  'xbcb-document-ingest-workflow' = 'xbcb-document-ingest-workflow',
  'xbcb-document-sign-workflow' = 'xbcb-document-signworkflow',
  'xbcb-finance-authority' = 'xbcb-finance-authority',
  'xbcb-finance-workflow' = 'xbcb-finance-workflow',
  'xbcb-gateway' = 'xbcb-gateway',
  'xbcb-importsign-ui' = 'xbcb-importsign-ui',
  'xbcb-lambda-layers' = 'xbcb-lambda-layers',
  'xbcb-message-exchange-authority' = 'xbcb-message-exchange-authority',
  'xbcb-message-exchange-listener' = 'xbcb-message-exchange-listener',
  'xbcb-message-exchange-workflow' = 'xbcb-message-exchange-workflow',
  'xbcb-mq-adapter' = 'xbcb-mq-adapter',
  'xbcb-new-app-ui' = 'xbcb-new-app-ui',
  'xbcb-notification-workflow' = 'xbcb-notification-workflow',
  'xbcb-party-authority' = 'xbcb-party-authority',
  'xbcb-party-workflow' = 'xbcb-party-workflow',
  'xbcb-product-authority' = 'xbcb-product-authority',
  'xbcb-qbo-inbound-adapter' = 'xbcb-qbo-inbound-adapter',
  'xbcb-search' = 'xbcb-search',
  'xbcb-shipment-authority' = 'xbcb-shipment-authority',
  'xbcb-shipment-workflow' = 'xbcb-shipment-workflow',
  'xbcb-trade-data-authority' = 'xbcb-trade-data-authority',
  'xbcb-trade-data-workflow' = 'xbcb-trade-data-workflow',
  'xbcb-work-order-authority' = 'xbcb-work-order-authority',
  'xbcb-work-order-workflow' = 'xbcb-work-order-workflow',
}
