import {
  ukEori,
  euEori,
  deVatNumber,
  nlVatNumber,
  gbVatNumber,
} from '@xbcb/regex';
import { Rule } from 'rc-field-form/lib/interface';
import { PartyRecordType } from '@xbcb/shared-types';
import { capitalCase } from 'change-case';

export const createEuNumberValidator =
  (
    iorType:
      | typeof PartyRecordType.DE_IOR
      | typeof PartyRecordType.GB_IOR
      | typeof PartyRecordType.NL_IOR
      | typeof PartyRecordType.FR_IOR,
    numberType: 'vat' | 'eori',
  ) =>
  async (rule: Rule, value: string | number) => {
    const iorTypeToRegexMap = {
      [PartyRecordType.DE_IOR]: { vat: deVatNumber, eori: euEori },
      [PartyRecordType.GB_IOR]: { vat: gbVatNumber, eori: ukEori },
      [PartyRecordType.NL_IOR]: { vat: nlVatNumber, eori: euEori },
      [PartyRecordType.FR_IOR]: { vat: nlVatNumber, eori: euEori },
    };
    const regex = iorTypeToRegexMap[iorType][numberType];
    if (value && !regex.test(value.toString()))
      throw new Error(`Invalid ${capitalCase(numberType)} Number`);
  };
