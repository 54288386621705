import { NoAccessPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const ShipperUserBulkMilestoneUploadRequestPermissionName = {
  ...NoAccessPermissionName,
};

export const ShipperUserBulkChargesUploadRequestPermissionName = {
  ...NoAccessPermissionName,
};

export const ShipperUserBulkComplianceUploadRequestPermissionName = {
  ...NoAccessPermissionName,
};

export default {
  [ObjectType.BULK_MILESTONE_UPLOAD_REQUEST]:
    ShipperUserBulkMilestoneUploadRequestPermissionName,
  [ObjectType.BULK_CHARGES_UPLOAD_REQUEST]:
    ShipperUserBulkChargesUploadRequestPermissionName,
  [ObjectType.BULK_CHARGES_UPLOAD_REQUEST]:
    ShipperUserBulkComplianceUploadRequestPermissionName,
};
