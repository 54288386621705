import localForage from 'localforage';
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client/core';
import { getEnv, reportError } from '@xbcb/ui-utils';
import { UiStage } from '@xbcb/ui-types';
import { CachePersistor, LocalForageWrapper } from 'apollo3-cache-persist';
import {
  cleanTypeName,
  onErrorLink,
  retryLink,
  splitLink,
  localStorageActiveBrokerIdLink,
} from './links';
import possibleTypesBeta from './possibleTypes-beta.json';
import possibleTypesGamma from './possibleTypes-gamma.json';
import possibleTypesProd from './possibleTypes-prod.json';

const possibleTypesMap = {
  [UiStage.LOCAL]: possibleTypesBeta,
  [UiStage.ALPHA]: possibleTypesBeta,
  [UiStage.BETA]: possibleTypesBeta,
  [UiStage.GAMMA]: possibleTypesGamma,
  [UiStage.PROD]: possibleTypesProd,
};

const { stage } = getEnv();

export const possibleTypes = possibleTypesMap[stage];

// https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
// Defining custom merge functions for record fields that don't have an ID and may be used in updating cache
// to prevent Apollo error: Cache data may be lost when replacing the %s field of a %s object.
const typePolicies = {
  Product: {
    fields: {
      complianceDetails: {
        merge: true,
      },
    },
  },
  UsConsumptionEntry: {
    fields: {
      ior: {
        merge: true,
      },
    },
  },
};

const cache = new InMemoryCache({ possibleTypes, typePolicies });

const ALLOWLISTED_QUERIES = [/customdomain/i];

interface CacheData {
  ROOT_QUERY?: Record<string, unknown>;
  [key: string]: unknown;
}

/**
 * Persist cached data to localForage. Using CachePersistor
 * allows us to have finer-grained control over cache behavior.
 * For now, this is an opt-in pattern. The `data` object below
 * will look something like:
 *
 * {
 *  ROOT_QUERY: {
 *    queryName: { ... cached response },
 *    anotherQuery: { ref: AnotherQuery:variable_12345 }
 *  },
 *  AnotherQuery:variable_12345: { ...cached response }
 * }
 *
 * For keys that we *do* want to include in the cache,
 * we match the query name (against both the base object and ROOT_QUERY)
 * and include in the filtered response. Everything else is removed to prevent
 * cluttering the cache and returning improperly cached responses.
 *
 */
export const cachePersistor = new CachePersistor({
  cache,
  storage: new LocalForageWrapper(localForage),
  persistenceMapper: async (data: string) => {
    // filter cached data and queries
    const cache: CacheData = {};
    const newRoot: CacheData['ROOT_QUERY'] = {};
    try {
      const parsed = JSON.parse(data);
      const root = parsed['ROOT_QUERY'] ?? {};
      Object.keys(parsed).forEach((currentKey) => {
        if (ALLOWLISTED_QUERIES.some((regex) => regex.test(currentKey))) {
          cache[currentKey] = parsed[currentKey];
        }
      });
      Object.keys(root).forEach((currentKey) => {
        if (ALLOWLISTED_QUERIES.some((regex) => regex.test(currentKey))) {
          newRoot[currentKey] = root[currentKey];
        }
      });
      const filtered = { ...cache, ROOT_QUERY: newRoot };
      return filtered;
    } catch (e) {
      reportError(`Error persisting Apollo cache data: ${e}`);
      return {};
    }
  },
});

export const client = new ApolloClient({
  // name are version are used for https://www.apollographql.com/docs/studio/client-awareness/
  name: `app-ui-${stage}`,
  version: process.env.REACT_APP_VERSION,
  link: ApolloLink.from([
    localStorageActiveBrokerIdLink,
    cleanTypeName,
    onErrorLink,
    retryLink,
    splitLink,
  ]),
  cache,
});
