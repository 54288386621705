export enum InvoiceStatus {
  UPCOMING = 'UPCOMING',
  PROCESSING = 'PROCESSING',
  UNPAID = 'UNPAID',
  PAID = 'PAID',
  PENDING_VOID = 'PENDING_VOID',
  VOID = 'VOID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  OVERDUE = 'OVERDUE',
  // When invoice is not yet created in AR/OFA
  AWAITING_CREATE = 'AWAITING_CREATE',
}
