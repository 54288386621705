import { NamePath } from '@xbcb/ui-types';
import { get, isEqual } from 'lodash';

// checks if any field provided in namePaths has changed
export const shouldUpdate =
  (namePaths: NamePath[], checkDeepEqual?: boolean) =>
  (prevValues: any, curValues: any) => {
    return namePaths.reduce(
      (acc, namePath) =>
        acc ||
        (checkDeepEqual
          ? !isEqual(get(prevValues, namePath), get(curValues, namePath))
          : get(prevValues, namePath) !== get(curValues, namePath)),
      false,
    );
  };
