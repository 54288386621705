import { RecordType, SubscriptionPaymentPeriod } from '@xbcb/shared-types';

export const subscriptionFeePriceTableObsolete = {
  [RecordType.SHIPPER]: {
    [SubscriptionPaymentPeriod.MONTHLY]: {
      LITE: 23,
      BASIC: 120,
      PRO: 300,
      ENTERPRISE: 600,
    },
    [SubscriptionPaymentPeriod.ANNUALLY]: {
      LITE: 228,
      BASIC: 1188,
      PRO: 2988,
      ENTERPRISE: 5988,
    },
  },
  [RecordType.FORWARDER]: {
    [SubscriptionPaymentPeriod.MONTHLY]: {
      LITE: 23,
      BASIC: 120,
      PRO: 300,
      ENTERPRISE: 600,
    },
    [SubscriptionPaymentPeriod.ANNUALLY]: {
      LITE: 228,
      BASIC: 1188,
      PRO: 2988,
      ENTERPRISE: 5988,
    },
  },
};
