import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

export const renewalDateFeature = 'RENEWAL_DATE_FEATURE';

const RENEWAL_DATE_FEATURE = new FeatureFlagService([
  {
    feature: renewalDateFeature,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA],
  },
]);

export { RENEWAL_DATE_FEATURE };
