import {
  WorkOrderGroup,
  BillingParty,
  WorkOrderType,
} from '@xbcb/api-gateway-client';
import { PreferredDutyCollectPartyType } from '@xbcb/finance-types';
import log from '@xbcb/log';
import { getPayerFromWorkOrderGroup } from './getPayer';
import { isPayingCustomer } from './isPayingCustomer';

export const getDutyPayer = (
  workOrderGroup: WorkOrderGroup,
  workOrderType: WorkOrderType,
): BillingParty | undefined => {
  const {
    shipment,
    id: workOrderGroupId,
    forwarders = [],
    shipper,
  } = workOrderGroup;

  const { preferredDutyCollectPartyType } = shipment || {};

  let payer;
  if (
    preferredDutyCollectPartyType === PreferredDutyCollectPartyType.FORWARDER
  ) {
    const forwarderWithPlan = forwarders.find((f) =>
      isPayingCustomer(f.forwarder),
    );
    if (forwarderWithPlan) {
      payer = forwarderWithPlan.forwarder;
    } else {
      log.info(
        `Can not find forwarder with subscription plan in work order group`,
        { group: workOrderGroupId },
      );
      // We do have the use case that payer does not have billingDetails,
      // so payer should also have no subscription plan
      if (forwarders.length) {
        payer = forwarders[0].forwarder;
      }
    }
  } else if (
    preferredDutyCollectPartyType === PreferredDutyCollectPartyType.BROKER &&
    shipper
  ) {
    if (!isPayingCustomer(shipper)) {
      log.info(
        `Can not find shipper with subscription plan in work order group `,
        { group: workOrderGroupId },
      );
    }
    payer = shipper;
  } else if (
    preferredDutyCollectPartyType === PreferredDutyCollectPartyType.CUSTOMS
  ) {
    log.info(
      'Shipment preferredDutyCollectPartyType is customs, not need to generate invoice',
    );
    return;
  }
  if (payer) return payer;
  payer = getPayerFromWorkOrderGroup(workOrderGroup, workOrderType);
  return payer;
};
