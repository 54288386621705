import React, { useRef } from 'react';
import { Select } from 'antd';
import { OptionFC } from 'rc-select/lib/Option';
import { useBundle } from '@amzn/react-arb-tools';
import { safeGetMessage } from '@xbcb/ui-utils';
import { SelectProps, SelectValue } from 'antd/lib/select';
import { get } from 'lodash';
import { StyledSelect } from './styles';

const { Option } = Select;

// should be typed as AntdSelect, but Antd has a bug. fixed here https://github.com/ant-design/ant-design/pull/27482 but not released yet
export const InternalClickSelect = React.forwardRef<
  any,
  SelectProps<SelectValue>
>(({ children, notFoundContent, ...threadedProps }, forwardedRef) => {
  const [bundle] = useBundle('shared');
  // should be typed as AntdSelect, but Antd has a bug. fixed here https://github.com/ant-design/ant-design/pull/27482 but not released yet
  const internalClickSelectRef = useRef<any>(null);
  const selectElement = forwardedRef || internalClickSelectRef;
  notFoundContent = notFoundContent ?? safeGetMessage(bundle, 'none_found');

  const handleFocus = (e: any) => {
    const getNode = get(selectElement, [
      'select',
      'rcSelect',
      'getInputDOMNode',
    ]);
    if (getNode) {
      const node = getNode();
      if (node) node.click();
    }
  };

  // TODO could not get this to work. Auto selects if only one option.
  // componentDidUpdate = () => {
  //   const { children = [], form, id } = this.props;
  //     get(children.length) === 1 &&
  //     get(this.selectElement, 'props.value') !== children[0].props.value
  //   )
  //     form.setFieldsValue({ [id]: children[0].props.value });
  // };

  return (
    <StyledSelect
      ref={selectElement}
      onFocus={handleFocus}
      notFoundContent={notFoundContent}
      {...threadedProps}
    >
      {children}
    </StyledSelect>
  );
});

const ClickSelect: typeof InternalClickSelect & {
  Option?: OptionFC;
} = InternalClickSelect;
ClickSelect.Option = Option;

export default ClickSelect as typeof InternalClickSelect & {
  Option: OptionFC;
};
