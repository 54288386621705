import styled from 'styled-components';

export const StyledDiv = styled.div`
  color: black;
  font-size: 14px;
  font-weight: 300;
  line-height: var(--space-4);
  text-align: left;
  transition: 0.3s opacity linear;
  height: var(--space-4);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
`;
