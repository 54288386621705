import { recordFields, recordActionFields } from '@xbcb/shared-queries';

/**
 * Light weight fragment, without the charges which makes a call to dowstream systems. Extend this fragment locally to fetch details from the remote server.
 */
export const payableInvoiceFields = `
fragment payableInvoiceFields on PayableInvoice {
  ...recordFields
  status
  serviceType
  payeeScac
  payee {
    id
  }
  workOrderGroup {
    id
  }
  externalReference {
    id
    source
  }
}
${recordFields}
${recordActionFields}`;
