import { get } from 'lodash';
import { ssn } from '@xbcb/regex';
import {
  UsCbp5106RelatedBusinessType,
  UsIorNumberType,
} from '@xbcb/party-types';
import {
  getCodes,
  isValidEIN,
  isValidCBPNumber,
  isValidIORNumber,
  isEin,
} from './';
import { AnyObject, RecordType } from '@xbcb/shared-types';
import { AppRecordValidateResult, AdditionalFormError } from '@xbcb/ui-types';
import { formatRecordName } from '@xbcb/js-utils';
const { CBP, SSN } = UsIorNumberType;

type UsIorNumberInput = {
  type: UsIorNumberType;
  value: string;
};

type UsCbp5106RelatedBusiness = {
  name: string;
  iorNumber: UsIorNumberInput;
  type: UsCbp5106RelatedBusinessType;
};

type validateCbp5106PartyProps = {
  input: AnyObject;
  recordType: typeof RecordType.US_IOR | typeof RecordType.US_CONSIGNEE;
  currentUser: AnyObject;
};

export const validateCbp5106Party = ({
  input,
  recordType,
  currentUser,
}: validateCbp5106PartyProps): AppRecordValidateResult => {
  const { accountType } = currentUser;
  const recordName = formatRecordName({
    recordType,
    accountType,
  });
  const iorName = formatRecordName({
    recordType: RecordType.US_IOR,
    accountType,
  });
  const cbpPortCodes = get(getCodes(), 'CBP.port');
  const {
    businessStructure,
    addresses,
    iorNumber,
    name = '',
    relatedBusinesses = [],
  } = input;
  const { physical, mailing } = addresses || {};
  const countryCode = physical ? physical.countryCode : mailing?.countryCode;
  const isUs = countryCode === 'US';
  const iorNumberValue = iorNumber?.value;
  const iorNumberType = iorNumber?.type;
  const additionalErrors: AdditionalFormError[] = [];

  const createIorErrorMessage = (iorType: string) =>
    `Hmm... ${iorNumberValue} isn't the correct format for a ${iorType}. Please fix before submitting.`;

  // EIN validation
  if (isEin(input) && iorNumberValue && !isValidEIN(iorNumberValue)) {
    additionalErrors.push({
      title: 'Invalid EIN',
      messages: [createIorErrorMessage('Federal Tax ID (EIN)')],
    });
  }
  // SSN validation
  if (iorNumberType === SSN && iorNumberValue && !ssn.test(iorNumberValue)) {
    additionalErrors.push({
      title: 'Invalid SSN',
      messages: [createIorErrorMessage('Social Security Number (SSN)')],
    });
  }
  // CBP Number validation
  if (
    iorNumberType === CBP &&
    iorNumberValue &&
    !isValidCBPNumber(iorNumberValue, cbpPortCodes)
  ) {
    additionalErrors.push({
      title: 'Invalid CBP',
      messages: [createIorErrorMessage('CBP Assigned Number (YYDDPP-NNNNN)')],
    });
  }
  relatedBusinesses.forEach((relatedBusiness: UsCbp5106RelatedBusiness) => {
    const {
      iorNumber: relatedBusinessIorNumber,
      name: relatedBusinessName = '',
    } = relatedBusiness;
    const relatedBusinessIorNumberValue = relatedBusinessIorNumber?.value;
    const createDuplicateInfoError = (fieldName: string) => ({
      title: `Same ${fieldName} as ${recordName}`,
      messages: [
        `A related business cannot have the same ${fieldName} as the ${recordName}. Please remove the related business if it is the same.`,
      ],
    });

    if (
      relatedBusinessName &&
      // TODO should this be `===` instead of includes?
      name.toLowerCase().includes(relatedBusinessName.toLowerCase())
    ) {
      additionalErrors.push(createDuplicateInfoError('name'));
    }
    if (relatedBusinessIorNumberValue === iorNumberValue) {
      additionalErrors.push(createDuplicateInfoError(`${iorName} number`));
    }

    if (
      relatedBusinessIorNumberValue &&
      !isValidIORNumber(relatedBusinessIorNumberValue, cbpPortCodes)
    ) {
      additionalErrors.push({
        title: '',
        messages: [
          `The related business ${iorName} number ${relatedBusinessIorNumberValue} is not a valid format.`,
        ],
      });
    }
  });

  return { additionalErrors, validateFields: [] };
};
