import moment from 'moment';
import { isDst } from './isDst';

/**
 * Interface for getNextHourOccurrence method and return with ISOString (UTC).
 * @param {number} hour The next hour to be occurred in EST (CBP Based hour). Ranges from 0 to 23.
 * @param {number} minute The next minute to be occurred in EST (CBP Based hour). Ranges from 0 to 59.
 */
export interface GetNextOccurrenceProps {
  hour: number;
  minute: number;
}

// Convert time to UTC ISO String
export const getNextCbpOccurrence = (props: GetNextOccurrenceProps) => {
  const { hour, minute } = props;
  if (![...Array(24).keys()].includes(hour)) {
    throw new Error(
      `[${hour}] is invalid next hour. Please enter hours from 0 to 23.`,
    );
  }
  if (![...Array(60).keys()].includes(minute)) {
    throw new Error(
      `[${minute}] is invalid next minute. Please enter minutes from 0 to 59.`,
    );
  }
  const now = moment();
  let hourInUtc = isDst(now.toDate()) ? hour + 4 : hour + 5;
  if (hourInUtc >= 24) {
    hourInUtc = hourInUtc % 12;
  }
  if (now.hour() > hourInUtc) {
    return now
      .clone()
      .set({ day: now.day() + 1, hour: hourInUtc, minute: minute, second: 0 })
      .toISOString();
  } else {
    return now
      .clone()
      .set({ day: now.day(), hour: hourInUtc, minute: minute, second: 0 })
      .toISOString();
  }
};
