import styled from 'styled-components';

export const StyledSignFormWrapper = styled.div`
  width: 848px;
  margin: 0 auto;

  .sign-form-typed {
    text-align: center;
    height: 120px;
    background-color: white;
    width: 100%;
    border-radius: var(--border-radius);
    border: 1px #d9d9d9 solid;
    margin: 0 auto 6px auto;
    color: black;
    span.sign-form-typed-text {
      font-family: Homemade Apple;
    }
  }

  .sign-box-wrapper p {
    margin-bottom: var(--space-3);
  }
  .ant-modal-footer {
    border-top: 0;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-tabs {
    border-radius: var(--border-radius);
  }
  .ant-tabs-nav {
    width: 100%;
  }
  .ant-tabs-nav .ant-tabs-tab {
    width: 33.33333333%;
    text-align: center;
    margin-right: 0;
  }
  .ant-tabs-tabpane {
    padding: var(--space-normal);
  }
  .sign-form-submit {
    width: 100%;
  }
  .sign-form-clear {
    position: absolute;
    top: 62px;
    right: 18px;
  }
  canvas#signature {
    border-radius: 4px;
    border: 1px #d9d9d9 solid;
  }
  .sign-form-upload {
    .ant-upload.ant-upload-drag {
      background-color: white;
    }
    .anticon-edit {
      font-size: 44px;
      margin-bottom: var(--space-normal);
      color: var(--highlight-red-violet);
    }
    img {
      max-width: 100%;
      max-height: 120px;
    }
  }
  .sign-form-user-agent {
    font-size: 12px;
  }

  .sign-form-signature-info p {
    margin-bottom: 0;
  }

  .sign-box-image {
    height: 75px;
  }

  .sign-box-signature {
    height: 75px;
    text-align: left;
  }
`;
