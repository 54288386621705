export enum AccountingSystemReferenceType {
  // qbo invoice id
  QBO_ID = 'QBO_ID',
  QBO_CUSTOMER_ID = 'QBO_CUSTOMER_ID',
  QBO_DOC_NUMBER = 'QBO_DOC_NUMBER',
  QBO_SYNC_TOKEN = 'QBO_SYNC_TOKEN',
  CLAIM_ID = 'CLAIM_ID',
  QBO_PAYMENT_ID = 'QBO_PAYMENT_ID',
  AR_CUSTOMER_ID = 'AR_CUSTOMER_ID',
  AR_INVOICE_ID = 'AR_INVOICE_ID',
  AR_ONBOARDING_COUNTRY = 'AR_ONBOARDING_COUNTRY',
}
