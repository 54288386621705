import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

export const euUkArIntegrationFeature = 'EU_UK_AR_INTEGRATION_FEATURE';

const EU_UK_AR_INTEGRATION_FEATURE = new FeatureFlagService([
  {
    feature: euUkArIntegrationFeature,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA, Stage.PROD],
  },
]);

export { EU_UK_AR_INTEGRATION_FEATURE };
