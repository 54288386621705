import { EsDataType } from '../../enums';
import { sharedCustomsEntryColumns } from './sharedCustomsEntry';
import { createShipmentWorkOrderRecord } from './utils';

export const gbCustomsEntry = createShipmentWorkOrderRecord({
  columns: {
    ...sharedCustomsEntryColumns,
    importEntryNumbers: {
      name: 'Import Entry Numbers',
      type: EsDataType.TEXT,
    },
  },
});
