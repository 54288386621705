import React from 'react';
import { Input } from 'antd';
import {
  show,
  createEuNumberValidator,
  formatString,
  safeGetMessage,
} from '@xbcb/ui-utils';

import { FormInstance } from 'antd/lib/form';
import { CssSize } from '@xbcb/ui-types';
import { FormItem } from '@xbcb/form-item-components';
import { PartyRecordType } from '@xbcb/shared-types';
import { useBundle } from '@amzn/react-arb-tools';

export type EuIorInfoCardProps = {
  recordType: PartyRecordType;
  disabled?: boolean;
  readOnly?: boolean;
  form: FormInstance;
  fullNamePath?: string[];
};

const EuIorInfoCard = ({
  recordType,
  readOnly = false,
  disabled,
  form,
  fullNamePath,
}: EuIorInfoCardProps) => {
  const [euIorInfoCardBundle] = useBundle(
    'components.formComponents.EuIorInfoCard',
  );
  const vatNumberField = fullNamePath
    ? [...fullNamePath, 'vatNumber']
    : ['vatNumber'];
  const eoriField = fullNamePath ? [...fullNamePath, 'eori'] : ['eori'];

  const showComponent =
    show({ readOnly, form, field: vatNumberField }) ||
    show({ readOnly, form, field: eoriField });

  if (!showComponent) return null;

  return (
    <>
      <FormItem
        $itemSize={CssSize.TINY_SHORT}
        $inline
        name={vatNumberField}
        rules={[
          {
            validator: createEuNumberValidator(
              recordType as
                | PartyRecordType.DE_IOR
                | PartyRecordType.GB_IOR
                | PartyRecordType.NL_IOR,
              'vat',
            ),
            required: false,
            message: ' ',
          },
        ]}
        label={safeGetMessage(euIorInfoCardBundle, 'vat_number')}
        $readOnly={readOnly}
      >
        <Input
          disabled={disabled}
          placeholder="NNNNNNNNNNN"
          onBlur={() => formatString(form, vatNumberField)}
        />
      </FormItem>
      <FormItem
        $itemSize={CssSize.TINY_SHORT}
        $inline
        name={eoriField}
        rules={[
          {
            validator: createEuNumberValidator(
              recordType as
                | PartyRecordType.DE_IOR
                | PartyRecordType.GB_IOR
                | PartyRecordType.NL_IOR,
              'eori',
            ),
            required: false,
            message: ' ',
          },
        ]}
        label={safeGetMessage(euIorInfoCardBundle, 'eori')}
        $readOnly={readOnly}
      >
        <Input
          disabled={disabled}
          placeholder="NNNNNNNNNNN"
          onBlur={() => formatString(form, eoriField)}
        />
      </FormItem>
    </>
  );
};

export default EuIorInfoCard;
