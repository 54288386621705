import React from 'react';
import dompurify from 'dompurify';

export interface RawHtmlProps {
  html: string;
}

const RawHtml: React.FC<RawHtmlProps> = ({ html }) => {
  const sanitizer = dompurify.sanitize;
  return <div dangerouslySetInnerHTML={{ __html: sanitizer(html) }} />;
};

export default RawHtml;
