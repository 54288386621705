import {
  iorNumberFields,
  passportFields,
  partyFragments,
  pointOfContactFields,
} from './party';

export const usCbp5106RelatedBusinessFields = `
fragment usCbp5106RelatedBusinessFields on UsCbp5106RelatedBusiness {
  name
  iorNumber {
    ...iorNumberFields
  }
  type
}`;

export const usCbp5106IndividualFields = `
fragment usCbp5106IndividualFields on UsCbp5106Individual {
  name
  title
  email
  phone {
    ...phoneFields
  }
  user {
    id
  }
  socialSecurityNumber
  passport {
    ...passportFields
  }
}`;

export const bankFields = `
fragment bankFields on UsCbp5106BankDetails {
  name
  city
  stateCode
  countryCode
  routingNumber
}`;

export const establishmentFields = `
fragment establishmentFields on UsCbp5106EstablishmentDetails {
  year
  countryCode
  stateCode
}`;

export const cbp5106AddressTypeFields = `
fragment cbp5106AddressTypeFields on UsCbp5106AddressType {
  code
  explanation
}`;

// not a proper gql fragment, just a shared string of fields to select
// TODO we should have usConsignee and usIor a Cbp5106 Interface
export const cbp5106Fields = `
  cbpStatus
  cbp5106RejectionReasons {
    name
    code
  }
  cbpNumberHelp
  cbpNumberReasons
  businessStructure
  businessDescription
  bank {
    ...bankFields
  }
  establishment {
    ...establishmentFields
  }
  entriesPerYearCode
  naics
  programCodes
  relatedBusinesses {
    ...usCbp5106RelatedBusinessFields
  }
  officer {
    ...pointOfContactFields
  }
  individuals {
    ...usCbp5106IndividualFields
  }
  cbpMailingAddressType {
    ...cbp5106AddressTypeFields
  }
  cbpPhysicalAddressType {
    ...cbp5106AddressTypeFields
  }
`;

export const cbp5106Fragments = `
${partyFragments}
${bankFields}
${establishmentFields}
${pointOfContactFields}
${passportFields}
${iorNumberFields}
${usCbp5106RelatedBusinessFields}
${usCbp5106IndividualFields}
${cbp5106AddressTypeFields}
`;
