import { cbp5106Fields, cbp5106Fragments } from './cbp5106';

const usConsigneeFragment = `
fragment usConsigneeFields on UsConsignee {
  ...recordFields
  ...partyFields
  iorNumber {
    ...iorNumberFields
  }
  ${cbp5106Fields}
  unknownDuns
}
`;

export const usConsigneeFragments = `
${usConsigneeFragment}
${cbp5106Fragments}
`;

// TODO deprecated name
export const usConsigneeFields = usConsigneeFragments;
