import React from 'react';
import { Tooltip } from 'antd';
import { StyledFormItem, StyledInfoCircleOutlined } from './styles';

type PropsForInlineTooltip = {
  // TODO switch to $spaceTop and $spaceRight
  spaceTop?: boolean;
  spaceRight?: boolean;
  title: string;
  className?: string;
};

const InlineTooltip: React.FC<PropsForInlineTooltip> = ({
  spaceTop,
  spaceRight,
  title,
  className = '',
}) => (
  <StyledFormItem
    $spaceTop={spaceTop}
    $spaceRight={spaceRight}
    className={className}
  >
    <Tooltip placement="right" title={title}>
      <StyledInfoCircleOutlined />
    </Tooltip>
  </StyledFormItem>
);

export default InlineTooltip;
