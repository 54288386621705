import { RetryLink } from '@apollo/client/link/retry';
import { Cognito } from '@xbcb/aws-utils';

export const retryLink = new RetryLink({
  delay: {
    initial: 200,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 6,
    retryIf: async (error) => {
      if (error?.message?.includes('Invalid login token')) {
        // Credentials have expired but Cognito session is still probably valid
        const authed = await Cognito.authUser({ force: true });
        // If this works we have a valid session & new credentials and should retry;
        // otherwise we know the retry will fail so no reason to try again.
        return Boolean(authed);
      }
      return true;
    },
  },
});
