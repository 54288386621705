import { AnyObject } from '@xbcb/shared-types';
import _ from 'lodash';

export const deepOmit = (obj: AnyObject, keysToOmit: string[] | string) => {
  const keysToOmitIndex = _.keyBy(
    Array.isArray(keysToOmit) ? keysToOmit : [keysToOmit],
  ); // create an index object of the keys that should be omitted

  const omitFromObject = (obj: AnyObject) => {
    // the inner function which will be called recursivley
    return _.transform(obj, (result: AnyObject, value, key) => {
      // transform to a new object
      if (key in keysToOmitIndex) {
        // if the key is in the index skip it
        return;
      }

      result[key] = _.isObject(value) ? omitFromObject(value) : value; // if the key is an object run it through the inner function - omitFromObject
    });
  };

  return omitFromObject(obj); // return the inner function result
};
