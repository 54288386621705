import {
  ssn as ssnRegex,
  ein as einRegex,
  einShort as einShortRegex,
  cbp as cbpAssignedRegex,
} from '@xbcb/regex';

export const validateIorNumber = (iorNumber: string) => {
  return (
    einRegex.test(iorNumber) ||
    einShortRegex.test(iorNumber) ||
    ssnRegex.test(iorNumber) ||
    cbpAssignedRegex.test(iorNumber)
  );
};
