import {
  CrudPermissionName,
  UsInBondPermissionName,
  UsIsfPermissionName,
} from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const ShipperUserUsIsfPermissionName = {
  ...UsIsfPermissionName,
};

export const ShipperUserUsConsumptionEntryPermissionName = {
  ...CrudPermissionName,
};

export const ShipperUserUsType86EntryPermissionName = {
  ...CrudPermissionName,
};

export const ShipperUserUsPostSummaryCorrectionPermissionName = {
  ...CrudPermissionName,
};

export const ShipperUserUsInBondPermissionName = {
  ...UsInBondPermissionName,
};
export const ShipperUserDeliveryOrderPermissionName = {
  ...CrudPermissionName,
};

export const ShipperUserUsIorContinuousBondRequestPermissionName = {
  ...CrudPermissionName,
};

export const ShipperUserUsDrawbackClaimPermissionName = {
  ...CrudPermissionName,
};

export const ShipperUserUsIorActivationPermissionName = {
  ...CrudPermissionName,
};

export const ShipperUserGbCustomsEntryPermissionName = {
  ...CrudPermissionName,
};

export const ShipperUserDeCustomsEntryPermissionName = {
  ...CrudPermissionName,
};

export const ShipperUserNlCustomsEntryPermissionName = {
  ...CrudPermissionName,
};

export const ShipperUserFrCustomsEntryPermissionName = {
  ...CrudPermissionName,
};

export default {
  [ObjectType.US_ISF]: ShipperUserUsIsfPermissionName,
  [ObjectType.US_CONSUMPTION_ENTRY]:
    ShipperUserUsConsumptionEntryPermissionName,
  [ObjectType.US_POST_SUMMARY_CORRECTION]:
    ShipperUserUsPostSummaryCorrectionPermissionName,
  [ObjectType.US_IN_BOND]: ShipperUserUsInBondPermissionName,
  [ObjectType.US_IOR_CONTINUOUS_BOND_REQUEST]:
    ShipperUserUsIorContinuousBondRequestPermissionName,
  [ObjectType.US_DRAWBACK_CLAIM]: ShipperUserUsDrawbackClaimPermissionName,
  [ObjectType.US_IOR_ACTIVATION]: ShipperUserUsIorActivationPermissionName,
  [ObjectType.DELIVERY_ORDER]: ShipperUserDeliveryOrderPermissionName,
  [ObjectType.GB_CUSTOMS_ENTRY]: ShipperUserGbCustomsEntryPermissionName,
  [ObjectType.DE_CUSTOMS_ENTRY]: ShipperUserDeCustomsEntryPermissionName,
  [ObjectType.NL_CUSTOMS_ENTRY]: ShipperUserNlCustomsEntryPermissionName,
  [ObjectType.FR_CUSTOMS_ENTRY]: ShipperUserFrCustomsEntryPermissionName,
  [ObjectType.US_TYPE86_ENTRY]: ShipperUserUsType86EntryPermissionName,
};
