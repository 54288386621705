import React from 'react';
import { Button, Spin } from 'antd';
import { StyledDiv } from './styles';
import { UiStage } from '@xbcb/ui-types';

interface LoadingProps {
  retry?: (e: React.MouseEvent) => void;
  isLoading?: boolean;
  error?: any;
  timedOut?: any;
  isComponent?: boolean;
}

const Loading = (props: LoadingProps) => {
  const { retry, isLoading, error, timedOut, isComponent } = props;

  // Handle the loading state
  if (isLoading) {
    return isComponent ? null : (
      <StyledDiv className="content">
        <StyledDiv className="content-main">
          <Spin className="loading-spin" />
        </StyledDiv>
      </StyledDiv>
    );
  }
  // Handle the error state
  else if (error || timedOut) {
    // eslint-disable-next-line no-console
    console.log(error);
    return isComponent ? (
      <Button onClick={retry}>Retry</Button>
    ) : (
      <StyledDiv className="content">
        <div className="content-header">
          <h1>Uh oh</h1>
        </div>
        <StyledDiv className="content-main">
          <div>
            <p>Sorry, there was a problem loading the page.</p>
            {process.env.REACT_APP_STAGE !== UiStage.PROD && (
              <p>{error.toString()}</p>
            )}
          </div>
        </StyledDiv>
      </StyledDiv>
    );
  } else {
    return null;
  }
};

export default Loading;
