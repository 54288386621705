import pluralize from 'pluralize';
import * as changeCase from 'change-case';
import { GraphQLResponse } from 'apollo-server-types';
import { ObjectType, RecordType } from '@xbcb/shared-types';
import { recordFields, recordActionFields } from './fields';
import _ from 'lodash';

export interface FieldsAndFragments {
  fields?: string;
  fragments?: string;
}

export interface RecordProps extends FieldsAndFragments {
  recordName: RecordType;
}

// also applicable to record interface
export const getRecordName = (
  recordName: ObjectType,
  type: 'camel' | 'pascal' = 'pascal',
) => {
  const RecordName =
    type === 'camel'
      ? changeCase.camelCase(recordName.toString())
      : changeCase.pascalCase(recordName.toString());
  return RecordName;
};

export const record = `
  ${recordFields}
  ${recordActionFields}
`;

export type QueryType = 'get' | 'gets' | 'getAll';
export type CrudType = 'create' | 'update' | 'delete' | QueryType;
export type MutationType = 'create' | 'update' | 'delete';
export type SearchType = 'search' | 'table';

export const constructRecordPath = ({
  recordName,
  crudOrSearchType,
  isRelationshipRecord,
}: {
  recordName: RecordType;
  crudOrSearchType: CrudType | SearchType;
  isRelationshipRecord?: boolean;
}) => {
  const camelRecordName = getRecordName(recordName, 'camel');
  const pascalRecordName = getRecordName(recordName);
  const mutateRecordPath = [
    `${crudOrSearchType}${pascalRecordName}`,
    isRelationshipRecord ? 'relationship' : 'record',
  ];
  const queryTypeToRecordNameMap = {
    create: mutateRecordPath,
    delete: mutateRecordPath,
    get: [camelRecordName],
    gets: [pluralize(camelRecordName)],
    getAll: [`all${pluralize(pascalRecordName)}`],
    search: [`search${pluralize(pascalRecordName)}`],
    table: [`search${pascalRecordName}Table`],
    update: mutateRecordPath,
  };
  return queryTypeToRecordNameMap[crudOrSearchType];
};

export const getRecordFromResponseV2 = ({
  response,
  crudOrSearchType = 'get',
  recordName,
  isRelationshipRecord,
}: {
  response: GraphQLResponse;
  crudOrSearchType: CrudType | SearchType;
  recordName: RecordType;
  isRelationshipRecord?: boolean;
}) => {
  const path = ['data'];
  const recordPath = constructRecordPath({
    crudOrSearchType,
    recordName,
    isRelationshipRecord,
  });
  path.push(...recordPath);
  return _.get(response, path);
};

export const getRecordFromResponse = (
  response: GraphQLResponse,
  type: CrudType = 'get',
  recordName: RecordType,
) => {
  const RecordName = getRecordName(recordName);
  const path = ['data'];
  if (type === 'get') {
    path.push(getRecordName(recordName, 'camel'));
  } else {
    path.push(`${type}${RecordName}`, 'record');
  }
  return _.get(response, path);
};

export const getRecordIdFromResponse = (
  response: GraphQLResponse,
  type: CrudType = 'get',
  recordName: RecordType,
) => {
  return _.get(getRecordFromResponse(response, type, recordName), 'id');
};

export const getSearchResultFromResponse = (
  response: GraphQLResponse,
  type: SearchType = 'search',
  recordName: RecordType,
) => {
  const RecordName = getRecordName(recordName);
  const path = ['data'];
  if (type === 'search') {
    path.push(`search${pluralize(RecordName)}`);
  } else {
    path.push(`search${RecordName}Table`);
  }
  return _.get(response, path);
};

export const dedupRecordQuery = (fields: string, fragments: string) => {
  const fieldsString = fields.includes('...recordFields')
    ? fields
    : `...recordFields ${fields}`;
  const fragmentsString =
    fragments.includes('recordFields') &&
    fragments.includes('recordActionFields')
      ? fragments
      : `${record} ${fragments}`;

  return { fieldsString, fragmentsString };
};
