export const dashboardDataLabels = {
  IN_PROGRESS: 'In Progress',
  UNASSIGNED: 'Unassigned',
  BEGINNER: 'Beginner',
  INTERMEDIATE: 'Intermediate',
  ADVANCED: 'Advanced',
  BLOCKED: 'Blocked',
  ESCALATED: 'Escalated',
  SLA_AT_RISK: 'Sla at risk',
  OVERDUE: 'Overdue',
};
