/**
 * This enum is meant to capture our CBMS specific cases for sending emails/
 * notifications through NW.
 */
export enum ButterCmsEventKey {
  // EMAILS
  'cbms.reportReconciliationAlert' = 'cbms.reportReconciliationAlert',
  'cbms.onboardingWelcomeEmail' = 'cbms.onboardingWelcomeEmail',
  'cbms.customerOnboarded' = 'cbms.customerOnboarded',
  'shipments.chainIoErrors' = 'shipments.chainIoErrors',
  'shipments.statementAuthorizationError' = 'shipments.statementAuthorizationError',
  'shipments.statementAuthorizationMissed' = 'shipments.statementAuthorizationMissed',
  'shipments.lateFilingIndicatorReceived' = 'shipments.lateFilingIndicatorReceived',
  'importers.requestPMS' = 'importers.requestPMS',
  'cbms.workOrderInquire' = 'cbms.workOrderInquire',
  'cbms.workOrderInquireRejectPoa' = 'cbms.workOrderInquireRejectPoa',
  'importers.rejectPMS' = 'importers.rejectPMS',
  'importers.rejectManifestConfidentiality' = 'importers.rejectManifestConfidentiality',
  // User must provide subject, text, and html in the variable map when using the generic template.
  'cbms.generic' = 'cbms.generic',
  'cbms.entryConfirmationApproved' = 'cbms.entryConfirmationApproved',
  'cbms.entryConfirmationDisputed' = 'cbms.entryConfirmationDisputed',
  'cbms.signPoaReminder' = 'cbms.signPoaReminder',
  'cbms.bondRenewalReminder' = 'cbms.bondRenewalReminder',
  'cbms.bondRenewalInvoiced' = 'cbms.bondRenewalInvoiced',
  'cbms.newBondInvoiced' = 'cbms.newBondInvoiced',
  'cbms.prepaidInvoice' = 'cbms.prepaidInvoice',

  // isf
  'shipments.isfFiled' = 'shipments.isfFiled',
  'shipments.isfRejected' = 'shipments.isfRejected',
  'shipments.isfNoBillMatch' = 'shipments.isfNoBillMatch',

  // shipment
  'shipments.created' = 'shipments.created',
  'shipments.canceled' = 'shipments.canceled',
  'shipments.forwarderTagged' = 'shipments.forwarderTagged',

  // entry
  'shipments.entryConfirmation' = 'shipments.entryConfirmation',
  'shipments.entryFiled' = 'shipments.entryFiled',
  'shipments.entryRevised' = 'shipments.entryRevised',
  'shipments.customsRelease' = 'shipments.customsRelease',
  'shipments.customsHold' = 'shipments.customsHold',
  'shipments.customsExam' = 'shipments.customsExam',
  'shipments.fdaNotice' = 'shipments.fdaNotice',
  'shipments.fdaNoticeCleared' = 'shipments.fdaNoticeCleared',
  'shipments.pgaRelease' = 'shipments.pgaRelease',
  'shipments.pscApproved' = 'shipments.pscApproved',
  'shipments.pgaHold' = 'shipments.pgaHold',
  'shipments.dailyStatement' = 'shipments.dailyStatement',
  'shipments.monthlyStatement' = 'shipments.monthlyStatement',
  'shipments.terminalRelease' = 'shipments.terminalRelease',

  // psc
  'shipments.pscFiled' = 'shipments.pscFiled',

  // shipper
  'importers.activated' = 'importers.activated',
  'importers.pmsApproved' = 'importers.pmsApproved',
  'importers.monthlyDutyReport' = 'importers.monthlyDutyReport',

  // inBond
  'shipments.inBondAuthorized' = 'shipments.inBondAuthorized',
  'shipments.inBondAction' = 'shipments.inBondAction',

  // forwarder
  'forwarder.inviteAccepted' = 'forwarder.inviteAccepted',

  // delivery order
  'shipments.doIssued' = 'shipments.doIssued',

  // users
  'users.invited' = 'users.invited',
  'users.resetPassword' = 'users.resetPassword',

  // invoices
  'cbms.invoiceCreditNote' = 'cbms.invoiceCreditNote',
  'cbms.invoiceReminder' = 'cbms.invoiceReminder',
  'cbms.externalInvoiceReminder' = 'cbms.externalInvoiceReminder',
  'cbms.subscriptionInvoice' = 'cbms.subscriptionInvoice',
  'cbms.workOrderGroupInvoice' = 'cbms.workOrderGroupInvoice',
  'cbms.workOrderGroupInvoiceFba' = 'cbms.workOrderGroupInvoiceFba',
  'cbms.workOrderGroupContinuousBondInvoice' = 'cbms.workOrderGroupContinuousBondInvoice',
  'cbms.voidInvoice' = 'cbms.voidInvoice',
  'cbms.requestForPayment' = 'cbms.requestForPayment',

  // statement authorization strikes
  'cbms.invoiceNonPaymentFirstStrike' = 'cbms.invoiceNonPaymentFirstStrike',
  'cbms.invoiceNonPaymentSecondStrike' = 'cbms.invoiceNonPaymentSecondStrike',
  'cbms.invoiceNonPaymentFinalStrike' = 'cbms.invoiceNonPaymentFinalStrike',
  'cbms.pmsInvoiceNonPaymentActioned' = 'cbms.pmsInvoiceNonPaymentActioned',
}
