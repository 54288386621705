import { partyFragments } from './party';
const gbConsigneeFragment = `
fragment gbConsigneeFields on GbConsignee {
  ...recordFields
  ...partyFields
}
`;

export const gbConsigneeFragments = `
${gbConsigneeFragment}
${partyFragments}
`;
