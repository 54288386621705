export const loopingDates = async (
  createdTimeStart: string,
  createdTimeEnd: string,
  period: number,
  callback: Function,
) => {
  const startDate: Date = new Date(createdTimeStart);
  const finalEndDate: Date = new Date(createdTimeEnd);
  while (startDate < finalEndDate) {
    const endDate = new Date(
      startDate.getTime() + period * 24 * 60 * 60 * 1000,
    );
    if (endDate > finalEndDate) endDate.setTime(finalEndDate.getTime());
    console.log(
      'Running callback function for startDate : ' +
        startDate.toISOString() +
        ' and endDate : ' +
        endDate.toISOString(),
    );
    await callback(startDate.toISOString(), endDate.toISOString());
    startDate.setTime(endDate.getTime());
  }
};
