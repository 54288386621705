import { createAction } from '@reduxjs/toolkit';
import { AnyObject } from '@xbcb/shared-types';

interface Record extends AnyObject {
  id: string;
}

export const PUT_RECORD = 'PUT_RECORD';

export const putRecord = createAction<Record, typeof PUT_RECORD>(PUT_RECORD);
