import { RecordType } from '@xbcb/shared-types';
import {
  DocumentTag,
  DocumentTagByRecordType,
  CountryLevelRecordDocumentTags,
} from '../enums';

/**
 * Returns the corresponding document tags for the input RecordType (and CountryCode if applicable).
 * @param {RecordType} recordType
 * @param {string} countryCode sub-key used to access the DocumentTags if the record type exists in the CountryLevelRecordDocumentTags mapping
 * @return {DocumentTag[]} the RecordType's corresponding DocumentTags (an empty array if none exist)
 * @throws {Error} if the provided recordType exists in the CountryLevelRecordDocumentTags mapping but a countryCode was not provided
 */
export const getDocumentTagForRecordType = (
  recordType: RecordType,
  countryCode?: string,
): DocumentTag[] => {
  let tags: string[] = [];
  if (recordType in CountryLevelRecordDocumentTags) {
    if (!countryCode)
      throw new Error(
        'Record type requires a country code to access document tag config',
      );

    tags = CountryLevelRecordDocumentTags[recordType][countryCode];
  } else {
    tags =
      DocumentTagByRecordType[
        recordType as keyof typeof DocumentTagByRecordType
      ];
  }
  return (tags ?? []) as DocumentTag[];
};
