import { ActivationStatus, CountryIor } from '@xbcb/party-types';
export const getIsIorActivated = ({
  ior,
  customsBrokerId,
}: {
  ior?: CountryIor;
  customsBrokerId: string;
}): boolean =>
  Boolean(
    ior &&
      (ior.activations?.some(
        ({ status, customsBroker }) =>
          status === ActivationStatus.ACTIVATED &&
          customsBroker?.id === customsBrokerId,
        // TODO deprecate once we no longer support the UsIor "fallback" case for FBA
      ) ||
        ('activationStatus' in ior &&
          ior.activationStatus === ActivationStatus.ACTIVATED)),
  );
