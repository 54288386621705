import { EsDataType } from '../enums';
import { createTableConfig } from './createTableConfig';
const columns = {
  accountId: {
    name: 'Account Id',
    type: EsDataType.KEYWORD,
    hidden: true,
  },
  accountName: {
    default: true,
    name: 'Account Name',
    type: EsDataType.TEXT,
  },
  status: {
    default: true,
    name: 'Template Status',
    type: EsDataType.KEYWORD,
  },
  templateType: {
    default: true,
    name: 'Template Type',
    type: EsDataType.KEYWORD,
  },
  approvedTime: {
    default: true,
    name: 'Approved Time',
    type: EsDataType.TIME,
  },
  rejectedTime: {
    default: true,
    name: 'Rejected Time',
    type: EsDataType.TIME,
  },
  activatedTime: {
    default: true,
    name: 'Activated Time',
    type: EsDataType.TIME,
  },
};
export const documentTemplate = createTableConfig({
  columns,
});
