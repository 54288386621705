import { SortOrder } from '@xbcb/shared-types';
import { shipperId, shipperName } from './party';
import { createPartyTable } from './createPartyTable';

export const frConsignee = createPartyTable({
  columns: {
    shipperName,
    shipperId,
  },
  tableOptions: {
    sortOrder: SortOrder.DESC,
  },
});
