import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

const largeDrawbackClaimFeatureName = 'LARGE_DRAWBACK_CLAIM_FEATURE';

const LARGE_DRAWBACK_CLAIM_FEATURE = new FeatureFlagService([
  {
    feature: largeDrawbackClaimFeatureName,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA],
  },
]);

export { LARGE_DRAWBACK_CLAIM_FEATURE, largeDrawbackClaimFeatureName };
