import { SortOrder, AccountType, AnyObject } from '@xbcb/shared-types';
import { createPartyTable } from './createPartyTable';
import { EsDataType } from '../../index';
import { shipperName, shipperId } from './party';

export const importerColumns: AnyObject = {
  shipperName,
  shipperId,
  forwarderName: {
    default: true,
    name: 'Forwarder',
    type: EsDataType.TEXT,
    blacklist: [AccountType.FORWARDER],
  },
  forwarderId: {
    name: 'Forwarder ID',
    type: EsDataType.KEYWORD,
    hidden: true,
  },
  identifier: {
    name: 'Identifier',
    type: EsDataType.KEYWORD,
  },
  email: {
    name: 'Email',
    type: EsDataType.TEXT,
  },
};

export const importer = createPartyTable({
  columns: importerColumns,
  tableOptions: {
    sortOrder: SortOrder.DESC,
  },
});
