import moment from 'moment';
import accounting from 'accounting';
import { pascalCase } from 'change-case';
/**
 * Capitalizes the first letter of a string
 * @param {string} str the input string
 * @return {string} the capitalized string
 */
export const titleCase = (str: string): string => {
  return pascalCase(str);
};
export const roundDate = (iso: string) =>
  iso && typeof iso === 'string' ? iso.substr(0, 10) : iso;

export const roundMonth = (iso: string) => iso.substr(5, 2);

export const formatCurrency = (num: string, symbol: string, decimals = 2) =>
  typeof num !== 'undefined'
    ? accounting.formatMoney(num, symbol, decimals)
    : num;

export const formatDollars = (num: string, decimals = 2) =>
  formatCurrency(num, '$', decimals);

export const formatDate = (isoString: string) =>
  moment(isoString).format('MM/DD/YY');
export const formatBool = (bool: boolean | undefined | null) =>
  bool ? 'Yes' : 'No';

export const formatPhone = (phone: {
  country?: string;
  number: string;
  extension?: string;
}) => {
  const { country = '', number, extension = '' } = phone;
  const isCountryPresent = country && country.length;
  const isExtensionPresent = extension && extension.length;
  return `${isCountryPresent ? country : ''}${
    isCountryPresent ? ' ' : ''
  }${number}${isExtensionPresent ? ' x' : ''}${
    isExtensionPresent ? extension : ''
  }`;
};

export const formatCbpPhone = (phone: {
  country?: string;
  number: string;
  extension?: string;
}) => {
  const { country = '', number } = phone || {};
  const isCountryPresent = country && country.length;
  return `${isCountryPresent ? country : ''}${
    isCountryPresent ? ' ' : ''
  }${number?.replace(/[^\d]/g, '')}`;
};

export const formatBytes = (bytes: number, isMetric = true) => {
  const thresh = isMetric ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) return bytes + ' B';
  const units = isMetric
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + ' ' + units[u];
};

/**
 * Truncates the input string and trims leading and trailing spaces
 * @param {string} str the input string
 * @param {number} length the truncated string length
 * @return {string}  the truncated string
 */
export const truncateString = (str: string, length: number): string => {
  return str.slice(0, length).trim();
};
