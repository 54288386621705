export enum OperatorUserTeamRole {
  OPERATOR = 'OPERATOR',
  PRIMARY_TEAM_LEAD = 'PRIMARY_TEAM_LEAD',
  SECONDARY_TEAM_LEAD = 'SECONDARY_TEAM_LEAD',
  SUBJECT_MATTER_EXPERT = 'SUBJECT_MATTER_EXPERT',
  VIEWER = 'VIEWER',
}

export const OperatorUserTeamRoleDescription =
  'Role of the OperatorUser in a team. Ex: PRIMARY TEAM LEAD';
