import gql from 'graphql-tag';
import { pluralize } from '@xbcb/js-utils';
import {
  ObjectType,
  DateSearchOperator,
  KeywordSearchOperator,
  SearchSortingOption,
  Tag,
} from '@xbcb/shared-types';
import { getRecordName, RecordProps } from '../shared';

interface SearchProps extends Omit<RecordProps, 'recordName'> {
  recordName: ObjectType;
}

export const SearchQuery = ({
  recordName,
  fields = '',
  fragments = '',
}: SearchProps) => {
  const RecordName = getRecordName(recordName);
  const pluralizedRecordName = pluralize(RecordName);
  return gql`
    query search${pluralizedRecordName}($input: Search${RecordName}Input!) {
      search${pluralizedRecordName}(input: $input) {
        results {
          ${fields}
        }
        paginationInfo {
          pageNumber
          pageSize
          totalPages
        }
      }
    }
    ${fragments}
  `;
};

export const createSearchQueryVariables = ({
  deletedTimeExists,
  operatorId,
  sortOptions,
  tags,
}: {
  deletedTimeExists?: boolean;
  operatorId: string;
  sortOptions: SearchSortingOption[];
  tags: Tag[];
}) => ({
  input: {
    searchCriteria: {
      tags: {
        values: tags,
        operator: KeywordSearchOperator.ONE_OF,
      },
      operatorId: {
        values: [operatorId],
        operator: KeywordSearchOperator.EQUALS,
      },
      deletedTime: {
        operator: deletedTimeExists
          ? DateSearchOperator.EXISTS
          : DateSearchOperator.DOES_NOT_EXIST,
      },
    },
    sortOptions,
  },
});

export const DashboardStatsQuery = ({ recordName }: SearchProps) => {
  const RecordName = getRecordName(recordName);
  const pluralizedRecordName = pluralize(RecordName);
  return gql`
    query search${pluralizedRecordName}($input: Search${RecordName}Input!) {
      search${pluralizedRecordName}(input: $input) {
        totalResults
      }
    }
  `;
};
