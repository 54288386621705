import * as changeCase from 'change-case';

export const constantCase = (recordName: string) => {
  // override the change-case/constant-case default split regex

  // For any Type86 record or transactions, use default split regex
  if (
    recordName.includes('usType86Entry') ||
    recordName.includes('UsType86Entry')
  ) {
    return changeCase.constantCase(recordName);
  }

  return changeCase.constantCase(recordName, {
    splitRegexp: [
      // "camelCase" -> "CAMEL_CASE"
      /([a-z0-9])([A-Z])/g,
      // "CAMELCase" -> "CAMEL_CASE"
      /([A-Z])([A-Z][a-z])/g,
      // "camel123Case" -> "CAMEL_123_CASE"
      /([a-z])([0-9])/g,
    ],
  });
};
