import { DocumentTag, DocumentFileExtension } from '@xbcb/document-types';

type SupportedDocumentTypes = {
  [key in DocumentFileExtension]?: DocumentTag[];
};

const supportedDocumentTypes: SupportedDocumentTypes = {
  [DocumentFileExtension.PDF]: [
    DocumentTag.COMMERCIAL_INVOICE,
    DocumentTag.PACKING_LIST,
  ],
};

export const isDocumentSupportedByScale = (
  documentTags: DocumentTag[],
  fileExtension: DocumentFileExtension,
): boolean => {
  // Scale has a ML model for each document type. We should only send documents with 1 tag.
  if (!documentTags || documentTags.length !== 1) {
    return false;
  }

  return Boolean(
    supportedDocumentTypes[fileExtension]?.includes(documentTags[0]),
  );
};
