export const personalEffectsFragment = `
fragment personalEffectsFields on UsIorPersonalEffects {
  birthDate
  passport {
    number
    expiration
    type
    countryCode
  }
  lastForeign {
    ...addressFields
  }
  newUs {
    ...addressFields
  }
}
`;
