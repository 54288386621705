import { AccountType } from '@xbcb/shared-types';
import { createTableConfig } from '../createTableConfig';
import { EsDataType } from '../../index';
import {
  EscalationReasonCategory,
  EscalationReasonSubCategory,
  ResolutionReasonCode,
  TaskEscalationType,
  WorkOrderTaskType,
} from '@xbcb/work-order-types';

export const workOrderTaskEscalationMessage = createTableConfig({
  tableOptions: {
    whitelist: [AccountType.OPERATOR],
  },
  columns: {
    allDetails: {
      name: 'All Details',
      type: EsDataType.TEXT,
      default: true,
    },
    escalationDetails: {
      name: 'Escalation Details',
      type: EsDataType.TEXT,
      default: true,
    },
    escalationType: {
      name: 'Escalation Type',
      type: EsDataType.TEXT,
      optionValues: Object.keys(TaskEscalationType),
      default: true,
    },
    escalationReason: {
      name: 'Escalation Reason',
      type: EsDataType.KEYWORD,
      optionValues: Object.keys(EscalationReasonCategory),
      default: true,
    },
    escalationSubReason: {
      name: 'Escalation Sub Reason',
      type: EsDataType.KEYWORD,
      optionValues: Object.keys(EscalationReasonSubCategory),
      default: true,
    },
    escalatedToName: {
      name: 'Escalation To Name',
      type: EsDataType.TEXT,
      default: true,
    },
    escalatedToId: {
      name: 'Escalation To Id',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    escalatedByName: {
      name: 'Escalated By Name',
      type: EsDataType.TEXT,
      default: true,
    },
    escalatedById: {
      name: 'Escalated To Id',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    resolutionDetails: {
      name: 'Resolution Details',
      type: EsDataType.TEXT,
      default: true,
    },
    resolutionReason: {
      name: 'Resolution Reasons',
      type: EsDataType.KEYWORD,
      optionValues: Object.keys(ResolutionReasonCode),
      default: true,
    },
    resolvedByName: {
      name: 'Resolved By Name',
      type: EsDataType.TEXT,
      default: true,
    },
    resolvedById: {
      name: 'Resolved By Id',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    replyDetails: {
      name: 'Reply Details',
      type: EsDataType.TEXT,
      default: true,
    },
    replyByName: {
      name: 'Reply By Names',
      type: EsDataType.TEXT,
      default: true,
    },
    replyById: {
      name: 'Reply By Ids',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    workOrderTaskType: {
      name: 'Work Order Task Type',
      type: EsDataType.KEYWORD,
      optionValues: Object.keys(WorkOrderTaskType),
      default: true,
    },
    subjectMatterExpertTeamName: {
      name: 'Subject Matter Expert Team Name',
      type: EsDataType.TEXT,
      default: true,
    },
    subjectMatterExpertTeamId: {
      name: 'Subject Matter Expert Team Id',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    entryNumber: {
      name: 'Entry Number',
      type: EsDataType.TEXT,
    },
    bookingId: {
      name: 'Booking Id',
      type: EsDataType.KEYWORD,
    },
    brokerName: {
      name: 'Broker Name',
      type: EsDataType.TEXT,
    },
    brokerId: {
      name: 'Broker ID',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
  },
});
