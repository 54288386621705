import { message } from 'antd';
// import GA from 'react-ga';
import { UiStage } from '@xbcb/ui-types';

export const openFetchDocTab = () => {
  const w = window.open('', '_blank');
  if (!w) {
    message.error(
      'We could not open another tab, please disable any pop-up blocker and try again.',
    );
    if (process.env.REACT_APP_STAGE === UiStage.PROD) {
      // GA.exception({
      //   description: 'window.open returned null',
      // });
    }
  }
  return w;
};

export const openDocumentInNewTab = (downloadLink: string) => {
  const w = openFetchDocTab();
  if (w) w.location.href = downloadLink;
};
