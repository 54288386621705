import {
  ApolloCache,
  DefaultContext,
  OperationVariables,
  MutationHookOptions,
  MutationTuple,
  useMutation as apolloUseMutation,
} from '@apollo/client';
import { safeGet } from '@xbcb/js-utils';
import { AnyObject } from '@xbcb/shared-types';
import {
  getOperationDefinitionFromDocument,
  getOperationNameFromDocument,
} from '@xbcb/shared-queries';
import { DocumentNode } from 'graphql';
import { useDispatch } from 'react-redux';
import { putRecord } from '../actions';

// Used to track mutation variables used between each invocation
let variables: AnyObject = {};

export const useMutation = <
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>(
  mutation: DocumentNode,
  mutationOptions?: MutationHookOptions<TData, TVariables, TContext>,
): MutationTuple<TData, TVariables, TContext, TCache> => {
  const dispatch = useDispatch();
  // Find the name of the mutation to verify that it is an update
  const operationDefinition = getOperationDefinitionFromDocument(mutation);
  const isUpdateOneMutation = operationDefinition?.name?.value === 'UpdateOne';
  const mutationName = getOperationNameFromDocument(mutation);
  const isUpdateOperation = isUpdateOneMutation && mutationName;
  const [executeMutation, statefulVariables] = apolloUseMutation(mutation, {
    ...(mutationOptions as any),
    onCompleted: (data) => {
      if (mutationOptions?.onCompleted) {
        mutationOptions.onCompleted(data);
      }
      if (isUpdateOperation) {
        const record = safeGet(data, [mutationName, 'record'], {});
        if (record.id) {
          dispatch(putRecord(record));
        }
      }
    },
    onError: (error, options) => {
      if (mutationOptions?.onError) {
        mutationOptions.onError(error, options);
      }
      if (isUpdateOperation) {
        const { id, version } = variables;
        dispatch(
          putRecord({
            id,
            version,
          }),
        );
      }
    },
  });
  const mutationWrapperFunc = async (
    options?: MutationHookOptions<TData, TVariables, TContext, TCache>,
  ) => {
    if (isUpdateOperation) {
      variables = options?.variables || ({} as AnyObject);
      const { id, version = 1 } = variables;
      dispatch(
        putRecord({
          id,
          version: version + 1,
        }),
      );
    }
    const mutationResponse = await executeMutation(options as any);
    // Apollo Client always returns an object even if there is an error
    // https://github.com/apollographql/apollo-client/issues/6831
    if (mutationResponse.errors) {
      // Throw without a message to defer to the `onError` link in `@xbcb/apollo-client`
      throw new Error();
    }
    return mutationResponse;
  };
  return [mutationWrapperFunc, statefulVariables];
};
