import React, { FocusEventHandler } from 'react';
import { Input } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { getAN, show } from '@xbcb/ui-utils';
import {
  createDataCyValue,
  CssSize,
  DataCyPrefix,
  NamePath,
} from '@xbcb/ui-types';
import FormItem from '../FormItem';

interface ANNumberProps {
  form: FormInstance;
  className?: string;
  label: string;
  localNamePath?: NamePath;
  fullNamePath: NamePath;
  readOnly?: boolean;
  isQueriable?: boolean;
  handleSearch?: Function;
  $itemSize?: string;
  required?: boolean;
  max: number;
  mask?: any;
  placeholder?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: Function;
  disabled?: boolean;
  debounce?: boolean;
  hideLabel?: boolean;
  pattern?: RegExp;
  dataCySuffix?: string;
  initialValue?: string | number;
}

const ANNumber = ({
  form,
  className,
  label,
  localNamePath,
  fullNamePath,
  readOnly,
  isQueriable,
  handleSearch,
  $itemSize = CssSize.TINY_SHORT,
  required = false,
  max = 20,
  mask = getAN,
  placeholder,
  onBlur,
  onChange,
  disabled,
  debounce = false,
  hideLabel,
  pattern,
  dataCySuffix,
  initialValue,
}: ANNumberProps) => {
  if (!show({ readOnly, form, field: fullNamePath })) return null;
  const C = isQueriable ? Input.Search : Input;

  const childProps: {
    placeholder?: string;
    disabled?: boolean;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    onChange: any;
    onSearch?: any;
  } = {
    placeholder,
    disabled,
    onBlur,
    onChange,
  };
  if (isQueriable) childProps.onSearch = handleSearch;
  if (!localNamePath) localNamePath = fullNamePath;
  return (
    <FormItem
      className={className}
      label={hideLabel ? '' : label}
      name={localNamePath}
      rules={[{ required, max, message: ' ', pattern }]}
      getValueFromEvent={mask(max)}
      $itemSize={$itemSize}
      $inline
      $readOnly={readOnly}
      debounce={debounce}
      data-cy={createDataCyValue(DataCyPrefix.AN_NUMBER, dataCySuffix)}
      initialValue={initialValue}
    >
      <C {...childProps} />
    </FormItem>
  );
};

export default ANNumber;
