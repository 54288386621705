import { Forwarder, UsIor } from '@xbcb/api-gateway-client';
import { UsBusinessStructure } from '@xbcb/party-types';
import { mapStructureCode } from '@xbcb/party-utils';

type GetTemplateFieldsProps = UsIor & {
  // These three fields are additional fields that we need info for that aren't
  // stored on the UsIor but are captured on the Sign UI
  secondaryOfficerName?: string;
  secondaryOfficerTitle?: string;
  forwarder?: Forwarder;
};

// This mapper is meant to convert the `additionalFields` of a template DSR (or
// the form fields used in the Sign UI) to the values the document templates
// need. This should mirror _very_ closely to the following lib which actually
// renders the template:
// https://code.amazon.com/packages/XbcbShared/blobs/mainline/--/packages/ui-utils/src/renderTemplate/index.js
// As of now all fields are supplied that the lib needs other than 'signerName'
// and 'signerTitle' which are now deprecated
export const getTemplateFields = (values: GetTemplateFieldsProps) => {
  const {
    name,
    iorNumber,
    alternateName,
    businessStructure,
    addresses,
    phone: formPhone,
    officer,
    forwarder,
    secondaryOfficerName,
    secondaryOfficerTitle,
    cbpNumberHelp,
  } = values;
  const structure =
    businessStructure &&
    mapStructureCode(businessStructure as UsBusinessStructure);
  const phoneCountry = formPhone?.country || '';
  const phoneNumber = formPhone?.number || '';
  const formattedPhone = `${phoneCountry}${phoneNumber}`;
  const {
    address,
    city,
    stateCode: state,
    countryCode: country,
    postalCode,
  } = addresses?.physical || addresses?.mailing || {};
  const { title: officerTitle, name: officerName } = officer || {};
  return {
    iorNumber: cbpNumberHelp ? '' : iorNumber?.value,
    name,
    structure,
    address,
    city,
    state,
    country,
    forwarder,
    secondaryOfficerName,
    secondaryOfficerTitle,
    officerName,
    officerTitle,
    // Not currently used by existing templates, but made available for future
    // use cases (and to mirror the lib mentioned above)
    alternateName: alternateName || name,
    phone: formattedPhone,
    postalCode,
    dba: alternateName || name, // MPOA template still uses dba, it will only be used for rendering and pdf generation but it will not be saved in IOR.
  };
};
