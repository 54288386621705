import styled from 'styled-components';
import { Tabs } from 'antd';

export const StyledTabs = styled(Tabs)`
  .ant-tabs {
    border-radius: var(--space-1);
  }

  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-nav .ant-tabs-tab {
    width: 33.33333333%;
    text-align: center;
    margin-right: 0;
    display: flex;
    justify-content: center;
    font-size: var(--font-size-18);
  }

  .ant-tabs-tabpane {
    padding: var(--space-4);
  }

  .sign-form-clear {
    position: absolute;
    top: 62px;
    right: 18px;
  }

  .sign-form-upload {
    .ant-upload.ant-upload-drag {
      background-color: white;
    }

    .anticon-edit {
      font-size: 44px;
      margin-bottom: var(--space-4);
      color: var(--primary-color);
    }

    img {
      max-width: 100%;
      max-height: 120px;
    }
  }

  .sign-form-typed {
    text-align: center;
    height: 120px;
    background-color: white;
    width: 100%;
    border-radius: var(--space-1);
    border: 1px #d9d9d9 solid;
    margin: 0 auto 6px auto;
    color: @black;

    span.sign-form-typed-text {
      font-family: Homemade Apple;
    }
  }

  canvas#signature {
    border-radius: 4px;
    border: 1px #d9d9d9 solid;
    width: 100%;
  }
`;

export const StyledSignFormSubmit = styled.div`
  padding: 10px 16px;

  .sign-form-submit-button {
    width: 100%;
  }
`;

// To use the font, store the font "HomemadeAppleFont.woff2" in UiApp/public folder like XbcbNewAppUi/public/HomemadeAppleFont.woff2
// Low Priority TODO: Find a way to store the font file in Shared instead of React App and then use here
export const StyledFontHomemadeApple = styled.div`
  /* latin */
  @font-face {
    font-family: 'Homemade Apple';
    font-style: normal;
    font-weight: 400;
    src: url(./HomemadeAppleFont.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
`;
