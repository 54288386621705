import { Layout, Col } from 'antd';
import styled from 'styled-components';
import { CssSize } from '@xbcb/ui-types';

export const StyledContent = styled(Layout.Content)`
  .ant-col {
    .ant-timeline {
      margin: 0;
      min-height: 280px;
      p {
        margin: 0;
      }
    }
  }
`;

export const StyledMainSectionDiv = styled.div`
  margin: 0;
  min-height: 280px;
`;

export const StyledMainContentDiv = styled.div`
  flex: 1;
`;

// The height of all other content on the page
// The calculation is computed the following way:
// 64 px => Height of AppHeader (packages/react/src/components/AppHeader/component.tsx)
// 12 px => Padding Top on a PageHeader component when there is a bread crumb
// 22 px => Height of Breadcrumb
// 40.5 px => Height of the page title
// 16 px => Padding Bottom on a PageHeader component
// 28 px => The typical heighth of the SecondaryHeaderContent. Not all pages
//          have it, but it's very difficult to determine whether they do or
//          not programmatically. Thus, error on the side of caution and always
//          providing the extra buffer. It still looks much better at the
//          bottom of the screen how that it did previously.
const otherContentHeight = 64 + 12 + 22 + 40.5 + 16 + 28;

// PageContent current use cases:
// 1. PartyFormBase (aka all party pages and Import Sign)
// 2. Product page
// 3. Shipment page
// 4. All WO pages
export const StyledCol = styled(Col)<{ $maxHeight?: string }>`
  overflow-y: auto;
  max-height: ${({ $maxHeight }) =>
    $maxHeight || `calc(100vh - ${otherContentHeight}px)`};
  min-width: ${CssSize.SHORT};
`;
