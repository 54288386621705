import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

export const continuousBondAutomationFeature =
  'continuousBondAutomationFeature';

const CONTINUOUS_BOND_AUTOMATION_FEATURE = new FeatureFlagService([
  {
    feature: continuousBondAutomationFeature,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA, Stage.PROD],
  },
]);

export { CONTINUOUS_BOND_AUTOMATION_FEATURE };
