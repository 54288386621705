import { EsDataType } from '../index';
import { createTableConfig } from './createTableConfig';

const columns = {
  fileName: {
    default: true,
    name: 'File Name',
    type: EsDataType.TEXT,
  },
  extension: {
    default: true,
    name: 'Extension',
    type: EsDataType.KEYWORD,
  },
  contentType: {
    name: 'Content Type',
    type: EsDataType.KEYWORD,
  },
  size: {
    default: true,
    name: 'Size',
    type: EsDataType.INTEGER,
  },
  status: {
    name: 'Status',
    type: EsDataType.KEYWORD,
  },
  previewStatus: {
    name: 'Preview Status',
    type: EsDataType.KEYWORD,
  },
  documentTags: {
    default: true,
    name: 'Document Tags',
    type: EsDataType.KEYWORD,
  },
  externalDocumentReferenceId: {
    default: true,
    name: 'External Reference Id',
    type: EsDataType.KEYWORD,
  },
};

// TODO add addition fields to search by
export const document = createTableConfig({ columns });
