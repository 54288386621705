import {
  AccountingType,
  AccountsPayableStatus,
  ChargeCode,
} from '@xbcb/finance-types';
import { EsDataType, expirationTime, recordFields } from '../..';
import { createTableConfig } from '../createTableConfig';

export const invoiceLinesRequest = createTableConfig({
  columns: {
    expirationTime,
    reviewStatus: {
      default: true,
      name: 'Review Status',
      type: EsDataType.KEYWORD,
    },
    externalReferenceId: {
      name: 'ExternalReference Id',
      type: EsDataType.KEYWORD,
      default: true,
    },
    workOrderGroupId: {
      default: true,
      name: 'Work Order Group Id',
      type: EsDataType.KEYWORD,
    },
    workOrderId: {
      default: true,
      name: 'Work Order Id',
      type: EsDataType.KEYWORD,
    },
    status: {
      default: true,
      name: 'Status',
      type: EsDataType.KEYWORD,
    },
    chargeCode: {
      default: true,
      name: 'Charge Code',
      type: EsDataType.KEYWORD,
      optionValues: Object.values(ChargeCode),
    },
    accountingType: {
      default: true,
      name: 'Accounting Type',
      type: EsDataType.KEYWORD,
      optionValues: Object.values(AccountingType),
    },
    accountsPayableStatus: {
      default: true,
      name: 'Accounts Payable Status',
      type: EsDataType.KEYWORD,
      optionValues: Object.values(AccountsPayableStatus),
    },
    payeeScac: {
      default: true,
      name: 'Payee SCAC',
      type: EsDataType.KEYWORD,
    },
    ...recordFields({
      createdTime: { react: true, default: true, name: 'Requested At' },
    }),
  },
  tableOptions: {
    sortField: 'createdTime',
  },
});
