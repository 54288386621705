/**
 * Represents the overall format of the template
 */
export enum DocumentTemplateType {
  CORPORATE_CERTIFICATION = 'CORPORATE_CERTIFICATION',
  DIRECT_POWER_OF_ATTORNEY = 'DIRECT_POWER_OF_ATTORNEY',
  EXTERNAL_SUB_POWER_OF_ATTORNEY = 'EXTERNAL_SUB_POWER_OF_ATTORNEY',
  MASTER_POWER_OF_ATTORNEY = 'MASTER_POWER_OF_ATTORNEY',
  SUB_POWER_OF_ATTORNEY = 'SUB_POWER_OF_ATTORNEY',
  SUB_POWER_OF_ATTORNEY_AUTHORITY = 'SUB_POWER_OF_ATTORNEY_AUTHORITY',
}
