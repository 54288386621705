// Ideally all values used as a suffix should be added to DataCySuffix, but,
// there are some cases where we dynamically create the DataCySuffix so not all
// of them are captured here. When possible add the value to DataCySuffix and
// avoid hard coding strings. DataCySuffix can then be used to define the
// data-cy value in the component _and_ in the test when we get element by it
export enum DataCySuffix {
  PARTY_CONTACT_INFO = 'partyContactInfo',
  SAME_MAILING_ADDRESS = 'sameMailingAddress',
  PREFERRED_CBP_ENTRY_PAYMENT_METHOD = 'preferredCbpEntryPaymentMethod',
  CANCEL_SHIPMENT = 'cancelShipment',
  UNCANCEL_SHIPMENT = 'uncancelShipment',
  CANCEL_US_ISF = 'cancelUsIsf',
  UNCANCEL_US_ISF = 'uncancelUsIsf',
  CANCEL_US_CONSUMPTION_ENTRY = 'cancelUsConsumptionEntry',
  UNCANCEL_US_CONSUMPTION_ENTRY = 'uncancelUsConsumptionEntry',
  SHIPPER_USER_CONTACT_INFO = 'shipperUserContactInfo',
  SHIPMENT = 'shipment',
  WORK_ORDER = 'workOrder',
  PARTY_POINT_OF_CONTACT = 'partyPointOfContact',
  PARTY_ADDITIONAL_COMPANY_INFO = 'partyAdditionalPartyInfo',
  RECEIVING_POINT_OF_CONTACT = 'receivingPointOfContact',
  DISPATCH_POINT_OF_CONTACT = 'dispatchPointOfContact',
  LAST_FOREIGN_ADDRESS = 'lastForeignAddress',
  ADD_WORK_ORDER = 'addWorkOrder',
  US_IN_BOND = 'usInBond',
  DELIVER_ORDER = 'deliveryOrder',
  US_POST_SUMMARY_CORRECTION = 'usPostSummaryCorrection',
  ACTIVATE_US_IOR = 'activateUsIor',
  US_IOR_PAYMENT_TERMS = 'usIorPaymentTerms',
  FORWARDER_ID = 'forwarderId',
  SELLER = 'seller',
  SOURCE_COUNTRY = 'sourceCountry',
  PRODUCT_TITLE = 'productTitle',
  PRODUCT = 'product',
  MARK_ENTRY_READY = 'Mark Entry Ready',
  START_TASK = 'startTask',
  STOP_TASK = 'stopTask',
  OPEN_TASK = 'openTask',
  PAUSE_TASK = 'pauseTask',
  TOOLTIP_TITLE = 'tooltipTitle',
  WARNING_ICON = 'warningIcon',
  USERS = 'users',
  SETTINGS = 'settings',
  CURRENCY_RATE = 'currencyRate',
  ASSIGN_WORK_ORDER_TASK = 'assignWorkOrderTask',
  COMPLETE_TASK = 'completeTask',
  COMMERICAL_INVOICE_BUILDING_WORK_ORDER_TASK = 'commercialInvoiceBuildingWorkOrderTask',
  DOCUMENT_TAGGING_WORK_ORDER_TASK = 'documentTaggingWorkOrderTask',
  ASSIGNMENT_TEAM_USERS_SELECT = 'assignmentTeamUsersSelect',
  PRIMARY_TEAM_LEAD = 'primaryTeamLead',
  TEAM_LEAD = 'teamLead',
  SECONDARY_TEAM_LEAD = 'secondaryTeamLead',
  SME_TEAM_INPUT = 'smeTeamInput',
  BACKUP_TEAM_INPUT = 'backupTeamInput',
  ASSIGNMENT_TEAMS = 'assignmentTeams',
  SME_TEAMS = 'smeTeams',
  EXPERT_USERS_SELECT = 'expertUsers',
  ASSIGNMENT_WORKSPACE = 'assignmentWorkspace',
  OPEN_ESCALATIONS = 'openEscalations',
  TEAM_ASSIGNMENTS = 'teamAssignments',
  MY_ASSIGNMENTS = 'myAssignments',
  ASSIGNMENT_QUEUE_STATUS = 'assignmentQueueStatus',
  TASK_EXCEPTIONS = 'taskExceptions',
  UNASSIGNED_BY_REQUIRED_SKILL_LEVEL = 'unassignedByRequiredSkillLevel',
  UNASSIGNED_BY_WORK_ORDER_TASK_TYPE = 'unassignedByWorkOrderTaskType',
  CONTAINER_NUMBER = 'containerNumber',
  CONTAINER_SEAL = 'containerSeal',
  ARRIVAL_FORM = 'arrivalForm',
  MANIFEST_QUANTITY = 'manifestQuantity',
  WORK_ORDER_TYPE = 'workOrderType',
  DATE_SELECT = 'dateSelect',
  MILESTONE_SELECT = 'milestoneSelect',
  INVOICE_NUMBER_INPUT = 'invoiceNumberInput',
  SUBMIT_ENTRY = 'submitEntry',
  DOCUMENT_UPLOAD_INPUT = 'documentUploadInput',
  US_CONSUMPTION_ENTRY = 'usConsumptionEntry',
  VALID = 'valid',
  INVALID = 'invalid',
  ABI_REFRESH = 'abiRefresh',
  UTILITIES = 'utilities',
}
