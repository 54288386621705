import { partyFragments } from './party';
const deConsigneeFragment = `
fragment deConsigneeFields on DeConsignee {
  ...recordFields
  ...partyFields
}
`;

export const deConsigneeFragments = `
${deConsigneeFragment}
${partyFragments}
`;
