import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { CssSize } from '@xbcb/ui-types';
import SimpleSelect from '../SimpleSelect';
import { safeGetMessage } from '@xbcb/ui-utils';
import { useBundle } from '@amzn/react-arb-tools';

interface CBPNumberReasonSelectProps {
  readOnly?: boolean;
  disabled?: boolean;
  form: FormInstance;
  initialValue?: string[];
}

const CBPNumberReasonSelect: React.FC<CBPNumberReasonSelectProps> = ({
  readOnly,
  form,
  disabled,
  initialValue,
}) => {
  const [cbpNumberReasonSelectBundle] = useBundle(
    'components.formItemComponents.CBPNumberReasonSelect',
  );
  return (
    <SimpleSelect
      label={safeGetMessage(cbpNumberReasonSelectBundle, 'reasons_applying')}
      multiple={true}
      required={true}
      localNamePath={['cbpNumberReasons']}
      fullNamePath={['cbpNumberReasons']}
      readOnly={readOnly}
      disabled={disabled}
      form={form}
      dropdownMatchSelectWidth={false}
      initialValue={initialValue}
      map={{
        HAS_SSN_NEEDS_CBP: safeGetMessage(
          cbpNumberReasonSelectBundle,
          'has_ssn_needs_cbp',
        ),
        NO_SSN: safeGetMessage(cbpNumberReasonSelectBundle, 'no_ssn'),
        NO_IRS: safeGetMessage(cbpNumberReasonSelectBundle, 'no_irs'),
        NO_IRS_OR_SSN: safeGetMessage(
          cbpNumberReasonSelectBundle,
          'no_irs_or_ssn',
        ),
        NOT_US_RESIDENT: safeGetMessage(
          cbpNumberReasonSelectBundle,
          'not_us_resident',
        ),
      }}
      $itemSize={CssSize.MEDIUM_LARGE}
    />
  );
};

export default CBPNumberReasonSelect;
