import { sharedUsEntryReleaseColumns } from './sharedUsEntryRelease';
import { UsCbpEntryReleaseStatus } from '@xbcb/work-order-types';
import { LoadType } from '@xbcb/shared-types';
import { createShipmentWorkOrderRecord } from './utils';
import { EsDataType } from '../../enums';

export const usType86Entry = createShipmentWorkOrderRecord({
  columns: {
    ...sharedUsEntryReleaseColumns,
    releaseDate: {
      name: 'Release Date',
      type: EsDataType.TIME,
    },
    releaseStatus: {
      name: 'Release Status',
      type: EsDataType.KEYWORD,
      optionValues: Object.keys(UsCbpEntryReleaseStatus),
    },
    preferredExamSite: {
      name: 'Preferred Exam Site',
      type: EsDataType.KEYWORD,
    },
    containerNumber: {
      name: 'Container #',
      type: EsDataType.TEXT,
    },
    pgaDisposition: {
      name: 'PGA Disposition',
      type: EsDataType.KEYWORD,
      default: true,
    },
    collectionStatus: {
      name: 'Collection Status',
      type: EsDataType.KEYWORD,
    },
    releaseDisposition: {
      name: 'Release Disposition',
      type: EsDataType.TEXT,
    },
    releaseRejectionReason: {
      name: 'Release Rejection Reason',
      type: EsDataType.TEXT,
    },
    releaseNotificationType: {
      name: 'Release Notification Type',
      type: EsDataType.KEYWORD,
    },
    bookingId: {
      name: 'Booking ID',
      type: EsDataType.KEYWORD,
    },
    brokerName: {
      name: 'Broker Name',
      type: EsDataType.TEXT,
    },
    loadType: {
      name: 'Load Type',
      type: EsDataType.KEYWORD,
      optionValues: Object.keys(LoadType),
    },
    eta: {
      name: 'ETA',
      type: EsDataType.TIME,
    },
    iorName: { name: 'IOR Name', type: EsDataType.TEXT, default: false },
    iorNumber: {
      name: 'IOR Number',
      type: EsDataType.KEYWORD,
      default: false,
      hidden: true,
    },
  },
});
