import { EsDataType } from '../../enums';
import { createTableConfig } from '../createTableConfig';

export const tableFilter = createTableConfig({
  columns: {
    name: {
      default: true,
      name: 'Custom Filter Name',
      type: EsDataType.TEXT,
    },
    operatorName: {
      default: true,
      name: 'Operator Name',
      type: EsDataType.TEXT,
    },

    recordTable: {
      default: true,
      name: 'Record Type',
      type: EsDataType.TEXT,
    },
  },
});
