import { partyFragments } from './party';
const nlConsigneeFragment = `
fragment nlConsigneeFields on NlConsignee {
  ...recordFields
  ...partyFields
}
`;

export const nlConsigneeFragments = `
${nlConsigneeFragment}
${partyFragments}
`;
