import { SortOrder } from '@xbcb/shared-types';
import { euIorColumns } from './euIor';
import { createPartyTable } from './createPartyTable';

export const deIor = createPartyTable({
  columns: euIorColumns,
  tableOptions: {
    sortOrder: SortOrder.DESC,
  },
});
