export enum aphLpcoFields {
  PHYTOSANITARY_CERTIFICATE = 'phytosanitaryCertificate',
  HEALTH_CERTIFICATE = 'healthCertificate',
  MEAT_CERTIFICATE = 'meatCertificate',
  TREATMENT_CERTIFICATE = 'treatmentCertificate',
  IRC_REGISTRY_NUMBER = 'ircRegistryNumber',
  IMPORTATION_OF_VETERINARY_BIOLOGICAL_PRODUCTS = 'importationOfVeterinaryBiologicalProducts',
  FOREIGN_SITE_CERTIFICATE_OF_INSPECTION = 'foreignSiteCertificateOfInspection',
  SOIL_PERMIT = 'soilPermit',
  PLANT_PESTS_PERMIT = 'plantPestsPermit',
  POST_ENTRY_QUARANTINE_PERMIT = 'postEntryQuarantinePermit',
  TIMBER_PERMIT = 'timberPermit',
  TRANSIT_PLANTS_PERMIT = 'transitPlantsPermit',
  COTTON_PERMIT = 'cottonPermit',
  SUGARCANE_PERMIT = 'sugarcanePermit',
  NURSERY_PLANTS_PERMIT = 'nurseryPlantsPermit',
  CORN_PERMIT = 'cornPermit',
  RICE_PERMIT = 'ricePermit',
  FRUITS_AND_VEGETABLES_PERMIT = 'fruitsAndVegetablesPermit',
  KHAPRA_BEETLE_PERMIT = 'khapraBeetlePermit',
  PLANTS_OF_CANADIAN_ORIGIN_PERMIT = 'plantsOfCanadianOriginPermit',
  PROHIBITED_PLANT_FOR_RESEARCH_PERMIT = 'prohibitedPlantForResearchPermit',
  PROTECTED_PLANT_PERMIT = 'protectedPlantPermit',
  VETERINARY_PERMIT_TO_IMPORT_CONTROLLED_MATERIALS = 'veterinaryPermitToImportControlledMaterials',
  STATEMENTS_PERMIT = 'statementsPermit',
  DECLARATION_OF_IMPORTATION = 'declarationOfImportation',
  SEED_ANALYSIS_CERTIFICATE = 'seedAnalysisCertificate',
  LIVE_ANIMALS_PERMIT = 'liveAnimalsPermit',
  APPLICATION_FOR_INSPECTION_AND_DIPPING = 'applicationForInspectionAndDipping',
  RABIES_VACCINATION_CERTIFCATE = 'rabiesVaccinationCertifcate',
  DOGS_PERMIT = 'dogsPermit',
  NOTICE_OF_ARRIVAL_PERMIT = 'noticeOfArrivalPermit',
  CERTIFICATE_OF_ORIGIN = 'certificateOfOrigin',
  GMO_PERMIT = 'gmoPermit',
  BRS_NOTIFICATION = 'brsNotification',
  BRS_ACKNOWLEDGEMENT_LETTER = 'brsAcknowledgementLetter',
}

export const aphLpcoList = [
  { fieldName: aphLpcoFields.PHYTOSANITARY_CERTIFICATE, typeCode: 'A01' },
  { fieldName: aphLpcoFields.HEALTH_CERTIFICATE, typeCode: 'A02' },
  { fieldName: aphLpcoFields.MEAT_CERTIFICATE, typeCode: 'A03' },
  { fieldName: aphLpcoFields.TREATMENT_CERTIFICATE, typeCode: 'A05' },
  {
    fieldName: aphLpcoFields.IMPORTATION_OF_VETERINARY_BIOLOGICAL_PRODUCTS,
    typeCode: 'A06',
  },
  {
    fieldName: aphLpcoFields.FOREIGN_SITE_CERTIFICATE_OF_INSPECTION,
    typeCode: 'A07',
  },
  { fieldName: aphLpcoFields.SOIL_PERMIT, typeCode: 'A09' },
  { fieldName: aphLpcoFields.PLANT_PESTS_PERMIT, typeCode: 'A10' },
  {
    fieldName: aphLpcoFields.POST_ENTRY_QUARANTINE_PERMIT,
    typeCode: 'A11',
  },
  { fieldName: aphLpcoFields.TIMBER_PERMIT, typeCode: 'A12' },
  { fieldName: aphLpcoFields.TRANSIT_PLANTS_PERMIT, typeCode: 'A13' },
  { fieldName: aphLpcoFields.COTTON_PERMIT, typeCode: 'A14' },
  { fieldName: aphLpcoFields.SUGARCANE_PERMIT, typeCode: 'A15' },
  { fieldName: aphLpcoFields.NURSERY_PLANTS_PERMIT, typeCode: 'A16' },
  { fieldName: aphLpcoFields.CORN_PERMIT, typeCode: 'A17' },
  { fieldName: aphLpcoFields.RICE_PERMIT, typeCode: 'A18' },
  {
    fieldName: aphLpcoFields.FRUITS_AND_VEGETABLES_PERMIT,
    typeCode: 'A19',
  },
  { fieldName: aphLpcoFields.KHAPRA_BEETLE_PERMIT, typeCode: 'A20' },
  {
    fieldName: aphLpcoFields.PLANTS_OF_CANADIAN_ORIGIN_PERMIT,
    typeCode: 'A21',
  },
  {
    fieldName: aphLpcoFields.PROHIBITED_PLANT_FOR_RESEARCH_PERMIT,
    typeCode: 'A22',
  },
  { fieldName: aphLpcoFields.PROTECTED_PLANT_PERMIT, typeCode: 'A23' },
  {
    fieldName: aphLpcoFields.VETERINARY_PERMIT_TO_IMPORT_CONTROLLED_MATERIALS,
    typeCode: 'A24',
  },
  { fieldName: aphLpcoFields.STATEMENTS_PERMIT, typeCode: 'A25' },
  { fieldName: aphLpcoFields.DECLARATION_OF_IMPORTATION, typeCode: 'A26' },
  { fieldName: aphLpcoFields.SEED_ANALYSIS_CERTIFICATE, typeCode: 'A27' },
  { fieldName: aphLpcoFields.LIVE_ANIMALS_PERMIT, typeCode: 'A28' },
  {
    fieldName: aphLpcoFields.APPLICATION_FOR_INSPECTION_AND_DIPPING,
    typeCode: 'A29',
  },
  {
    fieldName: aphLpcoFields.RABIES_VACCINATION_CERTIFCATE,
    typeCode: 'A30',
  },
  { fieldName: aphLpcoFields.DOGS_PERMIT, typeCode: 'A31' },
  { fieldName: aphLpcoFields.NOTICE_OF_ARRIVAL_PERMIT, typeCode: 'A32' },
  { fieldName: aphLpcoFields.CERTIFICATE_OF_ORIGIN, typeCode: 'A33' },
  { fieldName: aphLpcoFields.GMO_PERMIT, typeCode: 'A34' },
  { fieldName: aphLpcoFields.BRS_NOTIFICATION, typeCode: 'A35' },
  { fieldName: aphLpcoFields.BRS_ACKNOWLEDGEMENT_LETTER, typeCode: 'A36' },
];
