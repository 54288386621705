// commented out SPIs are not allowed, so we dont show them in the schema
// they could be deprecated by CBP, or some other reason we dont use them.
// See @xbcb/codes/codes/ClaimCodes.json where "hidden": true
// GraphQL does not allow "+" characters in the schema: https://github.com/folkloreinc/laravel-graphql/issues/153
// Therefore we must use a custom scalar, using codeScalar and this enum in compliance-shared-schema
export enum UsSpecialProgramIndicator {
  'A' = 'A',
  // 'A+' = 'A+',
  'AU' = 'AU',
  // 'B' = 'B',
  // 'B#' = 'B#',
  'BH' = 'BH',
  // 'C' = 'C',
  // 'C#' = 'C#',
  'CA' = 'CA',
  'CL' = 'CL',
  'CO' = 'CO',
  'D' = 'D',
  'E' = 'E',
  'IL' = 'IL',
  'J' = 'J',
  'J+' = 'J+',
  'JO' = 'JO',
  'K' = 'K',
  // 'K#' = 'K#',
  'KR' = 'KR',
  // 'L' = 'L',
  // 'L#' = 'L#',
  'MA' = 'MA',
  'MX' = 'MX',
  'N' = 'N',
  // 'NP' = 'NP',
  'OM' = 'OM',
  'P' = 'P',
  'PA' = 'PA',
  'PE' = 'PE',
  'P+' = 'P+',
  'R' = 'R',
  'S' = 'S',
  'SG' = 'SG',
  // 'W' = 'W',
  'Y' = 'Y',
  // 'Z' = 'Z',
}
