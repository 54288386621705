import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

const devOperatorUsers = {
  prod: [
    'operatorUser_993d19c4-a474-4771-93d6-720f2c4d308d', // [PROD] aankit@amazon.com
    'operatorUser_0eb844a5-e40e-4b7c-a12e-05583ca5baaf', // [PROD] almomai@amazon.com
    'operatorUser_ab118085-cba9-4e47-8a7a-f2ba884b0348', // [PROD] amanjha@amazon.com
    'operatorUser_e08e5e78-fba7-4b1d-b8c4-4f0abde0e549', // [PROD] cyangamz@amazon.com
    'operatorUser_a960f352-42ae-49a2-b1c3-8780e8200fee', // [PROD] garayush@amazon.com
    'operatorUser_1c33b223-025b-4128-b691-8750e2cb0d6b', // [PROD] goyad@amazon.com
    'operatorUser_b5e78539-b26f-4ed2-8118-e066216330d8', // [PROD] grnikhil@amazon.com
    'operatorUser_36a14507-8ce1-44dc-a414-e575f20e4710', // [PROD] jiliun@amazon.com
    'operatorUser_62d45e09-1638-4b91-84eb-85f51e3a105d', // [PROD] jskobos@amazon.com
    'operatorUser_30f434ea-4750-4751-8e29-e7ec90b59cc5', // [PROD] kevinae@amazon.com
    'operatorUser_3c7e8505-b1a5-4563-b6da-1db3892cf921', // [PROD] kgannam+prod@amazon.com
    'operatorUser_16c203c9-1841-41da-818d-c5c3f863b5bb', // [PROD] lhsing@amazon.com
    'operatorUser_6f53c9af-d615-44c2-a068-78601690245d', // [PROD] madill@amazon.com
    'operatorUser_b81ef27a-3f66-4dd7-b561-168256ad3aeb', // [PROD] mcity@amazon.com
    'operatorUser_593ebcee-9aa0-49e9-909d-e83929137ecb', // [PROD] msachet@amazon.com
    'operatorUser_5c4469aa-655d-43dc-97ef-96005594cc1b', // [PROD] naylor@amazon.com
    'operatorUser_9938a58c-cd8e-4342-8fb6-34a2a72d90a1', // [PROD] robsonpv@amazon.com
    'operatorUser_cb4e8c4a-0fde-4960-938a-42cadbecd32b', // [PROD] rsrohit@amazon.com
    'operatorUser_996e7b1b-22a8-441a-ac24-535bfd0216e1', // [PROD] sinbaru@amazon.com
    'operatorUser_a37b36e4-d624-4aba-9285-3bd020e46508', // [PROD] ssaseend@amazon.com
    'operatorUser_c295ca0d-ffc9-4016-acc7-c15cf019d5dc', // [PROD] sumigarg@amazon.com
    'operatorUser_8d451a62-3bc4-4569-9cde-4c247b901085', // [PROD] uruchir+prod@amazon.com
    'operatorUser_55f41d5c-eac1-4f3a-8d06-71b6912491c6', // [PROD] wonga@amazon.com
  ],
};
const opsOperatorUsers = {
  prod: [
    'operatorUser_a6d66cc1-d2b5-48d9-af21-e3592274a964', // [PROD] morgiaa@amazon.com
    'operatorUser_25b8d713-1eaf-495a-ad22-9758f4aa1196', // [PROD] patkait@amazon.com
    'operatorUser_cd28b906-d6ba-4060-af14-58486c6e021d', // [PROD] shcheren@amazon.com
    'operatorUser_df1c489a-cda4-47b8-b3c8-80179d0e170a', // [PROD] beckogba@amazon.com
    'operatorUser_5d3bba5b-aa61-49d3-8374-f33c60d9b56d', // [PROD] xandy@amazon.com
    'operatorUser_a7957917-3e94-4721-bf98-7d3497d86936', // [PROD] barbireg@amazon.com
    'operatorUser_dfaf0d74-352c-46d5-aae0-001e15013e99', // [PROD] undedav@amazon.com
    'operatorUser_16a86614-5bf4-46d8-8e4b-27c3f231bd4c', // [PROD] vbrherna@amazon.com
    'operatorUser_82361926-5748-4bd9-af21-15e94e1d7986', // [PROD] keithakk@amazon.com
    'operatorUser_6fa21667-a348-4d74-b212-bdfb77629bda', // [PROD] relliotc@amazon.com
    'operatorUser_600a284e-27e1-4d5a-816d-26280dc49085', // [PROD] meljiang@amazon.com
    'operatorUser_00c81d42-5873-4dc0-876d-cdea4df8f18c', // [PROD] eddiehud@amazon.com
    'operatorUser_42c5c72a-bf52-445f-8047-b5b7162de3f4', // [PROD] heiedwin@amazon.com
    'operatorUser_74ae8227-5ad9-4a82-b315-5238bca67726', // [PROD] ahmfbrad@amazon.com
    'operatorUser_fcb57990-6f72-430d-b01a-9eb55162b5b2', // [PROD] whisnato@amazon.com
    'operatorUser_9e988b9e-6a75-46bb-b968-8659b91653ac', // [PROD] ocartejo@amazon.com
    'operatorUser_d1e19d07-c530-4c3a-8866-2e0ff04d35cc', // [PROD] mhainjus@amazon.com
    'operatorUser_a196f24d-06c1-49b2-8ba3-c8a166c85a40', // [PROD] travsmar@amazon.com
    'operatorUser_0f780e77-a8cf-4927-b7f0-54b832a5061a', // [PROD] hmontg@amazon.com"
    'operatorUser_4ac45ab0-706b-44b0-8efc-cb02f71d3032', // [PROD] smthaph@amazon.com
    'operatorUser_1a30021b-d9b1-4034-97d6-b406af3f1f82', // [PROD] davidkio@amazon.com
    'operatorUser_11d58a79-e88f-4759-b8e9-b094be3c4a06', // [PROD] termyatt@amazon.com
    'operatorUser_53320694-50db-4de8-938d-99f24d99ab4a', // [PROD] parnearm@amazon.com
    'operatorUser_ecfb29b0-b4c2-4c4a-989c-78d45f20e5d4', // [PROD] parkeima@amazon.com
    'operatorUser_f98b1768-ca5f-5b48-ba18-f7af6adee859', // [PROD] ethomphu@amazon.com
    'operatorUser_2d2f2104-ed66-5729-ba2f-a987b7f7575f', // [PROD] ananicht@amazon.com
    'operatorUser_887dc303-05e4-47bd-a73f-b1c2d3b2b236', // [PROD] davlre@amazon.com
    'operatorUser_b8ddd696-1eb9-4ebe-80a8-72777f26ef2a', // [PROD] cmunoa@amazon.com
    'operatorUser_b5f66c70-4c18-472a-a743-67c95e5537d1', // [PROD] zenzhime@amazon.com
    'operatorUser_95668b2c-da41-4e6e-af7e-a7b8221d9828', // [PROD] hangyue@amazon.com
    'operatorUser_8cbdda55-6976-4144-83ec-76f915a8d576', // [PROD] carterli@amazon.com
    'operatorUser_3d9d94e3-dd56-4231-b6bd-81b66bfddebc', // [PROD] hongchn@amazon.com
    'operatorUser_57bb52c5-8129-43af-8834-d4fa582ff266', // [PROD] mizhe@amazon.com
    'operatorUser_5280f8f3-06e7-4525-b842-ef97d5fe1afe', // [PROD] vannawon@amazon.com
    'operatorUser_fd2f9bd1-c33f-4e80-afee-a3fca07acc6f', // [PROD] ecristci@amazon.com
    'operatorUser_a336f271-18b3-44fa-8c38-3f283f396300', // [PROD] shimrya@amazon.com
    'operatorUser_b4e8ac16-e7a5-4647-8c44-ded00de13a97', // [PROD] lmichcol@amazon.com
    'operatorUser_1db07986-b880-4a5b-9230-ec8b37b4fc00', // [PROD] ykcrawfo@amazon.com
    'operatorUser_34076028-3bad-41e2-9904-98b3ba54366f', // [PROD] joytim@amazon.com
    'operatorUser_072360fd-3043-4f67-8752-180ad5c09df3', // [PROD] nhheugas@amazon.com
    'operatorUser_a747b3d1-17cb-44d9-9a03-2cd814cb3a8a', // [PROD] alantlon@amazon.com
    'operatorUser_f59c7e84-91f0-4d11-8ca3-e55397f44cbf', // [PROD] mounirke@amazon.com
    'operatorUser_5f2f2316-f001-4b1f-9da3-2c073e2fba9d', // [PROD] ushancoc@amazon.com
    'operatorUser_d8ad5778-e00a-4d5d-b34a-3d801714ad5a', // [PROD] broteb@amazon.com
    'operatorUser_bc36449f-2b5c-4cbf-a28f-4c6d268e716c', // [PROD] milusm@amazon.com
    'operatorUser_f368ba6d-2bae-40ba-8eff-6f010a7a7389', // [PROD] ericwate@amazon.com
    'operatorUser_17c79646-7136-4355-9671-0979392b0ce6', // [PROD] zaljack@amazon.com
    'operatorUser_95668b2c-da41-4e6e-af7e-a7b8221d9828', // [PROD] hangyue@amazon.com
    'operatorUser_85db0974-3b6f-48a1-864f-5be7ba5b7db2', // [PROD] pedjamie@amazon.com
    'operatorUser_2ebc12f2-3ffb-4b38-b72c-adf78a70a046', // [PROD] ravedots@amazon.com
    'operatorUser_2ed39e07-aa73-4627-82aa-63619b597b26', // [PROD] lstapac@amazon.com
    'operatorUser_ae11661d-21ab-4280-8fda-1614c2ff5963', // [PROD] alexuhud@amazon.com
    'operatorUser_1df4dd12-02c8-4f47-a41c-fd2fbacbf617', // [PROD] kkmzk@amazon.com
    'operatorUser_921a17cd-c7f3-4e9a-a520-e920f7ec090b', // [PROD] wangyipi@amazon.com
    'operatorUser_0f5ec48c-58e8-446d-bf51-561bfc1ad8be', // [PROD] cjiali@amazon.com
    'operatorUser_2d814c47-d26b-41c6-9aee-f7b31134ba72', // [PROD] yanqg@amazon.com
    'operatorUser_acb77ea3-4084-46db-9423-dbf503eb8207', // [PROD] zhayijia@amazon.com
    'operatorUser_d2c6c06f-a403-4c52-aa5e-5198866cff47', // [PROD] wngsha@amazon.com
    'operatorUser_aa7029d4-f52d-5395-a2ea-83e3ead19a12', // [PROD] kkmzk@amazon.com
    'operatorUser_eb704a4f-6c36-4a11-be41-3e1acd1fcdd5', // [PROD] anuisht@amazon.com
    'operatorUser_46acb330-a983-4350-87a8-7f7b6a652994', // [PROD] jcobyou@amazon.com
    'operatorUser_0a3b67ae-bf74-4662-8e68-5e09afa253fc', // [PROD] fherong@amazon.com
    'operatorUser_17895b9b-0696-4beb-80bb-dfbd68b2aff7', // [PROD] nelshayd@amazon.com
    'operatorUser_ecfa743f-69b9-435d-9fb5-e5b87ad36fb2', // [PROD] bsshowes@amazon.com
    'operatorUser_e04d2702-3b74-4903-998c-4570d0033ade', // [PROD] mcwbrand@amazon.com
    'operatorUser_24ad6e7b-67ac-4113-b5bf-db51bcf9f26c', // [PROD] jiaraxu@amazon.com
    'operatorUser_57bb52c5-8129-43af-8834-d4fa582ff266', // [PROD] mizhe@amazon.com
    'operatorUser_2f69fcd7-a8bd-4469-b2e7-b166c02a874f', // [PROD] rruizh@amazon.com
    'operatorUser_a0a2a520-a2cd-4361-81e6-cca2be6f9e9f', // [PROD] xiaohoch@amazon.com
    'operatorUser_e3f5778d-18a9-47d5-b0db-987ce2534624', // [PROD] zhenqiao@amazon.com
    'operatorUser_bbdda46e-3dbb-549e-b29a-ca6af2f4c956', // [PROD] alexuhud@amazon.com
    'operatorUser_a4293e9d-e6bd-5823-9980-def6086e2a0f', // [PROD] truxc@amazon.com
    'operatorUser_f4369847-c3e1-4ada-8133-dba64e235b48', // [PROD] brobinsk@amazon.com
    'operatorUser_3858c81a-b5a7-4ba1-9d45-754182bbcafb', // [PROD] babalhsh@amazon.com
    'operatorUser_671a1bf3-9212-4067-b8da-604c4be4b998', // [PROD] hobcourt@amazon.com
    'operatorUser_f4b5500b-2013-4581-91cc-95a58ae6d7fd', // [PROD] hatchetv@amazon.com
    'operatorUser_6a8c426a-6c88-4e9d-aa28-249d92feb56e', // [PROD] erickmpe@amazon.com
    'operatorUser_55c94908-0a8d-4a0e-b7b8-256931e1cdfa', // [PROD] awwangz@amazon.com
    'operatorUser_590dbcc2-fc0b-44fa-b59d-bb1245675528', // [PROD] zhngazy@amazon.com
    'operatorUser_21f42b6e-2c11-4c0c-bac2-bc976f048016', // [PROD] yanwenti@amazon.com
    'operatorUser_20095b63-6b04-4c33-8ffd-2f885835d191', // [PROD] lffen@amazon.com
    'operatorUser_b80d3c69-63cf-5071-8f95-68bfff54a870', // [PROD] rverin@amazon.com
    'operatorUser_29c7366a-b8ae-4b31-9d60-7735989f17c6', // [PROD] mitcknee@amazon.com
    'operatorUser_b5030e48-f1e7-419d-aff6-2484b403a137', // [PROD] noellhax@amazon.com
    'operatorUser_9e4810a6-38be-4532-aa82-a7d402948dd6', // [PROD] tamatthj@amazon.com
    'operatorUser_62e93096-e80f-4e26-a7b3-d4fcf7fd92f1', // [PROD] truxc@amazon.com
    'operatorUser_2183f749-5099-4c75-9e4b-4e4104bea818', // [PROD] leilangs@amazon.com
    'operatorUser_3d9d94e3-dd56-4231-b6bd-81b66bfddebc', // [PROD] hongchn@amazon.com
    'operatorUser_2090200a-6f85-442b-962b-9157ecb39abf', // [PROD] xihung@amazon.com
    'operatorUser_9316c6a5-9fdb-4cd6-a3eb-e617ba6c7346', // [PROD] scrugand@amazon.com
    'operatorUser_f7888314-a7b1-4509-9af6-039b2ec7f28b', // [PROD] jegoldel@amazon.com
    'operatorUser_3ca61285-8ac8-4de1-8688-c8c4f1d7d68d', // [PROD] jaswideo@amazon.com
    'operatorUser_9c0c37f1-9ca5-4299-97b9-a3df8b9270f0', // [PROD] rverin@amazon.com
    'operatorUser_5c347c6a-c76a-4f48-b432-484e637c0616', // [PROD] cortsmik@amazon.com
    'operatorUser_c0ab9c32-c386-4d93-b892-bdadc2ff5627', // [PROD] rakelh@amazon.com
    'operatorUser_3675fbd7-68ef-48fc-973c-1222b80e44a7', // [PROD] resvanna@amazon.com
    'operatorUser_70dd4c65-6f35-4fc4-a616-574d25a29d2b', // [PROD] hzhiyun@amazon.com
    'operatorUser_92c2ac01-aca9-4a77-806f-cf6e115ea484', // [PROD] ycchnm@amazon.com
    'operatorUser_ee03ab59-e82e-4003-8abb-0b4399efdbe1', // [PROD] andekluk@amazon.com
    'operatorUser_363b154a-e354-4378-8653-7f518a862440', // [PROD] samorrit@amazon.com
    'operatorUser_bbd463d8-278e-4e89-b4fc-ccec95a251b9', // [PROD] kibeckmi@amazon.com
    'operatorUser_99ee2cc7-8c20-40d0-b248-9ca7781d7a3e', // [PROD] huasong@amazon.com
    'operatorUser_0a555bca-e043-42e6-b21a-11224599023b', // [PROD] lidanl@amazon.com
    'operatorUser_2784f297-a690-471d-bcce-700b156484bf', // [PROD] haiyinc@amazon.com
    'operatorUser_10318305-39dc-4cf5-a7d0-e19198ca0f23', // [PROD] ftiantia@amazon.com
    'operatorUser_64431f84-7c05-4a4b-aade-94e07b74a2e4', // [PROD] dlongmz@amazon.com
    'operatorUser_686bdfeb-7e4a-49ac-a8e3-5bcb224202f9', // [PROD] chunmw@amazon.com
    'operatorUser_5f50bbd0-247a-49f7-a999-ebf43731a749', // [PROD] levyandl@amazon.com
    'operatorUser_df153d07-0ee5-41a6-b6d4-e84c9feeddb0', // [PROD] dtparnel@amazon.com
    'operatorUser_6073099b-626c-4775-b3ba-b28ed1ea6a73', // [PROD] tianbiya@amazon.com
    'operatorUser_f25119bc-16c3-47b3-92a3-03710cc2a793', // [PROD] haotiah@amazon.com
    'operatorUser_2c724080-98fd-4474-bf25-ed7442eb41a8', // [PROD] chnmen@amazon.com
    'operatorUser_ff3cc87f-6851-4343-8493-36911abf360e', // [PROD] mengzeng@amazon.com
    'operatorUser_2972ebc8-7d2e-4410-8eda-9e4fccf571df', // [PROD] renmengs@amazon.com
    'operatorUser_62f64f8d-5fb7-4dd9-a04e-be327a70cfcf', // [PROD] jiewun@amazon.com
    'operatorUser_d78b8c8e-f76a-4e8c-9a82-9a4ab2387e0a', // [PROD] haolim@amazon.com
    'operatorUser_3aa295eb-ee58-4efb-8007-09c0cc165b53', // [PROD] wdinwang@amazon.com
    'operatorUser_51cdd059-625c-45af-a01b-bc5a9dcf544c', // [PROD] limzya@amazon.com
    'operatorUser_5daa8e13-7967-4ef4-b70b-f09ccb1ff09e', // [PROD] jbourhis+us@amazon.com
    'operatorUser_acd361e1-0525-46bb-bfd8-42a57d9268af', // [PROD] whaiqin@amazon.com
    'operatorUser_e4678701-a2c8-4ae1-8c70-e7c04d72353f', // [PROD] zhzeng@amazon.com
    'operatorUser_5007e4fd-ac5a-40c5-a947-60f011a704d2', // [PROD] longping@amazon.com
    'operatorUser_a1b4daf0-9a77-4835-aa29-5cfe20a6ad5b', // [PROD] jiew@amazon.com
    'operatorUser_42d71efe-771d-411e-9029-05e51267a128', // [PROD] alliun@amazon.com
    'operatorUser_a213f3b8-4dc6-4e4b-9cfe-85be7e910807', // [PROD] robesmat@amazon.com
    'operatorUser_a8fb1676-e08c-4cc8-a551-1a36b6a37a2f', // [PROD] zhhongj@amazon.com
    'operatorUser_67df7fc3-30d9-41b2-b91c-55e93a1dc357', // [PROD] ruiqzhou@amazon.com
  ],
};

const ASSIGNMENT_WORKSPACE_FEATURE = new FeatureFlagService([
  {
    feature: 'ASSIGNMENT_WORKSPACE_FEATURE',
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA, Stage.PROD],
  },
]);

export { ASSIGNMENT_WORKSPACE_FEATURE };
