import { CrudPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const ForwarderUserDocumentPermissionName = {
  ...CrudPermissionName,
};
export const ForwarderUserDocumentTemplatePermissionName = {
  ...CrudPermissionName,
};

export default {
  [ObjectType.DOCUMENT]: ForwarderUserDocumentPermissionName,
  [ObjectType.DOCUMENT_TEMPLATE]: ForwarderUserDocumentTemplatePermissionName,
};
