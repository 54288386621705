import { ApolloLink } from '@apollo/client';

type GetActiveBrokerIdFunction = () => string | null;

export const createActiveBrokerIdLink = (
  getActiveBrokerId: GetActiveBrokerIdFunction,
): ApolloLink =>
  new ApolloLink((operation, forward) => {
    const activeBrokerId = getActiveBrokerId() || '';
    operation.setContext(() => ({
      headers: {
        'active-broker-id': activeBrokerId,
      },
    }));
    return forward(operation);
  });

export const localStorageActiveBrokerIdLink = createActiveBrokerIdLink(() =>
  localStorage.getItem('ACTIVE_BROKER_ID'),
);
