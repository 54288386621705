export const signerFields = {
  'direct-poa': {
    signerName: 'officerName',
    signerTitle: 'officerTitle',
  },
  'master-poa': {
    signerName: 'officerName',
    signerTitle: 'officerTitle',
  },
  'sub-poa': {
    signerName: 'forwarderOfficerName',
    signerTitle: 'forwarderOfficerTitle',
  },
  externalSubPOA: {
    signerName: 'forwarderOfficerName',
    signerTitle: 'forwarderOfficerTitle',
  },
  subPOAAuthority: {
    signerName: 'forwarderOfficerName',
    signerTitle: 'forwarderOfficerTitle',
  },
  cc: {
    signerName: 'secondaryOfficerName',
    signerTitle: 'secondaryOfficerTitle',
  },
};
