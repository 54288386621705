import { split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { isMonsEnv, getEnv } from '@xbcb/ui-env';
import { batchHttpLink, httpLink } from './batchHttp';
import { persistedQueriesLink } from './persistedQuery';
import { wsLink } from './ws';
import { UiStage } from '@xbcb/ui-types';

const isMons = isMonsEnv();

const nonBatchedQueries = ['searchWorkOrderTasks'];
const { stage } = getEnv();
export const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  split(
    () => stage === UiStage.GAMMA,
    httpLink,
    split(
      ({ operationName }) => nonBatchedQueries.includes(operationName),
      isMons ? persistedQueriesLink.concat(httpLink) : httpLink,
      isMons ? persistedQueriesLink.concat(batchHttpLink) : batchHttpLink,
    ),
  ),
);
