import { getEnv } from '@xbcb/ui-env';
import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
  UserData,
} from 'amazon-cognito-identity-js';
import {
  CognitoIdentityCredentials,
  fromCognitoIdentityPool,
  FromCognitoIdentityPoolParameters,
  CognitoIdentityCredentialProvider,
} from '@aws-sdk/credential-provider-cognito-identity';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import {
  CognitoIdentityProviderClient,
  AdminDeleteUserCommand,
  AdminListUserAuthEventsCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import { memoize } from '@aws-sdk/property-provider';
import log from '@xbcb/log';

/**
 * Helpers to determine when our memoized credential provider should
 * refresh its credentials.
 */
const FIFTEEN_MINUTES = 1000 * 60 * 15;

interface DeleteUserResult {
  errors: string[];
  deleted: string[];
}

const isExpired = (credentials: CognitoIdentityCredentials): boolean =>
  credentials.expiration
    ? credentials.expiration.getTime() - new Date().getTime() < FIFTEEN_MINUTES
    : true;

const requiresRefresh = (credentials: CognitoIdentityCredentials): boolean =>
  // If the credentials include an expiration time (in our case they well), they will need to be refreshed.
  Boolean(credentials.expiration);

export const isCognitoUserSession = (
  session: unknown,
): session is CognitoUserSession =>
  Boolean((session as CognitoUserSession).getAccessToken);

export let cognitoSub: string | null;

type UserAttributes = {
  // eslint-disable-next-line camelcase
  phone_number?: string;
  [key: string]: string | undefined;
};

export const logout = (): void => {
  Cognito.signOutUser();
  cognitoSub = null;
};

export const SMS = 'SMS';
export const TOTP = 'TOTP';
export const SMS_MFA = 'SMS_MFA';
export const SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA';
export type MfaType =
  | typeof SMS
  | typeof TOTP
  | typeof SMS_MFA
  | typeof SOFTWARE_TOKEN_MFA;

// inlt client side user API methods (AWS Cognito)
export class Cognito {
  static credentialProvider: CognitoIdentityCredentialProvider | null;
  static getUserPool() {
    const {
      COGNITO_USER_POOL_ID: UserPoolId,
      COGNITO_APP_CLIENT_ID: ClientId,
    } = getEnv<{
      COGNITO_USER_POOL_ID: string;
      COGNITO_APP_CLIENT_ID: string;
    }>();
    return new CognitoUserPool({
      UserPoolId,
      ClientId,
      AdvancedSecurityDataCollectionFlag: true,
    });
  }

  static makeUser(inEmail: string) {
    const email = inEmail ? inEmail.toLowerCase() : inEmail;
    return new CognitoUser({ Username: email, Pool: this.getUserPool() });
  }

  static sendMFACode(user: CognitoUser, code: string, totp?: string) {
    return new Promise((resolve, reject) => {
      user.sendMFACode(
        code,
        {
          onSuccess: (result) => {
            resolve(result.getIdToken().getJwtToken());
          },
          onFailure: (err) => {
            reject(err);
          },
        },
        totp,
      );
    });
  }

  static getCurrentUser() {
    return this.getUserPool().getCurrentUser();
  }

  static async getCurrentUserV2() {
    const user = this.getUserPool().getCurrentUser();
    if (user) {
      await this.getSession(user);
      return user;
    } else {
      throw new Error('No user found');
    }
  }

  static getSession(user: CognitoUser): Promise<CognitoUserSession> {
    return new Promise((resolve, reject) => {
      user.getSession(
        (err: Error | null, session: CognitoUserSession | null) => {
          if (err) reject(err);
          else if (session) resolve(session);
        },
      );
    });
  }

  static async getUserData({ bypassCache = false }): Promise<UserData> {
    const options = {
      bypassCache,
    };
    const user = await this.getCurrentUserV2();
    return new Promise((resolve, reject) => {
      user.getUserData((err, data) => {
        if (err) reject(err);
        if (data) resolve(data);
      }, options);
    });
  }

  static async getMFASettings(options = {}) {
    const userData = await this.getUserData(options);
    const PreferredMfaSetting = userData?.PreferredMfaSetting || 'NOMFA';
    const UserMFASettingList = userData?.UserMFASettingList || [];
    return {
      PreferredMfaSetting,
      UserMFASettingList,
    };
  }

  static async listDevices() {
    const user = await this.getCurrentUserV2();
    new Promise((resolve, reject) => {
      user.listDevices(10, null, {
        onSuccess: (result) => resolve(result),
        onFailure: (err) => reject(err),
      });
    });
  }

  static async setDeviceStatusRemembered() {
    const user = await this.getCurrentUserV2();
    this.getCachedDeviceKeyAndPassword(user);
    return new Promise((resolve, reject) => {
      user.setDeviceStatusRemembered({
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => reject(err),
      });
    });
  }

  static async setDeviceStatusNotRemembered() {
    const user = await this.getCurrentUserV2();
    this.getCachedDeviceKeyAndPassword(user);
    return new Promise((resolve, reject) => {
      user.setDeviceStatusNotRemembered({
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => reject(err),
      });
    });
  }

  static getCachedDeviceKeyAndPassword(user: CognitoUser) {
    return user.getCachedDeviceKeyAndPassword();
  }

  static async setUserMfaPreference({
    mfaType,
    disable = false,
  }: {
    mfaType: MfaType;
    disable?: boolean;
  }) {
    const user = await this.getCurrentUserV2();
    const { phone_number: phoneNumber } = await this.getUserAttributes();

    const { UserMFASettingList } = await this.getMFASettings({
      bypassCache: true,
    });
    return new Promise((resolve, reject) => {
      const smsPreferredMfa =
        (!disable && mfaType === SMS) ||
        (disable && mfaType === TOTP && UserMFASettingList.includes(SMS_MFA))
          ? true
          : false;
      const smsEnabled =
        (disable && mfaType !== SMS && UserMFASettingList.includes(SMS_MFA)) ||
        (!disable && (mfaType === SMS || UserMFASettingList.includes(SMS_MFA)))
          ? true
          : false;
      const totpPreferredMfa =
        (!disable && mfaType === TOTP) ||
        (disable &&
          mfaType === SMS &&
          UserMFASettingList.includes(SOFTWARE_TOKEN_MFA))
          ? true
          : false;
      const totpEnabled =
        (disable &&
          mfaType !== 'TOTP' &&
          UserMFASettingList.includes(SOFTWARE_TOKEN_MFA)) ||
        (!disable &&
          (mfaType === 'TOTP' ||
            UserMFASettingList.includes(SOFTWARE_TOKEN_MFA)))
          ? true
          : false;
      const smsMfaSettings = phoneNumber
        ? {
            PreferredMfa: smsPreferredMfa,
            Enabled: smsEnabled,
          }
        : null;
      const totpMfaSettings =
        mfaType === 'TOTP'
          ? {
              PreferredMfa: totpPreferredMfa,
              Enabled: totpEnabled,
            }
          : null;
      user.setUserMfaPreference(
        smsMfaSettings,
        totpMfaSettings,
        (err, result) => {
          if (err) reject(err);
          resolve(result);
        },
      );
    });
  }

  static async disableMFA() {
    const user = await this.getCurrentUserV2();
    return new Promise((resolve, reject) => {
      user.setUserMfaPreference(
        { PreferredMfa: false, Enabled: false },
        { PreferredMfa: false, Enabled: false },
        (err, result) => {
          if (err) reject(err);
          resolve(result);
        },
      );
    });
  }

  static async getAttributeVerificationCode(attribute: string) {
    const user = await this.getCurrentUserV2();
    return new Promise((resolve, reject) => {
      user.getAttributeVerificationCode(attribute, {
        onSuccess: () => {
          resolve({ result: 'SUCCESS' });
        },
        onFailure: (err) => reject(err),
      });
    });
  }

  static async verifyAttribute(attribute: string, code: string) {
    const user = await this.getCurrentUserV2();
    return new Promise((resolve, reject) => {
      user.verifyAttribute(attribute, code, {
        onSuccess: (result) => resolve(result),
        onFailure: (err) => reject(err),
      });
    });
  }

  static async generateURI(authenticatorDisplayName: string) {
    const user = await this.getCurrentUserV2();
    return this.associateSoftwareToken(user, authenticatorDisplayName);
  }

  static associateSoftwareToken(
    user: CognitoUser,
    authenticatorDisplayName: string,
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      user.associateSoftwareToken({
        onFailure: (err) => {
          reject(err);
        },

        associateSecretCode: (secretCode) => {
          const uri = `otpauth://totp/INLT:${user.getUsername()}?secret=${secretCode}&issuer=${
            authenticatorDisplayName === 'INLT'
              ? 'INLT'
              : window.location.hostname
          }`;
          resolve(uri);
        },
      });
    });
  }

  static async verifySoftwareToken(challengeAnswer: string): Promise<
    | CognitoUserSession
    | {
        Session: string;
        Status: 'SUCCESS' | 'ERROR';
      }
  > {
    const user = await this.getCurrentUserV2();
    return new Promise((resolve, reject) => {
      user.verifySoftwareToken(challengeAnswer, 'My TOTP device', {
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });
  }

  static signInUser(
    values: { email: string; password: string },
    {
      userPasswordAuthenticationFlowType,
    }: {
      userPasswordAuthenticationFlowType?: boolean;
    },
  ): Promise<{
    verification?: boolean;
    jwtToken?: string;
    user: CognitoUser;
    delivery?: {
      DeliveryMedium: any;
      Destination?: any;
      DeviceName?: any;
    };
    newPassword?: boolean;
    userAttributes?: any;
  }> {
    const email = values.email ? values.email.toLowerCase() : values.email;
    const authenticationData = {
      Username: email,
      Password: values.password,
    };

    const user = this.makeUser(email);
    if (userPasswordAuthenticationFlowType) {
      user.setAuthenticationFlowType('USER_PASSWORD_AUTH');
    }

    const authenticationDetails = new AuthenticationDetails(authenticationData);

    return new Promise((resolve, reject) => {
      user.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          resolve({ jwtToken: result.getIdToken().getJwtToken(), user });
        },

        onFailure: (err) => {
          reject(err);
        },

        mfaRequired: (type, codeDeliveryDetails) => {
          resolve({
            verification: true,
            user,
            delivery: {
              DeliveryMedium: codeDeliveryDetails.CODE_DELIVERY_DELIVERY_MEDIUM,
              Destination: codeDeliveryDetails.CODE_DELIVERY_DESTINATION,
            },
          });
        },

        newPasswordRequired: (userAttributes, requiredAttributes) => {
          resolve({ newPassword: true, user, userAttributes });
        },

        totpRequired: (type, deliveryDetails) => {
          resolve({
            verification: true,
            user,
            delivery: {
              DeliveryMedium: 'TOTP',
              DeviceName: deliveryDetails.FRIENDLY_DEVICE_NAME,
            },
          });
        },
      });
    });
  }

  static submitNewPassword = (
    user: CognitoUser,
    userAttributes: any,
    newPassword: string,
  ): Promise<CognitoUserSession> => {
    // / this library (Which is official AWS but sketchy, doesnt accept this field back)
    delete userAttributes.email_verified;
    return new Promise((resolve, reject) => {
      user.completeNewPasswordChallenge(newPassword, userAttributes, {
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => {
          reject(err);
        },
      });
    });
  };

  static forgotPassword(inEmail: string): Promise<any> {
    const email = inEmail ? inEmail.toLowerCase() : inEmail;
    const user = this.makeUser(email);

    return new Promise((resolve, reject) => {
      user.forgotPassword({
        onSuccess: (data) => {
          // https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ForgotPassword.html#API_ForgotPassword_ResponseSyntax
          resolve(data);
        },
        onFailure: (err) => {
          reject(err);
        },
        inputVerificationCode: (data) => {
          resolve({
            verification: true,
            user,
            delivery: data.CodeDeliveryDetails,
          });
        },
      });
    });
  }

  static async changePassword(oldPassword: string, newPassword: string) {
    const user = await this.getCurrentUserV2();
    return new Promise((resolve, reject) => {
      user.changePassword(oldPassword, newPassword, (err, result) => {
        if (err) reject(err);
        else resolve(result);
      });
    });
  }

  static confirmPassword(
    user: CognitoUser,
    verificationCode: string,
    newPassword: string,
  ) {
    return new Promise<void>((resolve, reject) => {
      user.confirmPassword(verificationCode, newPassword, {
        onSuccess: () => {
          resolve();
        },
        onFailure: (err) => {
          // https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ForgotPassword.html#API_ForgotPassword_Errors
          reject(err);
        },
      });
    });
  }

  static async getUserToken(currentUser: CognitoUser): Promise<string> {
    const session = await this.getSession(currentUser);
    return new Promise((resolve, reject) => {
      resolve(session.getIdToken().getJwtToken());
    });
  }

  static async getUserAttributes(): Promise<UserAttributes> {
    const user = await this.getCurrentUserV2();
    return new Promise((resolve, reject) => {
      user.getUserAttributes((error, result) => {
        if (error) {
          reject(error);
          return;
        } else if (result) {
          const obj = result.reduce<UserAttributes>((acc, { Name, Value }) => {
            acc[Name] = Value;
            if (Name === 'sub') {
              cognitoSub = Value;
            }
            return acc;
          }, {});
          resolve(obj);
        }
      });
    });
  }

  static async updateAttributes(
    attributes: Parameters<CognitoUser['updateAttributes']>[0],
  ) {
    const user = await this.getCurrentUserV2();
    return new Promise((resolve, reject) => {
      user.updateAttributes(attributes, (err, result) => {
        if (err) reject(err);
        resolve(result);
      });
    });
  }

  static async getAwsCredentials(userToken: string, force?: boolean) {
    if (this.credentialProvider && !force) return;
    const {
      COGNITO_USER_POOL_ID: UserPoolId,
      COGNITO_REGION: CognitoRegion,
      COGNITO_IDENTITY_POOL_ID: IdentityPoolId,
    } = getEnv();
    const authenticator = `cognito-idp.${CognitoRegion}.amazonaws.com/${UserPoolId}`;
    const v3Params: FromCognitoIdentityPoolParameters = {
      identityPoolId: IdentityPoolId,
      client: new CognitoIdentityClient({ region: CognitoRegion }),
      logins: {
        [authenticator]: userToken,
      },
    };
    this.credentialProvider = memoize(
      fromCognitoIdentityPool(v3Params),
      isExpired,
      requiresRefresh,
    );
  }

  static signOutUser() {
    const currentUser = this.getCurrentUser();
    if (currentUser !== null) {
      currentUser.signOut();
    }
    if (this.credentialProvider) {
      this.credentialProvider = null;
    }
  }

  static async getUserId(): Promise<string | undefined> {
    let identityId;
    if (this.credentialProvider) {
      identityId = (await this.credentialProvider()).identityId;
    }
    return identityId;
  }

  static async authUser({ force }: { force?: boolean } = {}) {
    const currentUser = this.getCurrentUser();

    if (currentUser === null) {
      return false;
    }
    const userToken = await this.getUserToken(currentUser);

    await this.getAwsCredentials(userToken, force);

    return this.credentialProvider;
  }

  static getCognitoClient() {
    // Todo Set region as environment variable is set in XbcbClientAuthority
    return new CognitoIdentityProviderClient({
      region: 'us-west-2',
    });
  }

  static async findLatestSignInPassEvent(
    username: string,
  ): Promise<Date | undefined> {
    if (!username) {
      throw new Error(`Cognito error. Username ${username} not present`);
    }

    const cognitoClient = Cognito.getCognitoClient();

    return Cognito.findLatestSignInPassEventFunc(cognitoClient, username);
  }

  static async findLatestSignInPassEventFunc(
    cognito: CognitoIdentityProviderClient,
    username: string,
    nextToken?: string,
    latestEventDate?: Date,
  ): Promise<Date | undefined> {
    // COGNITO_USER_POOL_ID is set as environment variable is set in XbcbClientAuthority
    const { COGNITO_USER_POOL_ID: UserPoolId } = process.env;

    const command = new AdminListUserAuthEventsCommand({
      UserPoolId: UserPoolId,
      Username: username.toLowerCase(), // username is stored as lowercase in cognito
      MaxResults: 60, // Set MaxResults to max i.e 60
      NextToken: nextToken, // Use the NextToken for pagination
    });

    try {
      const response = await cognito.send(command);
      response.AuthEvents?.forEach((event) => {
        if (
          event.EventType === 'SignIn' &&
          event.EventResponse === 'Pass' &&
          event.CreationDate
        ) {
          const eventDate = new Date(event.CreationDate);
          if (!latestEventDate || eventDate > latestEventDate) {
            latestEventDate = eventDate;
          }
        }
      });

      // If there's a NextToken, recursively call the function to get more events
      if (response.NextToken) {
        return await this.findLatestSignInPassEventFunc(
          cognito,
          username,
          response.NextToken,
          latestEventDate,
        );
      }

      return latestEventDate;
    } catch (error) {
      throw new Error(
        `Error fetching auth events for username: ${username}: ${error}`,
      );
    }
  }

  static async deleteUser(
    username: string | undefined,
  ): Promise<DeleteUserResult> {
    if (!username) {
      throw new Error('Cognito error. Username not present');
    }

    const { COGNITO_USER_POOL_ID: UserPoolId } = process.env;

    const params = {
      UserPoolId,
      Username: username.toLowerCase(), // username is stored as lowercase in cognito
    };

    const commmand = new AdminDeleteUserCommand(params);
    const errors: string[] = [];
    const deleted: string[] = [];

    try {
      log.info(
        `Permanently deleting user ${username} from user pool ${UserPoolId}`,
      );
      await Cognito.getCognitoClient().send(commmand);

      deleted.push(`{username: ${username}, userPoolId: ${UserPoolId}`);
    } catch (error) {
      if (error.message === 'UserNotFoundException') {
        deleted.push(`{username: ${username}, userPoolId: ${UserPoolId}`);
      } else {
        errors.push(
          `{username: ${username}, userPoolId: ${UserPoolId}, error: ${error}}`,
        );
      }
    }
    return { deleted, errors };
  }
}
