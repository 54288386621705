import { ApolloClient, DocumentNode } from '@apollo/client';
import { Tag } from '@xbcb/api-gateway-client';
import { timeout } from '@xbcb/js-utils';
import {
  getRecordFromResponseV2,
  getOneQuery,
  SearchQuery,
  createSearchQueryVariables,
  CrudType,
  SearchType,
} from '@xbcb/shared-queries';
import { AnyObject, RecordType } from '@xbcb/shared-types';
import { reportError } from '..';

type RetryQueryUntilValidProps<TResult> = {
  client: ApolloClient<object>;
  recordName: RecordType;
  validator: (result: TResult) => boolean;
  retries?: number;
  // in MS
  timeoutDelay?: number;
  query: DocumentNode;
  variables: AnyObject;
  crudOrSearchType: CrudType | SearchType;
  throwError?: boolean;
};

export const retryQueryUntilValid = async <TResult>({
  client,
  recordName,
  validator,
  retries = 15,
  timeoutDelay = 1000,
  query,
  variables,
  crudOrSearchType,
  throwError,
}: RetryQueryUntilValidProps<TResult>): Promise<TResult | undefined> => {
  try {
    for (let attempt = 0; attempt < retries; attempt++) {
      const response = await client.query({
        query,
        fetchPolicy: 'network-only',
        variables,
      });
      const parsedResponse = getRecordFromResponseV2({
        response,
        crudOrSearchType,
        recordName,
      });
      // Return early if the parsedResponse is now valid
      if (validator(parsedResponse)) return parsedResponse;
      // await the timeoutDelay before attempting again
      await timeout(timeoutDelay);
    }
    throw new Error(
      `Attempted to query ${recordName} ${retries} times but result never satisfied the validator`,
    );
  } catch (e) {
    reportError(e);
    if (throwError) throw e;
    return undefined;
  }
};

type RetryQueryUntilValidExtension<TResult> = Omit<
  RetryQueryUntilValidProps<TResult>,
  // We should not accept `variables`, `query`, or `crudOrSearchType` as we
  // should construct those variables in the extension helper function. Also,
  // we should take `fields` as it'll be used to construct the query
  'variables' | 'query' | 'crudOrSearchType'
> & {
  fields?: string;
  fragments?: string;
};

export const retryGetOneQueryUntilValid = async <TResult>({
  id,
  fields,
  // `recordName` is a baseProp but we need it in this helper function
  recordName,
  fragments,
  ...baseProps
}: // In addition to the `RetryQueryUntilValidExtension` we need the record id
RetryQueryUntilValidExtension<TResult> & { id: string }): Promise<
  TResult | undefined
> =>
  retryQueryUntilValid<TResult>({
    ...baseProps,
    recordName,
    variables: { id },
    query: getOneQuery({ recordName, fields, fragments }),
    crudOrSearchType: 'get',
  });

export const retrySearchQueryUntilValid = async <
  TResult,
  TAdditionalSearchCriteria,
>({
  fields,
  operatorId,
  tags = [],
  additionalSearchCriteria,
  // `recordName` is a baseProp but we need it in this helper function
  recordName,
  fragments,
  ...baseProps
}: // In addition to the `RetryQueryUntilValidExtension` we need the operatorId
// Additionally, `tags` and `additionalSearchCriteria` are optional, one of
// which should be provided so we don't return all non-deleted records for the
// given operator
RetryQueryUntilValidExtension<TResult> & {
  operatorId: string;
  tags?: Tag[];
  additionalSearchCriteria?: TAdditionalSearchCriteria;
}): Promise<TResult | undefined> => {
  const variables = createSearchQueryVariables({
    deletedTimeExists: false,
    operatorId,
    sortOptions: [{ field: 'createdTime' }],
    tags,
  });
  // If additional search criteria (other than tags) are provided update accordingly
  if (additionalSearchCriteria) {
    variables.input.searchCriteria = {
      ...variables.input.searchCriteria,
      ...additionalSearchCriteria,
    };
  }

  return retryQueryUntilValid({
    ...baseProps,
    recordName,
    variables,
    query: SearchQuery({ recordName, fields, fragments }),
    crudOrSearchType: 'search',
  });
};
