import { CrudPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const OperatorUserWorkOrderTaskEscalationMessagePermissionName = {
  ...CrudPermissionName,
};

export default {
  [ObjectType.WORK_ORDER_TASK_ESCALATION_MESSAGE]:
    OperatorUserWorkOrderTaskEscalationMessagePermissionName,
};
