import React from 'react';
import { Redirect } from 'react-router';
import { useParams } from 'react-router-dom';
import { getRecordType } from '@xbcb/core';
import { TemplateBasedSignUploadsCardDocs } from '@xbcb/document-components';
import { RecordType } from '@xbcb/shared-types';
import StampBasedSign from '../StampBasedSign';
import TemplateBasedSign from '../TemplateBasedSign';
import { detect } from 'detect-browser';
import { Modal } from 'antd';
// This is the base SignPage component that is meant to be wrapped in the
// corresponding UI to create the actual SignPage component. It can then
// determine which attribution and Docs (will be added as a prop soon) to pass in.
const SignPageBase: React.FC<{
  attribution: string;
  Docs: TemplateBasedSignUploadsCardDocs;
}> = ({ attribution, Docs }) => {
  const { recordId } = useParams<{ recordId: string }>();
  const dsrRecordType = getRecordType(recordId);
  const browser = detect();
  if (browser?.name === 'safari') {
    Modal.warning({
      title:
        'Import Sign is not supported on Safari, please use another browser',
    });
    return <Redirect to="/" />;
  }
  switch (dsrRecordType) {
    case RecordType.STAMP_DOCUMENT_SIGN_REQUEST:
      return <StampBasedSign id={recordId} attribution={attribution} />;
    case RecordType.TEMPLATE_DOCUMENT_SIGN_REQUEST:
      return (
        <TemplateBasedSign
          id={recordId}
          attribution={attribution}
          Docs={Docs}
        />
      );
    default:
      return <Redirect to="/" />;
  }
};

export default SignPageBase;
