/**
 * Enum values associated with WorkOrderConfirmation
 */

// Client approval status
export enum WorkOrderConfirmationApprovalStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DISPUTED = 'DISPUTED',
  VOID = 'VOID',
  EXPIRED = 'EXPIRED',
}

// Transactions that may require work order confirmations
export enum UsConsumptionEntryConfirmationTransactionType {
  UPSERT_US_CONSUMPTION_ENTRY_SUMMARY_CBP = 'UPSERT_US_CONSUMPTION_ENTRY_SUMMARY_CBP',
  REPLACE_US_CONSUMPTION_ENTRY_RELEASE_CBP = 'REPLACE_US_CONSUMPTION_ENTRY_RELEASE_CBP',
  UPDATE_US_CONSUMPTION_ENTRY_RELEASE_CBP = 'UPDATE_US_CONSUMPTION_ENTRY_RELEASE_CBP',
  UPDATE_US_CONSUMPTION_ENTRY_PGA_CBP = 'UPDATE_US_CONSUMPTION_ENTRY_PGA_CBP',
}

export enum UsPostSummaryCorrectionConfirmationTransactionType {
  UPSERT_US_POST_SUMMARY_CORRECTION_SUMMARY_CBP = 'UPSERT_US_POST_SUMMARY_CORRECTION_SUMMARY_CBP',
}

export const WorkOrderConfirmationTransactionType = {
  ...UsConsumptionEntryConfirmationTransactionType,
  ...UsPostSummaryCorrectionConfirmationTransactionType,
};

export type WorkOrderConfirmationTransactionType =
  | UsConsumptionEntryConfirmationTransactionType
  | UsPostSummaryCorrectionConfirmationTransactionType;
