import { AccountType } from '@xbcb/shared-types';
import { EsDataType } from '../../index';
import { createExternalSystemTableConfig } from './externalSystem';

export const operatorSystem = createExternalSystemTableConfig({
  columns: {
    customsBrokerId: {
      name: 'CustomsBroker ID',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    customsBrokerName: {
      default: true,
      name: 'CustomsBroker',
      type: EsDataType.TEXT,
      whitelist: [AccountType.OPERATOR],
    },
  },
});
