import { RecordType, AccountType } from '../enums';

// https://quip-amazon.com/u3KqArFg8EhB/Dropdown-reasons-for-shipment-cancellation

const euCustomsEntryCancellationReasons = [
  'Long time with no confirmation on booking',
  'Schedule proposed, but seller declines due to time urgency.',
  'Seller could not make it due to postpone of cargo ready day',
  'Seller could not make it due to unknown reason',
  'Gateway congestion',
  'FC capacity issue',
  'Services charge is too high which is not acceptable',
  'Duty payment beyond expectation',
  'Seller unable to meet broker compliance requirements',
  'Seller cancelled due to outside reason (extreme weather, Suez)',
  'SSQL restrictions',
  'Seller decided to use another Freight Forwarder',
  'Wrong details submitted',
  'Other',
];

const deliveryOrderCancellationReasons = [
  'Issuing delivery order elsewhere',
  'Accidental delivery order creation',
  'Other',
];
const continuousBondCancellationReasons = [
  'Accidental continuous bond request',
  'Bond request expired',
  'Continuous bond no longer needed',
  'Continuous bond purchased elsewhere',
  'Other',
];

const usIorActivationCancellaitonReasons = [
  'Activation request canceled by customer',
  'Activation request expired',
  'Duplicate activation request (same FF)',
  'FF linked to another active IOR profile',
  'Other',
];

const usType86CancellationReasons = [
  'Shipment does not qualify for type of filing',
  'Other',
];
const sharedShipmentCancellationReasons = [
  'Duplicate Entry Filed w/INLT',
  'Duplicate Entry filed w/different broker',
  'Entry replaced by 7512 (in-bond shipment)',
  'Accidental shipment creation',
  'Shipment no longer needs clearance',
  'Other',
];

const sharedUsIsfCancellationReasons = [
  'ISF filed elsewhere',
  'Duplicate ISF filing',
  'No longer need ISF filing',
  'Other',
];
const sharedUsConsumptionEntryCancellationReasons = [
  'Duplicate Entry Filed w/INLT',
  'Duplicate Entry filed w/different broker',
  'Entry replaced by 7512 (in-bond shipment)',
  'Accidental Entry creation',
  'Other',
];
const sharedUsInBondCancellationReasons = [
  'Duplicate In-Bond Filed w/INLT',
  'Duplicate In-Bond filed w/different broker',
  'In-bond replaced by 7501 (consumption entry)',
  'Accidental In-Bond creation',
  'Other',
];
export const recordTypeToCancellationReasonMap: Record<
  AccountType,
  Record<string, string[]>
> = {
  [AccountType.OPERATOR]: {
    [RecordType.SHIPMENT]: [
      ...sharedShipmentCancellationReasons,
      'Shipment revision - need to refile (INLT Operations)',
    ],
    [RecordType.US_ISF]: sharedUsIsfCancellationReasons,
    [RecordType.US_CONSUMPTION_ENTRY]: [
      ...sharedUsConsumptionEntryCancellationReasons,
      'Entry revision - need to refile (INLT Operations)',
    ],
    [RecordType.US_IN_BOND]: [
      ...sharedUsInBondCancellationReasons,
      'In-bond revision - need to refile (INLT Operations)',
    ],
    [RecordType.DELIVERY_ORDER]: deliveryOrderCancellationReasons,
    [RecordType.US_IOR_CONTINUOUS_BOND_REQUEST]:
      continuousBondCancellationReasons,
    [RecordType.US_IOR_ACTIVATION]: usIorActivationCancellaitonReasons,
    [RecordType.DE_CUSTOMS_ENTRY]: euCustomsEntryCancellationReasons,
    [RecordType.NL_CUSTOMS_ENTRY]: euCustomsEntryCancellationReasons,
    [RecordType.FR_CUSTOMS_ENTRY]: euCustomsEntryCancellationReasons,
    [RecordType.GB_CUSTOMS_ENTRY]: euCustomsEntryCancellationReasons,
    [RecordType.US_TYPE86_ENTRY]: usType86CancellationReasons,
  },
  [AccountType.FORWARDER]: {
    [RecordType.SHIPMENT]: sharedShipmentCancellationReasons,
    [RecordType.US_ISF]: sharedUsIsfCancellationReasons,
    [RecordType.US_CONSUMPTION_ENTRY]:
      sharedUsConsumptionEntryCancellationReasons,
    [RecordType.US_IN_BOND]: sharedUsInBondCancellationReasons,
    [RecordType.DELIVERY_ORDER]: deliveryOrderCancellationReasons,
    [RecordType.US_IOR_CONTINUOUS_BOND_REQUEST]:
      continuousBondCancellationReasons,
    [RecordType.US_IOR_ACTIVATION]: usIorActivationCancellaitonReasons,
    [RecordType.DE_CUSTOMS_ENTRY]: euCustomsEntryCancellationReasons,
    [RecordType.NL_CUSTOMS_ENTRY]: euCustomsEntryCancellationReasons,
    [RecordType.FR_CUSTOMS_ENTRY]: euCustomsEntryCancellationReasons,
    [RecordType.GB_CUSTOMS_ENTRY]: euCustomsEntryCancellationReasons,
    [RecordType.US_TYPE86_ENTRY]: usType86CancellationReasons,
  },
  [AccountType.SHIPPER]: {
    [RecordType.SHIPMENT]: sharedShipmentCancellationReasons,
    [RecordType.US_ISF]: sharedUsIsfCancellationReasons,
    [RecordType.US_CONSUMPTION_ENTRY]:
      sharedUsConsumptionEntryCancellationReasons,
    [RecordType.US_IN_BOND]: sharedUsInBondCancellationReasons,
    [RecordType.DELIVERY_ORDER]: deliveryOrderCancellationReasons,
    [RecordType.US_IOR_CONTINUOUS_BOND_REQUEST]:
      continuousBondCancellationReasons,
    [RecordType.US_IOR_ACTIVATION]: usIorActivationCancellaitonReasons,
    [RecordType.DE_CUSTOMS_ENTRY]: euCustomsEntryCancellationReasons,
    [RecordType.NL_CUSTOMS_ENTRY]: euCustomsEntryCancellationReasons,
    [RecordType.FR_CUSTOMS_ENTRY]: euCustomsEntryCancellationReasons,
    [RecordType.GB_CUSTOMS_ENTRY]: euCustomsEntryCancellationReasons,
    [RecordType.US_TYPE86_ENTRY]: usType86CancellationReasons,
  },
};

export const reasonCodeToReasonStringMap: Record<string, string> = {
  DUPLICATE_IN_BOND_FILED_W_INLT: 'Duplicate In-Bond Filed w/INLT',
  DUPLICATE_IN_BOND_FILED_W_DIFFERENT_BROKER:
    'Duplicate In-Bond filed w/different broker',
  IN_BOND_REPLACED_BY_7501_CONSUMPTION_ENTRY:
    'In-bond replaced by 7501 (consumption entry)',
  ACCIDENTAL_IN_BOND_CREATION: 'Accidental In-Bond creation',
  ISF_FILED_ELSEWHERE: 'ISF filed elsewhere',
  DUPLICATE_ISF_FILING: 'Duplicate ISF filing',
  NO_LONGER_NEED_ISF_FILING: 'No longer need ISF filing',
  DUPLICATE_ENTRY_FILED_W_INLT: 'Duplicate Entry Filed w/INLT',
  DUPLICATE_ENTRY_FILED_W_DIFFERENT_BROKER:
    'Duplicate Entry filed w/different broker',
  ENTRY_REPLACED_BY_7512_IN_BOND_SHIPMENT:
    'Entry replaced by 7512 (in-bond shipment)',
  ACCIDENTAL_SHIPMENT_CREATION: 'Accidental shipment creation',
  SHIPMENT_NO_LONGER_NEEDS_CLEARANCE: 'Shipment no longer needs clearance',
  SHIPMENT_DOES_NOT_QUALIFY_FOR_TYPE_OF_FILING:
    'Shipment does not qualify for type of filing',
  ACTIVATION_REQUEST_CANCELED_BY_CUSTOMER:
    'Activation request canceled by customer',
  ACTIVATION_REQUEST_EXPIRED: 'Activation request expired',
  DUPLICATE_ACTIVATION_REQUEST_SAME_FF:
    'Duplicate activation request (same FF)',
  FF_LINKED_TO_ANOTHER_ACTIVE_IOR_PROFILE:
    'FF linked to another active IOR profile',
  ACCIDENTAL_CONTINUOUS_BOND_REQUEST: 'Accidental continuous bond request',
  BOND_REQUEST_EXPIRED: 'Bond request expired',
  CONTINUOUS_BOND_NO_LONGER_NEEDED: 'Continuous bond no longer needed',
  CONTINUOUS_BOND_PURCHASED_ELSEWHERE: 'Continuous bond purchased elsewhere',
  ISSUING_DELIVERY_ORDER_ELSEWHERE: 'Issuing delivery order elsewhere',
  ACCIDENTAL_DELIVERY_ORDER_CREATION: 'Accidental delivery order creation',
  LONG_TIME_WITH_NO_CONFIRMATION_ON_BOOKING:
    'Long time with no confirmation on booking',
  SCHEDULE_PROPOSED_BUT_SELLER_DECLINES_DUE_TO_TIME_URGENCY:
    'Schedule proposed, but seller declines due to time urgency.',
  SELLER_COULD_NOT_MAKE_IT_DUE_TO_POSTPONE_OF_CARGO_READY_DAY:
    'Seller could not make it due to postpone of cargo ready day',
  SELLER_COULD_NOT_MAKE_IT_DUE_TO_UNKNOWN_REASON:
    'Seller could not make it due to unknown reason',
  GATEWAY_CONGESTION: 'Gateway congestion',
  FC_CAPACITY_ISSUE: 'FC capacity issue',
  SERVICES_CHARGE_IS_TOO_HIGH_WHICH_IS_NOT_ACCEPTABLE:
    'Services charge is too high which is not acceptable',
  DUTY_PAYMENT_BEYOND_EXPECTATION: 'Duty payment beyond expectation',
  SELLER_UNABLE_TO_MEET_BROKER_COMPLIANCE_REQUIREMENTS:
    'Seller unable to meet broker compliance requirements',
  SELLER_CANCELLED_DUE_TO_OUTSIDE_REASON:
    'Seller cancelled due to outside reason (extreme weather, Suez)',
  SSQL_RESTRICTIONS: 'SSQL restrictions',
  SELLER_DECIDED_TO_USE_ANOTHER_FREIGHT_FORWARDER:
    'Seller decided to use another Freight Forwarder',
  WRONG_DETAILS_SUBMITTED: 'Wrong details submitted',
  OTHER: 'Other',
};
