import { UserStatus } from '@xbcb/client-types';

export const primaryTheme = {
  mode: 'light',
  spacing: {
    space_1: '2px',
    space_2: '4px',
    space_3: '8px',
    space_4: '16px',
    space_5: '32px',
    space_6: '64px',
    space_7: '128px',
    space_8: '256px',
    space_9: '512px',
    space_10: '1024px',
  } as const,
  font: {
    size: {
      xxsmall: '9px',
      xsmall: '12px',
      small: '14px',
      medium: '16px',
      large: '18px',
      xlarge: '24px',
      xxlarge: '32px',
    },
  } as const,
  size: {
    micro: '64px',
    micro_tiny: '96px',
    tiny: '128px',
    tiny_short: '192px',
    short: '256px',
    short_medium: '384px',
    medium: '512px',
    medium_large: '768px',
    large: '1024px',
    extra_large: '1536px',
  } as const,
  backgroundColor: '#643D52',
  fillColor: '#ffffff',
  palette: {
    primaryColor: '#643D52',
    smile: '#FF9900',
    squidInk: '#232F3E',
    white: '#ffffff',
    neutrals: {
      sky: '#00464F',
      storm: '#6C7778',
      summit: '#D5DBDB',
      snow: '#EAEDED',
    },
    blues: {
      poseidon: '#005276',
      aqua: '#008296',
      iris: '#9CD5F3',
      mist: '#A8DFEE',
    },
    teals: {
      wave: '#008577',
      typhoon: '#049796',
      lagoon: '#36C2B4',
      seafoam: '#A6E7CE',
    },
    greens: {
      forest: '#538000',
      jungle: '#ADE422',
      honeysuckle: '#94ED88',
      dew: '#E4FDBF',
    },
    yellows: {
      incandescence: '#FF6138',
      summer: '#FFC400',
      poppy: '#FEE600',
      nectar: '#FFEBB7',
    },
    pinks: {
      aurora: '#A90067',
      coral: '#FF7676',
      primrose: '#FFCFCF',
      rose: '#ECD8DA',
    },
    purples: {
      gamma: '#8c297d',
      lumos: '#CB2FBF',
      lavender: '#E5D0E6',
      quartz: '#FCE8F3',
    },
    reds: {
      red: '#FF0000',
    },
    greys: {
      grey: '#f0f0f0',
    },
    status: {
      good: '#ADE422',
      bad: '#FF0000',
    },
  },
  status: {
    [UserStatus.ACTIVE]: '#a0d911',
    [UserStatus.IDLE]: '#FAAD14',
    [UserStatus.ACTIVE_TASK]: '#52C41A',
    [UserStatus.OFFLINE]: '#BFBFBF',
    [UserStatus.OUT_OF_OFFICE]: '#722ED1',
    [UserStatus.AWAY]: '#85A5FF',
  },
} as const;

export const darkTheme = {
  ...primaryTheme,
  mode: 'dark',
  backgroundColor: '#788b8b',
  fillColor: '#000000',
};
