import cleanDeep from 'clean-deep';
import {
  CreateUsInBondInput,
  ShipmentBadge,
  ShipmentLeg,
  UsIor,
  UsInBondBadge,
} from '@xbcb/api-gateway-client';
import log from '@xbcb/log';
import { RecordType } from '@xbcb/shared-types';
import {
  getPortOfUnladingFromPortCode,
  getPortOfLadingFromPortCode,
} from './getPort';
import { createShipmentTags } from './shared';

type TransformShipmentLegToUsInBondInputProps = {
  badges?: ShipmentBadge[];
  shipmentLeg: ShipmentLeg;
  clientIdentifier?: string;
  wogId: string;
  shipmentId: string;
  operatorId: string;
};

// NOTE: The party fields (specifically ior and consignee) are assumed to
// already be present. Make sure they are provided inside the shipmentLeg
export const transformShipmentLegToUsInBondInput = ({
  badges,
  shipmentLeg,
  clientIdentifier,
  wogId,
  shipmentId,
  operatorId,
}: TransformShipmentLegToUsInBondInputProps) => {
  const {
    masterBills,
    arrival,
    departure,
    conveyance,
    modeOfTransport,
    containers,
    ior,
    consignee,
    loadType,
  } = shipmentLeg;

  const { containerized, conveyanceName, tripNumber, grossWeight } =
    conveyance || {};
  const input = {
    tags: createShipmentTags({ shipmentId, clientIdentifier }),
    group: {
      id: wogId,
    },
    operator: {
      id: operatorId,
    },
    masterBills,
    arrival: { time: arrival?.time },
    departure: {
      time: departure?.time,
      country: departure?.country,
    },
    conveyance: {
      containerized,
      conveyanceName,
      tripNumber,
      grossWeight,
    },
    modeOfTransport,
    containerNumbers: containers?.reduce((acc: string[], { number }) => {
      if (number) acc.push(number);
      return acc;
    }, []),
    broker: {
      usCustomsBroker: {
        id: arrival.customsBroker?.customsBroker?.id,
      },
    },
    loadType,
    badges: (badges || []) as UsInBondBadge[],
  } as CreateUsInBondInput;

  const shipmentIor = ior?.ior;
  if (shipmentIor) {
    // Need `as UsIor` because TS knows iorNumber does not exist on `Ior` (the
    // interface) but we expect this to be a UsIor and the iorNumber defined
    const { id, version, name, iorNumber } = shipmentIor as UsIor;
    input.ior = {
      usIor: {
        id,
        version,
      },
      name,
      iorNumber,
    };
  }

  const shipmentPortOfLading = departure?.portOfLading;
  if (shipmentPortOfLading) {
    const portOfLading = getPortOfLadingFromPortCode(shipmentPortOfLading);

    if (portOfLading) {
      if (!input.departure) input.departure = {};
      input.departure.portOfLading = portOfLading as string;
    } else {
      log.error(
        `Entry doesn't consume portCode because shipment has portOfLading with type [${shipmentPortOfLading?.type}] and value [${shipmentPortOfLading?.value}]`,
        {
          string: RecordType.SHIPMENT,
          id: shipmentId,
          key: 'PortCodeMappingMissing',
        },
      );
    }
  }

  const shipmentPortOfUnlading = arrival?.portOfUnlading;
  if (shipmentPortOfUnlading) {
    const portOfUnlading = getPortOfUnladingFromPortCode(
      shipmentPortOfUnlading,
    );
    if (portOfUnlading) {
      if (!input.arrival) input.arrival = {};
      input.arrival.portOfUnlading = portOfUnlading as string;
    } else {
      log.error(
        `UsInBond doesn't consume portCode because shipment has portOfUnLading with type [${shipmentPortOfUnlading?.type}] and value [${shipmentPortOfUnlading?.value}]`,
        {
          string: RecordType.SHIPMENT,
          id: shipmentId,
          key: 'PortCodeMappingMissing',
        },
      );
    }
  }

  const shipmentConsignee = consignee?.consignee;
  if (shipmentConsignee) {
    const { id, version, name, addresses } = shipmentConsignee;
    input.consignee = {
      usConsignee: {
        id,
        version,
      },
      name,
      addresses,
    };
  }

  const sanitizedInput = cleanDeep(input, {
    // Only remove emptyObjects and undefinedValues (they are true by default)
    emptyArrays: false,
    emptyStrings: false,
    nullValues: false,
  }) as CreateUsInBondInput;
  return sanitizedInput;
};
