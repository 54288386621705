import { ModeOfTransport } from '@xbcb/shared-types';
import { UsCbpEntrySummaryStatus } from '@xbcb/work-order-types';
import { sharedUsEntrySummaryColumns } from './sharedUsEntrySummary';
import { createShipmentWorkOrderRecord } from './utils';
import { EsDataType } from '../../enums';

export const usPostSummaryCorrection = createShipmentWorkOrderRecord({
  columns: {
    ...sharedUsEntrySummaryColumns,
    modeOfTransport: {
      name: 'Mode of Transportation',
      type: EsDataType.KEYWORD,
      react: true,
      optionValues: Object.keys(ModeOfTransport),
    },
    portOfLadingName: {
      default: true,
      name: 'Origin',
      type: EsDataType.KEYWORD,
    },
    summaryStatus: {
      name: 'Summary Status',
      type: EsDataType.KEYWORD,
      default: true,
      optionValues: Object.keys(UsCbpEntrySummaryStatus),
    },
    entryNumber: {
      name: 'Entry #',
      type: EsDataType.TEXT,
      analyzer: 'whitespace',
    },
    iorName: { name: 'IOR Name', type: EsDataType.TEXT, default: false },
  },
});
