// subset of FDAComplianceCodes.json (Affirmation of Compliance codes)
export enum UsFdaRadiationDeclarationComplianceCodeType {
  // Date of Manufacture
  RA1 = 'RA1',
  // Reason for Exclusion
  RA2 = 'RA2',
  // EPRC Product Declaration A3 (FDA 2877)
  RA3 = 'RA3',
  // EPRC Product Declaration A4 (FDA 2877)
  RA4 = 'RA4',
  // EPRC Product Declaration A5 (FDA 2877)
  RA5 = 'RA5',
  // EPRC Product Declaration A6 (FDA 2877)
  RA6 = 'RA6',
  // EPRC Product Declaration A7 (FDA 2877)
  RA7 = 'RA7',
  // EPRC Product Declaration B1 (FDA 2877)
  RB1 = 'RB1',
  // Reason
  RB2 = 'RB2',
  // EPRC Product Declaration C1 (FDA 2877)
  RC1 = 'RC1',
  // Dates & Use Restrictions
  RC2 = 'RC2',
  // EPRC Product Declaration D1 (FDA 2877)
  RD1 = 'RD1',
  // EPRC Product Declaration D2 (FDA 2877)
  RD2 = 'RD2',
  // EPRC Product Declaration D3 (FDA 2877)
  RD3 = 'RD3',
}

export enum DotBondQualifier {
  SINGLE = 'SINGLE',
  CONTINUOUS = 'CONTINUOUS',
}

export enum UsFdaRadiationEmittingDeviceForm {
  COMPONENT = 'COMPONENT',
  FINISHED = 'FINISHED',
}

export enum VehicleDriveSide {
  /** The vehicle’s drive side is on the right side. */
  RIGHT = 'RIGHT',
  /** The vehicle’s drive side is on the left side. */
  LEFT = 'LEFT',
  /** The vehicle does not have a drive side (e.g., trailer, motorcycle, etc.). */
  NONE = 'NONE',
}

export enum UsFdaDrugForm {
  FINISHED = 'FINISHED',
  API = 'API',
}

export enum FoodFacilityRegistrationExemption {
  /** Facility is out of business. */
  A = 'A',
  /** Facility is a private residence (21 CFR 1.227(b)(2)). */
  B = 'B',
  /** Facility is a restaurant (21 CFR 1.226(d); 1.227(b)(10)). */
  C = 'C',
  /** Facility is a retail food establishment (21 CFR 1.226(c); 1.227(b)(11)). */
  D = 'D',
  /** Facility is a non-processing fishing vessel (21 CFR 1.226(f). */
  E = 'E',
  /** Facility is a non-bottled drinking water collection and distribution establishment (21 CFR 1.227(b)(2)). */
  F = 'F',
  /** Unable to determine the registration number of the manufacturer. */
  K = 'K',
}

export enum UsPgaInspectionStatus {
  /** Request for inspection. */
  R = 'R',
  /** Inspection previously scheduled. */
  S = 'S',
  /** Inspection previously performed. */
  P = 'P',
  /** Lab testing previously performed. */
  L = 'L',
  /** BTA anticipated arrival information. */
  A = 'A',
  /** Product location for regulatory authority inspection. */
  I = 'I',
  /** Indicates product coming from Foreign Trade Zone (For FDA Type 21). */
  F = 'F',
}

export enum UsFdaLaboratoryType {
  /** Independent Third Party Laboratory. */
  ITL = 'ITL',
  /** Laboratory or Clinical Site. */
  LAB = 'LAB',
}

export enum VneBondExemptionStatusCode {
  /** The engine is exempt from Bond. */
  E1Y = 'E1Y',
  /** The engine is not exempt from Bond. */
  E1N = 'E1N',
}

export enum VneIndustryCode {
  /** Heavy-duty highway engines (for use in motor vehicles with gross vehicle weight rating above 8500LBS. */
  A = 'A',
  /** Locomotives or locomotive engines. */
  B = 'B',
  /** Marine compression-ignition engines. */
  C = 'C',
  /** Other non-road compression-ignition engines. */
  D = 'D',
  /** Marine spark-ignition engines. */
  E = 'E',
  /** Recreational engines and vehicles, including snowmobiles, off-highway motorcycles, all-terrain vehicles, and off-road utility vehicles. */
  F = 'F',
  /** Other non-road spark-ignition engines at or below 19kW or below 30kW if total displacement is at or below 1000cc. */
  G = 'G',
  /** Other non-road spark-ignition engines above 19kW. */
  H = 'H',
  /** Stationary compression-ignition engines. */
  I = 'I',
  /** Stationary Spark-ignition engines. */
  J = 'J',
}

export enum LpcoExemptionCode {
  /** Not engaged in the business of importing (for example, one-time personal use importation). */
  TTBEX1 = 'TTBEX1',
  /** Beer not made with both malted barley and hops or malt beverage that is not the. */
  /** product of alcoholic fermentation. */
  TTBEX2 = 'TTBEX2',
  /** Wine containing less than 7% alcohol by volume. */
  TTBEX3 = 'TTBEX3',
  /** Wine for industrial use. */
  TTBEX4 = 'TTBEX4',
  /** Distilled spirits for industrial use. */
  TTBEX5 = 'TTBEX5',
  /** Tobacco does not meet the definition of “processed tobacco” under the IRC. */
  TTBEX6 = 'TTBEX6',
  /** COLA waiver granted. */
  TTBEX7 = 'TTBEX7',
  /** Malt beverages withdrawn for consumption in a state that does not require. */
  /** labeling in conformity with the FAA Act. */
  TTBEX8 = 'TTBEX8',
  /** Bulk distilled spirits (in containers of over one gallon). */
  TTBEX9 = 'TTBEX9',
  /** Bulk wine (not for sale at retail). */
  TTBEX10 = 'TTBEX10',
  /** Bulk malt beverages (not for sale at retail). */
  TTBEX11 = 'TTBEX11',
  /** Not for sale or any other commercial purpose. */
  TTBEX12 = 'TTBEX12',
  /** This is a tobacco substitute and not a “tobacco product” under the IRC. */
  TTBEX13 = 'TTBEX13',
  /** Completely Denatured Alcohol produced in the Virgin Islands. */
  TTBEX14 = 'TTBEX14',
  /** Importer is a State or other political subdivision agency. */
  TTBEX15 = 'TTBEX15',
}

export enum UsPgaDisclaimerCode {
  /** Product is not regulated by this agency.. */
  A = 'A',
  /** Data is not required per agency guidance.. */
  B = 'B',
  /** Data filed through other agency means.. */
  C = 'C',
  /** Data filed through paper.. */
  D = 'D',
  /** Product does not contain fish or wildlife, including live, dead, parts or. */
  /** products thereof, except as specifically exempted from declaration requirements. */
  /** under 50 CFR Part 14.. */
  E = 'E',
}

export enum UsPgaFlag {
  /** Ozone Depleting Substances specific data may be required. */
  EP1 = 'EP1',
  /** Ozone Depleting Substances specific data is required. */
  EP2 = 'EP2',
  /** Vehicle and Engines specific data may be required. */
  EP3 = 'EP3',
  /** Vehicle and Engines specific data is required. */
  EP4 = 'EP4',
  /** Pesticides specific data may be required. */
  EP5 = 'EP5',
  /** Pesticides specific data is required. */
  EP6 = 'EP6',
  /** Toxic Substances Control Act specific data may be required. */
  EP7 = 'EP7',
  /** Toxic Substances Control Act specific data is required. */
  EP8 = 'EP8',
  /** FSIS data may be required. Applicable to all FSIS programs. */
  FS3 = 'FS3',
  /** FSIS data is required. Applicable to all FSIS programs. */
  FS4 = 'FS4',
  /** 370 specific data may be required. */
  NM1 = 'NM1',
  /** 370 specific data is required. */
  NM2 = 'NM2',
  /** Antarctic Marine Living Resources specific data may be required. */
  NM3 = 'NM3',
  /** Antarctic Marine Living Resources specific data is required. */
  NM4 = 'NM4',
  /** Highly Migratory Species specific data may be required. */
  NM5 = 'NM5',
  /** Highly Migratory Species specific data is required. */
  NM6 = 'NM6',
  /** Seafood Import Monitoring Program specific data is required. */
  NM8 = 'NM8',
  /** DOT/National Highway Traffic Safety Administration HS-7 data may be required. */
  DT1 = 'DT1',
  /** DOT/National Highway Traffic Safety Administration HS-7 data is required. */
  DT2 = 'DT2',
  /** Lacey Act specific data may be required. */
  AL1 = 'AL1',
  /** Lacey Act specific data is required. */
  AL2 = 'AL2',
  /** FDA data may be required 801(a). */
  FD1 = 'FD1',
  /** FDA data Required 801(a). */
  FD2 = 'FD2',
  /** FDA Prior Notice Data may be required 801(m). */
  FD3 = 'FD3',
  /** FDA Prior Notice Data is required 801(m). */
  FD4 = 'FD4',
  /** USDA/Agricultural Marketing Service Data related to egg products may be required. */
  AM1 = 'AM1',
  /** USDA/Agricultural Marketing Service Data Related to shell eggs is required. */
  AM2 = 'AM2',
  /** USDA/Agricultural Marketing Service Data Related to marketing orders may be. */
  /** required. */
  AM3 = 'AM3',
  /** USDA/Agricultural Marketing Service Data Related to marketing orders is required. */
  AM4 = 'AM4',
  /** USDA/Agriculture Marketing Service Data related to peanuts is required. */
  AM6 = 'AM6',
  /** USDA/Agriculture Marketing Service Data related to organics may be required. */
  AM7 = 'AM7',
  /** USDA/Agriculture Marketing Service Data related to organics is required. */
  AM8 = 'AM8',
  /** TTB data may be required. Applicable to all TTB programs.. */
  TB1 = 'TB1',
  /** TTB data is required. Applicable to all TTB programs.. */
  TB2 = 'TB2',
  /** TTB data may be required. */
  TB3 = 'TB3',
  /** APHIS data may be required. */
  AQ1 = 'AQ1',
  /** APHIS data is required. */
  AQ2 = 'AQ2',
  /** U.S. Department of State, Bureau of Oceans and International Environmental and. */
  /** Scientific Affairs, Office of Marine Conservation data may be required. */
  OM1 = 'OM1',
  /** U.S. Department of State, Bureau of Oceans and International Environmental and. */
  /** Scientific Affairs, Office of Marine Conservation data is required. */
  OM2 = 'OM2',
  /** U.S. Fish and Wildlife Service data may be required. */
  FW1 = 'FW1',
  /** U.S. Fish and Wildlife Service data is required. */
  FW2 = 'FW2',
  /** U.S. Fish and Wildlife Service data may be required. */
  FW3 = 'FW3',
  /** U.S. Drug Enforcement Administration data may be required. */
  DE1 = 'DE1',
}

/* Partner Government Agency codes */
export enum UsPgaCode {
  ACE = 'ACE',
  AMS = 'AMS',
  APH = 'APH',
  ATF = 'ATF',
  BIS = 'BIS',
  BLS = 'BLS',
  BTS = 'BTS',
  CBC = 'CBC',
  CBP = 'CBP',
  CDC = 'CDC',
  CGD = 'CGD',
  CPS = 'CPS',
  DCM = 'DCM',
  DEA = 'DEA',
  DEE = 'DEE',
  DTC = 'DTC',
  ECO = 'ECO',
  EIA = 'EIA',
  EPA = 'EPA',
  ETA = 'ETA',
  EXI = 'EXI',
  FAA = 'FAA',
  FAS = 'FAS',
  FCC = 'FCC',
  FCN = 'FCN',
  FDA = 'FDA',
  FHA = 'FHA',
  FMC = 'FMC',
  FMS = 'FMS',
  FSI = 'FSI',
  FTZ = 'FTZ',
  FWS = 'FWS',
  GIP = 'GIP',
  IDV = 'IDV',
  ICE = 'ICE',
  IRS = 'IRS',
  MAR = 'MAR',
  NHT = 'NHT',
  NMF = 'NMF',
  NRC = 'NRC',
  OFA = 'OFA',
  OFE = 'OFE',
  OFM = 'OFM',
  OGC = 'OGC',
  OLM = 'OLM',
  OMC = 'OMC',
  OTX = 'OTX',
  PHM = 'PHM',
  TRP = 'TRP',
  TSA = 'TSA',
  TTB = 'TTB',
  UTC = 'UTC',
}

export enum UsPgaHeaderStatus {
  PENDING = 'PENDING',
  DATA_UNDER_PGA_REVIEW = 'DATA_UNDER_PGA_REVIEW',
  HOLD_INTACT = 'HOLD_INTACT',
  DATA_REJECTED_PER_PGA_REVIEW = 'DATA_REJECTED_PER_PGA_REVIEW',
  MAY_PROCEED = 'MAY_PROCEED',
}

export enum VneRegulatoryCategory {
  LIGHT_DUTY_ON_ROAD = 'LIGHT_DUTY_ON_ROAD',
  HEAVY_DUTY_HIGHWAY_AND_NON_ROAD = 'HEAVY_DUTY_HIGHWAY_AND_NON_ROAD',
}
