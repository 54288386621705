import { EsDataType } from '../../index';
import { createTableConfig } from '../createTableConfig';

export const commodityGroup = createTableConfig({
  columns: {
    htsChapters: {
      default: true,
      name: 'Hts Chapters',
      type: EsDataType.TEXT,
    },
    pgas: {
      default: true,
      name: 'PGAs',
      type: EsDataType.TEXT,
    },
    name: {
      default: true,
      name: 'Commodity Group Name',
      type: EsDataType.TEXT,
    },
  },
  skipOperatorId: true,
});
