import { inspect } from 'util';
import log from '@xbcb/log';
import { S3Uploader, S3Reference } from './s3Uploader';

/** A function that generates uploadLink, referenceKey pairs for an established s3 bucket */
export type LinkGenerationFunction = () => Promise<{
  uploadLink: string;
  referenceKey: string;
}>;

export type UploadFunction = (uploadLink: string, data: any) => unknown;

/**
 * An S3Uploader that uploads records to s3 using a given s3LinkGenerationFunction
 *  and uploadFunction
 */
export class LinkBasedS3Uploader implements S3Uploader {
  private s3LinkGenerationFunction: LinkGenerationFunction;
  private uploadFunction: UploadFunction;

  constructor(
    s3LinkGenerationFunction: LinkGenerationFunction,
    uploadFunction: UploadFunction,
  ) {
    this.s3LinkGenerationFunction = s3LinkGenerationFunction;
    this.uploadFunction = uploadFunction;
  }

  public async upload(data: unknown): Promise<S3Reference> {
    const { uploadLink, referenceKey } = await this.s3LinkGenerationFunction();
    const startTime = performance.now();
    const response = await this.uploadFunction(uploadLink, data);
    const endTime = performance.now();

    log.info(`Uploaded payload to s3 with response: ${inspect(response)}`, {
      key: 'S3UploaderResult',
      string: `Time to Upload: ${endTime - startTime}`,
    });

    return { referenceKey };
  }
}
