import React, { useState } from 'react';
import EditOutlined from '@ant-design/icons/EditOutlined';
import { SignForm } from '@xbcb/modal-components';
import { StyledSignFormWrapper } from './styles';

// TODO convert to TS, remove classNames, modernize
// https://app.asana.com/0/1200172905173188/1200208278000545
const SignBox = (props) => {
  const { form, submitSignature, submitFailed } = props;
  const [isSigning, setSigning] = useState(false);
  const handleSignBoxClick = () => {
    setSigning(true);
  };
  const handleSignFormClose = () => {
    setSigning(false);
  };
  const handleSign = (signature, method, typed) => {
    submitSignature(signature, method, typed);
    handleSignFormClose();
  };
  const d = new Date();
  const date = `${d.getMonth() + 1}/${d.getDate()}/${d.getYear() - 100}`;
  const signature = form.getFieldValue('signature');
  const signerTitle = form.getFieldValue('signerTitle');
  const signerName = form.getFieldValue('signerName');
  return (
    <StyledSignFormWrapper>
      <SignForm
        visible={isSigning}
        close={handleSignFormClose}
        sign={handleSign}
      />
      <div
        className={
          submitFailed && !signature
            ? 'sign-box error'
            : signature
            ? 'sign-box-signature'
            : 'sign-box'
        }
        onClick={handleSignBoxClick}
      >
        {signature ? (
          <img src={signature} alt="" className="sign-box-image" />
        ) : (
          <div>
            <EditOutlined className="sign-icon" />
            <p>Click here to sign</p>
          </div>
        )}
      </div>

      <div className="sign-form-signature-info" style={{ fontSize: '14px' }}>
        <p className="sign-form-date" style={{ float: 'right' }}>
          {date}
        </p>

        <p className="sign-form-name">{signerName || 'Officer Name'}</p>
        <p className="sign-form-title">{signerTitle || 'Officer Title'}</p>
        <p className="sign-form-details" style={{ float: 'right' }} />

        <p className="sign-form-user-agent" />
      </div>
    </StyledSignFormWrapper>
  );
};

export default SignBox;
