import { EsDataType } from '../../index';
import { createTableConfig } from '../createTableConfig';

export const usPrincipalAdCvdCase = createTableConfig({
  columns: {
    principalCaseNumber: {
      default: true,
      name: 'Principal Case Number',
      type: EsDataType.TEXT,
    },
  },
  skipOperatorId: true,
});
