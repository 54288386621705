import log from '@xbcb/log';
import { AdCvdCase, SearchAdCvdCaseInput } from '@xbcb/api-gateway-client';
import { chunk } from 'lodash';
import { KeywordSearchOperator } from '@xbcb/shared-types';
import { AdCvdQueryFunction } from '../../types';

export type AdCvdCaseMap = { [key: string]: AdCvdCase };

const DEFAULT_BATCH_SIZE = 150;
const NUM_RETRIES = 3;
const INTERVAL = 3000;

export interface QueryAdCvdCasesProps {
  caseIds: string[];
  queryFn: AdCvdQueryFunction;
  interval?: number;
  numRetries?: number;
  batchSize?: number;
}

export const queryAdCvdCases = async ({
  queryFn,
  caseIds,
  batchSize,
  interval = INTERVAL,
  numRetries = NUM_RETRIES,
}: QueryAdCvdCasesProps): Promise<AdCvdCase[]> => {
  const searchCaseInput: SearchAdCvdCaseInput = {
    paginationOptions: {
      pageSize: batchSize,
    },
    searchCriteria: {
      id: {
        operator: KeywordSearchOperator.ONE_OF,
        values: caseIds,
      },
    },
  };
  const adCvdCasesPayload = await queryFn(
    searchCaseInput,
    numRetries,
    interval,
  );

  return adCvdCasesPayload.results;
};

/*
 * This class helps retrieve AdCvdCase data
 *
 * Fetches the ad/cv Case data from TDA using ids
 *
 * Subsequent requests for same id will be returned from cache.
 */
export class AdCvdCaseDataRetriever {
  readonly data: AdCvdCaseMap = {};
  readonly batchSize?: number;
  readonly customFields?: string;
  readonly queryFn: AdCvdQueryFunction;

  constructor(
    queryFn: AdCvdQueryFunction,
    batchSize = DEFAULT_BATCH_SIZE,
    customFields?: string,
  ) {
    this.batchSize = batchSize;
    this.customFields = customFields;
    this.queryFn = queryFn;
  }

  getAdCvdCaseData = async (caseId: string): Promise<AdCvdCase> => {
    if (caseId in this.data) {
      return this.data[caseId];
    } else {
      await this.fetchAdCvdCasesData([caseId]);
      return this.data[caseId];
    }
  };

  getAdCvdCasesToQuery = (caseIds: string[]): string[] => {
    const uniqueIds = Array.from(new Set(caseIds));
    const idsToQuery = uniqueIds.filter((caseId) => !this.data[caseId]);
    return idsToQuery;
  };

  // Method to fetch ad/cvd cases information and cache the results so it can be later retrieved using getAdCvdCaseData.
  public fetchAdCvdCasesData = async (
    caseIds: string[],
  ): Promise<AdCvdCaseMap> => {
    const uniqueIds = this.getAdCvdCasesToQuery(caseIds);
    const batches = chunk(uniqueIds, this.batchSize);
    const promises = batches.map((batch) =>
      this.batchedFetchAdCvdCasesData(batch),
    );
    const adCvdRecords = await Promise.all(promises);
    return Object.assign({}, ...adCvdRecords);
  };

  // Method to fetch ad/cvd cases information and cache the results per each batch so it can be later retrieved using getAdCvdCaseData.
  batchedFetchAdCvdCasesData = async (
    caseIds: string[],
  ): Promise<AdCvdCaseMap> => {
    log.info(`Querying adCvd cases.`, {
      key: 'queryAdCvdCases',
      params: {
        caseIds,
      },
    });
    const caseIdsToQuery = caseIds.filter((caseId) => !this.data[caseId]);
    const fetchedCases = await queryAdCvdCases({
      caseIds: caseIdsToQuery,
      batchSize: this.batchSize,
      queryFn: this.queryFn,
    });
    fetchedCases?.forEach((adCvdCase: AdCvdCase) => {
      this.data[adCvdCase.id] = adCvdCase;
    });
    return caseIds.reduce((map: AdCvdCaseMap, caseId) => {
      map[caseId] = this.data[caseId];
      return map;
    }, {});
  };
}
