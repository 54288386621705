import { cloneDeep, omit } from 'lodash';
import {
  RecordSaver,
  RecordSizeThresholdConfig,
  SavedRecordFieldsReference,
  isObjectTooLarge,
} from '@xbcb/record-dao';
import { AnyObject } from '@xbcb/shared-types';

/**
 * A utlity class for a client that wants to call a RecordSaver's save method conditionally.
 * @template {AnyObject} TSavedFields the type representing the fields this LargePayloadClient's RecordSaver saves off of the TRecordType
 * @template {TSavedFields} TRecordType the record type for which this LargePayloadClient's RecordSaver is configured
 */
export class LargePayloadClient<
  TSavedFields extends AnyObject,
  TRecordType extends TSavedFields,
> {
  private saver: RecordSaver<TSavedFields, TRecordType>;
  private readonly enabled: boolean;
  private readonly recordSizeThresholdConfigs: RecordSizeThresholdConfig[];
  private useReferenceKeyFunction: (
    referenceKey: SavedRecordFieldsReference,
    input: TRecordType,
  ) => TRecordType;

  /**
   *
   * @param {RecordSaver<TSavedFields, TRecordType>} saver the record saver this Client will use to proces Large Payloads
   * @param {function(SavedRecordFieldsReference, TRecordType):TRecordType} useReferenceKeyFunction The function used to load a referenceKey into an input object
   * @param {RecordSizeThresholdConfig[]} recordSizeThresholdConfigs
   * @param {boolean} enabled optional, defaulted to true; A prod check.  When false, processForOversizedInput will do nothing
   */
  constructor(
    saver: RecordSaver<TSavedFields, TRecordType>,
    useReferenceKeyFunction: (
      referenceKey: SavedRecordFieldsReference,
      input: TRecordType,
    ) => TRecordType,
    recordSizeThresholdConfigs: RecordSizeThresholdConfig[] = [],
    enabled = true,
  ) {
    this.saver = saver;
    this.useReferenceKeyFunction = useReferenceKeyFunction;
    this.recordSizeThresholdConfigs = recordSizeThresholdConfigs;
    this.enabled = enabled;
  }

  /**
   * If an input is too large, saves the input's large fields using the RecordSaver and
   *  saves the returned referenceKey into the input using useReferenceKeyFunction
   * @param {TRecordType} input the input to modify (if it is too large)
   * @return {Promise<TRecordType>} if not too large, the original input; else a copy of the original input with a reference key added and any large fields removed
   * @throws if the input has no large fields, but is still too large to go over the wire.  This is an unexpected usecase.
   */
  public processForOversizedInput = async (
    input: TRecordType,
  ): Promise<TRecordType> => {
    if (!this.enabled) return input;

    const fieldsToSave = this.saver.fieldsToSave;
    let saveResult: undefined | SavedRecordFieldsReference = undefined;

    if (isObjectTooLarge(input, this.recordSizeThresholdConfigs)) {
      if (!fieldsToSave.some((key) => input[key])) {
        throw new Error(
          `Input is too large to go over the wire, but it has none of the expected large fields: [${fieldsToSave}].`,
        );
      }

      saveResult = await this.saver.save(input);
    }

    if (saveResult) {
      // return an object without any of the fields we've saved elsewhere
      const newInput = cloneDeep(omit(input, fieldsToSave)) as TRecordType;
      return this.useReferenceKeyFunction(saveResult, newInput);
    }

    return input;
  };
}
