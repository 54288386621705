import { UsCbpPortCode, UsCbpForeignPortCode } from '@xbcb/api-gateway-client';

// https://www.unece.org/cefact/locode/service/location
// https://code.amazon.com/packages/XbcbShared/blobs/mainline/--/packages/codes/codes/CBPPortCodes.json
export const usCbpPortCodeMapping: {
  [key: string]: { [key: string]: UsCbpPortCode };
} = {
  UNLOCODE: {
    USBAL: '1303',
    USCHI: '3901',
    USDAL: '5501',
    USEWR: '4601',
    USHOU: '5301',
    USLAX: '2704',
    USLGB: '2704',
    USMKC: '4501',
    USNYC: '1001',
    USOAK: '2811',
    USORF: '1401',
    USSAV: '1703',
    USSEA: '3001',
    USSTL: '4503',
    USTAC: '3002',
  },
  IATA: {
    DFW: '5501',
    IAH: '5309',
    JFK: '4701',
    LAX: '2720',
    ONT: '2721',
    ORD: '3901',
    SFO: '2801',
  },
};

// https://code.amazon.com/packages/XbcbShared/blobs/mainline/--/packages/codes/codes/NewForeignPortCodes.json
export const usCbpForeignPortCodeMapping: {
  [key: string]: { [key: string]: UsCbpForeignPortCode };
} = {
  UNLOCODE: {
    CNBHY: '57076',
    CNCWN: '57067',
    CNFOC: '57015',
    CNHUA: '57072',
    CNLYG: '57025',
    CNNGB: '57020',
    CNNSA: '57073',
    CNSHA: '57035',
    CNSHK: '57018',
    CNSWA: '57070',
    CNSZX: '57000',
    CNTAO: '57047',
    CNTSN: '57043',
    CNXMN: '57069',
    CNYTN: '57078',
    CNZJG: '57075',
    CNZSN: '57079',
    CNZUH: '57077',
    DEHAM: '42879',
    ESBCN: '47061',
    FRLEH: '42737',
    GBFXT: '41323',
    GBSOU: '41398',
    HKHKG: '58201',
    ITGOA: '47527',
    ITMIL: '47500',
    JPOSA: '58866',
    JPTYO: '58886',
    NLRTM: '42157',
    VNHPH: '55201',
    VNHON: '55202',
    VNDAD: '55204',
    VNCPH: '55205',
    VNVUT: '55206',
    VNNHA: '55208',
    VNSGN: '55224',
  },
  IATA: {
    CAN: '57071',
    HKG: '58201',
    KIX: '58866',
    LHR: '41352',
    NGB: '57020',
    NRT: '58886',
    PVG: '57035',
    SZX: '57000',
    TAO: '57047',
    XMN: '57069',
  },
};
