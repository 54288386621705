import React from 'react';
import { Input, Modal } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { SingleFormCard } from '@xbcb/display-components';
import {
  PhoneNumber,
  OfficerTitle,
  Email,
  FormItem,
  PickDate,
} from '@xbcb/form-item-components';
import { timeout, cleanString } from '@xbcb/js-utils';
import { PoaType } from '@xbcb/shared-types';
import { CssSize, NamePath } from '@xbcb/ui-types';
import { StyledTitleDiv, StyledParagraph } from './styles';
import { iscbpReg2ChangesFeatureEnabled } from '../featureFlags';
import moment from 'moment';
import { safeGetMessage } from '@xbcb/ui-utils';
import { useBundle } from '@amzn/react-arb-tools';

interface UsIorOfficerInfoProps {
  form: FormInstance;
  disabled?: boolean;
  readOnly?: boolean;
  type: PoaType;
  isFetching?: boolean;
  nameLocalNamePath: NamePath;
  nameFullNamePath: NamePath;
  titleLocalNamePath: NamePath;
  titleFullNamePath: NamePath;
  birthDateLocalNamePath: NamePath;
  birthDateFullNamePath: NamePath;
  phoneLocalNamePath: NamePath;
  phoneFullNamePath: NamePath;
  nrccEmailLocalNamePath: NamePath;
  countryCodeFullNamePath: NamePath;
}

const UsIorOfficerInfo = ({
  form,
  disabled,
  readOnly,
  type,
  nameLocalNamePath,
  nameFullNamePath,
  titleLocalNamePath,
  titleFullNamePath,
  birthDateFullNamePath,
  birthDateLocalNamePath,
  phoneLocalNamePath,
  phoneFullNamePath,
  nrccEmailLocalNamePath,
  countryCodeFullNamePath,
}: UsIorOfficerInfoProps) => {
  const [bundle] = useBundle('components.formComponents.UsIorOfficerInfo');
  const { getFieldValue } = form;
  const countryCode = getFieldValue(countryCodeFullNamePath);
  const isDirectOrMasterPowerOfAttorney = [
    PoaType.DIRECT_POA,
    PoaType.MASTER_POA,
  ].includes(type);
  const showCC =
    countryCode && countryCode !== 'US' && isDirectOrMasterPowerOfAttorney;
  // If showCC is true, we want to validate the fields, but wait 1000ms to
  // give time for the nrcc email form item to actually be displayed,
  // otherwise if it's not being displayed calling validateFields too early
  // results in it never getting highlighted red
  if (showCC) setTimeout(form.validateFields, 1000);

  const getIsNameValid = (name?: string) =>
    name && name.trim().split(' ').length > 1;

  const handleNameBlur = async () => {
    await timeout(1000);
    const raw = getFieldValue(nameFullNamePath);
    const officerName = cleanString(raw);
    if (raw !== officerName) {
      form.setFields([{ name: nameFullNamePath, value: officerName }]);
    }
    if (officerName && !getIsNameValid(officerName)) {
      Modal.warning({
        title: safeGetMessage(bundle, 'invalid_officer_name'),
        content: safeGetMessage(bundle, 'please_enter_full_name'),
      });
    }
  };

  // TODO test this, it should likely be changed to something else that doesn't throw an error
  const validateName = async (rule: any, value: string) => {
    if (!getIsNameValid(value))
      throw new Error(safeGetMessage(bundle, 'invalid_officer_full_name'));
  };

  return (
    <SingleFormCard
      title={safeGetMessage(bundle, 'your_info')}
      extraContent={
        <StyledTitleDiv>
          {safeGetMessage(bundle, 'you_must_be_an_officer')}
        </StyledTitleDiv>
      }
    >
      <FormItem
        label={safeGetMessage(bundle, 'officer_full_name')}
        $readOnly={readOnly}
        name={nameLocalNamePath}
        rules={[
          {
            validator: validateName,
            required: true,
            message: '',
          },
        ]}
        $inline
        debounce
        $itemSize={CssSize.SHORT}
      >
        <Input disabled={disabled} onBlur={handleNameBlur} />
      </FormItem>
      <OfficerTitle
        fullNamePath={titleFullNamePath}
        localNamePath={titleLocalNamePath}
        required
        readOnly={readOnly}
        disabled={readOnly}
        documentType={type}
        form={form}
      />
      {iscbpReg2ChangesFeatureEnabled && isDirectOrMasterPowerOfAttorney && (
        <PickDate
          label={safeGetMessage(bundle, 'officer_birth_date')}
          fullNamePath={birthDateFullNamePath}
          localNamePath={birthDateLocalNamePath}
          readOnly={readOnly}
          disabled={disabled}
          disabledDate={(current) => current && current > moment()}
          required
          form={form}
        />
      )}
      {isDirectOrMasterPowerOfAttorney && (
        <PhoneNumber
          readOnly={readOnly}
          disabled={disabled}
          form={form}
          label={safeGetMessage(bundle, 'officer_phone_number')}
          defaultCountryCode="1"
          localNamePath={phoneLocalNamePath}
          fullNamePath={phoneFullNamePath}
        />
      )}
      {showCC && (
        <div>
          <StyledParagraph>
            {safeGetMessage(bundle, 'cbp_requires')}
          </StyledParagraph>
          <Email
            localNamePath={nrccEmailLocalNamePath}
            readOnly={readOnly}
            disabled={disabled}
            // Users don't understand "NRCC", should use "Secondary"
            label={safeGetMessage(bundle, 'secondary_officer_email')}
            required
          />
        </div>
      )}
    </SingleFormCard>
  );
};

export default UsIorOfficerInfo;
