import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

const escalationMessageSearchFeatureName = 'ESCALATION_MESSAGE_SEARCH_FEATURE';

const ESCALATION_MESSAGE_SEARCH_FEATURE = new FeatureFlagService([
  {
    feature: escalationMessageSearchFeatureName,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA, Stage.PROD],
  },
]);

export {
  ESCALATION_MESSAGE_SEARCH_FEATURE,
  escalationMessageSearchFeatureName,
};
