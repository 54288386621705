export enum UsConsumptionEntryAmendmentReason {
  INCORRECT_DOCUMENTATION = 'INCORRECT_DOCUMENTATION',
  MISSING_COMMERCIAL_INVOICE = 'MISSING_COMMERCIAL_INVOICE',
  HTS_NOT_PROVIDED_AND_CLIENT_DISAGREED_WITH_BROKER_CLASSIFICATION = 'HTS_NOT_PROVIDED_AND_CLIENT_DISAGREED_WITH_BROKER_CLASSIFICATION',
  HTS_PROVIDED_AND_CLIENT_DISAGREED_AFTER_REVIEWING_ENTRY_SUMMARY = 'HTS_PROVIDED_AND_CLIENT_DISAGREED_AFTER_REVIEWING_ENTRY_SUMMARY',
  INCORRECT_PIECE_COUNT_ENTERED = 'INCORRECT_PIECE_COUNT_ENTERED',
  OTHER_CLERICAL_ERROR = 'OTHER_CLERICAL_ERROR',
  TAKING_ENTRY_OFF_STATEMENT = 'TAKING_ENTRY_OFF_STATEMENT',
}

export const UsConsumptionEntryAmendmentReasonDescription =
  'The reason for amending the entry.';

// A list of amendment reasons which incur an amendment fee.
export const billableAmendmentReasons = [
  UsConsumptionEntryAmendmentReason.INCORRECT_DOCUMENTATION,
  UsConsumptionEntryAmendmentReason.MISSING_COMMERCIAL_INVOICE,
  UsConsumptionEntryAmendmentReason.HTS_NOT_PROVIDED_AND_CLIENT_DISAGREED_WITH_BROKER_CLASSIFICATION,
  UsConsumptionEntryAmendmentReason.HTS_PROVIDED_AND_CLIENT_DISAGREED_AFTER_REVIEWING_ENTRY_SUMMARY,
];
