import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import DocsCore, { DocsCoreCommonProps, getRecordIdTag } from '../DocsCore';

// The whole point of ImportSignDocs is to be a wrapper around DocsCore and
// provide logic generally for all use cases in NewAppUi to determine the
// operatorId, permissions, and if any additional tags are needed. These are
// considered the "DocsCoreAdditionalProps". ImportSignDocs should take the
// DocsCoreCommonProps as props as they can be configured at each individual
// use case and need to be provided to DocsCore with the
// DocsCoreAdditionalProps that it configures in this file
type ImportSignDocsProps = DocsCoreCommonProps & {
  operatorId: string;
};

const ImportSignDocs = ({
  operatorId,
  fallbackRecordId,
  searchQueryTags = [],
  // Don't default to `[]` as if they aren't defined, we don't want to provide
  // them to `DocsCore`. This is a different case than searchQueryTags
  additionalTags,
  ...docsProps
}: ImportSignDocsProps) => {
  const { recordId } = useParams<{ recordId?: string }>();
  const { pathname } = useLocation();
  const recordIdTag = getRecordIdTag({
    pathname,
    recordId,
    fallbackRecordId,
    operatorId,
  });

  return (
    <DocsCore
      {...docsProps}
      permissions={{
        READ: true,
        CREATE: true,
        UPDATE: true,
        DELETE: true,
      }}
      operatorId={operatorId}
      // In additional to the provided searchQueryTags, we should add the recordIdTag
      searchQueryTags={[recordIdTag, ...searchQueryTags]}
      // In additional to the provided additionalTags, we should add the recordIdTag.
      // If they're not provided, don't provide `tagsForNewDocuments` since DocsCore
      // will fallback to searchQueryTags
      tagsForNewDocuments={
        additionalTags ? [recordIdTag, ...additionalTags] : undefined
      }
    />
  );
};

export default ImportSignDocs;
