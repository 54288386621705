import { partyFragments } from './party';
const frConsigneeFragment = `
fragment frConsigneeFields on FrConsignee {
  ...recordFields
  ...partyFields
}
`;

export const frConsigneeFragments = `
${frConsigneeFragment}
${partyFragments}
`;
