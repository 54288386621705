import { SubscriptionPlan } from '@xbcb/shared-types';

interface StandardPriceSteppedRates {
  fiftyThousand: number;
  sixtyToOneHundredThousand: number;
  twoHundredToFiveHundredThousand: number;
  sixHundredToOneMillion: number;
  overOneMillion: number;
}

const standardContinuousBondSteppedRates: {
  [key in SubscriptionPlan]?: StandardPriceSteppedRates;
} = {
  [SubscriptionPlan.BASIC]: {
    fiftyThousand: 0.00626,
    sixtyToOneHundredThousand: 0.00627,
    twoHundredToFiveHundredThousand: 0.0039,
    sixHundredToOneMillion: 0.00218,
    overOneMillion: 0.00107,
  },
  [SubscriptionPlan.PRO]: {
    fiftyThousand: 0.00562,
    sixtyToOneHundredThousand: 0.00561,
    twoHundredToFiveHundredThousand: 0.00348,
    sixHundredToOneMillion: 0.00194,
    overOneMillion: 0.00095,
  },
  [SubscriptionPlan.ENTERPRISE]: {
    fiftyThousand: 0.005,
    sixtyToOneHundredThousand: 0.00501,
    twoHundredToFiveHundredThousand: 0.0031,
    sixHundredToOneMillion: 0.00172,
    overOneMillion: 0.00084,
  },
};

export default standardContinuousBondSteppedRates;
