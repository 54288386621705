import { partyFragments } from './party';

const ukCustomsBrokerFragment = `
fragment ukCustomsBrokerFields on UkCustomsBroker {
  ...recordFields
  ...partyFields
  entityName
  name
  eori
}
`;

export const ukCustomsBrokerFragments = `
${ukCustomsBrokerFragment}
${partyFragments}
`;
