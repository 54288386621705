import { BillingParty } from '@xbcb/api-gateway-client';
import { AccountingSystemReferenceType } from '@xbcb/finance-types';

export const getCustomerId = (party: BillingParty): string | undefined => {
  const customerReferences =
    party.billingDetails?.accountingSystemCustomerReferences;
  return customerReferences?.find(
    (ref) => ref.type === AccountingSystemReferenceType.AR_CUSTOMER_ID,
  )?.value;
};
