export const productLevelInquiryReasonCodeDescription =
  'The reason code for the work order product level inquiry';

export enum AsinInquiryReasonCode {
  GOODS_DESCRIPTION_INACCURATE = 'GOODS_DESCRIPTION_INACCURATE',
  INCORRECT_HS_CODE = 'INCORRECT_HS_CODE',
  INCORRECT_COUNTRY_OF_ORIGIN = 'INCORRECT_COUNTRY_OF_ORIGIN',
  INCORRECT_VALUATION = 'INCORRECT_VALUATION',
  REQUIRES_FURTHER_DOCUMENTATION = 'REQUIRES_FURTHER_DOCUMENTATION',
  REQUIRES_SUPPLEMENTARY_UNITS = 'REQUIRES_SUPPLEMENTARY_UNITS',
  OTHER = 'OTHER',
}

export const reasonCodesThatNeedSuggestions: string[] = [
  AsinInquiryReasonCode.INCORRECT_HS_CODE,
  AsinInquiryReasonCode.INCORRECT_COUNTRY_OF_ORIGIN,
  AsinInquiryReasonCode.INCORRECT_VALUATION,
  AsinInquiryReasonCode.GOODS_DESCRIPTION_INACCURATE,
  AsinInquiryReasonCode.REQUIRES_SUPPLEMENTARY_UNITS,
  AsinInquiryReasonCode.OTHER,
];

export const ProductLevelInquiryReasonCode = {
  ...AsinInquiryReasonCode,
};

export type ProductLevelInquiryReasonCode = AsinInquiryReasonCode;
