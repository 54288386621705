import { EsDataType } from '../../index';
import { sharedUsEntrySummaryColumns } from './sharedUsEntrySummary';
import { AccountType, LoadType } from '@xbcb/shared-types';
import {
  UsCbpEntryReleaseStatus,
  WorkOrderBadge,
} from '@xbcb/work-order-types';
import { createShipmentWorkOrderRecord } from './utils';

export const usConsumptionEntry = createShipmentWorkOrderRecord({
  columns: {
    ...sharedUsEntrySummaryColumns,
    firmsCode: {
      name: 'FIRMS',
      type: EsDataType.KEYWORD,
    },
    examSite: {
      name: 'Exam Site',
      type: EsDataType.KEYWORD,
      // TODO add format
    },
    preferredExamSite: {
      name: 'Preferred Exam Site',
      type: EsDataType.KEYWORD,
    },
    releaseDate: {
      name: 'Release Date',
      type: EsDataType.TIME,
    },
    dailyStatementNumber: {
      name: 'Daily Statement #',
      type: EsDataType.KEYWORD,
    },
    periodicStatementNumber: {
      name: 'Periodic Statement #',
      type: EsDataType.KEYWORD,
    },
    releaseStatus: {
      name: 'Release Status',
      type: EsDataType.KEYWORD,
      optionValues: Object.keys(UsCbpEntryReleaseStatus),
    },
    containerNumber: {
      name: 'Container #',
      type: EsDataType.TEXT,
    },
    pgaDisposition: {
      name: 'PGA Disposition',
      type: EsDataType.KEYWORD,
      default: true,
    },
    collectionStatus: {
      name: 'Collection Status',
      type: EsDataType.KEYWORD,
    },
    releaseDisposition: {
      name: 'Release Disposition',
      type: EsDataType.TEXT,
    },
    releaseRejectionReason: {
      name: 'Release Rejection Reason',
      type: EsDataType.TEXT,
    },
    summaryRejectionReason: {
      name: 'Summary Rejection Reason',
      type: EsDataType.TEXT,
    },
    summaryNotificationType: {
      name: 'Summary Notification Type',
      type: EsDataType.KEYWORD,
    },
    bookingId: {
      name: 'Booking ID',
      type: EsDataType.KEYWORD,
    },
    loadType: {
      name: 'Load Type',
      type: EsDataType.KEYWORD,
      optionValues: Object.values(LoadType),
    },
    badge: {
      name: 'Badge',
      type: EsDataType.KEYWORD,
      optionValues: Object.values(WorkOrderBadge),
    },
    eta: {
      name: 'ETA',
      type: EsDataType.TIME,
    },
    forwarderName: {
      name: (accountType: AccountType) =>
        accountType === AccountType.FORWARDER ? 'Agent' : 'Forwarder',
      type: EsDataType.TEXT,
    },
    brokerName: {
      name: 'Broker Name',
      type: EsDataType.TEXT,
    },
    bondNumber: {
      name: 'Bond Number',
      type: EsDataType.TEXT,
    },
    totalInvoiceLineCount: {
      name: 'Total Line Count',
      type: EsDataType.INTEGER,
    },
    iorNumber: {
      name: 'IOR Number',
      type: EsDataType.KEYWORD,
      default: false,
      hidden: true,
    },
  },
});
