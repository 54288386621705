export const isSetHeaderTariff = (htsNumber: string) => {
  const hts = htsNumber?.replace(/\./g, '');
  const setTariffs = [
    '8205906000',
    '8206000000',
    '8211100000',
    '821510',
    '821520',
    // Below HTS codes are fix for https://t.corp.amazon.com/V499760602/communication
    '9017900105',
    '9017900115',
    '9017900120',
    '9017900132',
    '9017900136',
    '9017900140',
    '9017900150',
    '9017900160',
    // Below HTS codes are fix for https://t.corp.amazon.com/V818388165/communication
    '9005908001',
    '9005904000',
  ];
  return setTariffs.includes(hts) || setTariffs.includes(hts.substr(0, 6));
};
