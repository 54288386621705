export * from './badge';

export enum CbpInBondEntryType {
  IMMEDIATE_TRANSPORTATION = 'IMMEDIATE_TRANSPORTATION', // (61)
  TRANSPORTATION_AND_EXPORTATION = 'TRANSPORTATION_AND_EXPORTATION', // (62)
  IMMEDIATE_EXPORTATION = 'IMMEDIATE_EXPORTATION', // (63)
}

export enum CbpInBondStatusBase {
  // If CBP authorizes (aka not rejected)
  AUTHORIZED = 'AUTHORIZED',
  // If CBP rejects for any reason
  REJECTED = 'REJECTED',
  // Set temporarily by us until we get a response back from CBP (they'll give either AUTHORIZED or REJECTED and then we'll update accordingly)
  SUBMITTED = 'SUBMITTED',
  // Initial, default status
  NOT_SUBMITTED = 'NOT_SUBMITTED',
}

export const CbpInBondActionStatus = {
  ...CbpInBondStatusBase,
};

export type CbpInBondActionStatus = CbpInBondStatusBase;

// These fields are specific to types that are cancelable / deletable with CBP
// such as CbpInBond and CbpInBondBill. CbpInBondAction is not cancelable /
// deleteable so it does not have these fields available
export enum CbpInBondStatusNullificationFields {
  // Happens once the cbpInBond is "finished" aka Immediate transport (61) in-bond arrived OR transport & export (62) in-bond or immediate export (63) in-bond exported
  CLOSED = 'CLOSED',
  // If we deleted the cbpInBond with CBP
  DELETED = 'DELETED',
}

export const CbpInBondStatus = {
  ...CbpInBondStatusBase,
  ...CbpInBondStatusNullificationFields,
};

export type CbpInBondStatus =
  | CbpInBondStatusBase
  | CbpInBondStatusNullificationFields;

// Currently, the CbpInBondBill has the same values as CbpInBond, but creating
// separate enums in case this changes in the future and to make it more clear
// otherwise seeing the bill have a `CbpInBond` status seems odd / confusing
export const CbpInBondBillStatus = {
  ...CbpInBondStatusBase,
  ...CbpInBondStatusNullificationFields,
};
export type CbpInBondBillStatus =
  | CbpInBondStatusBase
  | CbpInBondStatusNullificationFields;

export enum CbpInBondActionType {
  ARRIVE_ENTIRE_IN_BOND = 'ARRIVE_ENTIRE_IN_BOND',
  ARRIVE_BILL_OF_LADING = 'ARRIVE_BILL_OF_LADING',
  ARRIVE_CONTAINER = 'ARRIVE_CONTAINER',
  EXPORT_ENTIRE_IN_BOND = 'EXPORT_ENTIRE_IN_BOND',
  EXPORT_BILL_OF_LADING = 'EXPORT_BILL_OF_LADING',
  EXPORT_CONTAINER = 'EXPORT_CONTAINER',
  TRANSFER_ENTIRE_IN_BOND_LIABILITY = 'TRANSFER_ENTIRE_IN_BOND_LIABILITY',
  REQUEST_DIVERSION = 'REQUEST_DIVERSION',
}

export enum CbpInBondAdditionalReferenceIdentifierQualifier {
  _2K = '_2K',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  CG = 'CG',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CSK = 'CSK',
  CUB = 'CUB',
  CX = 'CX',
  ED = 'ED',
  FEN = 'FEN',
  FN = 'FN',
  FP = 'FP',
  GB = 'GB',
  GR = 'GR',
  HS = 'HS',
  IN = 'IN',
  LT = 'LT',
  MA = 'MA',
  MB = 'MB',
  OM = 'OM',
  OW = 'OW',
  PK = 'PK',
  PN = 'PN',
  PO = 'PO',
  RC = 'RC',
  S7 = 'S7',
  SI = 'SI',
  SO = 'SO',
  ST = 'ST',
  SW = 'SW',
  UT = 'UT',
  VA = 'VA',
  WU = 'WU',
  WY = 'WY',
  XC = 'XC',
  XP = 'XP',
  ZE = 'ZE',
  ZZ = 'ZZ',
}

// The status of the usInBondNumber in the usInBondNumber table. NOTE: We do
// not have a status for NOT_AVAILABLE, this is intentional. We simply want to
// set the status back to undefined if it's being used be a cbpInBondNumber.
// See this article for more detail on why:
// docs.aws.amazon.com/amazondynamodb/latest/developerguide/bp-indexes-general-sparse-indexes.html
export enum UsInBondNumberStatus {
  // Can be assigned to a cbpInBondNumber (not currently being used). This is
  // the "default" status when we first add numbers to the table
  AVAILABLE = 'AVAILABLE',
}
