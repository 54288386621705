import { BillingParty } from '@xbcb/api-gateway-client';
import { getRecordType } from '@xbcb/core';
import { RecordType } from '@xbcb/shared-types';

export const calculateSingleEntryBondFee = (
  payer: BillingParty,
  coverage: number,
) => {
  const { id, billingDetails } = payer;
  const { subscriptionPlan: plan, customFeeConfig } = billingDetails || {};
  const customSingleEntryBondRate = customFeeConfig?.singleEntryBond?.rate;
  const customSingleEntryBondMinimum =
    customFeeConfig?.singleEntryBond?.minimum;
  // Shipper customer with ENTERPRISE/PRO/BASIC plan all get 4.5
  let baseRate = plan === 'LITE' ? 5.5 : 4.5;
  if (getRecordType(id) === RecordType.FORWARDER) {
    baseRate =
      plan === 'ENTERPRISE'
        ? 4.5
        : plan === 'PRO'
        ? 5.0
        : plan === 'BASIC'
        ? 5.5
        : baseRate;
  }
  const baseMinimum = baseRate * 10;
  const stbRate = customSingleEntryBondRate || baseRate;
  const stbMinimum = customSingleEntryBondMinimum || baseMinimum;
  let amount = (stbRate * coverage) / 1000;
  if (amount < stbMinimum) amount = stbMinimum;
  return { amount };
};
