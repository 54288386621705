import styled from 'styled-components';
import { Radio } from 'antd';

export const StyledRadio = styled(Radio.Group)`
  label.ant-radio-button-wrapper {
    &::before {
      display: none;
    }
    background: white;
    text-align: center;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    &.ant-radio-button-wrapper-checked {
      background: var(--primary-color);
      color: white;
      font-weight: 500;
      box-shadow: none;
      outline: none;
    }
    border-radius: 4px;
  }
  &:not(.ant-radio-group-small) {
    label.ant-radio-button-wrapper:not(:last-child) {
      margin-right: var(--space-4);
    }
  }
  &.ant-radio-group-small {
    label.ant-radio-button-wrapper:not(:last-child) {
      margin-right: var(--space-3);
    }
  }
`;
