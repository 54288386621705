import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

const REGULATORY_CONTACT_FEATURE = new FeatureFlagService([
  {
    feature: 'REGULATORY_CONTACT_FEATURE',
    enabled: true,
    stages: [Stage.ALPHA],
  },
]);

export { REGULATORY_CONTACT_FEATURE };
