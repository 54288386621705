import React from 'react';
import { StyledDiv } from './styles';

export type LabelAboveProps = {
  text?: React.ReactNode;
};

const LabelAbove = ({ text }: LabelAboveProps) =>
  text ? (
    <StyledDiv title={typeof text === 'string' ? text : undefined}>
      {text}
    </StyledDiv>
  ) : null;

export default LabelAbove;
