import gql from 'graphql-tag';
import { pluralize } from '@xbcb/js-utils';
import { dedupRecordQuery, getRecordName, RecordProps } from './shared';

interface BatchUpsertProps extends RecordProps {}

export const batchUpsertMutation = ({
  recordName,
  fields = '',
  fragments = '',
}: BatchUpsertProps) => {
  const { fieldsString, fragmentsString } = dedupRecordQuery(fields, fragments);
  const RecordName = getRecordName(recordName);
  const pluralizedRecordName = pluralize(RecordName);
  const InputType = `Upsert${RecordName}Input`;
  return gql`
    mutation batchUpsert($idempotencyKey: String!, $upserts: [${InputType}!]!) {
      batchUpsert${pluralizedRecordName}(idempotencyKey: $idempotencyKey, upserts: $upserts) {
        successfulUpserts {
          ${fieldsString}
        }
        failedUpserts {
          ${fieldsString}
        }
      }
    }
    ${fragmentsString}
  `;
};
