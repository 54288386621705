import { userTeamFragments } from './userTeam';

const subjectMatterExpertTeamFragment = `
  fragment subjectMatterExpertTeamFields on SubjectMatterExpertTeam {
    operator {
      id
    }
    customsBroker {
      id
    }
    operatorUsers {
      edges {
        node {
          id
        }
        relationship {
          expertiseCategories {
            category
            subCategories
          }
        }
      }
    }
    ...userTeamFields
    ...recordFields
  }
`;

export const subjectMatterExpertTeamFragments = `
  ${subjectMatterExpertTeamFragment}
  ${userTeamFragments}
`;
