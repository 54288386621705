import { capitalCase } from 'change-case';
import { CensusOverrideCode, CensusWarningCode } from './warning';

/**
 * Mappings for working with the Census Warning Enums
 * Source: // Source: https://www.cbp.gov/document/technical-documentation/ace-catair-appendix-h-census-codes
 */

export interface CensusWarningData {
  description: string;
  overrideCodes: CensusOverrideCode[];
}

type OverrideMap = Record<CensusOverrideCode, string>;
export const CENSUS_OVERRIDE_MAP: OverrideMap = {
  // For most messages, the enum key has the code description and capitalCasing it is sufficient
  ...Object.entries(CensusOverrideCode).reduce(
    (acc, [k, v]) => ({ ...acc, [v]: capitalCase(k) }),
    {} as OverrideMap,
  ),
  // Special cases where that doesn't work
  ...{
    [CensusOverrideCode.ONE_OF_A_KIND]: 'One-of-a-kind',
    [CensusOverrideCode.NON_PRODUCT_LINE_ITEM_NEEDED_TO_CONDUCT_BUSINESS]:
      'Non-product Line Item Needed to Conduct Business',
  },
};

export const OVERRIDE_CODE_REVERSE_LOOKUP: Record<string, string> =
  Object.entries(CensusOverrideCode).reduce(
    (accum, [k, v]) => ({ ...accum, [v]: k }),
    {},
  );

const VERIFY_CORRECT = [
  CensusOverrideCode.PARAMETER_CHANGE_REQUEST_PENDING,
  CensusOverrideCode.CORRECT_AS_ENTERED,
];

const SPECIAL_CONDITIONS = [
  CensusOverrideCode.ENTERED_UNDER_SPECIAL_CONDITIONS,
];

const IMPROBABLE_COUNTRY = [
  CensusOverrideCode.EXCEPTION_TO_EMBARGO,
  CensusOverrideCode.TIMING_OF_EMBARGO,
  CensusOverrideCode.COUNTRY_VERIFIED_AS_CORRECT,
  ...VERIFY_CORRECT,
];

const IMPROBABLE_AIR_TARIFF = [CensusOverrideCode.SAMPLE, ...VERIFY_CORRECT];

const GROSS_WEIGHT = [
  CensusOverrideCode.WEIGHT_OF_ARTICLE_HEAVIER_THAN_NORMAL,
  CensusOverrideCode.PACKAGING_HEAVIER_THAN_NORMAL,
  ...VERIFY_CORRECT,
];

const ABOVE_EXPECTED_RATIO = [
  CensusOverrideCode.PROTOTYPE,
  CensusOverrideCode.SAMPLE,
  CensusOverrideCode.ASSIST,
  CensusOverrideCode.ONE_OF_A_KIND,
  CensusOverrideCode.PRECISION_MADE,
  CensusOverrideCode.UNIQUE_MATERIAL,
  CensusOverrideCode.EXPERIMENTAL_DRUG,
  CensusOverrideCode.MILITARY_APPLICATION,
  CensusOverrideCode.MARKET_CONDITIONS,
  CensusOverrideCode.WEIGHT_OF_ARTICLE_LIGHTER_THAN_NORMAL,
  ...VERIFY_CORRECT,
];

const BELOW_EXPECTED_RATIO = [
  CensusOverrideCode.UNIQUE_MATERIAL,
  CensusOverrideCode.MASS_PRODUCED,
  CensusOverrideCode.LESS_THAN_PERFECT,
  CensusOverrideCode.LOWER_QUALITY_MATERIAL,
  CensusOverrideCode.MARKET_CONDITIONS,
  CensusOverrideCode.WEIGHT_OF_ARTICLE_HEAVIER_THAN_NORMAL,
  CensusOverrideCode.FTZ_WITHDRAWAL_LOW_FOREIGN_VALUE,
  ...VERIFY_CORRECT,
];

const QUANTITY = [
  CensusOverrideCode.UNIQUE_MATERIAL,
  CensusOverrideCode.WEIGHT_OF_ARTICLE_HEAVIER_THAN_NORMAL,
  CensusOverrideCode.WEIGHT_OF_ARTICLE_LIGHTER_THAN_NORMAL,
  ...VERIFY_CORRECT,
];

const OR_AGR = [
  CensusOverrideCode.SAMPLE,
  CensusOverrideCode.MASS_PRODUCED,
  CensusOverrideCode.LESS_THAN_PERFECT,
  CensusOverrideCode.LOWER_QUALITY_MATERIAL,
  CensusOverrideCode.MARKET_CONDITIONS,
  CensusOverrideCode.SPECIAL_HANDLING_REQUIRED,
  CensusOverrideCode.CHARTERED_TRANSPORTATION,
  CensusOverrideCode.INSURANCE_COSTS_VERY_HIGH,
  CensusOverrideCode.RUSH_DELIVERY,
  CensusOverrideCode.WEIGHT_OF_ARTICLE_HEAVIER_THAN_NORMAL,
  CensusOverrideCode.PACKAGING_HEAVIER_THAN_NORMAL,
  ...VERIFY_CORRECT,
];

export const CENSUS_WARNING_MAP: Record<CensusWarningCode, CensusWarningData> =
  {
    [CensusWarningCode.CODE_27A]: {
      description: 'IMPROBABLE COUNTRY',
      overrideCodes: IMPROBABLE_COUNTRY,
    },
    [CensusWarningCode.CODE_27P]: {
      description: 'MAXIMUM VALUE EXCEEDED',
      overrideCodes: SPECIAL_CONDITIONS,
    },
    [CensusWarningCode.CODE_27B]: {
      description: 'QTY1/QTY2',
      overrideCodes: QUANTITY,
    },
    [CensusWarningCode.CODE_27M]: {
      description: 'QTY2/QTY1',
      overrideCodes: QUANTITY,
    },
    [CensusWarningCode.CODE_27D]: {
      description: 'OR-HI VAL/QTY (1)',
      overrideCodes: ABOVE_EXPECTED_RATIO,
    },
    [CensusWarningCode.CODE_27F]: {
      description: 'OR-HI VAL/QTY (2)',
      overrideCodes: ABOVE_EXPECTED_RATIO,
    },
    [CensusWarningCode.CODE_27C]: {
      description: 'OR-LO VAL/QTY (1)',
      overrideCodes: BELOW_EXPECTED_RATIO,
    },
    [CensusWarningCode.CODE_28E]: {
      description: 'OR-LO VAL/QTY (2)',
      overrideCodes: BELOW_EXPECTED_RATIO,
    },
    [CensusWarningCode.CODE_27G]: {
      description: 'IMPROBABLE AIR TARIFF',
      overrideCodes: IMPROBABLE_AIR_TARIFF,
    },
    [CensusWarningCode.CODE_27I]: {
      description: 'GROSS WEIGHT — VESSEL',
      overrideCodes: GROSS_WEIGHT,
    },
    [CensusWarningCode.CODE_27H]: {
      description: 'GROSS WEIGHT — AIR',
      overrideCodes: GROSS_WEIGHT,
    },
    [CensusWarningCode.CODE_27Q]: {
      description: 'MAXIMUM CHARGES EXCEEDED',
      overrideCodes: SPECIAL_CONDITIONS,
    },
    [CensusWarningCode.CODE_27J]: {
      description: 'OR-AGR CHARGES/VALUE',
      overrideCodes: OR_AGR,
    },
  };
