import gql from 'graphql-tag';
import { dedupRecordQuery, getRecordName, RecordProps } from './shared';

interface CreateOneProps extends RecordProps {}

export const createOneMutation = ({
  recordName,
  fields = '',
  fragments = '',
}: CreateOneProps) => {
  const { fieldsString, fragmentsString } = dedupRecordQuery(fields, fragments);
  const RecordName = getRecordName(recordName);
  const InputType = `Create${RecordName}Input`;
  return gql`
    mutation createOne($idempotencyKey: String!, $input: ${InputType}!) {
      create${RecordName}(
        idempotencyKey: $idempotencyKey
        input: $input
      ) {
        record {
          ${fieldsString}
        }
      }
    }
    ${fragmentsString}
  `;
};
