import { Invoice } from '@xbcb/api-gateway-client';
import { ChargeCode } from '@xbcb/finance-types';

// assumes that EU/UK will use the DUTY charge code
const requestForPaymentChargeCodes: string[] = [
  ChargeCode.DUTY,
  ChargeCode.IMPORT_VALUE_ADDED_TAX,
];

export const requestForPaymentIsRequired = (invoice: Invoice): boolean =>
  Boolean(
    invoice.lines?.some((line) =>
      requestForPaymentChargeCodes.includes(line.type),
    ),
  );
