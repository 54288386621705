import gql from 'graphql-tag';
import { getRecordName, dedupRecordQuery, RecordProps } from './shared';

interface DeleteOneProps extends RecordProps {}

export const deleteOneMutation = ({
  recordName,
  fields = '',
  fragments = '',
}: DeleteOneProps) => {
  const { fieldsString, fragmentsString } = dedupRecordQuery(fields, fragments);
  const RecordName = getRecordName(recordName);
  return gql`
    mutation deleteOne($id: ID!, $version: Int!) {
      delete${RecordName}(
        id: $id
        version: $version
      ) {
        record {
          ${fieldsString}
        }
      }
    }
    ${fragmentsString}
  `;
};
