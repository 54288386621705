import { euIorActivationsFields } from './euIorActivations';
import { partyFragments } from './party';

export const nlIorFragment = `
fragment nlIorFields on NlIor {
  ...recordFields
  ...partyFields
  vatNumber
  eori
  industry
  businessStructure
  ${euIorActivationsFields}
}
`;

export const nlIorFragments = `
${nlIorFragment}
${partyFragments}
`;
