import { EsDataType } from '../../index';
import { createTableConfig } from '../createTableConfig';

export const usHts = createTableConfig({
  columns: {
    number: {
      default: true,
      name: 'Hts',
      type: EsDataType.TEXT,
    },
    hsNumberType: {
      default: true,
      name: 'HS number type',
      type: EsDataType.KEYWORD,
    },
    startDate: {
      default: true,
      name: 'Start Date',
      type: EsDataType.TIME,
    },
    endDate: {
      default: true,
      name: 'End Date',
      type: EsDataType.TIME,
    },
  },
  skipOperatorId: true,
});
