import { RecordType } from '@xbcb/shared-types';
import { poaSubject, ccSubject, poaBody, ccBody } from '../signatureEmail';

const workDay = {
  works: true,
  start: '2019-01-01T14:00:00.000Z',
  end: '2019-01-01T22:00:00.000Z',
};

export const dataDefaults = {
  [RecordType.USER]: {
    profile: {},
    workingHours: {
      mon: workDay,
      tue: workDay,
      wed: workDay,
      thu: workDay,
      fri: workDay,
    },
    entryTimePercentage: 100,
    permissions: {
      products: 0,
      classifications: 0,
      shipments: 0,
      importers: 0,
      consignees: 0,
      suppliers: 0,
      truckers: 0,
      destinations: 0,
      forwarders: 0,
      amsQueries: 0,
      questionnaire: 0,
      users: 0,
      invoices: 0,
      company: 0,
      templates: 0,
    },
  },
  [RecordType.SHIPPER]: {},
  [RecordType.SUPPLIER]: {},
  consignees: {},
  [RecordType.PRODUCT]: {},
  [RecordType.TRUCKER]: {},
  [RecordType.FACILITY]: {},
  [RecordType.FORWARDER]: {},
  [RecordType.PRODUCT]: {},
  [RecordType.SHIPMENT]: {},
  [RecordType.CLASSIFICATION]: {
    lines: [
      {
        tariffs: [{}],
      },
    ],
  },
  onboarding: {
    profile: {
      profile: {
        mobilePhoneCountry: '1',
        officePhoneCountry: '1',
      },
      notifications: {
        forwarders: {
          inviteAccepted: {
            app: true,
          },
        },
        importers: {
          activated: {
            app: true,
            email: true,
          },
        },
        shipments: {
          availableForPickup: {
            app: true,
          },
          canceled: {
            app: true,
          },
          containerOutgate: {
            app: true,
          },
          emptyReturned: {
            app: true,
          },
          customsHold: {
            app: true,
            email: true,
          },
          customsExam: {
            app: true,
            email: true,
          },
          customsRelease: {
            app: true,
            email: true,
          },
          lfdEstablished: {
            app: true,
          },
          lfdWarning: {
            app: true,
            email: true,
          },
          pgaHold: {
            app: true,
            email: true,
          },
        },
        users: {
          inviteAccepted: {
            app: true,
          },
        },
      },
    },
    company: {
      countryCode: 'US',
      authorized: true,
      'direct-poa-subject': poaSubject,
      'direct-poa-body': poaBody,
      'cc-subject': ccSubject,
      'cc-body': ccBody,
    },
    subscription: {
      period: 'annually',
      paymentMethod: 'achDebit',
      plan: 2,
    },
    bond: {
      need: false,
      hasReason: 'continuous',
      duties: 1000,
      coverage: 50000,
      quote: 249,
    },
    duties: {
      paying: 'direct',
      needPayerUnitNumber: false,
      paymentMethod: 'achDebit',
    },
  },
  questionnaire: {
    company: {
      ctpatCertified: false,
      isaParticipant: false,
      ceeMember: false,
      public: false,
    },

    value: {
      relatedParties: false,
      commissions: false,
      fees: false,
      foreignWork: false,
      materials: false,
      antiDumping: false,
    },

    classification: {
      bindingRulings: false,
    },

    specialProgramIndicators: {
      eligible: [],
    },

    partnerGovernmentAgencies: {
      subject: [],
    },

    intellectualProperty: {
      granted: false,
    },

    miscellaneous: {
      cbpAction: false,
      claim: false,
    },
  },
};
