import gql from 'graphql-tag';
import { partyFragments } from './party';

export const incrementUsEntryBrokerNumber = gql`
  mutation IncrementUsEntryBrokerNumber($usCustomsBrokerId: ID!) {
    incrementUsEntryBrokerNumber(usCustomsBrokerId: $usCustomsBrokerId) {
      brokerNumber
    }
  }
`;

const usCustomsBrokerFragment = `
fragment usCustomsBrokerFields on UsCustomsBroker {
  ...recordFields
  ...partyFields
  entityName
  filerCode
  ein
}
`;

export const usCustomsBrokerFragments = `
${usCustomsBrokerFragment}
${partyFragments}
`;
