// these are client errors, we dont need to throw a fatal error
export enum ClientSideException {
  BAD_USER_INPUT = 'BAD_USER_INPUT',
  GRAPHQL_VALIDATION_FAILED = 'GRAPHQL_VALIDATION_FAILED',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  FORBIDDEN = 'FORBIDDEN',
  VERSION_MISMATCH = 'VERSION_MISMATCH',
  NOT_FOUND = 'NOT_FOUND',
  DELETE_FAILED = 'DELETE_FAILED',
}

export const ClientSideExceptionNameToCodeMap = {
  VersionMismatchException: ClientSideException.VERSION_MISMATCH,
  RecordNotFoundException: ClientSideException.NOT_FOUND,
  DeleteRecordException: ClientSideException.DELETE_FAILED,
};
