import styled from 'styled-components';
import { CssSpace } from '@xbcb/ui-types';

const marginRight = `
  margin-right: ${CssSpace.SPACE_4};
`;

export const StyledTitleDiv = styled.div`
  ${marginRight}
`;

export const StyledParagraph = styled.p`
  ${marginRight}
`;
