import {
  SecretsManagerClient,
  SecretsManagerClientConfig,
  GetSecretValueCommand,
} from '@aws-sdk/client-secrets-manager';

type ClientConfiguration = {
  region?: string;
  credentials?: SecretsManagerClientConfig['credentials'];
};

export const getSecretByArn = async (
  secretArn: string,
  // do not supply keyName for plaintext secrets because it will break JSON.parse
  keyName?: string,
  credentials?: SecretsManagerClientConfig['credentials'],
): Promise<string> => {
  const params: ClientConfiguration = {
    region: process.env.REGION || 'us-west-2',
    credentials,
  };
  const client = new SecretsManagerClient(params);
  const command = new GetSecretValueCommand({ SecretId: secretArn });
  const secret = await client.send(command);
  if (!secret?.SecretString) {
    throw new Error(`Can't find secret key for ${secretArn}`);
  }
  if (keyName) {
    return JSON.parse(secret?.SecretString as string)[`${keyName}`];
  } else {
    return secret?.SecretString;
  }
};
