import type { Product, UsConsumptionEntry } from '@xbcb/api-gateway-client';
import log from '@xbcb/log';
import { chunk } from 'lodash';
import { SortOrder } from '@xbcb/shared-types';
import type { QueryProductsFunction } from '../../../types';

// The search is sequential here but based on load make this parallel if needed
const getProductMap = async (
  productIds: string[],
  queryFn: QueryProductsFunction,
): Promise<Record<string, Product>> => {
  log.info(`Searching for ${productIds.length} products`);

  const productMap: Record<string, Product> = {};
  const retryCount = 10;
  const productIdChunks = chunk(productIds, 100); // ES has a limit of 10k elements

  for (const productIdChunk of productIdChunks) {
    let attempt = 0;
    const productsNotFound = new Set(productIdChunk);
    log.info(
      `Searching for ${productsNotFound.size} products at ${performance.now()}`,
    );
    while (productsNotFound.size > 0 && attempt < retryCount) {
      const { results: products } = await queryFn({
        searchCriteria: {
          id: {
            values: [...productsNotFound],
            operator: 'ONE_OF',
          },
        },
        sortOptions: [
          {
            field: 'id',
            order: SortOrder.ASC,
          },
        ],
      });

      for (const product of products) {
        if (productMap[product.id] !== undefined) {
          log.error(`Multiple products with ${product.id} found.`, {
            params: {
              existingProduct: productMap[product.id],
              newProduct: product,
            },
          });
          throw new Error(`Multiple products with ${product.id} found.`);
        }

        log.info(`Adding product ${product.id} to the map`);
        productMap[product.id] = product;
        productsNotFound.delete(product.id);
      }

      attempt += 1;
    }
    log.info(
      `Searching for ${
        productsNotFound.size
      } products ended at ${performance.now()}`,
    );
    if (productsNotFound.size > 0) {
      const notFoundProductIds = [...productsNotFound];
      log.error(`Failed to find products after ${retryCount} attempts`, {
        params: {
          notFoundProductIds,
        },
      });
    }

    log.info(`Attempts needed for search = ${attempt}`, {
      key: 'searchProductAttemptCount',
    });
  }

  log.info(`Found ${Object.keys(productMap).length} entries`);

  if (productIds.length !== Object.keys(productMap).length) {
    throw new Error("Search didn't return all the required products");
  }

  return productMap;
};

export const processProducts =
  (queryFn: any) =>
  async (entry: UsConsumptionEntry): Promise<void> => {
    // get product ids
    const productIds = new Set(
      entry.invoices
        ?.flatMap((invoice) =>
          (invoice.products || [])?.flatMap(({ product }) => product?.id),
        )
        .filter((id): id is string => id !== undefined),
    );

    // get product details
    const productMap = await getProductMap([...productIds], queryFn);

    // replace the id-version with details
    for (const invoice of entry.invoices || []) {
      for (const product of invoice.products || []) {
        if (!product.product?.id) continue;
        product.product = productMap[product.product.id];
      }
    }
  };
