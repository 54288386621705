import styled from 'styled-components';

export const StyledCompanyInfoDiv = styled.div`
  text-align: left;
  .company-info-section-title {
    margin-bottom: var(--space-4);
  }

  .company-info-import-details-title {
    margin-bottom: 5px;
  }

  .company-info-foreign-switch {
    float: right;
    margin-top: 5px;
  }

  .company-info-full-city-width {
    padding-right: 0;
  }
  h4,
  .cbp-number-help {
    margin-bottom: var(--space-4);
  }
  .ior-number-type {
    &.hidden {
      display: none;
    }
  }
  .ant-input-group > [class*='col-'] {
    padding: 0;
  }
  .ant-input-group > [class*='col-']:last-child {
    padding-left: 8px;
  }
`;

export const StyledFirstTimeRequirementsSpan = styled.span`
  font-size: 16px;
`;
