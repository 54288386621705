import { UiStage } from '@xbcb/ui-types';

// TODO consolidate w/ graphql-request/src/gatewayEndpoints

const region = 'us-west-2';

const remoteEndpoints = {
  [UiStage.ALPHA]: {
    endpoint: 'https://5kh5rpjaz9.execute-api.us-west-2.amazonaws.com',
    path: '/alpha/graphql',
    region,
  },
  [UiStage.BETA]: {
    endpoint: 'https://5wa9t68ym5.execute-api.us-west-2.amazonaws.com',
    path: '/beta/graphql',
    region,
  },
  [UiStage.GAMMA]: {
    endpoint: 'https://lk3fxwwgg7.execute-api.us-west-2.amazonaws.com',
    path: '/gamma/graphql',
    region,
  },
  [UiStage.PROD]: {
    endpoint: 'https://056ffwy7d4.execute-api.us-west-2.amazonaws.com',
    path: '/prod/graphql',
    region,
  },
};

const isRemoteUiStage = (
  stage: unknown,
): stage is keyof typeof remoteEndpoints =>
  Boolean(remoteEndpoints[stage as keyof typeof remoteEndpoints]);

const { REACT_APP_GATEWAY_STAGE } = process.env;
let localUiGateway;
if (isRemoteUiStage(REACT_APP_GATEWAY_STAGE)) {
  localUiGateway = remoteEndpoints[REACT_APP_GATEWAY_STAGE];
} else if (REACT_APP_GATEWAY_STAGE) {
  throw new Error(
    `REACT_APP_GATEWAY_STAGE ${REACT_APP_GATEWAY_STAGE} is invalid`,
  );
} else {
  localUiGateway = remoteEndpoints[UiStage.BETA];
}
export const gatewayEndpoints = {
  [UiStage.LOCAL]: localUiGateway,
  ...remoteEndpoints,
};
