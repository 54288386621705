import { Stage } from '@xbcb/core';
import { FeatureFlagService } from '../featureFlagService';

export const euUkRfpDocumentFeature = 'EU_UK_RFP_DOCUMENT_FEATURE';

const EU_UK_RFP_DOCUMENT_FEATURE = new FeatureFlagService([
  {
    feature: euUkRfpDocumentFeature,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA, Stage.PROD],
  },
]);

export { EU_UK_RFP_DOCUMENT_FEATURE };
