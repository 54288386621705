const debounces = {};
let counter = 0;

export const add = (debounce) => {
  debounces[counter] = debounce;
  return counter++;
};

export const flushAll = () => {
  Object.values(debounces).forEach((debounce) => {
    if (debounce && typeof debounce.flush === 'function') debounce.flush();
  });
};

export const remove = (idx) => {
  delete debounces[idx];
};
