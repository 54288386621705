import { LoadType } from '@xbcb/shared-types';
import { IsfType, UsIsfCbpStatus } from '@xbcb/work-order-types';
import { createShipmentWorkOrderRecord } from './utils';
import { EsDataType } from '../../enums';

export const usIsf = createShipmentWorkOrderRecord({
  columns: {
    isfNumber: {
      name: 'ISF #',
      type: EsDataType.KEYWORD,
    },
    masterBill: {
      default: true,
      name: 'Master Bill #',
      type: EsDataType.TEXT,
    },
    houseBill: {
      default: true,
      name: 'House Bill #',
      type: EsDataType.TEXT,
    },
    cbpStatus: {
      default: true,
      name: 'CBP Status',
      type: EsDataType.KEYWORD,
      optionValues: Object.keys(UsIsfCbpStatus),
    },
    isfType: {
      name: 'ISF Type',
      type: EsDataType.KEYWORD,
      optionValues: Object.keys(IsfType),
    },
    shipmentTypeCode: {
      name: 'CBP Shipment Type',
      type: EsDataType.KEYWORD,
    },
    iorName: {
      default: true,
      name: 'IOR name',
      type: EsDataType.TEXT,
    },
    manufactureName: {
      // TODO spelling, needs to change in the backend
      name: 'Manufacturer name',
      type: EsDataType.TEXT,
    },
    portOfUnladingCode: {
      name: 'Port of unlading code',
      type: EsDataType.KEYWORD,
    },
    placeOfDelivery: {
      name: 'Place of delivery',
      type: EsDataType.TEXT,
    },
    buyerName: {
      name: 'Buyer name',
      type: EsDataType.TEXT,
    },
    shipToName: {
      name: 'Ship to name',
      type: EsDataType.TEXT,
    },
    consolidatorName: {
      name: 'Consolidator name',
      type: EsDataType.TEXT,
    },
    sellerName: {
      name: 'Seller name',
      type: EsDataType.TEXT,
    },
    stuffingLocationName: {
      name: 'Stuffing location name',
      type: EsDataType.TEXT,
    },
    bookingId: {
      name: 'Booking ID',
      type: EsDataType.KEYWORD,
    },
    loadType: {
      name: 'Load Type',
      type: EsDataType.KEYWORD,
      optionValues: Object.keys(LoadType),
    },
    eta: {
      name: 'ETA',
      type: EsDataType.TIME,
    },
    bondNumber: {
      name: 'Bond Number',
      type: EsDataType.TEXT,
    },
  },
});
