import { AnyObject } from '@xbcb/shared-types';

export enum IotEventType {
  SHOW_MESSAGE = 'SHOW_MESSAGE',
  SHOW_MODAL = 'SHOW_MODAL',
  SHOW_NOTIFICATION = 'SHOW_NOTIFICATION',
}

export type CbmsUserRecipientsGroup = {
  userIds: string[];
  type: IotEventType;
  payload: AnyObject;
};

export interface IotEventPayload {
  cbmsUserRecipientsGroups: CbmsUserRecipientsGroup[];
}
