import { EsDataType, recordFields } from '../../index';
import { createTableConfig } from '../createTableConfig';

export const reportReconciliation = createTableConfig({
  columns: {
    fileName: {
      default: true,
      name: 'Report Name',
      type: EsDataType.KEYWORD,
    },
    type: {
      default: true,
      name: 'Report Type',
      type: EsDataType.KEYWORD,
    },

    status: {
      default: true,
      name: 'Report Status',
      type: EsDataType.KEYWORD,
    },
    numberOfRows: {
      default: true,
      name: 'Number Of Rows',
      type: EsDataType.FLOAT,
    },
    numberOfExceptions: {
      default: true,
      name: 'Number Of Exceptions',
      type: EsDataType.FLOAT,
    },
    ...recordFields({
      createdTime: { react: true, default: true, name: 'Requested At' },
    }),
  },
  tableOptions: {
    sortField: 'createdTime',
  },
});
