import gql from 'graphql-tag';
import { getRecordName, dedupRecordQuery, RecordProps } from './shared';

interface UpdateOneProps extends RecordProps {}

export const updateOneMutation = ({
  recordName,
  fields = '',
  fragments = '',
}: UpdateOneProps) => {
  const { fieldsString, fragmentsString } = dedupRecordQuery(fields, fragments);
  const RecordName = getRecordName(recordName);
  const InputType = `Update${RecordName}Input`;
  return gql`
    mutation UpdateOne($id: ID!, $version: Int!, $input: ${InputType}!) {
      update${RecordName}(
        id: $id
        version: $version
        input: $input
      ) {
        record {
          ${fieldsString}
        }
      }
    }
    ${fragmentsString}
  `;
};
