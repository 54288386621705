import { recordFields, recordActionFields } from '@xbcb/shared-queries';

export const documentFragment = `
fragment documentFields on Document {
  extension
  fileName
  contentType
  antivirusScanStatus
  passwordStatus
  sha1
  size
  documentTags
  content {
    downloadLink
    uploadLink
  }
  preview {
    downloadLink
    uploadLink
  }
  disSubmissions {
    disStatus 
    disDocName 
    transaction {
      ... on SubmitUsConsumptionEntryDisTransaction {
        id
        version
        created{time client{name}}
      }
      ... on SubmitUsType86EntryDisTransaction {
        id
        version
        created{time client{name}}
      }
    }
  }
  extractedDocumentContent {
    externalDocumentReference {
      id
    }
  }
  externalDocumentReference {
    id
  }
  status
  ...recordFields
}
`;

export const documentFragments = `
  ${documentFragment}
  ${recordFields}
  ${recordActionFields}
`;
