import { UsConsumptionEntry } from '@xbcb/api-gateway-client';

export const isEntryPaymentAuthorized = (entry: UsConsumptionEntry): boolean =>
  // This is confirmed by op team to be a proper way to check for authorization
  // entry is considered authorized if collectionStatus is one of 'Fully paid (2)/Duty free (3)/Drawback (5)/Authorized (6)' or authorizationTime is set from PZ response
  Boolean(
    entry?.dailyStatement?.authorizationTime ||
      (entry.collectionStatus &&
        ['2', '3', '5', '6'].includes(entry.collectionStatus)),
  );
