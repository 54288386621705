import { NoAccessPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const ForwarderUserBulkMilestoneUploadRequestPermissionName = {
  ...NoAccessPermissionName,
};

export const ForwarderUserBulkChargesUploadRequestPermissionName = {
  ...NoAccessPermissionName,
};

export const ForwarderUserBulkComplianceUploadRequestPermissionName = {
  ...NoAccessPermissionName,
};

export default {
  [ObjectType.BULK_MILESTONE_UPLOAD_REQUEST]:
    ForwarderUserBulkMilestoneUploadRequestPermissionName,
  [ObjectType.BULK_CHARGES_UPLOAD_REQUEST]:
    ForwarderUserBulkChargesUploadRequestPermissionName,
  [ObjectType.BULK_COMPLIANCE_UPLOAD_REQUEST]:
    ForwarderUserBulkComplianceUploadRequestPermissionName,
};
