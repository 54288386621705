import {
  deCustomsBrokerFragments,
  nlCustomsBrokerFragments,
  frCustomsBrokerFragments,
  ukCustomsBrokerFragments,
} from '@xbcb/party-queries';

// only takes input for type GbIorActivation, DeIorActivation, NlIorActivation, FrIorActivation
export const getEuIorActivationFragment = (
  activationFields: string,
  customsBrokerFields: string,
  type: string,
): string => {
  const fragment = `fragment ${activationFields} on ${type}{
    ...recordFields
    ior {
      ior {
        id
        vatNumber
        eori 
        name
        businessStructure
        industry
        activations {
          status
          activated {
            time
          }
          customsBroker{
            id
          }
        }
      }
    }
    operator {
      id
    }
    broker {
      customsBroker{
        ...${customsBrokerFields}
      }
    }
    milestones {
      id
      version
      name
      occurenceTime
    }
    status
    deadline
    isAutoSetReady
    group {
      id
      shipment {
        legs {
          arrival {
            time
          }
        }
      }
      shipper {
        id
      }
      customsBroker {
        ...${customsBrokerFields}
      }
      forwarders {
        forwarder {
          id
          name
        }
      }
    }
  }`;
  return fragment;
};

export const gbIorActivationFragment = getEuIorActivationFragment(
  'gbIorActivationFields',
  'ukCustomsBrokerFields',
  'GbIorActivation',
);

export const deIorActivationFragment = getEuIorActivationFragment(
  'deIorActivationFields',
  'deCustomsBrokerFields',
  'DeIorActivation',
);

export const nlIorActivationFragment = getEuIorActivationFragment(
  'nlIorActivationFields',
  'nlCustomsBrokerFields',
  'NlIorActivation',
);

export const frIorActivationFragment = getEuIorActivationFragment(
  'frIorActivationFields',
  'frCustomsBrokerFields',
  'FrIorActivation',
);

export const gbIorActivationFragments = `
${gbIorActivationFragment}
${ukCustomsBrokerFragments}
`;

export const deIorActivationFragments = `
${deIorActivationFragment}
${deCustomsBrokerFragments}
`;

export const nlIorActivationFragments = `
${nlIorActivationFragment}
${nlCustomsBrokerFragments}
`;

export const frIorActivationFragments = `
${frIorActivationFragment}
${frCustomsBrokerFragments}
`;
