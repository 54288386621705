import {
  CustomerUserNotificationType,
  ShipperUserNotificationType as ShipperUserNotifications,
  UserNotificationType,
} from '@xbcb/notification-types';

import { UserNotificationMethod } from '../base';

export const ShipperUserNotificationMethod = {
  ...UserNotificationMethod,
};

export const ShipperUserNotificationType = {
  ...CustomerUserNotificationType,
  ...UserNotificationType,
  ...ShipperUserNotifications,
};
