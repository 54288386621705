const ignoredSuffixes = ['jr', 'sr', 'i', 'ii', 'iii'];

export const convert5106Name = (name) => {
  if (!name) return name;
  const split = name.split(' ');
  if (split.length === 2) {
    const [firstName, lastName] = split;
    return `${lastName}, ${firstName}`;
  } else if (split.length === 3) {
    const [firstName, middleName, lastName] = split;
    return `${lastName}, ${firstName}, ${middleName.charAt(0)}`;
  } else if (split.length === 4) {
    const [firstName, middleName, lastName, suffix] = split;
    if (
      ignoredSuffixes.includes(
        suffix.toString().toLowerCase().replace(/\W/g, ''),
      )
    ) {
      return `${lastName}, ${firstName}, ${middleName.charAt(0)}`;
    } else {
      throw new Error(`Unsupported 5106 name: ${name}`);
    }
  } else {
    throw new Error(`Unsupported 5106 name: ${name}`);
  }
};
