import {
  UsConsumptionEntryProduct,
  UsPostSummaryCorrectionLineInput,
  UsPostSummaryCorrectionProductInput,
  UsPostSummaryCorrectionTariffInput,
} from '@xbcb/api-gateway-client';

export const transformUsConsumptionEntryProductsToUsPostSummaryCorrectionProductInputs =
  (
    usConsumptionEntryProducts: UsConsumptionEntryProduct[] | undefined,
  ): UsPostSummaryCorrectionProductInput[] | undefined => {
    const usPostSummaryCorrectionProducts = usConsumptionEntryProducts?.map(
      ({ product, quantity, totalValue, lines }) => {
        const usPostSummaryCorrectionProductInput: UsPostSummaryCorrectionProductInput =
          {
            quantity,
            totalValue,
          };
        if (product) {
          const { id, version } = product;
          usPostSummaryCorrectionProductInput.product = { id, version };
        }
        const usPostSummaryCorrectionLines = lines?.map(
          ({
            adCase,
            adPaymentMethod,
            adValue,
            aluminumExclusionNumber,
            cbpCharges,
            cvCase,
            cvPaymentMethod,
            cvValue,
            description,
            externalReference,
            feeOptions,
            licenseCode,
            licenseNumber,
            // TODO lineReasons
            manufacturer,
            origin,
            poNumber,
            productClaimCode,
            ruling,
            spi,
            steelExclusionNumber,
            tariffs,
            taxOptions,
            value,
          }) => {
            const line: UsPostSummaryCorrectionLineInput = {
              adPaymentMethod,
              adValue,
              aluminumExclusionNumber,
              cbpCharges,
              cvPaymentMethod,
              cvValue,
              description,
              externalReference,
              feeOptions,
              licenseCode,
              licenseNumber,
              origin,
              poNumber,
              productClaimCode,
              ruling,
              spi,
              steelExclusionNumber,
              taxOptions,
              value,
            };
            if (adCase) {
              const { id } = adCase;
              line.adCase = { id };
            }
            if (cvCase) {
              const { id } = cvCase;
              line.cvCase = { id };
            }
            if (manufacturer) {
              const { address, mid, name, supplier } = manufacturer;
              line.manufacturer = { address, mid, name };
              if (supplier) {
                const { id, version } = supplier;
                line.manufacturer.supplier = { id, version };
              }
            }
            line.tariffs = tariffs?.map(
              ({ hts, htsNumber, unitReportingQuantities, unitValue }) => {
                const tariff: UsPostSummaryCorrectionTariffInput = {
                  htsNumber,
                  unitValue,
                  unitReportingQuantities,
                };
                if (hts) tariff.hts = { id: hts?.id };
                return tariff;
              },
            );
            return line;
          },
        );
        usPostSummaryCorrectionProductInput.lines =
          usPostSummaryCorrectionLines;
        return usPostSummaryCorrectionProductInput;
      },
    );
    return usPostSummaryCorrectionProducts;
  };
