import {
  CrudPermissionName,
  UsInBondPermissionName,
  UsIsfPermissionName,
  NoAccessPermissionName,
} from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const ForwarderUserUsIsfPermissionName = {
  ...UsIsfPermissionName,
};

export const ForwarderUserUsConsumptionEntryPermissionName = {
  ...CrudPermissionName,
};

export const ForwarderUserPostSummaryCorrectionPermissionName = {
  ...NoAccessPermissionName,
};

export const ForwarderUserUsInBondPermissionName = {
  ...UsInBondPermissionName,
};

export const ForwarderUserUsIorContinuousBondRequestPermissionName = {
  ...CrudPermissionName,
};

export const ForwarderUserUsDrawbackClaimPermissionName = {
  ...CrudPermissionName,
};

export const ForwarderUserUsIorActivationPermissionName = {
  ...CrudPermissionName,
};

export const ForwarderUserDeliveryOrderPermissionName = {
  ...CrudPermissionName,
};

export const ForwarderUserGbCustomsEntryPermissionName = {
  ...CrudPermissionName,
};

export const ForwarderUserDeCustomsEntryPermissionName = {
  ...CrudPermissionName,
};

export const ForwarderUserNlCustomsEntryPermissionName = {
  ...CrudPermissionName,
};

export const ForwarderUserFrCustomsEntryPermissionName = {
  ...CrudPermissionName,
};

export const ForwarderUserUsType86EntryPermissionName = {
  ...CrudPermissionName,
};

const workOder = {
  [ObjectType.US_ISF]: ForwarderUserUsIsfPermissionName,
  [ObjectType.US_CONSUMPTION_ENTRY]:
    ForwarderUserUsConsumptionEntryPermissionName,
  [ObjectType.US_POST_SUMMARY_CORRECTION]:
    ForwarderUserPostSummaryCorrectionPermissionName,
  [ObjectType.US_IN_BOND]: ForwarderUserUsInBondPermissionName,
  [ObjectType.US_IOR_CONTINUOUS_BOND_REQUEST]:
    ForwarderUserUsIorContinuousBondRequestPermissionName,
  [ObjectType.US_DRAWBACK_CLAIM]: ForwarderUserUsDrawbackClaimPermissionName,
  [ObjectType.US_IOR_ACTIVATION]: ForwarderUserUsIorActivationPermissionName,
  [ObjectType.DELIVERY_ORDER]: ForwarderUserDeliveryOrderPermissionName,
  [ObjectType.GB_CUSTOMS_ENTRY]: ForwarderUserGbCustomsEntryPermissionName,
  [ObjectType.DE_CUSTOMS_ENTRY]: ForwarderUserDeCustomsEntryPermissionName,
  [ObjectType.NL_CUSTOMS_ENTRY]: ForwarderUserNlCustomsEntryPermissionName,
  [ObjectType.FR_CUSTOMS_ENTRY]: ForwarderUserFrCustomsEntryPermissionName,
  [ObjectType.US_TYPE86_ENTRY]: ForwarderUserUsType86EntryPermissionName,
};

export default workOder;
