import { mid, feiNumber, shipperId, shipperName } from './party';
import { createPartyTable } from './createPartyTable';
import { EsDataType } from '../../enums';

export const supplier = createPartyTable({
  columns: {
    shipperName,
    shipperId,
    mid,
    feiNumber,
    countryName: {
      name: 'Country',
      type: EsDataType.TEXT,
    },
  },
});
