import React from 'react';
import { notification } from 'antd';
import { reportError } from '../reportError';

export const showValidationErrors = (
  sections,
  {
    showAsList = false,
    duration = 4500,
    errorDecorator = (errorElement) => errorElement,
  } = {},
) => {
  try {
    const description = sections.map(({ title, messages }) => {
      const messageList = (
        <ul>
          {messages.map((message) => (
            <li key={message}>{message}</li>
          ))}
        </ul>
      );

      return showAsList ? (
        <li
          key={title}
          style={{
            wordWrap: 'break-word',
          }}
        >
          <b>{title}</b>
          {messageList}
        </li>
      ) : (
        <div
          key={title}
          style={{
            wordWrap: 'break-word',
          }}
        >
          <b>{title}</b>
          {messageList}
        </div>
      );
    });

    const opts = {
      message: 'There were issues with submitting',
      description: errorDecorator(description),
    };
    if (typeof duration !== undefined) {
      opts['duration'] = duration;
    }
    notification.error(opts);
  } catch (e) {
    reportError(e);
  }
};
