import { AccountType } from '@xbcb/shared-types';
import { EsDataType } from '../../index';
import { createUserTableConfig } from './user';
import { columns as externalIntegrationReferenceColumns } from '../externalIntegrationReference';

export const forwarderUser = createUserTableConfig({
  columns: {
    forwarderId: {
      name: 'Forwarder ID',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    forwarderName: {
      default: true,
      name: 'Forwarder',
      type: EsDataType.TEXT,
      whitelist: [AccountType.OPERATOR],
    },
    ...externalIntegrationReferenceColumns,
  },
});
