import gql from 'graphql-tag';

export const generateUsDrawbackClaimNumber = gql`
  mutation GenerateUsDrawbackClaimNumber($usDrawbackClaimId: ID!) {
    generateUsDrawbackClaimNumber(usDrawbackClaimId: $usDrawbackClaimId) {
      usDrawbackClaim {
        id
        version
        brokerNumber
        entryNumber
      }
      newNumberGenerated
    }
  }
`;
