import Validator from 'validatorjs';
import { AnyObject } from '@xbcb/shared-types';
interface DataValidatorBaseInput {
  data: AnyObject;
}

interface DeclarativeValidatorInput extends DataValidatorBaseInput {
  declarativeRules?: { [key: string]: string | string[] };
}

export type CustomValidatorFn = (data: AnyObject) => Promise<string[]>;

interface CustomValidatorInput extends DataValidatorBaseInput {
  customValidators?: CustomValidatorFn[];
}
export interface DataValidatorInput extends DataValidatorBaseInput {
  declarativeRules?: { [key: string]: string | string[] };
  customValidators?: CustomValidatorFn[];
}

export interface DataValidationResult {
  isValid: boolean;
  // Note: declarativeValidator output format: { 'key1': ['error1', 'error2'], 'key2': ['error3] }
  // Empty {} means no error.
  declarativeValidationErrors?: { [key: string]: string[] };
  // Empty [] means no error
  customValidationErrors?: string[];
}
// Performs data validation on input data. Runs declarative validations using ValidatorJS lib with declarativeRules.
// Runs custom validations functions.
export class DataValidator {
  public validate = async (
    input: DataValidatorInput,
  ): Promise<DataValidationResult> => {
    const declarativeValidationErrors = this.runDeclarativeValidations({
      data: input.data,
      declarativeRules: input.declarativeRules,
    });
    const customValidationErrors = await this.runCustomValidations({
      data: input.data,
      customValidators: input.customValidators,
    });

    return {
      isValid:
        Object.keys(declarativeValidationErrors).length === 0 &&
        customValidationErrors.length === 0,
      declarativeValidationErrors,
      customValidationErrors,
    };
  };

  private runDeclarativeValidations = (
    input: DeclarativeValidatorInput,
  ): { [key: string]: string[] } => {
    const { data, declarativeRules } = input;
    if (declarativeRules) {
      const declarativeValidator = new Validator(data, declarativeRules);
      const matched = declarativeValidator.passes();
      if (!matched) return declarativeValidator.errors.all();
      else return {};
    }
    return {};
  };

  private runCustomValidations = async (
    input: CustomValidatorInput,
  ): Promise<string[]> => {
    let errors: string[] = [];
    const { data, customValidators = [] } = input;
    for (const customValidator of customValidators) {
      try {
        errors = errors.concat(await customValidator(data));
      } catch (e: unknown) {
        errors.push((e as Error).message);
      }
    }
    return errors;
  };

  public static getFlattenedErrorListFromResult = (
    result: DataValidationResult,
  ): string[] => {
    const {
      declarativeValidationErrors = {},
      customValidationErrors: errors = [],
    } = result;
    for (const value of Object.values(declarativeValidationErrors)) {
      for (const error of value as string[]) {
        errors.push(error);
      }
    }
    return errors;
  };
}
