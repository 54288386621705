import moment from 'moment';
// specifically US CBP holidays
// Needs to be updating at least annually
// https://content.govdelivery.com/accounts/USDHSCBP/bulletins/2735246
// https://www.opm.gov/policy-data-oversight/pay-leave/federal-holidays#url=Overview

// used in the print date calculation of the entries
export const usHolidays = [
  '2018-01-01',
  '2018-01-15',
  '2018-02-19',
  '2018-05-28',
  '2018-07-04',
  '2018-09-03',
  '2018-10-08',
  '2018-11-12',
  '2018-11-22',
  '2018-12-25',
  '2019-01-01',
  '2019-01-21',
  '2019-02-18',
  '2019-05-27',
  '2019-07-04',
  '2019-09-02',
  '2019-10-14',
  '2019-11-11',
  '2019-11-28',
  '2019-12-25',
  '2020-01-01',
  '2020-01-20',
  '2020-02-17',
  '2020-05-25',
  '2020-07-03',
  '2020-09-07',
  '2020-10-12',
  '2020-11-11',
  '2020-11-26',
  '2020-12-24',
  '2020-12-25',
  '2021-01-01',
  '2021-01-18',
  '2021-02-15',
  '2021-05-31',
  '2021-07-04',
  '2021-07-05',
  '2021-09-06',
  '2021-10-11',
  '2021-11-11',
  '2021-11-25',
  '2021-12-24',
  '2021-12-25',
  '2021-12-31',
  '2022-01-17',
  '2022-02-21',
  '2022-05-30',
  '2022-06-20',
  '2022-07-04',
  '2022-09-05',
  '2022-10-10',
  '2022-11-11',
  '2022-11-24',
  '2022-12-26',
  // https://app.asana.com/0/0/1203589269608051/f
  '2023-01-02', // Monday, January 2       New Year’s Day
  '2023-01-16', // Monday, January 16      Birthday of Martin Luther King, Jr.
  '2023-02-20', // Monday, February 20     Washington’s Birthday
  '2023-05-29', // Monday, May 29          Memorial Day
  '2023-06-19', // Monday, June 19         Juneteenth National Independence Day
  '2023-07-04', // Tuesday, July 4         Independence Day
  '2023-09-04', // Monday, September 4     Labor Day
  '2023-10-09', // Monday, October 9       Columbus Day
  '2023-11-10', // Friday, November 10     Veterans Day
  '2023-11-23', // Thursday, November 23   Thanksgiving Day
  '2023-12-25', // Monday, December 25     Christmas Day
  // https://www.opm.gov/policy-data-oversight/pay-leave/federal-holidays/#url=2024
  '2024-01-01',
  '2024-01-15',
  '2024-02-19',
  '2024-05-27',
  '2024-06-19',
  '2024-07-04',
  '2024-09-02',
  '2024-10-14',
  '2024-11-11',
  '2024-11-28',
  '2024-12-25',
];

export const checkUsHolidays = (date: moment.Moment | Date) => {
  if (!date) throw new Error('Missing date in checkUsHoliday');
  const str = date.toISOString().substr(0, 10);
  return usHolidays.includes(str);
};
