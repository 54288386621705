import { recordFields, recordActionFields } from '@xbcb/shared-queries';

export const workOrderTaskDefinitionFragment = `fragment workOrderTaskDefinitionFields on WorkOrderTaskDefinition {
  ...recordFields
  name
  description
  workOrderType
  workOrderTaskType
  steps {
    name
    description
  }
}
`;

export const workOrderTaskDefinitionFragments = `
${workOrderTaskDefinitionFragment}
${recordFields}
${recordActionFields}
`;
