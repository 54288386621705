import gql from 'graphql-tag';
import { addressesFields } from '@xbcb/party-queries';
import { recordActionFields, recordFields } from '@xbcb/shared-queries';

const cbpInBondCarrierFragment = `fragment cbpInBondCarrierFields on CbpInBondCarrier {
  ein
  code
  cain
  trucker {
    trucker {
      id
      version
      name
    }
  }
}`;

const cbpInBondFragment = `fragment cbpInBondFields on CbpInBond {
  entryType
  modeOfTransport
  foreignPortOfDestination
  goodsNowAt
  importingConveyanceArrivalPort
  consignee {
    usConsignee {
      ...on UsConsignee {
        id
        version
        name
        ...addressesFields
      }
      ...on UsIor {
        id
        version
        name
        ...addressesFields
      }
    }
  }
  usPortOfDestination
  valueOfGoods
  previousCbpInBond {
    date
    number
    port
  }
  carrier {
    ...cbpInBondCarrierFields
  } 
  subjectToFdaBioterrorismAct
  actions {
    actualDate
    firmsCode
    type
    status
    billNumber
    arrivalPort
    carrier {
      ...cbpInBondCarrierFields
    }
    city
    stateCode
  }
  bills {
    number
    quantity
    status
  }
  number
  status
  additionalReferenceIdentifiers {
    qualifier
    referenceIdentifier
  }
}
`;

export const usInBondFragment = `fragment usInBondFields on UsInBond {
  ...recordFields
  loadType
  badges
  group {
    shipment {
      legs {
        transportationServiceName
      }
    }
    id
  }
  operator {
    id
  }
  stateMachine {
    id
    version
  }
  status
  deadline
  milestones {
    id
    version
  }
  entryDate
  cbpInBonds {
    ...cbpInBondFields
  }
  arrival {
    time
    portOfUnlading
  }
  departure {
     time
     portOfLading
     country
  }
  conveyance {
    conveyanceName
    grossWeight
    tripNumber
    containerized
  }
  ior {
    usIor {
      name
      iorNumber {
        value
      }
    }
  }
  consignee {
    usConsignee {
      id
      version
    }
  }
  modeOfTransport
}
`;
export const usInBondFragments = `
${addressesFields}
${usInBondFragment}
${recordFields}
${recordActionFields}
${cbpInBondFragment}
${cbpInBondCarrierFragment}
`;

export const queryAmsUsInBond = gql`
  mutation QueryAmsUsInBond(
    $id: ID!
    $idempotencyKey: String!
    $input: QueryAmsUsInBondInput!
  ) {
    queryAmsUsInBond(
      usInBondId: $id
      idempotencyKey: $idempotencyKey
      input: $input
    ) {
      transaction {
        id
        version
        usInBond {
          id
        }
      }
    }
  }
`;
