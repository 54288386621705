import moment from 'moment';

// These are keys which we want to display the same regardless of where you are geographically.
// for example, for in-bonds, the time of closing is entered and displayed at the time that the event actually happened, unadjusted for time zones.
const localTimeKeys = [
  'permissionLetterDate',
  'previousDate',
  'actualDate',
  'importDate',
  'exportDate',
  'estimatedEntryDate',
  'inBondDate',
  'lfd',
  'printDate',
];

// TODO clean this function. There are too many 'any's and the types dont make any sense.
// This function should be overridden.
export const unMomentize = (
  object?: { [key: string]: unknown },
  parentKey?: any,
  dateOnly?: boolean,
): any => {
  if (!object) return object;

  // primitives
  if (object && typeof object !== 'object') return object;

  // arrays
  if (Array.isArray(object))
    return object.map((obj) => unMomentize(obj, parentKey, dateOnly));

  // moment objects
  if (moment.isMoment(object)) {
    let timeString;
    if (
      parentKey &&
      object.toISOString &&
      localTimeKeys.some((localKey) => parentKey.toString().endsWith(localKey))
    ) {
      timeString = object.toISOString(true); // boolean arg keeps it local
      timeString = timeString.slice(0, 23); // TODO document this offset
    } else if (object.toISOString) {
      if (dateOnly) {
        timeString = object.format('YYYY-MM-DD');
      } else {
        timeString = object.toISOString();
      }
    }
    return timeString;
  }

  // objects
  return Object.entries(object).reduce((acc, [key, value]) => {
    acc[key] = unMomentize(value as { [key: string]: unknown }, key);
    return acc;
  }, {} as { [key: string]: any });
};
