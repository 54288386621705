export enum ReportReconciliationExceptionReferenceQualifer {
  PRINT_DATE = 'PRINT_DATE',
  RELEASE_DATE = 'RELEASE_DATE',
  AUTHORIZATION_DATE = 'AUTHORIZATION_DATE',
  FINAL_DAILY_STATEMENT_DATE = 'FINAL_DAILY_STATEMENT_DATE',
  FINAL_PERIODIC_STATEMENT_DATE = 'FINAL_PERIODIC_STATEMENT_DATE',
  INVOICE_AMOUNT = 'INVOICE_AMOUNT',
  ENTRY_DUTY_AMOUNT = 'ENTRY_DUTY_AMOUNT',
  STATEMENT_DUTY_AMOUNT = 'STATEMENT_DUTY_AMOUNT',
}
