import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

export const genericWorkOrderFeature = 'GENERIC_WORK_ORDER_FEATURE';

const GENERIC_WORK_ORDER_FEATURE = new FeatureFlagService([
  {
    feature: genericWorkOrderFeature,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA],
  },
]);

export { GENERIC_WORK_ORDER_FEATURE };
