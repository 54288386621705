import { createWorkOrderRecord } from './utils';
import { WorkOrderRecordType } from '@xbcb/shared-types';
import { EsDataType } from '../../index';

export const workOrder = createWorkOrderRecord({
  tableOptions: { recordTypes: Object.values(WorkOrderRecordType) },
  columns: {
    brokerNumber: {
      name: 'Broker Number',
      type: EsDataType.KEYWORD,
    },
  },
});
