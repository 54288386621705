import { UsIor, UsContinuousCustomsBond } from '@xbcb/api-gateway-client';

export const getPresentOrFutureContinuousBond = (
  usIor: UsIor,
): UsContinuousCustomsBond | undefined => {
  const presentBond = usIor.continuousBonds?.present;
  const futureBond = usIor.continuousBonds?.future;

  return presentBond || futureBond;
};
