import { WorkOrderType } from '@xbcb/api-gateway-client';
import { isPayingCustomer } from './isPayingCustomer';
import { shouldInvoiceShipper } from './shouldInvoiceShipper';

type GetPayerProps = {
  forwarders?: any[];
  shipper?: any;
  // Used to determine if the shipper should be invoiced by checking the
  // workOrderConfig for the forwarder
  workOrderType: WorkOrderType;
};

export const getPayer = ({
  forwarders = [],
  shipper = {},
  workOrderType,
}: GetPayerProps) => {
  // shipper or forwarders could be `null`
  shipper = shipper || {};
  forwarders = forwarders || [];
  let payer;

  const payingForwarder = forwarders.find(isPayingCustomer);

  if (payingForwarder) {
    if (shouldInvoiceShipper(payingForwarder, workOrderType)) {
      payer = shipper;
    } else {
      // Choose the first provided forwarder who has a plan.
      // TODO tie-breaking logic?
      payer = payingForwarder;
    }
  } else if (isPayingCustomer(shipper)) {
    // If shipper has a plan and none of the provided forwarders have a plan, choose shipper as payer
    payer = shipper;
  } else {
    // find all forwarder connections of shipper
    // choose the first forwarder with plan
    const shipperRelatedForwarders = shipper.forwarders?.nodes;
    payer = shipperRelatedForwarders?.find(isPayingCustomer);
  }

  if (!payer)
    throw new Error(
      `Payer not found among shipper "${
        shipper?.id
      }" and forwarders "${forwarders.map(({ id }) => id).join()}"`,
    );
  return payer;
};

// TODO consolidate w/ identical logic in work-order-utils/src/getWorkOrderConfiguration (what package is appropriate? Need to avoid circular dependencies).
export const getPayerFromWorkOrderGroup = (
  workOrderGroup: any,
  workOrderType: WorkOrderType,
) => {
  const { shipper, forwarders: nestedForwarders } = workOrderGroup;

  // forwarders are doubly nested in WorkOrderGroup.
  const forwarders = nestedForwarders?.map(
    (forwarder: any) => forwarder.forwarder,
  );

  return getPayer({ shipper, forwarders, workOrderType });
};
