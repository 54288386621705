import { recordFields, recordActionFields } from '@xbcb/shared-queries';

export const documentGenerationRequestFragment = `
fragment documentGenerationRequestFields on DocumentGenerationRequest {
  status
  operator {
    id
  }
}
`;

export const costFragment = `
fragment costFields on Cost {
  value
  currency
}
`;

export const usQuantityFragment = `
fragment usQuantityFields on Quantity {
  value
  unit
}
`;

export const documentGenerationRequestFragments = `
${documentGenerationRequestFragment}
${recordFields}
${recordActionFields}
`;
