// TODO deprecate, since url is now dynamic, stage names have also changed
export const app = {
  dev: 'https://localhost:3000',
  test: 'https://app-test-5thqw7hsk8b2w698.inlt.com',
  qa: 'https://app-qa-jzpyqw3gb7zqam4t.inlt.com',
  prod: 'https://app.inlt.com',
};

export const importsign = {
  dev: 'https://localhost:3001',
  test: 'https://test-wdajqi38ywr.importsign.com',
  qa: 'https://qa-fzeb7g4fqae5rk87.importsign.com',
  prod: 'https://importsign.com',
};

export const inlt = {
  dev: 'https://localhost:3002',
  test: 'https://test-17372113.inlt.com',
  qa: 'https://qa-vz9fihq7i0f2wucv.inlt.com',
  prod: 'https://inlt.com',
};
