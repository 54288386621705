import { externalSystemFragments } from './externalSystem';

const forwarderSystemFragment = `
fragment forwarderSystemFields on ForwarderSystem {
  operator {
    id
  }
  forwarder {
    id
  }
  ...externalSystemFields
  ...recordFields
}
`;

export const forwarderSystemFragments = `
${forwarderSystemFragment}
${externalSystemFragments}
`;
