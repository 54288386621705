import gql from 'graphql-tag';
import {
  RecordType,
  TransactionRecordType,
  TransactionUnionType,
  TransactionInterfaceType,
} from '@xbcb/shared-types';
import * as changeCase from 'change-case';
import { record as baseRecordFragments } from './shared';

interface TransactionQueriesProps {
  record?: {
    name: RecordType;
    fields?: string;
    fragments?: string;
  };
  transaction: {
    name: TransactionRecordType;
    customInputName?: string;
    unionName?: TransactionUnionType; // if not passed, only the mutation is returned, not the query
    interfaceName?: TransactionInterfaceType;
    input?: boolean;
    fields?: string;
    fragments?: string;
  };
}

export const transactionQueries = ({
  record,
  transaction: {
    name: TRANSACTION_NAME,
    customInputName,
    unionName: TRANSACTION_UNION_NAME,
    interfaceName: TRANSACTION_INTERFACE_NAME,
    input = false,
    fields: transactionFields = '',
    fragments: transactionFragments = '',
  },
}: TransactionQueriesProps) => {
  let recordName;
  let recordFields = '';
  let recordFragments = '';
  let recordIdName;
  if (record) {
    recordFields = record.fields || '';
    recordFragments = record.fragments || '';
    recordName = changeCase.camelCase(record.name.toString());
    recordIdName = `${recordName}Id`;
  }
  const MutationName = changeCase
    .pascalCase(TRANSACTION_NAME.toString().replace('_TRANSACTION', ''))
    .replace(/_/g, '');
  const mutationName = changeCase.camelCase(MutationName);
  const InputName = customInputName
    ? customInputName
    : input
    ? changeCase.pascalCase(MutationName + 'Input')
    : undefined;
  const inputNamedParam = input ? `, $input: ${InputName}!` : '';
  const inputParam = input ? `, input: $input` : '';
  const recordQueryString = record
    ? `
          ${recordName} {
            ...recordFields
            ${recordFields}
          }
  `
    : '';
  const recordIdNamedParam = record ? `, $${recordIdName}: ID!` : '';
  const recordIdParam = record ? `, ${recordIdName}: $${recordIdName}` : '';
  const mutation = gql`
    mutation ${MutationName}($idempotencyKey: String!${recordIdNamedParam}${inputNamedParam}) {
      ${mutationName}(idempotencyKey: $idempotencyKey${recordIdParam}${inputParam}) {
        transaction {
          ...recordFields
          ${recordQueryString}
          ${transactionFields}
        }
      }
    }
    ${baseRecordFragments}
    ${recordFragments}
    ${transactionFragments}
  `;

  if (!TRANSACTION_UNION_NAME) {
    return { mutation };
  }

  const QueryName = changeCase
    .pascalCase(TRANSACTION_UNION_NAME.toString())
    .replace(/_/g, '');
  const queryName = changeCase.camelCase(QueryName);
  if (record && !TRANSACTION_INTERFACE_NAME) {
    throw new Error('interfaceName is reuquired if record is passesd');
  }
  const InterfaceName =
    TRANSACTION_INTERFACE_NAME &&
    changeCase.pascalCase(TRANSACTION_INTERFACE_NAME.toString());
  const interfaceQueryString = record
    ? `
        ... on ${InterfaceName} {
          ${recordName} {
            ...recordFields
            ${recordFields}
          }
        }
`
    : '';
  const query = gql`
    query ${QueryName}($id: ID!) {
      ${queryName}(id: $id) {
        ...recordFields
        ${interfaceQueryString}
        ${transactionFields}
      }
    }
    ${baseRecordFragments}
    ${recordFragments}
    ${transactionFragments}
  `;
  return { query, mutation };
};
