import {
  DocumentAntivirusScanStatus,
  DocumentFileExtension,
  DocumentPasswordStatus,
  DocumentStatus,
} from '@xbcb/document-types';

export const documentFields = {
  antivirusScanStatus: DocumentAntivirusScanStatus.UNSCANNED,
  content: {
    __typename: 'UrlContent',
    downloadLink: null,
    uploadLink: null,
  },
  contentType: 'text/plain',
  disSubmissions: null,
  documentTags: [],
  extension: DocumentFileExtension.TXT,
  fileName: 'placeholder',
  passwordStatus: DocumentPasswordStatus.UNPROTECTED,
  preview: {
    __typename: 'UrlPreview',
    downloadLink: null,
    uploadLink: null,
  },
  sha1: null,
  size: 1000,
  status: DocumentStatus.PENDING_UPLOAD,
  tags: [],
};
