import { isSetHeaderTariff } from './isSetHeaderTariff';

export const isUnitValueApplicableToHts = (htsNumber: string): boolean => {
  const prefixesToExclude = ['99', '98'];
  const numbersToInclude = ['9801001045'];
  return (
    numbersToInclude.includes(htsNumber) ||
    (!isSetHeaderTariff(htsNumber) &&
      !prefixesToExclude.some((number) => htsNumber.startsWith(number)))
  );
};
