import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

const uiLocalizationFeatureName = 'UI_LOCALIZATION_FEATURE';

const UI_LOCALIZATION_FEATURE = new FeatureFlagService([
  {
    feature: uiLocalizationFeatureName,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA],
  },
]);

export { UI_LOCALIZATION_FEATURE, uiLocalizationFeatureName };
