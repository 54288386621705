import { CrudPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const ForwarderUserShipmentPermissionName = {
  ...CrudPermissionName,
};

export default {
  [ObjectType.SHIPMENT]: ForwarderUserShipmentPermissionName,
};
