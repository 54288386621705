import axios from 'axios';
import { get } from 'lodash';
import { message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { GraphQLResponse } from 'apollo-server-types';
import { getRecordFromResponse } from '@xbcb/shared-queries';
import { RecordType } from '@xbcb/shared-types';
import { reportError } from '../reportError';

export const uploadDocuments = async (
  files: RcFile[],
  responses: GraphQLResponse[],
) => {
  try {
    const uploadLinks = responses.map((response) => {
      const record = getRecordFromResponse(
        response,
        'create',
        RecordType.DOCUMENT,
      );
      return get(record, 'content.uploadLink');
    });
    const uploadPromises = [];
    for (let i = 0; i < uploadLinks.length; i++) {
      const file = files[i];
      const formData = new FormData();
      formData.append('file', file);
      uploadPromises.push(
        axios({
          url: uploadLinks[i],
          method: 'PUT',
          data: file,
          headers: {
            'Content-Type': file.type,
          },
        }),
      );
    }
    await Promise.all(uploadPromises);
    message.success(
      `Your document${files.length > 1 ? 's have' : ' has'} been uploaded`,
      5.0,
    );
  } catch (e) {
    reportError(e);
    message.error(
      'There was a problem uploading your documents. Please try again later.',
      5.0,
    );
  }
};
