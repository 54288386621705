import { onError } from '@apollo/client/link/error';
import { Operation, ServerError, ServerParseError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import {
  networkError as networkErrorMessage,
  reportError,
} from '@xbcb/ui-utils';

const getErrorReportingString = (
  error: GraphQLError | Error | ServerError | ServerParseError,
  operation: Operation,
): string =>
  `error message: "${JSON.stringify(error)}"\n
  operation: "${JSON.stringify(operation)}"`;

export const onErrorLink = onError(
  ({ networkError, graphQLErrors, operation }) => {
    if (networkError) {
      networkErrorMessage(networkError, true);
      const errorString = getErrorReportingString(networkError, operation);
      reportError(errorString);
    }
    if (graphQLErrors) {
      graphQLErrors.forEach((graphQLError) => {
        networkErrorMessage(graphQLError, false);
        const errorString = getErrorReportingString(graphQLError, operation);
        reportError(errorString);
      });
    }
  },
);
