import {
  shipperId,
  ein,
  invitingPartyId,
  shipperName,
  subscriptionPlan,
  arCustomerId,
  arOnboardingCountry,
} from './party';
import { columns as externalIntegrationReferenceColumns } from '../externalIntegrationReference';
import { createPartyTable } from './createPartyTable';
import { EsDataType } from '../../enums';

export const forwarder = createPartyTable({
  columns: {
    shipperName,
    shipperId,
    ein,
    subscriptionPlan,
    invitingPartyId,
    arCustomerId,
    arOnboardingCountry,
    scac: {
      default: true,
      name: 'SCAC',
      type: EsDataType.KEYWORD,
    },
    iorName: {
      name: 'IOR name',
      type: EsDataType.TEXT,
    },
    iorId: { name: 'The IOR ID', type: EsDataType.KEYWORD, hidden: true },
    city: {
      name: 'City',
      type: EsDataType.TEXT,
      default: true,
    },
    countryName: {
      name: 'Country',
      type: EsDataType.TEXT,
      default: true,
    },
    ...externalIntegrationReferenceColumns,
  },
});
