import { documentGenerationRequestFragments } from './documentGenerationRequest';

const inBondNotesDocumentGenerationRequestFragment = `
fragment inBondNotesDocumentGenerationRequestFields on InBondNotesDocumentGenerationRequest {
  document {
    id
  }
  modeOfTransport
  inBondNumber
  previousCbpInBondNumber
  bills {
    status
    number
    quantity
  }
  cbpInBondResponses {
    appCode
    actionCode
    number
    billsOfLadingDispositionMessages
    inBondNumber
    header {
      actionCode
      number
    }
    detail {
      dispositionCode
      quantity
      masterBillIssuerCode
      masterBillIssuerSequence
    }
    remarks
    time
  }
  ...documentGenerationRequestFields
  ...recordFields
}
`;

export const inBondNotesDocumentGenerationRequestFragments = `
${inBondNotesDocumentGenerationRequestFragment}
${documentGenerationRequestFragments}
`;
