export enum ReportReconciliationExceptionType {
  STATEMENT_NUMBER_NOT_FOUND = 'STATEMENT_NUMBER_NOT_FOUND',
  STATEMENT_NOT_FOUND = 'STATEMENT_NOT_FOUND',
  TOTAL_DEBIT_STATEMENT_MISMATCH = 'TOTAL_DEBIT_STATEMENT_MISMATCH',
  ENTRY_MISSING_INVOICE = 'ENTRY_MISSING_INVOICE',
  ENTRY_INVOICE_DUTY_MISMATCH = 'ENTRY_INVOICE_DUTY_MISMATCH',
  DEBIT_INVOICE_MISMATCH = 'DEBIT_INVOICE_MISMATCH',
  RELEASE_DATE_MISMATCH = 'RELEASE_DATE_MISMATCH',
  FINAL_DAILY_STATEMENT_DATE_MISMATCH = 'FINAL_DAILY_STATEMENT_DATE_MISMATCH',
  AUTHORIZED_DATE_MISMATCH = 'AUTHORIZED_DATE_MISMATCH',
  FINAL_PERIODIC_MONTHLY_STATEMENT_DATE_MISMATCH = 'FINAL_PERIODIC_MONTHLY_STATEMENT_DATE_MISMATCH',
  PRINT_DATE_INCORRECT = 'PRINT_DATE_INCORRECT',
  AUTHORIZATION_DATE_PRINT_DATE_MISMTACH = 'AUTHORIZATION_DATE_PRINT_DATE_MISMTACH',
  AUTHORIZATION_TIME_FINAL_DAILY_STATEMENT_TIME_MISMATCH = 'AUTHORIZATION_TIME_FINAL_DAILY_STATEMENT_TIME_MISMATCH',
  RELEASE_DATE_FINAL_PERIODIC_MONTHLY_STATEMENT_DATE_MISMATCH = 'RELEASE_DATE_FINAL_PERIODIC_MONTHLY_STATEMENT_DATE_MISMATCH',
}
