export default [
  {
    number: '99038005',
    include: [
      '72081060',
      '72082600',
      '72082700',
      '72083800',
      '72083900',
      '72084060',
      '72085300',
      '72085400',
      '72089000',
      '72253070',
      '72254070',
    ],
  },
  {
    number: '99038006',
    include: [
      '72111915',
      '72111920',
      '72111930',
      '72111945',
      '72111960',
      '72111975',
      '72269170',
      '72269180',
    ],
  },
  {
    number: '99038007',
    include: [
      '72081015',
      '72081030',
      '72082530',
      '72082560',
      '72083600',
      '72083700',
      '72111400',
      '72253030',
    ],
    exclude: ['7211140030', '7211140045'],
  },
  {
    number: '99038008',
    include: [
      '72091500',
      '72091600',
      '72091700',
      '72091815',
      '72091860',
      '72092500',
      '72092600',
      '72092700',
      '72092800',
      '72099000',
      '72107030',
      '72255070',
      '72255080',
      '72259900',
    ],
  },
  {
    number: '99038009',
    include: [
      '72112315',
      '72112320',
      '72112330',
      '72112345',
      '72112360',
      '72112920',
      '72112945',
      '72112960',
      '72119000',
      '72124010',
      '72124050',
      '72269250',
      '72269270',
      '72269280',
      '72269901',
    ],
    exclude: ['7226990110', '7226990130'],
  },
  {
    number: '99038010',
    include: ['72091825'],
  },
  {
    number: '99038011',
    include: [
      '72084030',
      '72085100',
      '72085200',
      '72109010',
      '72111300',
      '72111400',
      '72254030',
      '72255060',
      '72269150',
    ],
    exclude: ['7211140090'],
  },
  {
    number: '99038012',
    include: [
      '72104100',
      '72104900',
      '72107060',
      '72123010',
      '72123030',
      '72123050',
      '72259200',
      '72269901',
    ],
    exclude: ['7210706030', '7210706090', '7226990110', '7226990180'],
  },
  {
    number: '99038013',
    include: [
      '72102000',
      '72106100',
      '72106900',
      '72107060',
      '72109060',
      '72109090',
      '72125000',
      '72126000',
    ],
    exclude: ['7210706030', '7210706060'],
  },
  {
    number: '99038014',
    include: ['72105000'],
  },
  {
    number: '99038015',
    include: ['72101100', '72101200', '72121000'],
  },
  {
    number: '99038016',
    include: [
      '72251100',
      '72251900',
      '72261110',
      '72261190',
      '72261910',
      '72261990',
    ],
  },
  {
    number: '99038017',
    include: ['72103000', '72107060', '72122000', '72259100', '72269901'],
    exclude: ['7210706060', '7210706090', '7226990130', '7226990180'],
  },
  {
    number: '99038018',
    include: [
      '73042330',
      '73042360',
      '73042910',
      '73042920',
      '73042931',
      '73042941',
      '73042950',
      '73042961',
      '73052020',
      '73052040',
      '73052060',
      '73052080',
      '73062910',
      '73062920',
      '73062931',
      '73062941',
      '73062960',
      '73062981',
    ],
  },
  {
    number: '99038019',
    include: [
      '73041910',
      '73041950',
      '73051110',
      '73051150',
      '73051210',
      '73051250',
      '73051910',
      '73051950',
    ],
    exclude: [
      '7304191020',
      '7304191030',
      '7304191045',
      '7304191060',
      '7304195020',
      '7304195050',
    ],
  },
  {
    number: '99038020',
    include: ['73041910', '73041950', '73061910', '73061951'],
    exclude: ['7304191080', '7304195080', '7306191050', '7306195150'],
  },
  {
    number: '99038021',
    include: ['73061910', '73061951'],
    exclude: ['7306191010', '7306195110'],
  },
  {
    number: '99038022',
    include: ['73043900', '73045980', '73063050'],
    exclude: [
      '7304390002',
      '7304390004',
      '7304390006',
      '7304390008',
      '7304390028',
      '7304390032',
      '7304390040',
      '7304390044',
      '7304390052',
      '7304390056',
      '7304390068',
      '7304390072',
      '7304598020',
      '7304598025',
      '7304598035',
      '7304598040',
      '7304598050',
      '7304598055',
      '7304598065',
      '7304598070',
      '7306305010',
      '7306305015',
      '7306305020',
      '7306305035',
    ],
  },
  {
    number: '99038023',
    include: [
      '73049010',
      '73049030',
      '73053120',
      '73053140',
      '73053160',
      '73063030',
      '73065030',
      '73066110',
      '73066130',
      '73066910',
      '73066930',
    ],
    exclude: ['7305316010'],
  },
  {
    number: '99038024',
    include: [
      '73043130',
      '73043160',
      '73043900',
      '73045110',
      '73045150',
      '73045910',
      '73045960',
      '73045980',
      '73049050',
      '73049070',
      '73063010',
      '73063050',
      '73065010',
      '73065050',
      '73066150',
      '73066170',
      '73066950',
      '73066970',
    ],
    exclude: [
      '7304316010',
      '7304390002',
      '7304390004',
      '7304390006',
      '7304390008',
      '7304390016',
      '7304390020',
      '7304390024',
      '7304390036',
      '7304390048',
      '7304390062',
      '7304390076',
      '7304390080',
      '7304515005',
      '7304515015',
      '7304515045',
      '7304598010',
      '7304598015',
      '7304598030',
      '7304598045',
      '7304598060',
      '7304598080',
      '7306305010',
      '7306305025',
      '7306305028',
      '7306305032',
      '7306305040',
      '7306305055',
      '7306305085',
      '7306305090',
      '7306505010',
      '7306617030',
      '7306697030',
    ],
  },
  {
    number: '99038025',
    include: [
      '73043160',
      '73043900',
      '73045150',
      '73045920',
      '73063050',
      '73065050',
    ],
    exclude: [
      '7304316050',
      '7304390016',
      '7304390020',
      '7304390024',
      '7304390028',
      '7304390032',
      '7304390036',
      '7304390040',
      '7304390044',
      '7304390048',
      '7304390052',
      '7304390056',
      '7304390062',
      '7304390068',
      '7304390072',
      '7304390076',
      '7304390080',
      '7304515005',
      '7304515060',
      '7306305015',
      '7306305020',
      '7306305025',
      '7306305028',
      '7306305032',
      '7306305035',
      '7306305040',
      '7306305055',
      '7306305085',
      '7306305090',
      '7306505030',
      '7306505050',
      '7306505070',
    ],
  },
  {
    number: '99038026',
    include: ['73053910', '73053950'],
  },
  {
    number: '99038027',
    include: ['73045150', '73059010', '73059050', '73069010', '73069050'],
    exclude: ['7304515015', '7304515045', '7304515060'],
  },
  {
    number: '99038028',
    include: ['72191300', '72191400', '73192300', '72192400'],
  },
  {
    number: '99038029',
    include: ['72201210', '72201250'],
  },
  {
    number: '99038030',
    include: ['72191100', '72191200'],
  },
  {
    number: '99038031',
    include: ['72193200', '72193300', '72193400', '72193500', '72199000'],
  },
  {
    number: '99038032',
    include: [
      '72202010',
      '72202060',
      '72202070',
      '72202080',
      '72202090',
      '72209000',
    ],
  },
  {
    number: '99038033',
    include: ['72193100'],
    exclude: ['7219310050'],
  },
  {
    number: '99038034',
    include: ['72230010', '72230050', '72230090'],
  },
  {
    number: '99038035',
    include: [
      '73044130',
      '73044160',
      '73044900',
      '73053160',
      '73064010',
      '73064050',
      '73066170',
      '73066970',
    ],
    exclude: ['7305316090', '7306617060', '7306697060'],
  },
  {
    number: '99038036',
    include: ['73041100', '73061100'],
  },
  {
    number: '99038037',
    include: ['72222000', '72223000'],
  },
  {
    number: '99038038',
    include: ['72210000', '72221100', '72221900', '72224030'],
    exclude: [
      '7221000017',
      '7221000018',
      '7221000030',
      '7222403025',
      '7222403045',
    ],
  },
  {
    number: '99038039',
    include: ['72189100', '72189900'],
  },
  {
    number: '99038040',
    include: [
      '73042200',
      '73042430',
      '73042440',
      '73042460',
      '73062130',
      '73062140',
      '73062180',
    ],
  },
  {
    number: '99038041',
    include: ['72181000'],
  },
  {
    number: '99038042',
    include: ['72192100', '72192200', '72193100', '72201100'],
    exclude: ['7219310010'],
  },
  {
    number: '99038043',
    include: ['72210000'],
    exclude: ['7221000005', '7221000045', '7221000075'],
  },
  {
    number: '99038044',
    include: ['72224030', '72224060'],
    exclude: ['7222403065', '7222403085'],
  },
  {
    number: '99038045',
    include: [
      '72163100',
      '72163200',
      '72163300',
      '72164000',
      '72165000',
      '72169900',
      '72287030',
      '72287060',
    ],
    exclude: ['7228703060', '7228703081'],
  },
  {
    number: '99038046',
    include: [
      '72139130',
      '92139145',
      '72139160',
      '72139900',
      '72272000',
      '72279060',
    ],
    exclude: [
      '7213990060',
      '7227200080',
      '7227906005',
      '7227906010',
      '7227906040',
      '7227906090',
    ],
  },
  {
    number: '99038047',
    include: [
      '72171010',
      '72171020',
      '72171030',
      '72171040',
      '72171050',
      '72171060',
      '72171070',
      '72171080',
      '72171090',
      '72172015',
      '72172030',
      '72172045',
      '72172060',
      '72172075',
      '72173015',
      '72173030',
      '72173045',
      '72173060',
      '72173075',
      '72179010',
      '72179050',
      '72292000',
      '72299010',
      '72299050',
      '72299090',
    ],
  },
  {
    number: '99038048',
    include: [
      '72132000',
      '72139900',
      '72141000',
      '72143000',
      '72149100',
      '72149900',
      '72159010',
      '72272000',
      '72279060',
      '72282010',
      '72283080',
      '72284000',
      '72286060',
      '72288000',
    ],
    exclude: [
      '7213990030',
      '7213990090',
      '7227200030',
      '7227906020',
      '7227906030',
      '7227906035',
      '7228308010',
    ],
  },
  {
    number: '99038049',
    include: [
      '72151000',
      '72155000',
      '72159030',
      '72159050',
      '72282050',
      '72285050',
      '72286080',
    ],
  },
  {
    number: '99038050',
    include: ['72161000', '72162100', '72162200', '72287030'],
    exclude: ['7228703010', '7228703020', '7228703041'],
  },
  {
    number: '99038051',
    include: ['72131000', '72142000', '72283080'],
    exclude: [
      '7228308005',
      '7228308015',
      '7228308041',
      '7228308045',
      '7228308070',
    ],
  },
  {
    number: '99038052',
    include: ['73011000'],
  },
  {
    number: '99038053',
    include: ['73024000', '73029010', '73029090'],
  },
  {
    number: '99038054',
    include: ['73021010'],
    exclude: ['7302101010', '7302101035', '7302101065', '7302101075'],
  },
  {
    number: '99038055',
    include: ['73021010', '73021050'],
    exclude: ['7302101015', '7302101025', '7302101045', '7302101055'],
  },
  {
    number: '99038056',
    include: [
      '72241000',
      '72249000',
      '72253011',
      '72253051',
      '72254011',
      '72254051',
      '72255011',
      '72262000',
      '72269105',
      '72269115',
      '72269125',
      '72269210',
      '72269230',
      '72271000',
      '72279010',
      '72279020',
      '72281000',
      '72283020',
      '72283040',
      '72283060',
      '72285010',
      '72286010',
      '72299005',
    ],
    exclude: [
      '7224100005',
      '7224100075',
      '7224900005',
      '722400045',
      '7224900055',
      '7224900065',
      '7224900075',
    ],
  },
  {
    number: '99038057',
    include: ['72071100', '72071200', '72071900', '72072000', '72249000'],
    exclude: ['7224900015', '7224900025', '7224900035'],
  },
  {
    number: '99038058',
    include: ['72061000', '72069000', '72241000'],
    exclude: ['7224100045'],
  },
];
