/**
 * Represents the document template status
 */
export enum DocumentTemplateStatus {
  ACTIVATED = 'ACTIVATED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  DEACTIVATED = 'DEACTIVATED',
  SUBMITTED = 'SUBMITTED',
}
