export const billingDetailsFields = `
fragment billingDetailsFields on BillingDetails {
  status
  disbursement
  markups {
    type
    value {
      value
      currency
    }
  }
  customFees {
    type
    value {
      value
      currency
    }
  }
  customFeeConfig {
    singleEntryBond {
      minimum
      rate
    }
    disbursement {
      rate
    }
  }
  trialEndTime
  creditDetails {
    creditLimit {
      value
      currency
    }
  }
  paymentMethod 
  subscriptionPlan
  subscriptionPaymentPeriod
  workOrderInvoiceConfiguration {
    usConsumptionEntry {
      exemptFromEntryFee
      trigger
    }
    usIsf {
      exemptFromIsfFee
    }
  }
  accountingSystemCustomerReferences {
    type
    value
  }
  invoiceConfiguration {
    overridePaymentTerms
  }
  accountingDocs
  accountingPointofContacts {
    accountingCcEmails
    address {
      address
      address2
      city
      postalCode
      stateCode
      countryCode
    }
    pointOfContact {
      name
      title
      email
      phone {
        extension
        country
        number
      }
      user {
        id
      }
    }
  }
}`;
