import {
  OperatorUserNotificationType as OperatorUserNotifications,
  UserNotificationType,
} from '@xbcb/notification-types';
import { UserNotificationMethod } from '../base';

export const OperatorUserNotificationMethod = {
  ...UserNotificationMethod,
};
export const OperatorUserNotificationType = {
  ...UserNotificationType,
  ...OperatorUserNotifications,
};
