export enum OperatingRegion {
  // Denmark
  DE = 'DE',
  // Great Britain
  GB = 'GB',
  // Netherlands
  NL = 'NL',
  // France
  FR = 'FR',
  // United States
  US = 'US',
}
