import { partyFragments } from './party';
const globalConsigneeFragment = `
fragment globalConsigneeFields on GlobalConsignee {
  ...recordFields
  ...partyFields
  identificationIssuingCountry
}
`;

export const globalConsigneeFragments = `
${globalConsigneeFragment}
${partyFragments}
`;
