import { shipperId, ein, feiNumber, firmsCode, shipperName } from './party';
import { createPartyTable } from './createPartyTable';
import { EsDataType } from '../../enums';

export const facility = createPartyTable({
  columns: {
    shipperName,
    shipperId,
    firmsCode,
    ein,
    feiNumber,
    city: {
      default: true,
      name: 'City',
      type: EsDataType.TEXT,
    },
  },
});
