// Push value to a given array only if the value is defined.
export const arrayPushDefined = (array: unknown[], value: unknown): void => {
  if (value) {
    array.push(value);
  }
};

// Add value to a given set only if the value is defined.
export const setAddDefined = (set: Set<unknown>, value: unknown): void => {
  if (value) {
    set.add(value);
  }
};
