import { partyFragments } from './party';

const supplierFragment = `
fragment supplierFields on Supplier {
  ...recordFields
  ...partyFields
  complianceDetails {
    us {
      mid
      feiNumber
    }
  }
}
`;

export const usConsumptionEntrySupplierFields = `
  ...addressesFields
  name
  complianceDetails {
    us {
      mid
    }
  }
  pointOfContact {
    name
    email
    phone {
      country
      number
      extension
    }
  }
`;

export const usPostSummaryCorrectionSupplierFields = `
  ...addressesFields
  name
  complianceDetails {
    us {
      mid
    }
  }
`;

export const supplierFragments = `
${supplierFragment}
${partyFragments}
`;

export const supplierFields = supplierFragments;
