import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import { Input, Button, Tabs, Upload, message } from 'antd';
import SignaturePad from 'signature_pad/dist/signature_pad.js';
import {
  allowedSignCharacters,
  reportError,
  signatureFontSize,
} from '@xbcb/ui-utils';
import rasterizehtml from 'rasterizehtml';
import {
  StyledFontHomemadeApple,
  StyledSignFormSubmit,
  StyledTabs,
} from './styles';
import { DocumentSignatureMethod } from '@xbcb/document-types';

const TabPane = Tabs.TabPane;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const capHeightAndWidth = (width, height, maxWidth, maxHeight) => {
  const heightScaleFactor = maxHeight / height;
  const widthScaleFactor = maxWidth / width;

  if (heightScaleFactor >= 1 && widthScaleFactor >= 1) {
    return { width, height };
  } else if (heightScaleFactor < widthScaleFactor) {
    return { width: width * heightScaleFactor, height: maxHeight };
  } else {
    return { width: maxWidth, height: height * widthScaleFactor };
  }
};

const getImageTypeFromImageData = (imgData) => {
  // expecting imgData to look something like this: "data:image/png;base64,iVBORw0...""
  return imgData?.match(/data:(.*);/)?.[1] ?? 'image/png';
};

const scaleDownImageData = (imgData, maxWidth, maxHeight) => {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.onload = (e) => {
      const { width, height } = capHeightAndWidth(
        e.target.width,
        e.target.height,
        maxWidth,
        maxHeight,
      );
      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(e.target, 0, 0, width, height);

      const imageType = getImageTypeFromImageData(imgData);
      const newImgData = canvas.toDataURL(imageType);

      resolve(newImgData);
    };

    img.src = imgData;
  });
};

class SignFormContents extends Component {
  state = {
    method: false,
    typed: '',
    tab: DocumentSignatureMethod.TYPE,
    uploadedImage: null,
  };

  clearSignaturePad = () => {
    this.signaturePad.clear();
  };

  initSignaturePad = () => {
    const canvas = document.querySelector('canvas#signature');
    if (canvas) this.signaturePad = new SignaturePad(canvas);
  };

  handleTabChange = (tab) => {
    this.setState({ tab });
    if (tab === DocumentSignatureMethod.DRAW && !this.signaturePad) {
      this.initSignaturePad();
    } else if (tab === DocumentSignatureMethod.TYPE) {
      document.querySelector('input#sign-form-typed-input').focus();
    }
  };

  handleTypedChange = (e) => {
    const sig = e.target.value;
    this.setState({ typed: sig });
    const fontSize = signatureFontSize(sig);
    document.querySelector('.sign-form-typed span').style['font-size'] =
      fontSize;
    document.querySelector('.sign-form-typed span').style['font-family'] =
      'Homemade Apple';
  };

  handleFileChange = (file, fileList) => {
    getBase64(file, async (uploadedImage) => {
      const maxWidth = 440;
      const maxHeight = 118;
      uploadedImage = await scaleDownImageData(
        uploadedImage,
        maxWidth,
        maxHeight,
      );
      this.setState({ uploadedImage });
    });
  };

  handleSubmit = async () => {
    if (this.state.tab === DocumentSignatureMethod.DRAW) {
      const imageData = document
        .querySelector('canvas#signature')
        .toDataURL('image/png');
      this.props.sign(imageData, this.state.tab);
    } else if (this.state.tab === DocumentSignatureMethod.TYPE) {
      if (!this.state.typed || !this.state.typed.length) {
        message.error('Please type your signature');
        return;
      }
      if (
        this.state.typed
          .split('')
          .some((c) => !allowedSignCharacters.includes(c))
      ) {
        message.error(
          'Your signature contains unsupported characters, please try Draw or Upload',
        );
        return;
      }
      const signFormDiv = document.querySelector('div.sign-form-typed div');
      const canvas = document.createElement('canvas');
      canvas.width = 942;
      canvas.height = 118;
      try {
        await rasterizehtml.drawDocument(signFormDiv, canvas);
        const imageData = canvas.toDataURL('image/png');
        this.props.sign(imageData, this.state.tab, this.state.typed);
      } catch (e) {
        reportError(e);
        message.error(
          `Sorry, this method doesn't work on your browser, please try Draw or Upload`,
        );
      }
    } else if (this.state.tab === DocumentSignatureMethod.UPLOAD) {
      this.props.sign(this.state.uploadedImage, this.state.tab);
    }
  };

  render() {
    const { isLoading } = this.props;
    return (
      <>
        <StyledTabs
          defaultActiveKey={DocumentSignatureMethod.TYPE}
          animated={false}
          onChange={this.handleTabChange}
        >
          <TabPane
            forceRender={true}
            tab="Draw"
            key={DocumentSignatureMethod.DRAW}
          >
            <Button
              shape="circle"
              className="sign-form-clear"
              icon={<CloseOutlined />}
              onClick={this.clearSignaturePad}
            />
            <canvas id="signature" width="440" height="118" />
          </TabPane>
          <TabPane
            forceRender={true}
            tab="Type"
            key={DocumentSignatureMethod.TYPE}
          >
            <div className="sign-form-typed">
              <div
                style={{
                  padding: '0 12px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  lineHeight: '120px',
                  textAlign: 'left',
                }}
              >
                <StyledFontHomemadeApple>
                  <span
                    className="sign-form-typed-text"
                    style={{
                      fontSize: '28px',
                      fontFamily: 'Homemade Apple !important',
                    }}
                  >
                    {this.state.typed}
                  </span>
                </StyledFontHomemadeApple>
              </div>
            </div>
            <Input
              id="sign-form-typed-input"
              placeholder="Type name here"
              onChange={this.handleTypedChange}
              onPressEnter={this.handleSubmit}
            />
          </TabPane>
          <TabPane
            forceRender={true}
            tab="Upload"
            key={DocumentSignatureMethod.UPLOAD}
          >
            <Upload.Dragger
              accept="image/png, image/jpeg"
              beforeUpload={(file, fileList) => {
                this.handleFileChange(file, fileList);
                return false;
              }}
              multiple={false}
              showUploadList={false}
              className="sign-form-upload"
            >
              {this.state.uploadedImage ? (
                <img
                  src={this.state.uploadedImage}
                  alt=""
                  className="sign-form-uploaded-image"
                />
              ) : (
                <div>
                  <EditOutlined />
                  <p>Click or drag an image to upload your signature</p>
                </div>
              )}
            </Upload.Dragger>
          </TabPane>
        </StyledTabs>
        <StyledSignFormSubmit>
          <Button
            type="primary"
            className="sign-form-submit-button"
            onClick={this.handleSubmit}
            loading={isLoading ? isLoading : false}
          >
            Sign
          </Button>
        </StyledSignFormSubmit>
      </>
    );
  }
}

SignFormContents.propTypes = {
  isLoading: PropTypes.bool,
  sign: PropTypes.func.isRequired,
};

export default SignFormContents;
