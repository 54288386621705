import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

const subscriptionsDisabledFeatureName = 'SUBSCRIPTIONS_DISABLED_FEATURE';

const SUBSCRIPTIONS_DISABLED_FEATURE = new FeatureFlagService([
  {
    feature: subscriptionsDisabledFeatureName,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA, Stage.PROD],
    startTime: {
      [Stage.ALPHA]: '2023-11-01',
      [Stage.BETA]: '2023-11-01',
      [Stage.GAMMA]: '2023-11-01',
      [Stage.PROD]: '2023-12-04',
    },
  },
]);

export { SUBSCRIPTIONS_DISABLED_FEATURE, subscriptionsDisabledFeatureName };
