export enum AssignmentWorkspaceTab {
  TEAM_QUEUE = 'Team queue',
  MY_QUEUE = 'My queue',
  OPEN_ESCALATIONS = 'Open Escalations',
}

export enum AssignmentWorkspaceTaskTab {
  OPEN = 'Open',
  COMPLETED = 'Completed',
  ESCALATED_TO_ME = 'To Me',
  ESCALATED_TO_MY_TEAM = 'To My Team(s)',
}
