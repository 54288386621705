import { gql } from '@apollo/client';
import { usIorFragments } from '@xbcb/party-queries';
import { iscbpReg2ChangesFeatureEnabled } from '../featureFlags';

export const UPDATE_TEMPLATE_DOCUMENT_SIGN_REQUEST_WITH_SIGNATURE = gql`
  mutation UpdateDocumentSignRequestWithSignature(
    $version: Int!
    $id: ID!
    $signature: DocumentSignatureInput!
    $signerName: String!
    $signerTitle: String!
    ${
      iscbpReg2ChangesFeatureEnabled
        ? '$signerBirthDate: DateTime__patched_over_by_INLT'
        : ''
    }
    $additionalFields: JSONObject!
  ) {
    updateTemplateDocumentSignRequestWithSignature(
      version: $version
      id: $id
      signature: $signature
      signerName: $signerName
      signerTitle: $signerTitle
      ${
        iscbpReg2ChangesFeatureEnabled
          ? 'signerBirthDate: $signerBirthDate'
          : ''
      }
      additionalFields: $additionalFields
    ) {
      record {
        id
      }
    }
  }
`;

export const GET_TEMPLATE_DOCUMENT_SIGN_REQUEST = gql`
  query GetTemplateDocumentSignRequest($id: ID!) {
    templateDocumentSignRequest(id: $id) {
      id
      version
      status
      deleted {
        time
      }
      operator {
        id
      }
      expireTime
      template {
        id
        version
        html
        jsonHtml
        templateType
      }
      additionalFields
      tags {
        key
        value
      }
      signature {
        method
      }
      documentTags
    }
  }
`;

export const GET_US_IOR = gql`
  query GetUsIor($id: ID!) {
    usIor(id: $id) {
      ...usIorFields
      ${iscbpReg2ChangesFeatureEnabled ? 'businessWebsite' : ''}
    }
  }
  ${usIorFragments}
`;
