import { partyFragments } from './party';

export const operatorFragment = `
fragment operatorFields on Operator {
  ...recordFields
  ...partyFields
}
`;

export const operatorFragments = `
${operatorFragment}
${partyFragments}
`;
