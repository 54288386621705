import React, { ReactNode, useState } from 'react';
import { get } from 'lodash';
import {
  getCodes,
  isValidCBPNumber,
  validEINPrefixes,
  formatString,
  safeGetMessage,
} from '@xbcb/ui-utils';
import { Input, Modal } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { timeout } from '@xbcb/js-utils';
import { InlineTooltip } from '@xbcb/feedback-components';
import { UsIorNumberType } from '@xbcb/party-types';
import { conformToMask } from 'text-mask-core';
import {
  CssSize,
  NamePath,
  createDataCyValue,
  DataCyPrefix,
  DataCySuffix,
} from '@xbcb/ui-types';
import { AccountType } from '@xbcb/shared-types';
import { masks, labels, IorTypes, secureIorNumber } from './helper';
import { StyledFormItem, StyledDiv } from './styles';
import { useBundle } from '@amzn/react-arb-tools';

interface PropsForIORNumber {
  form: FormInstance;
  type: IorTypes;
  isUS?: boolean;
  accountType?: AccountType;
  isIndividual?: boolean;
  localNamePath: NamePath;
  fullNamePath: NamePath;
  hideTooltip?: boolean;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  $inline?: boolean;
  $itemSize?: CssSize;
  dataCySuffix?: DataCySuffix;
}

const IORNumber: React.FC<PropsForIORNumber> = ({
  form,
  type,
  isUS,
  accountType,
  isIndividual,
  localNamePath,
  fullNamePath,
  required,
  readOnly,
  hideTooltip,
  disabled,
  $inline,
  $itemSize = CssSize.TINY,
  dataCySuffix,
}) => {
  const [iorNumberBundle] = useBundle(
    'components.formItemComponents.IORNumber',
  );
  const [previousIORConformedValue, setPreviousIORConformedValue] =
    useState('');
  const handleIORChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (!e || !e.target) {
      return e;
    }

    let mask;
    if (masks[type]) {
      mask = masks[type];
    } else if (!isUS) {
      mask = masks.CBP;
    } else if (isIndividual) {
      mask = masks.SSN;
    } else {
      mask = masks.EIN;
    }

    // Changed this to currentTarget for ts to be happy
    const value = e.currentTarget.value;
    const conformedValue = conformToMask(value, mask, {
      guide: false,
      previousConformedValue: previousIORConformedValue,
    });

    const newVal =
      typeof conformedValue.conformedValue === 'string'
        ? conformedValue.conformedValue.toUpperCase()
        : conformedValue.conformedValue;

    setPreviousIORConformedValue(newVal);

    return newVal;
  };

  const onBlur = async () => {
    await timeout(1000);
    formatString(form, fullNamePath);
    const val = form.getFieldValue(fullNamePath);
    if (val && type === UsIorNumberType.EIN) {
      const prefix = val.substr(0, 2);
      if (!validEINPrefixes.includes(prefix)) {
        form.setFields([{ name: fullNamePath, value: undefined }]);
        Modal.error({
          title: safeGetMessage(iorNumberBundle, 'invalid_ein'),
          content: (
            <div>
              {safeGetMessage(iorNumberBundle, 'valid_prefixes_error_message', {
                val,
                a: (chunk: ReactNode) => (
                  <a
                    href="https://www.irs.gov/businesses/small-businesses-self-employed/how-eins-are-assigned-and-valid-ein-prefixes"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {chunk}
                  </a>
                ),
              })}
            </div>
          ),
        });
      }
      // We know the first 3 letters would be "NN-" where NN is any two numbers
      // We want to ensure the suffix is correct now. Which means it should
      // either be length 7 (and party transform will add `00`) to the end of
      // it, or length 9.
      const suffix = val.substr(3);
      if (suffix.length !== 7 && suffix.length !== 9) {
        Modal.error({
          title: safeGetMessage(iorNumberBundle, 'invalid_ein'),
          content: (
            <div>
              {safeGetMessage(iorNumberBundle, 'correct_tax_id_format', {
                val: val,
              })}
            </div>
          ),
        });
      }
    } else if (val && type === UsIorNumberType.CBP) {
      const cbpPortCodes = get(getCodes(), 'CBP.port');
      if (!isValidCBPNumber(val, cbpPortCodes)) {
        form.setFields([{ name: fullNamePath, value: undefined }]);
        Modal.error({
          title: safeGetMessage(iorNumberBundle, 'invalid_cbp_assigned_number'),
          content: (
            <div>
              {safeGetMessage(
                iorNumberBundle,
                'invalid_cbp_assigned_number_message',
                { val: val },
              )}
            </div>
          ),
        });
      }
    }
  };

  const label = labels[type]
    ? type
    : !isUS
    ? UsIorNumberType.CBP
    : isIndividual
    ? UsIorNumberType.SSN
    : UsIorNumberType.EIN;
  // const iorPlaceholder = placeholders[type]
  //   ? placeholders[type]
  //   : !isUS
  //     ? placeholders.cbp
  //     : isIndividual
  //       ? placeholders.ssn
  //       : placeholders.ein;
  return (
    <StyledDiv $inline={$inline}>
      <StyledFormItem
        $itemSize={$itemSize}
        label={safeGetMessage(
          iorNumberBundle,
          label.concat('_label').toLowerCase(),
        )}
        name={localNamePath}
        rules={[
          {
            required,
            message: ' ',
            pattern:
              type === UsIorNumberType.EIN
                ? // Can be format: 12-1234567 (then party transform will add 00 to
                  // the end) or 12-123456789 but NOT 12-12345678
                  /^([0-9]{2}-[0-9]{7}|[0-9]{2}-[0-9]{7}[A-Z0-9]{2})$/
                : undefined,
          },
        ]}
        $readOnly={readOnly}
        debounce
        getValueFromEvent={handleIORChange}
        data-cy={createDataCyValue(DataCyPrefix.IOR_NUMBER_VALUE, dataCySuffix)}
      >
        {disabled || readOnly ? (
          <p>
            {secureIorNumber({
              iorNumber: form.getFieldValue(fullNamePath),
              accountType,
              iorNumberType: type,
            })}
          </p>
        ) : (
          <Input disabled={disabled} onBlur={onBlur} />
        )}
      </StyledFormItem>
      {!hideTooltip ? (
        <InlineTooltip
          spaceTop
          title={safeGetMessage(
            iorNumberBundle,
            label.concat('_tooltip').toLowerCase(),
          )}
        />
      ) : null}
    </StyledDiv>
  );
};

export default IORNumber;
