import { UserRecordType } from '@xbcb/shared-types';
import {
  operatorId,
  EsDataType,
  recordFields,
  Columns,
  TableOptions,
  expirationTime,
} from '../..';
import { createTableConfig } from '../createTableConfig';

export const sharedUserColumns = {
  operatorId,
  expirationTime,
  name: { default: true, name: 'Name', type: EsDataType.TEXT },
  email: {
    default: true,
    name: 'Email',
    type: EsDataType.TEXT,
  },
  title: {
    default: true,
    name: 'Title',
    type: EsDataType.TEXT,
  },
  loggedIn: {
    default: true,
    name: 'Logged In',
    type: EsDataType.BOOLEAN,
  },
  mfaEnabled: {
    default: true,
    name: 'MFA Enabled',
    type: EsDataType.BOOLEAN,
  },
  teamId: {
    hidden: true,
    name: 'Team ID',
    type: EsDataType.KEYWORD,
  },
  teamName: {
    default: true,
    name: 'Team',
    type: EsDataType.TEXT,
  },
  ...recordFields({ createdTime: { name: 'Invited', default: true } }),
};

export const createUserTableConfig = <TColumns extends Columns>({
  tableOptions = {},
  columns,
}: {
  tableOptions?: TableOptions;
  columns?: TColumns;
}) =>
  createTableConfig({
    columns: {
      ...sharedUserColumns,
      ...columns,
    },
    tableOptions,
  });

export const user = createUserTableConfig({
  tableOptions: { recordTypes: Object.values(UserRecordType) },
});
