export enum ClaimClientId {
  CBMS = 'CBMS',
}

export enum ClaimRequesterId {
  INLT = 'INLT',
}
export enum ClaimType {
  LSP_OFF_MANIFEST = 'LSP_OFF_MANIFEST',
}
