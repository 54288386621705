import pluralize from 'pluralize';
import { paramCase } from 'change-case';
import { RecordType, ObjectType, UserRecordType } from '@xbcb/shared-types';
import { constantCase } from '@xbcb/js-utils';
import { app, getEnv } from './environment';

export const isRecordType = (
  maybeRecordType: string,
): maybeRecordType is keyof typeof RecordType => {
  return RecordType[maybeRecordType as keyof typeof RecordType] !== undefined;
};

export const isObjectType = (
  maybeObjectType: string,
): maybeObjectType is keyof typeof ObjectType => {
  return ObjectType[maybeObjectType as keyof typeof ObjectType] !== undefined;
};

export const isUserType = (id?: string) => {
  if (!id) return false;
  const recordType = getRecordType(id);
  if (!recordType) return false;
  return Object.keys(UserRecordType).includes(recordType);
};

// These are the "shipment WO types", should be checked via
// `isShipmentWorkOrderType` below rather than directly using this array
const shipmentWorkOrderTypes: RecordType[] = [
  RecordType.US_CONSUMPTION_ENTRY,
  RecordType.US_ISF,
  RecordType.US_POST_SUMMARY_CORRECTION,
  RecordType.US_IN_BOND,
  RecordType.DELIVERY_ORDER,
  RecordType.US_TYPE86_ENTRY,
];

// Takes a record ID and determines whether or not the record type is a
// shipment WO type. See the array above for which WO types these are.
export const isShipmentWorkOrderType = (id?: string): boolean => {
  if (!id) return false;
  const recordType = getRecordType(id);
  if (!recordType) return false;
  return shipmentWorkOrderTypes.includes(recordType);
};

export const getRecordType = (id: string) => {
  if (!id) return;
  const maybeRecordType = constantCase(id.split('_')[0]);
  if (isRecordType(maybeRecordType)) {
    return RecordType[maybeRecordType];
  } else {
    return;
  }
};

// Gives a CBMS website URL based on the record ID
export const createRecordCbmsUrl = (id: string) => {
  const recordType = getRecordType(id);
  if (!recordType) return;

  const { stage } = getEnv();
  const baseUrl = app[stage];
  const formattedRecordType = paramCase(pluralize(recordType));
  return `${baseUrl}/${formattedRecordType}/${id}`;
};
