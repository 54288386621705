/* eslint-disable no-useless-escape */
import { conformToMask } from 'text-mask-core';

export const getExclusionNumber = (type) => (len) => (e) => {
  // Now we can have STL or SPR, and ALU or ALR, so we cant hardcode the prefix
  // const prefix =
  //   type === 'Steel' ? 'STL' : type === 'Aluminum' ? 'ALU' : undefined;
  if (!e) {
    return e || undefined;
  }
  if (!e.target) {
    return e.target || undefined;
  }
  const regex = [/[A-Z]/, /[A-Z]/, /[A-Z]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  const value = e.target.value.toUpperCase();
  const conformedValue = conformToMask(value, regex, { guide: false });

  return conformedValue.conformedValue;
};

export const getRegex = (reg, len, e) => {
  if (!e) {
    return e || undefined;
  }
  if (!e.target) {
    return e.target || undefined;
  }
  const regex = [];
  for (let i = 0; i < len; i++) {
    regex.push(reg);
  }
  const value = e.target.value.toUpperCase();
  const conformedValue = conformToMask(value, regex, { guide: false });

  return conformedValue.conformedValue;
};

export const getNumber = (len) => (e) => getRegex(/\d/, len, e);
export const getAN = (len) => (e) => getRegex(/[A-Z0-9]/, len, e);
export const maxLength = (length) => (e) => e.target.value.slice(0, length);
export const getInvoiceNumber = (len) => (e) =>
  getRegex(/[\/\-A-Z0-9]/, len, e);

// pretty broad regex here, for when you don't know the type of IOR number.
export const getIORNumber = (len) => (e) => getRegex(/[A-Z0-9-]/, 12, e);

export const getDunsNumber = (e) => {
  if (!e) {
    return e || undefined;
  }
  if (!e.target) {
    return e.target || undefined;
  }
  const regex = [
    /[U0-9]/,
    /[N0-9]/,
    /[K0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ];

  const value = e.target.value.toUpperCase();
  const conformedValue = conformToMask(value, regex, { guide: false });
  return conformedValue.conformedValue || undefined;
};
