import { recordFragments } from '@xbcb/shared-queries';

const tableFilterFragment = `fragment tableFilterFields on TableFilter{
  id
  name
  operator {
    id
    name
  }
  recordTable
  tableFilterCriteria {
    field
    searchCriteria {
      values {
        value
        rangeValue
        minValue
        maxValue
      }
      operator
    }
  }
  visibleColumns
  tags {
    key
	  value
  }
  ...recordFields
}`;

export const tableFilterFragments = `
${tableFilterFragment}
${recordFragments}
`;
