import { euIorActivationsFields } from './euIorActivations';
import { partyFragments } from './party';

export const deIorFragment = `
fragment deIorFields on DeIor {
  ...recordFields
  ...partyFields
  vatNumber
  eori
  industry
  businessStructure
  ${euIorActivationsFields}
}
`;

export const deIorFragments = `
${deIorFragment}
${partyFragments}
`;
