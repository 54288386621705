import { Product, Tag } from '@xbcb/api-gateway-client';

export type ComplianceRefToProductMap = {
  [key: string]: Product;
};

export const createShipmentTags = ({
  shipmentId,
  clientIdentifier,
}: {
  shipmentId: string;
  clientIdentifier?: string;
}) => {
  const tags: Tag[] = [
    {
      key: 'shipmentId',
      value: shipmentId,
    },
  ];
  if (clientIdentifier) {
    tags.push({
      key: 'SHIPMENT_CLIENT_IDENTIFIER',
      value: clientIdentifier,
    });
  }
  return tags;
};
