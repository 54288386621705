/**
 * RecordType Permission Dependency.
 */
export const permissionDependency = {
  shipment: [
    'product',
    'usCustomsBroker',
    'supplier',
    'facility',
    'forwarder',
    'trucker',
    'shipper',
    'consignee',
    'company',
  ],
  product: ['shipper', 'supplier', 'document', 'company'],
  supplier: ['shipper', 'company'],
  trucker: ['shipper', 'company'],
  forwarder: ['shipper', 'company'],
  facility: ['shipper', 'company'],
  invoice: ['shipper', 'shipment', 'classification', 'company'],
};
