import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

const workOrderCloningFeatureName = 'WORK_ORDER_CLONING_FEATURE';

export const WorkOrderCloningFeatures = {
  WORK_ORDER_CLONING_FEATURE: workOrderCloningFeatureName,
};

const WORK_ORDER_CLONING_FEATURE = new FeatureFlagService([
  {
    feature: workOrderCloningFeatureName,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA, Stage.PROD],
  },
]);

export { WORK_ORDER_CLONING_FEATURE, workOrderCloningFeatureName };
