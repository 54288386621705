import moment from 'moment';
import { FeatureFlagContext, FeatureFlag } from './interfaces';

export class FeatureFlagService {
  private currentFeatureFlagResult: boolean;
  private currentFeature?: FeatureFlag;
  private currentFeatureFlagContext?: FeatureFlagContext;

  constructor(private readonly flags: FeatureFlag[]) {
    this.flags = flags;
    this.currentFeatureFlagResult = true;
  }

  private withContext(context: FeatureFlagContext) {
    this.currentFeatureFlagContext = context;
    return this;
  }

  private forFeature(feature: FeatureFlag) {
    this.currentFeature = feature;
    this.currentFeatureFlagResult = this.currentFeature.enabled;
    return this;
  }

  // In case of Lists this function just returns back the context if no values are present
  // Note: In future if we need to handle any context values which are not a list
  // We might need a new function which will handle similarly
  private isEnabledForList(
    key: keyof FeatureFlagContext,
    list?: Array<string | number>,
  ) {
    const value = this.currentFeatureFlagContext?.[key];
    if (value) {
      this.currentFeatureFlagResult &&= list?.includes(value) ?? true;
    }
    return this;
  }

  private isEnabledForListOfStage(
    key: keyof FeatureFlagContext,
    list?: { [key: string]: Array<string | number> },
  ) {
    const value = this.currentFeatureFlagContext?.[key];
    const stage = this.currentFeatureFlagContext?.stage;
    if (stage && value) {
      this.currentFeatureFlagResult &&= list?.[stage]?.includes(value) ?? true;
    }
    return this;
  }

  private result() {
    return this.currentFeatureFlagResult;
  }

  private isEnabledForStages() {
    return this.isEnabledForList('stage', this.currentFeature?.stages);
  }

  private isEnabledForOperators() {
    return this.isEnabledForListOfStage(
      'operator',
      this.currentFeature?.operators,
    );
  }

  private isEnabledForSellers() {
    return this.isEnabledForListOfStage('seller', this.currentFeature?.sellers);
  }

  private isEnabledForCountries() {
    return this.isEnabledForListOfStage(
      'countryOfOperation',
      this.currentFeature?.countriesOfOperation,
    );
  }

  private isEnabledForTeams() {
    return this.isEnabledForListOfStage('team', this.currentFeature?.teams);
  }

  private isEnabledForFeatureStartTime() {
    const time = this.currentFeatureFlagContext?.time;
    const stage = this.currentFeatureFlagContext?.stage;
    let featureStartTime;
    if (stage) {
      featureStartTime = this.currentFeature?.startTime?.[stage];
    }
    if (time && featureStartTime) {
      this.currentFeatureFlagResult &&=
        moment(time) >= moment(featureStartTime);
    }

    return this;
  }

  isEnabled(name: string, context: FeatureFlagContext): boolean {
    const feature = this.flags.find((flag) => flag.feature === name);
    if (!feature) return false;
    return this.forFeature(feature)
      .withContext(context)
      .isEnabledForStages()
      .isEnabledForOperators()
      .isEnabledForSellers()
      .isEnabledForCountries()
      .isEnabledForTeams()
      .isEnabledForFeatureStartTime()
      .result();
  }
}
