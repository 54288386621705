import { GraphQLResponse } from 'apollo-server-types';
import { pascalCase } from 'change-case';
import { DocumentNode } from 'graphql';
import { v4 as uuidv4 } from 'uuid';
import { client } from '../../client';
import {
  constructRecordPath,
  getRecordFromResponseV2,
  CrudType,
  getRecordName,
  QueryType,
  SearchType,
} from '@xbcb/shared-queries';
import { AnyObject, IdVersion, RecordType } from '@xbcb/shared-types';
import {
  ConstructOptimisticResults,
  constructOptimisticResults,
} from './constructOptimisticResults';
import { recordTypeToRecordFieldsMap } from '../optimisticRecordFields';
export * from './constructOptimisticResults';

// Creates an optimistic record to be put in the cache during an optimistic update
export const optimisticCrudRecord = <RecordFields>({
  crudType,
  overwriteOptimisticRecordFields,
  recordType,
}: {
  crudType: CrudType;
  overwriteOptimisticRecordFields?: RecordFields;
  recordType: RecordType;
}) => {
  const recordSpecificFields =
    recordTypeToRecordFieldsMap[
      recordType as keyof typeof recordTypeToRecordFieldsMap
    ];
  const queryType = ['get', 'gets', 'getAll'].includes(crudType)
    ? 'Query'
    : 'Mutation';
  const lowercaseCrudName = crudType.toLowerCase();
  const pascalCrudName = pascalCase(crudType);
  const camelRecordName = getRecordName(recordType, 'camel');
  const pascalRecordName = getRecordName(recordType);
  const defaultRecordFields = {
    // Optimistic records are an "estimate" of your actual data. It's just a placeholder after all.
    __typename: pascalRecordName,
    ...recordSpecificFields,
    created: null,
    deleted: null,
    id: `${camelRecordName}_${uuidv4()}`,
    updated: null,
    tags: null,
    version: 1,
    ...(overwriteOptimisticRecordFields || {}),
  };
  const mutationRecord = {
    __typename: 'Mutation',
    [`${lowercaseCrudName}${pascalRecordName}`]: {
      __typename: `${pascalCrudName}${pascalRecordName}Payload`,
      record: {
        ...defaultRecordFields,
      },
    },
  };

  return {
    ...(queryType === 'Query' ? defaultRecordFields : mutationRecord),
  };
};

export const updateQueryInCache = <Record extends IdVersion>({
  cache,
  // Determines how the existingRecord is updated in the cache.
  // Creates will add the record, deletes remove the record, and updates
  // replace the record.
  crudType,
  // This is a custom function that dictates how the cache update will
  // be performed
  customConstructOptimisticResults,
  // The GraphQL query string of the query being updated in the cache
  gqlQueryString,
  // The record being used to update the cache
  record,
  recordType,
  // The type of query to update optimistically in the cache
  updatedQueryType,
  // Variables for identifying the query being updated in the cache
  variables,
}: {
  cache?: any;
  crudType: CrudType;
  customConstructOptimisticResults?: ConstructOptimisticResults<Record>;
  gqlQueryString: DocumentNode;
  record: Record;
  recordType: RecordType;
  updatedQueryType: QueryType | SearchType;
  variables: AnyObject;
}) => {
  const query = {
    query: gqlQueryString,
    variables,
  };
  const queryName = constructRecordPath({
    recordName: recordType,
    crudOrSearchType: updatedQueryType,
  })[0];
  try {
    const data: any = cache ? cache.readQuery(query) : client.readQuery(query);
    const response = getRecordFromResponseV2({
      response: { data },
      crudOrSearchType: updatedQueryType,
      recordName: recordType,
    });
    const constructOptimisticResultsArgs = {
      crudType,
      record,
      response,
      updatedQueryType,
    };
    const newResults = customConstructOptimisticResults
      ? customConstructOptimisticResults(constructOptimisticResultsArgs)
      : constructOptimisticResults(constructOptimisticResultsArgs);
    const newData = {
      ...data,
      [queryName]: newResults,
    };
    cache
      ? cache.writeQuery({ ...query, data: newData })
      : client.writeQuery({ ...query, data: newData });
  } catch (e) {
    // It's possible for the query you're updating to not be on the ROOT_QUERY object. All it means is that the query hasn't fired yet so there is nothing to update.
    // Apollo errors are obfuscated and made into invariant errors in production so we cannot parse the message.
    // See here for info on the typical error that this block catches: https://github.com/apollographql/apollo-client/issues/1701
    // see here for info about Apollo invariant errors: https://github.com/apollographql/apollo-client/pull/6665
    // if (!(e.query && e.message.includes('ROOT_QUERY'))) throw e;
    console.log(
      'Optimistic update failed, it is likely that this query has not been requested yet.',
      queryName,
    );
  }
};

export const createOptimisticUpdateFunction =
  <Record extends IdVersion>({
    crudType,
    customConstructOptimisticResults,
    gqlQueryString,
    recordType,
    updatedQueryRecordType,
    updatedQueryType,
    variables,
  }: {
    crudType: CrudType;
    customConstructOptimisticResults?: ConstructOptimisticResults<Record>;
    gqlQueryString: DocumentNode;
    recordType: RecordType;
    updatedQueryRecordType?: RecordType;
    updatedQueryType: QueryType | SearchType;
    variables: AnyObject;
  }) =>
  (cache: any, response: GraphQLResponse) => {
    const record = getRecordFromResponseV2({
      response,
      crudOrSearchType: crudType,
      recordName: recordType,
    });
    updateQueryInCache({
      cache,
      crudType,
      customConstructOptimisticResults,
      gqlQueryString,
      record,
      recordType: updatedQueryRecordType ? updatedQueryRecordType : recordType,
      updatedQueryType,
      variables,
    });
  };
