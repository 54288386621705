export enum HsNumberType {
  HEADING = 'HEADING',
  SUBHEADING = 'SUBHEADING',
  PRODUCT = 'PRODUCT',
  HTS = 'HTS', // Fully qualified HTS.
}

export enum HsExportGroupCode {
  USALLC = 'USALLC', // Section 232 Steel all applicable countries.
  USALLC_AL = 'USALLC-AL', // Section 232 Aluminum all applicable countries.
  USCA_AL = 'USCA-AL', // Canada Aluminum duty
  USEU301S_1 = 'USEU301S.1',
  USEU301S_2 = 'USEU301S.2',
  USEU301S_3 = 'USEU301S.3',
  USEU301S_4 = 'USEU301S.4',
  USEU301S_5 = 'USEU301S.5',
  USEU301S_6 = 'USEU301S.6',
  USEU301S_7 = 'USEU301S.7',
  USEU301S_8 = 'USEU301S.8',
  USEU301S_9 = 'USEU301S.9',
  USEU301S_10 = 'USEU301S.10',
  USEU301S_11 = 'USEU301S.11',
  USEU301S_12 = 'USEU301S.12',
  USEU301S_13 = 'USEU301S.13',
  USEU301S_14 = 'USEU301S.14',
  USEU301S_15 = 'USEU301S.15',
  USEU301S_16 = 'USEU301S.16',
  USEU301S_17 = 'USEU301S.17',
  USEU301S_18 = 'USEU301S.18',
  USEU301S_19 = 'USEU301S.19',
}

export enum AdditionalHtsType {
  SECTION_301_LIST_1 = 'SECTION_301_LIST_1',
  SECTION_301_LIST_2 = 'SECTION_301_LIST_2',
  SECTION_301_LIST_3 = 'SECTION_301_LIST_3',
  SECTION_301_LIST_4A = 'SECTION_301_LIST_4A',
  SECTION_301_LIST_4B = 'SECTION_301_LIST_4B',
  SECTION_232_STEEL = 'SECTION_232_STEEL',
  SECTION_232_ALUMINIUM = 'SECTION_232_ALUMINIUM', // differs from legacy. spelling error (aluminum)
  SECTION_201_SOLAR_CELLS = 'SECTION_201_SOLAR_CELLS',
  SECTION_201_WASHER_FINISHED_GOODS = 'SECTION_201_WASHER_FINISHED_GOODS',
  SECTION_201_WASHER_PARTS = 'SECTION_201_WASHER_PARTS',
  DIGITAL_SERVICES_SECTION_301_TURKEY = 'DIGITAL_SERVICES_SECTION_301_TURKEY',
  DIGITAL_SERVICES_SECTION_301_SPAIN = 'DIGITAL_SERVICES_SECTION_301_SPAIN',
  DIGITAL_SERVICES_SECTION_301_ITALY = 'DIGITAL_SERVICES_SECTION_301_ITALY',
  DIGITAL_SERVICES_SECTION_301_FRANCE = 'DIGITAL_SERVICES_SECTION_301_FRANCE',
  DIGITAL_SERVICES_SECTION_301_UK = 'DIGITAL_SERVICES_SECTION_301_UK',
  DIGITAL_SERVICES_SECTION_301_INDIA = 'DIGITAL_SERVICES_SECTION_301_INDIA',
  DIGITAL_SERVICES_SECTION_301_AUSTRIA = 'DIGITAL_SERVICES_SECTION_301_AUSTRIA',
  RUSSIA_ALUMINUM = 'RUSSIA_ALUMINUM',
  RUSSIA_ALUMINUM_PRIVILEGED = 'RUSSIA_ALUMINUM_PRIVILEGED',
  EU_LARGE_CIVIL_AIRCRAFT_SECTION_301 = 'EU_LARGE_CIVIL_AIRCRAFT_SECTION_301',
}

export enum AdCvdCaseStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE_COMPANY_REVOKED = 'INACTIVE_COMPANY_REVOKED',
  INACTIVE_DEACTIVATED = 'INACTIVE_DEACTIVATED',
  INACTIVE_FINAL_EXCLUSION = 'INACTIVE_FINAL_EXCLUSION',
  INACTIVE_ORDER_REVOKED = 'INACTIVE_ORDER_REVOKED',
  INACTIVE_TERMINATION = 'INACTIVE_TERMINATION',
  INACTIVE_COMPANY_DEACTIVATED = 'INACTIVE_COMPANY_DEACTIVATED',
  INACTIVE_COMPANY_LOST_SEPARATE_RATE = 'INACTIVE_COMPANY_LOST_SEPARATE_RATE',
}

export enum UsPrincipalAdCvdCaseStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
