import { AccountType, AnyObject } from '@xbcb/shared-types';
import { ActivationStatus } from '@xbcb/party-types';
import { EsDataType } from '../../index';
import { shipperName, shipperId } from './party';

export const euIorColumns: AnyObject = {
  eori: {
    hidden: true,
    name: 'EORI',
    type: EsDataType.KEYWORD,
  },
  vatNumber: {
    hidden: true,
    name: 'VAT',
    type: EsDataType.KEYWORD,
  },
  industry: {
    hidden: true,
    name: 'Industry',
    type: EsDataType.KEYWORD,
  },
  shipperName,
  shipperId,
  forwarderName: {
    default: true,
    name: 'Forwarder',
    type: EsDataType.TEXT,
    blacklist: [AccountType.FORWARDER],
  },
  forwarderId: {
    name: 'Forwarder ID',
    type: EsDataType.KEYWORD,
    hidden: true,
  },
  activationStatus: {
    name: 'Activation Status',
    type: EsDataType.KEYWORD,
    default: true,
    optionValues: Object.values(ActivationStatus),
  },
  activationTime: {
    name: 'Activation Time',
    type: EsDataType.TIME,
  },
};
