import { AccountType } from '@xbcb/shared-types';
import {
  UsIorPmsStatus,
  UsIorCbpStatus,
  ActivationStatus,
  UsIorPaymentTerms,
  POAMethod,
  UsIorStatus,
} from '@xbcb/party-types';
import { InvoiceSource } from '@xbcb/finance-types';
import { createPartyTable } from './createPartyTable';
import { shipperName, shipperId } from './party';
import { iorNumber } from '../iorNumber';
import { EsDataType } from '../../enums';

export const usIor = createPartyTable({
  columns: {
    shipperName,
    shipperId,
    iorNumber,
    forwarderName: {
      default: true,
      name: 'Forwarder',
      type: EsDataType.TEXT,
      blacklist: [AccountType.FORWARDER],
    },
    forwarderId: {
      name: 'Forwarder ID',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    bondExpirationDate: {
      name: 'Bond Expiration Date',
      type: EsDataType.TIME,
      hidden: true,
    },
    pmsStatus: {
      default: true,
      name: 'PMS Status',
      type: EsDataType.KEYWORD,
      optionValues: Object.values(UsIorPmsStatus),
    },
    cbpStatus: {
      name: 'CBP Status',
      type: EsDataType.KEYWORD,
      optionValues: Object.values(UsIorCbpStatus),
    },
    industry: { name: 'Industry', type: EsDataType.KEYWORD },
    activationStatus: {
      name: 'Activation Status',
      type: EsDataType.KEYWORD,
      default: true,
      optionValues: Object.values(ActivationStatus),
    },
    activationTime: {
      name: 'Activation Time',
      type: EsDataType.TIME,
    },
    poaMethod: {
      name: 'POA Signing Method',
      type: EsDataType.KEYWORD,
      optionValues: Object.values(POAMethod),
    },
    businessStructure: {
      name: 'Entity Structure',
      type: EsDataType.TEXT,
    },
    paymentMethod: {
      name: 'Payment Method',
      type: EsDataType.KEYWORD,
      optionValues: Object.values(InvoiceSource),
    },
    bondNumber: {
      name: 'Bond Number',
      type: EsDataType.KEYWORD,
      default: true,
    },
    bondAnniversary: {
      name: 'Bond Anniversary',
      type: EsDataType.TIME,
      default: true,
    },
    hasActiveBond: {
      name: 'Bond (Yes/No)',
      type: EsDataType.KEYWORD,
    },
    paymentTerms: {
      name: 'Payment Terms',
      type: EsDataType.KEYWORD,
      optionValues: Object.values(UsIorPaymentTerms),
    },
    paymentTermsUpdatedTime: {
      name: 'Payment Terms Update Time',
      type: EsDataType.TIME,
      hidden: true,
    },
    status: {
      name: 'Status',
      type: EsDataType.KEYWORD,
      optionValues: Object.keys(UsIorStatus),
    },
  },
});
