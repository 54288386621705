/**
 * Determines size of typed signature in <SignFormContents/>
 * @param {string} typed - Typed signature
 * @return {string}
 */
export const signatureFontSize = (typed) => {
  let fontSize = '50px';
  if (typed.length >= 10) {
    fontSize = Math.max(45 - (typed.length - 10), 28) + 'px';
  }
  return fontSize;
};
