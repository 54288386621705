import { recordSizeThresholdConfigMap, S3Uploader } from '@xbcb/record-dao';
import { RecordType } from '@xbcb/shared-types';
import {
  LargeEntrySavedFields,
  UsConsumptionEntryMutationInput,
} from './types';
import { LargePayloadClient } from '../largePayloadClient';
import { UsConsumptionEntryS3Saver } from './usConsumptionEntryS3Saver';

/** A LargePayloadClient that uses a UsConsumptionEntryS3Saver with a given S3Uploader */
export class UsceLargeEntryClientBase extends LargePayloadClient<
  LargeEntrySavedFields,
  UsConsumptionEntryMutationInput
> {
  constructor(uploader: S3Uploader, enabled: boolean) {
    super(
      new UsConsumptionEntryS3Saver(uploader),
      (referenceKey, input) => {
        input.invoicesReference = referenceKey;
        return input;
      },
      recordSizeThresholdConfigMap[RecordType.US_CONSUMPTION_ENTRY],
      enabled,
    );
  }
}
