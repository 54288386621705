import { client } from '../../client';
import { getOneQuery, getRecordFromResponse } from '@xbcb/shared-queries';
import { IdVersion, RecordType } from '@xbcb/shared-types';
import { reportError } from '@xbcb/ui-utils';

export interface FetchLatestRecordArgs<Record extends IdVersion> {
  fields: string;
  fragments?: string;
  id: string;
  recordType: RecordType;
}

// Fetch the latest version of a record from DynamoDB
export const fetchLatestRecord = async <Record extends IdVersion>({
  fields,
  fragments,
  id,
  recordType,
}: FetchLatestRecordArgs<Record>) => {
  try {
    const response = await client.query({
      query: getOneQuery({
        recordName: recordType,
        fields,
        fragments,
      }),
      fetchPolicy: 'network-only',
      variables: {
        id,
      },
    });
    const record = getRecordFromResponse(response, 'get', recordType);
    return record;
  } catch (e) {
    reportError(e);
    throw e;
  }
};
