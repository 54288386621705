import { UiStage } from '@xbcb/ui-types';
import { awsRumClient } from '@xbcb/cloudwatch-rum';

export const reportError = (e: string | Error): void => {
  if (process.env.REACT_APP_STAGE !== UiStage.PROD) {
    console.log(`Error caught:`, e); // eslint-disable-line no-console
  }
  if (typeof e === 'string') {
    e = new Error(e);
  }
  awsRumClient.recordError(e);
};
