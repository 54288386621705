import styled from 'styled-components';
import { CssSpace } from '@xbcb/ui-types';

export { StyledSignFormDiv } from '../TemplateBasedSignSubmissionContent/styles';

export const PDFDocumentWrapper = styled.div`
  canvas {
    width: 100%;
    margin: 0 auto;
  }
  text-align: left;
`;

export const StyledBottomContentSignDiv = styled.div`
  margin: 0 auto;
  text-align: center;
  padding-top: ${CssSpace.SPACE_4};
  a {
    text-decoration: none;
    font-weight: normal;
  }
`;

export const StyledContentDiv = styled.div`
  a {
    font-weight: bold;
    text-decoration: underline;
  }
  padding-top: 25px;
  padding-bottom: 25px;
`;

export const StyledContentMainSignDiv = styled.div`
  min-height: 75vh;
  margin: 0 auto;
  max-width: var(--space-10);
  .signature-submit {
    width: var(--space-8);
    margin: 0 auto;
    margin-bottom: var(--space-4);
  }
`;
