import { AccountType } from '@xbcb/shared-types';
import { EsDataType } from '../../enums';
import { expirationTime, recordFields } from '../../defaults';

export const scac = {
  default: true,
  name: 'SCAC',
  type: EsDataType.KEYWORD,
};
export const ein = {
  name: 'EIN',
  type: EsDataType.KEYWORD,
};
export const feiNumber = {
  name: 'FEI',
  type: EsDataType.KEYWORD,
};
export const duns = {
  name: 'DUNS',
  type: EsDataType.KEYWORD,
};

export const firmsCode = {
  default: true,
  name: 'FIRMS',
  type: EsDataType.KEYWORD,
};

export const mid = { default: true, name: 'MID', type: EsDataType.KEYWORD };

export const shipperId = {
  name: 'Shipper ID',
  type: EsDataType.KEYWORD,
  hidden: true,
  blacklist: [AccountType.FORWARDER],
};

export const shipperName = {
  default: false,
  name: (accountType: AccountType) =>
    accountType === AccountType.FORWARDER ? 'Customer' : 'Shipper',
  type: EsDataType.TEXT,
};

export const locationInformation = {
  city: {
    name: 'City',
    type: EsDataType.TEXT,
  },
  stateName: {
    name: 'State',
    type: EsDataType.TEXT,
  },
  postalCode: {
    name: 'Postal Code',
    type: EsDataType.KEYWORD,
  },
  countryName: {
    name: 'Country',
    type: EsDataType.TEXT,
  },
};

export const partyFields = {
  name: {
    default: true,
    name: 'Name',
    type: EsDataType.TEXT,
  },
  duns,
  alternateName: {
    name: 'DBA',
    type: EsDataType.TEXT,
  },
  expirationTime,
  ...locationInformation,
  ...recordFields,
};

export const subscriptionPlan = {
  whitelist: [AccountType.OPERATOR],
  name: 'Plan',
  type: EsDataType.KEYWORD,
};

export const invitingPartyId = {
  name: 'Inviting Party ID',
  type: EsDataType.KEYWORD,
  hidden: true,
};

export const arCustomerId = {
  name: 'AR Customer ID',
  type: EsDataType.KEYWORD,
};

export const arOnboardingCountry = {
  name: 'AR Onboarding Country',
  type: EsDataType.TEXT,
};
