import { recordActionFields, recordFields } from '@xbcb/shared-queries';
export const templateDocumentSignRequestFragment = `fragment templateDocumentSignRequestFields on 
TemplateDocumentSignRequest {
  ...recordFields
  status
  url
  operator {
    id
  }
  expireTime
  expireAfterMinutes
  template {
    id
    version
    html
    jsonHtml
    templateType
  }
  additionalFields
  signature {
    method
  }
  documentTags
}`;

export const templateDocumentSignRequestFragments = `
${templateDocumentSignRequestFragment} 
${recordFields}
${recordActionFields}`;
