import { find, get } from 'lodash';
import { cleanObject } from '@xbcb/js-utils';
export { cleanObject, cleanValue } from '@xbcb/js-utils';

export const cleanShipmentObject = (uncleanValues) => {
  // antd getFieldValue has a bug that treats 'numberic' string keys starting with 1 as arrays, so we manually convert back to object
  get(uncleanValues, 'invoices', []).forEach((inv, i) => {
    get(inv, 'products', []).forEach((product) => {
      get(product, 'lines', []).forEach((line) => {
        if (line.fees) {
          line.fees = Object.keys(line.fees).reduce(
            (acc, code) => ({
              ...acc,
              [code]: line.fees[code],
            }),
            {},
          );
        }
      });
    });
  });
  const cleaned = cleanObject(uncleanValues);
  // we keep spawning blank empty rows due to quantity = 1 default initial value, this is a workaround to prevent db pollution
  get(cleaned, 'invoices', []).forEach((inv, i) => {
    if (inv.products && inv.products.length) {
      inv.products = inv.products.filter(
        (p) => !(Object.keys(p).length === 1 && p.quantity === 1),
      );
    }
    get(inv, 'products', []).forEach((product) => {
      delete product.totalReporting;
    });
  });
  // TODO figure out how to remove, right now you have to remove from shipment info to remove
  if (cleaned.isf && cleaned.isf.containers) {
    cleaned.isf.containers.forEach((number) => {
      if (!find(cleaned.containers, { number })) {
        if (!cleaned.containers) {
          cleaned.containers = [];
        }
        cleaned.containers.push({ number });
      }
    });
    delete cleaned.isf.containers;
  }
  return cleaned;
};
