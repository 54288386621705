import gql from 'graphql-tag';

export const queryAmsUsIsf = gql`
  mutation QueryAmsUsIsf(
    $id: ID!
    $idempotencyKey: String!
    $input: QueryAmsUsIsfInput!
  ) {
    queryAmsUsIsf(
      usIsfId: $id
      idempotencyKey: $idempotencyKey
      input: $input
    ) {
      transaction {
        id
        version
        usIsf {
          id
        }
      }
    }
  }
`;
