const priceTable = {
  FORWARDER: {
    continuousBond: {
      name: 'Continuous annual bond',
      tooltip:
        'Prices based on 50K bond, larger bond available upon request. Non-member rate is $349.',
      BASIC: '$313',
      PRO: '$281',
      ENTERPRISE: '$250',
    },
    isfBond: {
      name: 'ISF bond rate',
      BASIC: '$56',
      PRO: '$50',
      ENTERPRISE: '$45',
    },
    entryFee: {
      name: 'Entry fee rate',
      BASIC: '$63',
      PRO: '$50',
      ENTERPRISE: '$38',
    },
    inBondFee: {
      name: 'In-Bond fee rate',
      BASIC: '$63',
      PRO: '$50',
      ENTERPRISE: '$38',
    },
  },
  SHIPPER: {
    continuousBond: {
      name: 'Continuous annual bond',
      tooltip:
        'Prices based on 50K bond, larger bond available upon request. Non-member rate is $249.',
      LITE: '$229',
      BASIC: '$209',
      PRO: '$179',
      ENTERPRISE: 'Free',
    },
    isfBond: {
      name: 'ISF bond rate',
      LITE: '$66',
      BASIC: '$56',
      PRO: '$56',
      ENTERPRISE: '$56',
    },
    entryFee: {
      name: 'Entry fee rate',
      LITE: '$100',
      BASIC: '$75',
      PRO: '$63',
      ENTERPRISE: '$50',
    },
    inBondFee: {
      name: 'In-Bond fee rate',
      LITE: '$100',
      BASIC: '$75',
      PRO: '$63',
      ENTERPRISE: '$50',
    },
  },
};

export default priceTable;
