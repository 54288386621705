import { pointOfContactFields } from '@xbcb/party-queries';
import {
  addressFields,
  costFields,
  externalComplianceReferenceFields,
  phoneFields,
  recordActionFields,
  recordFields,
} from '@xbcb/shared-queries';
import { shipperContainerFragment } from '../workOrder';
import { memoFragments } from '@xbcb/client-queries';
import { sharedEuCustomsEntryLineFields } from '../lines';

export const getEuCustomsEntryFragment = (
  customsEntryFields: string,
  type: string,
  entryTypeSpecificFields = '',
): string => {
  const fragment = `fragment ${customsEntryFields} on ${type} {
    ...recordFields
    ${entryTypeSpecificFields}
    badges
    operator {
      id
    }
    ior {
      eori
      ior {
        id
        version
      }
     vatNumber
    }
    tasks {
      id
      version
    }
    consignee {
      eori
      vatNumber
      sameAsIor
      consignee {
        id
        version
      }
    }
    poNumbers
    loadType
    invoices {
      invoiceNumber
      transportationServiceName
      value {
        ...costFields
      }
      assist {
        ...costFields
      }
      conveyance {
        tripNumber
        modeOfTransport
        containerized
        conveyanceName
        grossWeight
      }
      currencyRate
      seller {
        name
        address {
          ...addressFields
        }
        seller {
          id
          version
        }
      }
      products {
        quantity
        totalValue {
          ...costFields
        }
        totalAssist {
          ...costFields
        }
        lines {
          ${sharedEuCustomsEntryLineFields}
        }
        product {
          id
          version
          clientIdentifier
          clientIdentifierVersion
          secondaryIdentifiers {
            value
            type
          }
        }
      }
    }
    milestones {
      id
      version
      name
      occurenceTime
    }
    masterBills {
      number
      houseBills {
        number
      }
    }
    containers {
      ...shipperContainerFields
    }
    departure {
      exportDate
      exportCountryCode
      portOfLading {
        type
        value
      }
    }
    conveyance {
      modeOfTransport
      containerized
      conveyanceName
      tripNumber
      grossWeight
    }
    arrival {
      portOfUnlading {
        type
        value
      }
      country
      importDate
    }
    broker {
      name
      customsBroker {
        id
        name
        email
        addresses {
          mailing {
            ...addressFields
          }
          physical {
            ...addressFields
          }
        }
        pointOfContact {
          ...pointOfContactFields
        }
        tags {
          key
          value
        }
      }
    }
    group {
      documents {
        documentTags
      }
      customsBroker {
        tags {
          key
          value
        }
      }
      forwarders {
        forwarder {
          billingDetails {
            disbursement
            status
            subscriptionPlan
          }
        }
      }
      shipment {
        id
        clientReferences {
          bookingId
        }
        legs {
          transportationServiceName
        }
        preferredDutyCollectPartyType
      }
      invoices{
        id
      }
      id
      memos {
        ...memoFields
      }
      shipper {
        id
        shipperExternallySourcedDetails {
          externalReferenceId
        }
        billingDetails {
          disbursement
          status
          subscriptionPlan
        }
        memos {
          ...memoFields
        }
      }
    }
    stateMachine {
      id
      version
    }
    totalValue
    totalAssist
    isAutoSetReady
  }`;
  return fragment;
};

export const gbCustomsEntryFragment = getEuCustomsEntryFragment(
  'gbCustomsEntryFields',
  'GbCustomsEntry',
  'importEntryNumbers',
);

export const deCustomsEntryFragment = getEuCustomsEntryFragment(
  'deCustomsEntryFields',
  'DeCustomsEntry',
);

export const nlCustomsEntryFragment = getEuCustomsEntryFragment(
  'nlCustomsEntryFields',
  'NlCustomsEntry',
);

export const frCustomsEntryFragment = getEuCustomsEntryFragment(
  'frCustomsEntryFields',
  'FrCustomsEntry',
);

export const gbCustomsEntryFragments = `
  ${gbCustomsEntryFragment}
  ${recordFields}
  ${recordActionFields}
  ${addressFields}
  ${shipperContainerFragment}
  ${phoneFields}
  ${pointOfContactFields}
  ${costFields}
  ${memoFragments}
  ${externalComplianceReferenceFields}
`;

export const deCustomsEntryFragments = `
  ${deCustomsEntryFragment}
  ${recordFields}
  ${recordActionFields}
  ${addressFields}
  ${shipperContainerFragment}
  ${phoneFields}
  ${pointOfContactFields}
  ${costFields}
  ${memoFragments}
  ${externalComplianceReferenceFields}
`;

export const nlCustomsEntryFragments = `
  ${nlCustomsEntryFragment}
  ${recordFields}
  ${recordActionFields}
  ${addressFields}
  ${shipperContainerFragment}
  ${phoneFields}
  ${pointOfContactFields}
  ${costFields}
  ${memoFragments}
  ${externalComplianceReferenceFields}
`;

export const frCustomsEntryFragments = `
  ${frCustomsEntryFragment}
  ${recordFields}
  ${recordActionFields}
  ${addressFields}
  ${shipperContainerFragment}
  ${phoneFields}
  ${pointOfContactFields}
  ${costFields}
  ${memoFragments}
  ${externalComplianceReferenceFields}
`;
