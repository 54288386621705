import { getRecordType } from '@xbcb/core';
import { RecordType } from '@xbcb/shared-types';

export const isEuCustomsEntry = (id?: string) => {
  if (!id) return false;
  const euCustomsEntryWorkOrderType: RecordType[] = [
    RecordType.GB_CUSTOMS_ENTRY,
    RecordType.DE_CUSTOMS_ENTRY,
    RecordType.NL_CUSTOMS_ENTRY,
    RecordType.FR_CUSTOMS_ENTRY,
  ];
  if (euCustomsEntryWorkOrderType.includes(getRecordType(id) as RecordType))
    return true;
  return false;
};
