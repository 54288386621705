import { documentGenerationRequestFragments } from './documentGenerationRequest';

const deliveryOrderDocumentGenerationRequestFragment = `
fragment deliveryOrderDocumentGenerationRequestFields on DeliveryOrderDocumentGenerationRequest {
  conveyance {
    name
    tripNumber
  }
  pickup {
    firmsCode
    specialInstructions
    lastFreeDate
    pickupNumber
  }
  destination {
    address {
      address
      address2
      city
      postalCode
      stateCode
      countryCode
    }
    receivingName
    facilityName
    receivingPhone {
      country
      number
      extension
    }
    receivingEmail
  }
  releaseDate
  importDate
  masterBill
  houseBill
  overflowBills
  importerName
  shipmentId
  carrierCode
  truckerName
  containers {
    number
    seal
    type
    description
    quantity
    weight
  }
  billTo
  inBondNumber
  entryNumber
  agent {
    agentName
    addresses {
      physical {
        address
        address2
        city
        postalCode
        stateCode
        countryCode
      }
      mailing {
        address
        address2
        city
        postalCode
        stateCode
        countryCode
      }
    }
    phone {
      country
      number
      extension
    }
    email
    pointOfContactName
    pointOfContactSign {
      id
      content {
        downloadLink
      }
    }
    logo {
      id
      content {
        downloadLink
      }
    }
  }
  status
  operator {
    id
  }
  id
  version
  tags {
    key
    value
  }
  document {
    id
  }
  ...documentGenerationRequestFields
  ...recordFields
}
`;

export const deliveryOrderDocumentGenerationRequestFragments = `
${deliveryOrderDocumentGenerationRequestFragment}
${documentGenerationRequestFragments}
`;
