import moment from 'moment';

export const isIso = (value: string) => {
  try {
    return moment(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid();
  } catch (e) {
    return false;
  }
};

export const isISO = isIso;
