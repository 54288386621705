import moment from 'moment';

export const getNextRenewalDate = (
  initialRenewalDate: string,
): moment.Moment => {
  // if renewal date is in the future (first renewal), use that date
  if (moment(initialRenewalDate).isAfter(moment())) {
    return moment(initialRenewalDate);
  }

  const currentYear = moment().year();
  // if the anniversary date this year has not passed, return current year anniversary
  if (moment(initialRenewalDate).set('year', currentYear).isAfter(moment())) {
    return moment(initialRenewalDate).set('year', currentYear);
  }

  // return next year's anniversary
  return moment(initialRenewalDate).set('year', currentYear + 1);
};
