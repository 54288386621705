import moment from 'moment';
import { get, set } from 'lodash';
import { isISO } from '@xbcb/js-utils';
import { getFullFormKeys } from '../getFullFormKeys';
export { unMomentize } from '@xbcb/js-utils';

// These are received/stored in the backend as '2020-01-01',
// with no time or timezone information available.
const timeInsensitiveKeys = [
  'importDate',
  'arrivalDate',
  'exportDate',
  'estimatedEntryDate',
  'inBondDate',
  'initiationDate',
];

export const momentize = (initialValues?: Record<string, any>): any => {
  if (!initialValues) return initialValues;

  const fullFormKeys = getFullFormKeys({
    values: initialValues,
  });

  fullFormKeys.forEach((key) => {
    const value = get(initialValues, key);
    if (
      timeInsensitiveKeys.some((suffix) => key.endsWith(suffix)) &&
      typeof value === 'string'
    ) {
      /*
       Moment will interpret the 2020-01-01 format as midnight in the user's timezone,
       which may not be the same date as in UTC time. We therefore need to be careful
       to ensure everything is UTC end-to-end.

       TT: https://t.corp.amazon.com/V1377532038
       */
      // .utc() assumes UTC when parsing (which is true of values coming from the server)
      set(initialValues, key, moment.utc(value));
    } else if (
      (isISO(value) ||
        key.endsWith('Month') ||
        key.endsWith('Date') ||
        key.endsWith('Time') ||
        key.endsWith('date') ||
        key.endsWith('passport.expiration') ||
        key.endsWith('arrival.time') ||
        key.endsWith('departure.time') ||
        key.endsWith('previousCbpInBond.date') ||
        key === 'lfd') &&
      !moment.isMoment(value)
    ) {
      set(initialValues, key, moment(value));
    }
  });
  return initialValues;
};
