import React, { useState, ReactNode, useEffect, useCallback } from 'react';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { Button } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { NamePath } from '@xbcb/ui-types';
import { cleanObject } from '@xbcb/ui-utils';
import { StyledHeading, StyledOptionalFormSectionTitleDiv } from './styles';

interface OptionalFormSectionProps {
  title?: ReactNode;
  children: ReactNode;
  onShow?: Function;
  form: FormInstance;
  fields?: NamePath[];
  readOnly?: boolean;
}

const OptionalFormSection = ({
  title,
  children,
  onShow,
  form,
  fields = [],
  readOnly,
}: OptionalFormSectionProps) => {
  const someFieldsDefined = useCallback(
    () => fields.some((field) => cleanObject(form.getFieldValue(field))),
    [fields, form],
  );

  const [isVisible, setVisible] = useState(someFieldsDefined());

  useEffect(() => {
    // If we "save", and go back to `readOnly` mode, isVisible should
    // mirror whether or not there are any fields filled out
    if (readOnly) setVisible(someFieldsDefined());
  }, [readOnly, someFieldsDefined]);

  const handleShow = () => {
    setVisible(true);
    if (onShow) onShow();
  };
  return (
    <div>
      <StyledOptionalFormSectionTitleDiv $isVisible={isVisible}>
        <StyledHeading>{title}</StyledHeading>
        {!isVisible && !readOnly && (
          <Button size="small" type="dashed" onClick={handleShow}>
            <PlusOutlined />
            Add
          </Button>
        )}
      </StyledOptionalFormSectionTitleDiv>
      {isVisible && children}
    </div>
  );
};

export default OptionalFormSection;
