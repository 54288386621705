import { awsRumClient } from '@xbcb/cloudwatch-rum';

/**
 * Direct wrapper around the RUM client's recordEvent method
 * Allows us to send custom events containing arbitrary data
 * to the RUM monitor.
 */

export const recordUIEvent = (
  eventName: string,
  eventDetails: Record<string, unknown>,
): void => {
  return awsRumClient.recordEvent(eventName, eventDetails);
};

/**
 * Wrapper around the Performance Web API
 * (https://developer.mozilla.org/en-US/docs/Web/API/Performance);
 * used for measuring the execution time of UI code.
 */
export class Timer {
  hasPerformanceAPI: boolean;
  startTime: number;
  constructor() {
    this.hasPerformanceAPI = 'performance' in window;
  }
  start(): void {
    if (this.hasPerformanceAPI) {
      this.startTime = performance.now();
    }
  }

  stop(): number | null {
    if (!this.hasPerformanceAPI || !this.startTime) {
      return null;
    }
    return performance.now() - this.startTime;
  }
}
