import { partyFragments } from './party';

const frCustomsBrokerFragment = `
fragment frCustomsBrokerFields on FrCustomsBroker {
  ...recordFields
  ...partyFields
  entityName
  name
  eori
}
`;

export const frCustomsBrokerFragments = `
${frCustomsBrokerFragment}
${partyFragments}
`;
