import styled from 'styled-components';
import { Card } from 'antd';

export const StyledTitle = styled.div<{
  $clickable?: boolean;
  $width?: string;
}>`
  vertical-align: top;
  width: ${({ theme }) => theme.size.short};

  ${({ $clickable }) => $clickable && 'cursor: pointer;'}
  ${({ $width }) => $width && `width: ${$width};`}
`;

export const StyledContent = styled.div`
  vertical-align: top;
  width: 100%;
`;

// TODO these styles need updated, they are using legacy classNames that likely don't exist
export const StyledCard = styled(Card)<{
  $inline?: boolean;
  $noMarginBottom?: boolean;
}>`
  ${({ $noMarginBottom, theme }) =>
    !$noMarginBottom && `margin-bottom: ${theme.spacing.space_4};`}
  text-align: left;
  .ant-card-body {
    display: flex;
    ${({ $inline }) => !$inline && 'flex-direction: column;'}
  }
  .single-card-title {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    margin-bottom: ${({ theme }) => theme.spacing.space_3};
  }
  .single-form-card-col-content {
    > *:last-child {
      margin-bottom: 0;
    }
  }
  &.title-position-top {
    .single-card-title {
      margin-bottom: ${({ theme }) => theme.spacing.space_4};
    }
    .ant-card-body {
      padding: 0;
      .single-form-card-col-title {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        .single-card-title {
          border-bottom: 1px solid ${({ theme }) => theme.palette.greys.grey};
          padding: ${({ theme }) =>
            `${theme.spacing.space_3} ${theme.spacing.space_4}`};
          margin: 0;
        }
      }
      .single-form-card-col-content {
        padding: ${({ theme }) => theme.spacing.space_4};
      }
    }
  }
`;
