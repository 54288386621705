export const SubjectMatterCategoryDescription =
  'Used to specify categories if this is a SME role';

export const SubjectMatterSubCategoryDescription =
  'Used to specify sub categories if this is a SME role';

export enum SubjectMatterCategory {
  CLASSIFICATION = 'CLASSIFICATION',
  PGA = 'PGA',
  AD_CVD = 'AD_CVD',
  COO_FTA = 'COO_FTA',
  VALUATION = 'VALUATION',
  MULTIPLE_ISSUES = 'MULTIPLE_ISSUES',
}

export enum ClassificationSubjectMatterSubCategory {
  APPAREL_FOOTWEAR_AND_TEXTILES = 'APPAREL_FOOTWEAR_AND_TEXTILES',
  ELECTRONICS = 'ELECTRONICS',
  BASE_METALS = 'BASE_METALS',
  MACHINERY = 'MACHINERY',
  PETROLEUM_NATURAL_GAS_AND_MINERALS = 'PETROLEUM_NATURAL_GAS_AND_MINERALS',
  CONSUMER_PRODUCTS_AND_MASS_MERCHANDISING = 'CONSUMER_PRODUCTS_AND_MASS_MERCHANDISING',
  AGRICULTURE_AND_PREPARED_PRODUCTS = 'AGRICULTURE_AND_PREPARED_PRODUCTS',
  AUTOMOTIVE_AND_AEROSPACE = 'AUTOMOTIVE_AND_AEROSPACE',
  PHARMACEUTICALS_HEALTH_AND_CHEMICALS = 'PHARMACEUTICALS_HEALTH_AND_CHEMICALS',
  INDUSTRIAL_AND_MANUFACTURING_MATERIALS = 'INDUSTRIAL_AND_MANUFACTURING_MATERIALS',
}

export enum PgaSubjectMatterSubCategory {
  FDA = 'FDA',
  NHTSA = 'NHTSA',
  APHIS = 'APHIS',
  EPA = 'EPA',
  FWS = 'FWS',
}

export const SubjectMatterSubCategory = {
  ...ClassificationSubjectMatterSubCategory,
  ...PgaSubjectMatterSubCategory,
};

export type SubjectMatterSubCategory =
  | ClassificationSubjectMatterSubCategory
  | PgaSubjectMatterSubCategory;

export const SubjectMatterCategoryToSubCatoriesMap = {
  [SubjectMatterCategory.CLASSIFICATION]: [
    ...Object.values(ClassificationSubjectMatterSubCategory),
  ],
  [SubjectMatterCategory.PGA]: [...Object.values(PgaSubjectMatterSubCategory)],
  [SubjectMatterCategory.AD_CVD]: [] as SubjectMatterSubCategory[],
  [SubjectMatterCategory.COO_FTA]: [] as SubjectMatterSubCategory[],
  [SubjectMatterCategory.VALUATION]: [] as SubjectMatterSubCategory[],
  [SubjectMatterCategory.MULTIPLE_ISSUES]: [] as SubjectMatterSubCategory[],
};
