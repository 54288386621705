export type StringMap = { [key: string]: string };
/**
 * Bidirectional string map.
 */
export class BiStringMap {
  private reverseMap: StringMap;

  constructor(public map: StringMap) {
    this.reverseMap = {};
    for (const key in map) {
      if (!Object.prototype.hasOwnProperty.call(map, key)) continue;
      const value = map[key];
      this.reverseMap[value] = key;
    }
  }

  /**
   * Get value in original map direction.
   * @param {string} key
   * @return {string}
   */
  get(key: string) {
    return this.map[key];
  }

  /**
   * Get value in reverse map direction.
   * @param {string} key
   * @return {string}
   */
  reverseGet(key: string) {
    return this.reverseMap[key];
  }
}
