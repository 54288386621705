import styled from 'styled-components';
import { CssSpace } from '@xbcb/ui-types';

export const StyledBottomContentSignDiv = styled.div`
  margin: 0 auto;
  text-align: center;
  padding-top: ${CssSpace.SPACE_4};
  a {
    text-decoration: none;
    font-weight: normal;
  }
`;

export const StyledErrorMessageDiv = styled.div`
  text-align: left;
  font-size: 14px;
`;

export const StyledAttributionsWrapper = styled.div`
  margin-top: ${CssSpace.SPACE_4};
`;

export const StyledSignFormDiv = styled.div`
  background-color: white;
  padding: var(--space-4);
  border-radius: var(--border-radius);
  max-width: var(--space-10);
  margin: 0 auto;
  &.max-width {
    width: 100%;
  }
  h2 {
    margin-bottom: 0;
  }
  p.center,
  h2.center {
    text-align: center;
  }
  p {
    text-align: left;
    margin-bottom: var(--space-4);
  }

  .sign-form-signature-info {
    max-width: 495px;

    .sign-form-date {
      float: right;
    }
  }

  .sign-box {
    height: 120px;
    background-color: white;
    width: 495px;
    border-radius: var(--border-radius);
    margin: 0 auto;
    text-align: center;
    border: 1px dashed;
    transition: all 0.3s;
    display: table-cell;
    vertical-align: center;

    img {
      max-width: 495px;
      max-height: 120px;
    }

    &:hover {
      border: 1px dashed var(--primary-color);
      cursor: pointer;
      .sign-icon {
        color: var(--primary-color);
      }
    }

    &.error {
      border: 1px dashed var(--bad);
    }

    .sign-icon {
      transition: all 0.3s;
      font-size: 50px;
      margin-bottom: 10px;
      margin-top: 10px;
    }

    p {
      text-align: center;
      font-size: 14px;
    }
  }
`;
