import { round } from 'lodash';
import log from '@xbcb/log';
import { ChargeCode } from '@xbcb/finance-types';
import {
  BillingParty,
  Forwarder,
  UsIorContinuousBondRequestBondRisk,
} from '@xbcb/api-gateway-client';
import { isAGLForwarder } from '@xbcb/party-utils';
import { SubscriptionPlan } from '@xbcb/shared-types';
import { fbaContinuousBondRates } from './fbaContinuousBondRates';
import getCustomFeeFromPayer from './getCustomFeeFromPayer';
import standardContinuousBondSteppedRates from './standardContinuousBondSteppedRates';
import antiDumpingCountervailingDutyContinuousBondFee from './antiDumpingCountervailingDutyContinuousBondSteppedRates';

const ANTI_DUMPING_COUNTERVAILING_DUTY_RISK_TYPE = '4';

const calculateAntiDumpingCountervailingDutySteppedRateFee = (
  bondAmount: number,
  subscriptionPlan: SubscriptionPlan,
) => {
  if (!antiDumpingCountervailingDutyContinuousBondFee[subscriptionPlan]) {
    return undefined;
  }

  const {
    fiftyToOneHundredThousand,
    twoHundredToFiveHundredThousand,
    sixHundredToOneMillion,
    overOneMillion,
  } = antiDumpingCountervailingDutyContinuousBondFee[subscriptionPlan]!;

  return round(
    Math.min(bondAmount, 100000) * fiftyToOneHundredThousand +
      Math.min(Math.max(bondAmount - 100000, 0), 400000) *
        twoHundredToFiveHundredThousand +
      Math.min(Math.max(bondAmount - 500000, 0), 500000) *
        sixHundredToOneMillion +
      Math.max(bondAmount - 1000000, 0) * overOneMillion,
    2,
  );
};

const getCustomContinuousBondFee = (
  bondAmount: number,
  payer: BillingParty,
) => {
  const customRate = getCustomFeeFromPayer(payer, ChargeCode.CONTINUOUS_BOND);
  if (!customRate) return;

  const unitPrice = customRate / 50000;
  return Math.round(unitPrice * bondAmount);
};

const calculateStandardPricingSteppedRateFee = (
  bondAmount: number,
  subscriptionPlan: SubscriptionPlan,
) => {
  if (subscriptionPlan === SubscriptionPlan.LITE) {
    if (bondAmount === 50000) {
      return 286;
    } else {
      return undefined;
    }
  }

  const {
    fiftyThousand,
    sixtyToOneHundredThousand,
    twoHundredToFiveHundredThousand,
    sixHundredToOneMillion,
    overOneMillion,
  } = standardContinuousBondSteppedRates[subscriptionPlan]!;

  if (bondAmount === 50000) return fiftyThousand * bondAmount;

  return round(
    Math.min(bondAmount, 100000) * sixtyToOneHundredThousand +
      Math.min(Math.max(bondAmount - 100000, 0), 400000) *
        twoHundredToFiveHundredThousand +
      Math.min(Math.max(bondAmount - 500000, 0), 500000) *
        sixHundredToOneMillion +
      Math.max(bondAmount - 1000000, 0) * overOneMillion,
    2,
  );
};

/* eslint-disable max-lines-per-function */ // removing this when deploying to prod and old code is removed
const calculateContinuousBondFee = ({
  bondAmount,
  payer,
  risk,
}: {
  bondAmount: number;
  payer: BillingParty;
  risk?: UsIorContinuousBondRequestBondRisk;
}): { amount: number } => {
  let fee = getCustomContinuousBondFee(bondAmount, payer);

  if (!fee) {
    const plan = payer.billingDetails?.subscriptionPlan;
    if (!plan) {
      const errorMessage = `Invoice payer ${payer.id} has no subscription plan`;
      log.error(errorMessage, {
        key: 'NoSubscriptionPlan',
        params: {
          payerId: payer.id,
          payerVersion: payer.version,
          subscriptionPlan: plan,
          bondAmount,
        },
      });
      throw new Error(errorMessage);
    }

    if (isAGLForwarder(payer as Forwarder)) {
      const fbaContinuousBondFee = fbaContinuousBondRates[bondAmount];
      if (!fbaContinuousBondFee) {
        throw new Error(`Bond amount ${bondAmount} is not supported for FBA`);
      }
      fee = fbaContinuousBondFee;
    } else if (risk?.type === ANTI_DUMPING_COUNTERVAILING_DUTY_RISK_TYPE) {
      fee = calculateAntiDumpingCountervailingDutySteppedRateFee(
        bondAmount,
        plan as SubscriptionPlan,
      );
    } else {
      fee = calculateStandardPricingSteppedRateFee(
        bondAmount,
        plan as SubscriptionPlan,
      );
    }

    if (!fee) {
      const errorMessage = `No fee configured for ${payer.id} ${plan} plan and ${bondAmount} bond amount`;
      log.error(errorMessage, {
        key: 'NoFeeConfigurationFound',
        params: {
          payerId: payer.id,
          payerVersion: payer.version,
          subscriptionPlan: plan,
          bondAmount,
        },
      });
      throw new Error(errorMessage);
    }
  }

  return { amount: fee };
};

export default calculateContinuousBondFee;
