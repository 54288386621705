import React, { Component } from 'react';
import { Modal } from 'antd';
import { SignFormContents } from '@xbcb/form-item-components';

class SignForm extends Component {
  render() {
    return (
      <Modal
        title={null}
        wrapClassName="sign-form-modal"
        visible={this.props.visible}
        onCancel={this.props.close}
        footer={null}
        closable={false}
      >
        <SignFormContents sign={this.props.sign} />
      </Modal>
    );
  }
}

export default SignForm;
