import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

export const cbpReg2ChangesFeature = 'CBP_REG_2_CHANGES_FEATURE';

const CBP_REG_2_CHANGES_FEATURE = new FeatureFlagService([
  {
    feature: cbpReg2ChangesFeature,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA, Stage.PROD],
  },
]);

export { CBP_REG_2_CHANGES_FEATURE };
