import { userTeamFragments } from './userTeam';

const businessSupportTeamFragment = `
  fragment businessSupportTeamFields on BusinessSupportTeam {
    operator {
      id
    }
    operatorUsers {
      nodes {
        id
      }
      edges {
        node {
          id
          version
        }
      }
    }
    ...userTeamFields
    ...recordFields
  }
`;

export const businessSupportTeamFragments = `
  ${businessSupportTeamFragment}
  ${userTeamFragments}
`;
