export const euIorActivationsFields = `
activations {
  rejectionReasons
  brokerRepresentation
  customsBroker {
    id
  }
  status
  rejected {
    time
  }
  activated {
    time
  }
  externalIorReferences {
    id
    referenceType
    documents {
      documentTag
      externalReference {
        id
        version
        externalReferenceType
      }
    }
  }
}`;
