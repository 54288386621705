import { partyFragments } from './party';

const customsAgentFragment = `
fragment customsAgentFields on CustomsAgent {
  ...recordFields
  ...partyFields
}
`;

export const customsAgentFragments = `
${customsAgentFragment}
${partyFragments}
`;
