import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

export const RoanokeApiFeatures = {
  CONTINUOUS_BOND: 'CONTINUOUS_BOND',
  SINGLE_ENTRY_BOND: 'SINGLE_ENTRY_BOND',
  SINGLE_ISF_BOND: 'SINGLE_ISF_BOND',
};

const ctbFeature = {
  feature: RoanokeApiFeatures.CONTINUOUS_BOND,
  enabled: true,
  stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA, Stage.PROD],
};

const sebFeature = {
  feature: RoanokeApiFeatures.SINGLE_ENTRY_BOND,
  enabled: true,
  stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA],
};

const sibFeature = {
  feature: RoanokeApiFeatures.SINGLE_ISF_BOND,
  enabled: true,
  stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA],
};

export const ROANOKE_API_FEATURE_FLAG_SERVICE = new FeatureFlagService([
  ctbFeature,
  sebFeature,
  sibFeature,
]);
