import {
  importSign,
  newAppUI,
  isInternalWebsite,
  isLocalHostInternalWebsite,
  getEnv,
} from './environment';
import {
  isClientAuthorityAccount,
  isImportSignAccount,
  isNewAppUiAccount,
} from './accounts';
import { InternalWebsiteName } from '@xbcb/shared-types';

// NOTE: This function does not currently pick up any of the Forwarder's custom
// domains as APP, which they should. See the TODO below and remove this NOTE
// once we have implemented

export const getInternalWebsiteNameFromUrl = ({
  baseUrl,
  awsAccountId,
}: {
  baseUrl?: string;
  // Used as a fallback for localhost
  awsAccountId?: string;
}) => {
  if (!baseUrl || !isInternalWebsite(baseUrl)) return;
  const { stage } = getEnv();
  // Unique case for https://localhost:3000
  if (isLocalHostInternalWebsite(baseUrl)) {
    // If it is localhost, the only way to differentiate between websites is by
    // referencing the awsAccountId, thus if it was not provided we cannot
    // determine the InternalWebsiteName, so we should return
    if (!awsAccountId) return;
    if (
      isClientAuthorityAccount(awsAccountId) ||
      isNewAppUiAccount(awsAccountId)
    )
      return InternalWebsiteName.APP;
    if (isImportSignAccount(awsAccountId)) {
      return InternalWebsiteName.IMPORTSIGN;
    }
    return;
  }
  // TODO also add marketing and documentation (update tests to include them)
  if (newAppUI[stage].some((url) => url === baseUrl)) {
    return InternalWebsiteName.APP;
  } else if (baseUrl === importSign[stage]) {
    return InternalWebsiteName.IMPORTSIGN;
  }
  // TODO we need to treat any forwarder custom domains as APP. To do so,
  // before returning, we should first look up the URL in the CustomDomain
  // authority (we should have a GSI to search on). There is a similar
  // implementation on line 110 in legacy `libs/response.js`. If we find a
  // CustomDomain that matches the baseUrl provided, then return
  // `InternalWebsiteName.APP`, otherwise proceed with returning `undefined`
  // Asana task: https://app.asana.com/0/1200172826136262/1200222890116028
  return;
};
