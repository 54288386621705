import CorporateCertificationWaiverStateCodes from '@xbcb/codes/codes/CorporateCertificationWaiverStateCodes.json';
import { UsBusinessStructure } from '@xbcb/party-types';

export const requiresCorporateCertification = (
  countryCode: string,
  stateCode: string,
  businessStructure: UsBusinessStructure,
) => {
  const isCCRequiredForBusinessStructure = [
    UsBusinessStructure.CORPORATION,
    UsBusinessStructure.LLC,
    UsBusinessStructure.SOLE_PROPRIETOR,
    UsBusinessStructure.PARTNERSHIP,
  ].includes(businessStructure);
  const isWithinUsCustomsBorder =
    countryCode !== 'US'
      ? true
      : !(stateCode in CorporateCertificationWaiverStateCodes);
  return isCCRequiredForBusinessStructure && isWithinUsCustomsBorder;
};
