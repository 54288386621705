// HTS numbers which can assume zero value in some scenarios but not always.
export const possibleZeroValueHts = (htsNumber: string) => {
  const cleanedHtsNumber = htsNumber.replace(/\./g, '');
  return (
    cleanedHtsNumber.startsWith('91') && // for optimization
    chapter91PossibleZeroValueHts.includes(cleanedHtsNumber)
  );
};

// Obtained from excel attached in https://t.corp.amazon.com/P42552718, https://t.corp.amazon.com/V682715758, https://t.corp.amazon.com/V1000283985
const chapter91PossibleZeroValueHts = [
  '9101114010',
  '9101114020',
  '9101114030',
  '9101114040',
  '9101118010',
  '9101118020',
  '9101118030',
  '9101118040',
  '9101192020',
  '9101192040',
  '9101192080',
  '9101194010',
  '9101194020',
  '9101194030',
  '9101194040',
  '9101198010',
  '9101198020',
  '9101198030',
  '9101198040',
  '9101215010',
  '9101215020',
  '9101218010',
  '9101218020',
  '9101218030',
  '9101291010',
  '9101291020',
  '9101291030',
  '9101292010',
  '9101292020',
  '9101292030',
  '9101293010',
  '9101293020',
  '9101293030',
  '9101294010',
  '9101294020',
  '9101294030',
  '9101295010',
  '9101295020',
  '9101295030',
  '9101299010',
  '9101299020',
  '9101914010',
  '9101914020',
  '9101914030',
  '9101918010',
  '9101918020',
  '9101918030',
  '9101992010',
  '9101992020',
  '9101994010',
  '9101994020',
  '9101996010',
  '9101996020',
  '9101998010',
  '9101998020',
  '9102111010',
  '9102111020',
  '9102111030',
  '9102111040',
  '9102112510',
  '9102112520',
  '9102112530',
  '9102112540',
  '9102113010',
  '9102113020',
  '9102113030',
  '9102113040',
  '9102114510',
  '9102114520',
  '9102114530',
  '9102114540',
  '9102115010',
  '9102115020',
  '9102115030',
  '9102115040',
  '9102116510',
  '9102116520',
  '9102116530',
  '9102116540',
  '9102117010',
  '9102117020',
  '9102117030',
  '9102117040',
  '9102119510',
  '9102119520',
  '9102119530',
  '9102119540',
  '9102192010',
  '9102192020',
  '9102192030',
  '9102192040',
  '9102194010',
  '9102194020',
  '9102194030',
  '9102194040',
  '9102196010',
  '9102196020',
  '9102196030',
  '9102196040',
  '9102198010',
  '9102198020',
  '9102198030',
  '9102198040',
  '9102211010',
  '9102211020',
  '9102211030',
  '9102212510',
  '9102212520',
  '9102212530',
  '9102213010',
  '9102213020',
  '9102213030',
  '9102215010',
  '9102215020',
  '9102215030',
  '9102217010',
  '9102217020',
  '9102217030',
  '9102219010',
  '9102219020',
  '9102219030',
  '9102290410',
  '9102290420',
  '9102291010',
  '9102291020',
  '9102291030',
  '9102291510',
  '9102291520',
  '9102291530',
  '9102292010',
  '9102292020',
  '9102292030',
  '9102292510',
  '9102292520',
  '9102292530',
  '9102293010',
  '9102293020',
  '9102293030',
  '9102293510',
  '9102293520',
  '9102293530',
  '9102294010',
  '9102294020',
  '9102294030',
  '9102294510',
  '9102294520',
  '9102294530',
  '9102295010',
  '9102295020',
  '9102295030',
  '9102295510',
  '9102295520',
  '9102295530',
  '9102296010',
  '9102296020',
  '9102296030',
  '9102912010',
  '9102912020',
  '9102914010',
  '9102914020',
  '9102914030',
  '9102918010',
  '9102918020',
  '9102918030',
  '9102992010',
  '9102992020',
  '9102994010',
  '9102994020',
  '9102996010',
  '9102996020',
  '9102998010',
  '9102998020',
  '9103102010',
  '9103102020',
  '9103102030',
  '9103102040',
  '9103104010',
  '9103104020',
  '9103104030',
  '9103104040',
  '9103104050',
  '9103104060',
  '9103108010',
  '9103108020',
  '9103108030',
  '9103108040',
  '9103108050',
  '9103108060',
  '9103900010',
  '9103900020',
  '9103900030',
  '9103900040',
  '9104000510',
  '9104000520',
  '9104001010',
  '9104001020',
  '9104002510',
  '9104002520',
  '9104003010',
  '9104003020',
  '9104004510',
  '9104004520',
  '9104005010',
  '9104005020',
  '9104005030',
  '9104006010',
  '9104006020',
  '9105114010',
  '9105114020',
  '9105114030',
  '9105114040',
  '9105114050',
  '9105118005',
  '9105118015',
  '9105118020',
  '9105118030',
  '9105118040',
  '9105118050',
  '9105118060',
  '9105118070',
  '9105191010',
  '9105191020',
  '9105191030',
  '9105191040',
  '9105192010',
  '9105192020',
  '9105192030',
  '9105192040',
  '9105193010',
  '9105193020',
  '9105193030',
  '9105193040',
  '9105214010',
  '9105214020',
  '9105214030',
  '9105218010',
  '9105218020',
  '9105218030',
  '9105218040',
  '9105218050',
  '9105291010',
  '9105291020',
  '9105292010',
  '9105292020',
  '9105293010',
  '9105293020',
  '9105914010',
  '9105914020',
  '9105914030',
  '9105918010',
  '9105918020',
  '9105918030',
  '9105918040',
  '9105918050',
  '9105992010',
  '9105992020',
  '9105993010',
  '9105993020',
  '9105994010',
  '9105994020',
  '9106100000', // https://t.corp.amazon.com/V1134621294
  '9106906500', // https://t.corp.amazon.com/V1266758885
  '9106905510',
  '9106905520',
  '9106908500',
  '9107004040',
  '9107004080', // https://t.corp.amazon.com/V1337211651
  '9108114010',
  '9108114020',
  '9108118010',
  '9108118020',
  '9108120010',
  '9108120020',
  '9108194010',
  '9108194020',
  '9108198010',
  '9108198020',
  '9109101010',
  '9109101020',
  '9109101030',
  '9109102010',
  '9109102020',
  '9109102030',
  '9109103010',
  '9109103020',
  '9109103030',
  '9109104010',
  '9109104020',
  '9109104030',
  '9109105010',
  '9109105020',
  '9109105030',
  '9109106010',
  '9109106020',
  '9109106030',
  '9109107010',
  '9109107020',
  '9109107030',
  '9109108010',
  '9109108020',
  '9109108030',
];
