const baseWorkOrderConfirmationConfigurationsFields = `
  ruleGroup {
    ruleSets {
      rules {
        criteria
        logicalOperator
        values
        currency
      }
      booleanOperator
    }
    booleanOperator
  }
  approveAfterMinutes
  includedDocuments
  workOrderType
`;

export const shipperWorkOrderConfirmationConfigurationsFields = `
fragment shipperWorkOrderConfirmationConfigurationsFields on ShipperWorkOrderConfirmationConfiguration {
  overrideForwarderConfig
  ${baseWorkOrderConfirmationConfigurationsFields}
}`;

export const forwarderWorkOrderConfirmationConfigurationsFields = `
fragment forwarderWorkOrderConfirmationConfigurationsFields on ForwarderWorkOrderConfirmationConfiguration {
  ${baseWorkOrderConfirmationConfigurationsFields}
}`;
