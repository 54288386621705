import {
  addressFields,
  costFields,
  recordActionFields,
  recordFields,
} from '@xbcb/shared-queries';

const invoiceDocumentGenerationRequestFragment = `fragment invoiceDocumentGenerationRequestFields on InvoiceDocumentGenerationRequest {
  ...recordFields
  number
  invoiceStatus
  documentTags
  total {
    ...costFields
  }
  document {
    id
  }
  dueDate
  billToParty {
    name
    address {
      ...addressFields
    }
  }
  references {
    iorName 
    invoiceUrl 
    vesselName 
    tripNumber 
    createdBy
    vatNumber
    referenceNumbers {
      type
      value
    }
  }
  lines {
    type
    amount {
      ...costFields
    }
  }
  invoiceDate
  operator {
    id
  }
}`;

export const invoiceDocumentGenerationRequestFragments = `
${invoiceDocumentGenerationRequestFragment} 
${addressFields}
${costFields}
${recordFields}
${recordActionFields}`;
