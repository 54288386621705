export enum CalculateAmendmentResultNoActionStatus {
  NOT_NECESSARY = 'NOT_NECESSARY',
  NO_DUTY_CHANGE = 'NO_DUTY_CHANGE',
  BROKER_DOES_NOT_PAY_DUTY = 'BROKER_DOES_NOT_PAY_DUTY',
  LINE_NOT_CREATED = 'LINE_NOT_CREATED',
  UNKNOWN_INVOICE_STATUS = 'UNKNOWN_INVOICE_STATUS',
}

export enum CalculateAmendmentResultActionableStatus {
  WILL_UPDATE_UPCOMING = 'WILL_UPDATE_UPCOMING',
  WILL_VOID_AND_REPLACE = 'WILL_VOID_AND_REPLACE',
  WILL_ISSUE_CREDIT_FOR_DIFF = 'WILL_ISSUE_CREDIT_FOR_DIFF',
  WILL_ISSUE_INVOICE_FOR_DIFF = 'WILL_ISSUE_INVOICE_FOR_DIFF',
}

export const CalculateAmendmentResultStatus = {
  ...CalculateAmendmentResultNoActionStatus,
  ...CalculateAmendmentResultActionableStatus,
};
export type CalculateAmendmentResultStatus =
  | CalculateAmendmentResultNoActionStatus
  | CalculateAmendmentResultActionableStatus;
