import { UsConsumptionEntry } from '@xbcb/api-gateway-client';
import { getAdCvdCaseIdsFromInvoices } from '../../util';
import { AdCvdCaseDataRetriever } from '../../sao/adCvdCaseSao';
import { AdCvdQueryFunction } from '../../../types';
import log from '@xbcb/log';

export const processAdCvdCases =
  (queryFn: AdCvdQueryFunction) =>
  async (entry: UsConsumptionEntry): Promise<void> => {
    if (!entry.invoices) return;
    // get case Ids to fetch
    const adCvdCaseIds = getAdCvdCaseIdsFromInvoices(entry.invoices);

    const adCvdCaseDataRetriever = new AdCvdCaseDataRetriever(queryFn);
    await adCvdCaseDataRetriever.fetchAdCvdCasesData(adCvdCaseIds);
    // map back fetched data to ad and cv case IdInputs
    for (const invoice of entry.invoices) {
      for (const product of invoice.products || []) {
        for (const line of product.lines || []) {
          if (line.adCase) {
            line.adCase = await adCvdCaseDataRetriever.getAdCvdCaseData(
              line.adCase.id,
            );
            log.debug(`processAdCvdCases adCase: ${line.adCase}`);
          }
          if (line.cvCase) {
            line.cvCase = await adCvdCaseDataRetriever.getAdCvdCaseData(
              line.cvCase.id,
            );
            log.debug(`processAdCvdCases cvCase: ${line.cvCase}`);
          }
        }
      }
    }
  };
