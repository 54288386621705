import { useRef, useEffect } from 'react';

/**
 * Returns previous state.
 * @param {T} value  state value
 * @return {T} previous state value
 */
export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};
