import { SortOrder } from '@xbcb/shared-types';
import { createPartyTable } from './createPartyTable';
import { EsDataType } from '../../enums';

export const broker = createPartyTable({
  columns: {
    identifier: {
      name: 'Identifier',
      type: EsDataType.TEXT,
      default: true,
      hidden: false,
    },
    identificationIssuingCountry: {
      name: 'Country',
      type: EsDataType.KEYWORD,
      default: true,
      hidden: false,
    },
  },
  tableOptions: {
    sortOrder: SortOrder.DESC,
  },
});
