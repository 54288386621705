import React from 'react';
import Icon from '@ant-design/icons/lib/components/Icon';
import { Dropdown, Menu } from 'antd';
import { AnyObject } from '@xbcb/shared-types';
import { StyledButton } from './styles';
// TODO remove any, `Icon` expects a string (even though this works)
const dots: any = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
  </svg>
);

// TODO properly type SubMenuOptions and subMenuProps once we start using it
type KebabMenuProps = {
  items: {
    label: string;
    action: (props: AnyObject) => Promise<void> | void;
    SubMenuOptions?: React.FC<any>;
    subMenuProps?: AnyObject;
  }[];
  loading?: boolean;
};

const KebabMenu = ({ items, loading }: KebabMenuProps) => {
  const menu = (
    <Menu>
      {items.map(({ label, action, SubMenuOptions, subMenuProps }) => {
        return SubMenuOptions ? (
          <Menu.SubMenu key={label} title={label}>
            <SubMenuOptions action={action} {...subMenuProps} />
          </Menu.SubMenu>
        ) : (
          <Menu.Item key={label} onClick={action}>
            {label}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  return (
    <Dropdown
      disabled={loading}
      trigger={['click']}
      overlay={menu}
      placement="bottomRight"
    >
      <StyledButton>
        <Icon component={() => dots} />
      </StyledButton>
    </Dropdown>
  );
};

export default KebabMenu;
