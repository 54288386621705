import { ein, scac, shipperId, shipperName } from './party';
import { createPartyTable } from './createPartyTable';
import { EsDataType } from '../../enums';

export const trucker = createPartyTable({
  columns: {
    shipperName,
    shipperId,
    scac,
    ein,
    city: {
      default: true,
      name: 'City',
      type: EsDataType.TEXT,
    },
  },
});
