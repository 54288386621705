import { stripTypeNames } from '@xbcb/js-utils';
import { AnyObject, RecordType } from '@xbcb/shared-types';
import { dataDefaults } from '../dataDefaults';
import { cleanObject } from '../clean';
import { momentize } from '../momentize';

export const prepareInitialValues = ({
  recordType,
  record,
}: {
  recordType: RecordType;
  // Record is optional as on create there wouldn't be a record yet to
  // initialize the form with
  record?: AnyObject;
}) => {
  const initialValues = {
    ...dataDefaults[recordType as keyof typeof dataDefaults],
    ...stripTypeNames(record || {}),
  };

  const cleanedInitialValues = cleanObject(initialValues);
  const momentizedInitialValues = momentize(cleanedInitialValues);

  return momentizedInitialValues || {};
};
