export const warningStatusDescription = 'The status of warning from CBP';
export enum WarningStatus {
  ACTIVE = 'ACTIVE',
  RESOLVED = 'RESOLVED',
}

export const censusOverrideCodeDescription =
  'The override codes apply to the census warning condition';
export enum CensusOverrideCode {
  EXCEPTION_TO_EMBARGO = '01',
  TIMING_OF_EMBARGO = '02',
  COUNTRY_VERIFIED_AS_CORRECT = '03',
  PROTOTYPE = '04',
  SAMPLE = '05',
  ASSIST = '06',
  ONE_OF_A_KIND = '07',
  PRECISION_MADE = '08',
  UNIQUE_MATERIAL = '09',
  EXPERIMENTAL_DRUG = '10',
  MILITARY_APPLICATION = '11',
  MASS_PRODUCED = '12',
  LESS_THAN_PERFECT = '13',
  LOWER_QUALITY_MATERIAL = '14',
  MARKET_CONDITIONS = '15',
  SPECIAL_HANDLING_REQUIRED = '16',
  CHARTERED_TRANSPORTATION = '17',
  INSURANCE_COSTS_VERY_HIGH = '18',
  RUSH_DELIVERY = '19',
  WEIGHT_OF_ARTICLE_HEAVIER_THAN_NORMAL = '20',
  WEIGHT_OF_ARTICLE_LIGHTER_THAN_NORMAL = '21',
  PACKAGING_HEAVIER_THAN_NORMAL = '22',
  PACKAGING_LIGHTER_THAN_NORMAL = '23',
  NON_PRODUCT_LINE_ITEM_NEEDED_TO_CONDUCT_BUSINESS = '24',
  BEGINNING_TO_IMPORT_NEW_PRODUCT_LINE = '25',
  COUNTRY_OF_EXPORT_VERIFIED_AS_CORRECT = '26',
  FTZ_WITHDRAWAL_LOW_FOREIGN_VALUE = '27',
  PARAMETER_CHANGE_REQUEST_PENDING = '49',
  CORRECT_AS_ENTERED = '50',
  ENTERED_UNDER_SPECIAL_CONDITIONS = '51',
}

// An enum member cannot have a numeric name, so we should add CODE_ prefix for them.
export const censusWarningCodePrefix = 'CODE_';

export enum CensusWarningCode {
  CODE_27A = '27A',
  CODE_27P = '27P',
  CODE_27B = '27B',
  CODE_27M = '27M',
  CODE_27D = '27D',
  CODE_27F = '27F',
  CODE_27C = '27C',
  CODE_28E = '28E',
  CODE_27G = '27G',
  CODE_27I = '27I',
  CODE_27H = '27H',
  CODE_27Q = '27Q',
  CODE_27J = '27J',
}

export const warningCodeDescription = 'The warning code from CBP';

// Currently there is only one warning code, cenesus warning code,
// here is to support multiple warning codes in the future.
export const WarningCode = {
  ...CensusWarningCode,
};

export type WarningCode = CensusWarningCode;
